import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgScenarioApiService } from './prog-scenario-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgScenarioApiService extends GeneratedProgScenarioApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
