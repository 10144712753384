import { Injectable } from '@angular/core';
import { ComposantAttenduReponseEnum } from '@enums';
import {
  AssertEmptyComposantAttenduInterface,
  ComposantAttendu,
  ComposantAttenduEntityState,
  DynamicType,
  Patrimoine,
  PatrimoineDictWithChildrenCountInterface,
  PatrimoineWithChildren,
  SocieteCaracteristique,
  SocieteComposant,
  SocietePatrimoineHierarchie,
  User
} from '@get/api-interfaces';
import { ComposantAttenduDocument, ComposantDocument, MyDatabaseCollections } from '@get/interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttenduApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { gatherComposantAttendusFromPatrimoineWithChildren } from '@get/utils';
import { Store } from '@ngrx/store';
import {
  areComposantAttenduEqual,
  getDirectAncestor,
  transformAncestorsArrayToTreeObj,
  transformArrayToObject,
  turnPatrimoineToComposantsAttendusObj,
  turnPatrimoineToSocieteComposantsObj,
  updatePatrimoineComposantAttendusFromChildren
} from '@utils';
import { RxDocument } from 'rxdb';
import { Observable, combineLatest, first, tap } from 'rxjs';
import { DbService } from './db.service';
import { PatrimoineDbService } from './patrimoine.db.service';

@Injectable({ providedIn: 'root' })
export class ComposantAttenduDbService {
  constructor(
    private dbService: DbService,
    private store$: Store<AppState>,
    private composantAttenduApiService: ComposantAttenduApiService,
    private readonly patrimoineDbService: PatrimoineDbService
  ) {}

  public findHighestAncestorId(patrimoineObj: DynamicType<Patrimoine>, idPatrimoine: number): number | undefined {
    const patrimoine = patrimoineObj[idPatrimoine];
    if (patrimoine) {
      const directAncestor = getDirectAncestor(patrimoine.ancetres);
      if (directAncestor) {
        return this.findHighestAncestorId(patrimoineObj, directAncestor.idAncetrePatrimoine);
      }
      return patrimoine.idPatrimoine;
    }
    return undefined;
  }

  public async recalculateComposantAttenduTreeForPatrimoine(
    idPatrimoine: number,
    idSocieteComposant: number,
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    newComposantDocument?: RxDocument<ComposantDocument>,
    idUser?: number
  ): Promise<ComposantAttenduDocument[]> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const patrimoinesInvolved = await this.patrimoineDbService.getAllPatrimoinesRelatedWithIdPatrimoine(
      idPatrimoine,
      databaseCollections
    );
    if (patrimoinesInvolved?.length) {
      // Ici on fait un find sans selector car les id sont fixes (non créés via le front) et find seul est rapide
      const composantAttendusDocuments = await databaseCollections['composant-attendus'].find().exec();
      const composantAttendus = composantAttendusDocuments
        ?.map(el => el.getLatest().toMutableJSON())
        ?.filter(el => el.societeComposant === idSocieteComposant);

      // Ici on fait un find avec selector car le front peut créer des nouveaux éléments et le fait de mettre un selector
      // permet de récupérer une version à jour de la db donc avec les éléments créés quelques ms avant de passer ici
      // Sans le selector, on se retrouverait avec l'état de la db locale avant ajout des nouveaux id car créés trop récemment
      // Cette query est beaucoup + lente que find sans selector
      const composantsDocuments = await databaseCollections.composants
        .find({ selector: { societeComposant: idSocieteComposant } })
        .exec();

      const composants = composantsDocuments?.map(el => el.getLatest().toMutableJSON())?.filter(el => !el.deleted);
      if (newComposantDocument) {
        const composant = newComposantDocument.getLatest().toMutableJSON();
        if (!composants?.some(cmp => cmp.idComposant === composant.idComposant)) {
          composants?.push(composant);
        }
      }
      const filteredComposants = composants.filter(el => el.societeComposant === idSocieteComposant);

      const idsSocietePatrimoineHierarchies = patrimoinesInvolved
        ?.map(el => el.idSocietePatrimoineHierarchie)
        ?.filter((id, idx, arr) => arr.indexOf(id) === idx);
      return new Promise((resolve, reject) => {
        combineLatest([
          selectors.selectAllSocietePatrimoineHierarchies,
          selectors.selectAllSocieteCaracteristiques,
          selectors.selectAllSocieteComposants
        ])
          .pipe(
            first(),
            tap(async ([societePatrimoineHierarchies, societeCaracteristiques, societeComposants]) => {
              const societeComposantsDict: DynamicType<SocieteComposant> = transformArrayToObject(societeComposants, {
                key: 'idSocieteComposant'
              });
              const filteredSocietePatrimoineHierarchies = societePatrimoineHierarchies
                .filter(el => idsSocietePatrimoineHierarchies.includes(el.idSocietePatrimoineHierarchie))
                ?.map(el => ({
                  ...el,
                  societeComposantRattachements: el.societeComposantRattachements?.filter(
                    rattachement => rattachement.idSocieteComposant === idSocieteComposant
                  )
                }));
              const societePatrimoineHierarchiesObj = transformArrayToObject(filteredSocietePatrimoineHierarchies, {
                key: 'idSocietePatrimoineHierarchie'
              });

              const societeCaracteristiquesObj = transformArrayToObject(societeCaracteristiques, {
                key: 'idSocieteCaracteristique'
              });
              const composantsPerEspace =
                filteredComposants?.reduce((acc, composant) => {
                  if (composant.espace?.idEspace) {
                    const formattedComposant = {
                      ...composant,
                      valeurs: composant.valeurs?.map(valeur => ({
                        ...valeur,
                        societeCaracteristique: societeCaracteristiquesObj[valeur.societeCaracteristique]
                      }))
                    };
                    if (acc[composant.espace?.idEspace]) {
                      acc[composant.espace?.idEspace].push(formattedComposant);
                    } else {
                      acc[composant.espace?.idEspace] = [formattedComposant];
                    }
                  }
                  return acc;
                }, {} as DynamicType<ComposantDocument[]>) || {};

              const composantAttendusPerPatrimoine =
                composantAttendus?.reduce((acc, composantAttendu) => {
                  const element = { ...composantAttendu, idSocieteComposant: composantAttendu.societeComposant };
                  if (acc[composantAttendu.patrimoine?.idPatrimoine]) {
                    acc[composantAttendu.patrimoine?.idPatrimoine].push(element);
                  } else {
                    acc[composantAttendu.patrimoine?.idPatrimoine] = [element];
                  }
                  return acc;
                }, {} as DynamicType<(ComposantAttenduDocument & { idSocieteComposant: number })[]>) || {};

              const formattedPatrimoines = patrimoinesInvolved.map(patrimoine => ({
                ...patrimoine,
                societePatrimoineHierarchie: societePatrimoineHierarchiesObj[patrimoine.idSocietePatrimoineHierarchie],
                composantAttendus: composantAttendusPerPatrimoine[patrimoine.idPatrimoine],
                espaces: patrimoine.espaces?.map(espace => ({
                  ...espace,
                  composants: composantsPerEspace[espace.idEspace] || []
                }))
              }));

              const mappedPatrimoines = formattedPatrimoines.map(patrimoine => ({
                idPatrimoine: patrimoine.idPatrimoine,
                ancetres: patrimoine.ancetres,
                oldComposantAttendus: transformArrayToObject(patrimoine.composantAttendus, {
                  key: 'idComposantAttendu'
                }),
                composantAttendus: turnPatrimoineToComposantsAttendusObj(
                  patrimoine as unknown as Patrimoine,
                  societeComposantsDict,
                  idUser as number
                ),
                societeComposants: turnPatrimoineToSocieteComposantsObj(patrimoine as unknown as Patrimoine),
                updated: false
              }));
              const patrimoinesTreeArrayObj: PatrimoineDictWithChildrenCountInterface =
                transformAncestorsArrayToTreeObj(mappedPatrimoines, {
                  key: 'idPatrimoine',
                  childrenKey: 'children',
                  parentListKey: 'ancetres',
                  parentListIdentifierKey: 'idAncetrePatrimoine'
                });

              let i = 0;
              while (i < formattedPatrimoines.length) {
                updatePatrimoineComposantAttendusFromChildren(
                  formattedPatrimoines[i].idPatrimoine,
                  patrimoinesTreeArrayObj
                );
                i++;
              }
              const composantAttenduToUpdate = [];

              i = 0;
              while (i < formattedPatrimoines.length) {
                const idFormattedPatrimoine = formattedPatrimoines[i].idPatrimoine;
                const pat = patrimoinesTreeArrayObj[idFormattedPatrimoine];
                if (pat) {
                  const idsSocieteComposantKeys = Object.keys(pat.societeComposants);
                  for (let j = 0; j < idsSocieteComposantKeys.length; j++) {
                    const idsSocieteComposantKey = +idsSocieteComposantKeys[j];
                    if (!isNaN(idsSocieteComposantKey)) {
                      const composantAttendu = pat.composantAttendus[idsSocieteComposantKey];
                      if (composantAttendu) {
                        const existingComposantAttendu =
                          pat.oldComposantAttendus[composantAttendu.idComposantAttendu as number];
                        if (
                          existingComposantAttendu &&
                          composantAttendu &&
                          !areComposantAttenduEqual(existingComposantAttendu, composantAttendu)
                        ) {
                          composantAttenduToUpdate.push({ ...composantAttendu, isSynced: false });
                        }
                      }
                    }
                  }
                }
                i++;
              }

              this.store$.dispatch(
                ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                  composantAttendus: composantAttenduToUpdate.map(
                    ca =>
                      ({
                        ...ca,
                        idComposantAttendu: +(ca.idComposantAttendu as number),
                        patrimoine: ca.patrimoine?.idPatrimoine
                      } as ComposantAttenduEntityState)
                  )
                })
              );
              resolve(composantAttenduToUpdate as unknown as ComposantAttenduDocument[]);
            })
          )
          .subscribe();
      });
    } else {
      return [];
    }
  }

  public async assertEmptyTree(
    params: AssertEmptyComposantAttenduInterface & {
      patrimoine: PatrimoineWithChildren;
    },
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    idUser?: number
  ): Promise<void> {
    const composantAttendus = gatherComposantAttendusFromPatrimoineWithChildren(params.patrimoine)?.filter(
      composantAttendu =>
        (composantAttendu.reponse === ComposantAttenduReponseEnum.neSaisPas || composantAttendu.reponse === null) &&
        ((params.idSocieteComposant &&
          composantAttendu.societeComposant.idSocieteComposant === params.idSocieteComposant) ||
          (params.idSocieteComposantFamille &&
            composantAttendu.societeComposant.idSocieteComposantFamille === params.idSocieteComposantFamille))
    );
    if (composantAttendus?.length) {
      const databaseCollections = await this.dbService.getDatabaseCollection();
      const currentComposantAttendusDocuments = await databaseCollections['composant-attendus']
        .find({
          selector: { $or: composantAttendus.map(el => ({ idComposantAttendu: el.idComposantAttendu.toString() })) }
        })
        .exec();
      const composantsAttendusToAnswerNoObj = transformArrayToObject(composantAttendus, { key: 'idComposantAttendu' });
      const mappedComposantAttendusToUpdate = currentComposantAttendusDocuments
        ?.map(el => el.getLatest().toMutableJSON())
        ?.map(composantAttendu =>
          composantsAttendusToAnswerNoObj[composantAttendu.idComposantAttendu]
            ? {
                ...composantAttendu,
                isSynced: false,
                reponse: ComposantAttenduReponseEnum.non,
                idUser,
                user: idUser,
                calcule: false,
                updatedAt: new Date().toISOString() as unknown as Date
              }
            : composantAttendu
        );

      if (mappedComposantAttendusToUpdate?.length) {
        this.dbService.setBlockingState(true);
        await databaseCollections['composant-attendus'].bulkUpsert(mappedComposantAttendusToUpdate);
        this.dbService.setBlockingState(false);

        const societeComposants = mappedComposantAttendusToUpdate
          ?.map(el => el.societeComposant)
          ?.filter((el, idx, arr) => arr.indexOf(el) === idx);
        const updatedComposantAttendus = [];
        for (let i = 0; i < societeComposants?.length; i++) {
          const ca = await this.recalculateComposantAttenduTreeForPatrimoine(
            params.patrimoine.idPatrimoine,
            societeComposants[i],
            selectors,
            undefined,
            idUser
          );
          updatedComposantAttendus.push(...ca);
        }
        await this.updateComposantAttendus(updatedComposantAttendus, databaseCollections);

        this.synchronizeFromIndexedDb();
      }
    }
  }

  public async assertEmptyPatrimoineComposantAttendu(params: {
    patrimoine: Patrimoine;
    idSocieteComposant: number;
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    };
    idUser?: number;
  }): Promise<void> {
    const composantAttenduToUpdate = params.patrimoine?.composantAttendus?.find(
      composantAttendu => composantAttendu.societeComposant?.idSocieteComposant === params.idSocieteComposant
    );
    if (composantAttenduToUpdate) {
      // Je n'update pas "nbReponseRempli" car le recalcul des composant-attendus s'en chargera
      // ce qui permet d'être sûr que la normalisation s'effectue correctement (à la fin du recalcul)
      const updatedComposantAttendu = {
        idComposantAttendu: composantAttenduToUpdate.idComposantAttendu,
        reponse: ComposantAttenduReponseEnum.non,
        societeComposant: params.idSocieteComposant,
        calcule: false,
        user: params.idUser as unknown as User,
        idUser: params.idUser,
        updatedAt: new Date().toISOString() as unknown as Date
      } as unknown as Partial<ComposantAttenduDocument>;

      const idComposantAttendu = updatedComposantAttendu.idComposantAttendu?.toString();
      const databaseCollections = await this.dbService.getDatabaseCollection();
      const composantAttenduDocument = await databaseCollections['composant-attendus']
        .findOne({ selector: { idComposantAttendu } })
        .exec();
      if (composantAttenduDocument) {
        await composantAttenduDocument.incrementalPatch({
          ...updatedComposantAttendu,
          idComposantAttendu,
          isSynced: false
        });
      }
      const updatedComposantAttendus = await this.recalculateComposantAttenduTreeForPatrimoine(
        params.patrimoine.idPatrimoine,
        params.idSocieteComposant,
        params.selectors,
        undefined,
        params.idUser
      );
      await this.updateComposantAttendus(updatedComposantAttendus, databaseCollections);
      this.synchronizeFromIndexedDb();
    }
  }

  public async updateComposantAttendus(
    composantAttendus: ComposantAttenduDocument[],
    databaseCollections?: MyDatabaseCollections
  ): Promise<void> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    if (composantAttendus?.length) {
      this.dbService.setBlockingState(true);
      await databaseCollections['composant-attendus'].bulkUpsert(composantAttendus);
      this.dbService.setBlockingState(false);
    }
  }

  public async updateComposantAttenduDocuments(
    composantAttenduDocuments: RxDocument<ComposantAttenduDocument>[],
    databaseCollections: MyDatabaseCollections
  ): Promise<void> {
    const composantAttendus = (
      composantAttenduDocuments?.map(ca => ({
        ...ca.getLatest().toJSON(),
        idComposantAttendu: +ca.idComposantAttendu
      })) as unknown as Partial<ComposantAttendu>[]
    )?.filter(ca => !isNaN(ca.idComposantAttendu as number));
    this.composantAttenduApiService
      .upsertManyComposantAttendus(composantAttendus)
      .pipe(
        first(),
        tap(async res => {
          if (res?.length) {
            this.store$.dispatch(
              ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                composantAttendus: res.map(ca => ({
                  ...ca,
                  patrimoine: ca.idPatrimoine,
                  isSynced: true
                }))
              })
            );

            this.dbService.setBlockingState(true);
            await databaseCollections['composant-attendus'].bulkUpsert(
              res.map(
                el =>
                  ({
                    ...el,
                    idComposantAttendu: el?.idComposantAttendu?.toString(),
                    isSynced: true
                  } as unknown as Partial<ComposantAttenduDocument>)
              )
            );
            this.dbService.setBlockingState(false);
          }
        })
      )
      .subscribe();
  }

  public async synchronizeFromIndexedDb(): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantAttenduDocuments = await databaseCollections['composant-attendus']
      .find({ selector: { isSynced: false } })
      .exec();
    const filteredDocuments = composantAttenduDocuments.filter(
      composantAttendu => composantAttendu.getLatest().toJSON().isSynced === false
    );
    if (filteredDocuments?.length) {
      await this.updateComposantAttenduDocuments(filteredDocuments, databaseCollections);
    }
  }
}
