import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgComposantSousGroupeApiService } from './prog-composant-sous-groupe-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgComposantSousGroupeApiService extends GeneratedProgComposantSousGroupeApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
