import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgInterventionFamille } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgInterventionFamilleRelationsIds } from '@get/store/ids-interfaces';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { ProgInterventionFamilleSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgInterventionFamilleService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgInterventionFamilleSelectors.selectIsLoadedProgInterventionFamille));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgInterventionFamilleSelectors.selectIsLoadingProgInterventionFamille));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgInterventionFamilleSelectors.selectIsReadyAndLoadedProgInterventionFamille as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgInterventionFamilles(schema: SelectSchema = {}): Observable<ProgInterventionFamille[]> {
    return this.store$.pipe(select(ProgInterventionFamilleSelectors.selectAllProgInterventionFamilles(schema))).pipe(
      switchMap(({ progInterventionFamilles }: { progInterventionFamilles: ProgInterventionFamille[] }) => {
        return this.getReady(schema).pipe(map(() => progInterventionFamilles));
      })
    );
  }

  public selectOneProgInterventionFamille(
    idProgInterventionFamille: number,
    schema: SelectSchema = {}
  ): Observable<ProgInterventionFamille> {
    return this.store$
      .pipe(select(ProgInterventionFamilleSelectors.selectOneProgInterventionFamille(schema, idProgInterventionFamille)))
      .pipe(
        switchMap(({ progInterventionFamille }: { progInterventionFamille: ProgInterventionFamille }) => {
          return this.getReady(schema).pipe(map(() => progInterventionFamille));
        })
      );
  }

  public upsertOneProgInterventionFamille(
    progInterventionFamille: Partial<ProgInterventionFamille>,
    ids: ProgInterventionFamilleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgInterventionFamille> {
    this.store$.dispatch(ProgInterventionFamilleGeneratedActions.upsertOneProgInterventionFamille({ progInterventionFamille, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgInterventionFamilleGeneratedActions.normalizeManyProgInterventionFamillesAfterUpsert,
        ProgInterventionFamilleGeneratedActions.progInterventionFamillesFailure,
        true
      );
    }
  }

  public deleteOneProgInterventionFamille(
    idProgInterventionFamille: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamille({ idProgInterventionFamille }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamilleSuccess,
        ProgInterventionFamilleGeneratedActions.progInterventionFamillesFailure
      );
    }
  }
}
