import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';

export const CampagneSocieteCaracteristiqueGeneratedActions = createActionGroup({
  source: 'Campagne Societe Caracteristique Generated',
  events: {
    'Upsert One Campagne Societe Caracteristique': props<{ campagneSocieteCaracteristique: Partial<CampagneSocieteCaracteristique>; ids?: CampagneSocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Campagne Societe Caracteristiques': props<{ campagneSocieteCaracteristiques: Partial<CampagneSocieteCaracteristique>[]; ids?: CampagneSocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Campagne Societe Caracteristiques Success': props<{ campagneSocieteCaracteristiques: CampagneSocieteCaracteristiqueEntityState[] }>(),
    'Delete One Campagne Societe Caracteristique Success': props<{ idCampagneSocieteCaracteristique: number }>(),
    'Normalize Many Campagne Societe Caracteristiques After Upsert': props<{ campagneSocieteCaracteristiques: CampagneSocieteCaracteristiqueEntityState[] }>(),
    'Campagne Societe Caracteristiques Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Campagne Societe Caracteristiques': emptyProps(),
    'Add Campagne Success': props<{ idCampagneSocieteCaracteristique: number; idCampagne: number }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idCampagneSocieteCaracteristiques: number[] }>(),
    'Add Societe Caracteristique Success': props<{ idCampagneSocieteCaracteristique: number; idSocieteCaracteristique: number }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idCampagneSocieteCaracteristiques: number[] }>()
  }
});
