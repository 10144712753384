import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurProgInterventionApiService } from '@get/store/api-services';
import { ValeurProgInterventionGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurProgIntervention } from '@get/store/configs/normalization';

export class GeneratedValeurProgInterventionEffects {
  constructor(
    protected actions$: Actions,
    protected valeurProgInterventionApiService: ValeurProgInterventionApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeurProgInterventionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurProgInterventionGeneratedActions.normalizeManyValeurProgInterventionsAfterUpsert),
      concatMap(({ valeurProgInterventions }) => {
        const actions: Action[] = getActionsToNormalizeValeurProgIntervention(valeurProgInterventions);
        return [getMultiAction(actions, '[ValeurProgIntervention] Normalization After Upsert Success')];
      })
    );
  });
}
