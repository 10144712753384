import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueChoixSelectors } from '@get/store/selectors';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteCaracteristiqueChoixService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectIsLoadedSocieteCaracteristiqueChoix));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectIsLoadingSocieteCaracteristiqueChoix));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteCaracteristiqueChoixSelectors.selectIsReadyAndLoadedSocieteCaracteristiqueChoix as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteCaracteristiqueChoices(schema: SelectSchema = {}): Observable<SocieteCaracteristiqueChoix[]> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectAllSocieteCaracteristiqueChoices(schema))).pipe(
      switchMap(({ societeCaracteristiqueChoices }: { societeCaracteristiqueChoices: SocieteCaracteristiqueChoix[] }) => {
        return this.getReady(schema).pipe(map(() => societeCaracteristiqueChoices));
      })
    );
  }

  public selectOneSocieteCaracteristiqueChoix(
    idSocieteCaracteristiqueChoix: number,
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueChoix> {
    return this.store$
      .pipe(select(SocieteCaracteristiqueChoixSelectors.selectOneSocieteCaracteristiqueChoix(schema, idSocieteCaracteristiqueChoix)))
      .pipe(
        switchMap(({ societeCaracteristiqueChoix }: { societeCaracteristiqueChoix: SocieteCaracteristiqueChoix }) => {
          return this.getReady(schema).pipe(map(() => societeCaracteristiqueChoix));
        })
      );
  }
}
