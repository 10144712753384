import { ProgBudgetState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progBudgetReducersGeneratedFunctions } from './prog-budget-generated.reducer';

const progBudgetReducersFunctions = [...progBudgetReducersGeneratedFunctions];

const progBudgetReducer = createReducer(ProgBudgetState.initialState, ...progBudgetReducersFunctions);

export function reducer(state: ProgBudgetState.IState | undefined, action: Action) {
  return progBudgetReducer(state, action);
}
