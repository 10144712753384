import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgIntervention } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgInterventionSelectors } from '@get/store/selectors';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgInterventionService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgInterventionSelectors.selectIsLoadedProgIntervention));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgInterventionSelectors.selectIsLoadingProgIntervention));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgInterventionSelectors.selectIsReadyAndLoadedProgIntervention as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgInterventions(schema: SelectSchema = {}): Observable<ProgIntervention[]> {
    return this.store$.pipe(select(ProgInterventionSelectors.selectAllProgInterventions(schema))).pipe(
      switchMap(({ progInterventions }: { progInterventions: ProgIntervention[] }) => {
        return this.getReady(schema).pipe(map(() => progInterventions));
      })
    );
  }

  public selectOneProgIntervention(
    idProgIntervention: number,
    schema: SelectSchema = {}
  ): Observable<ProgIntervention> {
    return this.store$
      .pipe(select(ProgInterventionSelectors.selectOneProgIntervention(schema, idProgIntervention)))
      .pipe(
        switchMap(({ progIntervention }: { progIntervention: ProgIntervention }) => {
          return this.getReady(schema).pipe(map(() => progIntervention));
        })
      );
  }
}
