import { ProgInterventionPatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progInterventionPatrimoineReducersGeneratedFunctions } from './prog-intervention-patrimoine-generated.reducer';

const progInterventionPatrimoineReducersFunctions = [...progInterventionPatrimoineReducersGeneratedFunctions];

const progInterventionPatrimoineReducer = createReducer(
  ProgInterventionPatrimoineState.initialState,
  ...progInterventionPatrimoineReducersFunctions
);

export function reducer(state: ProgInterventionPatrimoineState.IState | undefined, action: Action) {
  return progInterventionPatrimoineReducer(state, action);
}
