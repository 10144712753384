import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgBiblioInterventionApiService } from '@get/store/api-services';
import { GeneratedProgBiblioInterventionEffects } from './prog-biblio-intervention-generated.effects';

@Injectable()
export class ProgBiblioInterventionEffects extends GeneratedProgBiblioInterventionEffects {
  constructor(actions$: Actions, progBiblioInterventionApiService: ProgBiblioInterventionApiService, store$: Store<AppState>) {
    super(actions$, progBiblioInterventionApiService, store$);
  }
}
