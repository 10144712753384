import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgComposantGroupe, ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgComposantGroupeState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progComposantGroupeRelations: string[] = ['progComposantSousGroupes','societes',];

export const { selectEntities, selectAll } = ProgComposantGroupeState.adapter.getSelectors();

export const selectProgComposantGroupeState = createFeatureSelector<ProgComposantGroupeState.IState>(ProgComposantGroupeState.progComposantGroupeFeatureKey);

export const selectIsLoadedProgComposantGroupe = createSelector(
  selectProgComposantGroupeState,
  (state: ProgComposantGroupeState.IState) => state.isLoaded
);

export const selectIsLoadingProgComposantGroupe = createSelector(
  selectProgComposantGroupeState,
  (state: ProgComposantGroupeState.IState) => state.isLoading
);

export const selectIsReadyProgComposantGroupe = createSelector(
  selectProgComposantGroupeState,
  (state: ProgComposantGroupeState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgComposantGroupe = createSelector(
  selectProgComposantGroupeState,
  (state: ProgComposantGroupeState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgComposantGroupesEntities = createSelector(selectProgComposantGroupeState, selectEntities);

export const selectProgComposantGroupesArray = createSelector(selectProgComposantGroupeState, selectAll);

const progComposantGroupesInObject = (progComposantGroupes: Dictionary<ProgComposantGroupeEntityState>) => ({ progComposantGroupes })

const selectProgComposantGroupesEntitiesDictionary = createSelector(selectProgComposantGroupesEntities, progComposantGroupesInObject);

const selectAllProgComposantGroupesObject = createSelector(selectProgComposantGroupesEntities, progComposantGroupes => {
  return hydrateAll({ progComposantGroupes });
});

const selectOneProgComposantGroupeDictionary = (idProgComposantGroupe : number) =>
  createSelector(selectProgComposantGroupesEntities, progComposantGroupes => ({
    progComposantGroupes: { [idProgComposantGroupe]: progComposantGroupes[idProgComposantGroupe] }
  }));

const selectOneProgComposantGroupeDictionaryWithoutChild = (idProgComposantGroupe : number) =>
  createSelector(selectProgComposantGroupesEntities, progComposantGroupes => ({
    progComposantGroupe: progComposantGroupes[idProgComposantGroupe]
  }));

const selectAllProgComposantGroupesSelectors: Dictionary<Selector> = {};
export function selectAllProgComposantGroupes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgComposantGroupe>(
      schema,
      selectAllProgComposantGroupesSelectors,
      selectProgComposantGroupesEntitiesDictionary,
      getRelationSelectors,
      progComposantGroupeRelations,
      hydrateAll,
      'progComposantGroupe'
    );
  } else {
    return selectAllProgComposantGroupesObject;
  }
}

export function selectAllProgComposantGroupesDictionary(
  schema: SelectSchema = {},
  customKey = 'progComposantGroupes'
): Selector {
  return createSelector(selectAllProgComposantGroupes(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgComposantGroupeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progComposantGroupes.length; i++) {
      res[customKey][result.progComposantGroupes[i].idProgComposantGroupe] = result.progComposantGroupes[i];
    }
    return res;
  });
}

export function selectOneProgComposantGroupe(
  schema: SelectSchema = {},
  idProgComposantGroupe: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgComposantGroupeDictionary(idProgComposantGroupe)];
  selectors.push(...getRelationSelectors(schema, progComposantGroupeRelations, 'progComposantGroupe'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgComposantGroupeDictionaryWithoutChild(idProgComposantGroupe);
  }
}

interface hydrateArgs {
  progComposantGroupes: Dictionary<ProgComposantGroupeEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progComposantSousGroupes?: Dictionary<ProgComposantSousGroupeEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progComposantGroupes: (ProgComposantGroupe | null)[] } {
  const {
    progComposantGroupes,
    societes,
    progComposantSousGroupes
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progComposantGroupes: Object.keys(progComposantGroupes).map(idProgComposantGroupe =>
      hydrate(
        progComposantGroupes[idProgComposantGroupe] as ProgComposantGroupeEntityState,
        societes,
        progComposantSousGroupes
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progComposantGroupe: ProgComposantGroupeEntityState | null } {
  const {
    progComposantGroupes,
    societes,
    progComposantSousGroupes
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progComposantGroupe = Object.values(progComposantGroupes)[0];
  return {
    progComposantGroupe: hydrate(
      progComposantGroupe as ProgComposantGroupeEntityState,
      societes,
      progComposantSousGroupes
    )
  };
}

function hydrate(
  progComposantGroupe: ProgComposantGroupeEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progComposantSousGroupeEntities?: Dictionary<ProgComposantSousGroupeEntityState>,
): ProgComposantGroupe | null {
  if (!progComposantGroupe) {
    return null;
  }

  const progComposantGroupeHydrated: ProgComposantGroupeEntityState = { ...progComposantGroupe };
  if (societeEntities) {
    progComposantGroupeHydrated.societe = societeEntities[progComposantGroupe.societe as number] as Societe;
  } else {
    delete progComposantGroupeHydrated.societe;
  }

  if (progComposantSousGroupeEntities) {
    progComposantGroupeHydrated.progComposantSousGroupes = ((progComposantGroupeHydrated.progComposantSousGroupes as number[]) || []).map(
      id => progComposantSousGroupeEntities[id]
    ) as ProgComposantSousGroupe[];
  } else {
    delete progComposantGroupeHydrated.progComposantSousGroupes;
  }

  return progComposantGroupeHydrated as ProgComposantGroupe;
}
