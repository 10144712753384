import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgInterventionScenario, ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgInterventionScenarioState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progInterventionScenarioRelations: string[] = ['progScenarios','progInterventions',];

export const { selectEntities, selectAll } = ProgInterventionScenarioState.adapter.getSelectors();

export const selectProgInterventionScenarioState = createFeatureSelector<ProgInterventionScenarioState.IState>(ProgInterventionScenarioState.progInterventionScenarioFeatureKey);

export const selectIsLoadedProgInterventionScenario = createSelector(
  selectProgInterventionScenarioState,
  (state: ProgInterventionScenarioState.IState) => state.isLoaded
);

export const selectIsLoadingProgInterventionScenario = createSelector(
  selectProgInterventionScenarioState,
  (state: ProgInterventionScenarioState.IState) => state.isLoading
);

export const selectIsReadyProgInterventionScenario = createSelector(
  selectProgInterventionScenarioState,
  (state: ProgInterventionScenarioState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgInterventionScenario = createSelector(
  selectProgInterventionScenarioState,
  (state: ProgInterventionScenarioState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgInterventionScenariosEntities = createSelector(selectProgInterventionScenarioState, selectEntities);

export const selectProgInterventionScenariosArray = createSelector(selectProgInterventionScenarioState, selectAll);

const progInterventionScenariosInObject = (progInterventionScenarios: Dictionary<ProgInterventionScenarioEntityState>) => ({ progInterventionScenarios })

const selectProgInterventionScenariosEntitiesDictionary = createSelector(selectProgInterventionScenariosEntities, progInterventionScenariosInObject);

const selectAllProgInterventionScenariosObject = createSelector(selectProgInterventionScenariosEntities, progInterventionScenarios => {
  return hydrateAll({ progInterventionScenarios });
});

const selectOneProgInterventionScenarioDictionary = (idProgInterventionScenario : number) =>
  createSelector(selectProgInterventionScenariosEntities, progInterventionScenarios => ({
    progInterventionScenarios: { [idProgInterventionScenario]: progInterventionScenarios[idProgInterventionScenario] }
  }));

const selectOneProgInterventionScenarioDictionaryWithoutChild = (idProgInterventionScenario : number) =>
  createSelector(selectProgInterventionScenariosEntities, progInterventionScenarios => ({
    progInterventionScenario: progInterventionScenarios[idProgInterventionScenario]
  }));

const selectAllProgInterventionScenariosSelectors: Dictionary<Selector> = {};
export function selectAllProgInterventionScenarios(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgInterventionScenario>(
      schema,
      selectAllProgInterventionScenariosSelectors,
      selectProgInterventionScenariosEntitiesDictionary,
      getRelationSelectors,
      progInterventionScenarioRelations,
      hydrateAll,
      'progInterventionScenario'
    );
  } else {
    return selectAllProgInterventionScenariosObject;
  }
}

export function selectAllProgInterventionScenariosDictionary(
  schema: SelectSchema = {},
  customKey = 'progInterventionScenarios'
): Selector {
  return createSelector(selectAllProgInterventionScenarios(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgInterventionScenarioEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progInterventionScenarios.length; i++) {
      res[customKey][result.progInterventionScenarios[i].idProgInterventionScenario] = result.progInterventionScenarios[i];
    }
    return res;
  });
}

export function selectOneProgInterventionScenario(
  schema: SelectSchema = {},
  idProgInterventionScenario: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgInterventionScenarioDictionary(idProgInterventionScenario)];
  selectors.push(...getRelationSelectors(schema, progInterventionScenarioRelations, 'progInterventionScenario'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgInterventionScenarioDictionaryWithoutChild(idProgInterventionScenario);
  }
}

interface hydrateArgs {
  progInterventionScenarios: Dictionary<ProgInterventionScenarioEntityState>;
  progScenarios?: Dictionary<ProgScenarioEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progInterventionScenarios: (ProgInterventionScenario | null)[] } {
  const {
    progInterventionScenarios,
    progScenarios,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progInterventionScenarios: Object.keys(progInterventionScenarios).map(idProgInterventionScenario =>
      hydrate(
        progInterventionScenarios[idProgInterventionScenario] as ProgInterventionScenarioEntityState,
        progScenarios,
        progInterventions
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progInterventionScenario: ProgInterventionScenarioEntityState | null } {
  const {
    progInterventionScenarios,
    progScenarios,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progInterventionScenario = Object.values(progInterventionScenarios)[0];
  return {
    progInterventionScenario: hydrate(
      progInterventionScenario as ProgInterventionScenarioEntityState,
      progScenarios,
      progInterventions
    )
  };
}

function hydrate(
  progInterventionScenario: ProgInterventionScenarioEntityState,
  progScenarioEntities?: Dictionary<ProgScenarioEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
): ProgInterventionScenario | null {
  if (!progInterventionScenario) {
    return null;
  }

  const progInterventionScenarioHydrated: ProgInterventionScenarioEntityState = { ...progInterventionScenario };
  if (progScenarioEntities) {
    progInterventionScenarioHydrated.progScenario = progScenarioEntities[progInterventionScenario.progScenario as number] as ProgScenario;
  } else {
    delete progInterventionScenarioHydrated.progScenario;
  }
  if (progInterventionEntities) {
    progInterventionScenarioHydrated.progIntervention = progInterventionEntities[progInterventionScenario.progIntervention as number] as ProgIntervention;
  } else {
    delete progInterventionScenarioHydrated.progIntervention;
  }

  return progInterventionScenarioHydrated as ProgInterventionScenario;
}
