import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique } from '@get/store/configs/normalization';

export class GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects {
  constructor(
    protected actions$: Actions,
    protected societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert),
      concatMap(({ societeConfigAnalyseSyntheseSocieteCaracteristiques }) => {
        const actions: Action[] = getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiques, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteConfigAnalyseSyntheseSocieteCaracteristique] Normalization After Upsert Success')];
      })
    );
  });
}
