import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgScenario } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgScenarioRelationsIds } from '@get/store/ids-interfaces';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { ProgScenarioSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgScenarioService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgScenarioSelectors.selectIsLoadedProgScenario));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgScenarioSelectors.selectIsLoadingProgScenario));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgScenarioSelectors.selectIsReadyAndLoadedProgScenario as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgScenarios(schema: SelectSchema = {}): Observable<ProgScenario[]> {
    return this.store$.pipe(select(ProgScenarioSelectors.selectAllProgScenarios(schema))).pipe(
      switchMap(({ progScenarios }: { progScenarios: ProgScenario[] }) => {
        return this.getReady(schema).pipe(map(() => progScenarios));
      })
    );
  }

  public selectOneProgScenario(
    idProgScenario: number,
    schema: SelectSchema = {}
  ): Observable<ProgScenario> {
    return this.store$
      .pipe(select(ProgScenarioSelectors.selectOneProgScenario(schema, idProgScenario)))
      .pipe(
        switchMap(({ progScenario }: { progScenario: ProgScenario }) => {
          return this.getReady(schema).pipe(map(() => progScenario));
        })
      );
  }

  public upsertOneProgScenario(
    progScenario: Partial<ProgScenario>,
    ids: ProgScenarioRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgScenario> {
    this.store$.dispatch(ProgScenarioGeneratedActions.upsertOneProgScenario({ progScenario, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgScenarioGeneratedActions.normalizeManyProgScenariosAfterUpsert,
        ProgScenarioGeneratedActions.progScenariosFailure,
        true
      );
    }
  }

  public deleteOneProgScenario(
    idProgScenario: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgScenarioGeneratedActions.deleteOneProgScenario({ idProgScenario }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgScenarioGeneratedActions.deleteOneProgScenarioSuccess,
        ProgScenarioGeneratedActions.progScenariosFailure
      );
    }
  }
}
