export enum LibelleInternRightsEnum {
  accessConsulterEtEnrichir = 'consulter_et_enrichir_access',
  accessCampagneCollecte = 'campagne_collecte_access',
  accessAnalyser = 'analyser_access',
  accessProgrammation = 'programmation_access',
  accessSupport = 'support_access',
  updatePatrimoine = 'patrimoine_U',
  updatePatrimoineCaracteristique = 'patrimoine_caracteristique_U',
  createDeleteComposant = 'composant_CD',
  updateComposant = 'composant_U',
  createUpdateDeletecampagne = 'campagne_CUD',
  createUpdateDeleteUser = 'user_CUD',
  createDeletePatrimoine = 'patrimoine_CUD',
  updateDeleteComposant = 'composant_UD',
  createUpdateDeleteSetting = 'setting_CUD',
  createUpdateDeleteImportExcel = 'import_excel_CUD',
  getApi = 'api_G',
  createUpdateDeleteApi = 'api_CUD',
  accessProgrammationMultiYear = 'programmation_multi_year_access',
  accessProgrammationSingleYear = 'programmation_single_year_access',
  accessProgrammationFollowUp = 'programmation_follow_up_access',
  updateProgrammationMultiYear = 'programmation_multi_year_CUD',
  updateProgrammationSingleYear = 'programmation_single_year_CUD',
  updateProgrammationFollowUp = 'programmation_follow_up_CUD',
  programmationConsultPast = 'programmation_consult_past',
  programmationConsultCloseFuture = 'programmation_consult_close_future',
  programmationConsultFuture = 'programmation_consult_future'
}
