import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurProgInterventionApiService } from '@get/store/api-services';
import { GeneratedValeurProgInterventionEffects } from './valeur-prog-intervention-generated.effects';

@Injectable()
export class ValeurProgInterventionEffects extends GeneratedValeurProgInterventionEffects {
  constructor(actions$: Actions, valeurProgInterventionApiService: ValeurProgInterventionApiService, store$: Store<AppState>) {
    super(actions$, valeurProgInterventionApiService, store$);
  }
}
