import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgEtatTechnique, ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { ProgEtatTechniqueRelationsIds } from '@get/store/ids-interfaces';

export const ProgEtatTechniqueGeneratedActions = createActionGroup({
  source: 'Prog Etat Technique Generated',
  events: {
    'Upsert One Prog Etat Technique': props<{ progEtatTechnique: Partial<ProgEtatTechnique>; ids?: ProgEtatTechniqueRelationsIds; }>(),
    'Upsert Many Prog Etat Techniques': props<{ progEtatTechniques: Partial<ProgEtatTechnique>[]; ids?: ProgEtatTechniqueRelationsIds; }>(),
    'Upsert Many Prog Etat Techniques Success': props<{ progEtatTechniques: ProgEtatTechniqueEntityState[] }>(),
    'Delete One Prog Etat Technique': props<{ idProgEtatTechnique: number }>(),
    'Delete One Prog Etat Technique Success': props<{ idProgEtatTechnique: number }>(),
    'Normalize Many Prog Etat Techniques After Upsert': props<{ progEtatTechniques: ProgEtatTechniqueEntityState[] }>(),
    'Prog Etat Techniques Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Etat Techniques': emptyProps(),
    'Add Many Prog Patrimoine Info Success': props<{ idProgEtatTechnique: number; idProgPatrimoineInfos: number[] }>(),
    'Delete Many Prog Patrimoine Info Success': props<{ idProgPatrimoineInfos: number[]; idProgEtatTechniques: number[] }>(),
    'Add Societe Success': props<{ idProgEtatTechnique: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgEtatTechniques: number[] }>()
  }
});
