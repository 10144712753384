import { RepositoryService } from '@get/services/repository';
import { BoardState } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedBoardStateApiService {
  constructor(protected repo: RepositoryService) {}

  public getBoardStates(params?: any): Observable<BoardState[]> {
    return this.repo.getData<BoardState[]>('board-state', params);
  }

  public addBoardState(boardState: Partial<BoardState>): Observable<BoardState> {
    return this.repo.create<BoardState>('board-state', boardState);
  }

  public updateBoardState(boardState: Partial<BoardState>): Observable<BoardState> {
    return this.repo.update('board-state', boardState);
  }

  public deleteBoardState(idBoardState: number): Observable<number> {
    return this.repo.delete('board-state/' + +idBoardState);
  }
}
