import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@get/services/notification';
import { ComposantDbService } from '@get/services/storage';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly composantDbService: ComposantDbService
  ) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error from http error interceptor', error);
        if (
          error.status === 401 &&
          error.error.subCode !== 'E_INVALID_TOKEN' &&
          error.error.subCode !== 'E_USER_WRONG_PASSWORD'
        ) {
          this.notificationService.showError(error.error.message);
        } else if (error.status === 422 && error.error.message) {
          this.notificationService.showError(error.error.message);
        } else if (error.status === 400 && error.error.subCode === 'E_FILE_TYPE') {
          this.notificationService.showError(error.error.message);
        } else if (error.status === 405 && error.error.subCode === 'E_DEPRECATED_ROUTE') {
          window.alert('Votre application ne semble pas être à jour. Nous allons donc rafraîchir la page.');
          window.location.reload();
        } else if (error.status === 404 && error.error.subCode === 'E_OFF_NO_COMPOSANT') {
          this.composantDbService.removeInexistantComposant(error.error.data?.idComposant);
        }
        return throwError(() => error);
      })
    );
  }
}
