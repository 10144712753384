import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteEspaceFamilleState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeEspaceFamilleRelations: string[] = ['societeComposantRattachements','societeEspaces','societes','societePatrimoineHierarchies',];

export const { selectEntities, selectAll } = SocieteEspaceFamilleState.adapter.getSelectors();

export const selectSocieteEspaceFamilleState = createFeatureSelector<SocieteEspaceFamilleState.IState>(SocieteEspaceFamilleState.societeEspaceFamilleFeatureKey);

export const selectIsLoadedSocieteEspaceFamille = createSelector(
  selectSocieteEspaceFamilleState,
  (state: SocieteEspaceFamilleState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteEspaceFamille = createSelector(
  selectSocieteEspaceFamilleState,
  (state: SocieteEspaceFamilleState.IState) => state.isLoading
);

export const selectIsReadySocieteEspaceFamille = createSelector(
  selectSocieteEspaceFamilleState,
  (state: SocieteEspaceFamilleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteEspaceFamille = createSelector(
  selectSocieteEspaceFamilleState,
  (state: SocieteEspaceFamilleState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteEspaceFamillesEntities = createSelector(selectSocieteEspaceFamilleState, selectEntities);

export const selectSocieteEspaceFamillesArray = createSelector(selectSocieteEspaceFamilleState, selectAll);

const societeEspaceFamillesInObject = (societeEspaceFamilles: Dictionary<SocieteEspaceFamilleEntityState>) => ({ societeEspaceFamilles })

const selectSocieteEspaceFamillesEntitiesDictionary = createSelector(selectSocieteEspaceFamillesEntities, societeEspaceFamillesInObject);

const selectAllSocieteEspaceFamillesObject = createSelector(selectSocieteEspaceFamillesEntities, societeEspaceFamilles => {
  return hydrateAll({ societeEspaceFamilles });
});

const selectOneSocieteEspaceFamilleDictionary = (idSocieteEspaceFamille : number) =>
  createSelector(selectSocieteEspaceFamillesEntities, societeEspaceFamilles => ({
    societeEspaceFamilles: { [idSocieteEspaceFamille]: societeEspaceFamilles[idSocieteEspaceFamille] }
  }));

const selectOneSocieteEspaceFamilleDictionaryWithoutChild = (idSocieteEspaceFamille : number) =>
  createSelector(selectSocieteEspaceFamillesEntities, societeEspaceFamilles => ({
    societeEspaceFamille: societeEspaceFamilles[idSocieteEspaceFamille]
  }));

const selectAllSocieteEspaceFamillesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteEspaceFamilles(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteEspaceFamille>(
      schema,
      selectAllSocieteEspaceFamillesSelectors,
      selectSocieteEspaceFamillesEntitiesDictionary,
      getRelationSelectors,
      societeEspaceFamilleRelations,
      hydrateAll,
      'societeEspaceFamille'
    );
  } else {
    return selectAllSocieteEspaceFamillesObject;
  }
}

export function selectAllSocieteEspaceFamillesDictionary(
  schema: SelectSchema = {},
  customKey = 'societeEspaceFamilles'
): Selector {
  return createSelector(selectAllSocieteEspaceFamilles(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteEspaceFamilleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeEspaceFamilles.length; i++) {
      res[customKey][result.societeEspaceFamilles[i].idSocieteEspaceFamille] = result.societeEspaceFamilles[i];
    }
    return res;
  });
}

export function selectOneSocieteEspaceFamille(
  schema: SelectSchema = {},
  idSocieteEspaceFamille: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteEspaceFamilleDictionary(idSocieteEspaceFamille)];
  selectors.push(...getRelationSelectors(schema, societeEspaceFamilleRelations, 'societeEspaceFamille'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteEspaceFamilleDictionaryWithoutChild(idSocieteEspaceFamille);
  }
}

interface hydrateArgs {
  societeEspaceFamilles: Dictionary<SocieteEspaceFamilleEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
  societeComposantRattachements?: Dictionary<SocieteComposantRattachementEntityState>;
  societeEspaces?: Dictionary<SocieteEspaceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeEspaceFamilles: (SocieteEspaceFamille | null)[] } {
  const {
    societeEspaceFamilles,
    societes,
    societePatrimoineHierarchies,
    societeComposantRattachements,
    societeEspaces
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeEspaceFamilles: Object.keys(societeEspaceFamilles).map(idSocieteEspaceFamille =>
      hydrate(
        societeEspaceFamilles[idSocieteEspaceFamille] as SocieteEspaceFamilleEntityState,
        societes,
        societePatrimoineHierarchies,
        societeComposantRattachements,
        societeEspaces
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeEspaceFamille: SocieteEspaceFamilleEntityState | null } {
  const {
    societeEspaceFamilles,
    societes,
    societePatrimoineHierarchies,
    societeComposantRattachements,
    societeEspaces
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeEspaceFamille = Object.values(societeEspaceFamilles)[0];
  return {
    societeEspaceFamille: hydrate(
      societeEspaceFamille as SocieteEspaceFamilleEntityState,
      societes,
      societePatrimoineHierarchies,
      societeComposantRattachements,
      societeEspaces
    )
  };
}

function hydrate(
  societeEspaceFamille: SocieteEspaceFamilleEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
  societeComposantRattachementEntities?: Dictionary<SocieteComposantRattachementEntityState>,
  societeEspaceEntities?: Dictionary<SocieteEspaceEntityState>,
): SocieteEspaceFamille | null {
  if (!societeEspaceFamille) {
    return null;
  }

  const societeEspaceFamilleHydrated: SocieteEspaceFamilleEntityState = { ...societeEspaceFamille };
  if (societeEntities) {
    societeEspaceFamilleHydrated.societe = societeEntities[societeEspaceFamille.societe as number] as Societe;
  } else {
    delete societeEspaceFamilleHydrated.societe;
  }
  if (societePatrimoineHierarchieEntities) {
    societeEspaceFamilleHydrated.societePatrimoineHierarchie = societePatrimoineHierarchieEntities[societeEspaceFamille.societePatrimoineHierarchie as number] as SocietePatrimoineHierarchie;
  } else {
    delete societeEspaceFamilleHydrated.societePatrimoineHierarchie;
  }

  if (societeComposantRattachementEntities) {
    societeEspaceFamilleHydrated.societeComposantRattachements = ((societeEspaceFamilleHydrated.societeComposantRattachements as number[]) || []).map(
      id => societeComposantRattachementEntities[id]
    ) as SocieteComposantRattachement[];
  } else {
    delete societeEspaceFamilleHydrated.societeComposantRattachements;
  }

  if (societeEspaceEntities) {
    societeEspaceFamilleHydrated.societeEspaces = ((societeEspaceFamilleHydrated.societeEspaces as number[]) || []).map(
      id => societeEspaceEntities[id]
    ) as SocieteEspace[];
  } else {
    delete societeEspaceFamilleHydrated.societeEspaces;
  }

  return societeEspaceFamilleHydrated as SocieteEspaceFamille;
}
