import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteComposantFamille } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteComposantFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamilleSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteComposantFamilleService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteComposantFamilleSelectors.selectIsLoadedSocieteComposantFamille));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteComposantFamilleSelectors.selectIsLoadingSocieteComposantFamille));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteComposantFamilleSelectors.selectIsReadyAndLoadedSocieteComposantFamille as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteComposantFamilles(schema: SelectSchema = {}): Observable<SocieteComposantFamille[]> {
    return this.store$.pipe(select(SocieteComposantFamilleSelectors.selectAllSocieteComposantFamilles(schema))).pipe(
      switchMap(({ societeComposantFamilles }: { societeComposantFamilles: SocieteComposantFamille[] }) => {
        return this.getReady(schema).pipe(map(() => societeComposantFamilles));
      })
    );
  }

  public selectOneSocieteComposantFamille(
    idSocieteComposantFamille: number,
    schema: SelectSchema = {}
  ): Observable<SocieteComposantFamille> {
    return this.store$
      .pipe(select(SocieteComposantFamilleSelectors.selectOneSocieteComposantFamille(schema, idSocieteComposantFamille)))
      .pipe(
        switchMap(({ societeComposantFamille }: { societeComposantFamille: SocieteComposantFamille }) => {
          return this.getReady(schema).pipe(map(() => societeComposantFamille));
        })
      );
  }

  public getManySocieteComposantFamilles(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteComposantFamille[]> {
    this.store$.dispatch(SocieteComposantFamilleGeneratedActions.getManySocieteComposantFamilles({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert,
        SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure
      );
    }
  }

  public upsertOneSocieteComposantFamille(
    societeComposantFamille: Partial<SocieteComposantFamille>,
    ids: SocieteComposantFamilleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteComposantFamille> {
    this.store$.dispatch(SocieteComposantFamilleGeneratedActions.upsertOneSocieteComposantFamille({ societeComposantFamille, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert,
        SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure,
        true
      );
    }
  }

  public deleteOneSocieteComposantFamille(
    idSocieteComposantFamille: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamille({ idSocieteComposantFamille }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamilleSuccess,
        SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure
      );
    }
  }
}
