import { ProgComposantGroupeState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progComposantGroupeReducersGeneratedFunctions } from './prog-composant-groupe-generated.reducer';

const progComposantGroupeReducersFunctions = [...progComposantGroupeReducersGeneratedFunctions];

const progComposantGroupeReducer = createReducer(
  ProgComposantGroupeState.initialState,
  ...progComposantGroupeReducersFunctions
);

export function reducer(state: ProgComposantGroupeState.IState | undefined, action: Action) {
  return progComposantGroupeReducer(state, action);
}
