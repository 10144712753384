import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ComposantState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const composantRelations: string[] = ['valeurComposants','valeurs','espaces','societeComposants','users',];

export const { selectEntities, selectAll } = ComposantState.adapter.getSelectors();

export const selectComposantState = createFeatureSelector<ComposantState.IState>(ComposantState.composantFeatureKey);

export const selectIsLoadedComposant = createSelector(
  selectComposantState,
  (state: ComposantState.IState) => state.isLoaded
);

export const selectIsLoadingComposant = createSelector(
  selectComposantState,
  (state: ComposantState.IState) => state.isLoading
);

export const selectIsReadyComposant = createSelector(
  selectComposantState,
  (state: ComposantState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedComposant = createSelector(
  selectComposantState,
  (state: ComposantState.IState) => state.isLoaded && !state.isLoading
);

export const selectComposantsEntities = createSelector(selectComposantState, selectEntities);

export const selectComposantsArray = createSelector(selectComposantState, selectAll);

const composantsInObject = (composants: Dictionary<ComposantEntityState>) => ({ composants })

const selectComposantsEntitiesDictionary = createSelector(selectComposantsEntities, composantsInObject);

const selectAllComposantsObject = createSelector(selectComposantsEntities, composants => {
  return hydrateAll({ composants });
});

const selectOneComposantDictionary = (idComposant : number) =>
  createSelector(selectComposantsEntities, composants => ({
    composants: { [idComposant]: composants[idComposant] }
  }));

const selectOneComposantDictionaryWithoutChild = (idComposant : number) =>
  createSelector(selectComposantsEntities, composants => ({
    composant: composants[idComposant]
  }));

const selectAllComposantsSelectors: Dictionary<Selector> = {};
export function selectAllComposants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Composant>(
      schema,
      selectAllComposantsSelectors,
      selectComposantsEntitiesDictionary,
      getRelationSelectors,
      composantRelations,
      hydrateAll,
      'composant'
    );
  } else {
    return selectAllComposantsObject;
  }
}

export function selectAllComposantsDictionary(
  schema: SelectSchema = {},
  customKey = 'composants'
): Selector {
  return createSelector(selectAllComposants(schema), result => {
    const res = { [customKey]: {} as Dictionary<ComposantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.composants.length; i++) {
      res[customKey][result.composants[i].idComposant] = result.composants[i];
    }
    return res;
  });
}

export function selectOneComposant(
  schema: SelectSchema = {},
  idComposant: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneComposantDictionary(idComposant)];
  selectors.push(...getRelationSelectors(schema, composantRelations, 'composant'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneComposantDictionaryWithoutChild(idComposant);
  }
}

interface hydrateArgs {
  composants: Dictionary<ComposantEntityState>;
  espaces?: Dictionary<EspaceEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  users?: Dictionary<UserEntityState>;
  valeurComposants?: Dictionary<ValeurComposantEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { composants: (Composant | null)[] } {
  const {
    composants,
    espaces,
    societeComposants,
    users,
    valeurComposants,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    composants: Object.keys(composants).map(idComposant =>
      hydrate(
        composants[idComposant] as ComposantEntityState,
        espaces,
        societeComposants,
        users,
        valeurComposants,
        valeurs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { composant: ComposantEntityState | null } {
  const {
    composants,
    espaces,
    societeComposants,
    users,
    valeurComposants,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const composant = Object.values(composants)[0];
  return {
    composant: hydrate(
      composant as ComposantEntityState,
      espaces,
      societeComposants,
      users,
      valeurComposants,
      valeurs
    )
  };
}

function hydrate(
  composant: ComposantEntityState,
  espaceEntities?: Dictionary<EspaceEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  valeurComposantEntities?: Dictionary<ValeurComposantEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
): Composant | null {
  if (!composant) {
    return null;
  }

  const composantHydrated: ComposantEntityState = { ...composant };
  if (espaceEntities) {
    composantHydrated.espace = espaceEntities[composant.espace as number] as Espace;
  } else {
    delete composantHydrated.espace;
  }
  if (societeComposantEntities) {
    composantHydrated.societeComposant = societeComposantEntities[composant.societeComposant as number] as SocieteComposant;
  } else {
    delete composantHydrated.societeComposant;
  }
  if (userEntities) {
    composantHydrated.user = userEntities[composant.user as number] as User;
  } else {
    delete composantHydrated.user;
  }

  if (valeurComposantEntities) {
    composantHydrated.valeurComposants = ((composantHydrated.valeurComposants as number[]) || []).map(
      id => valeurComposantEntities[id]
    ) as ValeurComposant[];
  } else {
    delete composantHydrated.valeurComposants;
  }

  if (valeurEntities) {
    composantHydrated.valeurs = ((composantHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete composantHydrated.valeurs;
  }

  return composantHydrated as Composant;
}
