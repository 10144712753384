import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TypeModalEnum } from '@enums';

@Component({
  selector: 'get-information',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  public title: string;
  public question?: string;
  public questions?: string[];
  public isQuestionArray: boolean;
  public theme: TypeModalEnum = TypeModalEnum.information;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      question: string | string[];
      theme: TypeModalEnum;
    }
  ) {
    this.title = data.title;
    if (Array.isArray(data.question)) {
      this.questions = data.question;
      this.isQuestionArray = true;
    } else {
      this.question = data.question;
      this.isQuestionArray = false;
    }
    this.theme = data.theme ?? this.theme;
  }
}
