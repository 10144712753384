import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { ComposantAttenduRelationsIds } from '@get/store/ids-interfaces';

export const ComposantAttenduGeneratedActions = createActionGroup({
  source: 'Composant Attendu Generated',
  events: {
    'Upsert One Composant Attendu': props<{ composantAttendu: Partial<ComposantAttendu>; ids?: ComposantAttenduRelationsIds; }>(),
    'Upsert Many Composant Attendus': props<{ composantAttendus: Partial<ComposantAttendu>[]; ids?: ComposantAttenduRelationsIds; }>(),
    'Upsert Many Composant Attendus Success': props<{ composantAttendus: ComposantAttenduEntityState[] }>(),
    'Delete One Composant Attendu Success': props<{ idComposantAttendu: number }>(),
    'Normalize Many Composant Attendus After Upsert': props<{ composantAttendus: ComposantAttenduEntityState[] }>(),
    'Composant Attendus Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Composant Attendus': emptyProps(),
    'Add Societe Composant Success': props<{ idComposantAttendu: number; idSocieteComposant: number }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idComposantAttendus: number[] }>(),
    'Add Patrimoine Success': props<{ idComposantAttendu: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idComposantAttendus: number[] }>(),
    'Add User Success': props<{ idComposantAttendu: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idComposantAttendus: number[] }>()
  }
});
