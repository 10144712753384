import {
  DynamicType,
  GridError,
  Patrimoine,
  PatrimoineDataRow,
  SocieteTerritoire,
  SocieteTerritoireWithChildren,
  ValidationErrorInterface
} from '@get/api-interfaces';

export function calculateAvancementForPatrimoines(patrimoines: Partial<Patrimoine>[]): Partial<Patrimoine>[] {
  return patrimoines.map((patrimoine): Partial<Patrimoine> => {
    const summedUpRatio = patrimoine.composantAttendus?.reduce(
      (acc, composantAttendu) => {
        return {
          ratioSum:
            acc.ratioSum +
            (composantAttendu.nbReponsesAttendu === 0
              ? 0
              : (composantAttendu.nbReponsesRempli ?? 0) / composantAttendu.nbReponsesAttendu),
          nbRatios: acc.nbRatios + 1
        };
      },
      {
        ratioSum: 0,
        nbRatios: 0
      }
    );
    let txCompletion = 100;
    if (patrimoine?.composantAttendus?.length) {
      let totalValeursRempli = 0;
      let totalValeursAttendu = 0;
      for (let i = 0; i < patrimoine?.composantAttendus?.length; i++) {
        totalValeursRempli += patrimoine?.composantAttendus[i]?.nbValeursRempli ?? 0;
        totalValeursAttendu += patrimoine?.composantAttendus[i]?.nbValeursAttendu ?? 0;
      }
      if (totalValeursAttendu === 0) {
        txCompletion = 0;
      } else {
        txCompletion = (totalValeursRempli / totalValeursAttendu) * 100;
      }
    } else {
      txCompletion = 0;
    }
    return {
      idPatrimoine: patrimoine.idPatrimoine,
      idSocietePatrimoineHierarchie: patrimoine.idSocietePatrimoineHierarchie,
      idSociete: patrimoine.idSociete,
      txCouverture:
        !summedUpRatio || summedUpRatio?.nbRatios === 0 ? 0 : (summedUpRatio.ratioSum / summedUpRatio.nbRatios) * 100,
      txCompletion
    };
  });
}

export function fillDownwardsTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoireWithChildren>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj?.[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  treeArray?.push(idSocieteTerritoire);
  if (elem?.children?.length) {
    for (let i = 0; i < elem?.children?.length; i++) {
      treeArray = fillDownwardsTree(elem?.children[i]?.idSocieteTerritoire, elementObj, treeArray);
    }
  }
  return treeArray;
}

export function fillUpwardsTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoire>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  treeArray.push(idSocieteTerritoire);
  if (elem?.idTerritoireParent) {
    return fillUpwardsTree(elem?.idTerritoireParent, elementObj, treeArray);
  }
  return treeArray;
}

export function fillUpwardsEmptyTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoireWithChildren>,
  patrimoineTerritoires: DynamicType<boolean>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  const parent = elementObj[elem.idTerritoireParent];
  if (parent) {
    const doAnyBrotherHaveLink = !parent.children
      ?.filter(el => el.idSocieteTerritoire !== elem.idSocieteTerritoire)
      ?.map(el => el.idSocieteTerritoire)
      ?.every(el => !patrimoineTerritoires[el]);
    if (!parent?.toutPatrimoine && !doAnyBrotherHaveLink) {
      treeArray.push(elem.idTerritoireParent);
      return fillUpwardsEmptyTree(elem.idTerritoireParent, elementObj, patrimoineTerritoires, treeArray);
    }
  }
  return treeArray;
}

export function validatePatrimoineProperties(
  patrimoineToCreate: Partial<Patrimoine>
): ValidationErrorInterface<Patrimoine>[] {
  const errors: ValidationErrorInterface<Patrimoine>[] = [];
  if (!patrimoineToCreate.reference) {
    errors.push({
      properties: ['reference'],
      message: 'You need a reference to do this action'
    });
  }
  return errors;
}

export function mergePatrimoineAndGridErrors(patrimoines: Patrimoine[], gridErrors: GridError[]): PatrimoineDataRow[] {
  const patrimoineDataRows: PatrimoineDataRow[] = [...(patrimoines as PatrimoineDataRow[])];
  gridErrors?.forEach(gridErrorPatrimoine => {
    if (gridErrorPatrimoine.error.idPatrimoine > 0) {
      const index = patrimoineDataRows.findIndex(
        patrimoine => patrimoine.idPatrimoine === gridErrorPatrimoine.error.idPatrimoine
      );

      patrimoineDataRows[index] = { ...gridErrorPatrimoine.error };
    } else {
      patrimoineDataRows.push({ ...gridErrorPatrimoine.error });
    }
  });
  return patrimoineDataRows;
}
