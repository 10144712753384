import { RepositoryService } from '@get/services/repository';
import { UserPatrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserPatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public addUserPatrimoine(userPatrimoine: Partial<UserPatrimoine>): Observable<UserPatrimoine> {
    return this.repo.create<UserPatrimoine>('user-patrimoine', userPatrimoine);
  }

  public updateUserPatrimoine(userPatrimoine: Partial<UserPatrimoine>): Observable<UserPatrimoine> {
    return this.repo.update('user-patrimoine', userPatrimoine);
  }
}
