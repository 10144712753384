import { RepositoryService } from '@get/services/repository';
import { ProgComposantGroupe } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgComposantGroupeApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgComposantGroupe(progComposantGroupe: Partial<ProgComposantGroupe>): Observable<ProgComposantGroupe> {
    return this.repo.create<ProgComposantGroupe>('prog-composant-groupe', progComposantGroupe);
  }

  public updateProgComposantGroupe(progComposantGroupe: Partial<ProgComposantGroupe>): Observable<ProgComposantGroupe> {
    return this.repo.update('prog-composant-groupe', progComposantGroupe);
  }

  public deleteProgComposantGroupe(idProgComposantGroupe: number): Observable<number> {
    return this.repo.delete('prog-composant-groupe/' + +idProgComposantGroupe);
  }
}
