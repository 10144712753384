import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgEtatTechnique, ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { ProgPatrimoineInfo, ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgEtatTechniqueState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progEtatTechniqueRelations: string[] = ['progPatrimoineInfos','societes',];

export const { selectEntities, selectAll } = ProgEtatTechniqueState.adapter.getSelectors();

export const selectProgEtatTechniqueState = createFeatureSelector<ProgEtatTechniqueState.IState>(ProgEtatTechniqueState.progEtatTechniqueFeatureKey);

export const selectIsLoadedProgEtatTechnique = createSelector(
  selectProgEtatTechniqueState,
  (state: ProgEtatTechniqueState.IState) => state.isLoaded
);

export const selectIsLoadingProgEtatTechnique = createSelector(
  selectProgEtatTechniqueState,
  (state: ProgEtatTechniqueState.IState) => state.isLoading
);

export const selectIsReadyProgEtatTechnique = createSelector(
  selectProgEtatTechniqueState,
  (state: ProgEtatTechniqueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgEtatTechnique = createSelector(
  selectProgEtatTechniqueState,
  (state: ProgEtatTechniqueState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgEtatTechniquesEntities = createSelector(selectProgEtatTechniqueState, selectEntities);

export const selectProgEtatTechniquesArray = createSelector(selectProgEtatTechniqueState, selectAll);

const progEtatTechniquesInObject = (progEtatTechniques: Dictionary<ProgEtatTechniqueEntityState>) => ({ progEtatTechniques })

const selectProgEtatTechniquesEntitiesDictionary = createSelector(selectProgEtatTechniquesEntities, progEtatTechniquesInObject);

const selectAllProgEtatTechniquesObject = createSelector(selectProgEtatTechniquesEntities, progEtatTechniques => {
  return hydrateAll({ progEtatTechniques });
});

const selectOneProgEtatTechniqueDictionary = (idProgEtatTechnique : number) =>
  createSelector(selectProgEtatTechniquesEntities, progEtatTechniques => ({
    progEtatTechniques: { [idProgEtatTechnique]: progEtatTechniques[idProgEtatTechnique] }
  }));

const selectOneProgEtatTechniqueDictionaryWithoutChild = (idProgEtatTechnique : number) =>
  createSelector(selectProgEtatTechniquesEntities, progEtatTechniques => ({
    progEtatTechnique: progEtatTechniques[idProgEtatTechnique]
  }));

const selectAllProgEtatTechniquesSelectors: Dictionary<Selector> = {};
export function selectAllProgEtatTechniques(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgEtatTechnique>(
      schema,
      selectAllProgEtatTechniquesSelectors,
      selectProgEtatTechniquesEntitiesDictionary,
      getRelationSelectors,
      progEtatTechniqueRelations,
      hydrateAll,
      'progEtatTechnique'
    );
  } else {
    return selectAllProgEtatTechniquesObject;
  }
}

export function selectAllProgEtatTechniquesDictionary(
  schema: SelectSchema = {},
  customKey = 'progEtatTechniques'
): Selector {
  return createSelector(selectAllProgEtatTechniques(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgEtatTechniqueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progEtatTechniques.length; i++) {
      res[customKey][result.progEtatTechniques[i].idProgEtatTechnique] = result.progEtatTechniques[i];
    }
    return res;
  });
}

export function selectOneProgEtatTechnique(
  schema: SelectSchema = {},
  idProgEtatTechnique: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgEtatTechniqueDictionary(idProgEtatTechnique)];
  selectors.push(...getRelationSelectors(schema, progEtatTechniqueRelations, 'progEtatTechnique'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgEtatTechniqueDictionaryWithoutChild(idProgEtatTechnique);
  }
}

interface hydrateArgs {
  progEtatTechniques: Dictionary<ProgEtatTechniqueEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progPatrimoineInfos?: Dictionary<ProgPatrimoineInfoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progEtatTechniques: (ProgEtatTechnique | null)[] } {
  const {
    progEtatTechniques,
    societes,
    progPatrimoineInfos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progEtatTechniques: Object.keys(progEtatTechniques).map(idProgEtatTechnique =>
      hydrate(
        progEtatTechniques[idProgEtatTechnique] as ProgEtatTechniqueEntityState,
        societes,
        progPatrimoineInfos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progEtatTechnique: ProgEtatTechniqueEntityState | null } {
  const {
    progEtatTechniques,
    societes,
    progPatrimoineInfos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progEtatTechnique = Object.values(progEtatTechniques)[0];
  return {
    progEtatTechnique: hydrate(
      progEtatTechnique as ProgEtatTechniqueEntityState,
      societes,
      progPatrimoineInfos
    )
  };
}

function hydrate(
  progEtatTechnique: ProgEtatTechniqueEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progPatrimoineInfoEntities?: Dictionary<ProgPatrimoineInfoEntityState>,
): ProgEtatTechnique | null {
  if (!progEtatTechnique) {
    return null;
  }

  const progEtatTechniqueHydrated: ProgEtatTechniqueEntityState = { ...progEtatTechnique };
  if (societeEntities) {
    progEtatTechniqueHydrated.societe = societeEntities[progEtatTechnique.societe as number] as Societe;
  } else {
    delete progEtatTechniqueHydrated.societe;
  }

  if (progPatrimoineInfoEntities) {
    progEtatTechniqueHydrated.progPatrimoineInfos = ((progEtatTechniqueHydrated.progPatrimoineInfos as number[]) || []).map(
      id => progPatrimoineInfoEntities[id]
    ) as ProgPatrimoineInfo[];
  } else {
    delete progEtatTechniqueHydrated.progPatrimoineInfos;
  }

  return progEtatTechniqueHydrated as ProgEtatTechnique;
}
