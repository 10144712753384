import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocietePatrimoineHierarchieAncetre, SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocietePatrimoineHierarchieAncetreState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societePatrimoineHierarchieAncetreRelations: string[] = ['hierarchieAncetre','hierarchieDescendant',];

export const { selectEntities, selectAll } = SocietePatrimoineHierarchieAncetreState.adapter.getSelectors();

export const selectSocietePatrimoineHierarchieAncetreState = createFeatureSelector<SocietePatrimoineHierarchieAncetreState.IState>(SocietePatrimoineHierarchieAncetreState.societePatrimoineHierarchieAncetreFeatureKey);

export const selectIsLoadedSocietePatrimoineHierarchieAncetre = createSelector(
  selectSocietePatrimoineHierarchieAncetreState,
  (state: SocietePatrimoineHierarchieAncetreState.IState) => state.isLoaded
);

export const selectIsLoadingSocietePatrimoineHierarchieAncetre = createSelector(
  selectSocietePatrimoineHierarchieAncetreState,
  (state: SocietePatrimoineHierarchieAncetreState.IState) => state.isLoading
);

export const selectIsReadySocietePatrimoineHierarchieAncetre = createSelector(
  selectSocietePatrimoineHierarchieAncetreState,
  (state: SocietePatrimoineHierarchieAncetreState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocietePatrimoineHierarchieAncetre = createSelector(
  selectSocietePatrimoineHierarchieAncetreState,
  (state: SocietePatrimoineHierarchieAncetreState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocietePatrimoineHierarchieAncetresEntities = createSelector(selectSocietePatrimoineHierarchieAncetreState, selectEntities);

export const selectSocietePatrimoineHierarchieAncetresArray = createSelector(selectSocietePatrimoineHierarchieAncetreState, selectAll);

const societePatrimoineHierarchieAncetresInObject = (societePatrimoineHierarchieAncetres: Dictionary<SocietePatrimoineHierarchieAncetreEntityState>) => ({ societePatrimoineHierarchieAncetres })

const selectSocietePatrimoineHierarchieAncetresEntitiesDictionary = createSelector(selectSocietePatrimoineHierarchieAncetresEntities, societePatrimoineHierarchieAncetresInObject);

const selectAllSocietePatrimoineHierarchieAncetresObject = createSelector(selectSocietePatrimoineHierarchieAncetresEntities, societePatrimoineHierarchieAncetres => {
  return hydrateAll({ societePatrimoineHierarchieAncetres });
});

const selectOneSocietePatrimoineHierarchieAncetreDictionary = (idSocietePatrimoineHierarchieAncetre : number) =>
  createSelector(selectSocietePatrimoineHierarchieAncetresEntities, societePatrimoineHierarchieAncetres => ({
    societePatrimoineHierarchieAncetres: { [idSocietePatrimoineHierarchieAncetre]: societePatrimoineHierarchieAncetres[idSocietePatrimoineHierarchieAncetre] }
  }));

const selectOneSocietePatrimoineHierarchieAncetreDictionaryWithoutChild = (idSocietePatrimoineHierarchieAncetre : number) =>
  createSelector(selectSocietePatrimoineHierarchieAncetresEntities, societePatrimoineHierarchieAncetres => ({
    societePatrimoineHierarchieAncetre: societePatrimoineHierarchieAncetres[idSocietePatrimoineHierarchieAncetre]
  }));

const selectAllSocietePatrimoineHierarchieAncetresSelectors: Dictionary<Selector> = {};
export function selectAllSocietePatrimoineHierarchieAncetres(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocietePatrimoineHierarchieAncetre>(
      schema,
      selectAllSocietePatrimoineHierarchieAncetresSelectors,
      selectSocietePatrimoineHierarchieAncetresEntitiesDictionary,
      getRelationSelectors,
      societePatrimoineHierarchieAncetreRelations,
      hydrateAll,
      'societePatrimoineHierarchieAncetre'
    );
  } else {
    return selectAllSocietePatrimoineHierarchieAncetresObject;
  }
}

export function selectAllSocietePatrimoineHierarchieAncetresDictionary(
  schema: SelectSchema = {},
  customKey = 'societePatrimoineHierarchieAncetres'
): Selector {
  return createSelector(selectAllSocietePatrimoineHierarchieAncetres(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocietePatrimoineHierarchieAncetreEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societePatrimoineHierarchieAncetres.length; i++) {
      res[customKey][result.societePatrimoineHierarchieAncetres[i].idSocietePatrimoineHierarchieAncetre] = result.societePatrimoineHierarchieAncetres[i];
    }
    return res;
  });
}

export function selectOneSocietePatrimoineHierarchieAncetre(
  schema: SelectSchema = {},
  idSocietePatrimoineHierarchieAncetre: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocietePatrimoineHierarchieAncetreDictionary(idSocietePatrimoineHierarchieAncetre)];
  selectors.push(...getRelationSelectors(schema, societePatrimoineHierarchieAncetreRelations, 'societePatrimoineHierarchieAncetre'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocietePatrimoineHierarchieAncetreDictionaryWithoutChild(idSocietePatrimoineHierarchieAncetre);
  }
}

interface hydrateArgs {
  societePatrimoineHierarchieAncetres: Dictionary<SocietePatrimoineHierarchieAncetreEntityState>;
  hierarchieAncetre?: Dictionary<SocietePatrimoineHierarchieEntityState>;
  hierarchieDescendant?: Dictionary<SocietePatrimoineHierarchieEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societePatrimoineHierarchieAncetres: (SocietePatrimoineHierarchieAncetre | null)[] } {
  const {
    societePatrimoineHierarchieAncetres,
    hierarchieAncetre,
    hierarchieDescendant
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societePatrimoineHierarchieAncetres: Object.keys(societePatrimoineHierarchieAncetres).map(idSocietePatrimoineHierarchieAncetre =>
      hydrate(
        societePatrimoineHierarchieAncetres[idSocietePatrimoineHierarchieAncetre] as SocietePatrimoineHierarchieAncetreEntityState,
        hierarchieAncetre,
        hierarchieDescendant
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState | null } {
  const {
    societePatrimoineHierarchieAncetres,
    hierarchieAncetre,
    hierarchieDescendant
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societePatrimoineHierarchieAncetre = Object.values(societePatrimoineHierarchieAncetres)[0];
  return {
    societePatrimoineHierarchieAncetre: hydrate(
      societePatrimoineHierarchieAncetre as SocietePatrimoineHierarchieAncetreEntityState,
      hierarchieAncetre,
      hierarchieDescendant
    )
  };
}

function hydrate(
  societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState,
  hierarchieAncetreEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
  hierarchieDescendantEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
): SocietePatrimoineHierarchieAncetre | null {
  if (!societePatrimoineHierarchieAncetre) {
    return null;
  }

  const societePatrimoineHierarchieAncetreHydrated: SocietePatrimoineHierarchieAncetreEntityState = { ...societePatrimoineHierarchieAncetre };
  if (hierarchieAncetreEntities) {
    societePatrimoineHierarchieAncetreHydrated.hierarchieAncetre = hierarchieAncetreEntities[societePatrimoineHierarchieAncetre.hierarchieAncetre as number] as SocietePatrimoineHierarchie;
  } else {
    delete societePatrimoineHierarchieAncetreHydrated.hierarchieAncetre;
  }
  if (hierarchieDescendantEntities) {
    societePatrimoineHierarchieAncetreHydrated.hierarchieDescendant = hierarchieDescendantEntities[societePatrimoineHierarchieAncetre.hierarchieDescendant as number] as SocietePatrimoineHierarchie;
  } else {
    delete societePatrimoineHierarchieAncetreHydrated.hierarchieDescendant;
  }

  return societePatrimoineHierarchieAncetreHydrated as SocietePatrimoineHierarchieAncetre;
}
