import { ModulePathKeyEnum } from '@enums';

function getAppArrayUrl(path: ModulePathKeyEnum): string[] {
  if (!ModulePathKeyEnum[path]) {
    throw new Error(`${path} path inexistant`);
  }
  return ['app', path];
}

export function getAppUrl(path?: ModulePathKeyEnum | string): string {
  if (path) {
    if (path.includes('/')) {
      const startPath: ModulePathKeyEnum = path.split('/')[0] as ModulePathKeyEnum;
      return getAppArrayUrl(startPath).concat(path.split('/').slice(1)).join('/');
    }
    return getAppArrayUrl(path as ModulePathKeyEnum).join('/');
  }
  return 'app';
}
