import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BoardState, BoardStateEntityState } from '@get/api-interfaces';
import { BoardStateRelationsIds } from '@get/store/ids-interfaces';

export const BoardStateGeneratedActions = createActionGroup({
  source: 'Board State Generated',
  events: {
    'Get Many Board States': props<{ params: any }>(),
    'Upsert One Board State': props<{ boardState: Partial<BoardState>; ids?: BoardStateRelationsIds; }>(),
    'Upsert Many Board States': props<{ boardStates: Partial<BoardState>[]; ids?: BoardStateRelationsIds; }>(),
    'Upsert Many Board States Success': props<{ boardStates: BoardStateEntityState[] }>(),
    'Delete One Board State': props<{ idBoardState: number }>(),
    'Delete One Board State Success': props<{ idBoardState: number }>(),
    'Normalize Many Board States After Upsert': props<{ boardStates: BoardStateEntityState[] }>(),
    'Board States Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Board States': emptyProps(),
    'Add User Success': props<{ idBoardState: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idBoardStates: number[] }>()
  }
});
