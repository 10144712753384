import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ValeurRelationsIds } from '@get/store/ids-interfaces';

export const ValeurGeneratedActions = createActionGroup({
  source: 'Valeur Generated',
  events: {
    'Upsert One Valeur': props<{ valeur: Partial<Valeur>; ids?: ValeurRelationsIds; }>(),
    'Upsert Many Valeurs': props<{ valeurs: Partial<Valeur>[]; ids?: ValeurRelationsIds; }>(),
    'Upsert Many Valeurs Success': props<{ valeurs: ValeurEntityState[] }>(),
    'Delete One Valeur Success': props<{ idValeur: number }>(),
    'Normalize Many Valeurs After Upsert': props<{ valeurs: ValeurEntityState[] }>(),
    'Valeurs Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeurs': emptyProps(),
    'Add Many Valeur Composant Success': props<{ idValeur: number; idValeurComposants: number[] }>(),
    'Delete Many Valeur Composant Success': props<{ idValeurComposants: number[]; idValeurs: number[] }>(),
    'Add Many Composant Success': props<{ idValeur: number; idComposants: number[] }>(),
    'Delete Many Composant Success': props<{ idComposants: number[]; idValeurs: number[] }>(),
    'Add Many Valeur Patrimoine Success': props<{ idValeur: number; idValeurPatrimoines: number[] }>(),
    'Delete Many Valeur Patrimoine Success': props<{ idValeurPatrimoines: number[]; idValeurs: number[] }>(),
    'Add Many Patrimoine Success': props<{ idValeur: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idValeurs: number[] }>(),
    'Add Many Valeur Fichier Success': props<{ idValeur: number; idValeurFichiers: number[] }>(),
    'Delete Many Valeur Fichier Success': props<{ idValeurFichiers: number[]; idValeurs: number[] }>(),
    'Add Many Fichier Success': props<{ idValeur: number; idFichiers: number[] }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idValeurs: number[] }>(),
    'Add Societe Caracteristique Choix Success': props<{ idValeur: number; idSocieteCaracteristiqueChoix: number }>(),
    'Delete Many Societe Caracteristique Choix Success': props<{ idSocieteCaracteristiqueChoices: number[]; idValeurs: number[] }>(),
    'Add Societe Caracteristique Success': props<{ idValeur: number; idSocieteCaracteristique: number }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idValeurs: number[] }>(),
    'Add User Success': props<{ idValeur: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idValeurs: number[] }>()
  }
});
