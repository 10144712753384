import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@get/store/configs/reducers';
import { GeneratedProgComposantService } from './prog-composant-generated.service';
import { Actions } from '@ngrx/effects';
import { ProgComposantApiService } from '@get/store/api-services';

@Injectable({
  providedIn: 'root'
})
export class ProgComposantService extends GeneratedProgComposantService {
  constructor(
    store$: Store<AppState>,
    actions$: Actions,
    private readonly progComposantApiService: ProgComposantApiService
  ) {
    super(store$, actions$);
  }

  public getProgComposantHavingIntervention(idSociete: number): Promise<number[]> {
    return this.progComposantApiService.getProgComposantHavingIntervention({ idSociete });
  }
}
