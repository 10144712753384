import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgComposantGroupe, ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantGroupeRelationsIds } from '@get/store/ids-interfaces';

export const ProgComposantGroupeGeneratedActions = createActionGroup({
  source: 'Prog Composant Groupe Generated',
  events: {
    'Upsert One Prog Composant Groupe': props<{ progComposantGroupe: Partial<ProgComposantGroupe>; ids?: ProgComposantGroupeRelationsIds; }>(),
    'Upsert Many Prog Composant Groupes': props<{ progComposantGroupes: Partial<ProgComposantGroupe>[]; ids?: ProgComposantGroupeRelationsIds; }>(),
    'Upsert Many Prog Composant Groupes Success': props<{ progComposantGroupes: ProgComposantGroupeEntityState[] }>(),
    'Delete One Prog Composant Groupe': props<{ idProgComposantGroupe: number }>(),
    'Delete One Prog Composant Groupe Success': props<{ idProgComposantGroupe: number }>(),
    'Normalize Many Prog Composant Groupes After Upsert': props<{ progComposantGroupes: ProgComposantGroupeEntityState[] }>(),
    'Prog Composant Groupes Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Composant Groupes': emptyProps(),
    'Add Many Prog Composant Sous Groupe Success': props<{ idProgComposantGroupe: number; idProgComposantSousGroupes: number[] }>(),
    'Delete Many Prog Composant Sous Groupe Success': props<{ idProgComposantSousGroupes: number[]; idProgComposantGroupes: number[] }>(),
    'Add Societe Success': props<{ idProgComposantGroupe: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgComposantGroupes: number[] }>()
  }
});
