import { ValeurProgInterventionState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurProgInterventionReducersGeneratedFunctions } from './valeur-prog-intervention-generated.reducer';

const valeurProgInterventionReducersFunctions = [...valeurProgInterventionReducersGeneratedFunctions];

const valeurProgInterventionReducer = createReducer(
  ValeurProgInterventionState.initialState,
  ...valeurProgInterventionReducersFunctions
);

export function reducer(state: ValeurProgInterventionState.IState | undefined, action: Action) {
  return valeurProgInterventionReducer(state, action);
}
