import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UsageCaracteristiqueTemplate, UsageCaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { UsageCaracteristiqueTemplateRelationsIds } from '@get/store/ids-interfaces';

export const UsageCaracteristiqueTemplateGeneratedActions = createActionGroup({
  source: 'Usage Caracteristique Template Generated',
  events: {
    'Upsert One Usage Caracteristique Template': props<{ usageCaracteristiqueTemplate: Partial<UsageCaracteristiqueTemplate>; ids?: UsageCaracteristiqueTemplateRelationsIds; }>(),
    'Upsert Many Usage Caracteristique Templates': props<{ usageCaracteristiqueTemplates: Partial<UsageCaracteristiqueTemplate>[]; ids?: UsageCaracteristiqueTemplateRelationsIds; }>(),
    'Upsert Many Usage Caracteristique Templates Success': props<{ usageCaracteristiqueTemplates: UsageCaracteristiqueTemplateEntityState[] }>(),
    'Delete One Usage Caracteristique Template Success': props<{ idUsageCaracteristiqueTemplate: number }>(),
    'Normalize Many Usage Caracteristique Templates After Upsert': props<{ usageCaracteristiqueTemplates: UsageCaracteristiqueTemplateEntityState[] }>(),
    'Usage Caracteristique Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Usage Caracteristique Templates': emptyProps(),
    'Add Usage Success': props<{ idUsageCaracteristiqueTemplate: number; idUsage: number }>(),
    'Delete Many Usage Success': props<{ idUsages: number[]; idUsageCaracteristiqueTemplates: number[] }>(),
    'Add Caracteristique Template Success': props<{ idUsageCaracteristiqueTemplate: number; idCaracteristiqueTemplate: number }>(),
    'Delete Many Caracteristique Template Success': props<{ idCaracteristiqueTemplates: number[]; idUsageCaracteristiqueTemplates: number[] }>()
  }
});
