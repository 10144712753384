import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocietePatrimoineHierarchieAncetreApiService } from '@get/store/api-services';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocietePatrimoineHierarchieAncetre } from '@get/store/configs/normalization';

export class GeneratedSocietePatrimoineHierarchieAncetreEffects {
  constructor(
    protected actions$: Actions,
    protected societePatrimoineHierarchieAncetreApiService: SocietePatrimoineHierarchieAncetreApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert),
      concatMap(({ societePatrimoineHierarchieAncetres }) => {
        const actions: Action[] = getActionsToNormalizeSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetres, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocietePatrimoineHierarchieAncetre] Normalization After Upsert Success')];
      })
    );
  });
}
