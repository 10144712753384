import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Fichier } from '@get/api-interfaces';
import { FichierApiService } from '@get/store/api-services';
import { FichierGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeFichier } from '@get/store/configs/normalization';

export class GeneratedFichierEffects {
  constructor(
    protected actions$: Actions,
    protected fichierApiService: FichierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyFichiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.getManyFichiers),
      switchMap(({ params }) =>
        this.fichierApiService.getFichiers(params).pipe(
          map((fichiers: Fichier[]) => {
            return FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers });
          }),
          catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
        )
      )
    );
  });

  normalizeManyFichiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.normalizeManyFichiersAfterUpsert),
      concatMap(({ fichiers }) => {
        const actions: Action[] = getActionsToNormalizeFichier(fichiers, StoreActionType.upsert);
        return [getMultiAction(actions, '[Fichier] Normalization After Upsert Success')];
      })
    );
  });
}
