import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgUniteRelationsIds } from '@get/store/ids-interfaces';

export const ProgUniteGeneratedActions = createActionGroup({
  source: 'Prog Unite Generated',
  events: {
    'Upsert One Prog Unite': props<{ progUnite: Partial<ProgUnite>; ids?: ProgUniteRelationsIds; }>(),
    'Upsert Many Prog Unites': props<{ progUnites: Partial<ProgUnite>[]; ids?: ProgUniteRelationsIds; }>(),
    'Upsert Many Prog Unites Success': props<{ progUnites: ProgUniteEntityState[] }>(),
    'Delete One Prog Unite': props<{ idProgUnite: number }>(),
    'Delete One Prog Unite Success': props<{ idProgUnite: number }>(),
    'Normalize Many Prog Unites After Upsert': props<{ progUnites: ProgUniteEntityState[] }>(),
    'Prog Unites Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Unites': emptyProps(),
    'Add Many Prog Biblio Intervention Success': props<{ idProgUnite: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgUnites: number[] }>(),
    'Add Societe Success': props<{ idProgUnite: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgUnites: number[] }>()
  }
});
