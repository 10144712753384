import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgBiblioInterventionScenarioApiService } from '@get/store/api-services';
import { GeneratedProgBiblioInterventionScenarioEffects } from './prog-biblio-intervention-scenario-generated.effects';

@Injectable()
export class ProgBiblioInterventionScenarioEffects extends GeneratedProgBiblioInterventionScenarioEffects {
  constructor(actions$: Actions, progBiblioInterventionScenarioApiService: ProgBiblioInterventionScenarioApiService, store$: Store<AppState>) {
    super(actions$, progBiblioInterventionScenarioApiService, store$);
  }
}
