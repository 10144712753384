import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgPatrimoineInfo, ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { ProgPatrimoineInfoRelationsIds } from '@get/store/ids-interfaces';

export const ProgPatrimoineInfoGeneratedActions = createActionGroup({
  source: 'Prog Patrimoine Info Generated',
  events: {
    'Upsert One Prog Patrimoine Info': props<{ progPatrimoineInfo: Partial<ProgPatrimoineInfo>; ids?: ProgPatrimoineInfoRelationsIds; }>(),
    'Upsert Many Prog Patrimoine Infos': props<{ progPatrimoineInfos: Partial<ProgPatrimoineInfo>[]; ids?: ProgPatrimoineInfoRelationsIds; }>(),
    'Upsert Many Prog Patrimoine Infos Success': props<{ progPatrimoineInfos: ProgPatrimoineInfoEntityState[] }>(),
    'Delete One Prog Patrimoine Info Success': props<{ idProgPatrimoineInfo: number }>(),
    'Normalize Many Prog Patrimoine Infos After Upsert': props<{ progPatrimoineInfos: ProgPatrimoineInfoEntityState[] }>(),
    'Prog Patrimoine Infos Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Patrimoine Infos': emptyProps(),
    'Add Patrimoine Success': props<{ idProgPatrimoineInfo: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idProgPatrimoineInfos: number[] }>(),
    'Add Prog Composant Success': props<{ idProgPatrimoineInfo: number; idProgComposant: number }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idProgPatrimoineInfos: number[] }>(),
    'Add Prog Etat Technique Success': props<{ idProgPatrimoineInfo: number; idProgEtatTechnique: number }>(),
    'Delete Many Prog Etat Technique Success': props<{ idProgEtatTechniques: number[]; idProgPatrimoineInfos: number[] }>()
  }
});
