import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfoState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgPatrimoineInfoEntityState } from '@get/api-interfaces';

export const progPatrimoineInfoReducersGeneratedFunctions: ReducerTypes<ProgPatrimoineInfoState.IState, readonly ActionCreator[]>[] = [
  on(ProgPatrimoineInfoGeneratedActions.upsertOneProgPatrimoineInfo, (state: ProgPatrimoineInfoState.IState) => setLoadingsState(state, true)),

  on(ProgPatrimoineInfoGeneratedActions.upsertManyProgPatrimoineInfosSuccess, (state: ProgPatrimoineInfoState.IState, { progPatrimoineInfos }) =>
    ProgPatrimoineInfoState.adapter.upsertMany(progPatrimoineInfos, setLoadingsState(state, false))
  ),
  on(ProgPatrimoineInfoGeneratedActions.deleteOneProgPatrimoineInfoSuccess, (state: ProgPatrimoineInfoState.IState, { idProgPatrimoineInfo }) =>
    ProgPatrimoineInfoState.adapter.removeOne(idProgPatrimoineInfo, setLoadingsState(state, false))
  ),

  on(ProgPatrimoineInfoGeneratedActions.clearProgPatrimoineInfos, () => ProgPatrimoineInfoState.initialState),

  on(
    ProgPatrimoineInfoGeneratedActions.addPatrimoineSuccess,
    (state: ProgPatrimoineInfoState.IState, { idProgPatrimoineInfo, idPatrimoine }) => {
      if (!state.entities[idProgPatrimoineInfo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgPatrimoineInfo]: {
            ...state.entities[idProgPatrimoineInfo],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    ProgPatrimoineInfoGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ProgPatrimoineInfoState.IState, { idPatrimoines, idProgPatrimoineInfos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgPatrimoineInfos.reduce((entities, idProgPatrimoineInfo) => {
            if (!state.entities[idProgPatrimoineInfo]?.patrimoine) {
              return entities;
            }
            entities[idProgPatrimoineInfo] = {
              ...state.entities[idProgPatrimoineInfo],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idProgPatrimoineInfo]?.patrimoine
              )
                ? undefined
                : state.entities[idProgPatrimoineInfo]?.patrimoine
            } as ProgPatrimoineInfoEntityState;
            return entities;
          }, {} as Dictionary<ProgPatrimoineInfoEntityState>)
        }
      };
    }
  ),

  on(
    ProgPatrimoineInfoGeneratedActions.addProgComposantSuccess,
    (state: ProgPatrimoineInfoState.IState, { idProgPatrimoineInfo, idProgComposant }) => {
      if (!state.entities[idProgPatrimoineInfo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgPatrimoineInfo]: {
            ...state.entities[idProgPatrimoineInfo],
            progComposant: idProgComposant
          }
        }
      };
    }
  ),

  on(
    ProgPatrimoineInfoGeneratedActions.deleteManyProgComposantSuccess,
    (state: ProgPatrimoineInfoState.IState, { idProgComposants, idProgPatrimoineInfos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgPatrimoineInfos.reduce((entities, idProgPatrimoineInfo) => {
            if (!state.entities[idProgPatrimoineInfo]?.progComposant) {
              return entities;
            }
            entities[idProgPatrimoineInfo] = {
              ...state.entities[idProgPatrimoineInfo],
              progComposant: idProgComposants.some(
                (idProgComposant: number) => idProgComposant === state.entities[idProgPatrimoineInfo]?.progComposant
              )
                ? undefined
                : state.entities[idProgPatrimoineInfo]?.progComposant
            } as ProgPatrimoineInfoEntityState;
            return entities;
          }, {} as Dictionary<ProgPatrimoineInfoEntityState>)
        }
      };
    }
  ),

  on(
    ProgPatrimoineInfoGeneratedActions.addProgEtatTechniqueSuccess,
    (state: ProgPatrimoineInfoState.IState, { idProgPatrimoineInfo, idProgEtatTechnique }) => {
      if (!state.entities[idProgPatrimoineInfo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgPatrimoineInfo]: {
            ...state.entities[idProgPatrimoineInfo],
            progEtatTechnique: idProgEtatTechnique
          }
        }
      };
    }
  ),

  on(
    ProgPatrimoineInfoGeneratedActions.deleteManyProgEtatTechniqueSuccess,
    (state: ProgPatrimoineInfoState.IState, { idProgEtatTechniques, idProgPatrimoineInfos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgPatrimoineInfos.reduce((entities, idProgPatrimoineInfo) => {
            if (!state.entities[idProgPatrimoineInfo]?.progEtatTechnique) {
              return entities;
            }
            entities[idProgPatrimoineInfo] = {
              ...state.entities[idProgPatrimoineInfo],
              progEtatTechnique: idProgEtatTechniques.some(
                (idProgEtatTechnique: number) => idProgEtatTechnique === state.entities[idProgPatrimoineInfo]?.progEtatTechnique
              )
                ? undefined
                : state.entities[idProgPatrimoineInfo]?.progEtatTechnique
            } as ProgPatrimoineInfoEntityState;
            return entities;
          }, {} as Dictionary<ProgPatrimoineInfoEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgPatrimoineInfoState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgPatrimoineInfoState.IState {
  return { ...state, isLoaded, isLoading };
}
