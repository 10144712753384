import { RepositoryService } from '@get/services/repository';
import { ProgBiblioIntervention } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgBiblioInterventionApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgBiblioIntervention(progBiblioIntervention: Partial<ProgBiblioIntervention>): Observable<ProgBiblioIntervention> {
    return this.repo.create<ProgBiblioIntervention>('prog-biblio-intervention', progBiblioIntervention);
  }

  public updateProgBiblioIntervention(progBiblioIntervention: Partial<ProgBiblioIntervention>): Observable<ProgBiblioIntervention> {
    return this.repo.update('prog-biblio-intervention', progBiblioIntervention);
  }

  public deleteProgBiblioIntervention(idProgBiblioIntervention: number): Observable<number> {
    return this.repo.delete('prog-biblio-intervention/' + +idProgBiblioIntervention);
  }
}
