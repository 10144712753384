import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteEspaceFamille } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamilleSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteEspaceFamilleService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteEspaceFamilleSelectors.selectIsLoadedSocieteEspaceFamille));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteEspaceFamilleSelectors.selectIsLoadingSocieteEspaceFamille));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteEspaceFamilleSelectors.selectIsReadyAndLoadedSocieteEspaceFamille as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteEspaceFamilles(schema: SelectSchema = {}): Observable<SocieteEspaceFamille[]> {
    return this.store$.pipe(select(SocieteEspaceFamilleSelectors.selectAllSocieteEspaceFamilles(schema))).pipe(
      switchMap(({ societeEspaceFamilles }: { societeEspaceFamilles: SocieteEspaceFamille[] }) => {
        return this.getReady(schema).pipe(map(() => societeEspaceFamilles));
      })
    );
  }

  public selectOneSocieteEspaceFamille(
    idSocieteEspaceFamille: number,
    schema: SelectSchema = {}
  ): Observable<SocieteEspaceFamille> {
    return this.store$
      .pipe(select(SocieteEspaceFamilleSelectors.selectOneSocieteEspaceFamille(schema, idSocieteEspaceFamille)))
      .pipe(
        switchMap(({ societeEspaceFamille }: { societeEspaceFamille: SocieteEspaceFamille }) => {
          return this.getReady(schema).pipe(map(() => societeEspaceFamille));
        })
      );
  }

  public getManySocieteEspaceFamilles(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteEspaceFamille[]> {
    this.store$.dispatch(SocieteEspaceFamilleGeneratedActions.getManySocieteEspaceFamilles({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert,
        SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure
      );
    }
  }

  public upsertOneSocieteEspaceFamille(
    societeEspaceFamille: Partial<SocieteEspaceFamille>,
    ids: SocieteEspaceFamilleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteEspaceFamille> {
    this.store$.dispatch(SocieteEspaceFamilleGeneratedActions.upsertOneSocieteEspaceFamille({ societeEspaceFamille, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert,
        SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure,
        true
      );
    }
  }
}
