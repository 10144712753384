import { UsageCaracteristiqueTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { usageCaracteristiqueTemplateReducersGeneratedFunctions } from './usage-caracteristique-template-generated.reducer';

const usageCaracteristiqueTemplateReducersFunctions = [...usageCaracteristiqueTemplateReducersGeneratedFunctions];

const usageCaracteristiqueTemplateReducer = createReducer(UsageCaracteristiqueTemplateState.initialState, ...usageCaracteristiqueTemplateReducersFunctions);

export function reducer(state: UsageCaracteristiqueTemplateState.IState | undefined, action: Action) {
  return usageCaracteristiqueTemplateReducer(state, action);
}
