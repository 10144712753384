import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgComposantGroupeApiService } from './prog-composant-groupe-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgComposantGroupeApiService extends GeneratedProgComposantGroupeApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
