import { Environment } from '@get/api-interfaces';

const backUrl = 'https://api.integration.get-and-share.com';

export const environment: Environment = {
  integration: true,
  backUrl,
  apiUrl: `${backUrl}/api`,
  iconsUrl: `${backUrl}/icons`,
  imagesUrl: `${backUrl}/images`,
  formbricksEnv: 'clyfn7dup00rdk3xofa3hug2v'
};
