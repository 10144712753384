import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteTerritoireEntityState } from '@get/api-interfaces';

export const societeTerritoireReducersGeneratedFunctions: ReducerTypes<SocieteTerritoireState.IState, readonly ActionCreator[]>[] = [
  on(SocieteTerritoireGeneratedActions.upsertOneSocieteTerritoire, (state: SocieteTerritoireState.IState) => setLoadingsState(state, true)),

  on(SocieteTerritoireGeneratedActions.upsertManySocieteTerritoiresSuccess, (state: SocieteTerritoireState.IState, { societeTerritoires }) =>
    SocieteTerritoireState.adapter.upsertMany(societeTerritoires, setLoadingsState(state, false))
  ),
  on(SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoire, (state: SocieteTerritoireState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoireSuccess, (state: SocieteTerritoireState.IState, { idSocieteTerritoire }) =>
    SocieteTerritoireState.adapter.removeOne(idSocieteTerritoire, setLoadingsState(state, false))
  ),

  on(SocieteTerritoireGeneratedActions.clearSocieteTerritoires, () => SocieteTerritoireState.initialState),

  on(
    SocieteTerritoireGeneratedActions.addManySocieteTerritoirePatrimoineSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoire, idSocieteTerritoirePatrimoines }) => {
      if (!state.entities[idSocieteTerritoire]) {
        return state;
      }
      const societeTerritoirePatrimoines = (state.entities[idSocieteTerritoire]?.societeTerritoirePatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoire]: {
            ...state.entities[idSocieteTerritoire],
            societeTerritoirePatrimoines: societeTerritoirePatrimoines.concat(
              idSocieteTerritoirePatrimoines.filter(id => societeTerritoirePatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.deleteManySocieteTerritoirePatrimoineSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoirePatrimoines, idSocieteTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoires.reduce((entities, idSocieteTerritoire) => {
            if (!state.entities[idSocieteTerritoire]?.societeTerritoirePatrimoines) {
              return entities;
            }
            entities[idSocieteTerritoire] = {
              ...state.entities[idSocieteTerritoire],
              societeTerritoirePatrimoines: (state.entities[idSocieteTerritoire]?.societeTerritoirePatrimoines as number[])?.filter(
                (idSocieteTerritoirePatrimoine: number) => !idSocieteTerritoirePatrimoines.some((id: number) => id === idSocieteTerritoirePatrimoine)
              )
            } as SocieteTerritoireEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.addManyPatrimoineSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoire, idPatrimoines }) => {
      if (!state.entities[idSocieteTerritoire]) {
        return state;
      }
      const patrimoines = (state.entities[idSocieteTerritoire]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoire]: {
            ...state.entities[idSocieteTerritoire],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.deleteManyPatrimoineSuccess,
    (state: SocieteTerritoireState.IState, { idPatrimoines, idSocieteTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoires.reduce((entities, idSocieteTerritoire) => {
            if (!state.entities[idSocieteTerritoire]?.patrimoines) {
              return entities;
            }
            entities[idSocieteTerritoire] = {
              ...state.entities[idSocieteTerritoire],
              patrimoines: (state.entities[idSocieteTerritoire]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as SocieteTerritoireEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.addManySocieteTerritoireUserSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoire, idSocieteTerritoireUsers }) => {
      if (!state.entities[idSocieteTerritoire]) {
        return state;
      }
      const societeTerritoireUsers = (state.entities[idSocieteTerritoire]?.societeTerritoireUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoire]: {
            ...state.entities[idSocieteTerritoire],
            societeTerritoireUsers: societeTerritoireUsers.concat(
              idSocieteTerritoireUsers.filter(id => societeTerritoireUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.deleteManySocieteTerritoireUserSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoireUsers, idSocieteTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoires.reduce((entities, idSocieteTerritoire) => {
            if (!state.entities[idSocieteTerritoire]?.societeTerritoireUsers) {
              return entities;
            }
            entities[idSocieteTerritoire] = {
              ...state.entities[idSocieteTerritoire],
              societeTerritoireUsers: (state.entities[idSocieteTerritoire]?.societeTerritoireUsers as number[])?.filter(
                (idSocieteTerritoireUser: number) => !idSocieteTerritoireUsers.some((id: number) => id === idSocieteTerritoireUser)
              )
            } as SocieteTerritoireEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.addSocieteSuccess,
    (state: SocieteTerritoireState.IState, { idSocieteTerritoire, idSociete }) => {
      if (!state.entities[idSocieteTerritoire]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoire]: {
            ...state.entities[idSocieteTerritoire],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteTerritoireState.IState, { idSocietes, idSocieteTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoires.reduce((entities, idSocieteTerritoire) => {
            if (!state.entities[idSocieteTerritoire]?.societe) {
              return entities;
            }
            entities[idSocieteTerritoire] = {
              ...state.entities[idSocieteTerritoire],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteTerritoire]?.societe
              )
                ? undefined
                : state.entities[idSocieteTerritoire]?.societe
            } as SocieteTerritoireEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteTerritoireState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteTerritoireState.IState {
  return { ...state, isLoaded, isLoading };
}
