import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoardState, BoardStateEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { BoardStateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const boardStateRelations: string[] = ['users',];

export const { selectEntities, selectAll } = BoardStateState.adapter.getSelectors();

export const selectBoardStateState = createFeatureSelector<BoardStateState.IState>(BoardStateState.boardStateFeatureKey);

export const selectIsLoadedBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState.IState) => state.isLoaded
);

export const selectIsLoadingBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState.IState) => state.isLoading
);

export const selectIsReadyBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState.IState) => state.isLoaded && !state.isLoading
);

export const selectBoardStatesEntities = createSelector(selectBoardStateState, selectEntities);

export const selectBoardStatesArray = createSelector(selectBoardStateState, selectAll);

const boardStatesInObject = (boardStates: Dictionary<BoardStateEntityState>) => ({ boardStates })

const selectBoardStatesEntitiesDictionary = createSelector(selectBoardStatesEntities, boardStatesInObject);

const selectAllBoardStatesObject = createSelector(selectBoardStatesEntities, boardStates => {
  return hydrateAll({ boardStates });
});

const selectOneBoardStateDictionary = (idBoardState : number) =>
  createSelector(selectBoardStatesEntities, boardStates => ({
    boardStates: { [idBoardState]: boardStates[idBoardState] }
  }));

const selectOneBoardStateDictionaryWithoutChild = (idBoardState : number) =>
  createSelector(selectBoardStatesEntities, boardStates => ({
    boardState: boardStates[idBoardState]
  }));

const selectAllBoardStatesSelectors: Dictionary<Selector> = {};
export function selectAllBoardStates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<BoardState>(
      schema,
      selectAllBoardStatesSelectors,
      selectBoardStatesEntitiesDictionary,
      getRelationSelectors,
      boardStateRelations,
      hydrateAll,
      'boardState'
    );
  } else {
    return selectAllBoardStatesObject;
  }
}

export function selectAllBoardStatesDictionary(
  schema: SelectSchema = {},
  customKey = 'boardStates'
): Selector {
  return createSelector(selectAllBoardStates(schema), result => {
    const res = { [customKey]: {} as Dictionary<BoardStateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.boardStates.length; i++) {
      res[customKey][result.boardStates[i].idBoardState] = result.boardStates[i];
    }
    return res;
  });
}

export function selectOneBoardState(
  schema: SelectSchema = {},
  idBoardState: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneBoardStateDictionary(idBoardState)];
  selectors.push(...getRelationSelectors(schema, boardStateRelations, 'boardState'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneBoardStateDictionaryWithoutChild(idBoardState);
  }
}

interface hydrateArgs {
  boardStates: Dictionary<BoardStateEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { boardStates: (BoardState | null)[] } {
  const {
    boardStates,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    boardStates: Object.keys(boardStates).map(idBoardState =>
      hydrate(
        boardStates[idBoardState] as BoardStateEntityState,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { boardState: BoardStateEntityState | null } {
  const {
    boardStates,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const boardState = Object.values(boardStates)[0];
  return {
    boardState: hydrate(
      boardState as BoardStateEntityState,
      users
    )
  };
}

function hydrate(
  boardState: BoardStateEntityState,
  userEntities?: Dictionary<UserEntityState>,
): BoardState | null {
  if (!boardState) {
    return null;
  }

  const boardStateHydrated: BoardStateEntityState = { ...boardState };
  if (userEntities) {
    boardStateHydrated.user = userEntities[boardState.user as number] as User;
  } else {
    delete boardStateHydrated.user;
  }

  return boardStateHydrated as BoardState;
}
