import { Injectable } from '@angular/core';
import { AnalyzeGlobalComposantsWithResult, Organisation } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedOrganisationApiService } from './organisation-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationApiService extends GeneratedOrganisationApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getUserOrganisation(params: { idUser: number; params?: any }): Observable<Organisation> {
    return this.repo.getData<Organisation>('organisation/getUserOrganisation/' + params.idUser, params.params);
  }

  public getAnalyzeGlobalComposants(): Observable<AnalyzeGlobalComposantsWithResult[]> {
    return this.repo.getData('organisation/analyze-global-composants');
  }
}
