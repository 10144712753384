import { Injectable } from '@angular/core';
import { GridError, SocieteCaracteristiqueDataRow } from '@get/api-interfaces';
import { GridErrorActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridError: Partial<GridError>): number | undefined {
    if (!gridError.error.idSocieteCaracteristique) {
      gridError.error.idSocieteCaracteristique = --this.tempId;
    }
    gridError.idGridError = gridError.error.idSocieteCaracteristique;
    this.store$.dispatch(GridErrorActions.addOneGridError({ gridError: gridError as GridError }));
    return gridError.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(GridErrorActions.deleteOneGridErrorSuccess({ idGridError }));
  }

  public selectSocieteCaracteristiqueAndGridErrors(
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSelectors.selectSocieteCaracteristiqueAndGridErrors(schema)))
      .pipe(map(societeCaracteristiqueDataRow => societeCaracteristiqueDataRow));
  }

  public selectSocieteCaracteristiquePatrimoinesAndGridErrors(
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSelectors.selectSocieteCaracteristiquePatrimoinesAndGridErrors(schema)))
      .pipe(map(societeCaracteristiqueDataRow => societeCaracteristiqueDataRow));
  }

  public selectSocieteCaracteristiqueComposantsAndGridErrors(
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSelectors.selectSocieteCaracteristiqueComposantsAndGridErrors(schema)))
      .pipe(map(societeCaracteristiqueDataRow => societeCaracteristiqueDataRow));
  }

  public selectSocieteCaracteristiqueInterventionsAndGridErrors(
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSelectors.selectSocieteCaracteristiqueInterventionsAndGridErrors(schema)))
      .pipe(map(societeCaracteristiqueDataRow => societeCaracteristiqueDataRow));
  }
}
