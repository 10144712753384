import { DynamicType, KeysWithValuesOfType } from '@get/api-interfaces';

// TODO: Typer les fonctions de ce fichier
export function transformArrayToTree(
  values: any[],
  identifiers: { key: string; parentKey: string; childrenKey: string }
): any[] {
  if (!values?.length) {
    return [];
  }
  const obj = transformArrayToTreeObj(values, identifiers);
  const res = values.filter(value => !value[identifiers.parentKey]).map(value => obj[value[identifiers.key]]);
  return res;
}

export function transformArrayToTreeObj(
  values: any[],
  identifiers: { key: string; parentKey: string; childrenKey: string }
): any {
  if (!values?.length) {
    return {};
  }
  const obj = transformArrayToObject(values, identifiers);

  let i = 0;
  while (i < values.length) {
    if (values[i][identifiers.parentKey] && obj[values[i][identifiers.parentKey]]?.[identifiers.childrenKey]) {
      obj[values[i][identifiers.parentKey]][identifiers.childrenKey].push(obj[values[i][identifiers.key]]);
    }
    i++;
  }
  return obj;
}

export function transformArrayToObject(values: any[], identifiers: { key: string; childrenKey?: string }): any {
  const obj = {} as any;

  let i = 0;
  while (i < values?.length) {
    const value = values[i];
    obj[value[identifiers.key]] = identifiers.childrenKey ? { ...value, [identifiers.childrenKey]: [] } : { ...value };
    i++;
  }
  return obj;
}

export function transformArrayToObjectKeyFormatting(
  values: any[],
  identifiers: { key: string; childrenKey?: string },
  keyFormatter: (key: any) => any
): any {
  const obj = {} as any;

  let i = 0;
  while (i < values?.length) {
    const value = values[i];
    obj[keyFormatter(value[identifiers.key])] = identifiers.childrenKey
      ? { ...value, [identifiers.childrenKey]: [] }
      : { ...value };
    i++;
  }
  return obj;
}

export function transformArrayToObjectWithReduce(
  values: any[],
  identifiers: { key: string; childrenKey?: string }
): any {
  return (
    values?.reduce((acc, value) => {
      acc[value[identifiers.key]] = identifiers.childrenKey
        ? { ...value, [identifiers.childrenKey]: [] }
        : { ...value };
      return acc;
    }, {}) || {}
  );
}

export function fillArrayRecursivelyWithDepth(
  values: any[],
  identifiers: { childrenKey: string; depthKey: string },
  depth = 0
): any[] {
  return (
    values
      ?.map(value =>
        [{ ...value, [identifiers.childrenKey]: undefined, [identifiers.depthKey]: depth }].concat(
          value[identifiers.childrenKey]?.length
            ? fillArrayRecursivelyWithDepth(value[identifiers.childrenKey], identifiers, depth + 1)
            : []
        )
      )
      .flat() ?? []
  );
}

export function fillArrayRecursively<T>(values: T[], identifiers: { childrenKey: KeysWithValuesOfType<T, T[]> }): T[] {
  return (
    values
      ?.map(value =>
        (value[identifiers.childrenKey] as T[])?.length
          ? [value].concat(fillArrayRecursively(value[identifiers.childrenKey] as T[], identifiers))
          : [value]
      )
      .flat() ?? []
  );
}

export function remapObjParentToArray<T>(
  dict: DynamicType<T>,
  parentKey: KeysWithValuesOfType<T, number | undefined>,
  id: number
): T[] {
  return dict[id] ? remapObjParentToArray(dict, parentKey, dict[id][parentKey] as number).concat([dict[id]]) : [];
}
