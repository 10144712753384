import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgBiblioInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionScenarioState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';

export const progBiblioInterventionScenarioReducersGeneratedFunctions: ReducerTypes<ProgBiblioInterventionScenarioState.IState, readonly ActionCreator[]>[] = [
  on(ProgBiblioInterventionScenarioGeneratedActions.upsertOneProgBiblioInterventionScenario, (state: ProgBiblioInterventionScenarioState.IState) => setLoadingsState(state, true)),

  on(ProgBiblioInterventionScenarioGeneratedActions.upsertManyProgBiblioInterventionScenariosSuccess, (state: ProgBiblioInterventionScenarioState.IState, { progBiblioInterventionScenarios }) =>
    ProgBiblioInterventionScenarioState.adapter.upsertMany(progBiblioInterventionScenarios, setLoadingsState(state, false))
  ),
  on(ProgBiblioInterventionScenarioGeneratedActions.deleteOneProgBiblioInterventionScenarioSuccess, (state: ProgBiblioInterventionScenarioState.IState, { idProgBiblioInterventionScenario }) =>
    ProgBiblioInterventionScenarioState.adapter.removeOne(idProgBiblioInterventionScenario, setLoadingsState(state, false))
  ),

  on(ProgBiblioInterventionScenarioGeneratedActions.clearProgBiblioInterventionScenarios, () => ProgBiblioInterventionScenarioState.initialState),

  on(
    ProgBiblioInterventionScenarioGeneratedActions.addProgScenarioSuccess,
    (state: ProgBiblioInterventionScenarioState.IState, { idProgBiblioInterventionScenario, idProgScenario }) => {
      if (!state.entities[idProgBiblioInterventionScenario]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioInterventionScenario]: {
            ...state.entities[idProgBiblioInterventionScenario],
            progScenario: idProgScenario
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionScenarioGeneratedActions.deleteManyProgScenarioSuccess,
    (state: ProgBiblioInterventionScenarioState.IState, { idProgScenarios, idProgBiblioInterventionScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventionScenarios.reduce((entities, idProgBiblioInterventionScenario) => {
            if (!state.entities[idProgBiblioInterventionScenario]?.progScenario) {
              return entities;
            }
            entities[idProgBiblioInterventionScenario] = {
              ...state.entities[idProgBiblioInterventionScenario],
              progScenario: idProgScenarios.some(
                (idProgScenario: number) => idProgScenario === state.entities[idProgBiblioInterventionScenario]?.progScenario
              )
                ? undefined
                : state.entities[idProgBiblioInterventionScenario]?.progScenario
            } as ProgBiblioInterventionScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionScenarioEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionScenarioGeneratedActions.addProgBiblioInterventionSuccess,
    (state: ProgBiblioInterventionScenarioState.IState, { idProgBiblioInterventionScenario, idProgBiblioIntervention }) => {
      if (!state.entities[idProgBiblioInterventionScenario]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioInterventionScenario]: {
            ...state.entities[idProgBiblioInterventionScenario],
            progBiblioIntervention: idProgBiblioIntervention
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionScenarioGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgBiblioInterventionScenarioState.IState, { idProgBiblioInterventions, idProgBiblioInterventionScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventionScenarios.reduce((entities, idProgBiblioInterventionScenario) => {
            if (!state.entities[idProgBiblioInterventionScenario]?.progBiblioIntervention) {
              return entities;
            }
            entities[idProgBiblioInterventionScenario] = {
              ...state.entities[idProgBiblioInterventionScenario],
              progBiblioIntervention: idProgBiblioInterventions.some(
                (idProgBiblioIntervention: number) => idProgBiblioIntervention === state.entities[idProgBiblioInterventionScenario]?.progBiblioIntervention
              )
                ? undefined
                : state.entities[idProgBiblioInterventionScenario]?.progBiblioIntervention
            } as ProgBiblioInterventionScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionScenarioEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgBiblioInterventionScenarioState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgBiblioInterventionScenarioState.IState {
  return { ...state, isLoaded, isLoading };
}
