import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { PatrimoineHierarchieTemplate } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { PatrimoineHierarchieTemplateRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedPatrimoineHierarchieTemplateService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(PatrimoineHierarchieTemplateSelectors.selectIsLoadedPatrimoineHierarchieTemplate));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(PatrimoineHierarchieTemplateSelectors.selectIsLoadingPatrimoineHierarchieTemplate));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      PatrimoineHierarchieTemplateSelectors.selectIsReadyAndLoadedPatrimoineHierarchieTemplate as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllPatrimoineHierarchieTemplates(schema: SelectSchema = {}): Observable<PatrimoineHierarchieTemplate[]> {
    return this.store$.pipe(select(PatrimoineHierarchieTemplateSelectors.selectAllPatrimoineHierarchieTemplates(schema))).pipe(
      switchMap(({ patrimoineHierarchieTemplates }: { patrimoineHierarchieTemplates: PatrimoineHierarchieTemplate[] }) => {
        return this.getReady(schema).pipe(map(() => patrimoineHierarchieTemplates));
      })
    );
  }

  public selectOnePatrimoineHierarchieTemplate(
    idPatrimoineHierarchieTemplate: number,
    schema: SelectSchema = {}
  ): Observable<PatrimoineHierarchieTemplate> {
    return this.store$
      .pipe(select(PatrimoineHierarchieTemplateSelectors.selectOnePatrimoineHierarchieTemplate(schema, idPatrimoineHierarchieTemplate)))
      .pipe(
        switchMap(({ patrimoineHierarchieTemplate }: { patrimoineHierarchieTemplate: PatrimoineHierarchieTemplate }) => {
          return this.getReady(schema).pipe(map(() => patrimoineHierarchieTemplate));
        })
      );
  }

  public getManyPatrimoineHierarchieTemplates(
    params: any = {},
    getResult?: boolean
  ): void | Observable<PatrimoineHierarchieTemplate[]> {
    this.store$.dispatch(PatrimoineHierarchieTemplateGeneratedActions.getManyPatrimoineHierarchieTemplates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert,
        PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure
      );
    }
  }

  public upsertOnePatrimoineHierarchieTemplate(
    patrimoineHierarchieTemplate: Partial<PatrimoineHierarchieTemplate>,
    ids: PatrimoineHierarchieTemplateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<PatrimoineHierarchieTemplate> {
    this.store$.dispatch(PatrimoineHierarchieTemplateGeneratedActions.upsertOnePatrimoineHierarchieTemplate({ patrimoineHierarchieTemplate, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert,
        PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure,
        true
      );
    }
  }

  public deleteOnePatrimoineHierarchieTemplate(
    idPatrimoineHierarchieTemplate: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplate({ idPatrimoineHierarchieTemplate }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplateSuccess,
        PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure
      );
    }
  }
}
