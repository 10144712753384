import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';

export class GeneratedComposantApiService {
  constructor(protected repo: RepositoryService) {}

  public deleteComposant(idComposant: number): Observable<number> {
    return this.repo.delete('composant/' + +idComposant);
  }
}
