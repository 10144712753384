import { Injectable } from '@angular/core';
import { ProgInterventionApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GeneratedProgInterventionEffects } from './prog-intervention-generated.effects';

@Injectable()
export class ProgInterventionEffects extends GeneratedProgInterventionEffects {
  constructor(actions$: Actions, progInterventionApiService: ProgInterventionApiService, store$: Store<AppState>) {
    super(actions$, progInterventionApiService, store$);
  }
}
