import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgEtatTechniqueGeneratedActions } from '@get/store/actions';
import { ProgEtatTechniqueState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgEtatTechniqueEntityState } from '@get/api-interfaces';

export const progEtatTechniqueReducersGeneratedFunctions: ReducerTypes<ProgEtatTechniqueState.IState, readonly ActionCreator[]>[] = [
  on(ProgEtatTechniqueGeneratedActions.upsertOneProgEtatTechnique, (state: ProgEtatTechniqueState.IState) => setLoadingsState(state, true)),

  on(ProgEtatTechniqueGeneratedActions.upsertManyProgEtatTechniquesSuccess, (state: ProgEtatTechniqueState.IState, { progEtatTechniques }) =>
    ProgEtatTechniqueState.adapter.upsertMany(progEtatTechniques, setLoadingsState(state, false))
  ),
  on(ProgEtatTechniqueGeneratedActions.deleteOneProgEtatTechnique, (state: ProgEtatTechniqueState.IState) => setLoadingsState(state, true)),
  on(ProgEtatTechniqueGeneratedActions.deleteOneProgEtatTechniqueSuccess, (state: ProgEtatTechniqueState.IState, { idProgEtatTechnique }) =>
    ProgEtatTechniqueState.adapter.removeOne(idProgEtatTechnique, setLoadingsState(state, false))
  ),

  on(ProgEtatTechniqueGeneratedActions.clearProgEtatTechniques, () => ProgEtatTechniqueState.initialState),

  on(
    ProgEtatTechniqueGeneratedActions.addManyProgPatrimoineInfoSuccess,
    (state: ProgEtatTechniqueState.IState, { idProgEtatTechnique, idProgPatrimoineInfos }) => {
      if (!state.entities[idProgEtatTechnique]) {
        return state;
      }
      const progPatrimoineInfos = (state.entities[idProgEtatTechnique]?.progPatrimoineInfos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgEtatTechnique]: {
            ...state.entities[idProgEtatTechnique],
            progPatrimoineInfos: progPatrimoineInfos.concat(
              idProgPatrimoineInfos.filter(id => progPatrimoineInfos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgEtatTechniqueGeneratedActions.deleteManyProgPatrimoineInfoSuccess,
    (state: ProgEtatTechniqueState.IState, { idProgPatrimoineInfos, idProgEtatTechniques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgEtatTechniques.reduce((entities, idProgEtatTechnique) => {
            if (!state.entities[idProgEtatTechnique]?.progPatrimoineInfos) {
              return entities;
            }
            entities[idProgEtatTechnique] = {
              ...state.entities[idProgEtatTechnique],
              progPatrimoineInfos: (state.entities[idProgEtatTechnique]?.progPatrimoineInfos as number[])?.filter(
                (idProgPatrimoineInfo: number) => !idProgPatrimoineInfos.some((id: number) => id === idProgPatrimoineInfo)
              )
            } as ProgEtatTechniqueEntityState;
            return entities;
          }, {} as Dictionary<ProgEtatTechniqueEntityState>)
        }
      };
    }
  ),

  on(
    ProgEtatTechniqueGeneratedActions.addSocieteSuccess,
    (state: ProgEtatTechniqueState.IState, { idProgEtatTechnique, idSociete }) => {
      if (!state.entities[idProgEtatTechnique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgEtatTechnique]: {
            ...state.entities[idProgEtatTechnique],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgEtatTechniqueGeneratedActions.deleteManySocieteSuccess,
    (state: ProgEtatTechniqueState.IState, { idSocietes, idProgEtatTechniques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgEtatTechniques.reduce((entities, idProgEtatTechnique) => {
            if (!state.entities[idProgEtatTechnique]?.societe) {
              return entities;
            }
            entities[idProgEtatTechnique] = {
              ...state.entities[idProgEtatTechnique],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgEtatTechnique]?.societe
              )
                ? undefined
                : state.entities[idProgEtatTechnique]?.societe
            } as ProgEtatTechniqueEntityState;
            return entities;
          }, {} as Dictionary<ProgEtatTechniqueEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgEtatTechniqueState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgEtatTechniqueState.IState {
  return { ...state, isLoaded, isLoading };
}
