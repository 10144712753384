import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { User, UserEntityState } from '@get/api-interfaces';
import { UserApiService } from '@get/store/api-services';
import { UserGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUser } from '@get/store/configs/normalization';
import { UserSelectors } from '@get/store/selectors';
import { UserRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUser } from '@get/api-interfaces';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { CampagneUser } from '@get/api-interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttendu } from '@get/api-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { Campagne } from '@get/api-interfaces';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { UserSocieteProfil } from '@get/api-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfil } from '@get/api-interfaces';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoine } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { ComposantGeneratedActions } from '@get/store/actions';
import { Composant } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { UserNewsletter } from '@get/api-interfaces';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { Newsletter } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { BoardStateGeneratedActions } from '@get/store/actions';
import { BoardState } from '@get/api-interfaces';

export function getDefaultAddUserActions(user: UserEntityState, ids?: UserRelationsIds): Action[] {
  const actions: Action[] = [UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [user] })];

  if (ids?.organisation) {
    actions.push(
      OrganisationGeneratedActions.addManyUserSuccess({
        idOrganisation: ids.organisation,
        idUsers: [user.idUser]
      })
    );
    actions.push(
      UserGeneratedActions.addOrganisationSuccess({
        idUser: user.idUser,
        idOrganisation: ids.organisation
      })
    );
  }

  if (ids?.societeTerritoireUsers) {
    if (!Array.isArray(ids.societeTerritoireUsers)) {
      actions.push(
        SocieteTerritoireUserGeneratedActions.upsertOneSocieteTerritoireUser({
          societeTerritoireUser: {
            idUser: user.idUser,
            idSocieteTerritoireUser: ids.societeTerritoireUsers as number
          } as SocieteTerritoireUser
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteTerritoireUserSuccess({
          idUser: user.idUser,
          idSocieteTerritoireUsers: [ids.societeTerritoireUsers as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoireUserGeneratedActions.upsertManySocieteTerritoireUsers({
          societeTerritoireUsers: (ids.societeTerritoireUsers as number[]).map(
            (idSocieteTerritoireUser: number) => ({
              idUser: user.idUser,
              idSocieteTerritoireUser
            })
          ) as SocieteTerritoireUser[]
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteTerritoireUserSuccess({
          idUser: user.idUser,
          idSocieteTerritoireUsers: ids.societeTerritoireUsers as number[]
        })
      );
    }
  }

  if (ids?.campagneUsers) {
    if (!Array.isArray(ids.campagneUsers)) {
      actions.push(
        CampagneUserGeneratedActions.upsertOneCampagneUser({
          campagneUser: {
            idUser: user.idUser,
            idCampagneUser: ids.campagneUsers as number
          } as CampagneUser
        })
      );
      actions.push(
        UserGeneratedActions.addManyCampagneUserSuccess({
          idUser: user.idUser,
          idCampagneUsers: [ids.campagneUsers as number]
        })
      );
    } else {
      actions.push(
        CampagneUserGeneratedActions.upsertManyCampagneUsers({
          campagneUsers: (ids.campagneUsers as number[]).map(
            (idCampagneUser: number) => ({
              idUser: user.idUser,
              idCampagneUser
            })
          ) as CampagneUser[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyCampagneUserSuccess({
          idUser: user.idUser,
          idCampagneUsers: ids.campagneUsers as number[]
        })
      );
    }
  }

  if (ids?.composantAttendus) {
    if (!Array.isArray(ids.composantAttendus)) {
      actions.push(
        ComposantAttenduGeneratedActions.upsertOneComposantAttendu({
          composantAttendu: {
            idUser: user.idUser,
            idComposantAttendu: ids.composantAttendus as number
          } as ComposantAttendu
        })
      );
      actions.push(
        UserGeneratedActions.addManyComposantAttenduSuccess({
          idUser: user.idUser,
          idComposantAttendus: [ids.composantAttendus as number]
        })
      );
    } else {
      actions.push(
        ComposantAttenduGeneratedActions.upsertManyComposantAttendus({
          composantAttendus: (ids.composantAttendus as number[]).map(
            (idComposantAttendu: number) => ({
              idUser: user.idUser,
              idComposantAttendu
            })
          ) as ComposantAttendu[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyComposantAttenduSuccess({
          idUser: user.idUser,
          idComposantAttendus: ids.composantAttendus as number[]
        })
      );
    }
  }

  if (ids?.campagnes) {
    if (!Array.isArray(ids.campagnes)) {
      actions.push(
        CampagneGeneratedActions.upsertOneCampagne({
          campagne: {
            idUser: user.idUser,
            idCampagne: ids.campagnes as number
          } as Campagne & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyCampagneSuccess({
          idUser: user.idUser,
          idCampagnes: [ids.campagnes as number]
        })
      );
    } else {
      actions.push(
        CampagneGeneratedActions.upsertManyCampagnes({
          campagnes: (ids.campagnes as number[]).map(
            (idCampagne: number) => ({
              idUser: user.idUser,
              idCampagne
            })
          ) as Campagne[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyCampagneSuccess({
          idUser: user.idUser,
          idCampagnes: ids.campagnes as number[]
        })
      );
    }
  }

  if (ids?.userSocieteProfils) {
    if (!Array.isArray(ids.userSocieteProfils)) {
      actions.push(
        UserSocieteProfilGeneratedActions.upsertOneUserSocieteProfil({
          userSocieteProfil: {
            idUser: user.idUser,
            idUserSocieteProfil: ids.userSocieteProfils as number
          } as UserSocieteProfil
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserSocieteProfilSuccess({
          idUser: user.idUser,
          idUserSocieteProfils: [ids.userSocieteProfils as number]
        })
      );
    } else {
      actions.push(
        UserSocieteProfilGeneratedActions.upsertManyUserSocieteProfils({
          userSocieteProfils: (ids.userSocieteProfils as number[]).map(
            (idUserSocieteProfil: number) => ({
              idUser: user.idUser,
              idUserSocieteProfil
            })
          ) as UserSocieteProfil[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserSocieteProfilSuccess({
          idUser: user.idUser,
          idUserSocieteProfils: ids.userSocieteProfils as number[]
        })
      );
    }
  }

  if (ids?.societeProfils) {
    if (!Array.isArray(ids.societeProfils)) {
      actions.push(
        SocieteProfilGeneratedActions.upsertOneSocieteProfil({
          societeProfil: {
            idUser: user.idUser,
            idSocieteProfil: ids.societeProfils as number
          } as SocieteProfil & any
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteProfilSuccess({
          idUser: user.idUser,
          idSocieteProfils: [ids.societeProfils as number]
        })
      );
    } else {
      actions.push(
        SocieteProfilGeneratedActions.upsertManySocieteProfils({
          societeProfils: (ids.societeProfils as number[]).map(
            (idSocieteProfil: number) => ({
              idUser: user.idUser,
              idSocieteProfil
            })
          ) as SocieteProfil[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteProfilSuccess({
          idUser: user.idUser,
          idSocieteProfils: ids.societeProfils as number[]
        })
      );
    }
  }

  if (ids?.userPatrimoines) {
    if (!Array.isArray(ids.userPatrimoines)) {
      actions.push(
        UserPatrimoineGeneratedActions.upsertOneUserPatrimoine({
          userPatrimoine: {
            idUser: user.idUser,
            idUserPatrimoine: ids.userPatrimoines as number
          } as UserPatrimoine
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserPatrimoineSuccess({
          idUser: user.idUser,
          idUserPatrimoines: [ids.userPatrimoines as number]
        })
      );
    } else {
      actions.push(
        UserPatrimoineGeneratedActions.upsertManyUserPatrimoines({
          userPatrimoines: (ids.userPatrimoines as number[]).map(
            (idUserPatrimoine: number) => ({
              idUser: user.idUser,
              idUserPatrimoine
            })
          ) as UserPatrimoine[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserPatrimoineSuccess({
          idUser: user.idUser,
          idUserPatrimoines: ids.userPatrimoines as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idUser: user.idUser,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyPatrimoineSuccess({
          idUser: user.idUser,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idUser: user.idUser,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyPatrimoineSuccess({
          idUser: user.idUser,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.composants) {
    if (!Array.isArray(ids.composants)) {
      actions.push(
        ComposantGeneratedActions.upsertOneComposant({
          composant: {
            idUser: user.idUser,
            idComposant: ids.composants as number
          } as Composant
        })
      );
      actions.push(
        UserGeneratedActions.addManyComposantSuccess({
          idUser: user.idUser,
          idComposants: [ids.composants as number]
        })
      );
    } else {
      actions.push(
        ComposantGeneratedActions.upsertManyComposants({
          composants: (ids.composants as number[]).map(
            (idComposant: number) => ({
              idUser: user.idUser,
              idComposant
            })
          ) as Composant[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyComposantSuccess({
          idUser: user.idUser,
          idComposants: ids.composants as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idUser: user.idUser,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        UserGeneratedActions.addManyValeurSuccess({
          idUser: user.idUser,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idUser: user.idUser,
              idValeur
            })
          ) as Valeur[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyValeurSuccess({
          idUser: user.idUser,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  if (ids?.userNewsletters) {
    if (!Array.isArray(ids.userNewsletters)) {
      actions.push(
        UserNewsletterGeneratedActions.upsertOneUserNewsletter({
          userNewsletter: {
            idUser: user.idUser,
            idUserNewsletter: ids.userNewsletters as number
          } as UserNewsletter
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserNewsletterSuccess({
          idUser: user.idUser,
          idUserNewsletters: [ids.userNewsletters as number]
        })
      );
    } else {
      actions.push(
        UserNewsletterGeneratedActions.upsertManyUserNewsletters({
          userNewsletters: (ids.userNewsletters as number[]).map(
            (idUserNewsletter: number) => ({
              idUser: user.idUser,
              idUserNewsletter
            })
          ) as UserNewsletter[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserNewsletterSuccess({
          idUser: user.idUser,
          idUserNewsletters: ids.userNewsletters as number[]
        })
      );
    }
  }

  if (ids?.newsletters) {
    if (!Array.isArray(ids.newsletters)) {
      actions.push(
        NewsletterGeneratedActions.upsertOneNewsletter({
          newsletter: {
            idUser: user.idUser,
            idNewsletter: ids.newsletters as number
          } as Newsletter & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyNewsletterSuccess({
          idUser: user.idUser,
          idNewsletters: [ids.newsletters as number]
        })
      );
    } else {
      actions.push(
        NewsletterGeneratedActions.upsertManyNewsletters({
          newsletters: (ids.newsletters as number[]).map(
            (idNewsletter: number) => ({
              idUser: user.idUser,
              idNewsletter
            })
          ) as Newsletter[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyNewsletterSuccess({
          idUser: user.idUser,
          idNewsletters: ids.newsletters as number[]
        })
      );
    }
  }

  if (ids?.societeConfigAnalyseSyntheses) {
    if (!Array.isArray(ids.societeConfigAnalyseSyntheses)) {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese({
          societeConfigAnalyseSynthese: {
            idUser: user.idUser,
            idSocieteConfigAnalyseSynthese: ids.societeConfigAnalyseSyntheses as number
          } as SocieteConfigAnalyseSynthese
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idUser: user.idUser,
          idSocieteConfigAnalyseSyntheses: [ids.societeConfigAnalyseSyntheses as number]
        })
      );
    } else {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSyntheses({
          societeConfigAnalyseSyntheses: (ids.societeConfigAnalyseSyntheses as number[]).map(
            (idSocieteConfigAnalyseSynthese: number) => ({
              idUser: user.idUser,
              idSocieteConfigAnalyseSynthese
            })
          ) as SocieteConfigAnalyseSynthese[]
        })
      );
      actions.push(
        UserGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idUser: user.idUser,
          idSocieteConfigAnalyseSyntheses: ids.societeConfigAnalyseSyntheses as number[]
        })
      );
    }
  }

  if (ids?.boardStates) {
    if (!Array.isArray(ids.boardStates)) {
      actions.push(
        BoardStateGeneratedActions.upsertOneBoardState({
          boardState: {
            idUser: user.idUser,
            idBoardState: ids.boardStates as number
          } as BoardState
        })
      );
      actions.push(
        UserGeneratedActions.addManyBoardStateSuccess({
          idUser: user.idUser,
          idBoardStates: [ids.boardStates as number]
        })
      );
    } else {
      actions.push(
        BoardStateGeneratedActions.upsertManyBoardStates({
          boardStates: (ids.boardStates as number[]).map(
            (idBoardState: number) => ({
              idUser: user.idUser,
              idBoardState
            })
          ) as BoardState[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyBoardStateSuccess({
          idUser: user.idUser,
          idBoardStates: ids.boardStates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteUserActions(user: UserEntityState): Action[] {
  const actions: Action[] = [UserGeneratedActions.deleteOneUserSuccess({ idUser: user.idUser })];

  if (user.organisation) {
    actions.push(
      OrganisationGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idOrganisations: [user.organisation as number]
      })
    );
  }

  if (user.societeTerritoireUsers) {
    actions.push(
      SocieteTerritoireUserGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idSocieteTerritoireUsers: user.societeTerritoireUsers as number[]
      })
    );
  }

  if (user.campagneUsers) {
    actions.push(
      CampagneUserGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCampagneUsers: user.campagneUsers as number[]
      })
    );
  }

  if (user.composantAttendus) {
    actions.push(
      ComposantAttenduGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idComposantAttendus: user.composantAttendus as number[]
      })
    );
  }

  if (user.campagnes) {
    actions.push(
      CampagneGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCampagnes: user.campagnes as number[]
      })
    );
  }

  if (user.userSocieteProfils) {
    actions.push(
      UserSocieteProfilGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserSocieteProfils: user.userSocieteProfils as number[]
      })
    );
  }

  if (user.societeProfils) {
    actions.push(
      SocieteProfilGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idSocieteProfils: user.societeProfils as number[]
      })
    );
  }

  if (user.userPatrimoines) {
    actions.push(
      UserPatrimoineGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserPatrimoines: user.userPatrimoines as number[]
      })
    );
  }

  if (user.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idPatrimoines: user.patrimoines as number[]
      })
    );
  }

  if (user.composants) {
    actions.push(
      ComposantGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idComposants: user.composants as number[]
      })
    );
  }

  if (user.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idValeurs: user.valeurs as number[]
      })
    );
  }

  if (user.userNewsletters) {
    actions.push(
      UserNewsletterGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserNewsletters: user.userNewsletters as number[]
      })
    );
  }

  if (user.newsletters) {
    actions.push(
      NewsletterGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idNewsletters: user.newsletters as number[]
      })
    );
  }

  if (user.societeConfigAnalyseSyntheses) {
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idSocieteConfigAnalyseSyntheses: user.societeConfigAnalyseSyntheses as number[]
      })
    );
  }

  if (user.boardStates) {
    actions.push(
      BoardStateGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idBoardStates: user.boardStates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedUserEffects {
  constructor(
    protected actions$: Actions,
    protected userApiService: UserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.getManyUsers),
      switchMap(({ params }) =>
        this.userApiService.getUsers(params).pipe(
          map((users: User[]) => {
            return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users });
          }),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  getOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.getOneUser),
      switchMap(idUser =>
        this.userApiService.getUser(idUser).pipe(
          map((user: User) => {
            return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [user] });
          }),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  upsertOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.upsertOneUser),
      concatMap(
        ({
          user,
          ids
        }: {
          user: Partial<User>;
          ids?: UserRelationsIds;
        }) => {
          if (user.idUser) {
            return this.userApiService.updateUser(user).pipe(
              map((userReturned: User) => {
                return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [userReturned] });
              }),
              catchError(error => of(UserGeneratedActions.usersFailure({ error })))
            );
          } else {
            return this.userApiService.addUser(user).pipe(
              mergeMap((userReturned: User) => getDefaultAddUserActions(userReturned, ids)),
              catchError(error => of(UserGeneratedActions.usersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUser$ = createEffect(() => {
    const selectUserState$ = this.store$.select(UserSelectors.selectUserState);
    return this.actions$.pipe(
      ofType(UserGeneratedActions.deleteOneUser),
      withLatestFrom(selectUserState$),
      concatMap(([{ idUser }, state]) =>
        this.userApiService.deleteUser(idUser).pipe(
          mergeMap(_success => getDefaultDeleteUserActions(state.entities[idUser] as UserEntityState)),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  normalizeManyUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.normalizeManyUsersAfterUpsert),
      concatMap(({ users }) => {
        const actions: Action[] = getActionsToNormalizeUser(users, StoreActionType.upsert);
        return [getMultiAction(actions, '[User] Normalization After Upsert Success')];
      })
    );
  });
}
