import { AggregateEnum, ComponentTypeEnum, ComposantAttenduReponseEnum, ComposantCardDisableCause } from '@enums';
import {
  Composant,
  ComposantAttendu,
  DynamicType,
  Espace,
  GroupeArrayInterface,
  GroupeCardArrayInterface,
  GroupeElementInterface,
  GroupeObjInterface,
  Patrimoine,
  PatrimoineWithChildren,
  SocieteCaracteristique,
  SocieteComposant,
  SocieteComposantFamille,
  SocieteEspace,
  SocieteEspaceGroupedByLibelle
} from '@get/api-interfaces';
import { ComposantDocument } from '@get/interfaces';
import {
  canEspaceHaveComposant,
  canEspaceOnPatrimoineHaveComposant,
  canPatrimoineAndChildrenHaveComposant,
  canPatrimoineHaveComposant,
  compareStrings,
  defaultIconComposant,
  defaultIconComposantGroupe,
  extractNumberFromComposant,
  formatDateFullYear,
  formatTime,
  getFullName,
  groupArrayPerSubObjectKeyValue,
  transformArrayToObject
} from '@utils';
import { getPatrimoineTitleWithPrefix } from './patrimoine.utils';
import { getSortedValeursEntries } from './valeurs.utils';

export function getAllComposantAttendusGroupes(
  patrimoine: Patrimoine,
  onlyComposant = false,
  groupedEspace?: SocieteEspaceGroupedByLibelle,
  composantAttendusToUse?: ComposantAttendu[]
): GroupeArrayInterface[] {
  const groupesObj = {} as DynamicType<GroupeObjInterface>;
  const espacesObjFamille: DynamicType<SocieteEspace> = transformArrayToObject(groupedEspace?.espaces || [], {
    key: 'idSocieteEspaceFamille'
  });
  const caToUseObj: DynamicType<ComposantAttendu> = transformArrayToObject(composantAttendusToUse || [], {
    key: 'idSocieteComposant'
  });
  for (let i = 0; i < patrimoine?.composantAttendus?.length; i++) {
    const composantAttendu = patrimoine?.composantAttendus[i];
    const caToUse = caToUseObj[composantAttendu.societeComposant?.idSocieteComposant];
    const group: GroupeObjInterface = groupesObj[
      composantAttendu.societeComposant?.composantGroupe?.idComposantGroupe
    ] || {
      idComposantGroupe: composantAttendu.societeComposant?.composantGroupe?.idComposantGroupe,
      libelle: composantAttendu.societeComposant?.composantGroupe?.libelle ?? 'Autres',
      svgName: composantAttendu.societeComposant?.composantGroupe?.fichier?.fileName ?? defaultIconComposantGroupe,
      elements: {
        familles: {},
        composants: {}
      }
    };

    const svgIcon =
      composantAttendu.societeComposant?.societeComposantFamille?.fichier?.fileName ??
      composantAttendu.societeComposant?.fichier?.fileName ??
      defaultIconComposant;

    const componentEnabledForEspace =
      !groupedEspace ||
      composantAttendu.societeComposant?.societeComposantRattachements?.some(
        rattachement => rattachement.idSocieteEspaceFamille && espacesObjFamille[rattachement.idSocieteEspaceFamille]
      );
    const componentEnabledForHierarchie = !composantAttendusToUse || !!caToUse;
    if (!onlyComposant && composantAttendu.societeComposant?.societeComposantFamille) {
      const famille = group.elements.familles[
        composantAttendu.societeComposant?.societeComposantFamille?.idSocieteComposantFamille
      ] || {
        libelle: composantAttendu.societeComposant?.societeComposantFamille?.libelle,
        type: ComponentTypeEnum.famille,
        id: composantAttendu.societeComposant?.societeComposantFamille?.idSocieteComposantFamille,
        svgName: svgIcon,
        ordre: composantAttendu.societeComposant?.ordre,
        reponse: composantAttendusToUse ? caToUse?.reponse : composantAttendu.reponse,
        nbComposants: 0,
        nbReponsesAttendu: 0,
        nbReponsesRempli: 0,
        societeComposant: null,
        enabled: componentEnabledForEspace && componentEnabledForHierarchie,
        disableCause: !componentEnabledForHierarchie
          ? ComposantCardDisableCause.hierarchie
          : !componentEnabledForEspace
          ? ComposantCardDisableCause.espace
          : undefined
      };
      famille.enabled = famille.enabled || (componentEnabledForEspace && componentEnabledForHierarchie);
      // Le disableCause doit être mis sur espace si un des composants de la famille est mettable au niveau hierarchique du patrimoine mais que le filtre espace est à false
      if (!famille.enabled) {
        if (
          famille.disableCause === ComposantCardDisableCause.hierarchie &&
          componentEnabledForHierarchie &&
          !componentEnabledForEspace
        ) {
          famille.disableCause = ComposantCardDisableCause.espace;
        }
      }
      const reponseToUse = composantAttendusToUse ? caToUse?.reponse : composantAttendu.reponse;
      famille.reponse = famille.reponse === reponseToUse ? famille.reponse : undefined;
      famille.nbComposants += (composantAttendusToUse ? caToUse?.valeur : composantAttendu.valeur) || 0;
      famille.nbReponsesAttendu +=
        (composantAttendusToUse ? caToUse?.nbReponsesAttendu : composantAttendu.nbReponsesAttendu) || 0;
      famille.nbReponsesRempli +=
        (composantAttendusToUse ? caToUse?.nbReponsesRempli : composantAttendu.nbReponsesRempli) || 0;
      famille.ordre =
        composantAttendu.societeComposant?.ordre && famille.ordre
          ? Math.min(famille.ordre, composantAttendu.societeComposant?.ordre)
          : famille.ordre ?? composantAttendu.societeComposant?.ordre;
      group.elements.familles[composantAttendu.societeComposant?.societeComposantFamille?.idSocieteComposantFamille] =
        famille;
    } else {
      const composant = group.elements.composants[composantAttendu.societeComposant?.idSocieteComposant] || {
        libelle: composantAttendu.societeComposant?.libelle,
        type: ComponentTypeEnum.societeComposant,
        id: composantAttendu.societeComposant?.idSocieteComposant,
        svgName: svgIcon,
        ordre: composantAttendu.societeComposant?.ordre,
        reponse: composantAttendusToUse ? caToUse?.reponse : composantAttendu.reponse,
        nbComposants: composantAttendusToUse ? caToUse?.valeur : composantAttendu.valeur || 0,
        nbReponsesAttendu: composantAttendusToUse
          ? caToUse?.nbReponsesAttendu
          : composantAttendu.nbReponsesAttendu || 0,
        nbReponsesRempli: composantAttendusToUse ? caToUse?.nbReponsesRempli : composantAttendu.nbReponsesRempli || 0,
        societeComposant: composantAttendu.societeComposant,
        enabled: componentEnabledForEspace && componentEnabledForHierarchie,
        disableCause: !componentEnabledForHierarchie
          ? ComposantCardDisableCause.hierarchie
          : !componentEnabledForEspace
          ? ComposantCardDisableCause.espace
          : undefined
      };
      group.elements.composants[composantAttendu.societeComposant?.idSocieteComposant] = composant;
    }
    groupesObj[composantAttendu.societeComposant?.composantGroupe?.idComposantGroupe] = group;
  }
  return Object.values(groupesObj)
    ?.map((group: GroupeObjInterface) => ({
      idComposantGroupe: group.idComposantGroupe,
      libelle: group.libelle,
      svgName: group.svgName,
      elements: (
        Object.values(group.elements.familles).concat(
          Object.values(group.elements.composants)
        ) as GroupeElementInterface[]
      )
        .sort((a, b) => compareStrings(a.libelle, b.libelle))
        .sort((a, b) =>
          !a.ordre && b.ordre
            ? 1
            : a.ordre && !b.ordre
            ? -1
            : !a.ordre && !b.ordre
            ? compareStrings(a.libelle, b.libelle)
            : a.ordre - b.ordre
        )
    }))
    .sort((a, b) => (!a.libelle ? 1 : !b.libelle ? -1 : 0));
}

export function formatComposantAttendusGroupes(groupes: GroupeArrayInterface[]): GroupeCardArrayInterface[] {
  return groupes.map(
    (groupe): GroupeCardArrayInterface => ({
      idComposantGroupe: groupe.idComposantGroupe,
      libelle: groupe.libelle,
      svgName: groupe.svgName,
      composants: groupe.elements.map(composant => ({
        id: composant.id,
        idComposantGroupe: groupe.idComposantGroupe,
        type: composant.type,
        nb: composant.nbComposants,
        title: composant.libelle ?? 'Non Défini',
        svgName: composant.svgName,
        reponse: composant.reponse,
        nbReponsesRempli: composant.nbReponsesRempli,
        nbReponsesAttendu: composant.nbReponsesAttendu,
        societeComposant: composant.societeComposant,
        enabled: composant.enabled,
        disableCause: composant.disableCause,
        url:
          composant.type === ComponentTypeEnum.famille
            ? `${ComponentTypeEnum.famille}/${composant.id}`
            : `${ComponentTypeEnum.societeComposant}/${composant.id}`,
        progress: {
          displayClass:
            composant.nbReponsesRempli === 0
              ? 'progress-red'
              : composant.nbReponsesRempli === composant.nbReponsesAttendu
              ? 'progress-green-1'
              : 'progress-orange',
          displayValue: composant.nbReponsesRempli === 0 ? '-' : composant.nbComposants?.toString() ?? '-',
          displayTooltip:
            composant.nbReponsesRempli === 0
              ? 'Aucune donnée collectée'
              : composant.nbReponsesRempli === composant.nbReponsesAttendu
              ? 'Données complètes'
              : 'Données incomplètes'
        }
      }))
    })
  );
}

export function doesEspaceHaveComposant(espace: Espace, componentType: ComponentTypeEnum, id: number): boolean {
  return espace.composants?.some(composant =>
    componentType === ComponentTypeEnum.societeComposant
      ? composant.societeComposant?.idSocieteComposant === id
      : composant.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id
  );
}

export function doesEspaceHaveComposantExceptGivenComposant(
  espace: Espace,
  componentType: ComponentTypeEnum,
  id: number,
  idComposant: number
): boolean {
  return espace.composants?.some(
    composant =>
      composant.idComposant !== idComposant &&
      (componentType === ComponentTypeEnum.societeComposant
        ? composant.societeComposant?.idSocieteComposant === id
        : composant.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id)
  );
}

export function doesPatrimoineWithChildrenHaveComposant(
  element: PatrimoineWithChildren,
  componentType: ComponentTypeEnum,
  id: number
): boolean {
  return (
    element?.espaces?.some(espace => doesEspaceHaveComposant(espace, componentType, id)) ||
    element?.children?.some(child => doesPatrimoineWithChildrenHaveComposant(child, componentType, id))
  );
}

export function filterComposantAttendus(
  patrimoine: Patrimoine,
  componentType: ComponentTypeEnum,
  id: number
): ComposantAttendu[] {
  return (
    patrimoine?.composantAttendus?.filter(
      composantAttendu =>
        (componentType === ComponentTypeEnum.societeComposant
          ? composantAttendu.societeComposant?.idSocieteComposant === id
          : composantAttendu.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id) &&
        patrimoine?.societePatrimoineHierarchie?.societeComposantRattachements?.some(
          societeComposantRattachement =>
            societeComposantRattachement?.idSocieteComposant === composantAttendu.societeComposant?.idSocieteComposant
        )
    ) || []
  );
}

export function filterComposants(composants: Composant[], componentType: ComponentTypeEnum, id: number): Composant[] {
  return (
    composants?.filter(composant =>
      componentType === ComponentTypeEnum.societeComposant
        ? composant?.societeComposant?.idSocieteComposant === id
        : composant?.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id
    ) || []
  );
}

export function filterChildrenByComposant(
  elements: PatrimoineWithChildren[],
  componentType: ComponentTypeEnum,
  id: number
): PatrimoineWithChildren[] {
  return elements
    ?.filter(element => canPatrimoineAndChildrenHaveComposant(element, componentType, id))
    ?.map(el => {
      const filteredComposantAttendus = filterComposantAttendus(el, componentType, id);
      const lastComposantAttenduUpdatedFound =
        filteredComposantAttendus?.sort(
          (a, b) => Date.parse(b?.updatedAt?.toString()) - Date.parse(a?.updatedAt?.toString())
        ) ?? [];
      return {
        ...el,
        composantAttendus: filteredComposantAttendus,
        lastComposantAttenduUpdated: lastComposantAttenduUpdatedFound?.[0] ?? null,
        espaces: el.espaces
          ?.filter(espace => canEspaceHaveComposant(espace, componentType, id))
          ?.map(espace => ({
            ...espace,
            composants: filterComposants(espace.composants, componentType, id)
          })),
        children: filterChildrenByComposant(el.children, componentType, id),
        showButton: canPatrimoineHaveComposant(el, componentType, id),
        reponse: setReponse(componentType, filteredComposantAttendus, id)
      };
    });
}

export function filterChildrenWithExistingComposant(
  elements: PatrimoineWithChildren[],
  componentType: ComponentTypeEnum,
  id: number,
  familleComposants?: SocieteComposant[]
): PatrimoineWithChildren[] {
  return (
    elements
      ?.filter(
        element =>
          canPatrimoineAndChildrenHaveComposant(element, componentType, id) ||
          doesPatrimoineWithChildrenHaveComposant(element, componentType, id)
      )
      ?.map(el => {
        const filteredComposantAttendus = filterComposantAttendus(el, componentType, id);
        const lastComposantAttenduUpdatedFound =
          filteredComposantAttendus?.sort(
            (a, b) => Date.parse(b?.updatedAt?.toString()) - Date.parse(a?.updatedAt?.toString())
          ) ?? [];
        return {
          ...el,
          composantAttendus: filteredComposantAttendus,
          lastComposantAttenduUpdated: lastComposantAttenduUpdatedFound?.[0] ?? null,
          espaces: el.espaces
            ?.filter(
              espace =>
                canEspaceHaveComposant(espace, componentType, id) || doesEspaceHaveComposant(espace, componentType, id)
            )
            ?.map(espace => ({
              ...espace,
              composants: filterComposants(espace.composants, componentType, id)
            })),
          children: filterChildrenWithExistingComposant(el.children, componentType, id, familleComposants),
          showButton: canPatrimoineHaveComposant(el, componentType, id),
          reponse: setReponse(componentType, filteredComposantAttendus, id),
          familleComposants: familleComposants?.filter(comp =>
            el.espaces?.some(espace =>
              canEspaceOnPatrimoineHaveComposant(
                espace,
                el,
                ComponentTypeEnum.societeComposant,
                comp.idSocieteComposant
              )
            )
          )
        };
      }) || []
  );
}

export function patrimoineHasComposant(patrimoine: PatrimoineWithChildren) {
  return patrimoine?.composantAttendus?.some(el => el?.reponse === ComposantAttenduReponseEnum.oui);
}

export function filterChildrenWithExistingComposantMassDuplication(
  elements: PatrimoineWithChildren[]
): PatrimoineWithChildren[] {
  return (
    elements?.map(el => {
      return {
        ...el,
        children: filterChildrenWithExistingComposantMassDuplication(el.children),
        showButton: patrimoineHasComposant(el)
      };
    }) || []
  );
}

export function filterChildrenWithEspaceFilter(
  elements: PatrimoineWithChildren[],
  groupedEspace?: SocieteEspaceGroupedByLibelle
): PatrimoineWithChildren[] {
  const espacesObj = transformArrayToObject(groupedEspace?.espaces || [], { key: 'idSocieteEspace' });
  const espacesObjFamille = transformArrayToObject(groupedEspace?.espaces || [], { key: 'idSocieteEspaceFamille' });
  return (
    elements?.map(el => {
      return {
        ...el,
        espaces: el.espaces?.map(espace => ({
          ...espace,
          composants: !groupedEspace || espacesObj[espace.societeEspace?.idSocieteEspace] ? espace.composants : []
        })),
        children: filterChildrenWithEspaceFilter(el.children, groupedEspace),
        showButton:
          el.showButton &&
          (!groupedEspace || el.espaces?.some(espace => espacesObj[espace.societeEspace?.idSocieteEspace])),
        familleComposants: el.familleComposants?.filter(
          comp =>
            !groupedEspace ||
            comp.societeComposantRattachements?.some(
              rattachement =>
                rattachement.idSocieteEspaceFamille && espacesObjFamille[rattachement.idSocieteEspaceFamille]
            )
        )
      };
    }) || []
  );
}

export function setReponse(
  componentType: ComponentTypeEnum,
  composantAttendus: ComposantAttendu[],
  id: number
): ComposantAttenduReponseEnum | null {
  if (componentType === ComponentTypeEnum.societeComposant) {
    return (
      composantAttendus?.find(composantAttendu => composantAttendu?.societeComposant?.idSocieteComposant === id)
        ?.reponse ?? null
    );
  } else {
    if (composantAttendus.every(el => el.reponse === ComposantAttenduReponseEnum.non)) {
      return ComposantAttenduReponseEnum.non;
    } else if (composantAttendus.some(el => el.reponse === ComposantAttenduReponseEnum.oui)) {
      return ComposantAttenduReponseEnum.oui;
    }
    return null;
  }
}

export function formatPatrimoineComposantValeurs(
  elements: PatrimoineWithChildren[],
  componentType: ComponentTypeEnum
): PatrimoineWithChildren[] {
  return elements?.map(el => ({
    ...el,
    espaces: el.espaces?.map(espace => ({
      ...espace,
      composants: espace.composants?.map(composant => {
        const espaces =
          componentType === ComponentTypeEnum.famille
            ? el.espaces?.filter(space =>
                canEspaceHaveComposant(space, ComponentTypeEnum.societeComposant, composant.idSocieteComposant)
              )
            : el.espaces;
        return {
          ...composant,
          showSelectEspace: espaces?.some(esp => !esp.defaut),
          valeursEntries: getSortedValeursEntries(composant.valeurs)
        };
      })
    })),
    children: formatPatrimoineComposantValeurs(el.children, componentType)
  }));
}

export function countNbComposantsOnPatrimoine(element: PatrimoineWithChildren): number {
  return (
    element?.espaces?.reduce(
      (acc, el) =>
        acc + (el.composants?.map(cmp => extractNumberFromComposant(cmp))?.reduce((res, val) => res + val, 0) || 0),
      0
    ) || 0
  );
}

export function isThereComposantOnArray(elements: PatrimoineWithChildren[]): boolean {
  return (
    elements?.some(
      patrimoine =>
        (patrimoine?.nbComposants && patrimoine.nbComposants > 0) ||
        (patrimoine?.children?.length && isThereComposantOnArray(patrimoine.children))
    ) ?? false
  );
}

export function patrimoineWithTitleInfos(elements: PatrimoineWithChildren[]): PatrimoineWithChildren[] {
  return (
    elements?.map(el => {
      const children = patrimoineWithTitleInfos(el.children);
      const title = getPatrimoineTitleWithPrefix(el);
      const composantAttendus =
        el.composantAttendus?.filter(
          composant =>
            !el.familleComposants ||
            el.familleComposants?.find(
              familleComposant => familleComposant.idSocieteComposant === composant.societeComposant?.idSocieteComposant
            )
        ) || [];
      return {
        ...el,
        children,
        title,
        // TODO: Confirmer le mode de calcul pour la variable, selon la réponse, supprimer une méthode ou l'autre
        // nbComposants: el.composantAttendus?.reduce((acc, curr) => acc + (curr.valeur || 0), 0) || 0,
        nbComposants:
          countNbComposantsOnPatrimoine(el) +
          (children?.reduce((acc, child) => acc + (child.nbComposants || 0), 0) || 0),
        answered: composantAttendus?.every(
          ca => !(ca?.reponse === ComposantAttenduReponseEnum.neSaisPas || !ca?.reponse)
        ),
        composantNotExisting: composantAttendus?.filter(composant => !composant.reponse) || []
      };
    }) || []
  );
}

export function groupPatrimoineComposants(elements: PatrimoineWithChildren[]): PatrimoineWithChildren[] {
  return (
    elements?.map(el => ({
      ...el,
      espaces: el.espaces?.map(espace => ({
        ...espace,
        composantsEntries: groupArrayPerSubObjectKeyValue(espace.composants, 'societeComposant.libelle')
      })),
      children: groupPatrimoineComposants(el.children)
    })) || []
  );
}

export function formatComposantInformation(composant: Composant): string {
  const userInformation = !composant?.user ? '' : ` par <b>${getFullName(composant?.user)}</b>`;
  return (
    `<b>Référence</b> composant : <b>${composant?.reference ?? 'absente'}</b> <br />` +
    `Créé le <b>${formatDateFullYear(composant?.createdAt)}</b> à ${formatTime(composant?.createdAt)}.<br />` +
    `Dernière mise à jour le <b>${formatDateFullYear(composant?.updatedAt)}</b> à ${formatTime(
      composant?.updatedAt
    )}${userInformation}.`
  );
}

// TODO: Déplacer vers un utils societe caracteristique ??
export function aggregationSocieteCaracteristique(societeCaracteristique: SocieteCaracteristique): string | null {
  if (societeCaracteristique?.aggregate === AggregateEnum.somme) {
    return 'sum';
  } else if (societeCaracteristique?.aggregate === AggregateEnum.moyenne) {
    return 'avg';
  } else if (societeCaracteristique?.aggregate === AggregateEnum.max) {
    return 'max';
  } else if (societeCaracteristique?.aggregate === AggregateEnum.min) {
    return 'min';
  }
  return null;
}

export function filterOnlyUsefulComposants(
  composants: (Composant | ComposantDocument)[],
  composantOrFamille?: SocieteComposant | SocieteComposantFamille
): Composant[] {
  const composantOrFamilleObj =
    composantOrFamille && 'idSocieteComposant' in composantOrFamille
      ? {
          [composantOrFamille.idSocieteComposant]: true
        }
      : (composantOrFamille as SocieteComposantFamille)?.societeComposants?.reduce((acc, curr) => {
          acc[curr.idSocieteComposant] = true;
          return acc;
        }, {} as DynamicType<boolean>) || {};
  return (composants as Composant[])?.filter(composant => composantOrFamilleObj[composant.idSocieteComposant]) || [];
}

// Check if the current node or any of its descendants have showButton set to true
function hasShowButton(pat: PatrimoineWithChildren): boolean {
  return pat?.showButton || pat?.children?.some(child => hasShowButton(child));
}

// Function to filter an array of Patrimoines
export function filterPatrimoinesMassDuplication(patrimoineArray: PatrimoineWithChildren[]): PatrimoineWithChildren[] {
  return patrimoineArray
    .filter(pat => hasShowButton(pat))
    .map(pat => ({
      ...pat,
      children: filterPatrimoinesMassDuplication(pat.children)
    }));
}
