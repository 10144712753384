import { createEntityAdapter } from '@ngrx/entity';
import { ProgScenarioEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgScenarioEntityState>;

export const adapter = createEntityAdapter<ProgScenarioEntityState>({
  selectId: o => o.idProgScenario
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progScenarioFeatureKey = 'progScenario';
