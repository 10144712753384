import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteComposantApiService } from '@get/store/api-services';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteComposant } from '@get/store/configs/normalization';
import { SocieteComposantSelectors } from '@get/store/selectors';
import { SocieteComposantRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { ComposantGeneratedActions } from '@get/store/actions';
import { Composant } from '@get/api-interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttendu } from '@get/api-interfaces';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachement } from '@get/api-interfaces';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposant } from '@get/api-interfaces';

export function getDefaultAddSocieteComposantActions(societeComposant: SocieteComposantEntityState, ids?: SocieteComposantRelationsIds): Action[] {
  const actions: Action[] = [SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({ societeComposants: [societeComposant] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteComposantSuccess({
        idSociete: ids.societe,
        idSocieteComposants: [societeComposant.idSocieteComposant]
      })
    );
    actions.push(
      SocieteComposantGeneratedActions.addSocieteSuccess({
        idSocieteComposant: societeComposant.idSocieteComposant,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.composantTemplate) {
    actions.push(
      ComposantTemplateGeneratedActions.addManySocieteComposantSuccess({
        idComposantTemplate: ids.composantTemplate,
        idSocieteComposants: [societeComposant.idSocieteComposant]
      })
    );
    actions.push(
      SocieteComposantGeneratedActions.addComposantTemplateSuccess({
        idSocieteComposant: societeComposant.idSocieteComposant,
        idComposantTemplate: ids.composantTemplate
      })
    );
  }

  if (ids?.societeComposantFamille) {
    actions.push(
      SocieteComposantFamilleGeneratedActions.addManySocieteComposantSuccess({
        idSocieteComposantFamille: ids.societeComposantFamille,
        idSocieteComposants: [societeComposant.idSocieteComposant]
      })
    );
    actions.push(
      SocieteComposantGeneratedActions.addSocieteComposantFamilleSuccess({
        idSocieteComposant: societeComposant.idSocieteComposant,
        idSocieteComposantFamille: ids.societeComposantFamille
      })
    );
  }

  if (ids?.composantGroupe) {
    actions.push(
      ComposantGroupeGeneratedActions.addManySocieteComposantSuccess({
        idComposantGroupe: ids.composantGroupe,
        idSocieteComposants: [societeComposant.idSocieteComposant]
      })
    );
    actions.push(
      SocieteComposantGeneratedActions.addComposantGroupeSuccess({
        idSocieteComposant: societeComposant.idSocieteComposant,
        idComposantGroupe: ids.composantGroupe
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManySocieteComposantSuccess({
        idFichier: ids.fichier,
        idSocieteComposants: [societeComposant.idSocieteComposant]
      })
    );
    actions.push(
      SocieteComposantGeneratedActions.addFichierSuccess({
        idSocieteComposant: societeComposant.idSocieteComposant,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idSocieteComposant: societeComposant.idSocieteComposant,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idSocieteComposant: societeComposant.idSocieteComposant,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[]
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.composants) {
    if (!Array.isArray(ids.composants)) {
      actions.push(
        ComposantGeneratedActions.upsertOneComposant({
          composant: {
            idSocieteComposant: societeComposant.idSocieteComposant,
            idComposant: ids.composants as number
          } as Composant
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyComposantSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idComposants: [ids.composants as number]
        })
      );
    } else {
      actions.push(
        ComposantGeneratedActions.upsertManyComposants({
          composants: (ids.composants as number[]).map(
            (idComposant: number) => ({
              idSocieteComposant: societeComposant.idSocieteComposant,
              idComposant
            })
          ) as Composant[]
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyComposantSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idComposants: ids.composants as number[]
        })
      );
    }
  }

  if (ids?.composantAttendus) {
    if (!Array.isArray(ids.composantAttendus)) {
      actions.push(
        ComposantAttenduGeneratedActions.upsertOneComposantAttendu({
          composantAttendu: {
            idSocieteComposant: societeComposant.idSocieteComposant,
            idComposantAttendu: ids.composantAttendus as number
          } as ComposantAttendu
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyComposantAttenduSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idComposantAttendus: [ids.composantAttendus as number]
        })
      );
    } else {
      actions.push(
        ComposantAttenduGeneratedActions.upsertManyComposantAttendus({
          composantAttendus: (ids.composantAttendus as number[]).map(
            (idComposantAttendu: number) => ({
              idSocieteComposant: societeComposant.idSocieteComposant,
              idComposantAttendu
            })
          ) as ComposantAttendu[]
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyComposantAttenduSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idComposantAttendus: ids.composantAttendus as number[]
        })
      );
    }
  }

  if (ids?.societeComposantRattachements) {
    if (!Array.isArray(ids.societeComposantRattachements)) {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertOneSocieteComposantRattachement({
          societeComposantRattachement: {
            idSocieteComposant: societeComposant.idSocieteComposant,
            idSocieteComposantRattachement: ids.societeComposantRattachements as number
          } as SocieteComposantRattachement
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idSocieteComposantRattachements: [ids.societeComposantRattachements as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachements({
          societeComposantRattachements: (ids.societeComposantRattachements as number[]).map(
            (idSocieteComposantRattachement: number) => ({
              idSocieteComposant: societeComposant.idSocieteComposant,
              idSocieteComposantRattachement
            })
          ) as SocieteComposantRattachement[]
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idSocieteComposantRattachements: ids.societeComposantRattachements as number[]
        })
      );
    }
  }

  if (ids?.progComposants) {
    if (!Array.isArray(ids.progComposants)) {
      actions.push(
        ProgComposantGeneratedActions.upsertOneProgComposant({
          progComposant: {
            idSocieteComposant: societeComposant.idSocieteComposant,
            idProgComposant: ids.progComposants as number
          } as ProgComposant
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyProgComposantSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idProgComposants: [ids.progComposants as number]
        })
      );
    } else {
      actions.push(
        ProgComposantGeneratedActions.upsertManyProgComposants({
          progComposants: (ids.progComposants as number[]).map(
            (idProgComposant: number) => ({
              idSocieteComposant: societeComposant.idSocieteComposant,
              idProgComposant
            })
          ) as ProgComposant[]
        })
      );
      actions.push(
        SocieteComposantGeneratedActions.addManyProgComposantSuccess({
          idSocieteComposant: societeComposant.idSocieteComposant,
          idProgComposants: ids.progComposants as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteComposantActions(societeComposant: SocieteComposantEntityState): Action[] {
  const actions: Action[] = [SocieteComposantGeneratedActions.deleteOneSocieteComposantSuccess({ idSocieteComposant: societeComposant.idSocieteComposant })];

  if (societeComposant.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idSocietes: [societeComposant.societe as number]
      })
    );
  }

  if (societeComposant.composantTemplate) {
    actions.push(
      ComposantTemplateGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idComposantTemplates: [societeComposant.composantTemplate as number]
      })
    );
  }

  if (societeComposant.societeComposantFamille) {
    actions.push(
      SocieteComposantFamilleGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idSocieteComposantFamilles: [societeComposant.societeComposantFamille as number]
      })
    );
  }

  if (societeComposant.composantGroupe) {
    actions.push(
      ComposantGroupeGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idComposantGroupes: [societeComposant.composantGroupe as number]
      })
    );
  }

  if (societeComposant.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idFichiers: [societeComposant.fichier as number]
      })
    );
  }

  if (societeComposant.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idSocieteCaracteristiques: societeComposant.societeCaracteristiques as number[]
      })
    );
  }

  if (societeComposant.composants) {
    actions.push(
      ComposantGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idComposants: societeComposant.composants as number[]
      })
    );
  }

  if (societeComposant.composantAttendus) {
    actions.push(
      ComposantAttenduGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idComposantAttendus: societeComposant.composantAttendus as number[]
      })
    );
  }

  if (societeComposant.societeComposantRattachements) {
    actions.push(
      SocieteComposantRattachementGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idSocieteComposantRattachements: societeComposant.societeComposantRattachements as number[]
      })
    );
  }

  if (societeComposant.progComposants) {
    actions.push(
      ProgComposantGeneratedActions.deleteManySocieteComposantSuccess({
        idSocieteComposants: [societeComposant.idSocieteComposant],
        idProgComposants: societeComposant.progComposants as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteComposantEffects {
  constructor(
    protected actions$: Actions,
    protected societeComposantApiService: SocieteComposantApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantGeneratedActions.getManySocieteComposants),
      switchMap(({ params }) =>
        this.societeComposantApiService.getSocieteComposants(params).pipe(
          map((societeComposants: SocieteComposant[]) => {
            return SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({ societeComposants });
          }),
          catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantGeneratedActions.upsertOneSocieteComposant),
      concatMap(
        ({
          societeComposant,
          ids
        }: {
          societeComposant: Partial<SocieteComposant>;
          ids?: SocieteComposantRelationsIds;
        }) => {
          if (societeComposant.idSocieteComposant) {
            return this.societeComposantApiService.updateSocieteComposant(societeComposant).pipe(
              map((societeComposantReturned: SocieteComposant) => {
                return SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({ societeComposants: [societeComposantReturned] });
              }),
              catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
            );
          } else {
            return this.societeComposantApiService.addSocieteComposant(societeComposant).pipe(
              mergeMap((societeComposantReturned: SocieteComposant) => getDefaultAddSocieteComposantActions(societeComposantReturned, ids)),
              catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteComposant$ = createEffect(() => {
    const selectSocieteComposantState$ = this.store$.select(SocieteComposantSelectors.selectSocieteComposantState);
    return this.actions$.pipe(
      ofType(SocieteComposantGeneratedActions.deleteOneSocieteComposant),
      withLatestFrom(selectSocieteComposantState$),
      concatMap(([{ idSocieteComposant }, state]) =>
        this.societeComposantApiService.deleteSocieteComposant(idSocieteComposant).pipe(
          mergeMap(_success => getDefaultDeleteSocieteComposantActions(state.entities[idSocieteComposant] as SocieteComposantEntityState)),
          catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteComposantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert),
      concatMap(({ societeComposants }) => {
        const actions: Action[] = getActionsToNormalizeSocieteComposant(societeComposants, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteComposant] Normalization After Upsert Success')];
      })
    );
  });
}
