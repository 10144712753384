import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { BoardStateGeneratedActions } from '@get/store/actions';
import { BoardStateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { BoardStateEntityState } from '@get/api-interfaces';

export const boardStateReducersGeneratedFunctions: ReducerTypes<BoardStateState.IState, readonly ActionCreator[]>[] = [
  on(BoardStateGeneratedActions.getManyBoardStates, (state: BoardStateState.IState) => setLoadingsState(state, true)),
  on(BoardStateGeneratedActions.upsertOneBoardState, (state: BoardStateState.IState) => setLoadingsState(state, true)),

  on(BoardStateGeneratedActions.upsertManyBoardStatesSuccess, (state: BoardStateState.IState, { boardStates }) =>
    BoardStateState.adapter.upsertMany(boardStates, setLoadingsState(state, false))
  ),
  on(BoardStateGeneratedActions.deleteOneBoardState, (state: BoardStateState.IState) => setLoadingsState(state, true)),
  on(BoardStateGeneratedActions.deleteOneBoardStateSuccess, (state: BoardStateState.IState, { idBoardState }) =>
    BoardStateState.adapter.removeOne(idBoardState, setLoadingsState(state, false))
  ),

  on(BoardStateGeneratedActions.clearBoardStates, () => BoardStateState.initialState),

  on(
    BoardStateGeneratedActions.addUserSuccess,
    (state: BoardStateState.IState, { idBoardState, idUser }) => {
      if (!state.entities[idBoardState]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idBoardState]: {
            ...state.entities[idBoardState],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    BoardStateGeneratedActions.deleteManyUserSuccess,
    (state: BoardStateState.IState, { idUsers, idBoardStates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idBoardStates.reduce((entities, idBoardState) => {
            if (!state.entities[idBoardState]?.user) {
              return entities;
            }
            entities[idBoardState] = {
              ...state.entities[idBoardState],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idBoardState]?.user
              )
                ? undefined
                : state.entities[idBoardState]?.user
            } as BoardStateEntityState;
            return entities;
          }, {} as Dictionary<BoardStateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: BoardStateState.IState,
  isLoading: boolean,
  isLoaded = true
): BoardStateState.IState {
  return { ...state, isLoaded, isLoading };
}
