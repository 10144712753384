import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgInterventionFamilleApiService } from '@get/store/api-services';
import { GeneratedProgInterventionFamilleEffects } from './prog-intervention-famille-generated.effects';

@Injectable()
export class ProgInterventionFamilleEffects extends GeneratedProgInterventionFamilleEffects {
  constructor(actions$: Actions, progInterventionFamilleApiService: ProgInterventionFamilleApiService, store$: Store<AppState>) {
    super(actions$, progInterventionFamilleApiService, store$);
  }
}
