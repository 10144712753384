import {
  GridError,
  ProgBiblioIntervention,
  ProgBiblioInterventionDataRow,
  ValidationErrorInterface
} from '@get/api-interfaces';

export function validateProgBiblioInterventionProperties(
  biblioInterventionToCreate: Partial<ProgBiblioIntervention>
): ValidationErrorInterface<ProgBiblioIntervention>[] {
  const errors: ValidationErrorInterface<ProgBiblioIntervention>[] = [];
  if (!biblioInterventionToCreate.idProgComposant) {
    errors.push({
      properties: ['idProgComposant'],
      message: 'You need an idProgComposant to do this action'
    });
  }
  return errors;
}

export function mergeProgBiblioInterventionAndGridErrors(
  biblioInterventions: ProgBiblioIntervention[],
  gridErrors: GridError[]
): ProgBiblioInterventionDataRow[] {
  const biblioInterventionDataRows: ProgBiblioInterventionDataRow[] = [
    ...(biblioInterventions as ProgBiblioInterventionDataRow[])
  ];
  gridErrors?.forEach(gridError => {
    if (gridError.error.idProgBiblioIntervention > 0) {
      const index = biblioInterventionDataRows.findIndex(
        biblioIntervention => biblioIntervention.idProgBiblioIntervention === gridError.error.idProgBiblioIntervention
      );

      biblioInterventionDataRows[index] = { ...gridError.error };
    } else {
      biblioInterventionDataRows.push({ ...gridError.error });
    }
  });

  return biblioInterventionDataRows;
}
