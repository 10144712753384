import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposantState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgComposantEntityState } from '@get/api-interfaces';

export const progComposantReducersGeneratedFunctions: ReducerTypes<ProgComposantState.IState, readonly ActionCreator[]>[] = [
  on(ProgComposantGeneratedActions.upsertOneProgComposant, (state: ProgComposantState.IState) => setLoadingsState(state, true)),

  on(ProgComposantGeneratedActions.upsertManyProgComposantsSuccess, (state: ProgComposantState.IState, { progComposants }) =>
    ProgComposantState.adapter.upsertMany(progComposants, setLoadingsState(state, false))
  ),
  on(ProgComposantGeneratedActions.deleteOneProgComposant, (state: ProgComposantState.IState) => setLoadingsState(state, true)),
  on(ProgComposantGeneratedActions.deleteOneProgComposantSuccess, (state: ProgComposantState.IState, { idProgComposant }) =>
    ProgComposantState.adapter.removeOne(idProgComposant, setLoadingsState(state, false))
  ),

  on(ProgComposantGeneratedActions.clearProgComposants, () => ProgComposantState.initialState),

  on(
    ProgComposantGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idProgBiblioInterventions }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idProgComposant]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgComposantState.IState, { idProgBiblioInterventions, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.progBiblioInterventions) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              progBiblioInterventions: (state.entities[idProgComposant]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.addManyProgPatrimoineInfoSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idProgPatrimoineInfos }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      const progPatrimoineInfos = (state.entities[idProgComposant]?.progPatrimoineInfos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            progPatrimoineInfos: progPatrimoineInfos.concat(
              idProgPatrimoineInfos.filter(id => progPatrimoineInfos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManyProgPatrimoineInfoSuccess,
    (state: ProgComposantState.IState, { idProgPatrimoineInfos, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.progPatrimoineInfos) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              progPatrimoineInfos: (state.entities[idProgComposant]?.progPatrimoineInfos as number[])?.filter(
                (idProgPatrimoineInfo: number) => !idProgPatrimoineInfos.some((id: number) => id === idProgPatrimoineInfo)
              )
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.addManyPatrimoineSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idPatrimoines }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      const patrimoines = (state.entities[idProgComposant]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ProgComposantState.IState, { idPatrimoines, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.patrimoines) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              patrimoines: (state.entities[idProgComposant]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.addSocieteSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idSociete }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManySocieteSuccess,
    (state: ProgComposantState.IState, { idSocietes, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.societe) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgComposant]?.societe
              )
                ? undefined
                : state.entities[idProgComposant]?.societe
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.addProgComposantSousGroupeSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idProgComposantSousGroupe }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            progComposantSousGroupe: idProgComposantSousGroupe
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManyProgComposantSousGroupeSuccess,
    (state: ProgComposantState.IState, { idProgComposantSousGroupes, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.progComposantSousGroupe) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              progComposantSousGroupe: idProgComposantSousGroupes.some(
                (idProgComposantSousGroupe: number) => idProgComposantSousGroupe === state.entities[idProgComposant]?.progComposantSousGroupe
              )
                ? undefined
                : state.entities[idProgComposant]?.progComposantSousGroupe
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.addSocieteComposantSuccess,
    (state: ProgComposantState.IState, { idProgComposant, idSocieteComposant }) => {
      if (!state.entities[idProgComposant]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposant]: {
            ...state.entities[idProgComposant],
            societeComposant: idSocieteComposant
          }
        }
      };
    }
  ),

  on(
    ProgComposantGeneratedActions.deleteManySocieteComposantSuccess,
    (state: ProgComposantState.IState, { idSocieteComposants, idProgComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposants.reduce((entities, idProgComposant) => {
            if (!state.entities[idProgComposant]?.societeComposant) {
              return entities;
            }
            entities[idProgComposant] = {
              ...state.entities[idProgComposant],
              societeComposant: idSocieteComposants.some(
                (idSocieteComposant: number) => idSocieteComposant === state.entities[idProgComposant]?.societeComposant
              )
                ? undefined
                : state.entities[idProgComposant]?.societeComposant
            } as ProgComposantEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgComposantState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgComposantState.IState {
  return { ...state, isLoaded, isLoading };
}
