import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionRelationsIds } from '@get/store/ids-interfaces';

export const ProgBiblioInterventionGeneratedActions = createActionGroup({
  source: 'Prog Biblio Intervention Generated',
  events: {
    'Upsert One Prog Biblio Intervention': props<{ progBiblioIntervention: Partial<ProgBiblioIntervention>; ids?: ProgBiblioInterventionRelationsIds; }>(),
    'Upsert Many Prog Biblio Interventions': props<{ progBiblioInterventions: Partial<ProgBiblioIntervention>[]; ids?: ProgBiblioInterventionRelationsIds; }>(),
    'Upsert Many Prog Biblio Interventions Success': props<{ progBiblioInterventions: ProgBiblioInterventionEntityState[] }>(),
    'Delete One Prog Biblio Intervention': props<{ idProgBiblioIntervention: number }>(),
    'Delete One Prog Biblio Intervention Success': props<{ idProgBiblioIntervention: number }>(),
    'Normalize Many Prog Biblio Interventions After Upsert': props<{ progBiblioInterventions: ProgBiblioInterventionEntityState[] }>(),
    'Prog Biblio Interventions Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Biblio Interventions': emptyProps(),
    'Add Many Prog Biblio Intervention Scenario Success': props<{ idProgBiblioIntervention: number; idProgBiblioInterventionScenarios: number[] }>(),
    'Delete Many Prog Biblio Intervention Scenario Success': props<{ idProgBiblioInterventionScenarios: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Many Prog Scenario Success': props<{ idProgBiblioIntervention: number; idProgScenarios: number[] }>(),
    'Delete Many Prog Scenario Success': props<{ idProgScenarios: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Societe Success': props<{ idProgBiblioIntervention: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Prog Composant Success': props<{ idProgBiblioIntervention: number; idProgComposant: number }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Prog Budget Success': props<{ idProgBiblioIntervention: number; idProgBudget: number }>(),
    'Delete Many Prog Budget Success': props<{ idProgBudgets: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Prog Unite Success': props<{ idProgBiblioIntervention: number; idProgUnite: number }>(),
    'Delete Many Prog Unite Success': props<{ idProgUnites: number[]; idProgBiblioInterventions: number[] }>(),
    'Add Prog Intervention Famille Success': props<{ idProgBiblioIntervention: number; idProgInterventionFamille: number }>(),
    'Delete Many Prog Intervention Famille Success': props<{ idProgInterventionFamilles: number[]; idProgBiblioInterventions: number[] }>()
  }
});
