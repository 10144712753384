import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';

export const SocieteCaracteristiqueGeneratedActions = createActionGroup({
  source: 'Societe Caracteristique Generated',
  events: {
    'Upsert One Societe Caracteristique': props<{ societeCaracteristique: Partial<SocieteCaracteristique>; ids?: SocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Societe Caracteristiques': props<{ societeCaracteristiques: Partial<SocieteCaracteristique>[]; ids?: SocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Societe Caracteristiques Success': props<{ societeCaracteristiques: SocieteCaracteristiqueEntityState[] }>(),
    'Delete One Societe Caracteristique': props<{ idSocieteCaracteristique: number }>(),
    'Delete One Societe Caracteristique Success': props<{ idSocieteCaracteristique: number }>(),
    'Normalize Many Societe Caracteristiques After Upsert': props<{ societeCaracteristiques: SocieteCaracteristiqueEntityState[] }>(),
    'Societe Caracteristiques Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Caracteristiques': emptyProps(),
    'Add Many Societe Caracteristique Choix Success': props<{ idSocieteCaracteristique: number; idSocieteCaracteristiqueChoices: number[] }>(),
    'Delete Many Societe Caracteristique Choix Success': props<{ idSocieteCaracteristiqueChoices: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Many Valeur Success': props<{ idSocieteCaracteristique: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Many Campagne Societe Caracteristique Success': props<{ idSocieteCaracteristique: number; idCampagneSocieteCaracteristiques: number[] }>(),
    'Delete Many Campagne Societe Caracteristique Success': props<{ idCampagneSocieteCaracteristiques: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Many Campagne Success': props<{ idSocieteCaracteristique: number; idCampagnes: number[] }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Many Societe Config Analyse Synthese Societe Caracteristique Success': props<{ idSocieteCaracteristique: number; idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Config Analyse Synthese Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Many Societe Config Analyse Synthese Success': props<{ idSocieteCaracteristique: number; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Delete Many Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSyntheses: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Societe Success': props<{ idSocieteCaracteristique: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Caracteristique Template Success': props<{ idSocieteCaracteristique: number; idCaracteristiqueTemplate: number }>(),
    'Delete Many Caracteristique Template Success': props<{ idCaracteristiqueTemplates: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Societe Composant Success': props<{ idSocieteCaracteristique: number; idSocieteComposant: number }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idSocieteCaracteristiques: number[] }>(),
    'Add Societe Patrimoine Hierarchie Success': props<{ idSocieteCaracteristique: number; idSocietePatrimoineHierarchie: number }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idSocieteCaracteristiques: number[] }>()
  }
});
