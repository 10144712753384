import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AnalyzeComposantDtoInterface,
  AnalyzeComposants,
  AnalyzeGraphicComposant,
  AnalyzeGraphicDtoInterface,
  AssertEmptyComposantAttenduAllPatrimoieInterface,
  AssertEmptyComposantAttenduIdsPatrimoineInterface,
  AssertEmptyComposantAttenduInterface,
  Composant,
  ComposantAttendu,
  DynamicType,
  Fichier,
  SocieteComposant,
  Valeur
} from '@get/api-interfaces';
import { environment } from '@get/environment';
import { RepositoryService } from '@get/services/repository';
import { EMPTY, Observable, catchError, first, firstValueFrom, map } from 'rxjs';
import { GeneratedComposantApiService } from './composant-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ComposantApiService extends GeneratedComposantApiService {
  constructor(repo: RepositoryService, private readonly http: HttpClient) {
    super(repo);
  }

  public updateComposant(composant: Partial<Composant>): Observable<Composant> {
    return this.repo.update('composant', composant);
  }

  public duplicateComposant(idComposant: number, params?: { idCampagne?: number }): Observable<Composant> {
    return this.repo.create<Composant>('composant/duplicate/' + idComposant, null, params);
  }

  public getComposantsPerType(params?: any): Observable<Composant[]> {
    return this.repo.getData<Composant[]>('composant/per-type', params);
  }

  public getCountComposantCaracteristiques(params?: AnalyzeGraphicDtoInterface): Promise<AnalyzeGraphicComposant> {
    return firstValueFrom(
      this.repo.getData<AnalyzeGraphicComposant>('composant/count-composant-caracteristiques', params)
    );
  }

  public getCountComposants(params?: AnalyzeComposantDtoInterface): Promise<AnalyzeComposants> {
    return firstValueFrom(this.repo.getData<AnalyzeComposants>('composant/count-composants', params));
  }

  public downloadOneFichier(idFichier: number): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + `/composant/fichier/download/${idFichier}`, {
        responseType: 'blob'
      })
      .pipe(first());
  }

  public moveComposant(
    composant: Partial<Composant>
  ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[] }> {
    return this.repo.update('composant/move', composant);
  }

  public assertEmptyComposantAttendu(params: AssertEmptyComposantAttenduInterface): Observable<any> {
    return this.repo.update('composant/composant-attendu/assert-empty', params);
  }

  public assertEmptyComposantAttenduAllPatrimoines(
    params: AssertEmptyComposantAttenduAllPatrimoieInterface
  ): Observable<any> {
    return this.repo.update('composant/composant-attendu/assert-empty-all-patrimoines', params);
  }

  public assertEmptyTree(params: AssertEmptyComposantAttenduInterface): Observable<any> {
    return this.repo.update('composant/composant-attendu/assert-empty-tree', params);
  }
  public assertEmptyTreeIdsPatrimoine(params: AssertEmptyComposantAttenduIdsPatrimoineInterface): Observable<any> {
    return this.repo.update('composant/composant-attendu/assert-empty-tree-ids-patrimoine', params);
  }

  public uploadManyImages(
    files: Array<File>,
    queryParams: { idOrganisation?: number } = {}
  ): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/composant/images').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media, encodeURIComponent(media.name));
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public uploadMany(files: Array<File>, queryParams: { idOrganisation?: number } = {}): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/composant/files').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media, encodeURIComponent(media.name));
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public deleteComposantWithComposantAttendu(
    idComposant: number
  ): Observable<{ affectedRows: number; composantAttendus: ComposantAttendu[] }> {
    return this.repo.delete('composant/with-composant-attendu/' + +idComposant);
  }

  public deleteComposantsBySociete(idSociete: number): Observable<number> {
    return this.repo.delete('composant/by-societe/' + +idSociete);
  }

  public deleteComposantsBySocieteComposant(idSocieteComposant: number): Observable<number> {
    return this.repo.delete('composant/by-societe-composant/' + +idSocieteComposant);
  }

  public getComposantsBySociete(idSociete: number): Promise<SocieteComposant[]> {
    return firstValueFrom(this.repo.getData('composant/by-societe/' + +idSociete));
  }

  public updateOneComposantAndValeurs(composant: Partial<Composant>): Observable<{
    composant: Composant;
    composantAttendus: ComposantAttendu[];
    isEspaceErrored?: boolean;
    fichiersErroredDict: DynamicType<boolean>;
    valeursErroredDict: DynamicType<boolean>;
  }> {
    return this.repo.update('composant/with-valeurs', composant);
  }

  public createOneComposantAndValeurs(
    composant: Partial<Composant>
  ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[]; isEspaceErrored?: boolean }> {
    return this.repo.create('composant/with-valeurs', composant);
  }

  public updateValeurReturnComposant(
    valeur: Partial<Valeur>,
    params?: { idCampagne?: number }
  ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[] }> {
    return this.repo.update('composant/valeur/return-composant', valeur, params);
  }

  public deleteValeurFichier(idValeurFichier: number): Observable<number> {
    return this.repo.delete('composant/fichier/' + +idValeurFichier);
  }
}
