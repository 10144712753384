import { RepositoryService } from '@get/services/repository';
import { ProgBudget } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgBudgetApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgBudget(progBudget: Partial<ProgBudget>): Observable<ProgBudget> {
    return this.repo.create<ProgBudget>('prog-budget', progBudget);
  }

  public updateProgBudget(progBudget: Partial<ProgBudget>): Observable<ProgBudget> {
    return this.repo.update('prog-budget', progBudget);
  }

  public deleteProgBudget(idProgBudget: number): Observable<number> {
    return this.repo.delete('prog-budget/' + +idProgBudget);
  }
}
