import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgComposantGroupeApiService } from '@get/store/api-services';
import { GeneratedProgComposantGroupeEffects } from './prog-composant-groupe-generated.effects';

@Injectable()
export class ProgComposantGroupeEffects extends GeneratedProgComposantGroupeEffects {
  constructor(actions$: Actions, progComposantGroupeApiService: ProgComposantGroupeApiService, store$: Store<AppState>) {
    super(actions$, progComposantGroupeApiService, store$);
  }
}
