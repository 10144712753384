import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgUniteState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progUniteRelations: string[] = ['progBiblioInterventions','progInterventionSaisies','progInterventionCalculs','societes',];

export const { selectEntities, selectAll } = ProgUniteState.adapter.getSelectors();

export const selectProgUniteState = createFeatureSelector<ProgUniteState.IState>(ProgUniteState.progUniteFeatureKey);

export const selectIsLoadedProgUnite = createSelector(
  selectProgUniteState,
  (state: ProgUniteState.IState) => state.isLoaded
);

export const selectIsLoadingProgUnite = createSelector(
  selectProgUniteState,
  (state: ProgUniteState.IState) => state.isLoading
);

export const selectIsReadyProgUnite = createSelector(
  selectProgUniteState,
  (state: ProgUniteState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgUnite = createSelector(
  selectProgUniteState,
  (state: ProgUniteState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgUnitesEntities = createSelector(selectProgUniteState, selectEntities);

export const selectProgUnitesArray = createSelector(selectProgUniteState, selectAll);

const progUnitesInObject = (progUnites: Dictionary<ProgUniteEntityState>) => ({ progUnites })

const selectProgUnitesEntitiesDictionary = createSelector(selectProgUnitesEntities, progUnitesInObject);

const selectAllProgUnitesObject = createSelector(selectProgUnitesEntities, progUnites => {
  return hydrateAll({ progUnites });
});

const selectOneProgUniteDictionary = (idProgUnite : number) =>
  createSelector(selectProgUnitesEntities, progUnites => ({
    progUnites: { [idProgUnite]: progUnites[idProgUnite] }
  }));

const selectOneProgUniteDictionaryWithoutChild = (idProgUnite : number) =>
  createSelector(selectProgUnitesEntities, progUnites => ({
    progUnite: progUnites[idProgUnite]
  }));

const selectAllProgUnitesSelectors: Dictionary<Selector> = {};
export function selectAllProgUnites(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgUnite>(
      schema,
      selectAllProgUnitesSelectors,
      selectProgUnitesEntitiesDictionary,
      getRelationSelectors,
      progUniteRelations,
      hydrateAll,
      'progUnite'
    );
  } else {
    return selectAllProgUnitesObject;
  }
}

export function selectAllProgUnitesDictionary(
  schema: SelectSchema = {},
  customKey = 'progUnites'
): Selector {
  return createSelector(selectAllProgUnites(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgUniteEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progUnites.length; i++) {
      res[customKey][result.progUnites[i].idProgUnite] = result.progUnites[i];
    }
    return res;
  });
}

export function selectOneProgUnite(
  schema: SelectSchema = {},
  idProgUnite: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgUniteDictionary(idProgUnite)];
  selectors.push(...getRelationSelectors(schema, progUniteRelations, 'progUnite'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgUniteDictionaryWithoutChild(idProgUnite);
  }
}

interface hydrateArgs {
  progUnites: Dictionary<ProgUniteEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progInterventionSaisies?: Dictionary<ProgInterventionEntityState>;
  progInterventionCalculs?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progUnites: (ProgUnite | null)[] } {
  const {
    progUnites,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progUnites: Object.keys(progUnites).map(idProgUnite =>
      hydrate(
        progUnites[idProgUnite] as ProgUniteEntityState,
        societes,
        progBiblioInterventions,
        progInterventionSaisies,
        progInterventionCalculs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progUnite: ProgUniteEntityState | null } {
  const {
    progUnites,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progUnite = Object.values(progUnites)[0];
  return {
    progUnite: hydrate(
      progUnite as ProgUniteEntityState,
      societes,
      progBiblioInterventions,
      progInterventionSaisies,
      progInterventionCalculs
    )
  };
}

function hydrate(
  progUnite: ProgUniteEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progInterventionSaisiesEntities?: Dictionary<ProgInterventionEntityState>,
  progInterventionCalculsEntities?: Dictionary<ProgInterventionEntityState>,
): ProgUnite | null {
  if (!progUnite) {
    return null;
  }

  const progUniteHydrated: ProgUniteEntityState = { ...progUnite };
  if (societeEntities) {
    progUniteHydrated.societe = societeEntities[progUnite.societe as number] as Societe;
  } else {
    delete progUniteHydrated.societe;
  }

  if (progBiblioInterventionEntities) {
    progUniteHydrated.progBiblioInterventions = ((progUniteHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete progUniteHydrated.progBiblioInterventions;
  }

  if (progInterventionSaisiesEntities) {
    progUniteHydrated.progInterventionSaisies = ((progUniteHydrated.progInterventionSaisies as number[]) || []).map(
      id => progInterventionSaisiesEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progUniteHydrated.progInterventionSaisies;
  }

  if (progInterventionCalculsEntities) {
    progUniteHydrated.progInterventionCalculs = ((progUniteHydrated.progInterventionCalculs as number[]) || []).map(
      id => progInterventionCalculsEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progUniteHydrated.progInterventionCalculs;
  }

  return progUniteHydrated as ProgUnite;
}
