import { createEntityAdapter } from '@ngrx/entity';
import { ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgComposantSousGroupeEntityState>;

export const adapter = createEntityAdapter<ProgComposantSousGroupeEntityState>({
  selectId: o => o.idProgComposantSousGroupe
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progComposantSousGroupeFeatureKey = 'progComposantSousGroupe';
