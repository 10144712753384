import { CaracteristiqueTypeEnum } from '@enums';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { compareStrings, formatDateFullYear } from '@utils';

export function formatActualValue(value: any, societeCaracteristique?: SocieteCaracteristique): string {
  if (
    (societeCaracteristique && societeCaracteristique?.type === CaracteristiqueTypeEnum.date && value) ||
    (!societeCaracteristique && value instanceof Date)
  ) {
    return formatDateFullYear(value);
  }
  if (typeof value === 'boolean') {
    return value ? 'Oui' : 'Non';
  }
  return value;
}

export function getSortedValeursEntries<U extends { societeCaracteristique: SocieteCaracteristique }>(
  valeurs: U[]
): { grp: string; index: number; valeurs: U[][] }[] {
  return Object.entries(
    valeurs?.reduce((acc, value) => {
      acc[value?.societeCaracteristique?.titreGroupement] = {
        ...(acc[value?.societeCaracteristique?.titreGroupement] || {}),
        [value?.societeCaracteristique?.ligne]: (
          acc[value?.societeCaracteristique?.titreGroupement]?.[value?.societeCaracteristique?.ligne] || []
        ).concat(value)
      };
      return acc;
    }, {} as { [grp: string]: { [ligne: number]: U[] } }) || {}
  )
    ?.sort((a, b) => (a[0] === 'null' ? -1 : b[0] === 'null' ? 1 : compareStrings(a[0], b[0])))
    ?.map((entry, index) => ({
      grp: entry[0],
      index,
      valeurs: Object.values(entry[1])
        .map(values => values?.sort((a, b) => a?.societeCaracteristique?.ordre - b?.societeCaracteristique?.ordre))
        ?.sort((a, b) => a?.[0]?.societeCaracteristique?.ligne - b?.[0]?.societeCaracteristique?.ligne)
    }));
}
