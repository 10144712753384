import { createEntityAdapter } from '@ngrx/entity';
import { ProgBudgetEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgBudgetEntityState>;

export const adapter = createEntityAdapter<ProgBudgetEntityState>({
  selectId: o => o.idProgBudget
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progBudgetFeatureKey = 'progBudget';
