import { ProgInterventionFamilleState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progInterventionFamilleReducersGeneratedFunctions } from './prog-intervention-famille-generated.reducer';

const progInterventionFamilleReducersFunctions = [...progInterventionFamilleReducersGeneratedFunctions];

const progInterventionFamilleReducer = createReducer(
  ProgInterventionFamilleState.initialState,
  ...progInterventionFamilleReducersFunctions
);

export function reducer(state: ProgInterventionFamilleState.IState | undefined, action: Action) {
  return progInterventionFamilleReducer(state, action);
}
