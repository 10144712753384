import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgScenarioRelationsIds } from '@get/store/ids-interfaces';

export const ProgScenarioGeneratedActions = createActionGroup({
  source: 'Prog Scenario Generated',
  events: {
    'Upsert One Prog Scenario': props<{ progScenario: Partial<ProgScenario>; ids?: ProgScenarioRelationsIds; }>(),
    'Upsert Many Prog Scenarios': props<{ progScenarios: Partial<ProgScenario>[]; ids?: ProgScenarioRelationsIds; }>(),
    'Upsert Many Prog Scenarios Success': props<{ progScenarios: ProgScenarioEntityState[] }>(),
    'Delete One Prog Scenario': props<{ idProgScenario: number }>(),
    'Delete One Prog Scenario Success': props<{ idProgScenario: number }>(),
    'Normalize Many Prog Scenarios After Upsert': props<{ progScenarios: ProgScenarioEntityState[] }>(),
    'Prog Scenarios Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Scenarios': emptyProps(),
    'Add Many Prog Intervention Scenario Success': props<{ idProgScenario: number; idProgInterventionScenarios: number[] }>(),
    'Delete Many Prog Intervention Scenario Success': props<{ idProgInterventionScenarios: number[]; idProgScenarios: number[] }>(),
    'Add Many Prog Biblio Intervention Scenario Success': props<{ idProgScenario: number; idProgBiblioInterventionScenarios: number[] }>(),
    'Delete Many Prog Biblio Intervention Scenario Success': props<{ idProgBiblioInterventionScenarios: number[]; idProgScenarios: number[] }>(),
    'Add Many Prog Biblio Intervention Success': props<{ idProgScenario: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgScenarios: number[] }>(),
    'Add Societe Success': props<{ idProgScenario: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgScenarios: number[] }>()
  }
});
