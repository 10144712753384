import {
  AssertEmptyComposantAttenduInterface,
  CampaignInfos,
  Composant,
  ComposantWithIdPatrimoine,
  DuplicateAndMoveInterface,
  RefetchAvancement,
  Valeur
} from '@get/api-interfaces';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const CampagneActions = createActionGroup({
  source: 'Campagne',
  events: {
    'Get One Campagne Patrimoines': props<{ idCampagne: number; params?: any }>(),
    'Send Responsible Mail': props<{ emails: string[]; campaignInfos: CampaignInfos; launch: boolean }>(),
    'Refetch Avancement': props<RefetchAvancement>(),
    'Create Many Composants': props<{ composants: Partial<Composant>[] }>(),
    'Upsert One Valeur And Return Composant': props<{
      valeur: Partial<Valeur>;
      params?: { idCampagne?: number };
    }>(),
    'Upsert Many Valeurs And Return Composants': props<{
      valeurs: Partial<Valeur>[];
      params?: { idCampagne?: number };
    }>(),
    'Upsert One Composant': props<{ composant: Partial<ComposantWithIdPatrimoine>; ids?: ComposantRelationsIds }>(),
    'Upsert Many Composant': props<{ composants: Partial<Composant>[]; oldComposants: Partial<Composant>[] }>(),
    'Upsert One Composant Modify Espace': props<{
      composant: Partial<Composant>;
      oldIdEspace: number;
    }>(),
    'Upsert One Valeur': props<{ valeur: Partial<Valeur> }>(),
    'Duplicate One Composant': props<{
      idComposant: number;
      ids?: ComposantRelationsIds;
      params?: { idCampagne?: number };
    }>(),
    'Duplicate And Move One Composant': props<{
      body: DuplicateAndMoveInterface[];
      params?: { idCampagne?: number };
    }>(),
    'Move One Composant': props<{ composant: Partial<ComposantWithIdPatrimoine>; oldIdEspace: number }>(),
    'Assert Empty Composant Attendu ': props<{ params: AssertEmptyComposantAttenduInterface }>(),
    'Assert Empty Tree ': props<{ params: AssertEmptyComposantAttenduInterface }>(),
    'Delete One Composant With Composant Attendu': props<{ idComposant: number }>(),
    'Delete One Valeur Fichier': props<{ idValeurFichier: number }>(),
    'Delete One Valeur Fichier Success': props<{ idValeurFichier: number }>()
  }
});
