import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { NewsletterApiService } from '@get/store/api-services';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeNewsletter } from '@get/store/configs/normalization';
import { NewsletterSelectors } from '@get/store/selectors';
import { NewsletterRelationsIds } from '@get/store/ids-interfaces';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { UserNewsletter } from '@get/api-interfaces';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { OrganisationNewsletter } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { Organisation } from '@get/api-interfaces';

export function getDefaultAddNewsletterActions(newsletter: NewsletterEntityState, ids?: NewsletterRelationsIds): Action[] {
  const actions: Action[] = [NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert({ newsletters: [newsletter] })];

  if (ids?.userNewsletters) {
    if (!Array.isArray(ids.userNewsletters)) {
      actions.push(
        UserNewsletterGeneratedActions.upsertOneUserNewsletter({
          userNewsletter: {
            idNewsletter: newsletter.idNewsletter,
            idUserNewsletter: ids.userNewsletters as number
          } as UserNewsletter
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyUserNewsletterSuccess({
          idNewsletter: newsletter.idNewsletter,
          idUserNewsletters: [ids.userNewsletters as number]
        })
      );
    } else {
      actions.push(
        UserNewsletterGeneratedActions.upsertManyUserNewsletters({
          userNewsletters: (ids.userNewsletters as number[]).map(
            (idUserNewsletter: number) => ({
              idNewsletter: newsletter.idNewsletter,
              idUserNewsletter
            })
          ) as UserNewsletter[]
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyUserNewsletterSuccess({
          idNewsletter: newsletter.idNewsletter,
          idUserNewsletters: ids.userNewsletters as number[]
        })
      );
    }
  }

  if (ids?.organisationNewsletters) {
    if (!Array.isArray(ids.organisationNewsletters)) {
      actions.push(
        OrganisationNewsletterGeneratedActions.upsertOneOrganisationNewsletter({
          organisationNewsletter: {
            idNewsletter: newsletter.idNewsletter,
            idOrganisationNewsletter: ids.organisationNewsletters as number
          } as OrganisationNewsletter
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyOrganisationNewsletterSuccess({
          idNewsletter: newsletter.idNewsletter,
          idOrganisationNewsletters: [ids.organisationNewsletters as number]
        })
      );
    } else {
      actions.push(
        OrganisationNewsletterGeneratedActions.upsertManyOrganisationNewsletters({
          organisationNewsletters: (ids.organisationNewsletters as number[]).map(
            (idOrganisationNewsletter: number) => ({
              idNewsletter: newsletter.idNewsletter,
              idOrganisationNewsletter
            })
          ) as OrganisationNewsletter[]
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyOrganisationNewsletterSuccess({
          idNewsletter: newsletter.idNewsletter,
          idOrganisationNewsletters: ids.organisationNewsletters as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idNewsletter: newsletter.idNewsletter,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyUserSuccess({
          idNewsletter: newsletter.idNewsletter,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map(
            (idUser: number) => ({
              idNewsletter: newsletter.idNewsletter,
              idUser
            })
          ) as User[] & any[]
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyUserSuccess({
          idNewsletter: newsletter.idNewsletter,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.organisations) {
    if (!Array.isArray(ids.organisations)) {
      actions.push(
        OrganisationGeneratedActions.upsertOneOrganisation({
          organisation: {
            idNewsletter: newsletter.idNewsletter,
            idOrganisation: ids.organisations as number
          } as Organisation & any
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyOrganisationSuccess({
          idNewsletter: newsletter.idNewsletter,
          idOrganisations: [ids.organisations as number]
        })
      );
    } else {
      actions.push(
        OrganisationGeneratedActions.upsertManyOrganisations({
          organisations: (ids.organisations as number[]).map(
            (idOrganisation: number) => ({
              idNewsletter: newsletter.idNewsletter,
              idOrganisation
            })
          ) as Organisation[] & any[]
        })
      );
      actions.push(
        NewsletterGeneratedActions.addManyOrganisationSuccess({
          idNewsletter: newsletter.idNewsletter,
          idOrganisations: ids.organisations as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteNewsletterActions(newsletter: NewsletterEntityState): Action[] {
  const actions: Action[] = [NewsletterGeneratedActions.deleteOneNewsletterSuccess({ idNewsletter: newsletter.idNewsletter })];

  if (newsletter.userNewsletters) {
    actions.push(
      UserNewsletterGeneratedActions.deleteManyNewsletterSuccess({
        idNewsletters: [newsletter.idNewsletter],
        idUserNewsletters: newsletter.userNewsletters as number[]
      })
    );
  }

  if (newsletter.organisationNewsletters) {
    actions.push(
      OrganisationNewsletterGeneratedActions.deleteManyNewsletterSuccess({
        idNewsletters: [newsletter.idNewsletter],
        idOrganisationNewsletters: newsletter.organisationNewsletters as number[]
      })
    );
  }

  if (newsletter.users) {
    actions.push(
      UserGeneratedActions.deleteManyNewsletterSuccess({
        idNewsletters: [newsletter.idNewsletter],
        idUsers: newsletter.users as number[]
      })
    );
  }

  if (newsletter.organisations) {
    actions.push(
      OrganisationGeneratedActions.deleteManyNewsletterSuccess({
        idNewsletters: [newsletter.idNewsletter],
        idOrganisations: newsletter.organisations as number[]
      })
    );
  }

  return actions;
}

export class GeneratedNewsletterEffects {
  constructor(
    protected actions$: Actions,
    protected newsletterApiService: NewsletterApiService,
    protected store$: Store<AppState>
  ) {}

  getManyNewsletters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsletterGeneratedActions.getManyNewsletters),
      switchMap(({ params }) =>
        this.newsletterApiService.getNewsletters(params).pipe(
          map((newsletters: Newsletter[]) => {
            return NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert({ newsletters });
          }),
          catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
        )
      )
    );
  });

  upsertOneNewsletter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsletterGeneratedActions.upsertOneNewsletter),
      concatMap(
        ({
          newsletter,
          ids
        }: {
          newsletter: Partial<Newsletter>;
          ids?: NewsletterRelationsIds;
        }) => {
          if (newsletter.idNewsletter) {
            return this.newsletterApiService.updateNewsletter(newsletter).pipe(
              map((newsletterReturned: Newsletter) => {
                return NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert({ newsletters: [newsletterReturned] });
              }),
              catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
            );
          } else {
            return this.newsletterApiService.addNewsletter(newsletter).pipe(
              mergeMap((newsletterReturned: Newsletter) => getDefaultAddNewsletterActions(newsletterReturned, ids)),
              catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneNewsletter$ = createEffect(() => {
    const selectNewsletterState$ = this.store$.select(NewsletterSelectors.selectNewsletterState);
    return this.actions$.pipe(
      ofType(NewsletterGeneratedActions.deleteOneNewsletter),
      withLatestFrom(selectNewsletterState$),
      concatMap(([{ idNewsletter }, state]) =>
        this.newsletterApiService.deleteNewsletter(idNewsletter).pipe(
          mergeMap(_success => getDefaultDeleteNewsletterActions(state.entities[idNewsletter] as NewsletterEntityState)),
          catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
        )
      )
    );
  });

  normalizeManyNewslettersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert),
      concatMap(({ newsletters }) => {
        const actions: Action[] = getActionsToNormalizeNewsletter(newsletters, StoreActionType.upsert);
        return [getMultiAction(actions, '[Newsletter] Normalization After Upsert Success')];
      })
    );
  });
}
