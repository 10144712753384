import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UsageGeneratedActions } from '@get/store/actions';
import { UsageState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UsageEntityState } from '@get/api-interfaces';

export const usageReducersGeneratedFunctions: ReducerTypes<UsageState.IState, readonly ActionCreator[]>[] = [
  on(UsageGeneratedActions.getManyUsages, (state: UsageState.IState) => setLoadingsState(state, true)),
  on(UsageGeneratedActions.upsertOneUsage, (state: UsageState.IState) => setLoadingsState(state, true)),

  on(UsageGeneratedActions.upsertManyUsagesSuccess, (state: UsageState.IState, { usages }) =>
    UsageState.adapter.upsertMany(usages, setLoadingsState(state, false))
  ),
  on(UsageGeneratedActions.deleteOneUsage, (state: UsageState.IState) => setLoadingsState(state, true)),
  on(UsageGeneratedActions.deleteOneUsageSuccess, (state: UsageState.IState, { idUsage }) =>
    UsageState.adapter.removeOne(idUsage, setLoadingsState(state, false))
  ),

  on(UsageGeneratedActions.clearUsages, () => UsageState.initialState),

  on(
    UsageGeneratedActions.addManyUsageComposantTemplateSuccess,
    (state: UsageState.IState, { idUsage, idUsageComposantTemplates }) => {
      if (!state.entities[idUsage]) {
        return state;
      }
      const usageComposantTemplates = (state.entities[idUsage]?.usageComposantTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsage]: {
            ...state.entities[idUsage],
            usageComposantTemplates: usageComposantTemplates.concat(
              idUsageComposantTemplates.filter(id => usageComposantTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.deleteManyUsageComposantTemplateSuccess,
    (state: UsageState.IState, { idUsageComposantTemplates, idUsages }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsages.reduce((entities, idUsage) => {
            if (!state.entities[idUsage]?.usageComposantTemplates) {
              return entities;
            }
            entities[idUsage] = {
              ...state.entities[idUsage],
              usageComposantTemplates: (state.entities[idUsage]?.usageComposantTemplates as number[])?.filter(
                (idUsageComposantTemplate: number) => !idUsageComposantTemplates.some((id: number) => id === idUsageComposantTemplate)
              )
            } as UsageEntityState;
            return entities;
          }, {} as Dictionary<UsageEntityState>)
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.addManyUsageCaracteristiqueTemplateSuccess,
    (state: UsageState.IState, { idUsage, idUsageCaracteristiqueTemplates }) => {
      if (!state.entities[idUsage]) {
        return state;
      }
      const usageCaracteristiqueTemplates = (state.entities[idUsage]?.usageCaracteristiqueTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsage]: {
            ...state.entities[idUsage],
            usageCaracteristiqueTemplates: usageCaracteristiqueTemplates.concat(
              idUsageCaracteristiqueTemplates.filter(id => usageCaracteristiqueTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.deleteManyUsageCaracteristiqueTemplateSuccess,
    (state: UsageState.IState, { idUsageCaracteristiqueTemplates, idUsages }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsages.reduce((entities, idUsage) => {
            if (!state.entities[idUsage]?.usageCaracteristiqueTemplates) {
              return entities;
            }
            entities[idUsage] = {
              ...state.entities[idUsage],
              usageCaracteristiqueTemplates: (state.entities[idUsage]?.usageCaracteristiqueTemplates as number[])?.filter(
                (idUsageCaracteristiqueTemplate: number) => !idUsageCaracteristiqueTemplates.some((id: number) => id === idUsageCaracteristiqueTemplate)
              )
            } as UsageEntityState;
            return entities;
          }, {} as Dictionary<UsageEntityState>)
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.addManyComposantTemplateSuccess,
    (state: UsageState.IState, { idUsage, idComposantTemplates }) => {
      if (!state.entities[idUsage]) {
        return state;
      }
      const composantTemplates = (state.entities[idUsage]?.composantTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsage]: {
            ...state.entities[idUsage],
            composantTemplates: composantTemplates.concat(
              idComposantTemplates.filter(id => composantTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: UsageState.IState, { idComposantTemplates, idUsages }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsages.reduce((entities, idUsage) => {
            if (!state.entities[idUsage]?.composantTemplates) {
              return entities;
            }
            entities[idUsage] = {
              ...state.entities[idUsage],
              composantTemplates: (state.entities[idUsage]?.composantTemplates as number[])?.filter(
                (idComposantTemplate: number) => !idComposantTemplates.some((id: number) => id === idComposantTemplate)
              )
            } as UsageEntityState;
            return entities;
          }, {} as Dictionary<UsageEntityState>)
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.addManyCaracteristiqueTemplateSuccess,
    (state: UsageState.IState, { idUsage, idCaracteristiqueTemplates }) => {
      if (!state.entities[idUsage]) {
        return state;
      }
      const caracteristiqueTemplates = (state.entities[idUsage]?.caracteristiqueTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsage]: {
            ...state.entities[idUsage],
            caracteristiqueTemplates: caracteristiqueTemplates.concat(
              idCaracteristiqueTemplates.filter(id => caracteristiqueTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UsageGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: UsageState.IState, { idCaracteristiqueTemplates, idUsages }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsages.reduce((entities, idUsage) => {
            if (!state.entities[idUsage]?.caracteristiqueTemplates) {
              return entities;
            }
            entities[idUsage] = {
              ...state.entities[idUsage],
              caracteristiqueTemplates: (state.entities[idUsage]?.caracteristiqueTemplates as number[])?.filter(
                (idCaracteristiqueTemplate: number) => !idCaracteristiqueTemplates.some((id: number) => id === idCaracteristiqueTemplate)
              )
            } as UsageEntityState;
            return entities;
          }, {} as Dictionary<UsageEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UsageState.IState,
  isLoading: boolean,
  isLoaded = true
): UsageState.IState {
  return { ...state, isLoaded, isLoading };
}
