import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteCaracteristiqueService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueSelectors.selectIsLoadedSocieteCaracteristique));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueSelectors.selectIsLoadingSocieteCaracteristique));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteCaracteristiqueSelectors.selectIsReadyAndLoadedSocieteCaracteristique as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteCaracteristiques(schema: SelectSchema = {}): Observable<SocieteCaracteristique[]> {
    return this.store$.pipe(select(SocieteCaracteristiqueSelectors.selectAllSocieteCaracteristiques(schema))).pipe(
      switchMap(({ societeCaracteristiques }: { societeCaracteristiques: SocieteCaracteristique[] }) => {
        return this.getReady(schema).pipe(map(() => societeCaracteristiques));
      })
    );
  }

  public selectOneSocieteCaracteristique(
    idSocieteCaracteristique: number,
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristique> {
    return this.store$
      .pipe(select(SocieteCaracteristiqueSelectors.selectOneSocieteCaracteristique(schema, idSocieteCaracteristique)))
      .pipe(
        switchMap(({ societeCaracteristique }: { societeCaracteristique: SocieteCaracteristique }) => {
          return this.getReady(schema).pipe(map(() => societeCaracteristique));
        })
      );
  }

  public upsertOneSocieteCaracteristique(
    societeCaracteristique: Partial<SocieteCaracteristique>,
    ids: SocieteCaracteristiqueRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteCaracteristique> {
    this.store$.dispatch(SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({ societeCaracteristique, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert,
        SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure,
        true
      );
    }
  }

  public deleteOneSocieteCaracteristique(
    idSocieteCaracteristique: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristique({ idSocieteCaracteristique }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristiqueSuccess,
        SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure
      );
    }
  }
}
