import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgComposant } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgComposantRelationsIds } from '@get/store/ids-interfaces';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposantSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgComposantService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantSelectors.selectIsLoadedProgComposant));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantSelectors.selectIsLoadingProgComposant));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgComposantSelectors.selectIsReadyAndLoadedProgComposant as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgComposants(schema: SelectSchema = {}): Observable<ProgComposant[]> {
    return this.store$.pipe(select(ProgComposantSelectors.selectAllProgComposants(schema))).pipe(
      switchMap(({ progComposants }: { progComposants: ProgComposant[] }) => {
        return this.getReady(schema).pipe(map(() => progComposants));
      })
    );
  }

  public selectOneProgComposant(
    idProgComposant: number,
    schema: SelectSchema = {}
  ): Observable<ProgComposant> {
    return this.store$
      .pipe(select(ProgComposantSelectors.selectOneProgComposant(schema, idProgComposant)))
      .pipe(
        switchMap(({ progComposant }: { progComposant: ProgComposant }) => {
          return this.getReady(schema).pipe(map(() => progComposant));
        })
      );
  }

  public upsertOneProgComposant(
    progComposant: Partial<ProgComposant>,
    ids: ProgComposantRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgComposant> {
    this.store$.dispatch(ProgComposantGeneratedActions.upsertOneProgComposant({ progComposant, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantGeneratedActions.normalizeManyProgComposantsAfterUpsert,
        ProgComposantGeneratedActions.progComposantsFailure,
        true
      );
    }
  }

  public deleteOneProgComposant(
    idProgComposant: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgComposantGeneratedActions.deleteOneProgComposant({ idProgComposant }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantGeneratedActions.deleteOneProgComposantSuccess,
        ProgComposantGeneratedActions.progComposantsFailure
      );
    }
  }
}
