import { RepositoryService } from '@get/services/repository';
import { PatrimoineHierarchieTemplate } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedPatrimoineHierarchieTemplateApiService {
  constructor(protected repo: RepositoryService) {}

  public getPatrimoineHierarchieTemplates(params?: any): Observable<PatrimoineHierarchieTemplate[]> {
    return this.repo.getData<PatrimoineHierarchieTemplate[]>('patrimoine-hierarchie-template', params);
  }

  public addPatrimoineHierarchieTemplate(patrimoineHierarchieTemplate: Partial<PatrimoineHierarchieTemplate>): Observable<PatrimoineHierarchieTemplate> {
    return this.repo.create<PatrimoineHierarchieTemplate>('patrimoine-hierarchie-template', patrimoineHierarchieTemplate);
  }

  public updatePatrimoineHierarchieTemplate(patrimoineHierarchieTemplate: Partial<PatrimoineHierarchieTemplate>): Observable<PatrimoineHierarchieTemplate> {
    return this.repo.update('patrimoine-hierarchie-template', patrimoineHierarchieTemplate);
  }

  public deletePatrimoineHierarchieTemplate(idPatrimoineHierarchieTemplate: number): Observable<number> {
    return this.repo.delete('patrimoine-hierarchie-template/' + +idPatrimoineHierarchieTemplate);
  }
}
