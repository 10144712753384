import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteTerritoireUserState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeTerritoireUserRelations: string[] = ['societeTerritoires','users',];

export const { selectEntities, selectAll } = SocieteTerritoireUserState.adapter.getSelectors();

export const selectSocieteTerritoireUserState = createFeatureSelector<SocieteTerritoireUserState.IState>(SocieteTerritoireUserState.societeTerritoireUserFeatureKey);

export const selectIsLoadedSocieteTerritoireUser = createSelector(
  selectSocieteTerritoireUserState,
  (state: SocieteTerritoireUserState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteTerritoireUser = createSelector(
  selectSocieteTerritoireUserState,
  (state: SocieteTerritoireUserState.IState) => state.isLoading
);

export const selectIsReadySocieteTerritoireUser = createSelector(
  selectSocieteTerritoireUserState,
  (state: SocieteTerritoireUserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteTerritoireUser = createSelector(
  selectSocieteTerritoireUserState,
  (state: SocieteTerritoireUserState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteTerritoireUsersEntities = createSelector(selectSocieteTerritoireUserState, selectEntities);

export const selectSocieteTerritoireUsersArray = createSelector(selectSocieteTerritoireUserState, selectAll);

const societeTerritoireUsersInObject = (societeTerritoireUsers: Dictionary<SocieteTerritoireUserEntityState>) => ({ societeTerritoireUsers })

const selectSocieteTerritoireUsersEntitiesDictionary = createSelector(selectSocieteTerritoireUsersEntities, societeTerritoireUsersInObject);

const selectAllSocieteTerritoireUsersObject = createSelector(selectSocieteTerritoireUsersEntities, societeTerritoireUsers => {
  return hydrateAll({ societeTerritoireUsers });
});

const selectOneSocieteTerritoireUserDictionary = (idSocieteTerritoireUser : number) =>
  createSelector(selectSocieteTerritoireUsersEntities, societeTerritoireUsers => ({
    societeTerritoireUsers: { [idSocieteTerritoireUser]: societeTerritoireUsers[idSocieteTerritoireUser] }
  }));

const selectOneSocieteTerritoireUserDictionaryWithoutChild = (idSocieteTerritoireUser : number) =>
  createSelector(selectSocieteTerritoireUsersEntities, societeTerritoireUsers => ({
    societeTerritoireUser: societeTerritoireUsers[idSocieteTerritoireUser]
  }));

const selectAllSocieteTerritoireUsersSelectors: Dictionary<Selector> = {};
export function selectAllSocieteTerritoireUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteTerritoireUser>(
      schema,
      selectAllSocieteTerritoireUsersSelectors,
      selectSocieteTerritoireUsersEntitiesDictionary,
      getRelationSelectors,
      societeTerritoireUserRelations,
      hydrateAll,
      'societeTerritoireUser'
    );
  } else {
    return selectAllSocieteTerritoireUsersObject;
  }
}

export function selectAllSocieteTerritoireUsersDictionary(
  schema: SelectSchema = {},
  customKey = 'societeTerritoireUsers'
): Selector {
  return createSelector(selectAllSocieteTerritoireUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteTerritoireUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeTerritoireUsers.length; i++) {
      res[customKey][result.societeTerritoireUsers[i].idSocieteTerritoireUser] = result.societeTerritoireUsers[i];
    }
    return res;
  });
}

export function selectOneSocieteTerritoireUser(
  schema: SelectSchema = {},
  idSocieteTerritoireUser: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteTerritoireUserDictionary(idSocieteTerritoireUser)];
  selectors.push(...getRelationSelectors(schema, societeTerritoireUserRelations, 'societeTerritoireUser'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteTerritoireUserDictionaryWithoutChild(idSocieteTerritoireUser);
  }
}

interface hydrateArgs {
  societeTerritoireUsers: Dictionary<SocieteTerritoireUserEntityState>;
  societeTerritoires?: Dictionary<SocieteTerritoireEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeTerritoireUsers: (SocieteTerritoireUser | null)[] } {
  const {
    societeTerritoireUsers,
    societeTerritoires,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeTerritoireUsers: Object.keys(societeTerritoireUsers).map(idSocieteTerritoireUser =>
      hydrate(
        societeTerritoireUsers[idSocieteTerritoireUser] as SocieteTerritoireUserEntityState,
        societeTerritoires,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeTerritoireUser: SocieteTerritoireUserEntityState | null } {
  const {
    societeTerritoireUsers,
    societeTerritoires,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeTerritoireUser = Object.values(societeTerritoireUsers)[0];
  return {
    societeTerritoireUser: hydrate(
      societeTerritoireUser as SocieteTerritoireUserEntityState,
      societeTerritoires,
      users
    )
  };
}

function hydrate(
  societeTerritoireUser: SocieteTerritoireUserEntityState,
  societeTerritoireEntities?: Dictionary<SocieteTerritoireEntityState>,
  userEntities?: Dictionary<UserEntityState>,
): SocieteTerritoireUser | null {
  if (!societeTerritoireUser) {
    return null;
  }

  const societeTerritoireUserHydrated: SocieteTerritoireUserEntityState = { ...societeTerritoireUser };
  if (societeTerritoireEntities) {
    societeTerritoireUserHydrated.societeTerritoire = societeTerritoireEntities[societeTerritoireUser.societeTerritoire as number] as SocieteTerritoire;
  } else {
    delete societeTerritoireUserHydrated.societeTerritoire;
  }
  if (userEntities) {
    societeTerritoireUserHydrated.user = userEntities[societeTerritoireUser.user as number] as User;
  } else {
    delete societeTerritoireUserHydrated.user;
  }

  return societeTerritoireUserHydrated as SocieteTerritoireUser;
}
