<div mat-dialog-title [ngClass]="theme">
  <p>{{ title }}</p>
  <div class="close-dialog" [mat-dialog-close]="undefined" tabindex="-1">
    <i class="icon-ic_fluent_dismiss_24_regular flex items-center justify-center"></i>
  </div>
</div>
@if (!isQuestionArray) {
<div mat-dialog-content>
  <p class="text-padding" [innerHTML]="question"></p>
</div>
} @else {
<div mat-dialog-content>
  <p *ngFor="let q of questions" class="text-padding">{{ q }}</p>
</div>
}
<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]>Ok</button>
</div>
