import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { UsageComposantTemplate, UsageComposantTemplateEntityState } from '@get/api-interfaces';
import { UsageCaracteristiqueTemplate, UsageCaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UsageState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const usageRelations: string[] = ['usageComposantTemplates','usageCaracteristiqueTemplates','composantTemplates','caracteristiqueTemplates',];

export const { selectEntities, selectAll } = UsageState.adapter.getSelectors();

export const selectUsageState = createFeatureSelector<UsageState.IState>(UsageState.usageFeatureKey);

export const selectIsLoadedUsage = createSelector(
  selectUsageState,
  (state: UsageState.IState) => state.isLoaded
);

export const selectIsLoadingUsage = createSelector(
  selectUsageState,
  (state: UsageState.IState) => state.isLoading
);

export const selectIsReadyUsage = createSelector(
  selectUsageState,
  (state: UsageState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUsage = createSelector(
  selectUsageState,
  (state: UsageState.IState) => state.isLoaded && !state.isLoading
);

export const selectUsagesEntities = createSelector(selectUsageState, selectEntities);

export const selectUsagesArray = createSelector(selectUsageState, selectAll);

const usagesInObject = (usages: Dictionary<UsageEntityState>) => ({ usages })

const selectUsagesEntitiesDictionary = createSelector(selectUsagesEntities, usagesInObject);

const selectAllUsagesObject = createSelector(selectUsagesEntities, usages => {
  return hydrateAll({ usages });
});

const selectOneUsageDictionary = (idUsage : number) =>
  createSelector(selectUsagesEntities, usages => ({
    usages: { [idUsage]: usages[idUsage] }
  }));

const selectOneUsageDictionaryWithoutChild = (idUsage : number) =>
  createSelector(selectUsagesEntities, usages => ({
    usage: usages[idUsage]
  }));

const selectAllUsagesSelectors: Dictionary<Selector> = {};
export function selectAllUsages(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Usage>(
      schema,
      selectAllUsagesSelectors,
      selectUsagesEntitiesDictionary,
      getRelationSelectors,
      usageRelations,
      hydrateAll,
      'usage'
    );
  } else {
    return selectAllUsagesObject;
  }
}

export function selectAllUsagesDictionary(
  schema: SelectSchema = {},
  customKey = 'usages'
): Selector {
  return createSelector(selectAllUsages(schema), result => {
    const res = { [customKey]: {} as Dictionary<UsageEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.usages.length; i++) {
      res[customKey][result.usages[i].idUsage] = result.usages[i];
    }
    return res;
  });
}

export function selectOneUsage(
  schema: SelectSchema = {},
  idUsage: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUsageDictionary(idUsage)];
  selectors.push(...getRelationSelectors(schema, usageRelations, 'usage'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUsageDictionaryWithoutChild(idUsage);
  }
}

interface hydrateArgs {
  usages: Dictionary<UsageEntityState>;
  usageComposantTemplates?: Dictionary<UsageComposantTemplateEntityState>;
  usageCaracteristiqueTemplates?: Dictionary<UsageCaracteristiqueTemplateEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { usages: (Usage | null)[] } {
  const {
    usages,
    usageComposantTemplates,
    usageCaracteristiqueTemplates,
    composantTemplates,
    caracteristiqueTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    usages: Object.keys(usages).map(idUsage =>
      hydrate(
        usages[idUsage] as UsageEntityState,
        usageComposantTemplates,
        usageCaracteristiqueTemplates,
        composantTemplates,
        caracteristiqueTemplates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { usage: UsageEntityState | null } {
  const {
    usages,
    usageComposantTemplates,
    usageCaracteristiqueTemplates,
    composantTemplates,
    caracteristiqueTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const usage = Object.values(usages)[0];
  return {
    usage: hydrate(
      usage as UsageEntityState,
      usageComposantTemplates,
      usageCaracteristiqueTemplates,
      composantTemplates,
      caracteristiqueTemplates
    )
  };
}

function hydrate(
  usage: UsageEntityState,
  usageComposantTemplateEntities?: Dictionary<UsageComposantTemplateEntityState>,
  usageCaracteristiqueTemplateEntities?: Dictionary<UsageCaracteristiqueTemplateEntityState>,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
): Usage | null {
  if (!usage) {
    return null;
  }

  const usageHydrated: UsageEntityState = { ...usage };

  if (usageComposantTemplateEntities) {
    usageHydrated.usageComposantTemplates = ((usageHydrated.usageComposantTemplates as number[]) || []).map(
      id => usageComposantTemplateEntities[id]
    ) as UsageComposantTemplate[];
  } else {
    delete usageHydrated.usageComposantTemplates;
  }

  if (usageCaracteristiqueTemplateEntities) {
    usageHydrated.usageCaracteristiqueTemplates = ((usageHydrated.usageCaracteristiqueTemplates as number[]) || []).map(
      id => usageCaracteristiqueTemplateEntities[id]
    ) as UsageCaracteristiqueTemplate[];
  } else {
    delete usageHydrated.usageCaracteristiqueTemplates;
  }

  if (composantTemplateEntities) {
    usageHydrated.composantTemplates = ((usageHydrated.composantTemplates as number[]) || []).map(
      id => composantTemplateEntities[id]
    ) as ComposantTemplate[];
  } else {
    delete usageHydrated.composantTemplates;
  }

  if (caracteristiqueTemplateEntities) {
    usageHydrated.caracteristiqueTemplates = ((usageHydrated.caracteristiqueTemplates as number[]) || []).map(
      id => caracteristiqueTemplateEntities[id]
    ) as CaracteristiqueTemplate[];
  } else {
    delete usageHydrated.caracteristiqueTemplates;
  }

  return usageHydrated as Usage;
}
