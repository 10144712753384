import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamilleState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteComposantFamilleEntityState } from '@get/api-interfaces';

export const societeComposantFamilleReducersGeneratedFunctions: ReducerTypes<SocieteComposantFamilleState.IState, readonly ActionCreator[]>[] = [
  on(SocieteComposantFamilleGeneratedActions.getManySocieteComposantFamilles, (state: SocieteComposantFamilleState.IState) => setLoadingsState(state, true)),
  on(SocieteComposantFamilleGeneratedActions.upsertOneSocieteComposantFamille, (state: SocieteComposantFamilleState.IState) => setLoadingsState(state, true)),

  on(SocieteComposantFamilleGeneratedActions.upsertManySocieteComposantFamillesSuccess, (state: SocieteComposantFamilleState.IState, { societeComposantFamilles }) =>
    SocieteComposantFamilleState.adapter.upsertMany(societeComposantFamilles, setLoadingsState(state, false))
  ),
  on(SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamille, (state: SocieteComposantFamilleState.IState) => setLoadingsState(state, true)),
  on(SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamilleSuccess, (state: SocieteComposantFamilleState.IState, { idSocieteComposantFamille }) =>
    SocieteComposantFamilleState.adapter.removeOne(idSocieteComposantFamille, setLoadingsState(state, false))
  ),

  on(SocieteComposantFamilleGeneratedActions.clearSocieteComposantFamilles, () => SocieteComposantFamilleState.initialState),

  on(
    SocieteComposantFamilleGeneratedActions.addManySocieteComposantSuccess,
    (state: SocieteComposantFamilleState.IState, { idSocieteComposantFamille, idSocieteComposants }) => {
      if (!state.entities[idSocieteComposantFamille]) {
        return state;
      }
      const societeComposants = (state.entities[idSocieteComposantFamille]?.societeComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantFamille]: {
            ...state.entities[idSocieteComposantFamille],
            societeComposants: societeComposants.concat(
              idSocieteComposants.filter(id => societeComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteComposantFamilleGeneratedActions.deleteManySocieteComposantSuccess,
    (state: SocieteComposantFamilleState.IState, { idSocieteComposants, idSocieteComposantFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantFamilles.reduce((entities, idSocieteComposantFamille) => {
            if (!state.entities[idSocieteComposantFamille]?.societeComposants) {
              return entities;
            }
            entities[idSocieteComposantFamille] = {
              ...state.entities[idSocieteComposantFamille],
              societeComposants: (state.entities[idSocieteComposantFamille]?.societeComposants as number[])?.filter(
                (idSocieteComposant: number) => !idSocieteComposants.some((id: number) => id === idSocieteComposant)
              )
            } as SocieteComposantFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantFamilleEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantFamilleGeneratedActions.addSocieteSuccess,
    (state: SocieteComposantFamilleState.IState, { idSocieteComposantFamille, idSociete }) => {
      if (!state.entities[idSocieteComposantFamille]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantFamille]: {
            ...state.entities[idSocieteComposantFamille],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteComposantFamilleGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteComposantFamilleState.IState, { idSocietes, idSocieteComposantFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantFamilles.reduce((entities, idSocieteComposantFamille) => {
            if (!state.entities[idSocieteComposantFamille]?.societe) {
              return entities;
            }
            entities[idSocieteComposantFamille] = {
              ...state.entities[idSocieteComposantFamille],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteComposantFamille]?.societe
              )
                ? undefined
                : state.entities[idSocieteComposantFamille]?.societe
            } as SocieteComposantFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantFamilleEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantFamilleGeneratedActions.addFichierSuccess,
    (state: SocieteComposantFamilleState.IState, { idSocieteComposantFamille, idFichier }) => {
      if (!state.entities[idSocieteComposantFamille]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantFamille]: {
            ...state.entities[idSocieteComposantFamille],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    SocieteComposantFamilleGeneratedActions.deleteManyFichierSuccess,
    (state: SocieteComposantFamilleState.IState, { idFichiers, idSocieteComposantFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantFamilles.reduce((entities, idSocieteComposantFamille) => {
            if (!state.entities[idSocieteComposantFamille]?.fichier) {
              return entities;
            }
            entities[idSocieteComposantFamille] = {
              ...state.entities[idSocieteComposantFamille],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idSocieteComposantFamille]?.fichier
              )
                ? undefined
                : state.entities[idSocieteComposantFamille]?.fichier
            } as SocieteComposantFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantFamilleEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteComposantFamilleState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteComposantFamilleState.IState {
  return { ...state, isLoaded, isLoading };
}
