import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UserPatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const userPatrimoineRelations: string[] = ['users','patrimoines',];

export const { selectEntities, selectAll } = UserPatrimoineState.adapter.getSelectors();

export const selectUserPatrimoineState = createFeatureSelector<UserPatrimoineState.IState>(UserPatrimoineState.userPatrimoineFeatureKey);

export const selectIsLoadedUserPatrimoine = createSelector(
  selectUserPatrimoineState,
  (state: UserPatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingUserPatrimoine = createSelector(
  selectUserPatrimoineState,
  (state: UserPatrimoineState.IState) => state.isLoading
);

export const selectIsReadyUserPatrimoine = createSelector(
  selectUserPatrimoineState,
  (state: UserPatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserPatrimoine = createSelector(
  selectUserPatrimoineState,
  (state: UserPatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserPatrimoinesEntities = createSelector(selectUserPatrimoineState, selectEntities);

export const selectUserPatrimoinesArray = createSelector(selectUserPatrimoineState, selectAll);

const userPatrimoinesInObject = (userPatrimoines: Dictionary<UserPatrimoineEntityState>) => ({ userPatrimoines })

const selectUserPatrimoinesEntitiesDictionary = createSelector(selectUserPatrimoinesEntities, userPatrimoinesInObject);

const selectAllUserPatrimoinesObject = createSelector(selectUserPatrimoinesEntities, userPatrimoines => {
  return hydrateAll({ userPatrimoines });
});

const selectOneUserPatrimoineDictionary = (idUserPatrimoine : number) =>
  createSelector(selectUserPatrimoinesEntities, userPatrimoines => ({
    userPatrimoines: { [idUserPatrimoine]: userPatrimoines[idUserPatrimoine] }
  }));

const selectOneUserPatrimoineDictionaryWithoutChild = (idUserPatrimoine : number) =>
  createSelector(selectUserPatrimoinesEntities, userPatrimoines => ({
    userPatrimoine: userPatrimoines[idUserPatrimoine]
  }));

const selectAllUserPatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllUserPatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserPatrimoine>(
      schema,
      selectAllUserPatrimoinesSelectors,
      selectUserPatrimoinesEntitiesDictionary,
      getRelationSelectors,
      userPatrimoineRelations,
      hydrateAll,
      'userPatrimoine'
    );
  } else {
    return selectAllUserPatrimoinesObject;
  }
}

export function selectAllUserPatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey = 'userPatrimoines'
): Selector {
  return createSelector(selectAllUserPatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserPatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userPatrimoines.length; i++) {
      res[customKey][result.userPatrimoines[i].idUserPatrimoine] = result.userPatrimoines[i];
    }
    return res;
  });
}

export function selectOneUserPatrimoine(
  schema: SelectSchema = {},
  idUserPatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUserPatrimoineDictionary(idUserPatrimoine)];
  selectors.push(...getRelationSelectors(schema, userPatrimoineRelations, 'userPatrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserPatrimoineDictionaryWithoutChild(idUserPatrimoine);
  }
}

interface hydrateArgs {
  userPatrimoines: Dictionary<UserPatrimoineEntityState>;
  users?: Dictionary<UserEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userPatrimoines: (UserPatrimoine | null)[] } {
  const {
    userPatrimoines,
    users,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userPatrimoines: Object.keys(userPatrimoines).map(idUserPatrimoine =>
      hydrate(
        userPatrimoines[idUserPatrimoine] as UserPatrimoineEntityState,
        users,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userPatrimoine: UserPatrimoineEntityState | null } {
  const {
    userPatrimoines,
    users,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userPatrimoine = Object.values(userPatrimoines)[0];
  return {
    userPatrimoine: hydrate(
      userPatrimoine as UserPatrimoineEntityState,
      users,
      patrimoines
    )
  };
}

function hydrate(
  userPatrimoine: UserPatrimoineEntityState,
  userEntities?: Dictionary<UserEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): UserPatrimoine | null {
  if (!userPatrimoine) {
    return null;
  }

  const userPatrimoineHydrated: UserPatrimoineEntityState = { ...userPatrimoine };
  if (userEntities) {
    userPatrimoineHydrated.user = userEntities[userPatrimoine.user as number] as User;
  } else {
    delete userPatrimoineHydrated.user;
  }
  if (patrimoineEntities) {
    userPatrimoineHydrated.patrimoine = patrimoineEntities[userPatrimoine.patrimoine as number] as Patrimoine;
  } else {
    delete userPatrimoineHydrated.patrimoine;
  }

  return userPatrimoineHydrated as UserPatrimoine;
}
