import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { SocieteApiService } from '@get/store/api-services';
import { SocieteGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSociete } from '@get/store/configs/normalization';
import { SocieteRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamille } from '@get/api-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamille } from '@get/api-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { Campagne } from '@get/api-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoire } from '@get/api-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfil } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupe } from '@get/api-interfaces';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposant } from '@get/api-interfaces';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantGroupe } from '@get/api-interfaces';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupe } from '@get/api-interfaces';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { ProgBudget } from '@get/api-interfaces';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { ProgUnite } from '@get/api-interfaces';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { ProgInterventionFamille } from '@get/api-interfaces';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { ProgScenario } from '@get/api-interfaces';
import { ProgEtatTechniqueGeneratedActions } from '@get/store/actions';
import { ProgEtatTechnique } from '@get/api-interfaces';

export function getDefaultAddSocieteActions(societe: SocieteEntityState, ids?: SocieteRelationsIds): Action[] {
  const actions: Action[] = [SocieteGeneratedActions.normalizeManySocietesAfterUpsert({ societes: [societe] })];

  if (ids?.organisation) {
    actions.push(
      OrganisationGeneratedActions.addManySocieteSuccess({
        idOrganisation: ids.organisation,
        idSocietes: [societe.idSociete]
      })
    );
    actions.push(
      SocieteGeneratedActions.addOrganisationSuccess({
        idSociete: societe.idSociete,
        idOrganisation: ids.organisation
      })
    );
  }

  if (ids?.societeComposants) {
    if (!Array.isArray(ids.societeComposants)) {
      actions.push(
        SocieteComposantGeneratedActions.upsertOneSocieteComposant({
          societeComposant: {
            idSociete: societe.idSociete,
            idSocieteComposant: ids.societeComposants as number
          } as SocieteComposant
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteComposantSuccess({
          idSociete: societe.idSociete,
          idSocieteComposants: [ids.societeComposants as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantGeneratedActions.upsertManySocieteComposants({
          societeComposants: (ids.societeComposants as number[]).map(
            (idSocieteComposant: number) => ({
              idSociete: societe.idSociete,
              idSocieteComposant
            })
          ) as SocieteComposant[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteComposantSuccess({
          idSociete: societe.idSociete,
          idSocieteComposants: ids.societeComposants as number[]
        })
      );
    }
  }

  if (ids?.societePatrimoineHierarchies) {
    if (!Array.isArray(ids.societePatrimoineHierarchies)) {
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.upsertOneSocietePatrimoineHierarchie({
          societePatrimoineHierarchie: {
            idSociete: societe.idSociete,
            idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchies as number
          } as SocietePatrimoineHierarchie
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
          idSociete: societe.idSociete,
          idSocietePatrimoineHierarchies: [ids.societePatrimoineHierarchies as number]
        })
      );
    } else {
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.upsertManySocietePatrimoineHierarchies({
          societePatrimoineHierarchies: (ids.societePatrimoineHierarchies as number[]).map(
            (idSocietePatrimoineHierarchie: number) => ({
              idSociete: societe.idSociete,
              idSocietePatrimoineHierarchie
            })
          ) as SocietePatrimoineHierarchie[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
          idSociete: societe.idSociete,
          idSocietePatrimoineHierarchies: ids.societePatrimoineHierarchies as number[]
        })
      );
    }
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idSociete: societe.idSociete,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSociete: societe.idSociete,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idSociete: societe.idSociete,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSociete: societe.idSociete,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.societeComposantFamilles) {
    if (!Array.isArray(ids.societeComposantFamilles)) {
      actions.push(
        SocieteComposantFamilleGeneratedActions.upsertOneSocieteComposantFamille({
          societeComposantFamille: {
            idSociete: societe.idSociete,
            idSocieteComposantFamille: ids.societeComposantFamilles as number
          } as SocieteComposantFamille
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteComposantFamilleSuccess({
          idSociete: societe.idSociete,
          idSocieteComposantFamilles: [ids.societeComposantFamilles as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantFamilleGeneratedActions.upsertManySocieteComposantFamilles({
          societeComposantFamilles: (ids.societeComposantFamilles as number[]).map(
            (idSocieteComposantFamille: number) => ({
              idSociete: societe.idSociete,
              idSocieteComposantFamille
            })
          ) as SocieteComposantFamille[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteComposantFamilleSuccess({
          idSociete: societe.idSociete,
          idSocieteComposantFamilles: ids.societeComposantFamilles as number[]
        })
      );
    }
  }

  if (ids?.societeEspaceFamilles) {
    if (!Array.isArray(ids.societeEspaceFamilles)) {
      actions.push(
        SocieteEspaceFamilleGeneratedActions.upsertOneSocieteEspaceFamille({
          societeEspaceFamille: {
            idSociete: societe.idSociete,
            idSocieteEspaceFamille: ids.societeEspaceFamilles as number
          } as SocieteEspaceFamille
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteEspaceFamilleSuccess({
          idSociete: societe.idSociete,
          idSocieteEspaceFamilles: [ids.societeEspaceFamilles as number]
        })
      );
    } else {
      actions.push(
        SocieteEspaceFamilleGeneratedActions.upsertManySocieteEspaceFamilles({
          societeEspaceFamilles: (ids.societeEspaceFamilles as number[]).map(
            (idSocieteEspaceFamille: number) => ({
              idSociete: societe.idSociete,
              idSocieteEspaceFamille
            })
          ) as SocieteEspaceFamille[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteEspaceFamilleSuccess({
          idSociete: societe.idSociete,
          idSocieteEspaceFamilles: ids.societeEspaceFamilles as number[]
        })
      );
    }
  }

  if (ids?.campagnes) {
    if (!Array.isArray(ids.campagnes)) {
      actions.push(
        CampagneGeneratedActions.upsertOneCampagne({
          campagne: {
            idSociete: societe.idSociete,
            idCampagne: ids.campagnes as number
          } as Campagne
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyCampagneSuccess({
          idSociete: societe.idSociete,
          idCampagnes: [ids.campagnes as number]
        })
      );
    } else {
      actions.push(
        CampagneGeneratedActions.upsertManyCampagnes({
          campagnes: (ids.campagnes as number[]).map(
            (idCampagne: number) => ({
              idSociete: societe.idSociete,
              idCampagne
            })
          ) as Campagne[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyCampagneSuccess({
          idSociete: societe.idSociete,
          idCampagnes: ids.campagnes as number[]
        })
      );
    }
  }

  if (ids?.societeTerritoires) {
    if (!Array.isArray(ids.societeTerritoires)) {
      actions.push(
        SocieteTerritoireGeneratedActions.upsertOneSocieteTerritoire({
          societeTerritoire: {
            idSociete: societe.idSociete,
            idSocieteTerritoire: ids.societeTerritoires as number
          } as SocieteTerritoire
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteTerritoireSuccess({
          idSociete: societe.idSociete,
          idSocieteTerritoires: [ids.societeTerritoires as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoireGeneratedActions.upsertManySocieteTerritoires({
          societeTerritoires: (ids.societeTerritoires as number[]).map(
            (idSocieteTerritoire: number) => ({
              idSociete: societe.idSociete,
              idSocieteTerritoire
            })
          ) as SocieteTerritoire[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteTerritoireSuccess({
          idSociete: societe.idSociete,
          idSocieteTerritoires: ids.societeTerritoires as number[]
        })
      );
    }
  }

  if (ids?.societeProfils) {
    if (!Array.isArray(ids.societeProfils)) {
      actions.push(
        SocieteProfilGeneratedActions.upsertOneSocieteProfil({
          societeProfil: {
            idSociete: societe.idSociete,
            idSocieteProfil: ids.societeProfils as number
          } as SocieteProfil
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteProfilSuccess({
          idSociete: societe.idSociete,
          idSocieteProfils: [ids.societeProfils as number]
        })
      );
    } else {
      actions.push(
        SocieteProfilGeneratedActions.upsertManySocieteProfils({
          societeProfils: (ids.societeProfils as number[]).map(
            (idSocieteProfil: number) => ({
              idSociete: societe.idSociete,
              idSocieteProfil
            })
          ) as SocieteProfil[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteProfilSuccess({
          idSociete: societe.idSociete,
          idSocieteProfils: ids.societeProfils as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idSociete: societe.idSociete,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyPatrimoineSuccess({
          idSociete: societe.idSociete,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idSociete: societe.idSociete,
              idPatrimoine
            })
          ) as Patrimoine[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyPatrimoineSuccess({
          idSociete: societe.idSociete,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.societeConfigAnalyseSyntheses) {
    if (!Array.isArray(ids.societeConfigAnalyseSyntheses)) {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese({
          societeConfigAnalyseSynthese: {
            idSociete: societe.idSociete,
            idSocieteConfigAnalyseSynthese: ids.societeConfigAnalyseSyntheses as number
          } as SocieteConfigAnalyseSynthese
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idSociete: societe.idSociete,
          idSocieteConfigAnalyseSyntheses: [ids.societeConfigAnalyseSyntheses as number]
        })
      );
    } else {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSyntheses({
          societeConfigAnalyseSyntheses: (ids.societeConfigAnalyseSyntheses as number[]).map(
            (idSocieteConfigAnalyseSynthese: number) => ({
              idSociete: societe.idSociete,
              idSocieteConfigAnalyseSynthese
            })
          ) as SocieteConfigAnalyseSynthese[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idSociete: societe.idSociete,
          idSocieteConfigAnalyseSyntheses: ids.societeConfigAnalyseSyntheses as number[]
        })
      );
    }
  }

  if (ids?.composantGroupes) {
    if (!Array.isArray(ids.composantGroupes)) {
      actions.push(
        ComposantGroupeGeneratedActions.upsertOneComposantGroupe({
          composantGroupe: {
            idSociete: societe.idSociete,
            idComposantGroupe: ids.composantGroupes as number
          } as ComposantGroupe
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyComposantGroupeSuccess({
          idSociete: societe.idSociete,
          idComposantGroupes: [ids.composantGroupes as number]
        })
      );
    } else {
      actions.push(
        ComposantGroupeGeneratedActions.upsertManyComposantGroupes({
          composantGroupes: (ids.composantGroupes as number[]).map(
            (idComposantGroupe: number) => ({
              idSociete: societe.idSociete,
              idComposantGroupe
            })
          ) as ComposantGroupe[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyComposantGroupeSuccess({
          idSociete: societe.idSociete,
          idComposantGroupes: ids.composantGroupes as number[]
        })
      );
    }
  }

  if (ids?.progComposants) {
    if (!Array.isArray(ids.progComposants)) {
      actions.push(
        ProgComposantGeneratedActions.upsertOneProgComposant({
          progComposant: {
            idSociete: societe.idSociete,
            idProgComposant: ids.progComposants as number
          } as ProgComposant
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantSuccess({
          idSociete: societe.idSociete,
          idProgComposants: [ids.progComposants as number]
        })
      );
    } else {
      actions.push(
        ProgComposantGeneratedActions.upsertManyProgComposants({
          progComposants: (ids.progComposants as number[]).map(
            (idProgComposant: number) => ({
              idSociete: societe.idSociete,
              idProgComposant
            })
          ) as ProgComposant[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantSuccess({
          idSociete: societe.idSociete,
          idProgComposants: ids.progComposants as number[]
        })
      );
    }
  }

  if (ids?.progComposantGroupes) {
    if (!Array.isArray(ids.progComposantGroupes)) {
      actions.push(
        ProgComposantGroupeGeneratedActions.upsertOneProgComposantGroupe({
          progComposantGroupe: {
            idSociete: societe.idSociete,
            idProgComposantGroupe: ids.progComposantGroupes as number
          } as ProgComposantGroupe
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantGroupeSuccess({
          idSociete: societe.idSociete,
          idProgComposantGroupes: [ids.progComposantGroupes as number]
        })
      );
    } else {
      actions.push(
        ProgComposantGroupeGeneratedActions.upsertManyProgComposantGroupes({
          progComposantGroupes: (ids.progComposantGroupes as number[]).map(
            (idProgComposantGroupe: number) => ({
              idSociete: societe.idSociete,
              idProgComposantGroupe
            })
          ) as ProgComposantGroupe[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantGroupeSuccess({
          idSociete: societe.idSociete,
          idProgComposantGroupes: ids.progComposantGroupes as number[]
        })
      );
    }
  }

  if (ids?.progComposantSousGroupes) {
    if (!Array.isArray(ids.progComposantSousGroupes)) {
      actions.push(
        ProgComposantSousGroupeGeneratedActions.upsertOneProgComposantSousGroupe({
          progComposantSousGroupe: {
            idSociete: societe.idSociete,
            idProgComposantSousGroupe: ids.progComposantSousGroupes as number
          } as ProgComposantSousGroupe
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantSousGroupeSuccess({
          idSociete: societe.idSociete,
          idProgComposantSousGroupes: [ids.progComposantSousGroupes as number]
        })
      );
    } else {
      actions.push(
        ProgComposantSousGroupeGeneratedActions.upsertManyProgComposantSousGroupes({
          progComposantSousGroupes: (ids.progComposantSousGroupes as number[]).map(
            (idProgComposantSousGroupe: number) => ({
              idSociete: societe.idSociete,
              idProgComposantSousGroupe
            })
          ) as ProgComposantSousGroupe[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgComposantSousGroupeSuccess({
          idSociete: societe.idSociete,
          idProgComposantSousGroupes: ids.progComposantSousGroupes as number[]
        })
      );
    }
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idSociete: societe.idSociete,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgBiblioInterventionSuccess({
          idSociete: societe.idSociete,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idSociete: societe.idSociete,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgBiblioInterventionSuccess({
          idSociete: societe.idSociete,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  if (ids?.progBudgets) {
    if (!Array.isArray(ids.progBudgets)) {
      actions.push(
        ProgBudgetGeneratedActions.upsertOneProgBudget({
          progBudget: {
            idSociete: societe.idSociete,
            idProgBudget: ids.progBudgets as number
          } as ProgBudget
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgBudgetSuccess({
          idSociete: societe.idSociete,
          idProgBudgets: [ids.progBudgets as number]
        })
      );
    } else {
      actions.push(
        ProgBudgetGeneratedActions.upsertManyProgBudgets({
          progBudgets: (ids.progBudgets as number[]).map(
            (idProgBudget: number) => ({
              idSociete: societe.idSociete,
              idProgBudget
            })
          ) as ProgBudget[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgBudgetSuccess({
          idSociete: societe.idSociete,
          idProgBudgets: ids.progBudgets as number[]
        })
      );
    }
  }

  if (ids?.progUnites) {
    if (!Array.isArray(ids.progUnites)) {
      actions.push(
        ProgUniteGeneratedActions.upsertOneProgUnite({
          progUnite: {
            idSociete: societe.idSociete,
            idProgUnite: ids.progUnites as number
          } as ProgUnite
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgUniteSuccess({
          idSociete: societe.idSociete,
          idProgUnites: [ids.progUnites as number]
        })
      );
    } else {
      actions.push(
        ProgUniteGeneratedActions.upsertManyProgUnites({
          progUnites: (ids.progUnites as number[]).map(
            (idProgUnite: number) => ({
              idSociete: societe.idSociete,
              idProgUnite
            })
          ) as ProgUnite[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgUniteSuccess({
          idSociete: societe.idSociete,
          idProgUnites: ids.progUnites as number[]
        })
      );
    }
  }

  if (ids?.progInterventionFamilles) {
    if (!Array.isArray(ids.progInterventionFamilles)) {
      actions.push(
        ProgInterventionFamilleGeneratedActions.upsertOneProgInterventionFamille({
          progInterventionFamille: {
            idSociete: societe.idSociete,
            idProgInterventionFamille: ids.progInterventionFamilles as number
          } as ProgInterventionFamille
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgInterventionFamilleSuccess({
          idSociete: societe.idSociete,
          idProgInterventionFamilles: [ids.progInterventionFamilles as number]
        })
      );
    } else {
      actions.push(
        ProgInterventionFamilleGeneratedActions.upsertManyProgInterventionFamilles({
          progInterventionFamilles: (ids.progInterventionFamilles as number[]).map(
            (idProgInterventionFamille: number) => ({
              idSociete: societe.idSociete,
              idProgInterventionFamille
            })
          ) as ProgInterventionFamille[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgInterventionFamilleSuccess({
          idSociete: societe.idSociete,
          idProgInterventionFamilles: ids.progInterventionFamilles as number[]
        })
      );
    }
  }

  if (ids?.progScenarios) {
    if (!Array.isArray(ids.progScenarios)) {
      actions.push(
        ProgScenarioGeneratedActions.upsertOneProgScenario({
          progScenario: {
            idSociete: societe.idSociete,
            idProgScenario: ids.progScenarios as number
          } as ProgScenario
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgScenarioSuccess({
          idSociete: societe.idSociete,
          idProgScenarios: [ids.progScenarios as number]
        })
      );
    } else {
      actions.push(
        ProgScenarioGeneratedActions.upsertManyProgScenarios({
          progScenarios: (ids.progScenarios as number[]).map(
            (idProgScenario: number) => ({
              idSociete: societe.idSociete,
              idProgScenario
            })
          ) as ProgScenario[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgScenarioSuccess({
          idSociete: societe.idSociete,
          idProgScenarios: ids.progScenarios as number[]
        })
      );
    }
  }

  if (ids?.progEtatTechniques) {
    if (!Array.isArray(ids.progEtatTechniques)) {
      actions.push(
        ProgEtatTechniqueGeneratedActions.upsertOneProgEtatTechnique({
          progEtatTechnique: {
            idSociete: societe.idSociete,
            idProgEtatTechnique: ids.progEtatTechniques as number
          } as ProgEtatTechnique
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgEtatTechniqueSuccess({
          idSociete: societe.idSociete,
          idProgEtatTechniques: [ids.progEtatTechniques as number]
        })
      );
    } else {
      actions.push(
        ProgEtatTechniqueGeneratedActions.upsertManyProgEtatTechniques({
          progEtatTechniques: (ids.progEtatTechniques as number[]).map(
            (idProgEtatTechnique: number) => ({
              idSociete: societe.idSociete,
              idProgEtatTechnique
            })
          ) as ProgEtatTechnique[]
        })
      );
      actions.push(
        SocieteGeneratedActions.addManyProgEtatTechniqueSuccess({
          idSociete: societe.idSociete,
          idProgEtatTechniques: ids.progEtatTechniques as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteActions(societe: SocieteEntityState): Action[] {
  const actions: Action[] = [SocieteGeneratedActions.deleteOneSocieteSuccess({ idSociete: societe.idSociete })];

  if (societe.organisation) {
    actions.push(
      OrganisationGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idOrganisations: [societe.organisation as number]
      })
    );
  }

  if (societe.societeComposants) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteComposants: societe.societeComposants as number[]
      })
    );
  }

  if (societe.societePatrimoineHierarchies) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocietePatrimoineHierarchies: societe.societePatrimoineHierarchies as number[]
      })
    );
  }

  if (societe.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteCaracteristiques: societe.societeCaracteristiques as number[]
      })
    );
  }

  if (societe.societeComposantFamilles) {
    actions.push(
      SocieteComposantFamilleGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteComposantFamilles: societe.societeComposantFamilles as number[]
      })
    );
  }

  if (societe.societeEspaceFamilles) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteEspaceFamilles: societe.societeEspaceFamilles as number[]
      })
    );
  }

  if (societe.campagnes) {
    actions.push(
      CampagneGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idCampagnes: societe.campagnes as number[]
      })
    );
  }

  if (societe.societeTerritoires) {
    actions.push(
      SocieteTerritoireGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteTerritoires: societe.societeTerritoires as number[]
      })
    );
  }

  if (societe.societeProfils) {
    actions.push(
      SocieteProfilGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteProfils: societe.societeProfils as number[]
      })
    );
  }

  if (societe.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idPatrimoines: societe.patrimoines as number[]
      })
    );
  }

  if (societe.societeConfigAnalyseSyntheses) {
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idSocieteConfigAnalyseSyntheses: societe.societeConfigAnalyseSyntheses as number[]
      })
    );
  }

  if (societe.composantGroupes) {
    actions.push(
      ComposantGroupeGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idComposantGroupes: societe.composantGroupes as number[]
      })
    );
  }

  if (societe.progComposants) {
    actions.push(
      ProgComposantGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgComposants: societe.progComposants as number[]
      })
    );
  }

  if (societe.progComposantGroupes) {
    actions.push(
      ProgComposantGroupeGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgComposantGroupes: societe.progComposantGroupes as number[]
      })
    );
  }

  if (societe.progComposantSousGroupes) {
    actions.push(
      ProgComposantSousGroupeGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgComposantSousGroupes: societe.progComposantSousGroupes as number[]
      })
    );
  }

  if (societe.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgBiblioInterventions: societe.progBiblioInterventions as number[]
      })
    );
  }

  if (societe.progBudgets) {
    actions.push(
      ProgBudgetGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgBudgets: societe.progBudgets as number[]
      })
    );
  }

  if (societe.progUnites) {
    actions.push(
      ProgUniteGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgUnites: societe.progUnites as number[]
      })
    );
  }

  if (societe.progInterventionFamilles) {
    actions.push(
      ProgInterventionFamilleGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgInterventionFamilles: societe.progInterventionFamilles as number[]
      })
    );
  }

  if (societe.progScenarios) {
    actions.push(
      ProgScenarioGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgScenarios: societe.progScenarios as number[]
      })
    );
  }

  if (societe.progEtatTechniques) {
    actions.push(
      ProgEtatTechniqueGeneratedActions.deleteManySocieteSuccess({
        idSocietes: [societe.idSociete],
        idProgEtatTechniques: societe.progEtatTechniques as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteEffects {
  constructor(
    protected actions$: Actions,
    protected societeApiService: SocieteApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocietes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteGeneratedActions.getManySocietes),
      switchMap(({ params }) =>
        this.societeApiService.getSocietes(params).pipe(
          map((societes: Societe[]) => {
            return SocieteGeneratedActions.normalizeManySocietesAfterUpsert({ societes });
          }),
          catchError(error => of(SocieteGeneratedActions.societesFailure({ error })))
        )
      )
    );
  });

  upsertOneSociete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteGeneratedActions.upsertOneSociete),
      concatMap(
        ({
          societe,
          ids
        }: {
          societe: Partial<Societe>;
          ids?: SocieteRelationsIds;
        }) => {
          if (societe.idSociete) {
            return this.societeApiService.updateSociete(societe).pipe(
              map((societeReturned: Societe) => {
                return SocieteGeneratedActions.normalizeManySocietesAfterUpsert({ societes: [societeReturned] });
              }),
              catchError(error => of(SocieteGeneratedActions.societesFailure({ error })))
            );
          } else {
            return this.societeApiService.addSociete(societe).pipe(
              mergeMap((societeReturned: Societe) => getDefaultAddSocieteActions(societeReturned, ids)),
              catchError(error => of(SocieteGeneratedActions.societesFailure({ error })))
            );
          }
        }
      )
    );
  });

  normalizeManySocietesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteGeneratedActions.normalizeManySocietesAfterUpsert),
      concatMap(({ societes }) => {
        const actions: Action[] = getActionsToNormalizeSociete(societes, StoreActionType.upsert);
        return [getMultiAction(actions, '[Societe] Normalization After Upsert Success')];
      })
    );
  });
}
