import { createEntityAdapter } from '@ngrx/entity';
import { ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgInterventionPatrimoineEntityState>;

export const adapter = createEntityAdapter<ProgInterventionPatrimoineEntityState>({
  selectId: o => o.idProgInterventionPatrimoine
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progInterventionPatrimoineFeatureKey = 'progInterventionPatrimoine';
