import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';

export const societeConfigAnalyseSyntheseSocieteCaracteristiqueReducersGeneratedFunctions: ReducerTypes<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, readonly ActionCreator[]>[] = [
  on(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertOneSocieteConfigAnalyseSyntheseSocieteCaracteristique, (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),

  on(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesSuccess, (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { societeConfigAnalyseSyntheseSocieteCaracteristiques }) =>
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.adapter.upsertMany(societeConfigAnalyseSyntheseSocieteCaracteristiques, setLoadingsState(state, false))
  ),
  on(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess, (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheseSocieteCaracteristique }) =>
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.adapter.removeOne(idSocieteConfigAnalyseSyntheseSocieteCaracteristique, setLoadingsState(state, false))
  ),

  on(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.clearSocieteConfigAnalyseSyntheseSocieteCaracteristiques, () => SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.initialState),

  on(
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.addSocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheseSocieteCaracteristique, idSocieteConfigAnalyseSynthese }) => {
      if (!state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSyntheseSocieteCaracteristique]: {
            ...state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
            societeConfigAnalyseSynthese: idSocieteConfigAnalyseSynthese
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheses, idSocieteConfigAnalyseSyntheseSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.reduce((entities, idSocieteConfigAnalyseSyntheseSocieteCaracteristique) => {
            if (!state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeConfigAnalyseSynthese) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique] = {
              ...state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
              societeConfigAnalyseSynthese: idSocieteConfigAnalyseSyntheses.some(
                (idSocieteConfigAnalyseSynthese: number) => idSocieteConfigAnalyseSynthese === state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeConfigAnalyseSynthese
              )
                ? undefined
                : state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeConfigAnalyseSynthese
            } as SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.addSocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheseSocieteCaracteristique, idSocieteCaracteristique }) => {
      if (!state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSyntheseSocieteCaracteristique]: {
            ...state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
            societeCaracteristique: idSocieteCaracteristique
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState, { idSocieteCaracteristiques, idSocieteConfigAnalyseSyntheseSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.reduce((entities, idSocieteConfigAnalyseSyntheseSocieteCaracteristique) => {
            if (!state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeCaracteristique) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique] = {
              ...state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
              societeCaracteristique: idSocieteCaracteristiques.some(
                (idSocieteCaracteristique: number) => idSocieteCaracteristique === state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeCaracteristique
              )
                ? undefined
                : state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]?.societeCaracteristique
            } as SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState {
  return { ...state, isLoaded, isLoading };
}
