import { isDateAfterOrEqual, isDateBeforeOrEqual } from '@utils';
import dayjs from 'dayjs';

const today = new Date();
const halloweenStart = new Date(today.getFullYear(), 9, 28);
const halloweenEnd = new Date(today.getFullYear(), 9, 31);
const christmasStart = new Date(today.getFullYear(), 11, 1);
const christmasEnd = new Date(today.getFullYear(), 11, 26);

export function getLogoPath(): string {
  const currentDate = dayjs();
  if (isDateBeforeOrEqual(christmasStart, currentDate) && isDateAfterOrEqual(christmasEnd, currentDate)) {
    return '/assets/logo-noel.svg';
  }
  if (isDateBeforeOrEqual(halloweenStart, currentDate) && isDateAfterOrEqual(halloweenEnd, currentDate)) {
    return '/assets/logo-halloween.svg';
  }
  return '/assets/last-logo.svg';
}
