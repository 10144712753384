import {
  AssertEmptyComposantAttenduAllPatrimoieInterface,
  AssertEmptyComposantAttenduIdsPatrimoineInterface,
  AssertEmptyComposantAttenduInterface,
  Composant,
  Valeur
} from '@get/api-interfaces';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ComposantActions = createActionGroup({
  source: 'Composant',
  events: {
    'Duplicate One Composant': props<{
      idComposant: number;
      ids?: ComposantRelationsIds;
      params?: { idCampagne?: number };
    }>(),
    'Get Many Composants Per Type': props<{ params: any }>(),
    'Move One Composant': props<{ composant: Partial<Composant>; oldIdEspace: number }>(),
    'Upsert One Composant Modify Espace': props<{
      composant: Partial<Composant>;
      oldIdEspace: number;
    }>(),
    'Upsert One Valeur And Return Composant': props<{
      valeur: Partial<Valeur>;
      params?: { idCampagne?: number };
    }>(),
    'Update One Composant And Valeurs': props<{
      composant: Partial<Composant>;
    }>(),
    'Assert Empty Composant Attendu ': props<{ params: AssertEmptyComposantAttenduInterface }>(),
    'Assert Empty Composant Attendu All Patrimoines': props<{
      params: AssertEmptyComposantAttenduAllPatrimoieInterface;
    }>(),
    'Assert Empty Tree ': props<{ params: AssertEmptyComposantAttenduInterface }>(),
    'Assert Empty Tree Ids Patrimoine': props<{ params: AssertEmptyComposantAttenduIdsPatrimoineInterface }>(),
    'Create Many Composants': props<{ composants: Partial<Composant>[] }>(),
    'Delete One Composant With Composant Attendu': props<{ idComposant: number }>(),
    'Delete Composants By Societe': props<{ idSociete: number }>(),
    'Delete Composants By Societe Success': emptyProps(),
    'Delete Composants By Societe Composant': props<{ idSocieteComposant: number }>(),
    'Delete Composants By Societe Composant Success': emptyProps(),
    'Delete One Valeur Fichier': props<{ idValeurFichier: number }>(),
    'Delete One Valeur Fichier Success': props<{ idValeurFichier: number }>()
  }
});
