import { Injectable } from '@angular/core';import { Store } from '@ngrx/store';
import { AppState } from '@get/store/configs/reducers';
import { GeneratedBoardStateService } from './board-state-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class BoardStateService extends GeneratedBoardStateService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}

