import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CampagnePatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const campagnePatrimoineRelations: string[] = ['campagnes','patrimoines',];

export const { selectEntities, selectAll } = CampagnePatrimoineState.adapter.getSelectors();

export const selectCampagnePatrimoineState = createFeatureSelector<CampagnePatrimoineState.IState>(CampagnePatrimoineState.campagnePatrimoineFeatureKey);

export const selectIsLoadedCampagnePatrimoine = createSelector(
  selectCampagnePatrimoineState,
  (state: CampagnePatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingCampagnePatrimoine = createSelector(
  selectCampagnePatrimoineState,
  (state: CampagnePatrimoineState.IState) => state.isLoading
);

export const selectIsReadyCampagnePatrimoine = createSelector(
  selectCampagnePatrimoineState,
  (state: CampagnePatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCampagnePatrimoine = createSelector(
  selectCampagnePatrimoineState,
  (state: CampagnePatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectCampagnePatrimoinesEntities = createSelector(selectCampagnePatrimoineState, selectEntities);

export const selectCampagnePatrimoinesArray = createSelector(selectCampagnePatrimoineState, selectAll);

const campagnePatrimoinesInObject = (campagnePatrimoines: Dictionary<CampagnePatrimoineEntityState>) => ({ campagnePatrimoines })

const selectCampagnePatrimoinesEntitiesDictionary = createSelector(selectCampagnePatrimoinesEntities, campagnePatrimoinesInObject);

const selectAllCampagnePatrimoinesObject = createSelector(selectCampagnePatrimoinesEntities, campagnePatrimoines => {
  return hydrateAll({ campagnePatrimoines });
});

const selectOneCampagnePatrimoineDictionary = (idCampagnePatrimoine : number) =>
  createSelector(selectCampagnePatrimoinesEntities, campagnePatrimoines => ({
    campagnePatrimoines: { [idCampagnePatrimoine]: campagnePatrimoines[idCampagnePatrimoine] }
  }));

const selectOneCampagnePatrimoineDictionaryWithoutChild = (idCampagnePatrimoine : number) =>
  createSelector(selectCampagnePatrimoinesEntities, campagnePatrimoines => ({
    campagnePatrimoine: campagnePatrimoines[idCampagnePatrimoine]
  }));

const selectAllCampagnePatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllCampagnePatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CampagnePatrimoine>(
      schema,
      selectAllCampagnePatrimoinesSelectors,
      selectCampagnePatrimoinesEntitiesDictionary,
      getRelationSelectors,
      campagnePatrimoineRelations,
      hydrateAll,
      'campagnePatrimoine'
    );
  } else {
    return selectAllCampagnePatrimoinesObject;
  }
}

export function selectAllCampagnePatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey = 'campagnePatrimoines'
): Selector {
  return createSelector(selectAllCampagnePatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<CampagnePatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.campagnePatrimoines.length; i++) {
      res[customKey][result.campagnePatrimoines[i].idCampagnePatrimoine] = result.campagnePatrimoines[i];
    }
    return res;
  });
}

export function selectOneCampagnePatrimoine(
  schema: SelectSchema = {},
  idCampagnePatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCampagnePatrimoineDictionary(idCampagnePatrimoine)];
  selectors.push(...getRelationSelectors(schema, campagnePatrimoineRelations, 'campagnePatrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCampagnePatrimoineDictionaryWithoutChild(idCampagnePatrimoine);
  }
}

interface hydrateArgs {
  campagnePatrimoines: Dictionary<CampagnePatrimoineEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { campagnePatrimoines: (CampagnePatrimoine | null)[] } {
  const {
    campagnePatrimoines,
    campagnes,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    campagnePatrimoines: Object.keys(campagnePatrimoines).map(idCampagnePatrimoine =>
      hydrate(
        campagnePatrimoines[idCampagnePatrimoine] as CampagnePatrimoineEntityState,
        campagnes,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { campagnePatrimoine: CampagnePatrimoineEntityState | null } {
  const {
    campagnePatrimoines,
    campagnes,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const campagnePatrimoine = Object.values(campagnePatrimoines)[0];
  return {
    campagnePatrimoine: hydrate(
      campagnePatrimoine as CampagnePatrimoineEntityState,
      campagnes,
      patrimoines
    )
  };
}

function hydrate(
  campagnePatrimoine: CampagnePatrimoineEntityState,
  campagneEntities?: Dictionary<CampagneEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): CampagnePatrimoine | null {
  if (!campagnePatrimoine) {
    return null;
  }

  const campagnePatrimoineHydrated: CampagnePatrimoineEntityState = { ...campagnePatrimoine };
  if (campagneEntities) {
    campagnePatrimoineHydrated.campagne = campagneEntities[campagnePatrimoine.campagne as number] as Campagne;
  } else {
    delete campagnePatrimoineHydrated.campagne;
  }
  if (patrimoineEntities) {
    campagnePatrimoineHydrated.patrimoine = patrimoineEntities[campagnePatrimoine.patrimoine as number] as Patrimoine;
  } else {
    delete campagnePatrimoineHydrated.patrimoine;
  }

  return campagnePatrimoineHydrated as CampagnePatrimoine;
}
