import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { BoardState, BoardStateEntityState } from '@get/api-interfaces';
import { BoardStateApiService } from '@get/store/api-services';
import { BoardStateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeBoardState } from '@get/store/configs/normalization';
import { BoardStateSelectors } from '@get/store/selectors';
import { BoardStateRelationsIds } from '@get/store/ids-interfaces';
import { UserGeneratedActions } from '@get/store/actions';

export function getDefaultAddBoardStateActions(boardState: BoardStateEntityState, ids?: BoardStateRelationsIds): Action[] {
  const actions: Action[] = [BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert({ boardStates: [boardState] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyBoardStateSuccess({
        idUser: ids.user,
        idBoardStates: [boardState.idBoardState]
      })
    );
    actions.push(
      BoardStateGeneratedActions.addUserSuccess({
        idBoardState: boardState.idBoardState,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteBoardStateActions(boardState: BoardStateEntityState): Action[] {
  const actions: Action[] = [BoardStateGeneratedActions.deleteOneBoardStateSuccess({ idBoardState: boardState.idBoardState })];

  if (boardState.user) {
    actions.push(
      UserGeneratedActions.deleteManyBoardStateSuccess({
        idBoardStates: [boardState.idBoardState],
        idUsers: [boardState.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedBoardStateEffects {
  constructor(
    protected actions$: Actions,
    protected boardStateApiService: BoardStateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyBoardStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardStateGeneratedActions.getManyBoardStates),
      switchMap(({ params }) =>
        this.boardStateApiService.getBoardStates(params).pipe(
          map((boardStates: BoardState[]) => {
            return BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert({ boardStates });
          }),
          catchError(error => of(BoardStateGeneratedActions.boardStatesFailure({ error })))
        )
      )
    );
  });

  upsertOneBoardState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardStateGeneratedActions.upsertOneBoardState),
      concatMap(
        ({
          boardState,
          ids
        }: {
          boardState: Partial<BoardState>;
          ids?: BoardStateRelationsIds;
        }) => {
          if (boardState.idBoardState) {
            return this.boardStateApiService.updateBoardState(boardState).pipe(
              map((boardStateReturned: BoardState) => {
                return BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert({ boardStates: [boardStateReturned] });
              }),
              catchError(error => of(BoardStateGeneratedActions.boardStatesFailure({ error })))
            );
          } else {
            return this.boardStateApiService.addBoardState(boardState).pipe(
              mergeMap((boardStateReturned: BoardState) => getDefaultAddBoardStateActions(boardStateReturned, ids)),
              catchError(error => of(BoardStateGeneratedActions.boardStatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneBoardState$ = createEffect(() => {
    const selectBoardStateState$ = this.store$.select(BoardStateSelectors.selectBoardStateState);
    return this.actions$.pipe(
      ofType(BoardStateGeneratedActions.deleteOneBoardState),
      withLatestFrom(selectBoardStateState$),
      concatMap(([{ idBoardState }, state]) =>
        this.boardStateApiService.deleteBoardState(idBoardState).pipe(
          mergeMap(_success => getDefaultDeleteBoardStateActions(state.entities[idBoardState] as BoardStateEntityState)),
          catchError(error => of(BoardStateGeneratedActions.boardStatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyBoardStatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert),
      concatMap(({ boardStates }) => {
        const actions: Action[] = getActionsToNormalizeBoardState(boardStates, StoreActionType.upsert);
        return [getMultiAction(actions, '[BoardState] Normalization After Upsert Success')];
      })
    );
  });
}
