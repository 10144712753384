import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CampagneUserApiService } from '@get/store/api-services';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagneUser } from '@get/store/configs/normalization';

export class GeneratedCampagneUserEffects {
  constructor(
    protected actions$: Actions,
    protected campagneUserApiService: CampagneUserApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyCampagneUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert),
      concatMap(({ campagneUsers }) => {
        const actions: Action[] = getActionsToNormalizeCampagneUser(campagneUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampagneUser] Normalization After Upsert Success')];
      })
    );
  });
}
