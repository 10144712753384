import { GridError, GridErrorEntityState, SocieteEspaceFamille } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { GridErrorSocieteEspaceFamilleState } from '@get/store/states';
import { SelectSchema, Selector, SelectorModel, getRelationSelectors } from '@get/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mergeSocieteEspaceFamilleAndGridErrors } from '@utils';
import {
  selectAllSocieteEspaceFamilles,
  selectAllSocietePatrimoineHierarchies
} from './societe-espace-famille.selectors';

export const gridErrorRelations: string[] = [];

export const { selectEntities, selectAll } = GridErrorSocieteEspaceFamilleState.adapter.getSelectors();

export const selectGridErrorState = createFeatureSelector<GridErrorSocieteEspaceFamilleState.IState>(
  GridErrorSocieteEspaceFamilleState.gridErrorFeatureKey
);

export const selectIsLoadedGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorSocieteEspaceFamilleState.IState) => state.isLoaded
);

export const selectIsLoadingGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorSocieteEspaceFamilleState.IState) => state.isLoading
);

export const selectIsReadyGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorSocieteEspaceFamilleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorSocieteEspaceFamilleState.IState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const GridErrorModel: SelectorModel = {
  name: 'gridErrors',
  getSelector: selectAllGridErrorsDictionary,
  isReady: selectIsReadyGridError
};

export const selectGridErrorsEntities = createSelector(selectGridErrorState, selectEntities);

export const selectGridErrorsArray = createSelector(selectGridErrorState, selectAll);

const gridErrorsInObject = (gridErrors: Dictionary<GridErrorEntityState>) => ({ gridErrors });

const selectGridErrorsEntitiesDictionary = createSelector(selectGridErrorsEntities, gridErrorsInObject);

const selectAllGridErrorsObject = createSelector(selectGridErrorsEntities, gridErrors => {
  return hydrateAll({ gridErrors });
});

const selectOneGridErrorDictionary = (idGridError: number) =>
  createSelector(selectGridErrorsEntities, gridErrors => {
    return { gridErrors: { [idGridError]: gridErrors[idGridError] } };
  });

const selectOneGridErrorDictionaryWithoutChild = (idGridError: number) =>
  createSelector(selectGridErrorsEntities, gridErrors => {
    return { gridErrorSocieteEspaceFamille: gridErrors[idGridError] };
  });

const selectAllGridErrorsSelectors: Dictionary<Selector> = {};
export function selectAllGridErrors(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GridError>(
      schema,
      selectAllGridErrorsSelectors,
      selectGridErrorsEntitiesDictionary,
      getRelationSelectors,
      gridErrorRelations,
      hydrateAll,
      'gridErrorSocieteEspaceFamille'
    );
  } else {
    return selectAllGridErrorsObject;
  }
}

export function selectAllGridErrorsDictionary(schema: SelectSchema = {}, customKey = 'gridErrors'): Selector {
  return createSelector(selectAllGridErrors(schema), result => {
    const res = { [customKey]: {} as Dictionary<GridErrorEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.gridErrors.length; i++) {
      res[customKey][result.gridErrors[i].idGridError] = result.gridErrors[i];
    }
    return res;
  });
}

export function selectOneGridError(schema: SelectSchema = {}, idGridError: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGridErrorDictionary(idGridError)];
    selectors.push(...getRelationSelectors(schema, gridErrorRelations, 'gridErrorSocieteEspaceFamille'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGridErrorDictionaryWithoutChild(idGridError);
  }
}

interface hydrateArgs {
  gridErrors: Dictionary<GridErrorEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { gridErrors: (GridError | null)[] } {
  const { gridErrors } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    gridErrors: Object.keys(gridErrors).map(idGridError => hydrate(gridErrors[idGridError] as GridErrorEntityState))
  };
}

function hydrateOne(...args: hydrateArgs[]): { gridErrorSocieteEspaceFamille: GridErrorEntityState | null } {
  const { gridErrors } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const gridErrorSocieteEspaceFamille = Object.values(gridErrors)[0];
  return { gridErrorSocieteEspaceFamille: hydrate(gridErrorSocieteEspaceFamille as GridErrorEntityState) };
}

function hydrate(gridErrorSocieteEspaceFamille: GridErrorEntityState): GridError | null {
  if (!gridErrorSocieteEspaceFamille) {
    return null;
  }

  const gridErrorHydrated: GridErrorEntityState = { ...gridErrorSocieteEspaceFamille };

  return gridErrorHydrated as GridError;
}

export function selectSocieteEspaceFamilleAndGridErrors(schema: SelectSchema = {}) {
  return createSelector(
    selectAllSocieteEspaceFamilles(schema),
    selectAllGridErrors({}),
    (
      selectedSocieteEspaceFamilles: { societeEspaceFamilles: SocieteEspaceFamille[] },
      selectedGridErrors: { gridErrors: GridError[] }
    ) => {
      return mergeSocieteEspaceFamilleAndGridErrors(
        selectedSocieteEspaceFamilles.societeEspaceFamilles,
        selectedGridErrors.gridErrors
      );
    }
  );
}

export function selectPatrimoineHierachieAndGridErrors(schema: SelectSchema = {}) {
  return createSelector(
    selectAllSocietePatrimoineHierarchies(schema),
    selectAllGridErrors({}),
    (
      selectedSocieteEspaceFamilles: { societeEspaceFamilles: SocieteEspaceFamille[] },
      selectedGridErrors: { gridErrors: GridError[] }
    ) => {
      return mergeSocieteEspaceFamilleAndGridErrors(
        selectedSocieteEspaceFamilles.societeEspaceFamilles,
        selectedGridErrors.gridErrors
      );
    }
  );
}
