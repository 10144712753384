import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { CampagneUserRelationsIds } from '@get/store/ids-interfaces';

export const CampagneUserGeneratedActions = createActionGroup({
  source: 'Campagne User Generated',
  events: {
    'Upsert One Campagne User': props<{ campagneUser: Partial<CampagneUser>; ids?: CampagneUserRelationsIds; }>(),
    'Upsert Many Campagne Users': props<{ campagneUsers: Partial<CampagneUser>[]; ids?: CampagneUserRelationsIds; }>(),
    'Upsert Many Campagne Users Success': props<{ campagneUsers: CampagneUserEntityState[] }>(),
    'Delete One Campagne User Success': props<{ idCampagneUser: number }>(),
    'Normalize Many Campagne Users After Upsert': props<{ campagneUsers: CampagneUserEntityState[] }>(),
    'Campagne Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Campagne Users': emptyProps(),
    'Add User Success': props<{ idCampagneUser: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idCampagneUsers: number[] }>(),
    'Add Campagne Success': props<{ idCampagneUser: number; idCampagne: number }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idCampagneUsers: number[] }>()
  }
});
