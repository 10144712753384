import { createEntityAdapter } from '@ngrx/entity';
import { ProgInterventionEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgInterventionEntityState>;

export const adapter = createEntityAdapter<ProgInterventionEntityState>({
  selectId: o => o.idProgIntervention
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progInterventionFeatureKey = 'progIntervention';
