import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Composant } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ComposantGeneratedActions } from '@get/store/actions';
import { ComposantSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedComposantService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ComposantSelectors.selectIsLoadedComposant));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ComposantSelectors.selectIsLoadingComposant));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ComposantSelectors.selectIsReadyAndLoadedComposant as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllComposants(schema: SelectSchema = {}): Observable<Composant[]> {
    return this.store$.pipe(select(ComposantSelectors.selectAllComposants(schema))).pipe(
      switchMap(({ composants }: { composants: Composant[] }) => {
        return this.getReady(schema).pipe(map(() => composants));
      })
    );
  }

  public selectOneComposant(
    idComposant: number,
    schema: SelectSchema = {}
  ): Observable<Composant> {
    return this.store$
      .pipe(select(ComposantSelectors.selectOneComposant(schema, idComposant)))
      .pipe(
        switchMap(({ composant }: { composant: Composant }) => {
          return this.getReady(schema).pipe(map(() => composant));
        })
      );
  }

  public deleteOneComposant(
    idComposant: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ComposantGeneratedActions.deleteOneComposant({ idComposant }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.deleteOneComposantSuccess,
        ComposantGeneratedActions.composantsFailure
      );
    }
  }
}
