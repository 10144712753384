import { RepositoryService } from '@get/services/repository';
import { ProgInterventionFamille } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgInterventionFamilleApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgInterventionFamille(progInterventionFamille: Partial<ProgInterventionFamille>): Observable<ProgInterventionFamille> {
    return this.repo.create<ProgInterventionFamille>('prog-intervention-famille', progInterventionFamille);
  }

  public updateProgInterventionFamille(progInterventionFamille: Partial<ProgInterventionFamille>): Observable<ProgInterventionFamille> {
    return this.repo.update('prog-intervention-famille', progInterventionFamille);
  }

  public deleteProgInterventionFamille(idProgInterventionFamille: number): Observable<number> {
    return this.repo.delete('prog-intervention-famille/' + +idProgInterventionFamille);
  }
}
