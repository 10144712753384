import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateRelationsIds } from '@get/store/ids-interfaces';

export const CaracteristiqueChoixTemplateGeneratedActions = createActionGroup({
  source: 'Caracteristique Choix Template Generated',
  events: {
    'Upsert One Caracteristique Choix Template': props<{ caracteristiqueChoixTemplate: Partial<CaracteristiqueChoixTemplate>; ids?: CaracteristiqueChoixTemplateRelationsIds; }>(),
    'Upsert Many Caracteristique Choix Templates': props<{ caracteristiqueChoixTemplates: Partial<CaracteristiqueChoixTemplate>[]; ids?: CaracteristiqueChoixTemplateRelationsIds; }>(),
    'Upsert Many Caracteristique Choix Templates Success': props<{ caracteristiqueChoixTemplates: CaracteristiqueChoixTemplateEntityState[] }>(),
    'Delete One Caracteristique Choix Template Success': props<{ idCaracteristiqueChoixTemplate: number }>(),
    'Normalize Many Caracteristique Choix Templates After Upsert': props<{ caracteristiqueChoixTemplates: CaracteristiqueChoixTemplateEntityState[] }>(),
    'Caracteristique Choix Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Caracteristique Choix Templates': emptyProps(),
    'Add Many Societe Caracteristique Choix Success': props<{ idCaracteristiqueChoixTemplate: number; idSocieteCaracteristiqueChoices: number[] }>(),
    'Delete Many Societe Caracteristique Choix Success': props<{ idSocieteCaracteristiqueChoices: number[]; idCaracteristiqueChoixTemplates: number[] }>(),
    'Add Caracteristique Template Success': props<{ idCaracteristiqueChoixTemplate: number; idCaracteristiqueTemplate: number }>(),
    'Delete Many Caracteristique Template Success': props<{ idCaracteristiqueTemplates: number[]; idCaracteristiqueChoixTemplates: number[] }>(),
    'Add Fichier Success': props<{ idCaracteristiqueChoixTemplate: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idCaracteristiqueChoixTemplates: number[] }>()
  }
});
