import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieAncetreState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';

export const societePatrimoineHierarchieAncetreReducersGeneratedFunctions: ReducerTypes<SocietePatrimoineHierarchieAncetreState.IState, readonly ActionCreator[]>[] = [
  on(SocietePatrimoineHierarchieAncetreGeneratedActions.upsertOneSocietePatrimoineHierarchieAncetre, (state: SocietePatrimoineHierarchieAncetreState.IState) => setLoadingsState(state, true)),

  on(SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetresSuccess, (state: SocietePatrimoineHierarchieAncetreState.IState, { societePatrimoineHierarchieAncetres }) =>
    SocietePatrimoineHierarchieAncetreState.adapter.upsertMany(societePatrimoineHierarchieAncetres, setLoadingsState(state, false))
  ),
  on(SocietePatrimoineHierarchieAncetreGeneratedActions.deleteOneSocietePatrimoineHierarchieAncetreSuccess, (state: SocietePatrimoineHierarchieAncetreState.IState, { idSocietePatrimoineHierarchieAncetre }) =>
    SocietePatrimoineHierarchieAncetreState.adapter.removeOne(idSocietePatrimoineHierarchieAncetre, setLoadingsState(state, false))
  ),

  on(SocietePatrimoineHierarchieAncetreGeneratedActions.clearSocietePatrimoineHierarchieAncetres, () => SocietePatrimoineHierarchieAncetreState.initialState),

  on(
    SocietePatrimoineHierarchieAncetreGeneratedActions.addHierarchieAncetreSuccess,
    (state: SocietePatrimoineHierarchieAncetreState.IState, { idSocietePatrimoineHierarchieAncetre, idHierarchieAncetre }) => {
      if (!state.entities[idSocietePatrimoineHierarchieAncetre]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchieAncetre]: {
            ...state.entities[idSocietePatrimoineHierarchieAncetre],
            hierarchieAncetre: idHierarchieAncetre
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieAncetreGeneratedActions.deleteManyHierarchieAncetreSuccess,
    (state: SocietePatrimoineHierarchieAncetreState.IState, { idHierarchieAncetre, idSocietePatrimoineHierarchieAncetres }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchieAncetres.reduce((entities, idSocietePatrimoineHierarchieAncetre) => {
            if (!state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieAncetre) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchieAncetre] = {
              ...state.entities[idSocietePatrimoineHierarchieAncetre],
              societePatrimoineHierarchie: idHierarchieAncetre.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieAncetre
              )
                ? undefined
                : state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieAncetre
            } as SocietePatrimoineHierarchieAncetreEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieAncetreEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieAncetreGeneratedActions.addHierarchieDescendantSuccess,
    (state: SocietePatrimoineHierarchieAncetreState.IState, { idSocietePatrimoineHierarchieAncetre, idHierarchieDescendant }) => {
      if (!state.entities[idSocietePatrimoineHierarchieAncetre]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchieAncetre]: {
            ...state.entities[idSocietePatrimoineHierarchieAncetre],
            hierarchieDescendant: idHierarchieDescendant
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieAncetreGeneratedActions.deleteManyHierarchieDescendantSuccess,
    (state: SocietePatrimoineHierarchieAncetreState.IState, { idHierarchieDescendant, idSocietePatrimoineHierarchieAncetres }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchieAncetres.reduce((entities, idSocietePatrimoineHierarchieAncetre) => {
            if (!state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieDescendant) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchieAncetre] = {
              ...state.entities[idSocietePatrimoineHierarchieAncetre],
              societePatrimoineHierarchie: idHierarchieDescendant.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieDescendant
              )
                ? undefined
                : state.entities[idSocietePatrimoineHierarchieAncetre]?.hierarchieDescendant
            } as SocietePatrimoineHierarchieAncetreEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieAncetreEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocietePatrimoineHierarchieAncetreState.IState,
  isLoading: boolean,
  isLoaded = true
): SocietePatrimoineHierarchieAncetreState.IState {
  return { ...state, isLoaded, isLoading };
}
