import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { ValeurFichierState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurFichierEntityState } from '@get/api-interfaces';

export const valeurFichierReducersGeneratedFunctions: ReducerTypes<ValeurFichierState.IState, readonly ActionCreator[]>[] = [
  on(ValeurFichierGeneratedActions.upsertOneValeurFichier, (state: ValeurFichierState.IState) => setLoadingsState(state, true)),

  on(ValeurFichierGeneratedActions.upsertManyValeurFichiersSuccess, (state: ValeurFichierState.IState, { valeurFichiers }) =>
    ValeurFichierState.adapter.upsertMany(valeurFichiers, setLoadingsState(state, false))
  ),
  on(ValeurFichierGeneratedActions.deleteOneValeurFichierSuccess, (state: ValeurFichierState.IState, { idValeurFichier }) =>
    ValeurFichierState.adapter.removeOne(idValeurFichier, setLoadingsState(state, false))
  ),

  on(ValeurFichierGeneratedActions.clearValeurFichiers, () => ValeurFichierState.initialState),

  on(
    ValeurFichierGeneratedActions.addValeurSuccess,
    (state: ValeurFichierState.IState, { idValeurFichier, idValeur }) => {
      if (!state.entities[idValeurFichier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurFichier]: {
            ...state.entities[idValeurFichier],
            valeur: idValeur
          }
        }
      };
    }
  ),

  on(
    ValeurFichierGeneratedActions.deleteManyValeurSuccess,
    (state: ValeurFichierState.IState, { idValeurs, idValeurFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurFichiers.reduce((entities, idValeurFichier) => {
            if (!state.entities[idValeurFichier]?.valeur) {
              return entities;
            }
            entities[idValeurFichier] = {
              ...state.entities[idValeurFichier],
              valeur: idValeurs.some(
                (idValeur: number) => idValeur === state.entities[idValeurFichier]?.valeur
              )
                ? undefined
                : state.entities[idValeurFichier]?.valeur
            } as ValeurFichierEntityState;
            return entities;
          }, {} as Dictionary<ValeurFichierEntityState>)
        }
      };
    }
  ),

  on(
    ValeurFichierGeneratedActions.addFichierSuccess,
    (state: ValeurFichierState.IState, { idValeurFichier, idFichier }) => {
      if (!state.entities[idValeurFichier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurFichier]: {
            ...state.entities[idValeurFichier],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    ValeurFichierGeneratedActions.deleteManyFichierSuccess,
    (state: ValeurFichierState.IState, { idFichiers, idValeurFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurFichiers.reduce((entities, idValeurFichier) => {
            if (!state.entities[idValeurFichier]?.fichier) {
              return entities;
            }
            entities[idValeurFichier] = {
              ...state.entities[idValeurFichier],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idValeurFichier]?.fichier
              )
                ? undefined
                : state.entities[idValeurFichier]?.fichier
            } as ValeurFichierEntityState;
            return entities;
          }, {} as Dictionary<ValeurFichierEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ValeurFichierState.IState,
  isLoading: boolean,
  isLoaded = true
): ValeurFichierState.IState {
  return { ...state, isLoaded, isLoading };
}
