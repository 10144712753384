import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { ValeurComposantRelationsIds } from '@get/store/ids-interfaces';

export const ValeurComposantGeneratedActions = createActionGroup({
  source: 'Valeur Composant Generated',
  events: {
    'Upsert One Valeur Composant': props<{ valeurComposant: Partial<ValeurComposant>; ids?: ValeurComposantRelationsIds; }>(),
    'Upsert Many Valeur Composants': props<{ valeurComposants: Partial<ValeurComposant>[]; ids?: ValeurComposantRelationsIds; }>(),
    'Upsert Many Valeur Composants Success': props<{ valeurComposants: ValeurComposantEntityState[] }>(),
    'Delete One Valeur Composant Success': props<{ idValeurComposant: number }>(),
    'Normalize Many Valeur Composants After Upsert': props<{ valeurComposants: ValeurComposantEntityState[] }>(),
    'Valeur Composants Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeur Composants': emptyProps(),
    'Add Valeur Success': props<{ idValeurComposant: number; idValeur: number }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idValeurComposants: number[] }>(),
    'Add Composant Success': props<{ idValeurComposant: number; idComposant: number }>(),
    'Delete Many Composant Success': props<{ idComposants: number[]; idValeurComposants: number[] }>()
  }
});
