import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteTerritoirePatrimoineApiService } from '@get/store/api-services';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteTerritoirePatrimoine } from '@get/store/configs/normalization';

export class GeneratedSocieteTerritoirePatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected societeTerritoirePatrimoineApiService: SocieteTerritoirePatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteTerritoirePatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert),
      concatMap(({ societeTerritoirePatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteTerritoirePatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
