import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsageComposantTemplate, UsageComposantTemplateEntityState } from '@get/api-interfaces';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UsageComposantTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const usageComposantTemplateRelations: string[] = ['usages','composantTemplates',];

export const { selectEntities, selectAll } = UsageComposantTemplateState.adapter.getSelectors();

export const selectUsageComposantTemplateState = createFeatureSelector<UsageComposantTemplateState.IState>(UsageComposantTemplateState.usageComposantTemplateFeatureKey);

export const selectIsLoadedUsageComposantTemplate = createSelector(
  selectUsageComposantTemplateState,
  (state: UsageComposantTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingUsageComposantTemplate = createSelector(
  selectUsageComposantTemplateState,
  (state: UsageComposantTemplateState.IState) => state.isLoading
);

export const selectIsReadyUsageComposantTemplate = createSelector(
  selectUsageComposantTemplateState,
  (state: UsageComposantTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUsageComposantTemplate = createSelector(
  selectUsageComposantTemplateState,
  (state: UsageComposantTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectUsageComposantTemplatesEntities = createSelector(selectUsageComposantTemplateState, selectEntities);

export const selectUsageComposantTemplatesArray = createSelector(selectUsageComposantTemplateState, selectAll);

const usageComposantTemplatesInObject = (usageComposantTemplates: Dictionary<UsageComposantTemplateEntityState>) => ({ usageComposantTemplates })

const selectUsageComposantTemplatesEntitiesDictionary = createSelector(selectUsageComposantTemplatesEntities, usageComposantTemplatesInObject);

const selectAllUsageComposantTemplatesObject = createSelector(selectUsageComposantTemplatesEntities, usageComposantTemplates => {
  return hydrateAll({ usageComposantTemplates });
});

const selectOneUsageComposantTemplateDictionary = (idUsageComposantTemplate : number) =>
  createSelector(selectUsageComposantTemplatesEntities, usageComposantTemplates => ({
    usageComposantTemplates: { [idUsageComposantTemplate]: usageComposantTemplates[idUsageComposantTemplate] }
  }));

const selectOneUsageComposantTemplateDictionaryWithoutChild = (idUsageComposantTemplate : number) =>
  createSelector(selectUsageComposantTemplatesEntities, usageComposantTemplates => ({
    usageComposantTemplate: usageComposantTemplates[idUsageComposantTemplate]
  }));

const selectAllUsageComposantTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllUsageComposantTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UsageComposantTemplate>(
      schema,
      selectAllUsageComposantTemplatesSelectors,
      selectUsageComposantTemplatesEntitiesDictionary,
      getRelationSelectors,
      usageComposantTemplateRelations,
      hydrateAll,
      'usageComposantTemplate'
    );
  } else {
    return selectAllUsageComposantTemplatesObject;
  }
}

export function selectAllUsageComposantTemplatesDictionary(
  schema: SelectSchema = {},
  customKey = 'usageComposantTemplates'
): Selector {
  return createSelector(selectAllUsageComposantTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<UsageComposantTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.usageComposantTemplates.length; i++) {
      res[customKey][result.usageComposantTemplates[i].idUsageComposantTemplate] = result.usageComposantTemplates[i];
    }
    return res;
  });
}

export function selectOneUsageComposantTemplate(
  schema: SelectSchema = {},
  idUsageComposantTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUsageComposantTemplateDictionary(idUsageComposantTemplate)];
  selectors.push(...getRelationSelectors(schema, usageComposantTemplateRelations, 'usageComposantTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUsageComposantTemplateDictionaryWithoutChild(idUsageComposantTemplate);
  }
}

interface hydrateArgs {
  usageComposantTemplates: Dictionary<UsageComposantTemplateEntityState>;
  usages?: Dictionary<UsageEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { usageComposantTemplates: (UsageComposantTemplate | null)[] } {
  const {
    usageComposantTemplates,
    usages,
    composantTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    usageComposantTemplates: Object.keys(usageComposantTemplates).map(idUsageComposantTemplate =>
      hydrate(
        usageComposantTemplates[idUsageComposantTemplate] as UsageComposantTemplateEntityState,
        usages,
        composantTemplates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { usageComposantTemplate: UsageComposantTemplateEntityState | null } {
  const {
    usageComposantTemplates,
    usages,
    composantTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const usageComposantTemplate = Object.values(usageComposantTemplates)[0];
  return {
    usageComposantTemplate: hydrate(
      usageComposantTemplate as UsageComposantTemplateEntityState,
      usages,
      composantTemplates
    )
  };
}

function hydrate(
  usageComposantTemplate: UsageComposantTemplateEntityState,
  usageEntities?: Dictionary<UsageEntityState>,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
): UsageComposantTemplate | null {
  if (!usageComposantTemplate) {
    return null;
  }

  const usageComposantTemplateHydrated: UsageComposantTemplateEntityState = { ...usageComposantTemplate };
  if (usageEntities) {
    usageComposantTemplateHydrated.usage = usageEntities[usageComposantTemplate.usage as number] as Usage;
  } else {
    delete usageComposantTemplateHydrated.usage;
  }
  if (composantTemplateEntities) {
    usageComposantTemplateHydrated.composantTemplate = composantTemplateEntities[usageComposantTemplate.composantTemplate as number] as ComposantTemplate;
  } else {
    delete usageComposantTemplateHydrated.composantTemplate;
  }

  return usageComposantTemplateHydrated as UsageComposantTemplate;
}
