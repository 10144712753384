import { GridError, ProgComposant, ProgComposantDataRow, ValidationErrorInterface } from '@get/api-interfaces';

export function validateProgComposantProperties(
  composantToCreate: Partial<ProgComposant>
): ValidationErrorInterface<ProgComposant>[] {
  const errors: ValidationErrorInterface<ProgComposant>[] = [];
  if (!composantToCreate.idProgComposantSousGroupe || !composantToCreate.libelle) {
    errors.push({
      properties: ['idProgComposantSousGroupe', 'libelle'],
      message: 'You need an idProgComposantSousGroupe and a libelle to do this action'
    });
  }
  return errors;
}

export function mergeProgComposantAndGridErrors(
  composants: ProgComposant[],
  gridErrors: GridError[]
): ProgComposantDataRow[] {
  const composantDataRows: ProgComposantDataRow[] = [...(composants as ProgComposantDataRow[])];
  gridErrors?.forEach(gridError => {
    if (gridError.error.idProgComposant > 0) {
      const index = composantDataRows.findIndex(
        composant => composant.idProgComposant === gridError.error.idProgComposant
      );

      composantDataRows[index] = { ...gridError.error };
    } else {
      composantDataRows.push({ ...gridError.error });
    }
  });

  return composantDataRows;
}
