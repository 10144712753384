import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { UsageApiService } from '@get/store/api-services';
import { UsageGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUsage } from '@get/store/configs/normalization';
import { UsageSelectors } from '@get/store/selectors';
import { UsageRelationsIds } from '@get/store/ids-interfaces';
import { UsageComposantTemplateGeneratedActions } from '@get/store/actions';
import { UsageComposantTemplate } from '@get/api-interfaces';
import { UsageCaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { UsageCaracteristiqueTemplate } from '@get/api-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplate } from '@get/api-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplate } from '@get/api-interfaces';

export function getDefaultAddUsageActions(usage: UsageEntityState, ids?: UsageRelationsIds): Action[] {
  const actions: Action[] = [UsageGeneratedActions.normalizeManyUsagesAfterUpsert({ usages: [usage] })];

  if (ids?.usageComposantTemplates) {
    if (!Array.isArray(ids.usageComposantTemplates)) {
      actions.push(
        UsageComposantTemplateGeneratedActions.upsertOneUsageComposantTemplate({
          usageComposantTemplate: {
            idUsage: usage.idUsage,
            idUsageComposantTemplate: ids.usageComposantTemplates as number
          } as UsageComposantTemplate
        })
      );
      actions.push(
        UsageGeneratedActions.addManyUsageComposantTemplateSuccess({
          idUsage: usage.idUsage,
          idUsageComposantTemplates: [ids.usageComposantTemplates as number]
        })
      );
    } else {
      actions.push(
        UsageComposantTemplateGeneratedActions.upsertManyUsageComposantTemplates({
          usageComposantTemplates: (ids.usageComposantTemplates as number[]).map(
            (idUsageComposantTemplate: number) => ({
              idUsage: usage.idUsage,
              idUsageComposantTemplate
            })
          ) as UsageComposantTemplate[]
        })
      );
      actions.push(
        UsageGeneratedActions.addManyUsageComposantTemplateSuccess({
          idUsage: usage.idUsage,
          idUsageComposantTemplates: ids.usageComposantTemplates as number[]
        })
      );
    }
  }

  if (ids?.usageCaracteristiqueTemplates) {
    if (!Array.isArray(ids.usageCaracteristiqueTemplates)) {
      actions.push(
        UsageCaracteristiqueTemplateGeneratedActions.upsertOneUsageCaracteristiqueTemplate({
          usageCaracteristiqueTemplate: {
            idUsage: usage.idUsage,
            idUsageCaracteristiqueTemplate: ids.usageCaracteristiqueTemplates as number
          } as UsageCaracteristiqueTemplate
        })
      );
      actions.push(
        UsageGeneratedActions.addManyUsageCaracteristiqueTemplateSuccess({
          idUsage: usage.idUsage,
          idUsageCaracteristiqueTemplates: [ids.usageCaracteristiqueTemplates as number]
        })
      );
    } else {
      actions.push(
        UsageCaracteristiqueTemplateGeneratedActions.upsertManyUsageCaracteristiqueTemplates({
          usageCaracteristiqueTemplates: (ids.usageCaracteristiqueTemplates as number[]).map(
            (idUsageCaracteristiqueTemplate: number) => ({
              idUsage: usage.idUsage,
              idUsageCaracteristiqueTemplate
            })
          ) as UsageCaracteristiqueTemplate[]
        })
      );
      actions.push(
        UsageGeneratedActions.addManyUsageCaracteristiqueTemplateSuccess({
          idUsage: usage.idUsage,
          idUsageCaracteristiqueTemplates: ids.usageCaracteristiqueTemplates as number[]
        })
      );
    }
  }

  if (ids?.composantTemplates) {
    if (!Array.isArray(ids.composantTemplates)) {
      actions.push(
        ComposantTemplateGeneratedActions.upsertOneComposantTemplate({
          composantTemplate: {
            idUsage: usage.idUsage,
            idComposantTemplate: ids.composantTemplates as number
          } as ComposantTemplate & any
        })
      );
      actions.push(
        UsageGeneratedActions.addManyComposantTemplateSuccess({
          idUsage: usage.idUsage,
          idComposantTemplates: [ids.composantTemplates as number]
        })
      );
    } else {
      actions.push(
        ComposantTemplateGeneratedActions.upsertManyComposantTemplates({
          composantTemplates: (ids.composantTemplates as number[]).map(
            (idComposantTemplate: number) => ({
              idUsage: usage.idUsage,
              idComposantTemplate
            })
          ) as ComposantTemplate[] & any[]
        })
      );
      actions.push(
        UsageGeneratedActions.addManyComposantTemplateSuccess({
          idUsage: usage.idUsage,
          idComposantTemplates: ids.composantTemplates as number[]
        })
      );
    }
  }

  if (ids?.caracteristiqueTemplates) {
    if (!Array.isArray(ids.caracteristiqueTemplates)) {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate({
          caracteristiqueTemplate: {
            idUsage: usage.idUsage,
            idCaracteristiqueTemplate: ids.caracteristiqueTemplates as number
          } as CaracteristiqueTemplate & any
        })
      );
      actions.push(
        UsageGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idUsage: usage.idUsage,
          idCaracteristiqueTemplates: [ids.caracteristiqueTemplates as number]
        })
      );
    } else {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplates({
          caracteristiqueTemplates: (ids.caracteristiqueTemplates as number[]).map(
            (idCaracteristiqueTemplate: number) => ({
              idUsage: usage.idUsage,
              idCaracteristiqueTemplate
            })
          ) as CaracteristiqueTemplate[] & any[]
        })
      );
      actions.push(
        UsageGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idUsage: usage.idUsage,
          idCaracteristiqueTemplates: ids.caracteristiqueTemplates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteUsageActions(usage: UsageEntityState): Action[] {
  const actions: Action[] = [UsageGeneratedActions.deleteOneUsageSuccess({ idUsage: usage.idUsage })];

  if (usage.usageComposantTemplates) {
    actions.push(
      UsageComposantTemplateGeneratedActions.deleteManyUsageSuccess({
        idUsages: [usage.idUsage],
        idUsageComposantTemplates: usage.usageComposantTemplates as number[]
      })
    );
  }

  if (usage.usageCaracteristiqueTemplates) {
    actions.push(
      UsageCaracteristiqueTemplateGeneratedActions.deleteManyUsageSuccess({
        idUsages: [usage.idUsage],
        idUsageCaracteristiqueTemplates: usage.usageCaracteristiqueTemplates as number[]
      })
    );
  }

  if (usage.composantTemplates) {
    actions.push(
      ComposantTemplateGeneratedActions.deleteManyUsageSuccess({
        idUsages: [usage.idUsage],
        idComposantTemplates: usage.composantTemplates as number[]
      })
    );
  }

  if (usage.caracteristiqueTemplates) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManyUsageSuccess({
        idUsages: [usage.idUsage],
        idCaracteristiqueTemplates: usage.caracteristiqueTemplates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedUsageEffects {
  constructor(
    protected actions$: Actions,
    protected usageApiService: UsageApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUsages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageGeneratedActions.getManyUsages),
      switchMap(({ params }) =>
        this.usageApiService.getUsages(params).pipe(
          map((usages: Usage[]) => {
            return UsageGeneratedActions.normalizeManyUsagesAfterUpsert({ usages });
          }),
          catchError(error => of(UsageGeneratedActions.usagesFailure({ error })))
        )
      )
    );
  });

  upsertOneUsage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageGeneratedActions.upsertOneUsage),
      concatMap(
        ({
          usage,
          ids
        }: {
          usage: Partial<Usage>;
          ids?: UsageRelationsIds;
        }) => {
          if (usage.idUsage) {
            return this.usageApiService.updateUsage(usage).pipe(
              map((usageReturned: Usage) => {
                return UsageGeneratedActions.normalizeManyUsagesAfterUpsert({ usages: [usageReturned] });
              }),
              catchError(error => of(UsageGeneratedActions.usagesFailure({ error })))
            );
          } else {
            return this.usageApiService.addUsage(usage).pipe(
              mergeMap((usageReturned: Usage) => getDefaultAddUsageActions(usageReturned, ids)),
              catchError(error => of(UsageGeneratedActions.usagesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUsage$ = createEffect(() => {
    const selectUsageState$ = this.store$.select(UsageSelectors.selectUsageState);
    return this.actions$.pipe(
      ofType(UsageGeneratedActions.deleteOneUsage),
      withLatestFrom(selectUsageState$),
      concatMap(([{ idUsage }, state]) =>
        this.usageApiService.deleteUsage(idUsage).pipe(
          mergeMap(_success => getDefaultDeleteUsageActions(state.entities[idUsage] as UsageEntityState)),
          catchError(error => of(UsageGeneratedActions.usagesFailure({ error })))
        )
      )
    );
  });

  normalizeManyUsagesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageGeneratedActions.normalizeManyUsagesAfterUpsert),
      concatMap(({ usages }) => {
        const actions: Action[] = getActionsToNormalizeUsage(usages, StoreActionType.upsert);
        return [getMultiAction(actions, '[Usage] Normalization After Upsert Success')];
      })
    );
  });
}
