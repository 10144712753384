import { createEntityAdapter } from '@ngrx/entity';
import { ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ValeurPatrimoineEntityState>;

export const adapter = createEntityAdapter<ValeurPatrimoineEntityState>({
  selectId: o => o.idValeurPatrimoine
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const valeurPatrimoineFeatureKey = 'valeurPatrimoine';
