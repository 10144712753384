import { GridError } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const GridErrorProgComposantSousGroupeActions = createActionGroup({
  source: 'Grid Error Prog Composant Sous Groupe',
  events: {
    'Add One Grid Error Prog Composant Sous Groupe': props<{
      gridErrorProgComposantSousGroupe: GridError;
    }>(),
    'Delete One Grid Error Prog Composant Sous Groupe Success': props<{ idGridError: number }>()
  }
});
