import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Patrimoine } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { PatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedPatrimoineService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(PatrimoineSelectors.selectIsLoadedPatrimoine));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(PatrimoineSelectors.selectIsLoadingPatrimoine));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      PatrimoineSelectors.selectIsReadyAndLoadedPatrimoine as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllPatrimoines(schema: SelectSchema = {}): Observable<Patrimoine[]> {
    return this.store$.pipe(select(PatrimoineSelectors.selectAllPatrimoines(schema))).pipe(
      switchMap(({ patrimoines }: { patrimoines: Patrimoine[] }) => {
        return this.getReady(schema).pipe(map(() => patrimoines));
      })
    );
  }

  public selectOnePatrimoine(
    idPatrimoine: number,
    schema: SelectSchema = {}
  ): Observable<Patrimoine> {
    return this.store$
      .pipe(select(PatrimoineSelectors.selectOnePatrimoine(schema, idPatrimoine)))
      .pipe(
        switchMap(({ patrimoine }: { patrimoine: Patrimoine }) => {
          return this.getReady(schema).pipe(map(() => patrimoine));
        })
      );
  }

  public getOnePatrimoine(
    idPatrimoine: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Patrimoine> {
    this.store$.dispatch(PatrimoineGeneratedActions.getOnePatrimoine({ idPatrimoine, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure,
        true
      );
    }
  }

  public upsertOnePatrimoine(
    patrimoine: Partial<Patrimoine>,
    ids: PatrimoineRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Patrimoine> {
    this.store$.dispatch(PatrimoineGeneratedActions.upsertOnePatrimoine({ patrimoine, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure,
        true
      );
    }
  }
}
