import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteProfilState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeProfilRelations: string[] = ['users','userSocieteProfils','societeProfilDroits','droits','societes',];

export const { selectEntities, selectAll } = SocieteProfilState.adapter.getSelectors();

export const selectSocieteProfilState = createFeatureSelector<SocieteProfilState.IState>(SocieteProfilState.societeProfilFeatureKey);

export const selectIsLoadedSocieteProfil = createSelector(
  selectSocieteProfilState,
  (state: SocieteProfilState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteProfil = createSelector(
  selectSocieteProfilState,
  (state: SocieteProfilState.IState) => state.isLoading
);

export const selectIsReadySocieteProfil = createSelector(
  selectSocieteProfilState,
  (state: SocieteProfilState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteProfil = createSelector(
  selectSocieteProfilState,
  (state: SocieteProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteProfilsEntities = createSelector(selectSocieteProfilState, selectEntities);

export const selectSocieteProfilsArray = createSelector(selectSocieteProfilState, selectAll);

const societeProfilsInObject = (societeProfils: Dictionary<SocieteProfilEntityState>) => ({ societeProfils })

const selectSocieteProfilsEntitiesDictionary = createSelector(selectSocieteProfilsEntities, societeProfilsInObject);

const selectAllSocieteProfilsObject = createSelector(selectSocieteProfilsEntities, societeProfils => {
  return hydrateAll({ societeProfils });
});

const selectOneSocieteProfilDictionary = (idSocieteProfil : number) =>
  createSelector(selectSocieteProfilsEntities, societeProfils => ({
    societeProfils: { [idSocieteProfil]: societeProfils[idSocieteProfil] }
  }));

const selectOneSocieteProfilDictionaryWithoutChild = (idSocieteProfil : number) =>
  createSelector(selectSocieteProfilsEntities, societeProfils => ({
    societeProfil: societeProfils[idSocieteProfil]
  }));

const selectAllSocieteProfilsSelectors: Dictionary<Selector> = {};
export function selectAllSocieteProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteProfil>(
      schema,
      selectAllSocieteProfilsSelectors,
      selectSocieteProfilsEntitiesDictionary,
      getRelationSelectors,
      societeProfilRelations,
      hydrateAll,
      'societeProfil'
    );
  } else {
    return selectAllSocieteProfilsObject;
  }
}

export function selectAllSocieteProfilsDictionary(
  schema: SelectSchema = {},
  customKey = 'societeProfils'
): Selector {
  return createSelector(selectAllSocieteProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeProfils.length; i++) {
      res[customKey][result.societeProfils[i].idSocieteProfil] = result.societeProfils[i];
    }
    return res;
  });
}

export function selectOneSocieteProfil(
  schema: SelectSchema = {},
  idSocieteProfil: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteProfilDictionary(idSocieteProfil)];
  selectors.push(...getRelationSelectors(schema, societeProfilRelations, 'societeProfil'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteProfilDictionaryWithoutChild(idSocieteProfil);
  }
}

interface hydrateArgs {
  societeProfils: Dictionary<SocieteProfilEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  users?: Dictionary<UserEntityState>;
  userSocieteProfils?: Dictionary<UserSocieteProfilEntityState>;
  societeProfilDroits?: Dictionary<SocieteProfilDroitEntityState>;
  droits?: Dictionary<DroitEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeProfils: (SocieteProfil | null)[] } {
  const {
    societeProfils,
    societes,
    users,
    userSocieteProfils,
    societeProfilDroits,
    droits
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeProfils: Object.keys(societeProfils).map(idSocieteProfil =>
      hydrate(
        societeProfils[idSocieteProfil] as SocieteProfilEntityState,
        societes,
        users,
        userSocieteProfils,
        societeProfilDroits,
        droits
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeProfil: SocieteProfilEntityState | null } {
  const {
    societeProfils,
    societes,
    users,
    userSocieteProfils,
    societeProfilDroits,
    droits
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeProfil = Object.values(societeProfils)[0];
  return {
    societeProfil: hydrate(
      societeProfil as SocieteProfilEntityState,
      societes,
      users,
      userSocieteProfils,
      societeProfilDroits,
      droits
    )
  };
}

function hydrate(
  societeProfil: SocieteProfilEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  userSocieteProfilEntities?: Dictionary<UserSocieteProfilEntityState>,
  societeProfilDroitEntities?: Dictionary<SocieteProfilDroitEntityState>,
  droitEntities?: Dictionary<DroitEntityState>,
): SocieteProfil | null {
  if (!societeProfil) {
    return null;
  }

  const societeProfilHydrated: SocieteProfilEntityState = { ...societeProfil };
  if (societeEntities) {
    societeProfilHydrated.societe = societeEntities[societeProfil.societe as number] as Societe;
  } else {
    delete societeProfilHydrated.societe;
  }

  if (userEntities) {
    societeProfilHydrated.users = ((societeProfilHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete societeProfilHydrated.users;
  }

  if (userSocieteProfilEntities) {
    societeProfilHydrated.userSocieteProfils = ((societeProfilHydrated.userSocieteProfils as number[]) || []).map(
      id => userSocieteProfilEntities[id]
    ) as UserSocieteProfil[];
  } else {
    delete societeProfilHydrated.userSocieteProfils;
  }

  if (societeProfilDroitEntities) {
    societeProfilHydrated.societeProfilDroits = ((societeProfilHydrated.societeProfilDroits as number[]) || []).map(
      id => societeProfilDroitEntities[id]
    ) as SocieteProfilDroit[];
  } else {
    delete societeProfilHydrated.societeProfilDroits;
  }

  if (droitEntities) {
    societeProfilHydrated.droits = ((societeProfilHydrated.droits as number[]) || []).map(
      id => droitEntities[id]
    ) as Droit[];
  } else {
    delete societeProfilHydrated.droits;
  }

  return societeProfilHydrated as SocieteProfil;
}
