import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgPatrimoineInfo } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgPatrimoineInfoSelectors } from '@get/store/selectors';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgPatrimoineInfoService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgPatrimoineInfoSelectors.selectIsLoadedProgPatrimoineInfo));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgPatrimoineInfoSelectors.selectIsLoadingProgPatrimoineInfo));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgPatrimoineInfoSelectors.selectIsReadyAndLoadedProgPatrimoineInfo as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgPatrimoineInfos(schema: SelectSchema = {}): Observable<ProgPatrimoineInfo[]> {
    return this.store$.pipe(select(ProgPatrimoineInfoSelectors.selectAllProgPatrimoineInfos(schema))).pipe(
      switchMap(({ progPatrimoineInfos }: { progPatrimoineInfos: ProgPatrimoineInfo[] }) => {
        return this.getReady(schema).pipe(map(() => progPatrimoineInfos));
      })
    );
  }

  public selectOneProgPatrimoineInfo(
    idProgPatrimoineInfo: number,
    schema: SelectSchema = {}
  ): Observable<ProgPatrimoineInfo> {
    return this.store$
      .pipe(select(ProgPatrimoineInfoSelectors.selectOneProgPatrimoineInfo(schema, idProgPatrimoineInfo)))
      .pipe(
        switchMap(({ progPatrimoineInfo }: { progPatrimoineInfo: ProgPatrimoineInfo }) => {
          return this.getReady(schema).pipe(map(() => progPatrimoineInfo));
        })
      );
  }
}
