import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteComposantState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeComposantRelations: string[] = ['societeCaracteristiques','composants','composantAttendus','societeComposantRattachements','progComposants','societes','composantTemplates','societeComposantFamilles','composantGroupes','fichiers',];

export const { selectEntities, selectAll } = SocieteComposantState.adapter.getSelectors();

export const selectSocieteComposantState = createFeatureSelector<SocieteComposantState.IState>(SocieteComposantState.societeComposantFeatureKey);

export const selectIsLoadedSocieteComposant = createSelector(
  selectSocieteComposantState,
  (state: SocieteComposantState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteComposant = createSelector(
  selectSocieteComposantState,
  (state: SocieteComposantState.IState) => state.isLoading
);

export const selectIsReadySocieteComposant = createSelector(
  selectSocieteComposantState,
  (state: SocieteComposantState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteComposant = createSelector(
  selectSocieteComposantState,
  (state: SocieteComposantState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteComposantsEntities = createSelector(selectSocieteComposantState, selectEntities);

export const selectSocieteComposantsArray = createSelector(selectSocieteComposantState, selectAll);

const societeComposantsInObject = (societeComposants: Dictionary<SocieteComposantEntityState>) => ({ societeComposants })

const selectSocieteComposantsEntitiesDictionary = createSelector(selectSocieteComposantsEntities, societeComposantsInObject);

const selectAllSocieteComposantsObject = createSelector(selectSocieteComposantsEntities, societeComposants => {
  return hydrateAll({ societeComposants });
});

const selectOneSocieteComposantDictionary = (idSocieteComposant : number) =>
  createSelector(selectSocieteComposantsEntities, societeComposants => ({
    societeComposants: { [idSocieteComposant]: societeComposants[idSocieteComposant] }
  }));

const selectOneSocieteComposantDictionaryWithoutChild = (idSocieteComposant : number) =>
  createSelector(selectSocieteComposantsEntities, societeComposants => ({
    societeComposant: societeComposants[idSocieteComposant]
  }));

const selectAllSocieteComposantsSelectors: Dictionary<Selector> = {};
export function selectAllSocieteComposants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteComposant>(
      schema,
      selectAllSocieteComposantsSelectors,
      selectSocieteComposantsEntitiesDictionary,
      getRelationSelectors,
      societeComposantRelations,
      hydrateAll,
      'societeComposant'
    );
  } else {
    return selectAllSocieteComposantsObject;
  }
}

export function selectAllSocieteComposantsDictionary(
  schema: SelectSchema = {},
  customKey = 'societeComposants'
): Selector {
  return createSelector(selectAllSocieteComposants(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteComposantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeComposants.length; i++) {
      res[customKey][result.societeComposants[i].idSocieteComposant] = result.societeComposants[i];
    }
    return res;
  });
}

export function selectOneSocieteComposant(
  schema: SelectSchema = {},
  idSocieteComposant: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteComposantDictionary(idSocieteComposant)];
  selectors.push(...getRelationSelectors(schema, societeComposantRelations, 'societeComposant'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteComposantDictionaryWithoutChild(idSocieteComposant);
  }
}

interface hydrateArgs {
  societeComposants: Dictionary<SocieteComposantEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
  societeComposantFamilles?: Dictionary<SocieteComposantFamilleEntityState>;
  composantGroupes?: Dictionary<ComposantGroupeEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  composants?: Dictionary<ComposantEntityState>;
  composantAttendus?: Dictionary<ComposantAttenduEntityState>;
  societeComposantRattachements?: Dictionary<SocieteComposantRattachementEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeComposants: (SocieteComposant | null)[] } {
  const {
    societeComposants,
    societes,
    composantTemplates,
    societeComposantFamilles,
    composantGroupes,
    fichiers,
    societeCaracteristiques,
    composants,
    composantAttendus,
    societeComposantRattachements,
    progComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeComposants: Object.keys(societeComposants).map(idSocieteComposant =>
      hydrate(
        societeComposants[idSocieteComposant] as SocieteComposantEntityState,
        societes,
        composantTemplates,
        societeComposantFamilles,
        composantGroupes,
        fichiers,
        societeCaracteristiques,
        composants,
        composantAttendus,
        societeComposantRattachements,
        progComposants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeComposant: SocieteComposantEntityState | null } {
  const {
    societeComposants,
    societes,
    composantTemplates,
    societeComposantFamilles,
    composantGroupes,
    fichiers,
    societeCaracteristiques,
    composants,
    composantAttendus,
    societeComposantRattachements,
    progComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeComposant = Object.values(societeComposants)[0];
  return {
    societeComposant: hydrate(
      societeComposant as SocieteComposantEntityState,
      societes,
      composantTemplates,
      societeComposantFamilles,
      composantGroupes,
      fichiers,
      societeCaracteristiques,
      composants,
      composantAttendus,
      societeComposantRattachements,
      progComposants
    )
  };
}

function hydrate(
  societeComposant: SocieteComposantEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
  societeComposantFamilleEntities?: Dictionary<SocieteComposantFamilleEntityState>,
  composantGroupeEntities?: Dictionary<ComposantGroupeEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
  composantAttenduEntities?: Dictionary<ComposantAttenduEntityState>,
  societeComposantRattachementEntities?: Dictionary<SocieteComposantRattachementEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
): SocieteComposant | null {
  if (!societeComposant) {
    return null;
  }

  const societeComposantHydrated: SocieteComposantEntityState = { ...societeComposant };
  if (societeEntities) {
    societeComposantHydrated.societe = societeEntities[societeComposant.societe as number] as Societe;
  } else {
    delete societeComposantHydrated.societe;
  }
  if (composantTemplateEntities) {
    societeComposantHydrated.composantTemplate = composantTemplateEntities[societeComposant.composantTemplate as number] as ComposantTemplate;
  } else {
    delete societeComposantHydrated.composantTemplate;
  }
  if (societeComposantFamilleEntities) {
    societeComposantHydrated.societeComposantFamille = societeComposantFamilleEntities[societeComposant.societeComposantFamille as number] as SocieteComposantFamille;
  } else {
    delete societeComposantHydrated.societeComposantFamille;
  }
  if (composantGroupeEntities) {
    societeComposantHydrated.composantGroupe = composantGroupeEntities[societeComposant.composantGroupe as number] as ComposantGroupe;
  } else {
    delete societeComposantHydrated.composantGroupe;
  }
  if (fichierEntities) {
    societeComposantHydrated.fichier = fichierEntities[societeComposant.fichier as number] as Fichier;
  } else {
    delete societeComposantHydrated.fichier;
  }

  if (societeCaracteristiqueEntities) {
    societeComposantHydrated.societeCaracteristiques = ((societeComposantHydrated.societeCaracteristiques as number[]) || []).map(
      id => societeCaracteristiqueEntities[id]
    ) as SocieteCaracteristique[];
  } else {
    delete societeComposantHydrated.societeCaracteristiques;
  }

  if (composantEntities) {
    societeComposantHydrated.composants = ((societeComposantHydrated.composants as number[]) || []).map(
      id => composantEntities[id]
    ) as Composant[];
  } else {
    delete societeComposantHydrated.composants;
  }

  if (composantAttenduEntities) {
    societeComposantHydrated.composantAttendus = ((societeComposantHydrated.composantAttendus as number[]) || []).map(
      id => composantAttenduEntities[id]
    ) as ComposantAttendu[];
  } else {
    delete societeComposantHydrated.composantAttendus;
  }

  if (societeComposantRattachementEntities) {
    societeComposantHydrated.societeComposantRattachements = ((societeComposantHydrated.societeComposantRattachements as number[]) || []).map(
      id => societeComposantRattachementEntities[id]
    ) as SocieteComposantRattachement[];
  } else {
    delete societeComposantHydrated.societeComposantRattachements;
  }

  if (progComposantEntities) {
    societeComposantHydrated.progComposants = ((societeComposantHydrated.progComposants as number[]) || []).map(
      id => progComposantEntities[id]
    ) as ProgComposant[];
  } else {
    delete societeComposantHydrated.progComposants;
  }

  return societeComposantHydrated as SocieteComposant;
}
