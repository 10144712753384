import { createEntityAdapter } from '@ngrx/entity';
import { BoardStateEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<BoardStateEntityState>;

export const adapter = createEntityAdapter<BoardStateEntityState>({
  selectId: o => o.idBoardState
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const boardStateFeatureKey = 'boardState';
