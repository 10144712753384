import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { ProgScenarioState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgScenarioEntityState } from '@get/api-interfaces';

export const progScenarioReducersGeneratedFunctions: ReducerTypes<ProgScenarioState.IState, readonly ActionCreator[]>[] = [
  on(ProgScenarioGeneratedActions.upsertOneProgScenario, (state: ProgScenarioState.IState) => setLoadingsState(state, true)),

  on(ProgScenarioGeneratedActions.upsertManyProgScenariosSuccess, (state: ProgScenarioState.IState, { progScenarios }) =>
    ProgScenarioState.adapter.upsertMany(progScenarios, setLoadingsState(state, false))
  ),
  on(ProgScenarioGeneratedActions.deleteOneProgScenario, (state: ProgScenarioState.IState) => setLoadingsState(state, true)),
  on(ProgScenarioGeneratedActions.deleteOneProgScenarioSuccess, (state: ProgScenarioState.IState, { idProgScenario }) =>
    ProgScenarioState.adapter.removeOne(idProgScenario, setLoadingsState(state, false))
  ),

  on(ProgScenarioGeneratedActions.clearProgScenarios, () => ProgScenarioState.initialState),

  on(
    ProgScenarioGeneratedActions.addManyProgInterventionScenarioSuccess,
    (state: ProgScenarioState.IState, { idProgScenario, idProgInterventionScenarios }) => {
      if (!state.entities[idProgScenario]) {
        return state;
      }
      const progInterventionScenarios = (state.entities[idProgScenario]?.progInterventionScenarios as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgScenario]: {
            ...state.entities[idProgScenario],
            progInterventionScenarios: progInterventionScenarios.concat(
              idProgInterventionScenarios.filter(id => progInterventionScenarios.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.deleteManyProgInterventionScenarioSuccess,
    (state: ProgScenarioState.IState, { idProgInterventionScenarios, idProgScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgScenarios.reduce((entities, idProgScenario) => {
            if (!state.entities[idProgScenario]?.progInterventionScenarios) {
              return entities;
            }
            entities[idProgScenario] = {
              ...state.entities[idProgScenario],
              progInterventionScenarios: (state.entities[idProgScenario]?.progInterventionScenarios as number[])?.filter(
                (idProgInterventionScenario: number) => !idProgInterventionScenarios.some((id: number) => id === idProgInterventionScenario)
              )
            } as ProgScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgScenarioEntityState>)
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.addManyProgBiblioInterventionScenarioSuccess,
    (state: ProgScenarioState.IState, { idProgScenario, idProgBiblioInterventionScenarios }) => {
      if (!state.entities[idProgScenario]) {
        return state;
      }
      const progBiblioInterventionScenarios = (state.entities[idProgScenario]?.progBiblioInterventionScenarios as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgScenario]: {
            ...state.entities[idProgScenario],
            progBiblioInterventionScenarios: progBiblioInterventionScenarios.concat(
              idProgBiblioInterventionScenarios.filter(id => progBiblioInterventionScenarios.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.deleteManyProgBiblioInterventionScenarioSuccess,
    (state: ProgScenarioState.IState, { idProgBiblioInterventionScenarios, idProgScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgScenarios.reduce((entities, idProgScenario) => {
            if (!state.entities[idProgScenario]?.progBiblioInterventionScenarios) {
              return entities;
            }
            entities[idProgScenario] = {
              ...state.entities[idProgScenario],
              progBiblioInterventionScenarios: (state.entities[idProgScenario]?.progBiblioInterventionScenarios as number[])?.filter(
                (idProgBiblioInterventionScenario: number) => !idProgBiblioInterventionScenarios.some((id: number) => id === idProgBiblioInterventionScenario)
              )
            } as ProgScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgScenarioEntityState>)
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: ProgScenarioState.IState, { idProgScenario, idProgBiblioInterventions }) => {
      if (!state.entities[idProgScenario]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idProgScenario]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgScenario]: {
            ...state.entities[idProgScenario],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgScenarioState.IState, { idProgBiblioInterventions, idProgScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgScenarios.reduce((entities, idProgScenario) => {
            if (!state.entities[idProgScenario]?.progBiblioInterventions) {
              return entities;
            }
            entities[idProgScenario] = {
              ...state.entities[idProgScenario],
              progBiblioInterventions: (state.entities[idProgScenario]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as ProgScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgScenarioEntityState>)
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.addSocieteSuccess,
    (state: ProgScenarioState.IState, { idProgScenario, idSociete }) => {
      if (!state.entities[idProgScenario]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgScenario]: {
            ...state.entities[idProgScenario],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgScenarioGeneratedActions.deleteManySocieteSuccess,
    (state: ProgScenarioState.IState, { idSocietes, idProgScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgScenarios.reduce((entities, idProgScenario) => {
            if (!state.entities[idProgScenario]?.societe) {
              return entities;
            }
            entities[idProgScenario] = {
              ...state.entities[idProgScenario],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgScenario]?.societe
              )
                ? undefined
                : state.entities[idProgScenario]?.societe
            } as ProgScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgScenarioEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgScenarioState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgScenarioState.IState {
  return { ...state, isLoaded, isLoading };
}
