import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganisationAzureLogin, OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { OrganisationAzureLoginRelationsIds } from '@get/store/ids-interfaces';

export const OrganisationAzureLoginGeneratedActions = createActionGroup({
  source: 'Organisation Azure Login Generated',
  events: {
    'Upsert One Organisation Azure Login': props<{ organisationAzureLogin: Partial<OrganisationAzureLogin>; ids?: OrganisationAzureLoginRelationsIds; }>(),
    'Upsert Many Organisation Azure Logins': props<{ organisationAzureLogins: Partial<OrganisationAzureLogin>[]; ids?: OrganisationAzureLoginRelationsIds; }>(),
    'Upsert Many Organisation Azure Logins Success': props<{ organisationAzureLogins: OrganisationAzureLoginEntityState[] }>(),
    'Delete One Organisation Azure Login Success': props<{ idOrganisationAzureLogin: number }>(),
    'Normalize Many Organisation Azure Logins After Upsert': props<{ organisationAzureLogins: OrganisationAzureLoginEntityState[] }>(),
    'Organisation Azure Logins Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organisation Azure Logins': emptyProps(),
    'Add Organisation Success': props<{ idOrganisationAzureLogin: number; idOrganisation: number }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idOrganisationAzureLogins: number[] }>()
  }
});
