import { createEntityAdapter } from '@ngrx/entity';
import { UserNewsletterEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<UserNewsletterEntityState>;

export const adapter = createEntityAdapter<UserNewsletterEntityState>({
  selectId: o => o.idUserNewsletter
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const userNewsletterFeatureKey = 'userNewsletter';
