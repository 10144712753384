import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { PatrimoineHierarchieTemplate, PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { PatrimoineHierarchieTemplateApiService } from '@get/store/api-services';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizePatrimoineHierarchieTemplate } from '@get/store/configs/normalization';
import { PatrimoineHierarchieTemplateSelectors } from '@get/store/selectors';
import { PatrimoineHierarchieTemplateRelationsIds } from '@get/store/ids-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplate } from '@get/api-interfaces';

export function getDefaultAddPatrimoineHierarchieTemplateActions(patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState, ids?: PatrimoineHierarchieTemplateRelationsIds): Action[] {
  const actions: Action[] = [PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert({ patrimoineHierarchieTemplates: [patrimoineHierarchieTemplate] })];

  if (ids?.societePatrimoineHierarchies) {
    if (!Array.isArray(ids.societePatrimoineHierarchies)) {
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.upsertOneSocietePatrimoineHierarchie({
          societePatrimoineHierarchie: {
            idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
            idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchies as number
          } as SocietePatrimoineHierarchie
        })
      );
      actions.push(
        PatrimoineHierarchieTemplateGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
          idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
          idSocietePatrimoineHierarchies: [ids.societePatrimoineHierarchies as number]
        })
      );
    } else {
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.upsertManySocietePatrimoineHierarchies({
          societePatrimoineHierarchies: (ids.societePatrimoineHierarchies as number[]).map(
            (idSocietePatrimoineHierarchie: number) => ({
              idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
              idSocietePatrimoineHierarchie
            })
          ) as SocietePatrimoineHierarchie[]
        })
      );
      actions.push(
        PatrimoineHierarchieTemplateGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
          idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
          idSocietePatrimoineHierarchies: ids.societePatrimoineHierarchies as number[]
        })
      );
    }
  }

  if (ids?.caracteristiqueTemplates) {
    if (!Array.isArray(ids.caracteristiqueTemplates)) {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate({
          caracteristiqueTemplate: {
            idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
            idCaracteristiqueTemplate: ids.caracteristiqueTemplates as number
          } as CaracteristiqueTemplate
        })
      );
      actions.push(
        PatrimoineHierarchieTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
          idCaracteristiqueTemplates: [ids.caracteristiqueTemplates as number]
        })
      );
    } else {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplates({
          caracteristiqueTemplates: (ids.caracteristiqueTemplates as number[]).map(
            (idCaracteristiqueTemplate: number) => ({
              idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
              idCaracteristiqueTemplate
            })
          ) as CaracteristiqueTemplate[]
        })
      );
      actions.push(
        PatrimoineHierarchieTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate,
          idCaracteristiqueTemplates: ids.caracteristiqueTemplates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeletePatrimoineHierarchieTemplateActions(patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState): Action[] {
  const actions: Action[] = [PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplateSuccess({ idPatrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate })];

  if (patrimoineHierarchieTemplate.societePatrimoineHierarchies) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManyPatrimoineHierarchieTemplateSuccess({
        idPatrimoineHierarchieTemplates: [patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate],
        idSocietePatrimoineHierarchies: patrimoineHierarchieTemplate.societePatrimoineHierarchies as number[]
      })
    );
  }

  if (patrimoineHierarchieTemplate.caracteristiqueTemplates) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManyPatrimoineHierarchieTemplateSuccess({
        idPatrimoineHierarchieTemplates: [patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate],
        idCaracteristiqueTemplates: patrimoineHierarchieTemplate.caracteristiqueTemplates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedPatrimoineHierarchieTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected patrimoineHierarchieTemplateApiService: PatrimoineHierarchieTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyPatrimoineHierarchieTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineHierarchieTemplateGeneratedActions.getManyPatrimoineHierarchieTemplates),
      switchMap(({ params }) =>
        this.patrimoineHierarchieTemplateApiService.getPatrimoineHierarchieTemplates(params).pipe(
          map((patrimoineHierarchieTemplates: PatrimoineHierarchieTemplate[]) => {
            return PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert({ patrimoineHierarchieTemplates });
          }),
          catchError(error => of(PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure({ error })))
        )
      )
    );
  });

  upsertOnePatrimoineHierarchieTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineHierarchieTemplateGeneratedActions.upsertOnePatrimoineHierarchieTemplate),
      concatMap(
        ({
          patrimoineHierarchieTemplate,
          ids
        }: {
          patrimoineHierarchieTemplate: Partial<PatrimoineHierarchieTemplate>;
          ids?: PatrimoineHierarchieTemplateRelationsIds;
        }) => {
          if (patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate) {
            return this.patrimoineHierarchieTemplateApiService.updatePatrimoineHierarchieTemplate(patrimoineHierarchieTemplate).pipe(
              map((patrimoineHierarchieTemplateReturned: PatrimoineHierarchieTemplate) => {
                return PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert({ patrimoineHierarchieTemplates: [patrimoineHierarchieTemplateReturned] });
              }),
              catchError(error => of(PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure({ error })))
            );
          } else {
            return this.patrimoineHierarchieTemplateApiService.addPatrimoineHierarchieTemplate(patrimoineHierarchieTemplate).pipe(
              mergeMap((patrimoineHierarchieTemplateReturned: PatrimoineHierarchieTemplate) => getDefaultAddPatrimoineHierarchieTemplateActions(patrimoineHierarchieTemplateReturned, ids)),
              catchError(error => of(PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOnePatrimoineHierarchieTemplate$ = createEffect(() => {
    const selectPatrimoineHierarchieTemplateState$ = this.store$.select(PatrimoineHierarchieTemplateSelectors.selectPatrimoineHierarchieTemplateState);
    return this.actions$.pipe(
      ofType(PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplate),
      withLatestFrom(selectPatrimoineHierarchieTemplateState$),
      concatMap(([{ idPatrimoineHierarchieTemplate }, state]) =>
        this.patrimoineHierarchieTemplateApiService.deletePatrimoineHierarchieTemplate(idPatrimoineHierarchieTemplate).pipe(
          mergeMap(_success => getDefaultDeletePatrimoineHierarchieTemplateActions(state.entities[idPatrimoineHierarchieTemplate] as PatrimoineHierarchieTemplateEntityState)),
          catchError(error => of(PatrimoineHierarchieTemplateGeneratedActions.patrimoineHierarchieTemplatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyPatrimoineHierarchieTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineHierarchieTemplateGeneratedActions.normalizeManyPatrimoineHierarchieTemplatesAfterUpsert),
      concatMap(({ patrimoineHierarchieTemplates }) => {
        const actions: Action[] = getActionsToNormalizePatrimoineHierarchieTemplate(patrimoineHierarchieTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[PatrimoineHierarchieTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
