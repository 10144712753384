import { GridError } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const GridErrorProgBiblioInterventionActions = createActionGroup({
  source: 'Grid Error Prog Biblio Intervention',
  events: {
    'Add One Grid Error Prog Biblio Intervention': props<{
      gridErrorProgBiblioIntervention: GridError;
    }>(),
    'Delete One Grid Error Prog Biblio Intervention Success': props<{ idGridError: number }>()
  }
});
