import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { OrganisationNewsletterRelationsIds } from '@get/store/ids-interfaces';

export const OrganisationNewsletterGeneratedActions = createActionGroup({
  source: 'Organisation Newsletter Generated',
  events: {
    'Upsert One Organisation Newsletter': props<{ organisationNewsletter: Partial<OrganisationNewsletter>; ids?: OrganisationNewsletterRelationsIds; }>(),
    'Upsert Many Organisation Newsletters': props<{ organisationNewsletters: Partial<OrganisationNewsletter>[]; ids?: OrganisationNewsletterRelationsIds; }>(),
    'Upsert Many Organisation Newsletters Success': props<{ organisationNewsletters: OrganisationNewsletterEntityState[] }>(),
    'Delete One Organisation Newsletter Success': props<{ idOrganisationNewsletter: number }>(),
    'Normalize Many Organisation Newsletters After Upsert': props<{ organisationNewsletters: OrganisationNewsletterEntityState[] }>(),
    'Organisation Newsletters Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organisation Newsletters': emptyProps(),
    'Add Organisation Success': props<{ idOrganisationNewsletter: number; idOrganisation: number }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idOrganisationNewsletters: number[] }>(),
    'Add Newsletter Success': props<{ idOrganisationNewsletter: number; idNewsletter: number }>(),
    'Delete Many Newsletter Success': props<{ idNewsletters: number[]; idOrganisationNewsletters: number[] }>()
  }
});
