import { RepositoryService } from '@get/services/repository';
import { ProgComposantSousGroupe } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgComposantSousGroupeApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgComposantSousGroupe(progComposantSousGroupe: Partial<ProgComposantSousGroupe>): Observable<ProgComposantSousGroupe> {
    return this.repo.create<ProgComposantSousGroupe>('prog-composant-sous-groupe', progComposantSousGroupe);
  }

  public updateProgComposantSousGroupe(progComposantSousGroupe: Partial<ProgComposantSousGroupe>): Observable<ProgComposantSousGroupe> {
    return this.repo.update('prog-composant-sous-groupe', progComposantSousGroupe);
  }

  public deleteProgComposantSousGroupe(idProgComposantSousGroupe: number): Observable<number> {
    return this.repo.delete('prog-composant-sous-groupe/' + +idProgComposantSousGroupe);
  }
}
