import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { SocieteEspaceApiService } from '@get/store/api-services';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteEspace } from '@get/store/configs/normalization';
import { SocieteEspaceSelectors } from '@get/store/selectors';
import { SocieteEspaceRelationsIds } from '@get/store/ids-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { EspaceGeneratedActions } from '@get/store/actions';
import { Espace } from '@get/api-interfaces';

export function getDefaultAddSocieteEspaceActions(societeEspace: SocieteEspaceEntityState, ids?: SocieteEspaceRelationsIds): Action[] {
  const actions: Action[] = [SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert({ societeEspaces: [societeEspace] })];

  if (ids?.societeEspaceFamille) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.addManySocieteEspaceSuccess({
        idSocieteEspaceFamille: ids.societeEspaceFamille,
        idSocieteEspaces: [societeEspace.idSocieteEspace]
      })
    );
    actions.push(
      SocieteEspaceGeneratedActions.addSocieteEspaceFamilleSuccess({
        idSocieteEspace: societeEspace.idSocieteEspace,
        idSocieteEspaceFamille: ids.societeEspaceFamille
      })
    );
  }

  if (ids?.espaces) {
    if (!Array.isArray(ids.espaces)) {
      actions.push(
        EspaceGeneratedActions.upsertOneEspace({
          espace: {
            idSocieteEspace: societeEspace.idSocieteEspace,
            idEspace: ids.espaces as number
          } as Espace
        })
      );
      actions.push(
        SocieteEspaceGeneratedActions.addManyEspaceSuccess({
          idSocieteEspace: societeEspace.idSocieteEspace,
          idEspaces: [ids.espaces as number]
        })
      );
    } else {
      actions.push(
        EspaceGeneratedActions.upsertManyEspaces({
          espaces: (ids.espaces as number[]).map(
            (idEspace: number) => ({
              idSocieteEspace: societeEspace.idSocieteEspace,
              idEspace
            })
          ) as Espace[]
        })
      );
      actions.push(
        SocieteEspaceGeneratedActions.addManyEspaceSuccess({
          idSocieteEspace: societeEspace.idSocieteEspace,
          idEspaces: ids.espaces as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteEspaceActions(societeEspace: SocieteEspaceEntityState): Action[] {
  const actions: Action[] = [SocieteEspaceGeneratedActions.deleteOneSocieteEspaceSuccess({ idSocieteEspace: societeEspace.idSocieteEspace })];

  if (societeEspace.societeEspaceFamille) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.deleteManySocieteEspaceSuccess({
        idSocieteEspaces: [societeEspace.idSocieteEspace],
        idSocieteEspaceFamilles: [societeEspace.societeEspaceFamille as number]
      })
    );
  }

  if (societeEspace.espaces) {
    actions.push(
      EspaceGeneratedActions.deleteManySocieteEspaceSuccess({
        idSocieteEspaces: [societeEspace.idSocieteEspace],
        idEspaces: societeEspace.espaces as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteEspaceEffects {
  constructor(
    protected actions$: Actions,
    protected societeEspaceApiService: SocieteEspaceApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteEspaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceGeneratedActions.getManySocieteEspaces),
      switchMap(({ params }) =>
        this.societeEspaceApiService.getSocieteEspaces(params).pipe(
          map((societeEspaces: SocieteEspace[]) => {
            return SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert({ societeEspaces });
          }),
          catchError(error => of(SocieteEspaceGeneratedActions.societeEspacesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteEspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceGeneratedActions.upsertOneSocieteEspace),
      concatMap(
        ({
          societeEspace,
          ids
        }: {
          societeEspace: Partial<SocieteEspace>;
          ids?: SocieteEspaceRelationsIds;
        }) => {
          if (societeEspace.idSocieteEspace) {
            return this.societeEspaceApiService.updateSocieteEspace(societeEspace).pipe(
              map((societeEspaceReturned: SocieteEspace) => {
                return SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert({ societeEspaces: [societeEspaceReturned] });
              }),
              catchError(error => of(SocieteEspaceGeneratedActions.societeEspacesFailure({ error })))
            );
          } else {
            return this.societeEspaceApiService.addSocieteEspace(societeEspace).pipe(
              mergeMap((societeEspaceReturned: SocieteEspace) => getDefaultAddSocieteEspaceActions(societeEspaceReturned, ids)),
              catchError(error => of(SocieteEspaceGeneratedActions.societeEspacesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteEspace$ = createEffect(() => {
    const selectSocieteEspaceState$ = this.store$.select(SocieteEspaceSelectors.selectSocieteEspaceState);
    return this.actions$.pipe(
      ofType(SocieteEspaceGeneratedActions.deleteOneSocieteEspace),
      withLatestFrom(selectSocieteEspaceState$),
      concatMap(([{ idSocieteEspace }, state]) =>
        this.societeEspaceApiService.deleteSocieteEspace(idSocieteEspace).pipe(
          mergeMap(_success => getDefaultDeleteSocieteEspaceActions(state.entities[idSocieteEspace] as SocieteEspaceEntityState)),
          catchError(error => of(SocieteEspaceGeneratedActions.societeEspacesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteEspacesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert),
      concatMap(({ societeEspaces }) => {
        const actions: Action[] = getActionsToNormalizeSocieteEspace(societeEspaces, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteEspace] Normalization After Upsert Success')];
      })
    );
  });
}
