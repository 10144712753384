import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { ProgBudgetState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgBudgetEntityState } from '@get/api-interfaces';

export const progBudgetReducersGeneratedFunctions: ReducerTypes<ProgBudgetState.IState, readonly ActionCreator[]>[] = [
  on(ProgBudgetGeneratedActions.upsertOneProgBudget, (state: ProgBudgetState.IState) => setLoadingsState(state, true)),

  on(ProgBudgetGeneratedActions.upsertManyProgBudgetsSuccess, (state: ProgBudgetState.IState, { progBudgets }) =>
    ProgBudgetState.adapter.upsertMany(progBudgets, setLoadingsState(state, false))
  ),
  on(ProgBudgetGeneratedActions.deleteOneProgBudget, (state: ProgBudgetState.IState) => setLoadingsState(state, true)),
  on(ProgBudgetGeneratedActions.deleteOneProgBudgetSuccess, (state: ProgBudgetState.IState, { idProgBudget }) =>
    ProgBudgetState.adapter.removeOne(idProgBudget, setLoadingsState(state, false))
  ),

  on(ProgBudgetGeneratedActions.clearProgBudgets, () => ProgBudgetState.initialState),

  on(
    ProgBudgetGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: ProgBudgetState.IState, { idProgBudget, idProgBiblioInterventions }) => {
      if (!state.entities[idProgBudget]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idProgBudget]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBudget]: {
            ...state.entities[idProgBudget],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgBudgetGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgBudgetState.IState, { idProgBiblioInterventions, idProgBudgets }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBudgets.reduce((entities, idProgBudget) => {
            if (!state.entities[idProgBudget]?.progBiblioInterventions) {
              return entities;
            }
            entities[idProgBudget] = {
              ...state.entities[idProgBudget],
              progBiblioInterventions: (state.entities[idProgBudget]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as ProgBudgetEntityState;
            return entities;
          }, {} as Dictionary<ProgBudgetEntityState>)
        }
      };
    }
  ),

  on(
    ProgBudgetGeneratedActions.addSocieteSuccess,
    (state: ProgBudgetState.IState, { idProgBudget, idSociete }) => {
      if (!state.entities[idProgBudget]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBudget]: {
            ...state.entities[idProgBudget],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgBudgetGeneratedActions.deleteManySocieteSuccess,
    (state: ProgBudgetState.IState, { idSocietes, idProgBudgets }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBudgets.reduce((entities, idProgBudget) => {
            if (!state.entities[idProgBudget]?.societe) {
              return entities;
            }
            entities[idProgBudget] = {
              ...state.entities[idProgBudget],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgBudget]?.societe
              )
                ? undefined
                : state.entities[idProgBudget]?.societe
            } as ProgBudgetEntityState;
            return entities;
          }, {} as Dictionary<ProgBudgetEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgBudgetState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgBudgetState.IState {
  return { ...state, isLoaded, isLoading };
}
