import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgUniteApiService } from '@get/store/api-services';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgUnite } from '@get/store/configs/normalization';
import { ProgUniteSelectors } from '@get/store/selectors';
import { ProgUniteRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';

export function getDefaultAddProgUniteActions(progUnite: ProgUniteEntityState, ids?: ProgUniteRelationsIds): Action[] {
  const actions: Action[] = [ProgUniteGeneratedActions.normalizeManyProgUnitesAfterUpsert({ progUnites: [progUnite] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgUniteSuccess({
        idSociete: ids.societe,
        idProgUnites: [progUnite.idProgUnite]
      })
    );
    actions.push(
      ProgUniteGeneratedActions.addSocieteSuccess({
        idProgUnite: progUnite.idProgUnite,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idProgUnite: progUnite.idProgUnite,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention
        })
      );
      actions.push(
        ProgUniteGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgUnite: progUnite.idProgUnite,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idProgUnite: progUnite.idProgUnite,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[]
        })
      );
      actions.push(
        ProgUniteGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgUnite: progUnite.idProgUnite,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgUniteActions(progUnite: ProgUniteEntityState): Action[] {
  const actions: Action[] = [ProgUniteGeneratedActions.deleteOneProgUniteSuccess({ idProgUnite: progUnite.idProgUnite })];

  if (progUnite.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgUniteSuccess({
        idProgUnites: [progUnite.idProgUnite],
        idSocietes: [progUnite.societe as number]
      })
    );
  }

  if (progUnite.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManyProgUniteSuccess({
        idProgUnites: [progUnite.idProgUnite],
        idProgBiblioInterventions: progUnite.progBiblioInterventions as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgUniteEffects {
  constructor(
    protected actions$: Actions,
    protected progUniteApiService: ProgUniteApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgUnite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgUniteGeneratedActions.upsertOneProgUnite),
      concatMap(
        ({
          progUnite,
          ids
        }: {
          progUnite: Partial<ProgUnite>;
          ids?: ProgUniteRelationsIds;
        }) => {
          if (progUnite.idProgUnite) {
            return this.progUniteApiService.updateProgUnite(progUnite).pipe(
              map((progUniteReturned: ProgUnite) => {
                return ProgUniteGeneratedActions.normalizeManyProgUnitesAfterUpsert({ progUnites: [progUniteReturned] });
              }),
              catchError(error => of(ProgUniteGeneratedActions.progUnitesFailure({ error })))
            );
          } else {
            return this.progUniteApiService.addProgUnite(progUnite).pipe(
              mergeMap((progUniteReturned: ProgUnite) => getDefaultAddProgUniteActions(progUniteReturned, ids)),
              catchError(error => of(ProgUniteGeneratedActions.progUnitesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgUnite$ = createEffect(() => {
    const selectProgUniteState$ = this.store$.select(ProgUniteSelectors.selectProgUniteState);
    return this.actions$.pipe(
      ofType(ProgUniteGeneratedActions.deleteOneProgUnite),
      withLatestFrom(selectProgUniteState$),
      concatMap(([{ idProgUnite }, state]) =>
        this.progUniteApiService.deleteProgUnite(idProgUnite).pipe(
          mergeMap(_success => getDefaultDeleteProgUniteActions(state.entities[idProgUnite] as ProgUniteEntityState)),
          catchError(error => of(ProgUniteGeneratedActions.progUnitesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgUnitesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgUniteGeneratedActions.normalizeManyProgUnitesAfterUpsert),
      concatMap(({ progUnites }) => {
        const actions: Action[] = getActionsToNormalizeProgUnite(progUnites, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgUnite] Normalization After Upsert Success')];
      })
    );
  });
}
