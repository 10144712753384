import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { OrganisationApiService } from '@get/store/api-services';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeOrganisation } from '@get/store/configs/normalization';
import { OrganisationRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { Societe } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { Fichier } from '@get/api-interfaces';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { OrganisationAzureLogin } from '@get/api-interfaces';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { OrganisationNewsletter } from '@get/api-interfaces';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { Newsletter } from '@get/api-interfaces';

export function getDefaultAddOrganisationActions(organisation: OrganisationEntityState, ids?: OrganisationRelationsIds): Action[] {
  const actions: Action[] = [OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({ organisations: [organisation] })];

  if (ids?.societes) {
    if (!Array.isArray(ids.societes)) {
      actions.push(
        SocieteGeneratedActions.upsertOneSociete({
          societe: {
            idOrganisation: organisation.idOrganisation,
            idSociete: ids.societes as number
          } as Societe
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManySocieteSuccess({
          idOrganisation: organisation.idOrganisation,
          idSocietes: [ids.societes as number]
        })
      );
    } else {
      actions.push(
        SocieteGeneratedActions.upsertManySocietes({
          societes: (ids.societes as number[]).map(
            (idSociete: number) => ({
              idOrganisation: organisation.idOrganisation,
              idSociete
            })
          ) as Societe[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManySocieteSuccess({
          idOrganisation: organisation.idOrganisation,
          idSocietes: ids.societes as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idOrganisation: organisation.idOrganisation,
            idUser: ids.users as number
          } as User
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyUserSuccess({
          idOrganisation: organisation.idOrganisation,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map(
            (idUser: number) => ({
              idOrganisation: organisation.idOrganisation,
              idUser
            })
          ) as User[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyUserSuccess({
          idOrganisation: organisation.idOrganisation,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.fichiers) {
    if (!Array.isArray(ids.fichiers)) {
      actions.push(
        FichierGeneratedActions.upsertOneFichier({
          fichier: {
            idOrganisation: organisation.idOrganisation,
            idFichier: ids.fichiers as number
          } as Fichier
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyFichierSuccess({
          idOrganisation: organisation.idOrganisation,
          idFichiers: [ids.fichiers as number]
        })
      );
    } else {
      actions.push(
        FichierGeneratedActions.upsertManyFichiers({
          fichiers: (ids.fichiers as number[]).map(
            (idFichier: number) => ({
              idOrganisation: organisation.idOrganisation,
              idFichier
            })
          ) as Fichier[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyFichierSuccess({
          idOrganisation: organisation.idOrganisation,
          idFichiers: ids.fichiers as number[]
        })
      );
    }
  }

  if (ids?.organisationAzureLogins) {
    if (!Array.isArray(ids.organisationAzureLogins)) {
      actions.push(
        OrganisationAzureLoginGeneratedActions.upsertOneOrganisationAzureLogin({
          organisationAzureLogin: {
            idOrganisation: organisation.idOrganisation,
            idOrganisationAzureLogin: ids.organisationAzureLogins as number
          } as OrganisationAzureLogin
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyOrganisationAzureLoginSuccess({
          idOrganisation: organisation.idOrganisation,
          idOrganisationAzureLogins: [ids.organisationAzureLogins as number]
        })
      );
    } else {
      actions.push(
        OrganisationAzureLoginGeneratedActions.upsertManyOrganisationAzureLogins({
          organisationAzureLogins: (ids.organisationAzureLogins as number[]).map(
            (idOrganisationAzureLogin: number) => ({
              idOrganisation: organisation.idOrganisation,
              idOrganisationAzureLogin
            })
          ) as OrganisationAzureLogin[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyOrganisationAzureLoginSuccess({
          idOrganisation: organisation.idOrganisation,
          idOrganisationAzureLogins: ids.organisationAzureLogins as number[]
        })
      );
    }
  }

  if (ids?.organisationNewsletters) {
    if (!Array.isArray(ids.organisationNewsletters)) {
      actions.push(
        OrganisationNewsletterGeneratedActions.upsertOneOrganisationNewsletter({
          organisationNewsletter: {
            idOrganisation: organisation.idOrganisation,
            idOrganisationNewsletter: ids.organisationNewsletters as number
          } as OrganisationNewsletter
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyOrganisationNewsletterSuccess({
          idOrganisation: organisation.idOrganisation,
          idOrganisationNewsletters: [ids.organisationNewsletters as number]
        })
      );
    } else {
      actions.push(
        OrganisationNewsletterGeneratedActions.upsertManyOrganisationNewsletters({
          organisationNewsletters: (ids.organisationNewsletters as number[]).map(
            (idOrganisationNewsletter: number) => ({
              idOrganisation: organisation.idOrganisation,
              idOrganisationNewsletter
            })
          ) as OrganisationNewsletter[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyOrganisationNewsletterSuccess({
          idOrganisation: organisation.idOrganisation,
          idOrganisationNewsletters: ids.organisationNewsletters as number[]
        })
      );
    }
  }

  if (ids?.newsletters) {
    if (!Array.isArray(ids.newsletters)) {
      actions.push(
        NewsletterGeneratedActions.upsertOneNewsletter({
          newsletter: {
            idOrganisation: organisation.idOrganisation,
            idNewsletter: ids.newsletters as number
          } as Newsletter & any
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyNewsletterSuccess({
          idOrganisation: organisation.idOrganisation,
          idNewsletters: [ids.newsletters as number]
        })
      );
    } else {
      actions.push(
        NewsletterGeneratedActions.upsertManyNewsletters({
          newsletters: (ids.newsletters as number[]).map(
            (idNewsletter: number) => ({
              idOrganisation: organisation.idOrganisation,
              idNewsletter
            })
          ) as Newsletter[] & any[]
        })
      );
      actions.push(
        OrganisationGeneratedActions.addManyNewsletterSuccess({
          idOrganisation: organisation.idOrganisation,
          idNewsletters: ids.newsletters as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganisationActions(organisation: OrganisationEntityState): Action[] {
  const actions: Action[] = [OrganisationGeneratedActions.deleteOneOrganisationSuccess({ idOrganisation: organisation.idOrganisation })];

  if (organisation.societes) {
    actions.push(
      SocieteGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idSocietes: organisation.societes as number[]
      })
    );
  }

  if (organisation.users) {
    actions.push(
      UserGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idUsers: organisation.users as number[]
      })
    );
  }

  if (organisation.fichiers) {
    actions.push(
      FichierGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idFichiers: organisation.fichiers as number[]
      })
    );
  }

  if (organisation.organisationAzureLogins) {
    actions.push(
      OrganisationAzureLoginGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idOrganisationAzureLogins: organisation.organisationAzureLogins as number[]
      })
    );
  }

  if (organisation.organisationNewsletters) {
    actions.push(
      OrganisationNewsletterGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idOrganisationNewsletters: organisation.organisationNewsletters as number[]
      })
    );
  }

  if (organisation.newsletters) {
    actions.push(
      NewsletterGeneratedActions.deleteManyOrganisationSuccess({
        idOrganisations: [organisation.idOrganisation],
        idNewsletters: organisation.newsletters as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganisationEffects {
  constructor(
    protected actions$: Actions,
    protected organisationApiService: OrganisationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganisations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationGeneratedActions.getManyOrganisations),
      switchMap(({ params }) =>
        this.organisationApiService.getOrganisations(params).pipe(
          map((organisations: Organisation[]) => {
            return OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({ organisations });
          }),
          catchError(error => of(OrganisationGeneratedActions.organisationsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationGeneratedActions.upsertOneOrganisation),
      concatMap(
        ({
          organisation,
          ids
        }: {
          organisation: Partial<Organisation>;
          ids?: OrganisationRelationsIds;
        }) => {
          if (organisation.idOrganisation) {
            return this.organisationApiService.updateOrganisation(organisation).pipe(
              map((organisationReturned: Organisation) => {
                return OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({ organisations: [organisationReturned] });
              }),
              catchError(error => of(OrganisationGeneratedActions.organisationsFailure({ error })))
            );
          } else {
            return this.organisationApiService.addOrganisation(organisation).pipe(
              mergeMap((organisationReturned: Organisation) => getDefaultAddOrganisationActions(organisationReturned, ids)),
              catchError(error => of(OrganisationGeneratedActions.organisationsFailure({ error })))
            );
          }
        }
      )
    );
  });

  normalizeManyOrganisationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert),
      concatMap(({ organisations }) => {
        const actions: Action[] = getActionsToNormalizeOrganisation(organisations, StoreActionType.upsert);
        return [getMultiAction(actions, '[Organisation] Normalization After Upsert Success')];
      })
    );
  });
}
