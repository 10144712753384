import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgInterventionScenarioApiService } from '@get/store/api-services';
import { ProgInterventionScenarioGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgInterventionScenario } from '@get/store/configs/normalization';

export class GeneratedProgInterventionScenarioEffects {
  constructor(
    protected actions$: Actions,
    protected progInterventionScenarioApiService: ProgInterventionScenarioApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyProgInterventionScenariosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgInterventionScenarioGeneratedActions.normalizeManyProgInterventionScenariosAfterUpsert),
      concatMap(({ progInterventionScenarios }) => {
        const actions: Action[] = getActionsToNormalizeProgInterventionScenario(progInterventionScenarios, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgInterventionScenario] Normalization After Upsert Success')];
      })
    );
  });
}
