import { GridErrorProgBiblioInterventionActions } from '@get/store/actions';
import { GridErrorProgBiblioInterventionState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<
  GridErrorProgBiblioInterventionState.IState,
  readonly ActionCreator[]
>[] = [];

export function setLoadingsState(
  state: GridErrorProgBiblioInterventionState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorProgBiblioInterventionState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorProgBiblioInterventionActions.addOneGridErrorProgBiblioIntervention,
    (state: GridErrorProgBiblioInterventionState.IState, { gridErrorProgBiblioIntervention }) =>
      GridErrorProgBiblioInterventionState.adapter.upsertMany(
        [gridErrorProgBiblioIntervention],
        setLoadingsState(state, false)
      )
  ),
  on(
    GridErrorProgBiblioInterventionActions.deleteOneGridErrorProgBiblioInterventionSuccess,
    (state: GridErrorProgBiblioInterventionState.IState, { idGridError }) =>
      GridErrorProgBiblioInterventionState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(
  GridErrorProgBiblioInterventionState.initialState,
  ...gridErrorReducersFunctions
);

export function reducer(state: GridErrorProgBiblioInterventionState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
