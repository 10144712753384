import { createEntityAdapter } from '@ngrx/entity';
import { SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteComposantRattachementEntityState>;

export const adapter = createEntityAdapter<SocieteComposantRattachementEntityState>({
  selectId: o => o.idSocieteComposantRattachement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const societeComposantRattachementFeatureKey = 'societeComposantRattachement';
