import { Injectable } from '@angular/core';
import { OfflineContextEnum, StoreActionType } from '@enums';
import {
  Composant,
  ComposantAttendu,
  ComposantAttenduEntityState,
  DynamicType,
  Espace,
  Patrimoine,
  PatrimoineWithChildren,
  SocieteComposant,
  SocieteComposantFamille
} from '@get/api-interfaces';
import { ComposantDocument, MyDatabaseCollections } from '@get/interfaces';
import { ComposantAttenduGeneratedActions, PatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import {
  getActionsToNormalizeComposant,
  getActionsToNormalizeComposantAttendu,
  getActionsToNormalizePatrimoine
} from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import {
  filterOnlyUsefulComposantAttendusForConsultComponents,
  filterOnlyUsefulComposantAttendusForConsultDetail,
  filterOnlyUsefulComposants,
  findHighestAncestorId,
  gatherPatrimoineWithChildrenArray,
  mapPatrimoinesForConsultComponents,
  mapPatrimoinesForConsultDetails,
  mapPatrimoinesForConsultHierarchy
} from '@get/utils';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { compareNumberCustomWithNegatives, transformAncestorsArrayToTree, transformArrayToObject } from '@utils';
import fastDeepEqual from 'fast-deep-equal';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  startWith,
  tap
} from 'rxjs';
import { v4 as uuidGenerator } from 'uuid';
import { DbService } from './db.service';

@Injectable({ providedIn: 'root' })
export class PatrimoineDbService {
  constructor(
    private store$: Store<AppState>,
    private patrimoineApiService: PatrimoineApiService,
    private dbService: DbService
  ) {}

  public async getAllPatrimoinesRelatedWithIdPatrimoine(
    idPatrimoine: number,
    databaseCollections?: MyDatabaseCollections
  ): Promise<Patrimoine[]> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });
    const idHighestAncetre = findHighestAncestorId(patrimoinesObj, idPatrimoine);
    const patrimoinesTreeArray: PatrimoineWithChildren[] = transformAncestorsArrayToTree(
      idHighestAncetre as number,
      patrimoines,
      {
        key: 'idPatrimoine',
        childrenKey: 'children',
        parentListKey: 'ancetres',
        parentListIdentifierKey: 'idAncetrePatrimoine'
      }
    );
    return !patrimoinesTreeArray.length ? [] : gatherPatrimoineWithChildrenArray(patrimoinesTreeArray[0]);
  }

  private getComposantsFormattedFromPatrimoines(
    patrimoines: Patrimoine[],
    currentStoreComposants: Composant[]
  ): Composant[] {
    const currentStoreObj: DynamicType<Composant> = transformArrayToObject(currentStoreComposants, {
      key: 'idComposant'
    });
    return (
      patrimoines?.map(
        patrimoine =>
          patrimoine.espaces?.map(
            espace =>
              espace.composants.map(composant => ({
                ...composant,
                uuid: composant.uuid ?? currentStoreObj[composant.idComposant]?.uuid ?? uuidGenerator(),
                espace: { idEspace: espace.idEspace } as Espace
              })) || []
          ) || []
      ) || []
    ).flat(3);
  }

  private formatPatrimoines(patrimoines: Patrimoine[]): Patrimoine[] {
    return (
      patrimoines?.map(patrimoine => ({
        ...patrimoine,
        composantAttendus:
          patrimoine.composantAttendus?.map(
            composantAttendu =>
              ({
                idComposantAttendu: composantAttendu.idComposantAttendu
              } as ComposantAttendu)
          ) || [],
        espaces:
          patrimoine.espaces?.map(espace => {
            const { composants, ...esp } = espace;
            return esp as Espace;
          }) || []
      })) || []
    );
  }

  private formatComposantAttendus(patrimoines: Patrimoine[]): ComposantAttendu[] {
    return (
      patrimoines
        ?.map(patrimoine =>
          patrimoine?.composantAttendus?.map(composantAttendu => ({
            ...composantAttendu,
            patrimoine: {
              idPatrimoine: patrimoine.idPatrimoine
            } as Patrimoine,
            reponse: composantAttendu.reponse ?? null
          }))
        )
        .flat() || []
    );
  }

  public fetchCompletePatrimoineAndUpsertDb(params: {
    idPatrimoine: number;
    idSocietePatrimoineHierarchie?: number;
    context: OfflineContextEnum;
    completedRoute$?: ReplaySubject<void>;
    composantOrFamille?: SocieteComposant | SocieteComposantFamille;
    selectAllComposants?: Observable<Composant[]>; // Required to avoid circular dependencies
  }): void {
    this.patrimoineApiService
      .getOnePatrimoineComplete({ idPatrimoine: params.idPatrimoine })
      .pipe(
        first(),
        tap(async elements => {
          let currentStoreComposants: Composant[] = [];
          if (params.selectAllComposants) {
            params.selectAllComposants
              .pipe(
                first(),
                tap(composants => (currentStoreComposants = composants))
              )
              .subscribe();
          }
          if (elements?.length) {
            const composants = this.getComposantsFormattedFromPatrimoines(elements, currentStoreComposants);
            const composantAttendus = this.formatComposantAttendus(elements);
            const patrimoines = this.formatPatrimoines(elements);
            const composantsForStore =
              params.context === OfflineContextEnum.consultComponents
                ? filterOnlyUsefulComposants(composants, params.composantOrFamille)
                : [];

            const composantAttendusForStore =
              params.context === OfflineContextEnum.consultDetails
                ? filterOnlyUsefulComposantAttendusForConsultDetail(composantAttendus, params.idPatrimoine)
                : params.context === OfflineContextEnum.consultComponents
                ? filterOnlyUsefulComposantAttendusForConsultComponents(
                    composantAttendus,
                    params.idPatrimoine,
                    params.composantOrFamille
                  )
                : [];
            const patrimoinesForStore =
              params.context === OfflineContextEnum.consultDetails
                ? mapPatrimoinesForConsultDetails(patrimoines, params.idPatrimoine)
                : params.context === OfflineContextEnum.consultHierarchy
                ? mapPatrimoinesForConsultHierarchy(
                    patrimoines,
                    params.idPatrimoine,
                    params.idSocietePatrimoineHierarchie
                  )
                : mapPatrimoinesForConsultComponents(
                    patrimoines,
                    params.idPatrimoine,
                    composantAttendusForStore,
                    composantsForStore
                  );

            // TODO: MultiAction
            this.store$.dispatch(
              getMultiAction(
                [
                  ...getActionsToNormalizePatrimoine(patrimoinesForStore, StoreActionType.upsert),
                  ...getActionsToNormalizeComposant(composantsForStore, StoreActionType.upsert),
                  ...getActionsToNormalizeComposantAttendu(composantAttendusForStore, StoreActionType.upsert)
                ],
                '[Complete] - Normalization'
              )
            );

            const databaseCollections = await this.dbService.getDatabaseCollection();
            const composantsDocuments = await databaseCollections.composants.find().exec();
            const composantsJson = composantsDocuments.map(el => el.getLatest().toMutableJSON());
            const composantsObj: DynamicType<ComposantDocument> = transformArrayToObject(composantsJson, {
              key: 'idComposant'
            });
            for (let i = 0; i < composants?.length; i++) {
              if (composantsObj[composants[i].idComposant]?.uuid) {
                composants[i].uuid = composantsObj[composants[i].idComposant]?.uuid;
              }
            }

            await this.dbService.updateIndexedDb(
              databaseCollections,
              'composant-attendus',
              composantAttendus,
              'idComposantAttendu',
              el => el?.isSynced !== false
            );
            await this.dbService.updateIndexedDb(
              databaseCollections,
              'composants',
              composants,
              'uuid',
              el => el?.isSynced !== false
            );
            await this.dbService.updateIndexedDb(databaseCollections, 'patrimoines', patrimoines, 'idPatrimoine');
            params.completedRoute$?.next();
          }
        })
      )
      .subscribe();
  }

  // ====================================================== //
  // ==================== REFILL STORE ==================== //
  // ====================================================== //
  // TODO: Check si possible de généraliser les fonctions suivantes (hors consultComponents)
  public handleIndexedDbRefillingStorageConsultDetailPatrimoines<U>(params: {
    databaseCollections: MyDatabaseCollections;
    idPatrimoine?: number;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
  }) {
    return combineLatest([
      params.databaseCollections['patrimoines'].find().$.pipe(
        startWith([]),
        map(values => values?.map(el => el.getLatest().toMutableJSON())),
        map(values => mapPatrimoinesForConsultDetails(values, params.idPatrimoine)),
        map(values => values.sort((a, b) => compareNumberCustomWithNegatives(+a.idPatrimoine, +b.idPatrimoine))),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ]).pipe(
      untilDestroyed(params.component),
      tap(([currentValues]) => {
        if (currentValues?.length) {
          this.store$.dispatch(
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({
              patrimoines: currentValues.map(
                el =>
                  ({
                    ...el,
                    idPatrimoine: +el.idPatrimoine
                  } as Patrimoine)
              )
            })
          );
        }
      })
    );
  }

  public handleIndexedDbRefillingStorageConsultHierarchyPatrimoines<U>(params: {
    databaseCollections: MyDatabaseCollections;
    idPatrimoine?: number;
    idSocietePatrimoineHierarchie?: number;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
  }) {
    return combineLatest([
      params.databaseCollections['patrimoines'].find().$.pipe(
        startWith([]),
        map(values => values?.map(el => el.getLatest().toMutableJSON())),
        map(values =>
          mapPatrimoinesForConsultHierarchy(values, params.idPatrimoine as number, params.idSocietePatrimoineHierarchie)
        ),
        map(values => values.sort((a, b) => compareNumberCustomWithNegatives(+a.idPatrimoine, +b.idPatrimoine))),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ]).pipe(
      untilDestroyed(params.component),
      tap(([currentValues]) => {
        if (currentValues?.length) {
          this.store$.dispatch(
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({
              patrimoines: currentValues.map(
                el =>
                  ({
                    ...el,
                    idPatrimoine: +el.idPatrimoine
                  } as Patrimoine)
              )
            })
          );
        }
      })
    );
  }

  public handleIndexedDbRefillingStorageConsultDetailComposantAttendus<U>(params: {
    databaseCollections: MyDatabaseCollections;
    idPatrimoine: number;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
  }) {
    return combineLatest([
      params.databaseCollections['composant-attendus'].find().$.pipe(
        startWith([]),
        map(values => values?.map(el => el.getLatest().toMutableJSON())),
        map(values => filterOnlyUsefulComposantAttendusForConsultDetail(values, params.idPatrimoine)),
        map(values =>
          values.sort((a, b) => compareNumberCustomWithNegatives(+a.idComposantAttendu, +b.idComposantAttendu))
        ),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ]).pipe(
      untilDestroyed(params.component),
      tap(([currentValues]) => {
        if (currentValues?.length) {
          this.store$.dispatch(
            ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: currentValues.map(
                el =>
                  ({
                    ...el,
                    idComposantAttendu: +el.idComposantAttendu
                  } as ComposantAttenduEntityState)
              )
            })
          );
        }
      })
    );
  }

  public handleIndexedDbRefillingStorageConsultComponents<U>(params: {
    databaseCollections: MyDatabaseCollections;
    idPatrimoine: number;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
    composantOrFamille: SocieteComposant | SocieteComposantFamille;
  }) {
    let oldComposants: Composant[] = [];
    let oldComposantAttendus: ComposantAttenduEntityState[] = [];
    let oldPatrimoines: Patrimoine[] = [];
    return combineLatest([
      params.databaseCollections['composants'].find().$.pipe(
        startWith([]),
        map(composants => composants?.map(el => el.getLatest().toMutableJSON())?.filter(el => !el.deleted)),
        map(composants => filterOnlyUsefulComposants(composants, params.composantOrFamille)),
        map(composants => composants.sort((a, b) => compareNumberCustomWithNegatives(+a.idComposant, +b.idComposant))),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      params.databaseCollections['composant-attendus'].find().$.pipe(
        startWith([]),
        map(composantAttendus => composantAttendus?.map(el => el.getLatest().toMutableJSON())),
        map(composantAttendus =>
          filterOnlyUsefulComposantAttendusForConsultComponents(
            composantAttendus,
            params.idPatrimoine,
            params.composantOrFamille
          )
        ),
        map(composantAttendus =>
          composantAttendus.sort((a, b) =>
            compareNumberCustomWithNegatives(+a.idComposantAttendu, +b.idComposantAttendu)
          )
        ),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      params.databaseCollections['patrimoines'].find().$.pipe(
        startWith([]),
        map(patrimoines => patrimoines?.map(el => el.getLatest().toMutableJSON())),
        map(patrimoines =>
          patrimoines.sort((a, b) => compareNumberCustomWithNegatives(+a.idPatrimoine, +b.idPatrimoine))
        ),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ]).pipe(
      untilDestroyed(params.component),
      debounceTime(20),
      tap(([composants, composantAttendus, patrimoines]) => {
        if (composantAttendus?.length && patrimoines?.length) {
          const patrimoinesForStore = mapPatrimoinesForConsultComponents(
            patrimoines,
            params.idPatrimoine,
            composantAttendus,
            composants
          );
          const actions = [];
          if (!fastDeepEqual(oldPatrimoines, patrimoinesForStore)) {
            actions.push(
              ...getActionsToNormalizePatrimoine(
                patrimoinesForStore.map(
                  el =>
                    ({
                      ...el,
                      idPatrimoine: +el.idPatrimoine
                    } as Patrimoine)
                ),
                StoreActionType.upsert
              )
            );
          }
          if (!fastDeepEqual(oldComposants, composants)) {
            actions.push(
              ...getActionsToNormalizeComposant(
                composants.map(
                  el =>
                    ({
                      ...el,
                      idComposant: +el.idComposant
                    } as unknown as Composant)
                ),
                StoreActionType.upsert
              )
            );
          }
          if (!fastDeepEqual(oldComposantAttendus, composantAttendus)) {
            actions.push(
              ...getActionsToNormalizeComposantAttendu(
                composantAttendus.map(
                  el =>
                    ({
                      ...el,
                      idComposantAttendu: +el.idComposantAttendu
                    } as ComposantAttenduEntityState)
                ),
                StoreActionType.upsert
              )
            );
          }
          oldPatrimoines = patrimoinesForStore;
          oldComposants = composants;
          oldComposantAttendus = composantAttendus;
          if (actions.length > 1) {
            this.store$.dispatch(getMultiAction(actions, '[Refill From Storage] - Normalization'));
          } else if (actions.length === 1) {
            this.store$.dispatch(actions[0]);
          }
        }
      })
    );
  }
}
