import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';

export const patrimoineHierarchieTemplateReducersGeneratedFunctions: ReducerTypes<PatrimoineHierarchieTemplateState.IState, readonly ActionCreator[]>[] = [
  on(PatrimoineHierarchieTemplateGeneratedActions.getManyPatrimoineHierarchieTemplates, (state: PatrimoineHierarchieTemplateState.IState) => setLoadingsState(state, true)),
  on(PatrimoineHierarchieTemplateGeneratedActions.upsertOnePatrimoineHierarchieTemplate, (state: PatrimoineHierarchieTemplateState.IState) => setLoadingsState(state, true)),

  on(PatrimoineHierarchieTemplateGeneratedActions.upsertManyPatrimoineHierarchieTemplatesSuccess, (state: PatrimoineHierarchieTemplateState.IState, { patrimoineHierarchieTemplates }) =>
    PatrimoineHierarchieTemplateState.adapter.upsertMany(patrimoineHierarchieTemplates, setLoadingsState(state, false))
  ),
  on(PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplate, (state: PatrimoineHierarchieTemplateState.IState) => setLoadingsState(state, true)),
  on(PatrimoineHierarchieTemplateGeneratedActions.deleteOnePatrimoineHierarchieTemplateSuccess, (state: PatrimoineHierarchieTemplateState.IState, { idPatrimoineHierarchieTemplate }) =>
    PatrimoineHierarchieTemplateState.adapter.removeOne(idPatrimoineHierarchieTemplate, setLoadingsState(state, false))
  ),

  on(PatrimoineHierarchieTemplateGeneratedActions.clearPatrimoineHierarchieTemplates, () => PatrimoineHierarchieTemplateState.initialState),

  on(
    PatrimoineHierarchieTemplateGeneratedActions.addManySocietePatrimoineHierarchieSuccess,
    (state: PatrimoineHierarchieTemplateState.IState, { idPatrimoineHierarchieTemplate, idSocietePatrimoineHierarchies }) => {
      if (!state.entities[idPatrimoineHierarchieTemplate]) {
        return state;
      }
      const societePatrimoineHierarchies = (state.entities[idPatrimoineHierarchieTemplate]?.societePatrimoineHierarchies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoineHierarchieTemplate]: {
            ...state.entities[idPatrimoineHierarchieTemplate],
            societePatrimoineHierarchies: societePatrimoineHierarchies.concat(
              idSocietePatrimoineHierarchies.filter(id => societePatrimoineHierarchies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineHierarchieTemplateGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: PatrimoineHierarchieTemplateState.IState, { idSocietePatrimoineHierarchies, idPatrimoineHierarchieTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoineHierarchieTemplates.reduce((entities, idPatrimoineHierarchieTemplate) => {
            if (!state.entities[idPatrimoineHierarchieTemplate]?.societePatrimoineHierarchies) {
              return entities;
            }
            entities[idPatrimoineHierarchieTemplate] = {
              ...state.entities[idPatrimoineHierarchieTemplate],
              societePatrimoineHierarchies: (state.entities[idPatrimoineHierarchieTemplate]?.societePatrimoineHierarchies as number[])?.filter(
                (idSocietePatrimoineHierarchie: number) => !idSocietePatrimoineHierarchies.some((id: number) => id === idSocietePatrimoineHierarchie)
              )
            } as PatrimoineHierarchieTemplateEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineHierarchieTemplateEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineHierarchieTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess,
    (state: PatrimoineHierarchieTemplateState.IState, { idPatrimoineHierarchieTemplate, idCaracteristiqueTemplates }) => {
      if (!state.entities[idPatrimoineHierarchieTemplate]) {
        return state;
      }
      const caracteristiqueTemplates = (state.entities[idPatrimoineHierarchieTemplate]?.caracteristiqueTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoineHierarchieTemplate]: {
            ...state.entities[idPatrimoineHierarchieTemplate],
            caracteristiqueTemplates: caracteristiqueTemplates.concat(
              idCaracteristiqueTemplates.filter(id => caracteristiqueTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineHierarchieTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: PatrimoineHierarchieTemplateState.IState, { idCaracteristiqueTemplates, idPatrimoineHierarchieTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoineHierarchieTemplates.reduce((entities, idPatrimoineHierarchieTemplate) => {
            if (!state.entities[idPatrimoineHierarchieTemplate]?.caracteristiqueTemplates) {
              return entities;
            }
            entities[idPatrimoineHierarchieTemplate] = {
              ...state.entities[idPatrimoineHierarchieTemplate],
              caracteristiqueTemplates: (state.entities[idPatrimoineHierarchieTemplate]?.caracteristiqueTemplates as number[])?.filter(
                (idCaracteristiqueTemplate: number) => !idCaracteristiqueTemplates.some((id: number) => id === idCaracteristiqueTemplate)
              )
            } as PatrimoineHierarchieTemplateEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineHierarchieTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: PatrimoineHierarchieTemplateState.IState,
  isLoading: boolean,
  isLoaded = true
): PatrimoineHierarchieTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
