import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { NewsletterState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const newsletterRelations: string[] = ['userNewsletters','organisationNewsletters','users','organisations',];

export const { selectEntities, selectAll } = NewsletterState.adapter.getSelectors();

export const selectNewsletterState = createFeatureSelector<NewsletterState.IState>(NewsletterState.newsletterFeatureKey);

export const selectIsLoadedNewsletter = createSelector(
  selectNewsletterState,
  (state: NewsletterState.IState) => state.isLoaded
);

export const selectIsLoadingNewsletter = createSelector(
  selectNewsletterState,
  (state: NewsletterState.IState) => state.isLoading
);

export const selectIsReadyNewsletter = createSelector(
  selectNewsletterState,
  (state: NewsletterState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedNewsletter = createSelector(
  selectNewsletterState,
  (state: NewsletterState.IState) => state.isLoaded && !state.isLoading
);

export const selectNewslettersEntities = createSelector(selectNewsletterState, selectEntities);

export const selectNewslettersArray = createSelector(selectNewsletterState, selectAll);

const newslettersInObject = (newsletters: Dictionary<NewsletterEntityState>) => ({ newsletters })

const selectNewslettersEntitiesDictionary = createSelector(selectNewslettersEntities, newslettersInObject);

const selectAllNewslettersObject = createSelector(selectNewslettersEntities, newsletters => {
  return hydrateAll({ newsletters });
});

const selectOneNewsletterDictionary = (idNewsletter : number) =>
  createSelector(selectNewslettersEntities, newsletters => ({
    newsletters: { [idNewsletter]: newsletters[idNewsletter] }
  }));

const selectOneNewsletterDictionaryWithoutChild = (idNewsletter : number) =>
  createSelector(selectNewslettersEntities, newsletters => ({
    newsletter: newsletters[idNewsletter]
  }));

const selectAllNewslettersSelectors: Dictionary<Selector> = {};
export function selectAllNewsletters(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Newsletter>(
      schema,
      selectAllNewslettersSelectors,
      selectNewslettersEntitiesDictionary,
      getRelationSelectors,
      newsletterRelations,
      hydrateAll,
      'newsletter'
    );
  } else {
    return selectAllNewslettersObject;
  }
}

export function selectAllNewslettersDictionary(
  schema: SelectSchema = {},
  customKey = 'newsletters'
): Selector {
  return createSelector(selectAllNewsletters(schema), result => {
    const res = { [customKey]: {} as Dictionary<NewsletterEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.newsletters.length; i++) {
      res[customKey][result.newsletters[i].idNewsletter] = result.newsletters[i];
    }
    return res;
  });
}

export function selectOneNewsletter(
  schema: SelectSchema = {},
  idNewsletter: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneNewsletterDictionary(idNewsletter)];
  selectors.push(...getRelationSelectors(schema, newsletterRelations, 'newsletter'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneNewsletterDictionaryWithoutChild(idNewsletter);
  }
}

interface hydrateArgs {
  newsletters: Dictionary<NewsletterEntityState>;
  userNewsletters?: Dictionary<UserNewsletterEntityState>;
  organisationNewsletters?: Dictionary<OrganisationNewsletterEntityState>;
  users?: Dictionary<UserEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { newsletters: (Newsletter | null)[] } {
  const {
    newsletters,
    userNewsletters,
    organisationNewsletters,
    users,
    organisations
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    newsletters: Object.keys(newsletters).map(idNewsletter =>
      hydrate(
        newsletters[idNewsletter] as NewsletterEntityState,
        userNewsletters,
        organisationNewsletters,
        users,
        organisations
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { newsletter: NewsletterEntityState | null } {
  const {
    newsletters,
    userNewsletters,
    organisationNewsletters,
    users,
    organisations
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const newsletter = Object.values(newsletters)[0];
  return {
    newsletter: hydrate(
      newsletter as NewsletterEntityState,
      userNewsletters,
      organisationNewsletters,
      users,
      organisations
    )
  };
}

function hydrate(
  newsletter: NewsletterEntityState,
  userNewsletterEntities?: Dictionary<UserNewsletterEntityState>,
  organisationNewsletterEntities?: Dictionary<OrganisationNewsletterEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  organisationEntities?: Dictionary<OrganisationEntityState>,
): Newsletter | null {
  if (!newsletter) {
    return null;
  }

  const newsletterHydrated: NewsletterEntityState = { ...newsletter };

  if (userNewsletterEntities) {
    newsletterHydrated.userNewsletters = ((newsletterHydrated.userNewsletters as number[]) || []).map(
      id => userNewsletterEntities[id]
    ) as UserNewsletter[];
  } else {
    delete newsletterHydrated.userNewsletters;
  }

  if (organisationNewsletterEntities) {
    newsletterHydrated.organisationNewsletters = ((newsletterHydrated.organisationNewsletters as number[]) || []).map(
      id => organisationNewsletterEntities[id]
    ) as OrganisationNewsletter[];
  } else {
    delete newsletterHydrated.organisationNewsletters;
  }

  if (userEntities) {
    newsletterHydrated.users = ((newsletterHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete newsletterHydrated.users;
  }

  if (organisationEntities) {
    newsletterHydrated.organisations = ((newsletterHydrated.organisations as number[]) || []).map(
      id => organisationEntities[id]
    ) as Organisation[];
  } else {
    delete newsletterHydrated.organisations;
  }

  return newsletterHydrated as Newsletter;
}
