import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { PatrimoineAncetreApiService } from '@get/store/api-services';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizePatrimoineAncetre } from '@get/store/configs/normalization';

export class GeneratedPatrimoineAncetreEffects {
  constructor(
    protected actions$: Actions,
    protected patrimoineAncetreApiService: PatrimoineAncetreApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyPatrimoineAncetresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert),
      concatMap(({ patrimoineAncetres }) => {
        const actions: Action[] = getActionsToNormalizePatrimoineAncetre(patrimoineAncetres, StoreActionType.upsert);
        return [getMultiAction(actions, '[PatrimoineAncetre] Normalization After Upsert Success')];
      })
    );
  });
}
