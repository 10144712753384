import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgUnite } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgUniteRelationsIds } from '@get/store/ids-interfaces';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { ProgUniteSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgUniteService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgUniteSelectors.selectIsLoadedProgUnite));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgUniteSelectors.selectIsLoadingProgUnite));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgUniteSelectors.selectIsReadyAndLoadedProgUnite as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgUnites(schema: SelectSchema = {}): Observable<ProgUnite[]> {
    return this.store$.pipe(select(ProgUniteSelectors.selectAllProgUnites(schema))).pipe(
      switchMap(({ progUnites }: { progUnites: ProgUnite[] }) => {
        return this.getReady(schema).pipe(map(() => progUnites));
      })
    );
  }

  public selectOneProgUnite(
    idProgUnite: number,
    schema: SelectSchema = {}
  ): Observable<ProgUnite> {
    return this.store$
      .pipe(select(ProgUniteSelectors.selectOneProgUnite(schema, idProgUnite)))
      .pipe(
        switchMap(({ progUnite }: { progUnite: ProgUnite }) => {
          return this.getReady(schema).pipe(map(() => progUnite));
        })
      );
  }

  public upsertOneProgUnite(
    progUnite: Partial<ProgUnite>,
    ids: ProgUniteRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgUnite> {
    this.store$.dispatch(ProgUniteGeneratedActions.upsertOneProgUnite({ progUnite, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgUniteGeneratedActions.normalizeManyProgUnitesAfterUpsert,
        ProgUniteGeneratedActions.progUnitesFailure,
        true
      );
    }
  }

  public deleteOneProgUnite(
    idProgUnite: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgUniteGeneratedActions.deleteOneProgUnite({ idProgUnite }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgUniteGeneratedActions.deleteOneProgUniteSuccess,
        ProgUniteGeneratedActions.progUnitesFailure
      );
    }
  }
}
