import { create1ToNArray } from './arrays.utils';

export const popularityScores = create1ToNArray(4);

export function displayStars(
  nbFullStar: number,
  nbStarTotal: number,
  fullStarIcon: string,
  emptyStarIcon: string
): string {
  const arrResult = [];
  if (fullStarIcon) {
    for (let i = 0; i < Math.min(nbFullStar || 0, nbStarTotal); i++) {
      arrResult.push(fullStarIcon);
    }
  }
  if (emptyStarIcon) {
    const missingStars = (nbStarTotal || 0) - arrResult.length;
    for (let i = 0; i < missingStars; i++) {
      arrResult.push(emptyStarIcon);
    }
  }
  return arrResult.join('');
}
