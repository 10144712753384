import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteProfilDroitState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeProfilDroitRelations: string[] = ['societeProfils','droits',];

export const { selectEntities, selectAll } = SocieteProfilDroitState.adapter.getSelectors();

export const selectSocieteProfilDroitState = createFeatureSelector<SocieteProfilDroitState.IState>(SocieteProfilDroitState.societeProfilDroitFeatureKey);

export const selectIsLoadedSocieteProfilDroit = createSelector(
  selectSocieteProfilDroitState,
  (state: SocieteProfilDroitState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteProfilDroit = createSelector(
  selectSocieteProfilDroitState,
  (state: SocieteProfilDroitState.IState) => state.isLoading
);

export const selectIsReadySocieteProfilDroit = createSelector(
  selectSocieteProfilDroitState,
  (state: SocieteProfilDroitState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteProfilDroit = createSelector(
  selectSocieteProfilDroitState,
  (state: SocieteProfilDroitState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteProfilDroitsEntities = createSelector(selectSocieteProfilDroitState, selectEntities);

export const selectSocieteProfilDroitsArray = createSelector(selectSocieteProfilDroitState, selectAll);

const societeProfilDroitsInObject = (societeProfilDroits: Dictionary<SocieteProfilDroitEntityState>) => ({ societeProfilDroits })

const selectSocieteProfilDroitsEntitiesDictionary = createSelector(selectSocieteProfilDroitsEntities, societeProfilDroitsInObject);

const selectAllSocieteProfilDroitsObject = createSelector(selectSocieteProfilDroitsEntities, societeProfilDroits => {
  return hydrateAll({ societeProfilDroits });
});

const selectOneSocieteProfilDroitDictionary = (idSocieteProfilDroit : number) =>
  createSelector(selectSocieteProfilDroitsEntities, societeProfilDroits => ({
    societeProfilDroits: { [idSocieteProfilDroit]: societeProfilDroits[idSocieteProfilDroit] }
  }));

const selectOneSocieteProfilDroitDictionaryWithoutChild = (idSocieteProfilDroit : number) =>
  createSelector(selectSocieteProfilDroitsEntities, societeProfilDroits => ({
    societeProfilDroit: societeProfilDroits[idSocieteProfilDroit]
  }));

const selectAllSocieteProfilDroitsSelectors: Dictionary<Selector> = {};
export function selectAllSocieteProfilDroits(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteProfilDroit>(
      schema,
      selectAllSocieteProfilDroitsSelectors,
      selectSocieteProfilDroitsEntitiesDictionary,
      getRelationSelectors,
      societeProfilDroitRelations,
      hydrateAll,
      'societeProfilDroit'
    );
  } else {
    return selectAllSocieteProfilDroitsObject;
  }
}

export function selectAllSocieteProfilDroitsDictionary(
  schema: SelectSchema = {},
  customKey = 'societeProfilDroits'
): Selector {
  return createSelector(selectAllSocieteProfilDroits(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteProfilDroitEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeProfilDroits.length; i++) {
      res[customKey][result.societeProfilDroits[i].idSocieteProfilDroit] = result.societeProfilDroits[i];
    }
    return res;
  });
}

export function selectOneSocieteProfilDroit(
  schema: SelectSchema = {},
  idSocieteProfilDroit: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteProfilDroitDictionary(idSocieteProfilDroit)];
  selectors.push(...getRelationSelectors(schema, societeProfilDroitRelations, 'societeProfilDroit'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteProfilDroitDictionaryWithoutChild(idSocieteProfilDroit);
  }
}

interface hydrateArgs {
  societeProfilDroits: Dictionary<SocieteProfilDroitEntityState>;
  societeProfils?: Dictionary<SocieteProfilEntityState>;
  droits?: Dictionary<DroitEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeProfilDroits: (SocieteProfilDroit | null)[] } {
  const {
    societeProfilDroits,
    societeProfils,
    droits
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeProfilDroits: Object.keys(societeProfilDroits).map(idSocieteProfilDroit =>
      hydrate(
        societeProfilDroits[idSocieteProfilDroit] as SocieteProfilDroitEntityState,
        societeProfils,
        droits
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeProfilDroit: SocieteProfilDroitEntityState | null } {
  const {
    societeProfilDroits,
    societeProfils,
    droits
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeProfilDroit = Object.values(societeProfilDroits)[0];
  return {
    societeProfilDroit: hydrate(
      societeProfilDroit as SocieteProfilDroitEntityState,
      societeProfils,
      droits
    )
  };
}

function hydrate(
  societeProfilDroit: SocieteProfilDroitEntityState,
  societeProfilEntities?: Dictionary<SocieteProfilEntityState>,
  droitEntities?: Dictionary<DroitEntityState>,
): SocieteProfilDroit | null {
  if (!societeProfilDroit) {
    return null;
  }

  const societeProfilDroitHydrated: SocieteProfilDroitEntityState = { ...societeProfilDroit };
  if (societeProfilEntities) {
    societeProfilDroitHydrated.societeProfil = societeProfilEntities[societeProfilDroit.societeProfil as number] as SocieteProfil;
  } else {
    delete societeProfilDroitHydrated.societeProfil;
  }
  if (droitEntities) {
    societeProfilDroitHydrated.droit = droitEntities[societeProfilDroit.droit as number] as Droit;
  } else {
    delete societeProfilDroitHydrated.droit;
  }

  return societeProfilDroitHydrated as SocieteProfilDroit;
}
