import { ProgBiblioInterventionScenarioState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progBiblioInterventionScenarioReducersGeneratedFunctions } from './prog-biblio-intervention-scenario-generated.reducer';

const progBiblioInterventionScenarioReducersFunctions = [...progBiblioInterventionScenarioReducersGeneratedFunctions];

const progBiblioInterventionScenarioReducer = createReducer(
  ProgBiblioInterventionScenarioState.initialState,
  ...progBiblioInterventionScenarioReducersFunctions
);

export function reducer(state: ProgBiblioInterventionScenarioState.IState | undefined, action: Action) {
  return progBiblioInterventionScenarioReducer(state, action);
}
