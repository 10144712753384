import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgInterventionScenarioState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgInterventionScenarioEntityState } from '@get/api-interfaces';

export const progInterventionScenarioReducersGeneratedFunctions: ReducerTypes<ProgInterventionScenarioState.IState, readonly ActionCreator[]>[] = [
  on(ProgInterventionScenarioGeneratedActions.upsertOneProgInterventionScenario, (state: ProgInterventionScenarioState.IState) => setLoadingsState(state, true)),

  on(ProgInterventionScenarioGeneratedActions.upsertManyProgInterventionScenariosSuccess, (state: ProgInterventionScenarioState.IState, { progInterventionScenarios }) =>
    ProgInterventionScenarioState.adapter.upsertMany(progInterventionScenarios, setLoadingsState(state, false))
  ),
  on(ProgInterventionScenarioGeneratedActions.deleteOneProgInterventionScenarioSuccess, (state: ProgInterventionScenarioState.IState, { idProgInterventionScenario }) =>
    ProgInterventionScenarioState.adapter.removeOne(idProgInterventionScenario, setLoadingsState(state, false))
  ),

  on(ProgInterventionScenarioGeneratedActions.clearProgInterventionScenarios, () => ProgInterventionScenarioState.initialState),

  on(
    ProgInterventionScenarioGeneratedActions.addProgScenarioSuccess,
    (state: ProgInterventionScenarioState.IState, { idProgInterventionScenario, idProgScenario }) => {
      if (!state.entities[idProgInterventionScenario]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgInterventionScenario]: {
            ...state.entities[idProgInterventionScenario],
            progScenario: idProgScenario
          }
        }
      };
    }
  ),

  on(
    ProgInterventionScenarioGeneratedActions.deleteManyProgScenarioSuccess,
    (state: ProgInterventionScenarioState.IState, { idProgScenarios, idProgInterventionScenarios }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgInterventionScenarios.reduce((entities, idProgInterventionScenario) => {
            if (!state.entities[idProgInterventionScenario]?.progScenario) {
              return entities;
            }
            entities[idProgInterventionScenario] = {
              ...state.entities[idProgInterventionScenario],
              progScenario: idProgScenarios.some(
                (idProgScenario: number) => idProgScenario === state.entities[idProgInterventionScenario]?.progScenario
              )
                ? undefined
                : state.entities[idProgInterventionScenario]?.progScenario
            } as ProgInterventionScenarioEntityState;
            return entities;
          }, {} as Dictionary<ProgInterventionScenarioEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgInterventionScenarioState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgInterventionScenarioState.IState {
  return { ...state, isLoaded, isLoading };
}
