import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CampagneSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagneSocieteCaracteristique } from '@get/store/configs/normalization';

export class GeneratedCampagneSocieteCaracteristiqueEffects {
  constructor(
    protected actions$: Actions,
    protected campagneSocieteCaracteristiqueApiService: CampagneSocieteCaracteristiqueApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyCampagneSocieteCaracteristiquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert),
      concatMap(({ campagneSocieteCaracteristiques }) => {
        const actions: Action[] = getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiques, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampagneSocieteCaracteristique] Normalization After Upsert Success')];
      })
    );
  });
}
