import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UsageComposantTemplateGeneratedActions } from '@get/store/actions';
import { UsageComposantTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UsageComposantTemplateEntityState } from '@get/api-interfaces';

export const usageComposantTemplateReducersGeneratedFunctions: ReducerTypes<UsageComposantTemplateState.IState, readonly ActionCreator[]>[] = [
  on(UsageComposantTemplateGeneratedActions.upsertOneUsageComposantTemplate, (state: UsageComposantTemplateState.IState) => setLoadingsState(state, true)),

  on(UsageComposantTemplateGeneratedActions.upsertManyUsageComposantTemplatesSuccess, (state: UsageComposantTemplateState.IState, { usageComposantTemplates }) =>
    UsageComposantTemplateState.adapter.upsertMany(usageComposantTemplates, setLoadingsState(state, false))
  ),
  on(UsageComposantTemplateGeneratedActions.deleteOneUsageComposantTemplateSuccess, (state: UsageComposantTemplateState.IState, { idUsageComposantTemplate }) =>
    UsageComposantTemplateState.adapter.removeOne(idUsageComposantTemplate, setLoadingsState(state, false))
  ),

  on(UsageComposantTemplateGeneratedActions.clearUsageComposantTemplates, () => UsageComposantTemplateState.initialState),

  on(
    UsageComposantTemplateGeneratedActions.addUsageSuccess,
    (state: UsageComposantTemplateState.IState, { idUsageComposantTemplate, idUsage }) => {
      if (!state.entities[idUsageComposantTemplate]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsageComposantTemplate]: {
            ...state.entities[idUsageComposantTemplate],
            usage: idUsage
          }
        }
      };
    }
  ),

  on(
    UsageComposantTemplateGeneratedActions.deleteManyUsageSuccess,
    (state: UsageComposantTemplateState.IState, { idUsages, idUsageComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsageComposantTemplates.reduce((entities, idUsageComposantTemplate) => {
            if (!state.entities[idUsageComposantTemplate]?.usage) {
              return entities;
            }
            entities[idUsageComposantTemplate] = {
              ...state.entities[idUsageComposantTemplate],
              usage: idUsages.some(
                (idUsage: number) => idUsage === state.entities[idUsageComposantTemplate]?.usage
              )
                ? undefined
                : state.entities[idUsageComposantTemplate]?.usage
            } as UsageComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<UsageComposantTemplateEntityState>)
        }
      };
    }
  ),

  on(
    UsageComposantTemplateGeneratedActions.addComposantTemplateSuccess,
    (state: UsageComposantTemplateState.IState, { idUsageComposantTemplate, idComposantTemplate }) => {
      if (!state.entities[idUsageComposantTemplate]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsageComposantTemplate]: {
            ...state.entities[idUsageComposantTemplate],
            composantTemplate: idComposantTemplate
          }
        }
      };
    }
  ),

  on(
    UsageComposantTemplateGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: UsageComposantTemplateState.IState, { idComposantTemplates, idUsageComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsageComposantTemplates.reduce((entities, idUsageComposantTemplate) => {
            if (!state.entities[idUsageComposantTemplate]?.composantTemplate) {
              return entities;
            }
            entities[idUsageComposantTemplate] = {
              ...state.entities[idUsageComposantTemplate],
              composantTemplate: idComposantTemplates.some(
                (idComposantTemplate: number) => idComposantTemplate === state.entities[idUsageComposantTemplate]?.composantTemplate
              )
                ? undefined
                : state.entities[idUsageComposantTemplate]?.composantTemplate
            } as UsageComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<UsageComposantTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UsageComposantTemplateState.IState,
  isLoading: boolean,
  isLoaded = true
): UsageComposantTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
