import { ImportStepEnum } from '@enums';

export interface ImportError {
  message: string;
  onglet: string;
}

export interface BackError {
  title: string;
  messages: string[];
}

export interface FileImportError {
  fileName: string;
  errors: BackError[];
  supplement?: string[];
}

export class CustomBackErrorWithStepClass extends Error {
  step: ImportStepEnum;

  constructor(message: string, step: ImportStepEnum) {
    super(message);

    this.step = step;
  }
}

export class CustomBackErrorClass extends Error {
  messages: string[];

  constructor(messages: string[]) {
    super('');

    this.messages = messages;
  }
}
