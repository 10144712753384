import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValeurProgIntervention, ValeurProgInterventionEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurProgInterventionState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurProgInterventionRelations: string[] = ['valeurs','progInterventions',];

export const { selectEntities, selectAll } = ValeurProgInterventionState.adapter.getSelectors();

export const selectValeurProgInterventionState = createFeatureSelector<ValeurProgInterventionState.IState>(ValeurProgInterventionState.valeurProgInterventionFeatureKey);

export const selectIsLoadedValeurProgIntervention = createSelector(
  selectValeurProgInterventionState,
  (state: ValeurProgInterventionState.IState) => state.isLoaded
);

export const selectIsLoadingValeurProgIntervention = createSelector(
  selectValeurProgInterventionState,
  (state: ValeurProgInterventionState.IState) => state.isLoading
);

export const selectIsReadyValeurProgIntervention = createSelector(
  selectValeurProgInterventionState,
  (state: ValeurProgInterventionState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeurProgIntervention = createSelector(
  selectValeurProgInterventionState,
  (state: ValeurProgInterventionState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeurProgInterventionsEntities = createSelector(selectValeurProgInterventionState, selectEntities);

export const selectValeurProgInterventionsArray = createSelector(selectValeurProgInterventionState, selectAll);

const valeurProgInterventionsInObject = (valeurProgInterventions: Dictionary<ValeurProgInterventionEntityState>) => ({ valeurProgInterventions })

const selectValeurProgInterventionsEntitiesDictionary = createSelector(selectValeurProgInterventionsEntities, valeurProgInterventionsInObject);

const selectAllValeurProgInterventionsObject = createSelector(selectValeurProgInterventionsEntities, valeurProgInterventions => {
  return hydrateAll({ valeurProgInterventions });
});

const selectOneValeurProgInterventionDictionary = (idValeurProgIntervention : number) =>
  createSelector(selectValeurProgInterventionsEntities, valeurProgInterventions => ({
    valeurProgInterventions: { [idValeurProgIntervention]: valeurProgInterventions[idValeurProgIntervention] }
  }));

const selectOneValeurProgInterventionDictionaryWithoutChild = (idValeurProgIntervention : number) =>
  createSelector(selectValeurProgInterventionsEntities, valeurProgInterventions => ({
    valeurProgIntervention: valeurProgInterventions[idValeurProgIntervention]
  }));

const selectAllValeurProgInterventionsSelectors: Dictionary<Selector> = {};
export function selectAllValeurProgInterventions(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ValeurProgIntervention>(
      schema,
      selectAllValeurProgInterventionsSelectors,
      selectValeurProgInterventionsEntitiesDictionary,
      getRelationSelectors,
      valeurProgInterventionRelations,
      hydrateAll,
      'valeurProgIntervention'
    );
  } else {
    return selectAllValeurProgInterventionsObject;
  }
}

export function selectAllValeurProgInterventionsDictionary(
  schema: SelectSchema = {},
  customKey = 'valeurProgInterventions'
): Selector {
  return createSelector(selectAllValeurProgInterventions(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurProgInterventionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurProgInterventions.length; i++) {
      res[customKey][result.valeurProgInterventions[i].idValeurProgIntervention] = result.valeurProgInterventions[i];
    }
    return res;
  });
}

export function selectOneValeurProgIntervention(
  schema: SelectSchema = {},
  idValeurProgIntervention: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurProgInterventionDictionary(idValeurProgIntervention)];
  selectors.push(...getRelationSelectors(schema, valeurProgInterventionRelations, 'valeurProgIntervention'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurProgInterventionDictionaryWithoutChild(idValeurProgIntervention);
  }
}

interface hydrateArgs {
  valeurProgInterventions: Dictionary<ValeurProgInterventionEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurProgInterventions: (ValeurProgIntervention | null)[] } {
  const {
    valeurProgInterventions,
    valeurs,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurProgInterventions: Object.keys(valeurProgInterventions).map(idValeurProgIntervention =>
      hydrate(
        valeurProgInterventions[idValeurProgIntervention] as ValeurProgInterventionEntityState,
        valeurs,
        progInterventions
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeurProgIntervention: ValeurProgInterventionEntityState | null } {
  const {
    valeurProgInterventions,
    valeurs,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeurProgIntervention = Object.values(valeurProgInterventions)[0];
  return {
    valeurProgIntervention: hydrate(
      valeurProgIntervention as ValeurProgInterventionEntityState,
      valeurs,
      progInterventions
    )
  };
}

function hydrate(
  valeurProgIntervention: ValeurProgInterventionEntityState,
  valeurEntities?: Dictionary<ValeurEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
): ValeurProgIntervention | null {
  if (!valeurProgIntervention) {
    return null;
  }

  const valeurProgInterventionHydrated: ValeurProgInterventionEntityState = { ...valeurProgIntervention };
  if (valeurEntities) {
    valeurProgInterventionHydrated.valeur = valeurEntities[valeurProgIntervention.valeur as number] as Valeur;
  } else {
    delete valeurProgInterventionHydrated.valeur;
  }
  if (progInterventionEntities) {
    valeurProgInterventionHydrated.progIntervention = progInterventionEntities[valeurProgIntervention.progIntervention as number] as ProgIntervention;
  } else {
    delete valeurProgInterventionHydrated.progIntervention;
  }

  return valeurProgInterventionHydrated as ValeurProgIntervention;
}
