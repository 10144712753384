import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { EspaceGeneratedActions } from '@get/store/actions';
import { EspaceState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { EspaceEntityState } from '@get/api-interfaces';

export const espaceReducersGeneratedFunctions: ReducerTypes<EspaceState.IState, readonly ActionCreator[]>[] = [
  on(EspaceGeneratedActions.upsertOneEspace, (state: EspaceState.IState) => setLoadingsState(state, true)),

  on(EspaceGeneratedActions.upsertManyEspacesSuccess, (state: EspaceState.IState, { espaces }) =>
    EspaceState.adapter.upsertMany(espaces, setLoadingsState(state, false))
  ),
  on(EspaceGeneratedActions.deleteOneEspaceSuccess, (state: EspaceState.IState, { idEspace }) =>
    EspaceState.adapter.removeOne(idEspace, setLoadingsState(state, false))
  ),

  on(EspaceGeneratedActions.clearEspaces, () => EspaceState.initialState),

  on(
    EspaceGeneratedActions.addManyComposantSuccess,
    (state: EspaceState.IState, { idEspace, idComposants }) => {
      if (!state.entities[idEspace]) {
        return state;
      }
      const composants = (state.entities[idEspace]?.composants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idEspace]: {
            ...state.entities[idEspace],
            composants: composants.concat(
              idComposants.filter(id => composants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    EspaceGeneratedActions.deleteManyComposantSuccess,
    (state: EspaceState.IState, { idComposants, idEspaces }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idEspaces.reduce((entities, idEspace) => {
            if (!state.entities[idEspace]?.composants) {
              return entities;
            }
            entities[idEspace] = {
              ...state.entities[idEspace],
              composants: (state.entities[idEspace]?.composants as number[])?.filter(
                (idComposant: number) => !idComposants.some((id: number) => id === idComposant)
              )
            } as EspaceEntityState;
            return entities;
          }, {} as Dictionary<EspaceEntityState>)
        }
      };
    }
  ),

  on(
    EspaceGeneratedActions.addPatrimoineSuccess,
    (state: EspaceState.IState, { idEspace, idPatrimoine }) => {
      if (!state.entities[idEspace]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idEspace]: {
            ...state.entities[idEspace],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    EspaceGeneratedActions.deleteManyPatrimoineSuccess,
    (state: EspaceState.IState, { idPatrimoines, idEspaces }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idEspaces.reduce((entities, idEspace) => {
            if (!state.entities[idEspace]?.patrimoine) {
              return entities;
            }
            entities[idEspace] = {
              ...state.entities[idEspace],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idEspace]?.patrimoine
              )
                ? undefined
                : state.entities[idEspace]?.patrimoine
            } as EspaceEntityState;
            return entities;
          }, {} as Dictionary<EspaceEntityState>)
        }
      };
    }
  ),

  on(
    EspaceGeneratedActions.addSocieteEspaceSuccess,
    (state: EspaceState.IState, { idEspace, idSocieteEspace }) => {
      if (!state.entities[idEspace]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idEspace]: {
            ...state.entities[idEspace],
            societeEspace: idSocieteEspace
          }
        }
      };
    }
  ),

  on(
    EspaceGeneratedActions.deleteManySocieteEspaceSuccess,
    (state: EspaceState.IState, { idSocieteEspaces, idEspaces }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idEspaces.reduce((entities, idEspace) => {
            if (!state.entities[idEspace]?.societeEspace) {
              return entities;
            }
            entities[idEspace] = {
              ...state.entities[idEspace],
              societeEspace: idSocieteEspaces.some(
                (idSocieteEspace: number) => idSocieteEspace === state.entities[idEspace]?.societeEspace
              )
                ? undefined
                : state.entities[idEspace]?.societeEspace
            } as EspaceEntityState;
            return entities;
          }, {} as Dictionary<EspaceEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: EspaceState.IState,
  isLoading: boolean,
  isLoaded = true
): EspaceState.IState {
  return { ...state, isLoaded, isLoading };
}
