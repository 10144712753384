import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { UserNewsletterRelationsIds } from '@get/store/ids-interfaces';

export const UserNewsletterGeneratedActions = createActionGroup({
  source: 'User Newsletter Generated',
  events: {
    'Upsert One User Newsletter': props<{ userNewsletter: Partial<UserNewsletter>; ids?: UserNewsletterRelationsIds; }>(),
    'Upsert Many User Newsletters': props<{ userNewsletters: Partial<UserNewsletter>[]; ids?: UserNewsletterRelationsIds; }>(),
    'Upsert Many User Newsletters Success': props<{ userNewsletters: UserNewsletterEntityState[] }>(),
    'Delete One User Newsletter Success': props<{ idUserNewsletter: number }>(),
    'Normalize Many User Newsletters After Upsert': props<{ userNewsletters: UserNewsletterEntityState[] }>(),
    'User Newsletters Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Newsletters': emptyProps(),
    'Add User Success': props<{ idUserNewsletter: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserNewsletters: number[] }>(),
    'Add Newsletter Success': props<{ idUserNewsletter: number; idNewsletter: number }>(),
    'Delete Many Newsletter Success': props<{ idNewsletters: number[]; idUserNewsletters: number[] }>()
  }
});
