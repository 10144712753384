import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { OrganisationRelationsIds } from '@get/store/ids-interfaces';

export const OrganisationGeneratedActions = createActionGroup({
  source: 'Organisation Generated',
  events: {
    'Get Many Organisations': props<{ params: any }>(),
    'Upsert One Organisation': props<{ organisation: Partial<Organisation>; ids?: OrganisationRelationsIds; }>(),
    'Upsert Many Organisations': props<{ organisations: Partial<Organisation>[]; ids?: OrganisationRelationsIds; }>(),
    'Upsert Many Organisations Success': props<{ organisations: OrganisationEntityState[] }>(),
    'Delete One Organisation Success': props<{ idOrganisation: number }>(),
    'Normalize Many Organisations After Upsert': props<{ organisations: OrganisationEntityState[] }>(),
    'Organisations Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organisations': emptyProps(),
    'Add Many Societe Success': props<{ idOrganisation: number; idSocietes: number[] }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idOrganisations: number[] }>(),
    'Add Many User Success': props<{ idOrganisation: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idOrganisations: number[] }>(),
    'Add Many Fichier Success': props<{ idOrganisation: number; idFichiers: number[] }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idOrganisations: number[] }>(),
    'Add Many Organisation Azure Login Success': props<{ idOrganisation: number; idOrganisationAzureLogins: number[] }>(),
    'Delete Many Organisation Azure Login Success': props<{ idOrganisationAzureLogins: number[]; idOrganisations: number[] }>(),
    'Add Many Organisation Newsletter Success': props<{ idOrganisation: number; idOrganisationNewsletters: number[] }>(),
    'Delete Many Organisation Newsletter Success': props<{ idOrganisationNewsletters: number[]; idOrganisations: number[] }>(),
    'Add Many Newsletter Success': props<{ idOrganisation: number; idNewsletters: number[] }>(),
    'Delete Many Newsletter Success': props<{ idNewsletters: number[]; idOrganisations: number[] }>()
  }
});
