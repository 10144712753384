import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgInterventionScenarioApiService } from '@get/store/api-services';
import { GeneratedProgInterventionScenarioEffects } from './prog-intervention-scenario-generated.effects';

@Injectable()
export class ProgInterventionScenarioEffects extends GeneratedProgInterventionScenarioEffects {
  constructor(actions$: Actions, progInterventionScenarioApiService: ProgInterventionScenarioApiService, store$: Store<AppState>) {
    super(actions$, progInterventionScenarioApiService, store$);
  }
}
