import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { UserPatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const UserPatrimoineGeneratedActions = createActionGroup({
  source: 'User Patrimoine Generated',
  events: {
    'Upsert One User Patrimoine': props<{ userPatrimoine: Partial<UserPatrimoine>; ids?: UserPatrimoineRelationsIds; }>(),
    'Upsert Many User Patrimoines': props<{ userPatrimoines: Partial<UserPatrimoine>[]; ids?: UserPatrimoineRelationsIds; }>(),
    'Upsert Many User Patrimoines Success': props<{ userPatrimoines: UserPatrimoineEntityState[] }>(),
    'Delete One User Patrimoine Success': props<{ idUserPatrimoine: number }>(),
    'Normalize Many User Patrimoines After Upsert': props<{ userPatrimoines: UserPatrimoineEntityState[] }>(),
    'User Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Patrimoines': emptyProps(),
    'Add User Success': props<{ idUserPatrimoine: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserPatrimoines: number[] }>(),
    'Add Patrimoine Success': props<{ idUserPatrimoine: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idUserPatrimoines: number[] }>()
  }
});
