export const MIN_INT = -2147483648;
export const MAX_INT = 2147483647;

export function sortNumberComparator(valueA: number, valueB: number): number {
  return +valueA - +valueB;
}

/**
 * Method to sort an array of primaryKeys with negatives going after all positives
 * @param a number
 * @param b number
 * @returns number
 * @example
 * a = 1, b = 3 returns -2
 * a = 3, b = 1 returns 2
 * a = 1, b = -1 returns -1
 * a = -1, b = 1 returns 1
 * a = -1, b = -5 returns -4
 * a = -5, b = -1 returns 4
 */
export function compareNumberCustomWithNegatives(a: number, b: number): number {
  if (a < 0 && b >= 0) {
    return 1;
  }
  if (b < 0 && a >= 0) {
    return -1;
  }
  if (b < 0 && a < 0) {
    return b - a;
  }
  return a - b;
}

export function formatNumberWithSpace(nb: number): string {
  return parseFloat((+nb)?.toFixed(3)).toLocaleString();
}

export function formatNumberToEuro(nb: number, kEuro = false, addSuffix = true): string {
  if (kEuro) {
    return formatNumberWithSpace(Math.round(nb / 1000)) + (addSuffix ? 'k€' : '');
  }
  return formatNumberWithSpace(nb) + (addSuffix ? '€' : '');
}

export function formatNumberWithBase(value: number, base: number): string {
  const left = value || value === 0 ? formatNumberWithSpace(value) : '';
  const right = base ? formatNumberWithSpace(base) : 'base';
  return left + ' / ' + right;
}

export function parseStringFloatOrLeave(value: string) {
  if (!value) {
    return value;
  }
  const parsed = parseFloat(value);
  return isNaN(parsed) ? value : parsed;
}

export function parseStringIntOrLeave(value: string) {
  if (!value) {
    return value;
  }
  const parsed = parseInt(value, 10);
  return isNaN(parsed) ? value : parsed;
}

export function isNumberDefined(nb: number): boolean {
  return !!nb || nb === 0;
}
