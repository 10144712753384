import { createEntityAdapter } from '@ngrx/entity';
import { ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgComposantGroupeEntityState>;

export const adapter = createEntityAdapter<ProgComposantGroupeEntityState>({
  selectId: o => o.idProgComposantGroupe
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progComposantGroupeFeatureKey = 'progComposantGroupe';
