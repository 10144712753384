import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { UsageRelationsIds } from '@get/store/ids-interfaces';

export const UsageGeneratedActions = createActionGroup({
  source: 'Usage Generated',
  events: {
    'Get Many Usages': props<{ params: any }>(),
    'Upsert One Usage': props<{ usage: Partial<Usage>; ids?: UsageRelationsIds; }>(),
    'Upsert Many Usages': props<{ usages: Partial<Usage>[]; ids?: UsageRelationsIds; }>(),
    'Upsert Many Usages Success': props<{ usages: UsageEntityState[] }>(),
    'Delete One Usage': props<{ idUsage: number }>(),
    'Delete One Usage Success': props<{ idUsage: number }>(),
    'Normalize Many Usages After Upsert': props<{ usages: UsageEntityState[] }>(),
    'Usages Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Usages': emptyProps(),
    'Add Many Usage Composant Template Success': props<{ idUsage: number; idUsageComposantTemplates: number[] }>(),
    'Delete Many Usage Composant Template Success': props<{ idUsageComposantTemplates: number[]; idUsages: number[] }>(),
    'Add Many Usage Caracteristique Template Success': props<{ idUsage: number; idUsageCaracteristiqueTemplates: number[] }>(),
    'Delete Many Usage Caracteristique Template Success': props<{ idUsageCaracteristiqueTemplates: number[]; idUsages: number[] }>(),
    'Add Many Composant Template Success': props<{ idUsage: number; idComposantTemplates: number[] }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idUsages: number[] }>(),
    'Add Many Caracteristique Template Success': props<{ idUsage: number; idCaracteristiqueTemplates: number[] }>(),
    'Delete Many Caracteristique Template Success': props<{ idCaracteristiqueTemplates: number[]; idUsages: number[] }>()
  }
});
