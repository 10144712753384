import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BoardStateApiService } from '@get/store/api-services';
import { GeneratedBoardStateEffects } from './board-state-generated.effects';

@Injectable()
export class BoardStateEffects extends GeneratedBoardStateEffects {
  constructor(actions$: Actions, boardStateApiService: BoardStateApiService, store$: Store<AppState>) {
    super(actions$, boardStateApiService, store$);
  }
}
