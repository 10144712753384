import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteComposantRattachementApiService } from '@get/store/api-services';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteComposantRattachement } from '@get/store/configs/normalization';

export class GeneratedSocieteComposantRattachementEffects {
  constructor(
    protected actions$: Actions,
    protected societeComposantRattachementApiService: SocieteComposantRattachementApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteComposantRattachementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert),
      concatMap(({ societeComposantRattachements }) => {
        const actions: Action[] = getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachements, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteComposantRattachement] Normalization After Upsert Success')];
      })
    );
  });
}
