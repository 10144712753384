import { CaracteristiqueTypeEnum, ComponentTypeEnum, InfoAdditionnelleEnum } from '@enums';
import {
  Composant,
  Espace,
  Patrimoine,
  PatrimoineWithChildren,
  SocieteComposantRattachement,
  SocietePatrimoineHierarchie,
  Valeur
} from '@get/api-interfaces';

export function getComposantsFromEspaces(espaces: Espace[]): Composant[] {
  return espaces?.map(espace => espace.composants || []).flat() || [];
}

export function getAllComposantsFromPatrimoine(patrimoine: PatrimoineWithChildren): Composant[] {
  return getComposantsFromEspaces(patrimoine?.espaces).concat(
    patrimoine?.children?.map(el => getAllComposantsFromPatrimoine(el)).flat() ?? []
  );
}

// Est-ce qu'un composant donné peut être rattaché à un espace donné (si espace "poubelle" alors true sinon dépend du paramétrage)
export function canEspaceHaveComposant(espace: Espace, componentType: ComponentTypeEnum, id: number): boolean {
  return (
    !espace?.societeEspace ||
    !!espace?.societeEspace?.societeEspaceFamille?.societeComposantRattachements?.some(societeComposantRattachement =>
      componentType === ComponentTypeEnum.societeComposant
        ? societeComposantRattachement?.societeComposant?.idSocieteComposant === id
        : societeComposantRattachement?.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id
    )
  );
}

// Est-ce qu'un espace donné est configuré sur le patrimoine pour pouvoir avoir un composant donné
export function canEspaceOnPatrimoineHaveComposant(
  espace: Espace,
  element: Patrimoine,
  componentType: ComponentTypeEnum,
  id: number
): boolean {
  const rattachements = composantRattachementForPatrimoine(element, componentType, id);
  return (
    !!rattachements?.length &&
    rattachements.some(
      rattachement => rattachement.idSocieteEspaceFamille === (espace.societeEspace?.idSocieteEspaceFamille || null)
    )
  );
}

export function canPatrimoineAndChildrenHaveComposant(
  element: PatrimoineWithChildren,
  componentType: ComponentTypeEnum,
  id: number
): boolean {
  return (
    canPatrimoineHaveComposant(element, componentType, id) ||
    element?.children?.some(child => canPatrimoineAndChildrenHaveComposant(child, componentType, id))
  );
}

export function composantRattachementForSocietePatrimoineHierarchie(
  societePatrimoineHierarchie: SocietePatrimoineHierarchie,
  componentType: ComponentTypeEnum,
  id: number
): SocieteComposantRattachement[] {
  return (
    societePatrimoineHierarchie?.societeComposantRattachements?.filter(societeComposantRattachement =>
      componentType === ComponentTypeEnum.societeComposant
        ? societeComposantRattachement?.societeComposant?.idSocieteComposant === id
        : societeComposantRattachement?.societeComposant?.societeComposantFamille?.idSocieteComposantFamille === id
    ) || []
  );
}

export function composantRattachementForPatrimoine(
  element: Patrimoine,
  componentType: ComponentTypeEnum,
  id: number
): SocieteComposantRattachement[] {
  return composantRattachementForSocietePatrimoineHierarchie(element?.societePatrimoineHierarchie, componentType, id);
}

export function canPatrimoineHaveComposant(element: Patrimoine, componentType: ComponentTypeEnum, id: number): boolean {
  return !!composantRattachementForPatrimoine(element, componentType, id)?.length;
}

export function extractNumberFromComposant(composant: Composant): number {
  let nbToAdd = -1;
  for (let idx = 0; idx < composant.valeurs?.length; idx++) {
    const val = composant.valeurs[idx];
    if (
      val.societeCaracteristique?.type === CaracteristiqueTypeEnum.integer &&
      val.societeCaracteristique?.infoAdditionnelle === InfoAdditionnelleEnum.decompte &&
      !isNaN(val.valeurInteger) &&
      // Obligatoire car null & false ne correspondent pas à NaN donc on pourrait les accepter alors qu'il ne faut pas
      (val.valeurInteger > 0 || val.valeurInteger === 0)
    ) {
      nbToAdd = nbToAdd < 0 ? val.valeurInteger : nbToAdd + val.valeurInteger;
    }
  }
  // Si il n'y a pas de valeur de type decompte ou qu'aucune n'est remplie, on retourne 1
  return nbToAdd < 0 ? 1 : nbToAdd;
}

export function getValeursToUpdateForExistingComposants(
  composant: Composant,
  newValeurs: { [k: string]: any },
  valeurs: Valeur[]
): Valeur[] {
  return (
    composant?.valeurs?.reduce((acc, valeur) => {
      // entry permet juste de check si la caracteristique doit être mise à jour
      const entry = newValeurs?.[valeur.societeCaracteristique.libelleTechnique];

      if (!entry) {
        return acc;
      }

      // TODO: Réopti car ceci est un fix d'un ancien bug mais le find peut peut-être être évité
      const actualValeur = valeurs?.find(val => val.idSocieteCaracteristique === valeur.idSocieteCaracteristique);
      if (actualValeur) {
        acc.push({ ...actualValeur, idValeur: valeur.idValeur });
      }
      return acc;
    }, [] as Valeur[]) || []
  );
}
