import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgEtatTechniqueApiService } from '@get/store/api-services';
import { GeneratedProgEtatTechniqueEffects } from './prog-etat-technique-generated.effects';

@Injectable()
export class ProgEtatTechniqueEffects extends GeneratedProgEtatTechniqueEffects {
  constructor(actions$: Actions, progEtatTechniqueApiService: ProgEtatTechniqueApiService, store$: Store<AppState>) {
    super(actions$, progEtatTechniqueApiService, store$);
  }
}
