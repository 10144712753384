import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgBiblioInterventionEntityState } from '@get/api-interfaces';

export const progBiblioInterventionReducersGeneratedFunctions: ReducerTypes<ProgBiblioInterventionState.IState, readonly ActionCreator[]>[] = [
  on(ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention, (state: ProgBiblioInterventionState.IState) => setLoadingsState(state, true)),

  on(ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventionsSuccess, (state: ProgBiblioInterventionState.IState, { progBiblioInterventions }) =>
    ProgBiblioInterventionState.adapter.upsertMany(progBiblioInterventions, setLoadingsState(state, false))
  ),
  on(ProgBiblioInterventionGeneratedActions.deleteOneProgBiblioIntervention, (state: ProgBiblioInterventionState.IState) => setLoadingsState(state, true)),
  on(ProgBiblioInterventionGeneratedActions.deleteOneProgBiblioInterventionSuccess, (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention }) =>
    ProgBiblioInterventionState.adapter.removeOne(idProgBiblioIntervention, setLoadingsState(state, false))
  ),

  on(ProgBiblioInterventionGeneratedActions.clearProgBiblioInterventions, () => ProgBiblioInterventionState.initialState),

  on(
    ProgBiblioInterventionGeneratedActions.addManyProgBiblioInterventionScenarioSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgBiblioInterventionScenarios }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      const progBiblioInterventionScenarios = (state.entities[idProgBiblioIntervention]?.progBiblioInterventionScenarios as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progBiblioInterventionScenarios: progBiblioInterventionScenarios.concat(
              idProgBiblioInterventionScenarios.filter(id => progBiblioInterventionScenarios.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgBiblioInterventionScenarioSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioInterventionScenarios, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progBiblioInterventionScenarios) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progBiblioInterventionScenarios: (state.entities[idProgBiblioIntervention]?.progBiblioInterventionScenarios as number[])?.filter(
                (idProgBiblioInterventionScenario: number) => !idProgBiblioInterventionScenarios.some((id: number) => id === idProgBiblioInterventionScenario)
              )
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addManyProgScenarioSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgScenarios }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      const progScenarios = (state.entities[idProgBiblioIntervention]?.progScenarios as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progScenarios: progScenarios.concat(
              idProgScenarios.filter(id => progScenarios.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgScenarioSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgScenarios, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progScenarios) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progScenarios: (state.entities[idProgBiblioIntervention]?.progScenarios as number[])?.filter(
                (idProgScenario: number) => !idProgScenarios.some((id: number) => id === idProgScenario)
              )
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addSocieteSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idSociete }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManySocieteSuccess,
    (state: ProgBiblioInterventionState.IState, { idSocietes, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.societe) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgBiblioIntervention]?.societe
              )
                ? undefined
                : state.entities[idProgBiblioIntervention]?.societe
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addProgComposantSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgComposant }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progComposant: idProgComposant
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgComposantSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgComposants, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progComposant) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progComposant: idProgComposants.some(
                (idProgComposant: number) => idProgComposant === state.entities[idProgBiblioIntervention]?.progComposant
              )
                ? undefined
                : state.entities[idProgBiblioIntervention]?.progComposant
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addProgBudgetSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgBudget }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progBudget: idProgBudget
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgBudgetSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBudgets, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progBudget) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progBudget: idProgBudgets.some(
                (idProgBudget: number) => idProgBudget === state.entities[idProgBiblioIntervention]?.progBudget
              )
                ? undefined
                : state.entities[idProgBiblioIntervention]?.progBudget
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addProgUniteSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgUnite }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progUnite: idProgUnite
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgUniteSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgUnites, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progUnite) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progUnite: idProgUnites.some(
                (idProgUnite: number) => idProgUnite === state.entities[idProgBiblioIntervention]?.progUnite
              )
                ? undefined
                : state.entities[idProgBiblioIntervention]?.progUnite
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.addProgInterventionFamilleSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgBiblioIntervention, idProgInterventionFamille }) => {
      if (!state.entities[idProgBiblioIntervention]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgBiblioIntervention]: {
            ...state.entities[idProgBiblioIntervention],
            progInterventionFamille: idProgInterventionFamille
          }
        }
      };
    }
  ),

  on(
    ProgBiblioInterventionGeneratedActions.deleteManyProgInterventionFamilleSuccess,
    (state: ProgBiblioInterventionState.IState, { idProgInterventionFamilles, idProgBiblioInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgBiblioInterventions.reduce((entities, idProgBiblioIntervention) => {
            if (!state.entities[idProgBiblioIntervention]?.progInterventionFamille) {
              return entities;
            }
            entities[idProgBiblioIntervention] = {
              ...state.entities[idProgBiblioIntervention],
              progInterventionFamille: idProgInterventionFamilles.some(
                (idProgInterventionFamille: number) => idProgInterventionFamille === state.entities[idProgBiblioIntervention]?.progInterventionFamille
              )
                ? undefined
                : state.entities[idProgBiblioIntervention]?.progInterventionFamille
            } as ProgBiblioInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgBiblioInterventionEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgBiblioInterventionState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgBiblioInterventionState.IState {
  return { ...state, isLoaded, isLoading };
}
