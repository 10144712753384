import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteCaracteristiqueEntityState } from '@get/api-interfaces';

export const societeCaracteristiqueReducersGeneratedFunctions: ReducerTypes<SocieteCaracteristiqueState.IState, readonly ActionCreator[]>[] = [
  on(SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique, (state: SocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),

  on(SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiquesSuccess, (state: SocieteCaracteristiqueState.IState, { societeCaracteristiques }) =>
    SocieteCaracteristiqueState.adapter.upsertMany(societeCaracteristiques, setLoadingsState(state, false))
  ),
  on(SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristique, (state: SocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),
  on(SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristiqueSuccess, (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique }) =>
    SocieteCaracteristiqueState.adapter.removeOne(idSocieteCaracteristique, setLoadingsState(state, false))
  ),

  on(SocieteCaracteristiqueGeneratedActions.clearSocieteCaracteristiques, () => SocieteCaracteristiqueState.initialState),

  on(
    SocieteCaracteristiqueGeneratedActions.addManySocieteCaracteristiqueChoixSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSocieteCaracteristiqueChoices }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const societeCaracteristiqueChoices = (state.entities[idSocieteCaracteristique]?.societeCaracteristiqueChoices as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societeCaracteristiqueChoices: societeCaracteristiqueChoices.concat(
              idSocieteCaracteristiqueChoices.filter(id => societeCaracteristiqueChoices.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristiqueChoices, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societeCaracteristiqueChoices) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societeCaracteristiqueChoices: (state.entities[idSocieteCaracteristique]?.societeCaracteristiqueChoices as number[])?.filter(
                (idSocieteCaracteristiqueChoix: number) => !idSocieteCaracteristiqueChoices.some((id: number) => id === idSocieteCaracteristiqueChoix)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addManyValeurSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idValeurs }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const valeurs = (state.entities[idSocieteCaracteristique]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManyValeurSuccess,
    (state: SocieteCaracteristiqueState.IState, { idValeurs, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.valeurs) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              valeurs: (state.entities[idSocieteCaracteristique]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idCampagneSocieteCaracteristiques }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const campagneSocieteCaracteristiques = (state.entities[idSocieteCaracteristique]?.campagneSocieteCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            campagneSocieteCaracteristiques: campagneSocieteCaracteristiques.concat(
              idCampagneSocieteCaracteristiques.filter(id => campagneSocieteCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManyCampagneSocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristiques, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.campagneSocieteCaracteristiques) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              campagneSocieteCaracteristiques: (state.entities[idSocieteCaracteristique]?.campagneSocieteCaracteristiques as number[])?.filter(
                (idCampagneSocieteCaracteristique: number) => !idCampagneSocieteCaracteristiques.some((id: number) => id === idCampagneSocieteCaracteristique)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addManyCampagneSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idCampagnes }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const campagnes = (state.entities[idSocieteCaracteristique]?.campagnes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            campagnes: campagnes.concat(
              idCampagnes.filter(id => campagnes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManyCampagneSuccess,
    (state: SocieteCaracteristiqueState.IState, { idCampagnes, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.campagnes) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              campagnes: (state.entities[idSocieteCaracteristique]?.campagnes as number[])?.filter(
                (idCampagne: number) => !idCampagnes.some((id: number) => id === idCampagne)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSocieteConfigAnalyseSyntheseSocieteCaracteristiques }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const societeConfigAnalyseSyntheseSocieteCaracteristiques = (state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societeConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSyntheseSocieteCaracteristiques.concat(
              idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.filter(id => societeConfigAnalyseSyntheseSocieteCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheseSocieteCaracteristiques, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societeConfigAnalyseSyntheseSocieteCaracteristiques: (state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[])?.filter(
                (idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number) => !idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.some((id: number) => id === idSocieteConfigAnalyseSyntheseSocieteCaracteristique)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSocieteConfigAnalyseSyntheses }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      const societeConfigAnalyseSyntheses = (state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheses as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societeConfigAnalyseSyntheses: societeConfigAnalyseSyntheses.concat(
              idSocieteConfigAnalyseSyntheses.filter(id => societeConfigAnalyseSyntheses.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteConfigAnalyseSyntheses, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheses) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societeConfigAnalyseSyntheses: (state.entities[idSocieteCaracteristique]?.societeConfigAnalyseSyntheses as number[])?.filter(
                (idSocieteConfigAnalyseSynthese: number) => !idSocieteConfigAnalyseSyntheses.some((id: number) => id === idSocieteConfigAnalyseSynthese)
              )
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addSocieteSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSociete }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocietes, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societe) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteCaracteristique]?.societe
              )
                ? undefined
                : state.entities[idSocieteCaracteristique]?.societe
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addCaracteristiqueTemplateSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idCaracteristiqueTemplate }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            caracteristiqueTemplate: idCaracteristiqueTemplate
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: SocieteCaracteristiqueState.IState, { idCaracteristiqueTemplates, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.caracteristiqueTemplate) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              caracteristiqueTemplate: idCaracteristiqueTemplates.some(
                (idCaracteristiqueTemplate: number) => idCaracteristiqueTemplate === state.entities[idSocieteCaracteristique]?.caracteristiqueTemplate
              )
                ? undefined
                : state.entities[idSocieteCaracteristique]?.caracteristiqueTemplate
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addSocieteComposantSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSocieteComposant }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societeComposant: idSocieteComposant
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocieteComposantSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteComposants, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societeComposant) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societeComposant: idSocieteComposants.some(
                (idSocieteComposant: number) => idSocieteComposant === state.entities[idSocieteCaracteristique]?.societeComposant
              )
                ? undefined
                : state.entities[idSocieteCaracteristique]?.societeComposant
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.addSocietePatrimoineHierarchieSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocieteCaracteristique, idSocietePatrimoineHierarchie }) => {
      if (!state.entities[idSocieteCaracteristique]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristique]: {
            ...state.entities[idSocieteCaracteristique],
            societePatrimoineHierarchie: idSocietePatrimoineHierarchie
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: SocieteCaracteristiqueState.IState, { idSocietePatrimoineHierarchies, idSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiques.reduce((entities, idSocieteCaracteristique) => {
            if (!state.entities[idSocieteCaracteristique]?.societePatrimoineHierarchie) {
              return entities;
            }
            entities[idSocieteCaracteristique] = {
              ...state.entities[idSocieteCaracteristique],
              societePatrimoineHierarchie: idSocietePatrimoineHierarchies.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idSocieteCaracteristique]?.societePatrimoineHierarchie
              )
                ? undefined
                : state.entities[idSocieteCaracteristique]?.societePatrimoineHierarchie
            } as SocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteCaracteristiqueState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteCaracteristiqueState.IState {
  return { ...state, isLoaded, isLoading };
}
