import { createEntityAdapter } from '@ngrx/entity';
import { SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteConfigAnalyseSyntheseEntityState>;

export const adapter = createEntityAdapter<SocieteConfigAnalyseSyntheseEntityState>({
  selectId: o => o.idSocieteConfigAnalyseSynthese
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const societeConfigAnalyseSyntheseFeatureKey = 'societeConfigAnalyseSynthese';
