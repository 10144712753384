import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Organisation } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { OrganisationRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { OrganisationSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedOrganisationService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganisationSelectors.selectIsLoadedOrganisation));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganisationSelectors.selectIsLoadingOrganisation));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganisationSelectors.selectIsReadyAndLoadedOrganisation as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganisations(schema: SelectSchema = {}): Observable<Organisation[]> {
    return this.store$.pipe(select(OrganisationSelectors.selectAllOrganisations(schema))).pipe(
      switchMap(({ organisations }: { organisations: Organisation[] }) => {
        return this.getReady(schema).pipe(map(() => organisations));
      })
    );
  }

  public selectOneOrganisation(
    idOrganisation: number,
    schema: SelectSchema = {}
  ): Observable<Organisation> {
    return this.store$
      .pipe(select(OrganisationSelectors.selectOneOrganisation(schema, idOrganisation)))
      .pipe(
        switchMap(({ organisation }: { organisation: Organisation }) => {
          return this.getReady(schema).pipe(map(() => organisation));
        })
      );
  }

  public getManyOrganisations(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Organisation[]> {
    this.store$.dispatch(OrganisationGeneratedActions.getManyOrganisations({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert,
        OrganisationGeneratedActions.organisationsFailure
      );
    }
  }

  public upsertOneOrganisation(
    organisation: Partial<Organisation>,
    ids: OrganisationRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Organisation> {
    this.store$.dispatch(OrganisationGeneratedActions.upsertOneOrganisation({ organisation, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert,
        OrganisationGeneratedActions.organisationsFailure,
        true
      );
    }
  }
}
