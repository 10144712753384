import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgInterventionFamilleState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progInterventionFamilleRelations: string[] = ['progBiblioInterventions','progInterventionSaisies','progInterventionCalculs','societes',];

export const { selectEntities, selectAll } = ProgInterventionFamilleState.adapter.getSelectors();

export const selectProgInterventionFamilleState = createFeatureSelector<ProgInterventionFamilleState.IState>(ProgInterventionFamilleState.progInterventionFamilleFeatureKey);

export const selectIsLoadedProgInterventionFamille = createSelector(
  selectProgInterventionFamilleState,
  (state: ProgInterventionFamilleState.IState) => state.isLoaded
);

export const selectIsLoadingProgInterventionFamille = createSelector(
  selectProgInterventionFamilleState,
  (state: ProgInterventionFamilleState.IState) => state.isLoading
);

export const selectIsReadyProgInterventionFamille = createSelector(
  selectProgInterventionFamilleState,
  (state: ProgInterventionFamilleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgInterventionFamille = createSelector(
  selectProgInterventionFamilleState,
  (state: ProgInterventionFamilleState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgInterventionFamillesEntities = createSelector(selectProgInterventionFamilleState, selectEntities);

export const selectProgInterventionFamillesArray = createSelector(selectProgInterventionFamilleState, selectAll);

const progInterventionFamillesInObject = (progInterventionFamilles: Dictionary<ProgInterventionFamilleEntityState>) => ({ progInterventionFamilles })

const selectProgInterventionFamillesEntitiesDictionary = createSelector(selectProgInterventionFamillesEntities, progInterventionFamillesInObject);

const selectAllProgInterventionFamillesObject = createSelector(selectProgInterventionFamillesEntities, progInterventionFamilles => {
  return hydrateAll({ progInterventionFamilles });
});

const selectOneProgInterventionFamilleDictionary = (idProgInterventionFamille : number) =>
  createSelector(selectProgInterventionFamillesEntities, progInterventionFamilles => ({
    progInterventionFamilles: { [idProgInterventionFamille]: progInterventionFamilles[idProgInterventionFamille] }
  }));

const selectOneProgInterventionFamilleDictionaryWithoutChild = (idProgInterventionFamille : number) =>
  createSelector(selectProgInterventionFamillesEntities, progInterventionFamilles => ({
    progInterventionFamille: progInterventionFamilles[idProgInterventionFamille]
  }));

const selectAllProgInterventionFamillesSelectors: Dictionary<Selector> = {};
export function selectAllProgInterventionFamilles(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgInterventionFamille>(
      schema,
      selectAllProgInterventionFamillesSelectors,
      selectProgInterventionFamillesEntitiesDictionary,
      getRelationSelectors,
      progInterventionFamilleRelations,
      hydrateAll,
      'progInterventionFamille'
    );
  } else {
    return selectAllProgInterventionFamillesObject;
  }
}

export function selectAllProgInterventionFamillesDictionary(
  schema: SelectSchema = {},
  customKey = 'progInterventionFamilles'
): Selector {
  return createSelector(selectAllProgInterventionFamilles(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgInterventionFamilleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progInterventionFamilles.length; i++) {
      res[customKey][result.progInterventionFamilles[i].idProgInterventionFamille] = result.progInterventionFamilles[i];
    }
    return res;
  });
}

export function selectOneProgInterventionFamille(
  schema: SelectSchema = {},
  idProgInterventionFamille: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgInterventionFamilleDictionary(idProgInterventionFamille)];
  selectors.push(...getRelationSelectors(schema, progInterventionFamilleRelations, 'progInterventionFamille'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgInterventionFamilleDictionaryWithoutChild(idProgInterventionFamille);
  }
}

interface hydrateArgs {
  progInterventionFamilles: Dictionary<ProgInterventionFamilleEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progInterventionSaisies?: Dictionary<ProgInterventionEntityState>;
  progInterventionCalculs?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progInterventionFamilles: (ProgInterventionFamille | null)[] } {
  const {
    progInterventionFamilles,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progInterventionFamilles: Object.keys(progInterventionFamilles).map(idProgInterventionFamille =>
      hydrate(
        progInterventionFamilles[idProgInterventionFamille] as ProgInterventionFamilleEntityState,
        societes,
        progBiblioInterventions,
        progInterventionSaisies,
        progInterventionCalculs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progInterventionFamille: ProgInterventionFamilleEntityState | null } {
  const {
    progInterventionFamilles,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progInterventionFamille = Object.values(progInterventionFamilles)[0];
  return {
    progInterventionFamille: hydrate(
      progInterventionFamille as ProgInterventionFamilleEntityState,
      societes,
      progBiblioInterventions,
      progInterventionSaisies,
      progInterventionCalculs
    )
  };
}

function hydrate(
  progInterventionFamille: ProgInterventionFamilleEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progInterventionSaisiesEntities?: Dictionary<ProgInterventionEntityState>,
  progInterventionCalculsEntities?: Dictionary<ProgInterventionEntityState>,
): ProgInterventionFamille | null {
  if (!progInterventionFamille) {
    return null;
  }

  const progInterventionFamilleHydrated: ProgInterventionFamilleEntityState = { ...progInterventionFamille };
  if (societeEntities) {
    progInterventionFamilleHydrated.societe = societeEntities[progInterventionFamille.societe as number] as Societe;
  } else {
    delete progInterventionFamilleHydrated.societe;
  }

  if (progBiblioInterventionEntities) {
    progInterventionFamilleHydrated.progBiblioInterventions = ((progInterventionFamilleHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete progInterventionFamilleHydrated.progBiblioInterventions;
  }

  if (progInterventionSaisiesEntities) {
    progInterventionFamilleHydrated.progInterventionSaisies = ((progInterventionFamilleHydrated.progInterventionSaisies as number[]) || []).map(
      id => progInterventionSaisiesEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progInterventionFamilleHydrated.progInterventionSaisies;
  }

  if (progInterventionCalculsEntities) {
    progInterventionFamilleHydrated.progInterventionCalculs = ((progInterventionFamilleHydrated.progInterventionCalculs as number[]) || []).map(
      id => progInterventionCalculsEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progInterventionFamilleHydrated.progInterventionCalculs;
  }

  return progInterventionFamilleHydrated as ProgInterventionFamille;
}
