import { createEntityAdapter } from '@ngrx/entity';
import { ComposantGroupeEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ComposantGroupeEntityState>;

export const adapter = createEntityAdapter<ComposantGroupeEntityState>({
  selectId: o => o.idComposantGroupe
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const composantGroupeFeatureKey = 'composantGroupe';
