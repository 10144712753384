import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgInterventionApiService } from '@get/store/api-services';
import { ProgInterventionGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgIntervention } from '@get/store/configs/normalization';
import { ProgInterventionRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';

export function getDefaultAddProgInterventionActions(progIntervention: ProgInterventionEntityState, ids?: ProgInterventionRelationsIds): Action[] {
  const actions: Action[] = [ProgInterventionGeneratedActions.normalizeManyProgInterventionsAfterUpsert({ progInterventions: [progIntervention] })];

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idProgIntervention: progIntervention.idProgIntervention,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        ProgInterventionGeneratedActions.addManyPatrimoineSuccess({
          idProgIntervention: progIntervention.idProgIntervention,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idProgIntervention: progIntervention.idProgIntervention,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        ProgInterventionGeneratedActions.addManyPatrimoineSuccess({
          idProgIntervention: progIntervention.idProgIntervention,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgInterventionActions(progIntervention: ProgInterventionEntityState): Action[] {
  const actions: Action[] = [ProgInterventionGeneratedActions.deleteOneProgInterventionSuccess({ idProgIntervention: progIntervention.idProgIntervention })];

  if (progIntervention.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyProgInterventionSuccess({
        idProgInterventions: [progIntervention.idProgIntervention],
        idPatrimoines: progIntervention.patrimoines as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgInterventionEffects {
  constructor(
    protected actions$: Actions,
    protected progInterventionApiService: ProgInterventionApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyProgInterventionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgInterventionGeneratedActions.normalizeManyProgInterventionsAfterUpsert),
      concatMap(({ progInterventions }) => {
        const actions: Action[] = getActionsToNormalizeProgIntervention(progInterventions, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgIntervention] Normalization After Upsert Success')];
      })
    );
  });
}
