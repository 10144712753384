import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurPatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurPatrimoineRelations: string[] = ['valeurs','patrimoines',];

export const { selectEntities, selectAll } = ValeurPatrimoineState.adapter.getSelectors();

export const selectValeurPatrimoineState = createFeatureSelector<ValeurPatrimoineState.IState>(ValeurPatrimoineState.valeurPatrimoineFeatureKey);

export const selectIsLoadedValeurPatrimoine = createSelector(
  selectValeurPatrimoineState,
  (state: ValeurPatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingValeurPatrimoine = createSelector(
  selectValeurPatrimoineState,
  (state: ValeurPatrimoineState.IState) => state.isLoading
);

export const selectIsReadyValeurPatrimoine = createSelector(
  selectValeurPatrimoineState,
  (state: ValeurPatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeurPatrimoine = createSelector(
  selectValeurPatrimoineState,
  (state: ValeurPatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeurPatrimoinesEntities = createSelector(selectValeurPatrimoineState, selectEntities);

export const selectValeurPatrimoinesArray = createSelector(selectValeurPatrimoineState, selectAll);

const valeurPatrimoinesInObject = (valeurPatrimoines: Dictionary<ValeurPatrimoineEntityState>) => ({ valeurPatrimoines })

const selectValeurPatrimoinesEntitiesDictionary = createSelector(selectValeurPatrimoinesEntities, valeurPatrimoinesInObject);

const selectAllValeurPatrimoinesObject = createSelector(selectValeurPatrimoinesEntities, valeurPatrimoines => {
  return hydrateAll({ valeurPatrimoines });
});

const selectOneValeurPatrimoineDictionary = (idValeurPatrimoine : number) =>
  createSelector(selectValeurPatrimoinesEntities, valeurPatrimoines => ({
    valeurPatrimoines: { [idValeurPatrimoine]: valeurPatrimoines[idValeurPatrimoine] }
  }));

const selectOneValeurPatrimoineDictionaryWithoutChild = (idValeurPatrimoine : number) =>
  createSelector(selectValeurPatrimoinesEntities, valeurPatrimoines => ({
    valeurPatrimoine: valeurPatrimoines[idValeurPatrimoine]
  }));

const selectAllValeurPatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllValeurPatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ValeurPatrimoine>(
      schema,
      selectAllValeurPatrimoinesSelectors,
      selectValeurPatrimoinesEntitiesDictionary,
      getRelationSelectors,
      valeurPatrimoineRelations,
      hydrateAll,
      'valeurPatrimoine'
    );
  } else {
    return selectAllValeurPatrimoinesObject;
  }
}

export function selectAllValeurPatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey = 'valeurPatrimoines'
): Selector {
  return createSelector(selectAllValeurPatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurPatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurPatrimoines.length; i++) {
      res[customKey][result.valeurPatrimoines[i].idValeurPatrimoine] = result.valeurPatrimoines[i];
    }
    return res;
  });
}

export function selectOneValeurPatrimoine(
  schema: SelectSchema = {},
  idValeurPatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurPatrimoineDictionary(idValeurPatrimoine)];
  selectors.push(...getRelationSelectors(schema, valeurPatrimoineRelations, 'valeurPatrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurPatrimoineDictionaryWithoutChild(idValeurPatrimoine);
  }
}

interface hydrateArgs {
  valeurPatrimoines: Dictionary<ValeurPatrimoineEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurPatrimoines: (ValeurPatrimoine | null)[] } {
  const {
    valeurPatrimoines,
    valeurs,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurPatrimoines: Object.keys(valeurPatrimoines).map(idValeurPatrimoine =>
      hydrate(
        valeurPatrimoines[idValeurPatrimoine] as ValeurPatrimoineEntityState,
        valeurs,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeurPatrimoine: ValeurPatrimoineEntityState | null } {
  const {
    valeurPatrimoines,
    valeurs,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeurPatrimoine = Object.values(valeurPatrimoines)[0];
  return {
    valeurPatrimoine: hydrate(
      valeurPatrimoine as ValeurPatrimoineEntityState,
      valeurs,
      patrimoines
    )
  };
}

function hydrate(
  valeurPatrimoine: ValeurPatrimoineEntityState,
  valeurEntities?: Dictionary<ValeurEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): ValeurPatrimoine | null {
  if (!valeurPatrimoine) {
    return null;
  }

  const valeurPatrimoineHydrated: ValeurPatrimoineEntityState = { ...valeurPatrimoine };
  if (valeurEntities) {
    valeurPatrimoineHydrated.valeur = valeurEntities[valeurPatrimoine.valeur as number] as Valeur;
  } else {
    delete valeurPatrimoineHydrated.valeur;
  }
  if (patrimoineEntities) {
    valeurPatrimoineHydrated.patrimoine = patrimoineEntities[valeurPatrimoine.patrimoine as number] as Patrimoine;
  } else {
    delete valeurPatrimoineHydrated.patrimoine;
  }

  return valeurPatrimoineHydrated as ValeurPatrimoine;
}
