import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedUsageCaracteristiqueTemplateApiService } from './usage-caracteristique-template-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UsageCaracteristiqueTemplateApiService extends GeneratedUsageCaracteristiqueTemplateApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
