import { RepositoryService } from '@get/services/repository';
import { ProgUnite } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgUniteApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgUnite(progUnite: Partial<ProgUnite>): Observable<ProgUnite> {
    return this.repo.create<ProgUnite>('prog-unite', progUnite);
  }

  public updateProgUnite(progUnite: Partial<ProgUnite>): Observable<ProgUnite> {
    return this.repo.update('prog-unite', progUnite);
  }

  public deleteProgUnite(idProgUnite: number): Observable<number> {
    return this.repo.delete('prog-unite/' + +idProgUnite);
  }
}
