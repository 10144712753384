import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@get/environment';
import { NgrxHelperService } from '@get/services/ngrx-helper';
import { appState, metaReducers } from '@get/store/configs/reducers';
import {
  GridErrorReducer,
  GridErrorPatrimoineReducer,
  GridErrorSocieteComposantReducer,
  GridErrorSocieteEspaceFamilleReducer,
  GridErrorSocieteEspaceReducer,
  GridErrorSocieteTerritoireReducer,
  GridErrorProgComposantSousGroupeReducer,
  GridErrorProgComposantReducer,
  GridErrorProgBiblioInterventionReducer
} from '@get/store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as NativeStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects } from './index.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NativeStoreModule.forRoot(
      {
        ...appState,
        gridError: GridErrorReducer.reducer,
        gridErrorPatrimoine: GridErrorPatrimoineReducer.reducer,
        gridErrorProgBiblioIntervention: GridErrorProgBiblioInterventionReducer.reducer,
        gridErrorProgComposantSousGroupe: GridErrorProgComposantSousGroupeReducer.reducer,
        gridErrorProgComposant: GridErrorProgComposantReducer.reducer,
        gridErrorSocieteComposant: GridErrorSocieteComposantReducer.reducer,
        gridErrorSocieteEspace: GridErrorSocieteEspaceReducer.reducer,
        gridErrorSocieteEspaceFamille: GridErrorSocieteEspaceFamilleReducer.reducer,
        gridErrorSocieteTerritoire: GridErrorSocieteTerritoireReducer.reducer
      },
      {
        metaReducers: metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: false,
          strictStateImmutability: true,
          strictStateSerializability: true
        }
      }
    ),
    EffectsModule.forRoot(effects),
    environment.useDevtool || !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : []
  ],
  providers: [NgrxHelperService]
})
export class GetStoreConfigsModuleModule {}
