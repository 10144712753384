import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurGeneratedActions } from '@get/store/actions';
import { ValeurState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurEntityState } from '@get/api-interfaces';

export const valeurReducersGeneratedFunctions: ReducerTypes<ValeurState.IState, readonly ActionCreator[]>[] = [
  on(ValeurGeneratedActions.upsertOneValeur, (state: ValeurState.IState) => setLoadingsState(state, true)),

  on(ValeurGeneratedActions.upsertManyValeursSuccess, (state: ValeurState.IState, { valeurs }) =>
    ValeurState.adapter.upsertMany(valeurs, setLoadingsState(state, false))
  ),
  on(ValeurGeneratedActions.deleteOneValeurSuccess, (state: ValeurState.IState, { idValeur }) =>
    ValeurState.adapter.removeOne(idValeur, setLoadingsState(state, false))
  ),

  on(ValeurGeneratedActions.clearValeurs, () => ValeurState.initialState),

  on(
    ValeurGeneratedActions.addManyValeurComposantSuccess,
    (state: ValeurState.IState, { idValeur, idValeurComposants }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const valeurComposants = (state.entities[idValeur]?.valeurComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            valeurComposants: valeurComposants.concat(
              idValeurComposants.filter(id => valeurComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyValeurComposantSuccess,
    (state: ValeurState.IState, { idValeurComposants, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.valeurComposants) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              valeurComposants: (state.entities[idValeur]?.valeurComposants as number[])?.filter(
                (idValeurComposant: number) => !idValeurComposants.some((id: number) => id === idValeurComposant)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addManyComposantSuccess,
    (state: ValeurState.IState, { idValeur, idComposants }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const composants = (state.entities[idValeur]?.composants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            composants: composants.concat(
              idComposants.filter(id => composants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyComposantSuccess,
    (state: ValeurState.IState, { idComposants, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.composants) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              composants: (state.entities[idValeur]?.composants as number[])?.filter(
                (idComposant: number) => !idComposants.some((id: number) => id === idComposant)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addManyValeurPatrimoineSuccess,
    (state: ValeurState.IState, { idValeur, idValeurPatrimoines }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const valeurPatrimoines = (state.entities[idValeur]?.valeurPatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            valeurPatrimoines: valeurPatrimoines.concat(
              idValeurPatrimoines.filter(id => valeurPatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyValeurPatrimoineSuccess,
    (state: ValeurState.IState, { idValeurPatrimoines, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.valeurPatrimoines) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              valeurPatrimoines: (state.entities[idValeur]?.valeurPatrimoines as number[])?.filter(
                (idValeurPatrimoine: number) => !idValeurPatrimoines.some((id: number) => id === idValeurPatrimoine)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addManyPatrimoineSuccess,
    (state: ValeurState.IState, { idValeur, idPatrimoines }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const patrimoines = (state.entities[idValeur]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ValeurState.IState, { idPatrimoines, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.patrimoines) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              patrimoines: (state.entities[idValeur]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addManyValeurFichierSuccess,
    (state: ValeurState.IState, { idValeur, idValeurFichiers }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const valeurFichiers = (state.entities[idValeur]?.valeurFichiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            valeurFichiers: valeurFichiers.concat(
              idValeurFichiers.filter(id => valeurFichiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyValeurFichierSuccess,
    (state: ValeurState.IState, { idValeurFichiers, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.valeurFichiers) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              valeurFichiers: (state.entities[idValeur]?.valeurFichiers as number[])?.filter(
                (idValeurFichier: number) => !idValeurFichiers.some((id: number) => id === idValeurFichier)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addManyFichierSuccess,
    (state: ValeurState.IState, { idValeur, idFichiers }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      const fichiers = (state.entities[idValeur]?.fichiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            fichiers: fichiers.concat(
              idFichiers.filter(id => fichiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyFichierSuccess,
    (state: ValeurState.IState, { idFichiers, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.fichiers) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              fichiers: (state.entities[idValeur]?.fichiers as number[])?.filter(
                (idFichier: number) => !idFichiers.some((id: number) => id === idFichier)
              )
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addSocieteCaracteristiqueChoixSuccess,
    (state: ValeurState.IState, { idValeur, idSocieteCaracteristiqueChoix }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            societeCaracteristiqueChoix: idSocieteCaracteristiqueChoix
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess,
    (state: ValeurState.IState, { idSocieteCaracteristiqueChoices, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.societeCaracteristiqueChoix) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              societeCaracteristiqueChoix: idSocieteCaracteristiqueChoices.some(
                (idSocieteCaracteristiqueChoix: number) => idSocieteCaracteristiqueChoix === state.entities[idValeur]?.societeCaracteristiqueChoix
              )
                ? undefined
                : state.entities[idValeur]?.societeCaracteristiqueChoix
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addSocieteCaracteristiqueSuccess,
    (state: ValeurState.IState, { idValeur, idSocieteCaracteristique }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            societeCaracteristique: idSocieteCaracteristique
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: ValeurState.IState, { idSocieteCaracteristiques, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.societeCaracteristique) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              societeCaracteristique: idSocieteCaracteristiques.some(
                (idSocieteCaracteristique: number) => idSocieteCaracteristique === state.entities[idValeur]?.societeCaracteristique
              )
                ? undefined
                : state.entities[idValeur]?.societeCaracteristique
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addUserSuccess,
    (state: ValeurState.IState, { idValeur, idUser }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyUserSuccess,
    (state: ValeurState.IState, { idUsers, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.user) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idValeur]?.user
              )
                ? undefined
                : state.entities[idValeur]?.user
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ValeurState.IState,
  isLoading: boolean,
  isLoaded = true
): ValeurState.IState {
  return { ...state, isLoaded, isLoading };
}
