import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Usage } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { UsageRelationsIds } from '@get/store/ids-interfaces';
import { UsageGeneratedActions } from '@get/store/actions';
import { UsageSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedUsageService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(UsageSelectors.selectIsLoadedUsage));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(UsageSelectors.selectIsLoadingUsage));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      UsageSelectors.selectIsReadyAndLoadedUsage as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUsages(schema: SelectSchema = {}): Observable<Usage[]> {
    return this.store$.pipe(select(UsageSelectors.selectAllUsages(schema))).pipe(
      switchMap(({ usages }: { usages: Usage[] }) => {
        return this.getReady(schema).pipe(map(() => usages));
      })
    );
  }

  public selectOneUsage(
    idUsage: number,
    schema: SelectSchema = {}
  ): Observable<Usage> {
    return this.store$
      .pipe(select(UsageSelectors.selectOneUsage(schema, idUsage)))
      .pipe(
        switchMap(({ usage }: { usage: Usage }) => {
          return this.getReady(schema).pipe(map(() => usage));
        })
      );
  }

  public getManyUsages(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Usage[]> {
    this.store$.dispatch(UsageGeneratedActions.getManyUsages({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UsageGeneratedActions.normalizeManyUsagesAfterUpsert,
        UsageGeneratedActions.usagesFailure
      );
    }
  }

  public upsertOneUsage(
    usage: Partial<Usage>,
    ids: UsageRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Usage> {
    this.store$.dispatch(UsageGeneratedActions.upsertOneUsage({ usage, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UsageGeneratedActions.normalizeManyUsagesAfterUpsert,
        UsageGeneratedActions.usagesFailure,
        true
      );
    }
  }

  public deleteOneUsage(
    idUsage: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(UsageGeneratedActions.deleteOneUsage({ idUsage }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UsageGeneratedActions.deleteOneUsageSuccess,
        UsageGeneratedActions.usagesFailure
      );
    }
  }
}
