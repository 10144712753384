import { Injectable } from '@angular/core';
import { DynamicType } from '@get/api-interfaces';
import { MyDatabase, MyDatabaseCollections } from '@get/interfaces';
import { AppState } from '@get/store/configs/reducers';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Action, Store } from '@ngrx/store';
import { compareNumberCustomWithNegatives, transformArrayToChunks, transformArrayToObject } from '@utils';
import fastDeepEqual from 'fast-deep-equal';
import { RxCollection, RxDatabase, createRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { ReplaySubject, combineLatest, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs';

@Injectable()
export class DbService {
  private dbPromise: Promise<MyDatabase>;
  private databaseCollectionsPromise: Promise<MyDatabaseCollections>;
  private databaseCollections?: MyDatabaseCollections;

  private isBlockingState = false;
  private upsertingValues: DynamicType<DynamicType<boolean> | undefined> = {};
  private removingValues: DynamicType<DynamicType<boolean> | undefined> = {};

  constructor(private store$: Store<AppState>) {
    this.dbPromise = this.initDbPromise();
    this.databaseCollectionsPromise = this.initDatabaseCollections();
  }

  private async initDbPromise(): Promise<RxDatabase<any>> {
    return createRxDatabase({
      name: 'organisations',
      storage: getRxStorageDexie(),
      cleanupPolicy: {
        /**
         * The minimum time in milliseconds for how long
         * a document has to be deleted before it is
         * purged by the cleanup.
         * [default=one month]
         */
        minimumDeletedTime: 1000 * 60 * 60 * 24 * 7, // one week,
        /**
         * The minimum amount of that that the RxCollection must have existed.
         * This ensures that at the initial page load, more important
         * tasks are not slowed down because a cleanup process is running.
         * [default=60 seconds]
         */
        minimumCollectionAge: 1000 * 30, // 30 seconds
        /**
         * After the initial cleanup is done,
         * a new cleanup is started after [runEach] milliseconds
         * [default=5 minutes]
         */
        runEach: 1000 * 60 * 10 // 10 minutes
      }
    });
  }

  private async removeDeprecatedCollections(db: MyDatabase): Promise<void> {
    const dbWithCollectionsToRemove = await db.addCollections({
      'composant-groupes': {
        schema: {
          title: 'composantGroupes schema',
          version: 0,
          primaryKey: 'idComposantGroupe',
          type: 'object',
          properties: {
            idComposantGroupe: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            idFichier: { type: 'number' },
            libelle: { type: 'string' }
          }
        }
      }
    });
    dbWithCollectionsToRemove['composant-groupes']?.remove();
    dbWithCollectionsToRemove['composant-groupes']?.destroy();
  }

  private async initDatabaseCollections(): Promise<MyDatabaseCollections> {
    const db = await this.dbPromise;
    // After 20s, remove data from unused tables to free up space
    setTimeout(() => {
      this.removeDeprecatedCollections(db);
    }, 20000);
    return db.addCollections({
      // Needed by initialisation
      organisations: {
        schema: {
          title: 'organisations schema',
          version: 0,
          primaryKey: 'idOrganisation',
          type: 'object',
          properties: {
            idOrganisation: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            libelle: {
              type: 'string'
            }
          }
        }
      },
      droits: {
        schema: {
          title: 'droits schema',
          version: 0,
          primaryKey: 'idDroit',
          type: 'object',
          properties: {
            idDroit: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            libelle: { type: 'string' }
          }
        }
      },
      fichiers: {
        schema: {
          title: 'fichiers schema',
          version: 0,
          primaryKey: 'idFichier',
          type: 'object',
          properties: {
            idFichier: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            fileName: { type: 'string' },
            fileType: { type: 'string' },
            originalName: { type: 'string' }
          }
        }
      },
      'societe-composant-descriptions': {
        schema: {
          title: 'societeComposantDescriptions schema',
          version: 0,
          primaryKey: 'idSocieteComposant',
          type: 'object',
          properties: {
            idSocieteComposant: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            description: { type: 'string' },
            descriptionLexique: { type: 'string' }
          }
        }
      },
      // Other tables
      patrimoines: {
        schema: {
          title: 'patrimoines schema',
          version: 0,
          primaryKey: 'idPatrimoine',
          type: 'object',
          properties: {
            idPatrimoine: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            }
            // adresse: { type: 'string' },
            // avancement: { type: 'number' },
            // codePostal: { type: 'number' },
            // commune: { type: 'string' },
            // idSociete: { type: 'number' },
            // lat: { type: 'number' },
            // libelle: { type: 'string' },
            // lng: { type: 'number' },
            // reference: { type: 'string' }
          }
        }
      },
      'societe-patrimoine-hierarchies': {
        schema: {
          title: 'societePatrimoineHierarchies schema',
          version: 0,
          primaryKey: 'idSocietePatrimoineHierarchie',
          type: 'object',
          properties: {
            idSocietePatrimoineHierarchie: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            }
          }
        }
      },
      composants: {
        schema: {
          title: 'composants schema',
          version: 0,
          primaryKey: 'uuid',
          type: 'object',
          properties: {
            uuid: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            isSynced: {
              type: 'boolean'
            },
            societeComposant: {
              type: 'integer'
            }
          }
        }
      },
      'composant-attendus': {
        schema: {
          title: 'composantAttendus schema',
          version: 0,
          primaryKey: 'idComposantAttendu',
          type: 'object',
          properties: {
            idComposantAttendu: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            isSynced: {
              type: 'boolean'
            },
            societeComposant: {
              type: 'integer'
            }
          }
        }
      },
      icons: {
        schema: {
          title: 'icons schema',
          version: 0,
          primaryKey: 'name',
          type: 'object',
          properties: {
            name: {
              type: 'string',
              maxLength: 100 // <- the primary key must have set maxLength
            },
            svg: {
              type: 'string'
            }
          }
        }
      }
    });
  }

  async getDatabaseCollection(): Promise<MyDatabaseCollections> {
    if (!this.databaseCollections) {
      this.databaseCollections = await this.databaseCollectionsPromise;
      return this.databaseCollections;
    }
    return this.databaseCollections;
  }

  public setBlockingState(isBlocked: boolean): void {
    this.isBlockingState = isBlocked;
  }

  // N'est plus utilisée mais peut servir d'exemple pour refacto d'autres sections
  public async handleIndexedDbRefillingStorage<T, U>(params: {
    databaseCollections: MyDatabaseCollections;
    tableKey: keyof MyDatabaseCollections;
    storeKey: keyof DynamicType<T>;
    offlinePrimaryKey: keyof T;
    normalizationMethod(param: DynamicType<T[]>): Action;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
    isBlockable?: boolean;
    keysToOmit?: (keyof T)[];
  }): Promise<void> {
    combineLatest([
      (params.databaseCollections[params.tableKey] as RxCollection).find().$.pipe(
        untilDestroyed(params.component),
        startWith([]),
        map(values => values?.map(el => el.getLatest().toJSON())?.filter(el => !el.deleted)),
        map(values =>
          !params.offlinePrimaryKey
            ? values
            : values.sort((a, b) =>
                compareNumberCustomWithNegatives(+a[params.offlinePrimaryKey], +b[params.offlinePrimaryKey])
              )
        ),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ])
      .pipe(
        filter(([_]) => !params.isBlockable || !this.isBlockingState),
        tap(([currentValues]) => {
          if (currentValues?.length) {
            this.store$.dispatch(
              params.normalizationMethod({
                [params.storeKey]: currentValues.map(el => ({
                  ...el,
                  [params.offlinePrimaryKey]: +el[params.offlinePrimaryKey],
                  ...(params.keysToOmit || []).reduce((acc, curr) => {
                    acc[curr as string] = undefined;
                    return acc;
                  }, {} as DynamicType<undefined>)
                }))
              })
            );
          }
        })
      )
      .subscribe();
  }

  public async clearIndexedDbTable<T>(
    databaseCollection: MyDatabaseCollections,
    params: {
      tableKey: keyof MyDatabaseCollections;
      primaryKey: keyof T;
    }
  ): Promise<void> {
    const elements = await (databaseCollection[params.tableKey] as RxCollection).find().exec();
    const ids = elements?.map(el => el[params.primaryKey]);
    if (ids?.length) {
      databaseCollection[params.tableKey].bulkRemove(ids);
    }
  }

  public async updateIndexedDb<T>(
    databaseCollections: MyDatabaseCollections,
    tableName: keyof MyDatabaseCollections,
    elements: T[],
    offlinePrimaryKey: keyof T,
    condition?: (el: any) => boolean
  ): Promise<void> {
    // Get previous data in indexedDb to filter and remove elements we are not expecting to be stored
    const previousElements =
      (await databaseCollections[tableName].find().exec())?.map(el => el.getLatest().toJSON()) || [];
    const newArrayObj = elements.reduce((acc, curr) => {
      acc[curr[offlinePrimaryKey] as number] = true;
      return acc;
    }, {} as DynamicType<boolean>);
    // Removing only elements that are not useful but matching condition (synced for example)
    const previousElementsToRemove: string[] = (previousElements as any)
      .filter((el: any) => !newArrayObj[el[offlinePrimaryKey]] && (!condition || condition(el)))
      .map((el: any) => el[offlinePrimaryKey]);
    if (previousElementsToRemove?.length) {
      const filteredElementsToRemove = previousElementsToRemove.filter(el => !this.removingValues[tableName]?.[el]);
      if (filteredElementsToRemove?.length) {
        let shouldEmptyRemovingValues = false;
        if (!this.removingValues[tableName]) {
          this.removingValues[tableName] = filteredElementsToRemove.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {} as DynamicType<boolean>);
          shouldEmptyRemovingValues = true;
        }
        const chunksToRemove = transformArrayToChunks(previousElementsToRemove, 1000);
        for (let j = 0; j < chunksToRemove?.length; j++) {
          await databaseCollections[tableName].bulkRemove(chunksToRemove[j]);
        }
        if (shouldEmptyRemovingValues) {
          this.removingValues[tableName] = undefined;
        }
      }
    }

    // Filter elements to prevent overriding not synced composants
    const previousElementsObj = transformArrayToObject(previousElements, { key: offlinePrimaryKey as string });
    const filteredElements = elements
      .map(el => ({
        ...el,
        [offlinePrimaryKey]: el?.[offlinePrimaryKey]?.toString()
      }))
      .filter(el => !fastDeepEqual(el, previousElementsObj[el[offlinePrimaryKey]]));
    const elementsToUpsert = !condition
      ? filteredElements
      : filteredElements.filter(el => condition(previousElementsObj[el[offlinePrimaryKey]]));

    // Update table in indexedDb
    if (elementsToUpsert?.length) {
      const filteredElementsToUpsert = elementsToUpsert.filter(
        el => !this.upsertingValues[tableName]?.[el[offlinePrimaryKey] as string]
      );
      if (filteredElementsToUpsert?.length) {
        let shouldEmptyUpsertingValues = false;
        if (!this.upsertingValues[tableName]) {
          this.upsertingValues[tableName] = filteredElementsToUpsert.reduce((acc, curr) => {
            acc[curr[offlinePrimaryKey] as string] = true;
            return acc;
          }, {} as DynamicType<boolean>);
          shouldEmptyUpsertingValues = true;
        }
        const chunks = transformArrayToChunks(filteredElementsToUpsert, 500);
        for (let j = 0; j < chunks?.length; j++) {
          await databaseCollections[tableName].bulkUpsert(chunks[j]);
        }
        if (shouldEmptyUpsertingValues) {
          this.upsertingValues[tableName] = undefined;
        }
      }
    }
  }
}
