import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgInterventionScenario, ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgInterventionScenarioRelationsIds } from '@get/store/ids-interfaces';

export const ProgInterventionScenarioGeneratedActions = createActionGroup({
  source: 'Prog Intervention Scenario Generated',
  events: {
    'Upsert One Prog Intervention Scenario': props<{ progInterventionScenario: Partial<ProgInterventionScenario>; ids?: ProgInterventionScenarioRelationsIds; }>(),
    'Upsert Many Prog Intervention Scenarios': props<{ progInterventionScenarios: Partial<ProgInterventionScenario>[]; ids?: ProgInterventionScenarioRelationsIds; }>(),
    'Upsert Many Prog Intervention Scenarios Success': props<{ progInterventionScenarios: ProgInterventionScenarioEntityState[] }>(),
    'Delete One Prog Intervention Scenario Success': props<{ idProgInterventionScenario: number }>(),
    'Normalize Many Prog Intervention Scenarios After Upsert': props<{ progInterventionScenarios: ProgInterventionScenarioEntityState[] }>(),
    'Prog Intervention Scenarios Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Intervention Scenarios': emptyProps(),
    'Add Prog Scenario Success': props<{ idProgInterventionScenario: number; idProgScenario: number }>(),
    'Delete Many Prog Scenario Success': props<{ idProgScenarios: number[]; idProgInterventionScenarios: number[] }>()
  }
});
