import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgUniteApiService } from '@get/store/api-services';
import { GeneratedProgUniteEffects } from './prog-unite-generated.effects';

@Injectable()
export class ProgUniteEffects extends GeneratedProgUniteEffects {
  constructor(actions$: Actions, progUniteApiService: ProgUniteApiService, store$: Store<AppState>) {
    super(actions$, progUniteApiService, store$);
  }
}
