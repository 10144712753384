import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgComposantGroupe, ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantGroupeApiService } from '@get/store/api-services';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgComposantGroupe } from '@get/store/configs/normalization';
import { ProgComposantGroupeSelectors } from '@get/store/selectors';
import { ProgComposantGroupeRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupe } from '@get/api-interfaces';

export function getDefaultAddProgComposantGroupeActions(progComposantGroupe: ProgComposantGroupeEntityState, ids?: ProgComposantGroupeRelationsIds): Action[] {
  const actions: Action[] = [ProgComposantGroupeGeneratedActions.normalizeManyProgComposantGroupesAfterUpsert({ progComposantGroupes: [progComposantGroupe] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgComposantGroupeSuccess({
        idSociete: ids.societe,
        idProgComposantGroupes: [progComposantGroupe.idProgComposantGroupe]
      })
    );
    actions.push(
      ProgComposantGroupeGeneratedActions.addSocieteSuccess({
        idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progComposantSousGroupes) {
    if (!Array.isArray(ids.progComposantSousGroupes)) {
      actions.push(
        ProgComposantSousGroupeGeneratedActions.upsertOneProgComposantSousGroupe({
          progComposantSousGroupe: {
            idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe,
            idProgComposantSousGroupe: ids.progComposantSousGroupes as number
          } as ProgComposantSousGroupe
        })
      );
      actions.push(
        ProgComposantGroupeGeneratedActions.addManyProgComposantSousGroupeSuccess({
          idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe,
          idProgComposantSousGroupes: [ids.progComposantSousGroupes as number]
        })
      );
    } else {
      actions.push(
        ProgComposantSousGroupeGeneratedActions.upsertManyProgComposantSousGroupes({
          progComposantSousGroupes: (ids.progComposantSousGroupes as number[]).map(
            (idProgComposantSousGroupe: number) => ({
              idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe,
              idProgComposantSousGroupe
            })
          ) as ProgComposantSousGroupe[]
        })
      );
      actions.push(
        ProgComposantGroupeGeneratedActions.addManyProgComposantSousGroupeSuccess({
          idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe,
          idProgComposantSousGroupes: ids.progComposantSousGroupes as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgComposantGroupeActions(progComposantGroupe: ProgComposantGroupeEntityState): Action[] {
  const actions: Action[] = [ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupeSuccess({ idProgComposantGroupe: progComposantGroupe.idProgComposantGroupe })];

  if (progComposantGroupe.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgComposantGroupeSuccess({
        idProgComposantGroupes: [progComposantGroupe.idProgComposantGroupe],
        idSocietes: [progComposantGroupe.societe as number]
      })
    );
  }

  if (progComposantGroupe.progComposantSousGroupes) {
    actions.push(
      ProgComposantSousGroupeGeneratedActions.deleteManyProgComposantGroupeSuccess({
        idProgComposantGroupes: [progComposantGroupe.idProgComposantGroupe],
        idProgComposantSousGroupes: progComposantGroupe.progComposantSousGroupes as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgComposantGroupeEffects {
  constructor(
    protected actions$: Actions,
    protected progComposantGroupeApiService: ProgComposantGroupeApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgComposantGroupe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantGroupeGeneratedActions.upsertOneProgComposantGroupe),
      concatMap(
        ({
          progComposantGroupe,
          ids
        }: {
          progComposantGroupe: Partial<ProgComposantGroupe>;
          ids?: ProgComposantGroupeRelationsIds;
        }) => {
          if (progComposantGroupe.idProgComposantGroupe) {
            return this.progComposantGroupeApiService.updateProgComposantGroupe(progComposantGroupe).pipe(
              map((progComposantGroupeReturned: ProgComposantGroupe) => {
                return ProgComposantGroupeGeneratedActions.normalizeManyProgComposantGroupesAfterUpsert({ progComposantGroupes: [progComposantGroupeReturned] });
              }),
              catchError(error => of(ProgComposantGroupeGeneratedActions.progComposantGroupesFailure({ error })))
            );
          } else {
            return this.progComposantGroupeApiService.addProgComposantGroupe(progComposantGroupe).pipe(
              mergeMap((progComposantGroupeReturned: ProgComposantGroupe) => getDefaultAddProgComposantGroupeActions(progComposantGroupeReturned, ids)),
              catchError(error => of(ProgComposantGroupeGeneratedActions.progComposantGroupesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgComposantGroupe$ = createEffect(() => {
    const selectProgComposantGroupeState$ = this.store$.select(ProgComposantGroupeSelectors.selectProgComposantGroupeState);
    return this.actions$.pipe(
      ofType(ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupe),
      withLatestFrom(selectProgComposantGroupeState$),
      concatMap(([{ idProgComposantGroupe }, state]) =>
        this.progComposantGroupeApiService.deleteProgComposantGroupe(idProgComposantGroupe).pipe(
          mergeMap(_success => getDefaultDeleteProgComposantGroupeActions(state.entities[idProgComposantGroupe] as ProgComposantGroupeEntityState)),
          catchError(error => of(ProgComposantGroupeGeneratedActions.progComposantGroupesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgComposantGroupesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantGroupeGeneratedActions.normalizeManyProgComposantGroupesAfterUpsert),
      concatMap(({ progComposantGroupes }) => {
        const actions: Action[] = getActionsToNormalizeProgComposantGroupe(progComposantGroupes, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgComposantGroupe] Normalization After Upsert Success')];
      })
    );
  });
}
