import { RepositoryService } from '@get/services/repository';
import { ProgComposant } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgComposantApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgComposant(progComposant: Partial<ProgComposant>): Observable<ProgComposant> {
    return this.repo.create<ProgComposant>('prog-composant', progComposant);
  }

  public updateProgComposant(progComposant: Partial<ProgComposant>): Observable<ProgComposant> {
    return this.repo.update('prog-composant', progComposant);
  }

  public deleteProgComposant(idProgComposant: number): Observable<number> {
    return this.repo.delete('prog-composant/' + +idProgComposant);
  }
}
