import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseApiService } from '@get/store/api-services';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteConfigAnalyseSynthese } from '@get/store/configs/normalization';
import { SocieteGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';

export function getDefaultDeleteSocieteConfigAnalyseSyntheseActions(societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState): Action[] {
  const actions: Action[] = [SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSuccess({ idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese })];

  if (societeConfigAnalyseSynthese.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocietes: [societeConfigAnalyseSynthese.societe as number]
      })
    );
  }

  if (societeConfigAnalyseSynthese.user) {
    actions.push(
      UserGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idUsers: [societeConfigAnalyseSynthese.user as number]
      })
    );
  }

  if (societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
    actions.push(
      SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]
      })
    );
  }

  if (societeConfigAnalyseSynthese.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocieteCaracteristiques: societeConfigAnalyseSynthese.societeCaracteristiques as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteConfigAnalyseSyntheseEffects {
  constructor(
    protected actions$: Actions,
    protected societeConfigAnalyseSyntheseApiService: SocieteConfigAnalyseSyntheseApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteConfigAnalyseSynthesesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert),
      concatMap(({ societeConfigAnalyseSyntheses }) => {
        const actions: Action[] = getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSyntheses, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteConfigAnalyseSynthese] Normalization After Upsert Success')];
      })
    );
  });
}
