import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantGroupeState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgComposantGroupeEntityState } from '@get/api-interfaces';

export const progComposantGroupeReducersGeneratedFunctions: ReducerTypes<ProgComposantGroupeState.IState, readonly ActionCreator[]>[] = [
  on(ProgComposantGroupeGeneratedActions.upsertOneProgComposantGroupe, (state: ProgComposantGroupeState.IState) => setLoadingsState(state, true)),

  on(ProgComposantGroupeGeneratedActions.upsertManyProgComposantGroupesSuccess, (state: ProgComposantGroupeState.IState, { progComposantGroupes }) =>
    ProgComposantGroupeState.adapter.upsertMany(progComposantGroupes, setLoadingsState(state, false))
  ),
  on(ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupe, (state: ProgComposantGroupeState.IState) => setLoadingsState(state, true)),
  on(ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupeSuccess, (state: ProgComposantGroupeState.IState, { idProgComposantGroupe }) =>
    ProgComposantGroupeState.adapter.removeOne(idProgComposantGroupe, setLoadingsState(state, false))
  ),

  on(ProgComposantGroupeGeneratedActions.clearProgComposantGroupes, () => ProgComposantGroupeState.initialState),

  on(
    ProgComposantGroupeGeneratedActions.addManyProgComposantSousGroupeSuccess,
    (state: ProgComposantGroupeState.IState, { idProgComposantGroupe, idProgComposantSousGroupes }) => {
      if (!state.entities[idProgComposantGroupe]) {
        return state;
      }
      const progComposantSousGroupes = (state.entities[idProgComposantGroupe]?.progComposantSousGroupes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposantGroupe]: {
            ...state.entities[idProgComposantGroupe],
            progComposantSousGroupes: progComposantSousGroupes.concat(
              idProgComposantSousGroupes.filter(id => progComposantSousGroupes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgComposantGroupeGeneratedActions.deleteManyProgComposantSousGroupeSuccess,
    (state: ProgComposantGroupeState.IState, { idProgComposantSousGroupes, idProgComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposantGroupes.reduce((entities, idProgComposantGroupe) => {
            if (!state.entities[idProgComposantGroupe]?.progComposantSousGroupes) {
              return entities;
            }
            entities[idProgComposantGroupe] = {
              ...state.entities[idProgComposantGroupe],
              progComposantSousGroupes: (state.entities[idProgComposantGroupe]?.progComposantSousGroupes as number[])?.filter(
                (idProgComposantSousGroupe: number) => !idProgComposantSousGroupes.some((id: number) => id === idProgComposantSousGroupe)
              )
            } as ProgComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantGroupeGeneratedActions.addSocieteSuccess,
    (state: ProgComposantGroupeState.IState, { idProgComposantGroupe, idSociete }) => {
      if (!state.entities[idProgComposantGroupe]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposantGroupe]: {
            ...state.entities[idProgComposantGroupe],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgComposantGroupeGeneratedActions.deleteManySocieteSuccess,
    (state: ProgComposantGroupeState.IState, { idSocietes, idProgComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposantGroupes.reduce((entities, idProgComposantGroupe) => {
            if (!state.entities[idProgComposantGroupe]?.societe) {
              return entities;
            }
            entities[idProgComposantGroupe] = {
              ...state.entities[idProgComposantGroupe],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgComposantGroupe]?.societe
              )
                ? undefined
                : state.entities[idProgComposantGroupe]?.societe
            } as ProgComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantGroupeEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgComposantGroupeState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgComposantGroupeState.IState {
  return { ...state, isLoaded, isLoading };
}
