import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgEtatTechnique, ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { ProgEtatTechniqueApiService } from '@get/store/api-services';
import { ProgEtatTechniqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgEtatTechnique } from '@get/store/configs/normalization';
import { ProgEtatTechniqueSelectors } from '@get/store/selectors';
import { ProgEtatTechniqueRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfo } from '@get/api-interfaces';

export function getDefaultAddProgEtatTechniqueActions(progEtatTechnique: ProgEtatTechniqueEntityState, ids?: ProgEtatTechniqueRelationsIds): Action[] {
  const actions: Action[] = [ProgEtatTechniqueGeneratedActions.normalizeManyProgEtatTechniquesAfterUpsert({ progEtatTechniques: [progEtatTechnique] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgEtatTechniqueSuccess({
        idSociete: ids.societe,
        idProgEtatTechniques: [progEtatTechnique.idProgEtatTechnique]
      })
    );
    actions.push(
      ProgEtatTechniqueGeneratedActions.addSocieteSuccess({
        idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progPatrimoineInfos) {
    if (!Array.isArray(ids.progPatrimoineInfos)) {
      actions.push(
        ProgPatrimoineInfoGeneratedActions.upsertOneProgPatrimoineInfo({
          progPatrimoineInfo: {
            idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique,
            idProgPatrimoineInfo: ids.progPatrimoineInfos as number
          } as ProgPatrimoineInfo
        })
      );
      actions.push(
        ProgEtatTechniqueGeneratedActions.addManyProgPatrimoineInfoSuccess({
          idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique,
          idProgPatrimoineInfos: [ids.progPatrimoineInfos as number]
        })
      );
    } else {
      actions.push(
        ProgPatrimoineInfoGeneratedActions.upsertManyProgPatrimoineInfos({
          progPatrimoineInfos: (ids.progPatrimoineInfos as number[]).map(
            (idProgPatrimoineInfo: number) => ({
              idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique,
              idProgPatrimoineInfo
            })
          ) as ProgPatrimoineInfo[]
        })
      );
      actions.push(
        ProgEtatTechniqueGeneratedActions.addManyProgPatrimoineInfoSuccess({
          idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique,
          idProgPatrimoineInfos: ids.progPatrimoineInfos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgEtatTechniqueActions(progEtatTechnique: ProgEtatTechniqueEntityState): Action[] {
  const actions: Action[] = [ProgEtatTechniqueGeneratedActions.deleteOneProgEtatTechniqueSuccess({ idProgEtatTechnique: progEtatTechnique.idProgEtatTechnique })];

  if (progEtatTechnique.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgEtatTechniqueSuccess({
        idProgEtatTechniques: [progEtatTechnique.idProgEtatTechnique],
        idSocietes: [progEtatTechnique.societe as number]
      })
    );
  }

  if (progEtatTechnique.progPatrimoineInfos) {
    actions.push(
      ProgPatrimoineInfoGeneratedActions.deleteManyProgEtatTechniqueSuccess({
        idProgEtatTechniques: [progEtatTechnique.idProgEtatTechnique],
        idProgPatrimoineInfos: progEtatTechnique.progPatrimoineInfos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgEtatTechniqueEffects {
  constructor(
    protected actions$: Actions,
    protected progEtatTechniqueApiService: ProgEtatTechniqueApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgEtatTechnique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgEtatTechniqueGeneratedActions.upsertOneProgEtatTechnique),
      concatMap(
        ({
          progEtatTechnique,
          ids
        }: {
          progEtatTechnique: Partial<ProgEtatTechnique>;
          ids?: ProgEtatTechniqueRelationsIds;
        }) => {
          if (progEtatTechnique.idProgEtatTechnique) {
            return this.progEtatTechniqueApiService.updateProgEtatTechnique(progEtatTechnique).pipe(
              map((progEtatTechniqueReturned: ProgEtatTechnique) => {
                return ProgEtatTechniqueGeneratedActions.normalizeManyProgEtatTechniquesAfterUpsert({ progEtatTechniques: [progEtatTechniqueReturned] });
              }),
              catchError(error => of(ProgEtatTechniqueGeneratedActions.progEtatTechniquesFailure({ error })))
            );
          } else {
            return this.progEtatTechniqueApiService.addProgEtatTechnique(progEtatTechnique).pipe(
              mergeMap((progEtatTechniqueReturned: ProgEtatTechnique) => getDefaultAddProgEtatTechniqueActions(progEtatTechniqueReturned, ids)),
              catchError(error => of(ProgEtatTechniqueGeneratedActions.progEtatTechniquesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgEtatTechnique$ = createEffect(() => {
    const selectProgEtatTechniqueState$ = this.store$.select(ProgEtatTechniqueSelectors.selectProgEtatTechniqueState);
    return this.actions$.pipe(
      ofType(ProgEtatTechniqueGeneratedActions.deleteOneProgEtatTechnique),
      withLatestFrom(selectProgEtatTechniqueState$),
      concatMap(([{ idProgEtatTechnique }, state]) =>
        this.progEtatTechniqueApiService.deleteProgEtatTechnique(idProgEtatTechnique).pipe(
          mergeMap(_success => getDefaultDeleteProgEtatTechniqueActions(state.entities[idProgEtatTechnique] as ProgEtatTechniqueEntityState)),
          catchError(error => of(ProgEtatTechniqueGeneratedActions.progEtatTechniquesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgEtatTechniquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgEtatTechniqueGeneratedActions.normalizeManyProgEtatTechniquesAfterUpsert),
      concatMap(({ progEtatTechniques }) => {
        const actions: Action[] = getActionsToNormalizeProgEtatTechnique(progEtatTechniques, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgEtatTechnique] Normalization After Upsert Success')];
      })
    );
  });
}
