import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Fichier } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { FichierGeneratedActions } from '@get/store/actions';
import { FichierSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedFichierService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(FichierSelectors.selectIsLoadedFichier));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(FichierSelectors.selectIsLoadingFichier));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      FichierSelectors.selectIsReadyAndLoadedFichier as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllFichiers(schema: SelectSchema = {}): Observable<Fichier[]> {
    return this.store$.pipe(select(FichierSelectors.selectAllFichiers(schema))).pipe(
      switchMap(({ fichiers }: { fichiers: Fichier[] }) => {
        return this.getReady(schema).pipe(map(() => fichiers));
      })
    );
  }

  public selectOneFichier(
    idFichier: number,
    schema: SelectSchema = {}
  ): Observable<Fichier> {
    return this.store$
      .pipe(select(FichierSelectors.selectOneFichier(schema, idFichier)))
      .pipe(
        switchMap(({ fichier }: { fichier: Fichier }) => {
          return this.getReady(schema).pipe(map(() => fichier));
        })
      );
  }

  public getManyFichiers(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Fichier[]> {
    this.store$.dispatch(FichierGeneratedActions.getManyFichiers({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FichierGeneratedActions.normalizeManyFichiersAfterUpsert,
        FichierGeneratedActions.fichiersFailure
      );
    }
  }
}
