import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfilApiService } from '@get/store/api-services';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteProfil } from '@get/store/configs/normalization';
import { SocieteProfilSelectors } from '@get/store/selectors';
import { SocieteProfilRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { UserSocieteProfil } from '@get/api-interfaces';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroit } from '@get/api-interfaces';
import { DroitGeneratedActions } from '@get/store/actions';
import { Droit } from '@get/api-interfaces';

export function getDefaultAddSocieteProfilActions(societeProfil: SocieteProfilEntityState, ids?: SocieteProfilRelationsIds): Action[] {
  const actions: Action[] = [SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert({ societeProfils: [societeProfil] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteProfilSuccess({
        idSociete: ids.societe,
        idSocieteProfils: [societeProfil.idSocieteProfil]
      })
    );
    actions.push(
      SocieteProfilGeneratedActions.addSocieteSuccess({
        idSocieteProfil: societeProfil.idSocieteProfil,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idSocieteProfil: societeProfil.idSocieteProfil,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyUserSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map(
            (idUser: number) => ({
              idSocieteProfil: societeProfil.idSocieteProfil,
              idUser
            })
          ) as User[] & any[]
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyUserSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.userSocieteProfils) {
    if (!Array.isArray(ids.userSocieteProfils)) {
      actions.push(
        UserSocieteProfilGeneratedActions.upsertOneUserSocieteProfil({
          userSocieteProfil: {
            idSocieteProfil: societeProfil.idSocieteProfil,
            idUserSocieteProfil: ids.userSocieteProfils as number
          } as UserSocieteProfil
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyUserSocieteProfilSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idUserSocieteProfils: [ids.userSocieteProfils as number]
        })
      );
    } else {
      actions.push(
        UserSocieteProfilGeneratedActions.upsertManyUserSocieteProfils({
          userSocieteProfils: (ids.userSocieteProfils as number[]).map(
            (idUserSocieteProfil: number) => ({
              idSocieteProfil: societeProfil.idSocieteProfil,
              idUserSocieteProfil
            })
          ) as UserSocieteProfil[]
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyUserSocieteProfilSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idUserSocieteProfils: ids.userSocieteProfils as number[]
        })
      );
    }
  }

  if (ids?.societeProfilDroits) {
    if (!Array.isArray(ids.societeProfilDroits)) {
      actions.push(
        SocieteProfilDroitGeneratedActions.upsertOneSocieteProfilDroit({
          societeProfilDroit: {
            idSocieteProfil: societeProfil.idSocieteProfil,
            idSocieteProfilDroit: ids.societeProfilDroits as number
          } as SocieteProfilDroit
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManySocieteProfilDroitSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idSocieteProfilDroits: [ids.societeProfilDroits as number]
        })
      );
    } else {
      actions.push(
        SocieteProfilDroitGeneratedActions.upsertManySocieteProfilDroits({
          societeProfilDroits: (ids.societeProfilDroits as number[]).map(
            (idSocieteProfilDroit: number) => ({
              idSocieteProfil: societeProfil.idSocieteProfil,
              idSocieteProfilDroit
            })
          ) as SocieteProfilDroit[]
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManySocieteProfilDroitSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idSocieteProfilDroits: ids.societeProfilDroits as number[]
        })
      );
    }
  }

  if (ids?.droits) {
    if (!Array.isArray(ids.droits)) {
      actions.push(
        DroitGeneratedActions.upsertOneDroit({
          droit: {
            idSocieteProfil: societeProfil.idSocieteProfil,
            idDroit: ids.droits as number
          } as Droit & any
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyDroitSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idDroits: [ids.droits as number]
        })
      );
    } else {
      actions.push(
        DroitGeneratedActions.upsertManyDroits({
          droits: (ids.droits as number[]).map(
            (idDroit: number) => ({
              idSocieteProfil: societeProfil.idSocieteProfil,
              idDroit
            })
          ) as Droit[] & any[]
        })
      );
      actions.push(
        SocieteProfilGeneratedActions.addManyDroitSuccess({
          idSocieteProfil: societeProfil.idSocieteProfil,
          idDroits: ids.droits as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteProfilActions(societeProfil: SocieteProfilEntityState): Action[] {
  const actions: Action[] = [SocieteProfilGeneratedActions.deleteOneSocieteProfilSuccess({ idSocieteProfil: societeProfil.idSocieteProfil })];

  if (societeProfil.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteProfilSuccess({
        idSocieteProfils: [societeProfil.idSocieteProfil],
        idSocietes: [societeProfil.societe as number]
      })
    );
  }

  if (societeProfil.users) {
    actions.push(
      UserGeneratedActions.deleteManySocieteProfilSuccess({
        idSocieteProfils: [societeProfil.idSocieteProfil],
        idUsers: societeProfil.users as number[]
      })
    );
  }

  if (societeProfil.userSocieteProfils) {
    actions.push(
      UserSocieteProfilGeneratedActions.deleteManySocieteProfilSuccess({
        idSocieteProfils: [societeProfil.idSocieteProfil],
        idUserSocieteProfils: societeProfil.userSocieteProfils as number[]
      })
    );
  }

  if (societeProfil.societeProfilDroits) {
    actions.push(
      SocieteProfilDroitGeneratedActions.deleteManySocieteProfilSuccess({
        idSocieteProfils: [societeProfil.idSocieteProfil],
        idSocieteProfilDroits: societeProfil.societeProfilDroits as number[]
      })
    );
  }

  if (societeProfil.droits) {
    actions.push(
      DroitGeneratedActions.deleteManySocieteProfilSuccess({
        idSocieteProfils: [societeProfil.idSocieteProfil],
        idDroits: societeProfil.droits as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteProfilEffects {
  constructor(
    protected actions$: Actions,
    protected societeProfilApiService: SocieteProfilApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneSocieteProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilGeneratedActions.upsertOneSocieteProfil),
      concatMap(
        ({
          societeProfil,
          ids
        }: {
          societeProfil: Partial<SocieteProfil>;
          ids?: SocieteProfilRelationsIds;
        }) => {
          if (societeProfil.idSocieteProfil) {
            return this.societeProfilApiService.updateSocieteProfil(societeProfil).pipe(
              map((societeProfilReturned: SocieteProfil) => {
                return SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert({ societeProfils: [societeProfilReturned] });
              }),
              catchError(error => of(SocieteProfilGeneratedActions.societeProfilsFailure({ error })))
            );
          } else {
            return this.societeProfilApiService.addSocieteProfil(societeProfil).pipe(
              mergeMap((societeProfilReturned: SocieteProfil) => getDefaultAddSocieteProfilActions(societeProfilReturned, ids)),
              catchError(error => of(SocieteProfilGeneratedActions.societeProfilsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteProfil$ = createEffect(() => {
    const selectSocieteProfilState$ = this.store$.select(SocieteProfilSelectors.selectSocieteProfilState);
    return this.actions$.pipe(
      ofType(SocieteProfilGeneratedActions.deleteOneSocieteProfil),
      withLatestFrom(selectSocieteProfilState$),
      concatMap(([{ idSocieteProfil }, state]) =>
        this.societeProfilApiService.deleteSocieteProfil(idSocieteProfil).pipe(
          mergeMap(_success => getDefaultDeleteSocieteProfilActions(state.entities[idSocieteProfil] as SocieteProfilEntityState)),
          catchError(error => of(SocieteProfilGeneratedActions.societeProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert),
      concatMap(({ societeProfils }) => {
        const actions: Action[] = getActionsToNormalizeSocieteProfil(societeProfils, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteProfil] Normalization After Upsert Success')];
      })
    );
  });
}
