import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteTerritoire } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteTerritoireRelationsIds } from '@get/store/ids-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteTerritoireService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteTerritoireSelectors.selectIsLoadedSocieteTerritoire));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteTerritoireSelectors.selectIsLoadingSocieteTerritoire));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteTerritoireSelectors.selectIsReadyAndLoadedSocieteTerritoire as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteTerritoires(schema: SelectSchema = {}): Observable<SocieteTerritoire[]> {
    return this.store$.pipe(select(SocieteTerritoireSelectors.selectAllSocieteTerritoires(schema))).pipe(
      switchMap(({ societeTerritoires }: { societeTerritoires: SocieteTerritoire[] }) => {
        return this.getReady(schema).pipe(map(() => societeTerritoires));
      })
    );
  }

  public selectOneSocieteTerritoire(
    idSocieteTerritoire: number,
    schema: SelectSchema = {}
  ): Observable<SocieteTerritoire> {
    return this.store$
      .pipe(select(SocieteTerritoireSelectors.selectOneSocieteTerritoire(schema, idSocieteTerritoire)))
      .pipe(
        switchMap(({ societeTerritoire }: { societeTerritoire: SocieteTerritoire }) => {
          return this.getReady(schema).pipe(map(() => societeTerritoire));
        })
      );
  }

  public upsertOneSocieteTerritoire(
    societeTerritoire: Partial<SocieteTerritoire>,
    ids: SocieteTerritoireRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteTerritoire> {
    this.store$.dispatch(SocieteTerritoireGeneratedActions.upsertOneSocieteTerritoire({ societeTerritoire, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteTerritoireGeneratedActions.normalizeManySocieteTerritoiresAfterUpsert,
        SocieteTerritoireGeneratedActions.societeTerritoiresFailure,
        true
      );
    }
  }

  public deleteOneSocieteTerritoire(
    idSocieteTerritoire: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoire({ idSocieteTerritoire }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoireSuccess,
        SocieteTerritoireGeneratedActions.societeTerritoiresFailure
      );
    }
  }
}
