import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgInterventionPatrimoineApiService } from './prog-intervention-patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgInterventionPatrimoineApiService extends GeneratedProgInterventionPatrimoineApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
