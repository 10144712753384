import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { EspaceRelationsIds } from '@get/store/ids-interfaces';

export const EspaceGeneratedActions = createActionGroup({
  source: 'Espace Generated',
  events: {
    'Upsert One Espace': props<{ espace: Partial<Espace>; ids?: EspaceRelationsIds; }>(),
    'Upsert Many Espaces': props<{ espaces: Partial<Espace>[]; ids?: EspaceRelationsIds; }>(),
    'Upsert Many Espaces Success': props<{ espaces: EspaceEntityState[] }>(),
    'Delete One Espace Success': props<{ idEspace: number }>(),
    'Normalize Many Espaces After Upsert': props<{ espaces: EspaceEntityState[] }>(),
    'Espaces Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Espaces': emptyProps(),
    'Add Many Composant Success': props<{ idEspace: number; idComposants: number[] }>(),
    'Delete Many Composant Success': props<{ idComposants: number[]; idEspaces: number[] }>(),
    'Add Patrimoine Success': props<{ idEspace: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idEspaces: number[] }>(),
    'Add Societe Espace Success': props<{ idEspace: number; idSocieteEspace: number }>(),
    'Delete Many Societe Espace Success': props<{ idSocieteEspaces: number[]; idEspaces: number[] }>()
  }
});
