import { createEntityAdapter } from '@ngrx/entity';
import { ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgBiblioInterventionEntityState>;

export const adapter = createEntityAdapter<ProgBiblioInterventionEntityState>({
  selectId: o => o.idProgBiblioIntervention
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progBiblioInterventionFeatureKey = 'progBiblioIntervention';
