import { RepositoryService } from '@get/services/repository';
import { Usage } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUsageApiService {
  constructor(protected repo: RepositoryService) {}

  public getUsages(params?: any): Observable<Usage[]> {
    return this.repo.getData<Usage[]>('usage', params);
  }

  public addUsage(usage: Partial<Usage>): Observable<Usage> {
    return this.repo.create<Usage>('usage', usage);
  }

  public updateUsage(usage: Partial<Usage>): Observable<Usage> {
    return this.repo.update('usage', usage);
  }

  public deleteUsage(idUsage: number): Observable<number> {
    return this.repo.delete('usage/' + +idUsage);
  }
}
