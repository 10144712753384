import { RepositoryService } from '@get/services/repository';
import { SocieteTerritoire } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteTerritoireApiService {
  constructor(protected repo: RepositoryService) {}

  public addSocieteTerritoire(societeTerritoire: Partial<SocieteTerritoire>): Observable<SocieteTerritoire> {
    return this.repo.create<SocieteTerritoire>('societe-territoire', societeTerritoire);
  }

  public updateSocieteTerritoire(societeTerritoire: Partial<SocieteTerritoire>): Observable<SocieteTerritoire> {
    return this.repo.update('societe-territoire', societeTerritoire);
  }

  public deleteSocieteTerritoire(idSocieteTerritoire: number): Observable<number> {
    return this.repo.delete('societe-territoire/' + +idSocieteTerritoire);
  }
}
