import { GridErrorProgComposantActions } from '@get/store/actions';
import { GridErrorProgComposantState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorProgComposantState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorProgComposantState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorProgComposantState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorProgComposantActions.addOneGridErrorProgComposant,
    (state: GridErrorProgComposantState.IState, { gridErrorProgComposant }) =>
      GridErrorProgComposantState.adapter.upsertMany([gridErrorProgComposant], setLoadingsState(state, false))
  ),
  on(
    GridErrorProgComposantActions.deleteOneGridErrorProgComposantSuccess,
    (state: GridErrorProgComposantState.IState, { idGridError }) =>
      GridErrorProgComposantState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorProgComposantState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorProgComposantState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
