import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgScenarioApiService } from '@get/store/api-services';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgScenario } from '@get/store/configs/normalization';
import { ProgScenarioSelectors } from '@get/store/selectors';
import { ProgScenarioRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgInterventionScenario } from '@get/api-interfaces';
import { ProgBiblioInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionScenario } from '@get/api-interfaces';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';

export function getDefaultAddProgScenarioActions(progScenario: ProgScenarioEntityState, ids?: ProgScenarioRelationsIds): Action[] {
  const actions: Action[] = [ProgScenarioGeneratedActions.normalizeManyProgScenariosAfterUpsert({ progScenarios: [progScenario] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgScenarioSuccess({
        idSociete: ids.societe,
        idProgScenarios: [progScenario.idProgScenario]
      })
    );
    actions.push(
      ProgScenarioGeneratedActions.addSocieteSuccess({
        idProgScenario: progScenario.idProgScenario,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progInterventionScenarios) {
    if (!Array.isArray(ids.progInterventionScenarios)) {
      actions.push(
        ProgInterventionScenarioGeneratedActions.upsertOneProgInterventionScenario({
          progInterventionScenario: {
            idProgScenario: progScenario.idProgScenario,
            idProgInterventionScenario: ids.progInterventionScenarios as number
          } as ProgInterventionScenario
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgInterventionScenarioSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgInterventionScenarios: [ids.progInterventionScenarios as number]
        })
      );
    } else {
      actions.push(
        ProgInterventionScenarioGeneratedActions.upsertManyProgInterventionScenarios({
          progInterventionScenarios: (ids.progInterventionScenarios as number[]).map(
            (idProgInterventionScenario: number) => ({
              idProgScenario: progScenario.idProgScenario,
              idProgInterventionScenario
            })
          ) as ProgInterventionScenario[]
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgInterventionScenarioSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgInterventionScenarios: ids.progInterventionScenarios as number[]
        })
      );
    }
  }

  if (ids?.progBiblioInterventionScenarios) {
    if (!Array.isArray(ids.progBiblioInterventionScenarios)) {
      actions.push(
        ProgBiblioInterventionScenarioGeneratedActions.upsertOneProgBiblioInterventionScenario({
          progBiblioInterventionScenario: {
            idProgScenario: progScenario.idProgScenario,
            idProgBiblioInterventionScenario: ids.progBiblioInterventionScenarios as number
          } as ProgBiblioInterventionScenario
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgBiblioInterventionScenarioSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgBiblioInterventionScenarios: [ids.progBiblioInterventionScenarios as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionScenarioGeneratedActions.upsertManyProgBiblioInterventionScenarios({
          progBiblioInterventionScenarios: (ids.progBiblioInterventionScenarios as number[]).map(
            (idProgBiblioInterventionScenario: number) => ({
              idProgScenario: progScenario.idProgScenario,
              idProgBiblioInterventionScenario
            })
          ) as ProgBiblioInterventionScenario[]
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgBiblioInterventionScenarioSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgBiblioInterventionScenarios: ids.progBiblioInterventionScenarios as number[]
        })
      );
    }
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idProgScenario: progScenario.idProgScenario,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention & any
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idProgScenario: progScenario.idProgScenario,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[] & any[]
        })
      );
      actions.push(
        ProgScenarioGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgScenario: progScenario.idProgScenario,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgScenarioActions(progScenario: ProgScenarioEntityState): Action[] {
  const actions: Action[] = [ProgScenarioGeneratedActions.deleteOneProgScenarioSuccess({ idProgScenario: progScenario.idProgScenario })];

  if (progScenario.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgScenarioSuccess({
        idProgScenarios: [progScenario.idProgScenario],
        idSocietes: [progScenario.societe as number]
      })
    );
  }

  if (progScenario.progInterventionScenarios) {
    actions.push(
      ProgInterventionScenarioGeneratedActions.deleteManyProgScenarioSuccess({
        idProgScenarios: [progScenario.idProgScenario],
        idProgInterventionScenarios: progScenario.progInterventionScenarios as number[]
      })
    );
  }

  if (progScenario.progBiblioInterventionScenarios) {
    actions.push(
      ProgBiblioInterventionScenarioGeneratedActions.deleteManyProgScenarioSuccess({
        idProgScenarios: [progScenario.idProgScenario],
        idProgBiblioInterventionScenarios: progScenario.progBiblioInterventionScenarios as number[]
      })
    );
  }

  if (progScenario.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManyProgScenarioSuccess({
        idProgScenarios: [progScenario.idProgScenario],
        idProgBiblioInterventions: progScenario.progBiblioInterventions as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgScenarioEffects {
  constructor(
    protected actions$: Actions,
    protected progScenarioApiService: ProgScenarioApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgScenarioGeneratedActions.upsertOneProgScenario),
      concatMap(
        ({
          progScenario,
          ids
        }: {
          progScenario: Partial<ProgScenario>;
          ids?: ProgScenarioRelationsIds;
        }) => {
          if (progScenario.idProgScenario) {
            return this.progScenarioApiService.updateProgScenario(progScenario).pipe(
              map((progScenarioReturned: ProgScenario) => {
                return ProgScenarioGeneratedActions.normalizeManyProgScenariosAfterUpsert({ progScenarios: [progScenarioReturned] });
              }),
              catchError(error => of(ProgScenarioGeneratedActions.progScenariosFailure({ error })))
            );
          } else {
            return this.progScenarioApiService.addProgScenario(progScenario).pipe(
              mergeMap((progScenarioReturned: ProgScenario) => getDefaultAddProgScenarioActions(progScenarioReturned, ids)),
              catchError(error => of(ProgScenarioGeneratedActions.progScenariosFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgScenario$ = createEffect(() => {
    const selectProgScenarioState$ = this.store$.select(ProgScenarioSelectors.selectProgScenarioState);
    return this.actions$.pipe(
      ofType(ProgScenarioGeneratedActions.deleteOneProgScenario),
      withLatestFrom(selectProgScenarioState$),
      concatMap(([{ idProgScenario }, state]) =>
        this.progScenarioApiService.deleteProgScenario(idProgScenario).pipe(
          mergeMap(_success => getDefaultDeleteProgScenarioActions(state.entities[idProgScenario] as ProgScenarioEntityState)),
          catchError(error => of(ProgScenarioGeneratedActions.progScenariosFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgScenariosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgScenarioGeneratedActions.normalizeManyProgScenariosAfterUpsert),
      concatMap(({ progScenarios }) => {
        const actions: Action[] = getActionsToNormalizeProgScenario(progScenarios, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgScenario] Normalization After Upsert Success')];
      })
    );
  });
}
