import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgComposantApiService } from '@get/store/api-services';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgComposant } from '@get/store/configs/normalization';
import { ProgComposantSelectors } from '@get/store/selectors';
import { ProgComposantRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';
import { ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfo } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';

export function getDefaultAddProgComposantActions(progComposant: ProgComposantEntityState, ids?: ProgComposantRelationsIds): Action[] {
  const actions: Action[] = [ProgComposantGeneratedActions.normalizeManyProgComposantsAfterUpsert({ progComposants: [progComposant] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgComposantSuccess({
        idSociete: ids.societe,
        idProgComposants: [progComposant.idProgComposant]
      })
    );
    actions.push(
      ProgComposantGeneratedActions.addSocieteSuccess({
        idProgComposant: progComposant.idProgComposant,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progComposantSousGroupe) {
    actions.push(
      ProgComposantSousGroupeGeneratedActions.addManyProgComposantSuccess({
        idProgComposantSousGroupe: ids.progComposantSousGroupe,
        idProgComposants: [progComposant.idProgComposant]
      })
    );
    actions.push(
      ProgComposantGeneratedActions.addProgComposantSousGroupeSuccess({
        idProgComposant: progComposant.idProgComposant,
        idProgComposantSousGroupe: ids.progComposantSousGroupe
      })
    );
  }

  if (ids?.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.addManyProgComposantSuccess({
        idSocieteComposant: ids.societeComposant,
        idProgComposants: [progComposant.idProgComposant]
      })
    );
    actions.push(
      ProgComposantGeneratedActions.addSocieteComposantSuccess({
        idProgComposant: progComposant.idProgComposant,
        idSocieteComposant: ids.societeComposant
      })
    );
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idProgComposant: progComposant.idProgComposant,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgComposant: progComposant.idProgComposant,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idProgComposant: progComposant.idProgComposant,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[]
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgComposant: progComposant.idProgComposant,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  if (ids?.progPatrimoineInfos) {
    if (!Array.isArray(ids.progPatrimoineInfos)) {
      actions.push(
        ProgPatrimoineInfoGeneratedActions.upsertOneProgPatrimoineInfo({
          progPatrimoineInfo: {
            idProgComposant: progComposant.idProgComposant,
            idProgPatrimoineInfo: ids.progPatrimoineInfos as number
          } as ProgPatrimoineInfo
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyProgPatrimoineInfoSuccess({
          idProgComposant: progComposant.idProgComposant,
          idProgPatrimoineInfos: [ids.progPatrimoineInfos as number]
        })
      );
    } else {
      actions.push(
        ProgPatrimoineInfoGeneratedActions.upsertManyProgPatrimoineInfos({
          progPatrimoineInfos: (ids.progPatrimoineInfos as number[]).map(
            (idProgPatrimoineInfo: number) => ({
              idProgComposant: progComposant.idProgComposant,
              idProgPatrimoineInfo
            })
          ) as ProgPatrimoineInfo[]
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyProgPatrimoineInfoSuccess({
          idProgComposant: progComposant.idProgComposant,
          idProgPatrimoineInfos: ids.progPatrimoineInfos as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idProgComposant: progComposant.idProgComposant,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyPatrimoineSuccess({
          idProgComposant: progComposant.idProgComposant,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idProgComposant: progComposant.idProgComposant,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        ProgComposantGeneratedActions.addManyPatrimoineSuccess({
          idProgComposant: progComposant.idProgComposant,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgComposantActions(progComposant: ProgComposantEntityState): Action[] {
  const actions: Action[] = [ProgComposantGeneratedActions.deleteOneProgComposantSuccess({ idProgComposant: progComposant.idProgComposant })];

  if (progComposant.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idSocietes: [progComposant.societe as number]
      })
    );
  }

  if (progComposant.progComposantSousGroupe) {
    actions.push(
      ProgComposantSousGroupeGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idProgComposantSousGroupes: [progComposant.progComposantSousGroupe as number]
      })
    );
  }

  if (progComposant.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idSocieteComposants: [progComposant.societeComposant as number]
      })
    );
  }

  if (progComposant.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idProgBiblioInterventions: progComposant.progBiblioInterventions as number[]
      })
    );
  }

  if (progComposant.progPatrimoineInfos) {
    actions.push(
      ProgPatrimoineInfoGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idProgPatrimoineInfos: progComposant.progPatrimoineInfos as number[]
      })
    );
  }

  if (progComposant.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyProgComposantSuccess({
        idProgComposants: [progComposant.idProgComposant],
        idPatrimoines: progComposant.patrimoines as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgComposantEffects {
  constructor(
    protected actions$: Actions,
    protected progComposantApiService: ProgComposantApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantGeneratedActions.upsertOneProgComposant),
      concatMap(
        ({
          progComposant,
          ids
        }: {
          progComposant: Partial<ProgComposant>;
          ids?: ProgComposantRelationsIds;
        }) => {
          if (progComposant.idProgComposant) {
            return this.progComposantApiService.updateProgComposant(progComposant).pipe(
              map((progComposantReturned: ProgComposant) => {
                return ProgComposantGeneratedActions.normalizeManyProgComposantsAfterUpsert({ progComposants: [progComposantReturned] });
              }),
              catchError(error => of(ProgComposantGeneratedActions.progComposantsFailure({ error })))
            );
          } else {
            return this.progComposantApiService.addProgComposant(progComposant).pipe(
              mergeMap((progComposantReturned: ProgComposant) => getDefaultAddProgComposantActions(progComposantReturned, ids)),
              catchError(error => of(ProgComposantGeneratedActions.progComposantsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgComposant$ = createEffect(() => {
    const selectProgComposantState$ = this.store$.select(ProgComposantSelectors.selectProgComposantState);
    return this.actions$.pipe(
      ofType(ProgComposantGeneratedActions.deleteOneProgComposant),
      withLatestFrom(selectProgComposantState$),
      concatMap(([{ idProgComposant }, state]) =>
        this.progComposantApiService.deleteProgComposant(idProgComposant).pipe(
          mergeMap(_success => getDefaultDeleteProgComposantActions(state.entities[idProgComposant] as ProgComposantEntityState)),
          catchError(error => of(ProgComposantGeneratedActions.progComposantsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgComposantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantGeneratedActions.normalizeManyProgComposantsAfterUpsert),
      concatMap(({ progComposants }) => {
        const actions: Action[] = getActionsToNormalizeProgComposant(progComposants, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgComposant] Normalization After Upsert Success')];
      })
    );
  });
}
