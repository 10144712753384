import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BoardState } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { BoardStateRelationsIds } from '@get/store/ids-interfaces';
import { BoardStateGeneratedActions } from '@get/store/actions';
import { BoardStateSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedBoardStateService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(BoardStateSelectors.selectIsLoadedBoardState));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(BoardStateSelectors.selectIsLoadingBoardState));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      BoardStateSelectors.selectIsReadyAndLoadedBoardState as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllBoardStates(schema: SelectSchema = {}): Observable<BoardState[]> {
    return this.store$.pipe(select(BoardStateSelectors.selectAllBoardStates(schema))).pipe(
      switchMap(({ boardStates }: { boardStates: BoardState[] }) => {
        return this.getReady(schema).pipe(map(() => boardStates));
      })
    );
  }

  public selectOneBoardState(
    idBoardState: number,
    schema: SelectSchema = {}
  ): Observable<BoardState> {
    return this.store$
      .pipe(select(BoardStateSelectors.selectOneBoardState(schema, idBoardState)))
      .pipe(
        switchMap(({ boardState }: { boardState: BoardState }) => {
          return this.getReady(schema).pipe(map(() => boardState));
        })
      );
  }

  public getManyBoardStates(
    params: any = {},
    getResult?: boolean
  ): void | Observable<BoardState[]> {
    this.store$.dispatch(BoardStateGeneratedActions.getManyBoardStates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert,
        BoardStateGeneratedActions.boardStatesFailure
      );
    }
  }

  public upsertOneBoardState(
    boardState: Partial<BoardState>,
    ids: BoardStateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<BoardState> {
    this.store$.dispatch(BoardStateGeneratedActions.upsertOneBoardState({ boardState, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateGeneratedActions.normalizeManyBoardStatesAfterUpsert,
        BoardStateGeneratedActions.boardStatesFailure,
        true
      );
    }
  }

  public deleteOneBoardState(
    idBoardState: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(BoardStateGeneratedActions.deleteOneBoardState({ idBoardState }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateGeneratedActions.deleteOneBoardStateSuccess,
        BoardStateGeneratedActions.boardStatesFailure
      );
    }
  }
}
