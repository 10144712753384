import { UsageState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { usageReducersGeneratedFunctions } from './usage-generated.reducer';

const usageReducersFunctions = [...usageReducersGeneratedFunctions];

const usageReducer = createReducer(UsageState.initialState, ...usageReducersFunctions);

export function reducer(state: UsageState.IState | undefined, action: Action) {
  return usageReducer(state, action);
}
