import { Injectable } from '@angular/core';
import { AnalyzeGlobalComposantsWithResult, Organisation } from '@get/api-interfaces';
import { MyDatabaseCollections } from '@get/interfaces';
import { DbService } from '@get/services/storage';
import { OrganisationActions } from '@get/store/actions';
import { OrganisationApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, first, tap } from 'rxjs';
import { GeneratedOrganisationService } from './organisation-generated.service';
import { SocieteService } from './societe.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends GeneratedOrganisationService {
  public currentOrganisation?: Organisation;

  constructor(
    store$: Store<AppState>,
    actions$: Actions,
    private readonly organisationApiService: OrganisationApiService,
    private readonly societeService: SocieteService,
    private readonly dbService: DbService
  ) {
    super(store$, actions$);
  }

  public getSocietes() {
    return this.currentOrganisation?.societes;
  }

  public setCurrentOrganisation(organisation: Organisation): void {
    if (organisation) {
      this.currentOrganisation = organisation;
      this.societeService.setCurrentSociete(organisation.societes[0]);
    }
  }

  public async getUserOrganisation(idUser: number, databaseCollections?: MyDatabaseCollections): Promise<void> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    this.organisationApiService
      .getUserOrganisation({ idUser })
      .pipe(
        first(),
        tap(async organisation => {
          await this.updateOrganisationIndexedDb(organisation, databaseCollections);
        })
      )
      .subscribe();
  }

  public getAnalyzeGlobalComposants(): Observable<AnalyzeGlobalComposantsWithResult[]> {
    return this.organisationApiService.getAnalyzeGlobalComposants();
  }

  public async updateOrganisationIndexedDb(
    organisation: Organisation,
    databaseCollections?: MyDatabaseCollections
  ): Promise<void> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    this.store$.dispatch(OrganisationActions.upsertOrganisationOnAppInit({ organisation }));
    this.dbService.updateIndexedDb(databaseCollections, 'organisations', [organisation], 'idOrganisation');
  }
}
