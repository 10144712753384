import { ProgInterventionState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progInterventionReducersGeneratedFunctions } from './prog-intervention-generated.reducer';

const progInterventionReducersFunctions = [...progInterventionReducersGeneratedFunctions];

const progInterventionReducer = createReducer(ProgInterventionState.initialState, ...progInterventionReducersFunctions);

export function reducer(state: ProgInterventionState.IState | undefined, action: Action) {
  return progInterventionReducer(state, action);
}
