import { createEntityAdapter } from '@ngrx/entity';
import { CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<CaracteristiqueTemplateEntityState>;

export const adapter = createEntityAdapter<CaracteristiqueTemplateEntityState>({
  selectId: o => o.idCaracteristiqueTemplate
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const caracteristiqueTemplateFeatureKey = 'caracteristiqueTemplate';
