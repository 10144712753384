import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgComposantGroupe } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgComposantGroupeRelationsIds } from '@get/store/ids-interfaces';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantGroupeSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgComposantGroupeService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantGroupeSelectors.selectIsLoadedProgComposantGroupe));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantGroupeSelectors.selectIsLoadingProgComposantGroupe));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgComposantGroupeSelectors.selectIsReadyAndLoadedProgComposantGroupe as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgComposantGroupes(schema: SelectSchema = {}): Observable<ProgComposantGroupe[]> {
    return this.store$.pipe(select(ProgComposantGroupeSelectors.selectAllProgComposantGroupes(schema))).pipe(
      switchMap(({ progComposantGroupes }: { progComposantGroupes: ProgComposantGroupe[] }) => {
        return this.getReady(schema).pipe(map(() => progComposantGroupes));
      })
    );
  }

  public selectOneProgComposantGroupe(
    idProgComposantGroupe: number,
    schema: SelectSchema = {}
  ): Observable<ProgComposantGroupe> {
    return this.store$
      .pipe(select(ProgComposantGroupeSelectors.selectOneProgComposantGroupe(schema, idProgComposantGroupe)))
      .pipe(
        switchMap(({ progComposantGroupe }: { progComposantGroupe: ProgComposantGroupe }) => {
          return this.getReady(schema).pipe(map(() => progComposantGroupe));
        })
      );
  }

  public upsertOneProgComposantGroupe(
    progComposantGroupe: Partial<ProgComposantGroupe>,
    ids: ProgComposantGroupeRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgComposantGroupe> {
    this.store$.dispatch(ProgComposantGroupeGeneratedActions.upsertOneProgComposantGroupe({ progComposantGroupe, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantGroupeGeneratedActions.normalizeManyProgComposantGroupesAfterUpsert,
        ProgComposantGroupeGeneratedActions.progComposantGroupesFailure,
        true
      );
    }
  }

  public deleteOneProgComposantGroupe(
    idProgComposantGroupe: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupe({ idProgComposantGroupe }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantGroupeGeneratedActions.deleteOneProgComposantGroupeSuccess,
        ProgComposantGroupeGeneratedActions.progComposantGroupesFailure
      );
    }
  }
}
