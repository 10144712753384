import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CampagneUserState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const campagneUserRelations: string[] = ['users','campagnes',];

export const { selectEntities, selectAll } = CampagneUserState.adapter.getSelectors();

export const selectCampagneUserState = createFeatureSelector<CampagneUserState.IState>(CampagneUserState.campagneUserFeatureKey);

export const selectIsLoadedCampagneUser = createSelector(
  selectCampagneUserState,
  (state: CampagneUserState.IState) => state.isLoaded
);

export const selectIsLoadingCampagneUser = createSelector(
  selectCampagneUserState,
  (state: CampagneUserState.IState) => state.isLoading
);

export const selectIsReadyCampagneUser = createSelector(
  selectCampagneUserState,
  (state: CampagneUserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCampagneUser = createSelector(
  selectCampagneUserState,
  (state: CampagneUserState.IState) => state.isLoaded && !state.isLoading
);

export const selectCampagneUsersEntities = createSelector(selectCampagneUserState, selectEntities);

export const selectCampagneUsersArray = createSelector(selectCampagneUserState, selectAll);

const campagneUsersInObject = (campagneUsers: Dictionary<CampagneUserEntityState>) => ({ campagneUsers })

const selectCampagneUsersEntitiesDictionary = createSelector(selectCampagneUsersEntities, campagneUsersInObject);

const selectAllCampagneUsersObject = createSelector(selectCampagneUsersEntities, campagneUsers => {
  return hydrateAll({ campagneUsers });
});

const selectOneCampagneUserDictionary = (idCampagneUser : number) =>
  createSelector(selectCampagneUsersEntities, campagneUsers => ({
    campagneUsers: { [idCampagneUser]: campagneUsers[idCampagneUser] }
  }));

const selectOneCampagneUserDictionaryWithoutChild = (idCampagneUser : number) =>
  createSelector(selectCampagneUsersEntities, campagneUsers => ({
    campagneUser: campagneUsers[idCampagneUser]
  }));

const selectAllCampagneUsersSelectors: Dictionary<Selector> = {};
export function selectAllCampagneUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CampagneUser>(
      schema,
      selectAllCampagneUsersSelectors,
      selectCampagneUsersEntitiesDictionary,
      getRelationSelectors,
      campagneUserRelations,
      hydrateAll,
      'campagneUser'
    );
  } else {
    return selectAllCampagneUsersObject;
  }
}

export function selectAllCampagneUsersDictionary(
  schema: SelectSchema = {},
  customKey = 'campagneUsers'
): Selector {
  return createSelector(selectAllCampagneUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<CampagneUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.campagneUsers.length; i++) {
      res[customKey][result.campagneUsers[i].idCampagneUser] = result.campagneUsers[i];
    }
    return res;
  });
}

export function selectOneCampagneUser(
  schema: SelectSchema = {},
  idCampagneUser: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCampagneUserDictionary(idCampagneUser)];
  selectors.push(...getRelationSelectors(schema, campagneUserRelations, 'campagneUser'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCampagneUserDictionaryWithoutChild(idCampagneUser);
  }
}

interface hydrateArgs {
  campagneUsers: Dictionary<CampagneUserEntityState>;
  users?: Dictionary<UserEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { campagneUsers: (CampagneUser | null)[] } {
  const {
    campagneUsers,
    users,
    campagnes
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    campagneUsers: Object.keys(campagneUsers).map(idCampagneUser =>
      hydrate(
        campagneUsers[idCampagneUser] as CampagneUserEntityState,
        users,
        campagnes
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { campagneUser: CampagneUserEntityState | null } {
  const {
    campagneUsers,
    users,
    campagnes
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const campagneUser = Object.values(campagneUsers)[0];
  return {
    campagneUser: hydrate(
      campagneUser as CampagneUserEntityState,
      users,
      campagnes
    )
  };
}

function hydrate(
  campagneUser: CampagneUserEntityState,
  userEntities?: Dictionary<UserEntityState>,
  campagneEntities?: Dictionary<CampagneEntityState>,
): CampagneUser | null {
  if (!campagneUser) {
    return null;
  }

  const campagneUserHydrated: CampagneUserEntityState = { ...campagneUser };
  if (userEntities) {
    campagneUserHydrated.user = userEntities[campagneUser.user as number] as User;
  } else {
    delete campagneUserHydrated.user;
  }
  if (campagneEntities) {
    campagneUserHydrated.campagne = campagneEntities[campagneUser.campagne as number] as Campagne;
  } else {
    delete campagneUserHydrated.campagne;
  }

  return campagneUserHydrated as CampagneUser;
}
