import { ProgInterventionScenarioState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progInterventionScenarioReducersGeneratedFunctions } from './prog-intervention-scenario-generated.reducer';

const progInterventionScenarioReducersFunctions = [...progInterventionScenarioReducersGeneratedFunctions];

const progInterventionScenarioReducer = createReducer(
  ProgInterventionScenarioState.initialState,
  ...progInterventionScenarioReducersFunctions
);

export function reducer(state: ProgInterventionScenarioState.IState | undefined, action: Action) {
  return progInterventionScenarioReducer(state, action);
}
