import { BoardStateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { boardStateReducersGeneratedFunctions } from './board-state-generated.reducer';

const boardStateReducersFunctions = [...boardStateReducersGeneratedFunctions];

const boardStateReducer = createReducer(BoardStateState.initialState, ...boardStateReducersFunctions);

export function reducer(state: BoardStateState.IState | undefined, action: Action) {
  return boardStateReducer(state, action);
}
