import { createEntityAdapter } from '@ngrx/entity';
import { ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgInterventionFamilleEntityState>;

export const adapter = createEntityAdapter<ProgInterventionFamilleEntityState>({
  selectId: o => o.idProgInterventionFamille
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progInterventionFamilleFeatureKey = 'progInterventionFamille';
