import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { ValeurFichierRelationsIds } from '@get/store/ids-interfaces';

export const ValeurFichierGeneratedActions = createActionGroup({
  source: 'Valeur Fichier Generated',
  events: {
    'Upsert One Valeur Fichier': props<{ valeurFichier: Partial<ValeurFichier>; ids?: ValeurFichierRelationsIds; }>(),
    'Upsert Many Valeur Fichiers': props<{ valeurFichiers: Partial<ValeurFichier>[]; ids?: ValeurFichierRelationsIds; }>(),
    'Upsert Many Valeur Fichiers Success': props<{ valeurFichiers: ValeurFichierEntityState[] }>(),
    'Delete One Valeur Fichier Success': props<{ idValeurFichier: number }>(),
    'Normalize Many Valeur Fichiers After Upsert': props<{ valeurFichiers: ValeurFichierEntityState[] }>(),
    'Valeur Fichiers Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeur Fichiers': emptyProps(),
    'Add Valeur Success': props<{ idValeurFichier: number; idValeur: number }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idValeurFichiers: number[] }>(),
    'Add Fichier Success': props<{ idValeurFichier: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idValeurFichiers: number[] }>()
  }
});
