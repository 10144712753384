import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ComposantEntityState } from '@get/api-interfaces';
import { ComposantApiService } from '@get/store/api-services';
import { ComposantGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeComposant } from '@get/store/configs/normalization';
import { ComposantSelectors } from '@get/store/selectors';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';
import { EspaceGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ValeurComposant } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';

export function getDefaultAddComposantActions(composant: ComposantEntityState, ids?: ComposantRelationsIds): Action[] {
  const actions: Action[] = [ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [composant] })];

  if (ids?.espace) {
    actions.push(
      EspaceGeneratedActions.addManyComposantSuccess({
        idEspace: ids.espace,
        idComposants: [composant.idComposant]
      })
    );
    actions.push(
      ComposantGeneratedActions.addEspaceSuccess({
        idComposant: composant.idComposant,
        idEspace: ids.espace
      })
    );
  }

  if (ids?.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.addManyComposantSuccess({
        idSocieteComposant: ids.societeComposant,
        idComposants: [composant.idComposant]
      })
    );
    actions.push(
      ComposantGeneratedActions.addSocieteComposantSuccess({
        idComposant: composant.idComposant,
        idSocieteComposant: ids.societeComposant
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyComposantSuccess({
        idUser: ids.user,
        idComposants: [composant.idComposant]
      })
    );
    actions.push(
      ComposantGeneratedActions.addUserSuccess({
        idComposant: composant.idComposant,
        idUser: ids.user
      })
    );
  }

  if (ids?.valeurComposants) {
    if (!Array.isArray(ids.valeurComposants)) {
      actions.push(
        ValeurComposantGeneratedActions.upsertOneValeurComposant({
          valeurComposant: {
            idComposant: composant.idComposant,
            idValeurComposant: ids.valeurComposants as number
          } as ValeurComposant
        })
      );
      actions.push(
        ComposantGeneratedActions.addManyValeurComposantSuccess({
          idComposant: composant.idComposant,
          idValeurComposants: [ids.valeurComposants as number]
        })
      );
    } else {
      actions.push(
        ValeurComposantGeneratedActions.upsertManyValeurComposants({
          valeurComposants: (ids.valeurComposants as number[]).map(
            (idValeurComposant: number) => ({
              idComposant: composant.idComposant,
              idValeurComposant
            })
          ) as ValeurComposant[]
        })
      );
      actions.push(
        ComposantGeneratedActions.addManyValeurComposantSuccess({
          idComposant: composant.idComposant,
          idValeurComposants: ids.valeurComposants as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idComposant: composant.idComposant,
            idValeur: ids.valeurs as number
          } as Valeur & any
        })
      );
      actions.push(
        ComposantGeneratedActions.addManyValeurSuccess({
          idComposant: composant.idComposant,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idComposant: composant.idComposant,
              idValeur
            })
          ) as Valeur[] & any[]
        })
      );
      actions.push(
        ComposantGeneratedActions.addManyValeurSuccess({
          idComposant: composant.idComposant,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteComposantActions(composant: ComposantEntityState): Action[] {
  const actions: Action[] = [ComposantGeneratedActions.deleteOneComposantSuccess({ idComposant: composant.idComposant })];

  if (composant.espace) {
    actions.push(
      EspaceGeneratedActions.deleteManyComposantSuccess({
        idComposants: [composant.idComposant],
        idEspaces: [composant.espace as number]
      })
    );
  }

  if (composant.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyComposantSuccess({
        idComposants: [composant.idComposant],
        idSocieteComposants: [composant.societeComposant as number]
      })
    );
  }

  if (composant.user) {
    actions.push(
      UserGeneratedActions.deleteManyComposantSuccess({
        idComposants: [composant.idComposant],
        idUsers: [composant.user as number]
      })
    );
  }

  if (composant.valeurComposants) {
    actions.push(
      ValeurComposantGeneratedActions.deleteManyComposantSuccess({
        idComposants: [composant.idComposant],
        idValeurComposants: composant.valeurComposants as number[]
      })
    );
  }

  if (composant.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyComposantSuccess({
        idComposants: [composant.idComposant],
        idValeurs: composant.valeurs as number[]
      })
    );
  }

  return actions;
}

export class GeneratedComposantEffects {
  constructor(
    protected actions$: Actions,
    protected composantApiService: ComposantApiService,
    protected store$: Store<AppState>
  ) {}

  deleteOneComposant$ = createEffect(() => {
    const selectComposantState$ = this.store$.select(ComposantSelectors.selectComposantState);
    return this.actions$.pipe(
      ofType(ComposantGeneratedActions.deleteOneComposant),
      withLatestFrom(selectComposantState$),
      concatMap(([{ idComposant }, state]) =>
        this.composantApiService.deleteComposant(idComposant).pipe(
          mergeMap(_success => getDefaultDeleteComposantActions(state.entities[idComposant] as ComposantEntityState)),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  normalizeManyComposantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantGeneratedActions.normalizeManyComposantsAfterUpsert),
      concatMap(({ composants }) => {
        const actions: Action[] = getActionsToNormalizeComposant(composants, StoreActionType.upsert);
        return [getMultiAction(actions, '[Composant] Normalization After Upsert Success')];
      })
    );
  });
}
