import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteConfigAnalyseSyntheseSelectors } from '@get/store/selectors';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteConfigAnalyseSyntheseService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectIsLoadedSocieteConfigAnalyseSynthese));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectIsLoadingSocieteConfigAnalyseSynthese));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteConfigAnalyseSyntheseSelectors.selectIsReadyAndLoadedSocieteConfigAnalyseSynthese as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteConfigAnalyseSyntheses(schema: SelectSchema = {}): Observable<SocieteConfigAnalyseSynthese[]> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectAllSocieteConfigAnalyseSyntheses(schema))).pipe(
      switchMap(({ societeConfigAnalyseSyntheses }: { societeConfigAnalyseSyntheses: SocieteConfigAnalyseSynthese[] }) => {
        return this.getReady(schema).pipe(map(() => societeConfigAnalyseSyntheses));
      })
    );
  }

  public selectOneSocieteConfigAnalyseSynthese(
    idSocieteConfigAnalyseSynthese: number,
    schema: SelectSchema = {}
  ): Observable<SocieteConfigAnalyseSynthese> {
    return this.store$
      .pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectOneSocieteConfigAnalyseSynthese(schema, idSocieteConfigAnalyseSynthese)))
      .pipe(
        switchMap(({ societeConfigAnalyseSynthese }: { societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese }) => {
          return this.getReady(schema).pipe(map(() => societeConfigAnalyseSynthese));
        })
      );
  }
}
