import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteComposant } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteComposantRelationsIds } from '@get/store/ids-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposantSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocieteComposantService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteComposantSelectors.selectIsLoadedSocieteComposant));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteComposantSelectors.selectIsLoadingSocieteComposant));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteComposantSelectors.selectIsReadyAndLoadedSocieteComposant as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteComposants(schema: SelectSchema = {}): Observable<SocieteComposant[]> {
    return this.store$.pipe(select(SocieteComposantSelectors.selectAllSocieteComposants(schema))).pipe(
      switchMap(({ societeComposants }: { societeComposants: SocieteComposant[] }) => {
        return this.getReady(schema).pipe(map(() => societeComposants));
      })
    );
  }

  public selectOneSocieteComposant(
    idSocieteComposant: number,
    schema: SelectSchema = {}
  ): Observable<SocieteComposant> {
    return this.store$
      .pipe(select(SocieteComposantSelectors.selectOneSocieteComposant(schema, idSocieteComposant)))
      .pipe(
        switchMap(({ societeComposant }: { societeComposant: SocieteComposant }) => {
          return this.getReady(schema).pipe(map(() => societeComposant));
        })
      );
  }

  public getManySocieteComposants(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteComposant[]> {
    this.store$.dispatch(SocieteComposantGeneratedActions.getManySocieteComposants({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert,
        SocieteComposantGeneratedActions.societeComposantsFailure
      );
    }
  }

  public upsertOneSocieteComposant(
    societeComposant: Partial<SocieteComposant>,
    ids: SocieteComposantRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteComposant> {
    this.store$.dispatch(SocieteComposantGeneratedActions.upsertOneSocieteComposant({ societeComposant, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert,
        SocieteComposantGeneratedActions.societeComposantsFailure,
        true
      );
    }
  }

  public deleteOneSocieteComposant(
    idSocieteComposant: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteComposantGeneratedActions.deleteOneSocieteComposant({ idSocieteComposant }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantGeneratedActions.deleteOneSocieteComposantSuccess,
        SocieteComposantGeneratedActions.societeComposantsFailure
      );
    }
  }
}
