import { RepositoryService } from '@get/services/repository';
import { ProgEtatTechnique } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgEtatTechniqueApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgEtatTechnique(progEtatTechnique: Partial<ProgEtatTechnique>): Observable<ProgEtatTechnique> {
    return this.repo.create<ProgEtatTechnique>('prog-etat-technique', progEtatTechnique);
  }

  public updateProgEtatTechnique(progEtatTechnique: Partial<ProgEtatTechnique>): Observable<ProgEtatTechnique> {
    return this.repo.update('prog-etat-technique', progEtatTechnique);
  }

  public deleteProgEtatTechnique(idProgEtatTechnique: number): Observable<number> {
    return this.repo.delete('prog-etat-technique/' + +idProgEtatTechnique);
  }
}
