import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateApiService } from '@get/store/api-services';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCaracteristiqueChoixTemplate } from '@get/store/configs/normalization';
import { CaracteristiqueChoixTemplateRelationsIds } from '@get/store/ids-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';

export function getDefaultAddCaracteristiqueChoixTemplateActions(caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState, ids?: CaracteristiqueChoixTemplateRelationsIds): Action[] {
  const actions: Action[] = [CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert({ caracteristiqueChoixTemplates: [caracteristiqueChoixTemplate] })];

  if (ids?.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate,
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate]
      })
    );
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.addCaracteristiqueTemplateSuccess({
        idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
        idFichier: ids.fichier,
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate]
      })
    );
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.addFichierSuccess({
        idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.societeCaracteristiqueChoices) {
    if (!Array.isArray(ids.societeCaracteristiqueChoices)) {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix({
          societeCaracteristiqueChoix: {
            idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
            idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoices as number
          } as SocieteCaracteristiqueChoix
        })
      );
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
          idSocieteCaracteristiqueChoices: [ids.societeCaracteristiqueChoices as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoices({
          societeCaracteristiqueChoices: (ids.societeCaracteristiqueChoices as number[]).map(
            (idSocieteCaracteristiqueChoix: number) => ({
              idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
              idSocieteCaracteristiqueChoix
            })
          ) as SocieteCaracteristiqueChoix[]
        })
      );
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
          idSocieteCaracteristiqueChoices: ids.societeCaracteristiqueChoices as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCaracteristiqueChoixTemplateActions(caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState): Action[] {
  const actions: Action[] = [CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplateSuccess({ idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate })];

  if (caracteristiqueChoixTemplate.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idCaracteristiqueTemplates: [caracteristiqueChoixTemplate.caracteristiqueTemplate as number]
      })
    );
  }

  if (caracteristiqueChoixTemplate.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idFichiers: [caracteristiqueChoixTemplate.fichier as number]
      })
    );
  }

  if (caracteristiqueChoixTemplate.societeCaracteristiqueChoices) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idSocieteCaracteristiqueChoices: caracteristiqueChoixTemplate.societeCaracteristiqueChoices as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCaracteristiqueChoixTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected caracteristiqueChoixTemplateApiService: CaracteristiqueChoixTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyCaracteristiqueChoixTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert),
      concatMap(({ caracteristiqueChoixTemplates }) => {
        const actions: Action[] = getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[CaracteristiqueChoixTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
