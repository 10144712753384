import { Injectable } from '@angular/core';
import { ProgIntervention, ProgPatrimoineInfo } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedProgPatrimoineInfoApiService } from './prog-patrimoine-info-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgPatrimoineInfoApiService extends GeneratedProgPatrimoineInfoApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateOneProgPatrimoineInfo(progPatrimoineInfo: Partial<ProgPatrimoineInfo>): Observable<{
    progPatrimoineInfoResponse: ProgPatrimoineInfo;
    progInterventions: ProgIntervention[];
  }> {
    return this.repo.update('prog-patrimoine-info', progPatrimoineInfo);
  }
}
