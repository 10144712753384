import { Injectable } from '@angular/core';
import { CopyProgComposantParams } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { firstValueFrom, Observable } from 'rxjs';
import { GeneratedProgComposantApiService } from './prog-composant-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgComposantApiService extends GeneratedProgComposantApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getProgComposantHavingIntervention(params: { idSociete: number }): Promise<number[]> {
    return firstValueFrom(this.repo.getData<number[]>('prog-composant/having-intervention', params));
  }

  public duplicateProgComposantAndBiblios(params: CopyProgComposantParams): Observable<string[]> {
    return this.repo.create('prog-composant/duplicate-prog-composant-and-biblios', params);
  }
}
