import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CampagneGeneratedActions } from '@get/store/actions';
import { CampagneState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CampagneEntityState } from '@get/api-interfaces';

export const campagneReducersGeneratedFunctions: ReducerTypes<CampagneState.IState, readonly ActionCreator[]>[] = [
  on(CampagneGeneratedActions.getOneCampagne, (state: CampagneState.IState) => setLoadingsState(state, true)),
  on(CampagneGeneratedActions.getManyCampagnes, (state: CampagneState.IState) => setLoadingsState(state, true)),
  on(CampagneGeneratedActions.upsertOneCampagne, (state: CampagneState.IState) => setLoadingsState(state, true)),

  on(CampagneGeneratedActions.upsertManyCampagnesSuccess, (state: CampagneState.IState, { campagnes }) =>
    CampagneState.adapter.upsertMany(campagnes, setLoadingsState(state, false))
  ),
  on(CampagneGeneratedActions.deleteOneCampagne, (state: CampagneState.IState) => setLoadingsState(state, true)),
  on(CampagneGeneratedActions.deleteOneCampagneSuccess, (state: CampagneState.IState, { idCampagne }) =>
    CampagneState.adapter.removeOne(idCampagne, setLoadingsState(state, false))
  ),

  on(CampagneGeneratedActions.clearCampagnes, () => CampagneState.initialState),

  on(
    CampagneGeneratedActions.addManyPatrimoineSuccess,
    (state: CampagneState.IState, { idCampagne, idPatrimoines }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const patrimoines = (state.entities[idCampagne]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManyPatrimoineSuccess,
    (state: CampagneState.IState, { idPatrimoines, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.patrimoines) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              patrimoines: (state.entities[idCampagne]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addManyCampagneUserSuccess,
    (state: CampagneState.IState, { idCampagne, idCampagneUsers }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const campagneUsers = (state.entities[idCampagne]?.campagneUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            campagneUsers: campagneUsers.concat(
              idCampagneUsers.filter(id => campagneUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManyCampagneUserSuccess,
    (state: CampagneState.IState, { idCampagneUsers, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.campagneUsers) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              campagneUsers: (state.entities[idCampagne]?.campagneUsers as number[])?.filter(
                (idCampagneUser: number) => !idCampagneUsers.some((id: number) => id === idCampagneUser)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addManyUserSuccess,
    (state: CampagneState.IState, { idCampagne, idUsers }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const users = (state.entities[idCampagne]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            users: users.concat(
              idUsers.filter(id => users.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManyUserSuccess,
    (state: CampagneState.IState, { idUsers, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.users) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              users: (state.entities[idCampagne]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: CampagneState.IState, { idCampagne, idSocieteCaracteristiques }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idCampagne]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: CampagneState.IState, { idSocieteCaracteristiques, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.societeCaracteristiques) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              societeCaracteristiques: (state.entities[idCampagne]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addManyCampagnePatrimoineSuccess,
    (state: CampagneState.IState, { idCampagne, idCampagnePatrimoines }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const campagnePatrimoines = (state.entities[idCampagne]?.campagnePatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            campagnePatrimoines: campagnePatrimoines.concat(
              idCampagnePatrimoines.filter(id => campagnePatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManyCampagnePatrimoineSuccess,
    (state: CampagneState.IState, { idCampagnePatrimoines, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.campagnePatrimoines) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              campagnePatrimoines: (state.entities[idCampagne]?.campagnePatrimoines as number[])?.filter(
                (idCampagnePatrimoine: number) => !idCampagnePatrimoines.some((id: number) => id === idCampagnePatrimoine)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess,
    (state: CampagneState.IState, { idCampagne, idCampagneSocieteCaracteristiques }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      const campagneSocieteCaracteristiques = (state.entities[idCampagne]?.campagneSocieteCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            campagneSocieteCaracteristiques: campagneSocieteCaracteristiques.concat(
              idCampagneSocieteCaracteristiques.filter(id => campagneSocieteCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManyCampagneSocieteCaracteristiqueSuccess,
    (state: CampagneState.IState, { idCampagneSocieteCaracteristiques, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.campagneSocieteCaracteristiques) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              campagneSocieteCaracteristiques: (state.entities[idCampagne]?.campagneSocieteCaracteristiques as number[])?.filter(
                (idCampagneSocieteCaracteristique: number) => !idCampagneSocieteCaracteristiques.some((id: number) => id === idCampagneSocieteCaracteristique)
              )
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.addSocieteSuccess,
    (state: CampagneState.IState, { idCampagne, idSociete }) => {
      if (!state.entities[idCampagne]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagne]: {
            ...state.entities[idCampagne],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    CampagneGeneratedActions.deleteManySocieteSuccess,
    (state: CampagneState.IState, { idSocietes, idCampagnes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnes.reduce((entities, idCampagne) => {
            if (!state.entities[idCampagne]?.societe) {
              return entities;
            }
            entities[idCampagne] = {
              ...state.entities[idCampagne],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idCampagne]?.societe
              )
                ? undefined
                : state.entities[idCampagne]?.societe
            } as CampagneEntityState;
            return entities;
          }, {} as Dictionary<CampagneEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CampagneState.IState,
  isLoading: boolean,
  isLoaded = true
): CampagneState.IState {
  return { ...state, isLoaded, isLoading };
}
