import { createEntityAdapter } from '@ngrx/entity';
import { ValeurComposantEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ValeurComposantEntityState>;

export const adapter = createEntityAdapter<ValeurComposantEntityState>({
  selectId: o => o.idValeurComposant
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const valeurComposantFeatureKey = 'valeurComposant';
