import { ProgUniteState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progUniteReducersGeneratedFunctions } from './prog-unite-generated.reducer';

const progUniteReducersFunctions = [...progUniteReducersGeneratedFunctions];

const progUniteReducer = createReducer(ProgUniteState.initialState, ...progUniteReducersFunctions);

export function reducer(state: ProgUniteState.IState | undefined, action: Action) {
  return progUniteReducer(state, action);
}
