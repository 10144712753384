import { SocieteCaracteristiqueToHandle } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const SocieteCaracteristiqueActions = createActionGroup({
  source: 'Societe Caracteristique',
  events: {
    'Handle Many Societe Caracteristique Composants': props<SocieteCaracteristiqueToHandle>(),
    'Handle Many Societe Caracteristique Patrimoines': props<SocieteCaracteristiqueToHandle>()
  }
});
