import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { UserSocieteProfilState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserSocieteProfilEntityState } from '@get/api-interfaces';

export const userSocieteProfilReducersGeneratedFunctions: ReducerTypes<UserSocieteProfilState.IState, readonly ActionCreator[]>[] = [
  on(UserSocieteProfilGeneratedActions.upsertOneUserSocieteProfil, (state: UserSocieteProfilState.IState) => setLoadingsState(state, true)),

  on(UserSocieteProfilGeneratedActions.upsertManyUserSocieteProfilsSuccess, (state: UserSocieteProfilState.IState, { userSocieteProfils }) =>
    UserSocieteProfilState.adapter.upsertMany(userSocieteProfils, setLoadingsState(state, false))
  ),
  on(UserSocieteProfilGeneratedActions.deleteOneUserSocieteProfilSuccess, (state: UserSocieteProfilState.IState, { idUserSocieteProfil }) =>
    UserSocieteProfilState.adapter.removeOne(idUserSocieteProfil, setLoadingsState(state, false))
  ),

  on(UserSocieteProfilGeneratedActions.clearUserSocieteProfils, () => UserSocieteProfilState.initialState),

  on(
    UserSocieteProfilGeneratedActions.addUserSuccess,
    (state: UserSocieteProfilState.IState, { idUserSocieteProfil, idUser }) => {
      if (!state.entities[idUserSocieteProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserSocieteProfil]: {
            ...state.entities[idUserSocieteProfil],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    UserSocieteProfilGeneratedActions.deleteManyUserSuccess,
    (state: UserSocieteProfilState.IState, { idUsers, idUserSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserSocieteProfils.reduce((entities, idUserSocieteProfil) => {
            if (!state.entities[idUserSocieteProfil]?.user) {
              return entities;
            }
            entities[idUserSocieteProfil] = {
              ...state.entities[idUserSocieteProfil],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idUserSocieteProfil]?.user
              )
                ? undefined
                : state.entities[idUserSocieteProfil]?.user
            } as UserSocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<UserSocieteProfilEntityState>)
        }
      };
    }
  ),

  on(
    UserSocieteProfilGeneratedActions.addSocieteProfilSuccess,
    (state: UserSocieteProfilState.IState, { idUserSocieteProfil, idSocieteProfil }) => {
      if (!state.entities[idUserSocieteProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserSocieteProfil]: {
            ...state.entities[idUserSocieteProfil],
            societeProfil: idSocieteProfil
          }
        }
      };
    }
  ),

  on(
    UserSocieteProfilGeneratedActions.deleteManySocieteProfilSuccess,
    (state: UserSocieteProfilState.IState, { idSocieteProfils, idUserSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserSocieteProfils.reduce((entities, idUserSocieteProfil) => {
            if (!state.entities[idUserSocieteProfil]?.societeProfil) {
              return entities;
            }
            entities[idUserSocieteProfil] = {
              ...state.entities[idUserSocieteProfil],
              societeProfil: idSocieteProfils.some(
                (idSocieteProfil: number) => idSocieteProfil === state.entities[idUserSocieteProfil]?.societeProfil
              )
                ? undefined
                : state.entities[idUserSocieteProfil]?.societeProfil
            } as UserSocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<UserSocieteProfilEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UserSocieteProfilState.IState,
  isLoading: boolean,
  isLoaded = true
): UserSocieteProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
