import { Injectable } from '@angular/core';
import {
  Composant,
  ComposantAttendu,
  DynamicType,
  PatrimoineWithChildren,
  SocieteCaracteristique,
  SocieteComposant,
  SocieteEspace,
  SocietePatrimoineHierarchie
} from '@get/api-interfaces';
import { filterChildrenWithExistingComposantMassDuplication, patrimoineWithTitleInfos } from '@get/utils';
import { compareStrings, transformAncestorsArrayToTree, transformArrayToObject } from '@utils';
import { DbService } from './db.service';
import { PatrimoineDbService } from './patrimoine.db.service';

@Injectable({ providedIn: 'root' })
export class GatheringDataDbService {
  constructor(private dbService: DbService, private patrimoineDbService: PatrimoineDbService) {}

  public async gatherPatrimoineData(
    idPatrimoine: number,
    paramsObjs: {
      societePatrimoineHierarchiesObj: DynamicType<SocietePatrimoineHierarchie>;
      societeComposantsObj: DynamicType<SocieteComposant>;
      societeCaracteristiquesObj: DynamicType<SocieteCaracteristique>;
      societeEspacesObj: DynamicType<SocieteEspace>;
    }
  ): Promise<PatrimoineWithChildren[]> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const highestPatrimoines = await this.patrimoineDbService.getAllPatrimoinesRelatedWithIdPatrimoine(
      idPatrimoine,
      databaseCollections
    );
    const idHighestPatrimoine = highestPatrimoines[0]?.idPatrimoine;
    const composantAttendusDocuments = await databaseCollections['composant-attendus'].find().exec();
    const composantsDocuments = await databaseCollections['composants'].find().exec();

    const composantAttendus = composantAttendusDocuments.map(el => ({
      ...el.toJSON(),
      idComposantAttendu: +el.idComposantAttendu
    }));
    const composants = composantsDocuments.map(el => {
      const composant = el.toJSON();
      return {
        ...composant,
        societeComposant: paramsObjs.societeComposantsObj[composant.idSocieteComposant],
        valeurs: composant.valeurs?.map(valeur => ({
          ...valeur,
          societeCaracteristique: paramsObjs.societeCaracteristiquesObj[valeur.idSocieteCaracteristique]
        }))
      };
    });

    const composantAttendusObj: DynamicType<ComposantAttendu> = transformArrayToObject(composantAttendus, {
      key: 'idComposantAttendu'
    });
    const composantObjPerEspace = (composants as unknown as Composant[]).reduce((acc, curr) => {
      if (acc[curr.idEspace]?.length) {
        acc[curr.idEspace].push(curr);
      } else {
        acc[curr.idEspace] = [curr];
      }
      return acc;
    }, {} as DynamicType<Composant[]>);

    const patrimoines = highestPatrimoines
      .map(patrimoine => ({
        ...patrimoine,
        societePatrimoineHierarchie: {
          ...(paramsObjs.societePatrimoineHierarchiesObj[patrimoine.idSocietePatrimoineHierarchie] ||
            patrimoine.societePatrimoineHierarchie)
        },
        composantAttendus: patrimoine.composantAttendus?.map(composantAttendu => {
          const el = composantAttendusObj[composantAttendu.idComposantAttendu] || composantAttendu;
          return {
            ...el,
            societeComposant:
              typeof el.societeComposant === 'number'
                ? paramsObjs.societeComposantsObj[el.societeComposant]
                : paramsObjs.societeComposantsObj[el.idSocieteComposant]
          };
        }),
        espaces: patrimoine.espaces?.map(espace => ({
          ...espace,
          societeEspace:
            typeof espace.societeEspace === 'number'
              ? paramsObjs.societeEspacesObj[espace.societeEspace]
              : paramsObjs.societeEspacesObj[espace.idSocieteEspace],
          composants: composantObjPerEspace[espace.idEspace] || []
        }))
      }))
      .sort((a, b) => compareStrings(a.libelle, b.libelle));

    const patrimoineTargetMassDuplication = transformAncestorsArrayToTree(idHighestPatrimoine, patrimoines, {
      key: 'idPatrimoine',
      childrenKey: 'children',
      parentListKey: 'ancetres',
      parentListIdentifierKey: 'idAncetrePatrimoine'
    });
    const filteredArray = filterChildrenWithExistingComposantMassDuplication(patrimoineTargetMassDuplication);
    return patrimoineWithTitleInfos(filteredArray);
  }
}
