import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { ProgInterventionFamilleApiService } from '@get/store/api-services';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgInterventionFamille } from '@get/store/configs/normalization';
import { ProgInterventionFamilleSelectors } from '@get/store/selectors';
import { ProgInterventionFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';

export function getDefaultAddProgInterventionFamilleActions(progInterventionFamille: ProgInterventionFamilleEntityState, ids?: ProgInterventionFamilleRelationsIds): Action[] {
  const actions: Action[] = [ProgInterventionFamilleGeneratedActions.normalizeManyProgInterventionFamillesAfterUpsert({ progInterventionFamilles: [progInterventionFamille] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgInterventionFamilleSuccess({
        idSociete: ids.societe,
        idProgInterventionFamilles: [progInterventionFamille.idProgInterventionFamille]
      })
    );
    actions.push(
      ProgInterventionFamilleGeneratedActions.addSocieteSuccess({
        idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention
        })
      );
      actions.push(
        ProgInterventionFamilleGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[]
        })
      );
      actions.push(
        ProgInterventionFamilleGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgInterventionFamilleActions(progInterventionFamille: ProgInterventionFamilleEntityState): Action[] {
  const actions: Action[] = [ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamilleSuccess({ idProgInterventionFamille: progInterventionFamille.idProgInterventionFamille })];

  if (progInterventionFamille.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgInterventionFamilleSuccess({
        idProgInterventionFamilles: [progInterventionFamille.idProgInterventionFamille],
        idSocietes: [progInterventionFamille.societe as number]
      })
    );
  }

  if (progInterventionFamille.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManyProgInterventionFamilleSuccess({
        idProgInterventionFamilles: [progInterventionFamille.idProgInterventionFamille],
        idProgBiblioInterventions: progInterventionFamille.progBiblioInterventions as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgInterventionFamilleEffects {
  constructor(
    protected actions$: Actions,
    protected progInterventionFamilleApiService: ProgInterventionFamilleApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgInterventionFamille$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgInterventionFamilleGeneratedActions.upsertOneProgInterventionFamille),
      concatMap(
        ({
          progInterventionFamille,
          ids
        }: {
          progInterventionFamille: Partial<ProgInterventionFamille>;
          ids?: ProgInterventionFamilleRelationsIds;
        }) => {
          if (progInterventionFamille.idProgInterventionFamille) {
            return this.progInterventionFamilleApiService.updateProgInterventionFamille(progInterventionFamille).pipe(
              map((progInterventionFamilleReturned: ProgInterventionFamille) => {
                return ProgInterventionFamilleGeneratedActions.normalizeManyProgInterventionFamillesAfterUpsert({ progInterventionFamilles: [progInterventionFamilleReturned] });
              }),
              catchError(error => of(ProgInterventionFamilleGeneratedActions.progInterventionFamillesFailure({ error })))
            );
          } else {
            return this.progInterventionFamilleApiService.addProgInterventionFamille(progInterventionFamille).pipe(
              mergeMap((progInterventionFamilleReturned: ProgInterventionFamille) => getDefaultAddProgInterventionFamilleActions(progInterventionFamilleReturned, ids)),
              catchError(error => of(ProgInterventionFamilleGeneratedActions.progInterventionFamillesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgInterventionFamille$ = createEffect(() => {
    const selectProgInterventionFamilleState$ = this.store$.select(ProgInterventionFamilleSelectors.selectProgInterventionFamilleState);
    return this.actions$.pipe(
      ofType(ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamille),
      withLatestFrom(selectProgInterventionFamilleState$),
      concatMap(([{ idProgInterventionFamille }, state]) =>
        this.progInterventionFamilleApiService.deleteProgInterventionFamille(idProgInterventionFamille).pipe(
          mergeMap(_success => getDefaultDeleteProgInterventionFamilleActions(state.entities[idProgInterventionFamille] as ProgInterventionFamilleEntityState)),
          catchError(error => of(ProgInterventionFamilleGeneratedActions.progInterventionFamillesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgInterventionFamillesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgInterventionFamilleGeneratedActions.normalizeManyProgInterventionFamillesAfterUpsert),
      concatMap(({ progInterventionFamilles }) => {
        const actions: Action[] = getActionsToNormalizeProgInterventionFamille(progInterventionFamilles, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgInterventionFamille] Normalization After Upsert Success')];
      })
    );
  });
}
