import { createEntityAdapter } from '@ngrx/entity';
import { SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteProfilDroitEntityState>;

export const adapter = createEntityAdapter<SocieteProfilDroitEntityState>({
  selectId: o => o.idSocieteProfilDroit
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const societeProfilDroitFeatureKey = 'societeProfilDroit';
