import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgBiblioInterventionScenario, ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgBiblioInterventionScenarioState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progBiblioInterventionScenarioRelations: string[] = ['progScenarios','progBiblioInterventions',];

export const { selectEntities, selectAll } = ProgBiblioInterventionScenarioState.adapter.getSelectors();

export const selectProgBiblioInterventionScenarioState = createFeatureSelector<ProgBiblioInterventionScenarioState.IState>(ProgBiblioInterventionScenarioState.progBiblioInterventionScenarioFeatureKey);

export const selectIsLoadedProgBiblioInterventionScenario = createSelector(
  selectProgBiblioInterventionScenarioState,
  (state: ProgBiblioInterventionScenarioState.IState) => state.isLoaded
);

export const selectIsLoadingProgBiblioInterventionScenario = createSelector(
  selectProgBiblioInterventionScenarioState,
  (state: ProgBiblioInterventionScenarioState.IState) => state.isLoading
);

export const selectIsReadyProgBiblioInterventionScenario = createSelector(
  selectProgBiblioInterventionScenarioState,
  (state: ProgBiblioInterventionScenarioState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgBiblioInterventionScenario = createSelector(
  selectProgBiblioInterventionScenarioState,
  (state: ProgBiblioInterventionScenarioState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgBiblioInterventionScenariosEntities = createSelector(selectProgBiblioInterventionScenarioState, selectEntities);

export const selectProgBiblioInterventionScenariosArray = createSelector(selectProgBiblioInterventionScenarioState, selectAll);

const progBiblioInterventionScenariosInObject = (progBiblioInterventionScenarios: Dictionary<ProgBiblioInterventionScenarioEntityState>) => ({ progBiblioInterventionScenarios })

const selectProgBiblioInterventionScenariosEntitiesDictionary = createSelector(selectProgBiblioInterventionScenariosEntities, progBiblioInterventionScenariosInObject);

const selectAllProgBiblioInterventionScenariosObject = createSelector(selectProgBiblioInterventionScenariosEntities, progBiblioInterventionScenarios => {
  return hydrateAll({ progBiblioInterventionScenarios });
});

const selectOneProgBiblioInterventionScenarioDictionary = (idProgBiblioInterventionScenario : number) =>
  createSelector(selectProgBiblioInterventionScenariosEntities, progBiblioInterventionScenarios => ({
    progBiblioInterventionScenarios: { [idProgBiblioInterventionScenario]: progBiblioInterventionScenarios[idProgBiblioInterventionScenario] }
  }));

const selectOneProgBiblioInterventionScenarioDictionaryWithoutChild = (idProgBiblioInterventionScenario : number) =>
  createSelector(selectProgBiblioInterventionScenariosEntities, progBiblioInterventionScenarios => ({
    progBiblioInterventionScenario: progBiblioInterventionScenarios[idProgBiblioInterventionScenario]
  }));

const selectAllProgBiblioInterventionScenariosSelectors: Dictionary<Selector> = {};
export function selectAllProgBiblioInterventionScenarios(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgBiblioInterventionScenario>(
      schema,
      selectAllProgBiblioInterventionScenariosSelectors,
      selectProgBiblioInterventionScenariosEntitiesDictionary,
      getRelationSelectors,
      progBiblioInterventionScenarioRelations,
      hydrateAll,
      'progBiblioInterventionScenario'
    );
  } else {
    return selectAllProgBiblioInterventionScenariosObject;
  }
}

export function selectAllProgBiblioInterventionScenariosDictionary(
  schema: SelectSchema = {},
  customKey = 'progBiblioInterventionScenarios'
): Selector {
  return createSelector(selectAllProgBiblioInterventionScenarios(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgBiblioInterventionScenarioEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progBiblioInterventionScenarios.length; i++) {
      res[customKey][result.progBiblioInterventionScenarios[i].idProgBiblioInterventionScenario] = result.progBiblioInterventionScenarios[i];
    }
    return res;
  });
}

export function selectOneProgBiblioInterventionScenario(
  schema: SelectSchema = {},
  idProgBiblioInterventionScenario: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgBiblioInterventionScenarioDictionary(idProgBiblioInterventionScenario)];
  selectors.push(...getRelationSelectors(schema, progBiblioInterventionScenarioRelations, 'progBiblioInterventionScenario'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgBiblioInterventionScenarioDictionaryWithoutChild(idProgBiblioInterventionScenario);
  }
}

interface hydrateArgs {
  progBiblioInterventionScenarios: Dictionary<ProgBiblioInterventionScenarioEntityState>;
  progScenarios?: Dictionary<ProgScenarioEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progBiblioInterventionScenarios: (ProgBiblioInterventionScenario | null)[] } {
  const {
    progBiblioInterventionScenarios,
    progScenarios,
    progBiblioInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progBiblioInterventionScenarios: Object.keys(progBiblioInterventionScenarios).map(idProgBiblioInterventionScenario =>
      hydrate(
        progBiblioInterventionScenarios[idProgBiblioInterventionScenario] as ProgBiblioInterventionScenarioEntityState,
        progScenarios,
        progBiblioInterventions
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progBiblioInterventionScenario: ProgBiblioInterventionScenarioEntityState | null } {
  const {
    progBiblioInterventionScenarios,
    progScenarios,
    progBiblioInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progBiblioInterventionScenario = Object.values(progBiblioInterventionScenarios)[0];
  return {
    progBiblioInterventionScenario: hydrate(
      progBiblioInterventionScenario as ProgBiblioInterventionScenarioEntityState,
      progScenarios,
      progBiblioInterventions
    )
  };
}

function hydrate(
  progBiblioInterventionScenario: ProgBiblioInterventionScenarioEntityState,
  progScenarioEntities?: Dictionary<ProgScenarioEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
): ProgBiblioInterventionScenario | null {
  if (!progBiblioInterventionScenario) {
    return null;
  }

  const progBiblioInterventionScenarioHydrated: ProgBiblioInterventionScenarioEntityState = { ...progBiblioInterventionScenario };
  if (progScenarioEntities) {
    progBiblioInterventionScenarioHydrated.progScenario = progScenarioEntities[progBiblioInterventionScenario.progScenario as number] as ProgScenario;
  } else {
    delete progBiblioInterventionScenarioHydrated.progScenario;
  }
  if (progBiblioInterventionEntities) {
    progBiblioInterventionScenarioHydrated.progBiblioIntervention = progBiblioInterventionEntities[progBiblioInterventionScenario.progBiblioIntervention as number] as ProgBiblioIntervention;
  } else {
    delete progBiblioInterventionScenarioHydrated.progBiblioIntervention;
  }

  return progBiblioInterventionScenarioHydrated as ProgBiblioInterventionScenario;
}
