import {
  GridError,
  ProgComposantSousGroupe,
  ProgComposantSousGroupeDataRow,
  ValidationErrorInterface
} from '@get/api-interfaces';

export function validateProgComposantSousGroupeProperties(
  sousGroupeToCreate: Partial<ProgComposantSousGroupe>
): ValidationErrorInterface<ProgComposantSousGroupe>[] {
  const errors: ValidationErrorInterface<ProgComposantSousGroupe>[] = [];
  if (!sousGroupeToCreate.idProgComposantGroupe || !sousGroupeToCreate.libelle) {
    errors.push({
      properties: ['idProgComposantGroupe', 'libelle'],
      message: 'You need an idProgComposantGroupe and a libelle to do this action'
    });
  }
  return errors;
}

export function mergeProgComposantSousGroupeAndGridErrors(
  sousGroupes: ProgComposantSousGroupe[],
  gridErrors: GridError[]
): ProgComposantSousGroupeDataRow[] {
  const sousGroupeDataRows: ProgComposantSousGroupeDataRow[] = [...(sousGroupes as ProgComposantSousGroupeDataRow[])];
  gridErrors?.forEach(gridError => {
    if (gridError.error.idProgComposantSousGroupe > 0) {
      const index = sousGroupeDataRows.findIndex(
        sousGroupe => sousGroupe.idProgComposantSousGroupe === gridError.error.idProgComposantSousGroupe
      );

      sousGroupeDataRows[index] = { ...gridError.error };
    } else {
      sousGroupeDataRows.push({ ...gridError.error });
    }
  });

  return sousGroupeDataRows;
}
