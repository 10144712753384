import { ProgScenarioState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progScenarioReducersGeneratedFunctions } from './prog-scenario-generated.reducer';

const progScenarioReducersFunctions = [...progScenarioReducersGeneratedFunctions];

const progScenarioReducer = createReducer(ProgScenarioState.initialState, ...progScenarioReducersFunctions);

export function reducer(state: ProgScenarioState.IState | undefined, action: Action) {
  return progScenarioReducer(state, action);
}
