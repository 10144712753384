import { createEntityAdapter } from '@ngrx/entity';
import { ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgInterventionScenarioEntityState>;

export const adapter = createEntityAdapter<ProgInterventionScenarioEntityState>({
  selectId: o => o.idProgInterventionScenario
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progInterventionScenarioFeatureKey = 'progInterventionScenario';
