import { pSBC } from './colors.utils';

export function colouredCellRenderer(value: any, color: string, htmlClass: string): string {
  let style = '';
  if (color) {
    const textColor = pSBC(-0.5, color, false, true);
    const backgroundColor = pSBC(0.85, color, false, true);
    const borderColor = color;
    style = `style="border-left: 4px solid ${borderColor};border-radius: 4px;background-color: ${backgroundColor};color: ${textColor}"`;
  }
  return `<span ${htmlClass ? 'class="' + htmlClass + '"' : ''} ${style}>${value ?? ''}</span>`;
}
