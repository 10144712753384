import { RepositoryService } from '@get/services/repository';
import { ProgScenario } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProgScenarioApiService {
  constructor(protected repo: RepositoryService) {}

  public addProgScenario(progScenario: Partial<ProgScenario>): Observable<ProgScenario> {
    return this.repo.create<ProgScenario>('prog-scenario', progScenario);
  }

  public updateProgScenario(progScenario: Partial<ProgScenario>): Observable<ProgScenario> {
    return this.repo.update('prog-scenario', progScenario);
  }

  public deleteProgScenario(idProgScenario: number): Observable<number> {
    return this.repo.delete('prog-scenario/' + +idProgScenario);
  }
}
