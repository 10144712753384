import {
  GridError,
  SocieteEspaceFamille,
  SocieteEspaceFamilleDataRow,
  ValidationErrorInterface
} from '@get/api-interfaces';

export function validateSocieteEspaceFamilleProperties(
  societeEspaceFamilleToCreate: Partial<SocieteEspaceFamille>
): ValidationErrorInterface<SocieteEspaceFamille>[] {
  const errors: ValidationErrorInterface<SocieteEspaceFamille>[] = [];
  if (!societeEspaceFamilleToCreate.libelleTechnique || !societeEspaceFamilleToCreate.idSocietePatrimoineHierarchie) {
    errors.push({
      properties: ['libelleTechnique', 'idSocietePatrimoineHierarchie'],
      message: 'You need a libelleTechnique and an idSocietePatrimoineHierarchie to do this action'
    });
  }
  return errors;
}

export function mergeSocieteEspaceFamilleAndGridErrors(
  societeEspaceFamilles: SocieteEspaceFamille[],
  gridErrors: GridError[]
): SocieteEspaceFamilleDataRow[] {
  const societeEspaceFamilleDataRows: SocieteEspaceFamilleDataRow[] = [
    ...(societeEspaceFamilles as SocieteEspaceFamilleDataRow[])
  ];
  gridErrors?.forEach(gridErrorSocieteEspaceFamille => {
    if (gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille > 0) {
      const index = societeEspaceFamilleDataRows.findIndex(
        espace => espace.idSocieteEspaceFamille === gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille
      );

      societeEspaceFamilleDataRows[index] = { ...gridErrorSocieteEspaceFamille.error };
    } else {
      societeEspaceFamilleDataRows.push({ ...gridErrorSocieteEspaceFamille.error });
    }
  });
  return societeEspaceFamilleDataRows;
}
