import { CaracteristiqueChoixTemplate, SocieteCaracteristiqueChoix } from '@get/api-interfaces';

export function sortCaracteristiqueChoices(
  choices: SocieteCaracteristiqueChoix[] | CaracteristiqueChoixTemplate[]
): SocieteCaracteristiqueChoix[] | CaracteristiqueChoixTemplate[] {
  return [
    ...(choices
      ?.map(el => (el ? { ...el } : el))
      .sort(
        (
          choixA: SocieteCaracteristiqueChoix | CaracteristiqueChoixTemplate,
          choixB: SocieteCaracteristiqueChoix | CaracteristiqueChoixTemplate
        ) => choixA?.ordre - choixB?.ordre
      ) || [])
  ] as SocieteCaracteristiqueChoix[] | CaracteristiqueChoixTemplate[];
}
