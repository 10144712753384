import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgInterventionPatrimoine, ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { ProgInterventionScenario, ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ValeurProgIntervention, ValeurProgInterventionEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgInterventionState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progInterventionRelations: string[] = ['progInterventionPatrimoines','patrimoines','progInterventionScenarios','progScenarios','valeurProgInterventions','valeurs','societes','progComposants','progBiblioInterventions','progBudgetSaisie','progBudgetCalcul','progUniteSaisie','progUniteCalcul','progInterventionFamilleSaisie','progInterventionFamilleCalcul',];

export const { selectEntities, selectAll } = ProgInterventionState.adapter.getSelectors();

export const selectProgInterventionState = createFeatureSelector<ProgInterventionState.IState>(ProgInterventionState.progInterventionFeatureKey);

export const selectIsLoadedProgIntervention = createSelector(
  selectProgInterventionState,
  (state: ProgInterventionState.IState) => state.isLoaded
);

export const selectIsLoadingProgIntervention = createSelector(
  selectProgInterventionState,
  (state: ProgInterventionState.IState) => state.isLoading
);

export const selectIsReadyProgIntervention = createSelector(
  selectProgInterventionState,
  (state: ProgInterventionState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgIntervention = createSelector(
  selectProgInterventionState,
  (state: ProgInterventionState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgInterventionsEntities = createSelector(selectProgInterventionState, selectEntities);

export const selectProgInterventionsArray = createSelector(selectProgInterventionState, selectAll);

const progInterventionsInObject = (progInterventions: Dictionary<ProgInterventionEntityState>) => ({ progInterventions })

const selectProgInterventionsEntitiesDictionary = createSelector(selectProgInterventionsEntities, progInterventionsInObject);

const selectAllProgInterventionsObject = createSelector(selectProgInterventionsEntities, progInterventions => {
  return hydrateAll({ progInterventions });
});

const selectOneProgInterventionDictionary = (idProgIntervention : number) =>
  createSelector(selectProgInterventionsEntities, progInterventions => ({
    progInterventions: { [idProgIntervention]: progInterventions[idProgIntervention] }
  }));

const selectOneProgInterventionDictionaryWithoutChild = (idProgIntervention : number) =>
  createSelector(selectProgInterventionsEntities, progInterventions => ({
    progIntervention: progInterventions[idProgIntervention]
  }));

const selectAllProgInterventionsSelectors: Dictionary<Selector> = {};
export function selectAllProgInterventions(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgIntervention>(
      schema,
      selectAllProgInterventionsSelectors,
      selectProgInterventionsEntitiesDictionary,
      getRelationSelectors,
      progInterventionRelations,
      hydrateAll,
      'progIntervention'
    );
  } else {
    return selectAllProgInterventionsObject;
  }
}

export function selectAllProgInterventionsDictionary(
  schema: SelectSchema = {},
  customKey = 'progInterventions'
): Selector {
  return createSelector(selectAllProgInterventions(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgInterventionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progInterventions.length; i++) {
      res[customKey][result.progInterventions[i].idProgIntervention] = result.progInterventions[i];
    }
    return res;
  });
}

export function selectOneProgIntervention(
  schema: SelectSchema = {},
  idProgIntervention: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgInterventionDictionary(idProgIntervention)];
  selectors.push(...getRelationSelectors(schema, progInterventionRelations, 'progIntervention'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgInterventionDictionaryWithoutChild(idProgIntervention);
  }
}

interface hydrateArgs {
  progInterventions: Dictionary<ProgInterventionEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progBudgetSaisie?: Dictionary<ProgBudgetEntityState>;
  progBudgetCalcul?: Dictionary<ProgBudgetEntityState>;
  progUniteSaisie?: Dictionary<ProgUniteEntityState>;
  progUniteCalcul?: Dictionary<ProgUniteEntityState>;
  progInterventionFamilleSaisie?: Dictionary<ProgInterventionFamilleEntityState>;
  progInterventionFamilleCalcul?: Dictionary<ProgInterventionFamilleEntityState>;
  progInterventionPatrimoines?: Dictionary<ProgInterventionPatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  progInterventionScenarios?: Dictionary<ProgInterventionScenarioEntityState>;
  progScenarios?: Dictionary<ProgScenarioEntityState>;
  valeurProgInterventions?: Dictionary<ValeurProgInterventionEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progInterventions: (ProgIntervention | null)[] } {
  const {
    progInterventions,
    societes,
    progComposants,
    progBiblioInterventions,
    progBudgetSaisie,
    progBudgetCalcul,
    progUniteSaisie,
    progUniteCalcul,
    progInterventionFamilleSaisie,
    progInterventionFamilleCalcul,
    progInterventionPatrimoines,
    patrimoines,
    progInterventionScenarios,
    progScenarios,
    valeurProgInterventions,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progInterventions: Object.keys(progInterventions).map(idProgIntervention =>
      hydrate(
        progInterventions[idProgIntervention] as ProgInterventionEntityState,
        societes,
        progComposants,
        progBiblioInterventions,
        progBudgetSaisie,
        progBudgetCalcul,
        progUniteSaisie,
        progUniteCalcul,
        progInterventionFamilleSaisie,
        progInterventionFamilleCalcul,
        progInterventionPatrimoines,
        patrimoines,
        progInterventionScenarios,
        progScenarios,
        valeurProgInterventions,
        valeurs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progIntervention: ProgInterventionEntityState | null } {
  const {
    progInterventions,
    societes,
    progComposants,
    progBiblioInterventions,
    progBudgetSaisie,
    progBudgetCalcul,
    progUniteSaisie,
    progUniteCalcul,
    progInterventionFamilleSaisie,
    progInterventionFamilleCalcul,
    progInterventionPatrimoines,
    patrimoines,
    progInterventionScenarios,
    progScenarios,
    valeurProgInterventions,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progIntervention = Object.values(progInterventions)[0];
  return {
    progIntervention: hydrate(
      progIntervention as ProgInterventionEntityState,
      societes,
      progComposants,
      progBiblioInterventions,
      progBudgetSaisie,
      progBudgetCalcul,
      progUniteSaisie,
      progUniteCalcul,
      progInterventionFamilleSaisie,
      progInterventionFamilleCalcul,
      progInterventionPatrimoines,
      patrimoines,
      progInterventionScenarios,
      progScenarios,
      valeurProgInterventions,
      valeurs
    )
  };
}

function hydrate(
  progIntervention: ProgInterventionEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progBudgetSaisieEntities?: Dictionary<ProgBudgetEntityState>,
  progBudgetCalculEntities?: Dictionary<ProgBudgetEntityState>,
  progUniteSaisieEntities?: Dictionary<ProgUniteEntityState>,
  progUniteCalculEntities?: Dictionary<ProgUniteEntityState>,
  progInterventionFamilleSaisieEntities?: Dictionary<ProgInterventionFamilleEntityState>,
  progInterventionFamilleCalculEntities?: Dictionary<ProgInterventionFamilleEntityState>,
  progInterventionPatrimoineEntities?: Dictionary<ProgInterventionPatrimoineEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  progInterventionScenarioEntities?: Dictionary<ProgInterventionScenarioEntityState>,
  progScenarioEntities?: Dictionary<ProgScenarioEntityState>,
  valeurProgInterventionEntities?: Dictionary<ValeurProgInterventionEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
): ProgIntervention | null {
  if (!progIntervention) {
    return null;
  }

  const progInterventionHydrated: ProgInterventionEntityState = { ...progIntervention };
  if (societeEntities) {
    progInterventionHydrated.societe = societeEntities[progIntervention.societe as number] as Societe;
  } else {
    delete progInterventionHydrated.societe;
  }
  if (progComposantEntities) {
    progInterventionHydrated.progComposant = progComposantEntities[progIntervention.progComposant as number] as ProgComposant;
  } else {
    delete progInterventionHydrated.progComposant;
  }
  if (progBiblioInterventionEntities) {
    progInterventionHydrated.progBiblioIntervention = progBiblioInterventionEntities[progIntervention.progBiblioIntervention as number] as ProgBiblioIntervention;
  } else {
    delete progInterventionHydrated.progBiblioIntervention;
  }
  if (progBudgetSaisieEntities) {
    progInterventionHydrated.progBudgetSaisie = progBudgetSaisieEntities[progIntervention.progBudgetSaisie as number] as ProgBudget;
  } else {
    delete progInterventionHydrated.progBudgetSaisie;
  }
  if (progBudgetCalculEntities) {
    progInterventionHydrated.progBudgetCalcul = progBudgetCalculEntities[progIntervention.progBudgetCalcul as number] as ProgBudget;
  } else {
    delete progInterventionHydrated.progBudgetCalcul;
  }
  if (progUniteSaisieEntities) {
    progInterventionHydrated.progUniteSaisie = progUniteSaisieEntities[progIntervention.progUniteSaisie as number] as ProgUnite;
  } else {
    delete progInterventionHydrated.progUniteSaisie;
  }
  if (progUniteCalculEntities) {
    progInterventionHydrated.progUniteCalcul = progUniteCalculEntities[progIntervention.progUniteCalcul as number] as ProgUnite;
  } else {
    delete progInterventionHydrated.progUniteCalcul;
  }
  if (progInterventionFamilleSaisieEntities) {
    progInterventionHydrated.progInterventionFamilleSaisie = progInterventionFamilleSaisieEntities[progIntervention.progInterventionFamilleSaisie as number] as ProgInterventionFamille;
  } else {
    delete progInterventionHydrated.progInterventionFamilleSaisie;
  }
  if (progInterventionFamilleCalculEntities) {
    progInterventionHydrated.progInterventionFamilleCalcul = progInterventionFamilleCalculEntities[progIntervention.progInterventionFamilleCalcul as number] as ProgInterventionFamille;
  } else {
    delete progInterventionHydrated.progInterventionFamilleCalcul;
  }

  if (progInterventionPatrimoineEntities) {
    progInterventionHydrated.progInterventionPatrimoines = ((progInterventionHydrated.progInterventionPatrimoines as number[]) || []).map(
      id => progInterventionPatrimoineEntities[id]
    ) as ProgInterventionPatrimoine[];
  } else {
    delete progInterventionHydrated.progInterventionPatrimoines;
  }

  if (patrimoineEntities) {
    progInterventionHydrated.patrimoines = ((progInterventionHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete progInterventionHydrated.patrimoines;
  }

  if (progInterventionScenarioEntities) {
    progInterventionHydrated.progInterventionScenarios = ((progInterventionHydrated.progInterventionScenarios as number[]) || []).map(
      id => progInterventionScenarioEntities[id]
    ) as ProgInterventionScenario[];
  } else {
    delete progInterventionHydrated.progInterventionScenarios;
  }

  if (progScenarioEntities) {
    progInterventionHydrated.progScenarios = ((progInterventionHydrated.progScenarios as number[]) || []).map(
      id => progScenarioEntities[id]
    ) as ProgScenario[];
  } else {
    delete progInterventionHydrated.progScenarios;
  }

  if (valeurProgInterventionEntities) {
    progInterventionHydrated.valeurProgInterventions = ((progInterventionHydrated.valeurProgInterventions as number[]) || []).map(
      id => valeurProgInterventionEntities[id]
    ) as ValeurProgIntervention[];
  } else {
    delete progInterventionHydrated.valeurProgInterventions;
  }

  if (valeurEntities) {
    progInterventionHydrated.valeurs = ((progInterventionHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete progInterventionHydrated.valeurs;
  }

  return progInterventionHydrated as ProgIntervention;
}
