import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsageCaracteristiqueTemplate, UsageCaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UsageCaracteristiqueTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const usageCaracteristiqueTemplateRelations: string[] = ['usages','caracteristiqueTemplates',];

export const { selectEntities, selectAll } = UsageCaracteristiqueTemplateState.adapter.getSelectors();

export const selectUsageCaracteristiqueTemplateState = createFeatureSelector<UsageCaracteristiqueTemplateState.IState>(UsageCaracteristiqueTemplateState.usageCaracteristiqueTemplateFeatureKey);

export const selectIsLoadedUsageCaracteristiqueTemplate = createSelector(
  selectUsageCaracteristiqueTemplateState,
  (state: UsageCaracteristiqueTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingUsageCaracteristiqueTemplate = createSelector(
  selectUsageCaracteristiqueTemplateState,
  (state: UsageCaracteristiqueTemplateState.IState) => state.isLoading
);

export const selectIsReadyUsageCaracteristiqueTemplate = createSelector(
  selectUsageCaracteristiqueTemplateState,
  (state: UsageCaracteristiqueTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUsageCaracteristiqueTemplate = createSelector(
  selectUsageCaracteristiqueTemplateState,
  (state: UsageCaracteristiqueTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectUsageCaracteristiqueTemplatesEntities = createSelector(selectUsageCaracteristiqueTemplateState, selectEntities);

export const selectUsageCaracteristiqueTemplatesArray = createSelector(selectUsageCaracteristiqueTemplateState, selectAll);

const usageCaracteristiqueTemplatesInObject = (usageCaracteristiqueTemplates: Dictionary<UsageCaracteristiqueTemplateEntityState>) => ({ usageCaracteristiqueTemplates })

const selectUsageCaracteristiqueTemplatesEntitiesDictionary = createSelector(selectUsageCaracteristiqueTemplatesEntities, usageCaracteristiqueTemplatesInObject);

const selectAllUsageCaracteristiqueTemplatesObject = createSelector(selectUsageCaracteristiqueTemplatesEntities, usageCaracteristiqueTemplates => {
  return hydrateAll({ usageCaracteristiqueTemplates });
});

const selectOneUsageCaracteristiqueTemplateDictionary = (idUsageCaracteristiqueTemplate : number) =>
  createSelector(selectUsageCaracteristiqueTemplatesEntities, usageCaracteristiqueTemplates => ({
    usageCaracteristiqueTemplates: { [idUsageCaracteristiqueTemplate]: usageCaracteristiqueTemplates[idUsageCaracteristiqueTemplate] }
  }));

const selectOneUsageCaracteristiqueTemplateDictionaryWithoutChild = (idUsageCaracteristiqueTemplate : number) =>
  createSelector(selectUsageCaracteristiqueTemplatesEntities, usageCaracteristiqueTemplates => ({
    usageCaracteristiqueTemplate: usageCaracteristiqueTemplates[idUsageCaracteristiqueTemplate]
  }));

const selectAllUsageCaracteristiqueTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllUsageCaracteristiqueTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UsageCaracteristiqueTemplate>(
      schema,
      selectAllUsageCaracteristiqueTemplatesSelectors,
      selectUsageCaracteristiqueTemplatesEntitiesDictionary,
      getRelationSelectors,
      usageCaracteristiqueTemplateRelations,
      hydrateAll,
      'usageCaracteristiqueTemplate'
    );
  } else {
    return selectAllUsageCaracteristiqueTemplatesObject;
  }
}

export function selectAllUsageCaracteristiqueTemplatesDictionary(
  schema: SelectSchema = {},
  customKey = 'usageCaracteristiqueTemplates'
): Selector {
  return createSelector(selectAllUsageCaracteristiqueTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<UsageCaracteristiqueTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.usageCaracteristiqueTemplates.length; i++) {
      res[customKey][result.usageCaracteristiqueTemplates[i].idUsageCaracteristiqueTemplate] = result.usageCaracteristiqueTemplates[i];
    }
    return res;
  });
}

export function selectOneUsageCaracteristiqueTemplate(
  schema: SelectSchema = {},
  idUsageCaracteristiqueTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUsageCaracteristiqueTemplateDictionary(idUsageCaracteristiqueTemplate)];
  selectors.push(...getRelationSelectors(schema, usageCaracteristiqueTemplateRelations, 'usageCaracteristiqueTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUsageCaracteristiqueTemplateDictionaryWithoutChild(idUsageCaracteristiqueTemplate);
  }
}

interface hydrateArgs {
  usageCaracteristiqueTemplates: Dictionary<UsageCaracteristiqueTemplateEntityState>;
  usages?: Dictionary<UsageEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { usageCaracteristiqueTemplates: (UsageCaracteristiqueTemplate | null)[] } {
  const {
    usageCaracteristiqueTemplates,
    usages,
    caracteristiqueTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    usageCaracteristiqueTemplates: Object.keys(usageCaracteristiqueTemplates).map(idUsageCaracteristiqueTemplate =>
      hydrate(
        usageCaracteristiqueTemplates[idUsageCaracteristiqueTemplate] as UsageCaracteristiqueTemplateEntityState,
        usages,
        caracteristiqueTemplates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { usageCaracteristiqueTemplate: UsageCaracteristiqueTemplateEntityState | null } {
  const {
    usageCaracteristiqueTemplates,
    usages,
    caracteristiqueTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const usageCaracteristiqueTemplate = Object.values(usageCaracteristiqueTemplates)[0];
  return {
    usageCaracteristiqueTemplate: hydrate(
      usageCaracteristiqueTemplate as UsageCaracteristiqueTemplateEntityState,
      usages,
      caracteristiqueTemplates
    )
  };
}

function hydrate(
  usageCaracteristiqueTemplate: UsageCaracteristiqueTemplateEntityState,
  usageEntities?: Dictionary<UsageEntityState>,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
): UsageCaracteristiqueTemplate | null {
  if (!usageCaracteristiqueTemplate) {
    return null;
  }

  const usageCaracteristiqueTemplateHydrated: UsageCaracteristiqueTemplateEntityState = { ...usageCaracteristiqueTemplate };
  if (usageEntities) {
    usageCaracteristiqueTemplateHydrated.usage = usageEntities[usageCaracteristiqueTemplate.usage as number] as Usage;
  } else {
    delete usageCaracteristiqueTemplateHydrated.usage;
  }
  if (caracteristiqueTemplateEntities) {
    usageCaracteristiqueTemplateHydrated.caracteristiqueTemplate = caracteristiqueTemplateEntities[usageCaracteristiqueTemplate.caracteristiqueTemplate as number] as CaracteristiqueTemplate;
  } else {
    delete usageCaracteristiqueTemplateHydrated.caracteristiqueTemplate;
  }

  return usageCaracteristiqueTemplateHydrated as UsageCaracteristiqueTemplate;
}
