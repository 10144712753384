import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { OrganisationNewsletterState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganisationNewsletterEntityState } from '@get/api-interfaces';

export const organisationNewsletterReducersGeneratedFunctions: ReducerTypes<OrganisationNewsletterState.IState, readonly ActionCreator[]>[] = [
  on(OrganisationNewsletterGeneratedActions.upsertOneOrganisationNewsletter, (state: OrganisationNewsletterState.IState) => setLoadingsState(state, true)),

  on(OrganisationNewsletterGeneratedActions.upsertManyOrganisationNewslettersSuccess, (state: OrganisationNewsletterState.IState, { organisationNewsletters }) =>
    OrganisationNewsletterState.adapter.upsertMany(organisationNewsletters, setLoadingsState(state, false))
  ),
  on(OrganisationNewsletterGeneratedActions.deleteOneOrganisationNewsletterSuccess, (state: OrganisationNewsletterState.IState, { idOrganisationNewsletter }) =>
    OrganisationNewsletterState.adapter.removeOne(idOrganisationNewsletter, setLoadingsState(state, false))
  ),

  on(OrganisationNewsletterGeneratedActions.clearOrganisationNewsletters, () => OrganisationNewsletterState.initialState),

  on(
    OrganisationNewsletterGeneratedActions.addOrganisationSuccess,
    (state: OrganisationNewsletterState.IState, { idOrganisationNewsletter, idOrganisation }) => {
      if (!state.entities[idOrganisationNewsletter]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisationNewsletter]: {
            ...state.entities[idOrganisationNewsletter],
            organisation: idOrganisation
          }
        }
      };
    }
  ),

  on(
    OrganisationNewsletterGeneratedActions.deleteManyOrganisationSuccess,
    (state: OrganisationNewsletterState.IState, { idOrganisations, idOrganisationNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisationNewsletters.reduce((entities, idOrganisationNewsletter) => {
            if (!state.entities[idOrganisationNewsletter]?.organisation) {
              return entities;
            }
            entities[idOrganisationNewsletter] = {
              ...state.entities[idOrganisationNewsletter],
              organisation: idOrganisations.some(
                (idOrganisation: number) => idOrganisation === state.entities[idOrganisationNewsletter]?.organisation
              )
                ? undefined
                : state.entities[idOrganisationNewsletter]?.organisation
            } as OrganisationNewsletterEntityState;
            return entities;
          }, {} as Dictionary<OrganisationNewsletterEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationNewsletterGeneratedActions.addNewsletterSuccess,
    (state: OrganisationNewsletterState.IState, { idOrganisationNewsletter, idNewsletter }) => {
      if (!state.entities[idOrganisationNewsletter]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisationNewsletter]: {
            ...state.entities[idOrganisationNewsletter],
            newsletter: idNewsletter
          }
        }
      };
    }
  ),

  on(
    OrganisationNewsletterGeneratedActions.deleteManyNewsletterSuccess,
    (state: OrganisationNewsletterState.IState, { idNewsletters, idOrganisationNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisationNewsletters.reduce((entities, idOrganisationNewsletter) => {
            if (!state.entities[idOrganisationNewsletter]?.newsletter) {
              return entities;
            }
            entities[idOrganisationNewsletter] = {
              ...state.entities[idOrganisationNewsletter],
              newsletter: idNewsletters.some(
                (idNewsletter: number) => idNewsletter === state.entities[idOrganisationNewsletter]?.newsletter
              )
                ? undefined
                : state.entities[idOrganisationNewsletter]?.newsletter
            } as OrganisationNewsletterEntityState;
            return entities;
          }, {} as Dictionary<OrganisationNewsletterEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: OrganisationNewsletterState.IState,
  isLoading: boolean,
  isLoaded = true
): OrganisationNewsletterState.IState {
  return { ...state, isLoaded, isLoading };
}
