import { createEntityAdapter } from '@ngrx/entity';
import { SocieteComposantEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteComposantEntityState>;

export const adapter = createEntityAdapter<SocieteComposantEntityState>({
  selectId: o => o.idSocieteComposant
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const societeComposantFeatureKey = 'societeComposant';
