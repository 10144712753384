import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const SocieteTerritoirePatrimoineGeneratedActions = createActionGroup({
  source: 'Societe Territoire Patrimoine Generated',
  events: {
    'Upsert One Societe Territoire Patrimoine': props<{ societeTerritoirePatrimoine: Partial<SocieteTerritoirePatrimoine>; ids?: SocieteTerritoirePatrimoineRelationsIds; }>(),
    'Upsert Many Societe Territoire Patrimoines': props<{ societeTerritoirePatrimoines: Partial<SocieteTerritoirePatrimoine>[]; ids?: SocieteTerritoirePatrimoineRelationsIds; }>(),
    'Upsert Many Societe Territoire Patrimoines Success': props<{ societeTerritoirePatrimoines: SocieteTerritoirePatrimoineEntityState[] }>(),
    'Delete One Societe Territoire Patrimoine Success': props<{ idSocieteTerritoirePatrimoine: number }>(),
    'Normalize Many Societe Territoire Patrimoines After Upsert': props<{ societeTerritoirePatrimoines: SocieteTerritoirePatrimoineEntityState[] }>(),
    'Societe Territoire Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Territoire Patrimoines': emptyProps(),
    'Add Societe Territoire Success': props<{ idSocieteTerritoirePatrimoine: number; idSocieteTerritoire: number }>(),
    'Delete Many Societe Territoire Success': props<{ idSocieteTerritoires: number[]; idSocieteTerritoirePatrimoines: number[] }>(),
    'Add Patrimoine Success': props<{ idSocieteTerritoirePatrimoine: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idSocieteTerritoirePatrimoines: number[] }>()
  }
});
