import { RepositoryService } from '@get/services/repository';
import { SocieteEspace } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteEspaceApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteEspaces(params?: any): Observable<SocieteEspace[]> {
    return this.repo.getData<SocieteEspace[]>('societe-espace', params);
  }

  public addSocieteEspace(societeEspace: Partial<SocieteEspace>): Observable<SocieteEspace> {
    return this.repo.create<SocieteEspace>('societe-espace', societeEspace);
  }

  public updateSocieteEspace(societeEspace: Partial<SocieteEspace>): Observable<SocieteEspace> {
    return this.repo.update('societe-espace', societeEspace);
  }

  public deleteSocieteEspace(idSocieteEspace: number): Observable<number> {
    return this.repo.delete('societe-espace/' + +idSocieteEspace);
  }
}
