import { Injectable } from '@angular/core';
import { SocieteCaracteristique, SocieteCaracteristiqueToHandle } from '@get/api-interfaces';
import { SocieteCaracteristiqueActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, mapTo, switchMap } from 'rxjs';
import { GeneratedSocieteCaracteristiqueService } from './societe-caracteristique-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteCaracteristiqueService extends GeneratedSocieteCaracteristiqueService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public selectAllSocieteCaracteristiquePatrimoines(schema: SelectSchema = {}): Observable<SocieteCaracteristique[]> {
    return this.store$
      .pipe(select(SocieteCaracteristiqueSelectors.selectAllSocieteCaracteristiquePatrimoines(schema)))
      .pipe(
        switchMap(({ societeCaracteristiques }: { societeCaracteristiques: SocieteCaracteristique[] }) => {
          return this.getReady(schema).pipe(mapTo(societeCaracteristiques));
        })
      );
  }

  public selectAllSocieteCaracteristiqueComposants(schema: SelectSchema = {}): Observable<SocieteCaracteristique[]> {
    return this.store$
      .pipe(select(SocieteCaracteristiqueSelectors.selectAllSocieteCaracteristiqueComposants(schema)))
      .pipe(
        switchMap(({ societeCaracteristiques }: { societeCaracteristiques: SocieteCaracteristique[] }) => {
          return this.getReady(schema).pipe(mapTo(societeCaracteristiques));
        })
      );
  }

  public handleManySocieteCaracteristiqueComposants(
    params: SocieteCaracteristiqueToHandle
  ): void | Observable<SocieteCaracteristique[]> {
    this.store$.dispatch(SocieteCaracteristiqueActions.handleManySocieteCaracteristiqueComposants(params));
  }

  public handleManySocieteCaracteristiquePatrimoines(
    params: SocieteCaracteristiqueToHandle
  ): void | Observable<SocieteCaracteristique[]> {
    this.store$.dispatch(SocieteCaracteristiqueActions.handleManySocieteCaracteristiquePatrimoines(params));
  }
}
