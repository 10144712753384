import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgBiblioInterventionScenarioApiService } from '@get/store/api-services';
import { ProgBiblioInterventionScenarioGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgBiblioInterventionScenario } from '@get/store/configs/normalization';

export class GeneratedProgBiblioInterventionScenarioEffects {
  constructor(
    protected actions$: Actions,
    protected progBiblioInterventionScenarioApiService: ProgBiblioInterventionScenarioApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyProgBiblioInterventionScenariosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgBiblioInterventionScenarioGeneratedActions.normalizeManyProgBiblioInterventionScenariosAfterUpsert),
      concatMap(({ progBiblioInterventionScenarios }) => {
        const actions: Action[] = getActionsToNormalizeProgBiblioInterventionScenario(progBiblioInterventionScenarios, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgBiblioInterventionScenario] Normalization After Upsert Success')];
      })
    );
  });
}
