import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteComposantRelationsIds } from '@get/store/ids-interfaces';

export const SocieteComposantGeneratedActions = createActionGroup({
  source: 'Societe Composant Generated',
  events: {
    'Get Many Societe Composants': props<{ params: any }>(),
    'Upsert One Societe Composant': props<{ societeComposant: Partial<SocieteComposant>; ids?: SocieteComposantRelationsIds; }>(),
    'Upsert Many Societe Composants': props<{ societeComposants: Partial<SocieteComposant>[]; ids?: SocieteComposantRelationsIds; }>(),
    'Upsert Many Societe Composants Success': props<{ societeComposants: SocieteComposantEntityState[] }>(),
    'Delete One Societe Composant': props<{ idSocieteComposant: number }>(),
    'Delete One Societe Composant Success': props<{ idSocieteComposant: number }>(),
    'Normalize Many Societe Composants After Upsert': props<{ societeComposants: SocieteComposantEntityState[] }>(),
    'Societe Composants Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Composants': emptyProps(),
    'Add Many Societe Caracteristique Success': props<{ idSocieteComposant: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocieteComposants: number[] }>(),
    'Add Many Composant Success': props<{ idSocieteComposant: number; idComposants: number[] }>(),
    'Delete Many Composant Success': props<{ idComposants: number[]; idSocieteComposants: number[] }>(),
    'Add Many Composant Attendu Success': props<{ idSocieteComposant: number; idComposantAttendus: number[] }>(),
    'Delete Many Composant Attendu Success': props<{ idComposantAttendus: number[]; idSocieteComposants: number[] }>(),
    'Add Many Societe Composant Rattachement Success': props<{ idSocieteComposant: number; idSocieteComposantRattachements: number[] }>(),
    'Delete Many Societe Composant Rattachement Success': props<{ idSocieteComposantRattachements: number[]; idSocieteComposants: number[] }>(),
    'Add Many Prog Composant Success': props<{ idSocieteComposant: number; idProgComposants: number[] }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idSocieteComposants: number[] }>(),
    'Add Societe Success': props<{ idSocieteComposant: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteComposants: number[] }>(),
    'Add Composant Template Success': props<{ idSocieteComposant: number; idComposantTemplate: number }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idSocieteComposants: number[] }>(),
    'Add Societe Composant Famille Success': props<{ idSocieteComposant: number; idSocieteComposantFamille: number }>(),
    'Delete Many Societe Composant Famille Success': props<{ idSocieteComposantFamilles: number[]; idSocieteComposants: number[] }>(),
    'Add Composant Groupe Success': props<{ idSocieteComposant: number; idComposantGroupe: number }>(),
    'Delete Many Composant Groupe Success': props<{ idComposantGroupes: number[]; idSocieteComposants: number[] }>(),
    'Add Fichier Success': props<{ idSocieteComposant: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idSocieteComposants: number[] }>()
  }
});
