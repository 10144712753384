import { Injectable } from '@angular/core';
import { ProgIntervention, ProgPatrimoineInfo } from '@get/api-interfaces';
import { ProgPatrimoineInfoActions, ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfoApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedProgPatrimoineInfoService } from './prog-patrimoine-info-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgPatrimoineInfoService extends GeneratedProgPatrimoineInfoService {
  constructor(
    store$: Store<AppState>,
    actions$: Actions,
    private progPatrimoineInfoApiService: ProgPatrimoineInfoApiService
  ) {
    super(store$, actions$);
  }

  public updateOneProgPatrimoineInfo(
    progPatrimoineInfo: Partial<ProgPatrimoineInfo>,
    getResult?: boolean
  ): void | Observable<{
    progPatrimoineInfo: ProgPatrimoineInfo;
    progInterventions: ProgIntervention[];
  }> {
    this.store$.dispatch(ProgPatrimoineInfoActions.updateOneProgPatrimoineInfo({ progPatrimoineInfo }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgPatrimoineInfoGeneratedActions.normalizeManyProgPatrimoineInfosAfterUpsert,
        ProgPatrimoineInfoGeneratedActions.progPatrimoineInfosFailure,
        true
      );
    }
  }

  public updateOneProgPatrimoineInfoWithoutStore(progPatrimoineInfo: Partial<ProgPatrimoineInfo>): Observable<{
    progPatrimoineInfoResponse: ProgPatrimoineInfo;
    progInterventions: ProgIntervention[];
  }> {
    return this.progPatrimoineInfoApiService.updateOneProgPatrimoineInfo(progPatrimoineInfo);
  }
}
