import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { UserPatrimoineApiService } from '@get/store/api-services';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUserPatrimoine } from '@get/store/configs/normalization';
import { UserPatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';

export function getDefaultAddUserPatrimoineActions(userPatrimoine: UserPatrimoineEntityState, ids?: UserPatrimoineRelationsIds): Action[] {
  const actions: Action[] = [UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({ userPatrimoines: [userPatrimoine] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyUserPatrimoineSuccess({
        idUser: ids.user,
        idUserPatrimoines: [userPatrimoine.idUserPatrimoine]
      })
    );
    actions.push(
      UserPatrimoineGeneratedActions.addUserSuccess({
        idUserPatrimoine: userPatrimoine.idUserPatrimoine,
        idUser: ids.user
      })
    );
  }

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyUserPatrimoineSuccess({
        idPatrimoine: ids.patrimoine,
        idUserPatrimoines: [userPatrimoine.idUserPatrimoine]
      })
    );
    actions.push(
      UserPatrimoineGeneratedActions.addPatrimoineSuccess({
        idUserPatrimoine: userPatrimoine.idUserPatrimoine,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserPatrimoineActions(userPatrimoine: UserPatrimoineEntityState): Action[] {
  const actions: Action[] = [UserPatrimoineGeneratedActions.deleteOneUserPatrimoineSuccess({ idUserPatrimoine: userPatrimoine.idUserPatrimoine })];

  if (userPatrimoine.user) {
    actions.push(
      UserGeneratedActions.deleteManyUserPatrimoineSuccess({
        idUserPatrimoines: [userPatrimoine.idUserPatrimoine],
        idUsers: [userPatrimoine.user as number]
      })
    );
  }

  if (userPatrimoine.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyUserPatrimoineSuccess({
        idUserPatrimoines: [userPatrimoine.idUserPatrimoine],
        idPatrimoines: [userPatrimoine.patrimoine as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserPatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected userPatrimoineApiService: UserPatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneUserPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineGeneratedActions.upsertOneUserPatrimoine),
      concatMap(
        ({
          userPatrimoine,
          ids
        }: {
          userPatrimoine: Partial<UserPatrimoine>;
          ids?: UserPatrimoineRelationsIds;
        }) => {
          if (userPatrimoine.idUserPatrimoine) {
            return this.userPatrimoineApiService.updateUserPatrimoine(userPatrimoine).pipe(
              map((userPatrimoineReturned: UserPatrimoine) => {
                return UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({ userPatrimoines: [userPatrimoineReturned] });
              }),
              catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
            );
          } else {
            return this.userPatrimoineApiService.addUserPatrimoine(userPatrimoine).pipe(
              mergeMap((userPatrimoineReturned: UserPatrimoine) => getDefaultAddUserPatrimoineActions(userPatrimoineReturned, ids)),
              catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
            );
          }
        }
      )
    );
  });

  normalizeManyUserPatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert),
      concatMap(({ userPatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeUserPatrimoine(userPatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserPatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
