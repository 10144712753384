import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { UserNewsletterState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserNewsletterEntityState } from '@get/api-interfaces';

export const userNewsletterReducersGeneratedFunctions: ReducerTypes<UserNewsletterState.IState, readonly ActionCreator[]>[] = [
  on(UserNewsletterGeneratedActions.upsertOneUserNewsletter, (state: UserNewsletterState.IState) => setLoadingsState(state, true)),

  on(UserNewsletterGeneratedActions.upsertManyUserNewslettersSuccess, (state: UserNewsletterState.IState, { userNewsletters }) =>
    UserNewsletterState.adapter.upsertMany(userNewsletters, setLoadingsState(state, false))
  ),
  on(UserNewsletterGeneratedActions.deleteOneUserNewsletterSuccess, (state: UserNewsletterState.IState, { idUserNewsletter }) =>
    UserNewsletterState.adapter.removeOne(idUserNewsletter, setLoadingsState(state, false))
  ),

  on(UserNewsletterGeneratedActions.clearUserNewsletters, () => UserNewsletterState.initialState),

  on(
    UserNewsletterGeneratedActions.addUserSuccess,
    (state: UserNewsletterState.IState, { idUserNewsletter, idUser }) => {
      if (!state.entities[idUserNewsletter]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserNewsletter]: {
            ...state.entities[idUserNewsletter],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    UserNewsletterGeneratedActions.deleteManyUserSuccess,
    (state: UserNewsletterState.IState, { idUsers, idUserNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserNewsletters.reduce((entities, idUserNewsletter) => {
            if (!state.entities[idUserNewsletter]?.user) {
              return entities;
            }
            entities[idUserNewsletter] = {
              ...state.entities[idUserNewsletter],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idUserNewsletter]?.user
              )
                ? undefined
                : state.entities[idUserNewsletter]?.user
            } as UserNewsletterEntityState;
            return entities;
          }, {} as Dictionary<UserNewsletterEntityState>)
        }
      };
    }
  ),

  on(
    UserNewsletterGeneratedActions.addNewsletterSuccess,
    (state: UserNewsletterState.IState, { idUserNewsletter, idNewsletter }) => {
      if (!state.entities[idUserNewsletter]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserNewsletter]: {
            ...state.entities[idUserNewsletter],
            newsletter: idNewsletter
          }
        }
      };
    }
  ),

  on(
    UserNewsletterGeneratedActions.deleteManyNewsletterSuccess,
    (state: UserNewsletterState.IState, { idNewsletters, idUserNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserNewsletters.reduce((entities, idUserNewsletter) => {
            if (!state.entities[idUserNewsletter]?.newsletter) {
              return entities;
            }
            entities[idUserNewsletter] = {
              ...state.entities[idUserNewsletter],
              newsletter: idNewsletters.some(
                (idNewsletter: number) => idNewsletter === state.entities[idUserNewsletter]?.newsletter
              )
                ? undefined
                : state.entities[idUserNewsletter]?.newsletter
            } as UserNewsletterEntityState;
            return entities;
          }, {} as Dictionary<UserNewsletterEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UserNewsletterState.IState,
  isLoading: boolean,
  isLoaded = true
): UserNewsletterState.IState {
  return { ...state, isLoaded, isLoading };
}
