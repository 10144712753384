export const defaultResponse = 'Non répondu';

export const defaultColors = [
  '#ff9301',
  '#ffce4e',
  '#ffb202',
  '#ffaa01',
  '#ffc428',
  '#ffdc81',
  '#ffa201',
  '#ffb902',
  '#fff7e1',
  '#ffeab3',
  '#ffe1bf',
  '#ffd5a6',
  '#fff9f2',
  '#ffffff'
];

export const featureDisabled = 'Fonctionnalité indisponible sans connexion';

export const defaultRegexPhoneNumber = new RegExp(
  /^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-9]{1}([ .\-]?)(?:\d{2}\1?){3}\d{2}$/
);

export const defaultIconComposant = 'en_attente.svg';
export const defaultIconComposantGroupe = 'en_attente_jaune.svg';
