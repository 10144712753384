import { createEntityAdapter } from '@ngrx/entity';
import { UsageEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<UsageEntityState>;

export const adapter = createEntityAdapter<UsageEntityState>({
  selectId: o => o.idUsage
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const usageFeatureKey = 'usage';
