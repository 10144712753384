import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgPatrimoineInfo, ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgComposantState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progComposantRelations: string[] = ['progBiblioInterventions','progInterventions','progPatrimoineInfos','patrimoines','societes','progComposantSousGroupes','societeComposants',];

export const { selectEntities, selectAll } = ProgComposantState.adapter.getSelectors();

export const selectProgComposantState = createFeatureSelector<ProgComposantState.IState>(ProgComposantState.progComposantFeatureKey);

export const selectIsLoadedProgComposant = createSelector(
  selectProgComposantState,
  (state: ProgComposantState.IState) => state.isLoaded
);

export const selectIsLoadingProgComposant = createSelector(
  selectProgComposantState,
  (state: ProgComposantState.IState) => state.isLoading
);

export const selectIsReadyProgComposant = createSelector(
  selectProgComposantState,
  (state: ProgComposantState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgComposant = createSelector(
  selectProgComposantState,
  (state: ProgComposantState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgComposantsEntities = createSelector(selectProgComposantState, selectEntities);

export const selectProgComposantsArray = createSelector(selectProgComposantState, selectAll);

const progComposantsInObject = (progComposants: Dictionary<ProgComposantEntityState>) => ({ progComposants })

const selectProgComposantsEntitiesDictionary = createSelector(selectProgComposantsEntities, progComposantsInObject);

const selectAllProgComposantsObject = createSelector(selectProgComposantsEntities, progComposants => {
  return hydrateAll({ progComposants });
});

const selectOneProgComposantDictionary = (idProgComposant : number) =>
  createSelector(selectProgComposantsEntities, progComposants => ({
    progComposants: { [idProgComposant]: progComposants[idProgComposant] }
  }));

const selectOneProgComposantDictionaryWithoutChild = (idProgComposant : number) =>
  createSelector(selectProgComposantsEntities, progComposants => ({
    progComposant: progComposants[idProgComposant]
  }));

const selectAllProgComposantsSelectors: Dictionary<Selector> = {};
export function selectAllProgComposants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgComposant>(
      schema,
      selectAllProgComposantsSelectors,
      selectProgComposantsEntitiesDictionary,
      getRelationSelectors,
      progComposantRelations,
      hydrateAll,
      'progComposant'
    );
  } else {
    return selectAllProgComposantsObject;
  }
}

export function selectAllProgComposantsDictionary(
  schema: SelectSchema = {},
  customKey = 'progComposants'
): Selector {
  return createSelector(selectAllProgComposants(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgComposantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progComposants.length; i++) {
      res[customKey][result.progComposants[i].idProgComposant] = result.progComposants[i];
    }
    return res;
  });
}

export function selectOneProgComposant(
  schema: SelectSchema = {},
  idProgComposant: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgComposantDictionary(idProgComposant)];
  selectors.push(...getRelationSelectors(schema, progComposantRelations, 'progComposant'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgComposantDictionaryWithoutChild(idProgComposant);
  }
}

interface hydrateArgs {
  progComposants: Dictionary<ProgComposantEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progComposantSousGroupes?: Dictionary<ProgComposantSousGroupeEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
  progPatrimoineInfos?: Dictionary<ProgPatrimoineInfoEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progComposants: (ProgComposant | null)[] } {
  const {
    progComposants,
    societes,
    progComposantSousGroupes,
    societeComposants,
    progBiblioInterventions,
    progInterventions,
    progPatrimoineInfos,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progComposants: Object.keys(progComposants).map(idProgComposant =>
      hydrate(
        progComposants[idProgComposant] as ProgComposantEntityState,
        societes,
        progComposantSousGroupes,
        societeComposants,
        progBiblioInterventions,
        progInterventions,
        progPatrimoineInfos,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progComposant: ProgComposantEntityState | null } {
  const {
    progComposants,
    societes,
    progComposantSousGroupes,
    societeComposants,
    progBiblioInterventions,
    progInterventions,
    progPatrimoineInfos,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progComposant = Object.values(progComposants)[0];
  return {
    progComposant: hydrate(
      progComposant as ProgComposantEntityState,
      societes,
      progComposantSousGroupes,
      societeComposants,
      progBiblioInterventions,
      progInterventions,
      progPatrimoineInfos,
      patrimoines
    )
  };
}

function hydrate(
  progComposant: ProgComposantEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progComposantSousGroupeEntities?: Dictionary<ProgComposantSousGroupeEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
  progPatrimoineInfoEntities?: Dictionary<ProgPatrimoineInfoEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): ProgComposant | null {
  if (!progComposant) {
    return null;
  }

  const progComposantHydrated: ProgComposantEntityState = { ...progComposant };
  if (societeEntities) {
    progComposantHydrated.societe = societeEntities[progComposant.societe as number] as Societe;
  } else {
    delete progComposantHydrated.societe;
  }
  if (progComposantSousGroupeEntities) {
    progComposantHydrated.progComposantSousGroupe = progComposantSousGroupeEntities[progComposant.progComposantSousGroupe as number] as ProgComposantSousGroupe;
  } else {
    delete progComposantHydrated.progComposantSousGroupe;
  }
  if (societeComposantEntities) {
    progComposantHydrated.societeComposant = societeComposantEntities[progComposant.societeComposant as number] as SocieteComposant;
  } else {
    delete progComposantHydrated.societeComposant;
  }

  if (progBiblioInterventionEntities) {
    progComposantHydrated.progBiblioInterventions = ((progComposantHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete progComposantHydrated.progBiblioInterventions;
  }

  if (progInterventionEntities) {
    progComposantHydrated.progInterventions = ((progComposantHydrated.progInterventions as number[]) || []).map(
      id => progInterventionEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progComposantHydrated.progInterventions;
  }

  if (progPatrimoineInfoEntities) {
    progComposantHydrated.progPatrimoineInfos = ((progComposantHydrated.progPatrimoineInfos as number[]) || []).map(
      id => progPatrimoineInfoEntities[id]
    ) as ProgPatrimoineInfo[];
  } else {
    delete progComposantHydrated.progPatrimoineInfos;
  }

  if (patrimoineEntities) {
    progComposantHydrated.patrimoines = ((progComposantHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete progComposantHydrated.patrimoines;
  }

  return progComposantHydrated as ProgComposant;
}
