import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModulePathKeyEnum } from '@enums';
import { OrganisationService } from '@get/store/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationAccesProgrammationGuard {
  private retries = 0;

  constructor(private organisationService: OrganisationService, private router: Router) {}

  public canLoad(): Observable<boolean> | boolean {
    this.checkCurrentOrganisation();
    return true;
  }

  private checkCurrentOrganisation(): void {
    if (!this.organisationService.currentOrganisation) {
      if (this.retries <= 10) {
        this.retries++;
        setTimeout(() => {
          this.checkCurrentOrganisation();
        }, 500);
      }
    } else {
      this.retries = 0;
      if (!this.organisationService.currentOrganisation?.accesProgrammation) {
        this.router.navigate(['app', ModulePathKeyEnum.consult]);
      }
    }
  }
}
