import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionScenario, ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgBiblioInterventionState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progBiblioInterventionRelations: string[] = ['progInterventions','progBiblioInterventionScenarios','progScenarios','societes','progComposants','progBudgets','progUnites','progInterventionFamilles',];

export const { selectEntities, selectAll } = ProgBiblioInterventionState.adapter.getSelectors();

export const selectProgBiblioInterventionState = createFeatureSelector<ProgBiblioInterventionState.IState>(ProgBiblioInterventionState.progBiblioInterventionFeatureKey);

export const selectIsLoadedProgBiblioIntervention = createSelector(
  selectProgBiblioInterventionState,
  (state: ProgBiblioInterventionState.IState) => state.isLoaded
);

export const selectIsLoadingProgBiblioIntervention = createSelector(
  selectProgBiblioInterventionState,
  (state: ProgBiblioInterventionState.IState) => state.isLoading
);

export const selectIsReadyProgBiblioIntervention = createSelector(
  selectProgBiblioInterventionState,
  (state: ProgBiblioInterventionState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgBiblioIntervention = createSelector(
  selectProgBiblioInterventionState,
  (state: ProgBiblioInterventionState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgBiblioInterventionsEntities = createSelector(selectProgBiblioInterventionState, selectEntities);

export const selectProgBiblioInterventionsArray = createSelector(selectProgBiblioInterventionState, selectAll);

const progBiblioInterventionsInObject = (progBiblioInterventions: Dictionary<ProgBiblioInterventionEntityState>) => ({ progBiblioInterventions })

const selectProgBiblioInterventionsEntitiesDictionary = createSelector(selectProgBiblioInterventionsEntities, progBiblioInterventionsInObject);

const selectAllProgBiblioInterventionsObject = createSelector(selectProgBiblioInterventionsEntities, progBiblioInterventions => {
  return hydrateAll({ progBiblioInterventions });
});

const selectOneProgBiblioInterventionDictionary = (idProgBiblioIntervention : number) =>
  createSelector(selectProgBiblioInterventionsEntities, progBiblioInterventions => ({
    progBiblioInterventions: { [idProgBiblioIntervention]: progBiblioInterventions[idProgBiblioIntervention] }
  }));

const selectOneProgBiblioInterventionDictionaryWithoutChild = (idProgBiblioIntervention : number) =>
  createSelector(selectProgBiblioInterventionsEntities, progBiblioInterventions => ({
    progBiblioIntervention: progBiblioInterventions[idProgBiblioIntervention]
  }));

const selectAllProgBiblioInterventionsSelectors: Dictionary<Selector> = {};
export function selectAllProgBiblioInterventions(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgBiblioIntervention>(
      schema,
      selectAllProgBiblioInterventionsSelectors,
      selectProgBiblioInterventionsEntitiesDictionary,
      getRelationSelectors,
      progBiblioInterventionRelations,
      hydrateAll,
      'progBiblioIntervention'
    );
  } else {
    return selectAllProgBiblioInterventionsObject;
  }
}

export function selectAllProgBiblioInterventionsDictionary(
  schema: SelectSchema = {},
  customKey = 'progBiblioInterventions'
): Selector {
  return createSelector(selectAllProgBiblioInterventions(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgBiblioInterventionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progBiblioInterventions.length; i++) {
      res[customKey][result.progBiblioInterventions[i].idProgBiblioIntervention] = result.progBiblioInterventions[i];
    }
    return res;
  });
}

export function selectOneProgBiblioIntervention(
  schema: SelectSchema = {},
  idProgBiblioIntervention: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgBiblioInterventionDictionary(idProgBiblioIntervention)];
  selectors.push(...getRelationSelectors(schema, progBiblioInterventionRelations, 'progBiblioIntervention'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgBiblioInterventionDictionaryWithoutChild(idProgBiblioIntervention);
  }
}

interface hydrateArgs {
  progBiblioInterventions: Dictionary<ProgBiblioInterventionEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
  progBudgets?: Dictionary<ProgBudgetEntityState>;
  progUnites?: Dictionary<ProgUniteEntityState>;
  progInterventionFamilles?: Dictionary<ProgInterventionFamilleEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
  progBiblioInterventionScenarios?: Dictionary<ProgBiblioInterventionScenarioEntityState>;
  progScenarios?: Dictionary<ProgScenarioEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progBiblioInterventions: (ProgBiblioIntervention | null)[] } {
  const {
    progBiblioInterventions,
    societes,
    progComposants,
    progBudgets,
    progUnites,
    progInterventionFamilles,
    progInterventions,
    progBiblioInterventionScenarios,
    progScenarios
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progBiblioInterventions: Object.keys(progBiblioInterventions).map(idProgBiblioIntervention =>
      hydrate(
        progBiblioInterventions[idProgBiblioIntervention] as ProgBiblioInterventionEntityState,
        societes,
        progComposants,
        progBudgets,
        progUnites,
        progInterventionFamilles,
        progInterventions,
        progBiblioInterventionScenarios,
        progScenarios
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progBiblioIntervention: ProgBiblioInterventionEntityState | null } {
  const {
    progBiblioInterventions,
    societes,
    progComposants,
    progBudgets,
    progUnites,
    progInterventionFamilles,
    progInterventions,
    progBiblioInterventionScenarios,
    progScenarios
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progBiblioIntervention = Object.values(progBiblioInterventions)[0];
  return {
    progBiblioIntervention: hydrate(
      progBiblioIntervention as ProgBiblioInterventionEntityState,
      societes,
      progComposants,
      progBudgets,
      progUnites,
      progInterventionFamilles,
      progInterventions,
      progBiblioInterventionScenarios,
      progScenarios
    )
  };
}

function hydrate(
  progBiblioIntervention: ProgBiblioInterventionEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
  progBudgetEntities?: Dictionary<ProgBudgetEntityState>,
  progUniteEntities?: Dictionary<ProgUniteEntityState>,
  progInterventionFamilleEntities?: Dictionary<ProgInterventionFamilleEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
  progBiblioInterventionScenarioEntities?: Dictionary<ProgBiblioInterventionScenarioEntityState>,
  progScenarioEntities?: Dictionary<ProgScenarioEntityState>,
): ProgBiblioIntervention | null {
  if (!progBiblioIntervention) {
    return null;
  }

  const progBiblioInterventionHydrated: ProgBiblioInterventionEntityState = { ...progBiblioIntervention };
  if (societeEntities) {
    progBiblioInterventionHydrated.societe = societeEntities[progBiblioIntervention.societe as number] as Societe;
  } else {
    delete progBiblioInterventionHydrated.societe;
  }
  if (progComposantEntities) {
    progBiblioInterventionHydrated.progComposant = progComposantEntities[progBiblioIntervention.progComposant as number] as ProgComposant;
  } else {
    delete progBiblioInterventionHydrated.progComposant;
  }
  if (progBudgetEntities) {
    progBiblioInterventionHydrated.progBudget = progBudgetEntities[progBiblioIntervention.progBudget as number] as ProgBudget;
  } else {
    delete progBiblioInterventionHydrated.progBudget;
  }
  if (progUniteEntities) {
    progBiblioInterventionHydrated.progUnite = progUniteEntities[progBiblioIntervention.progUnite as number] as ProgUnite;
  } else {
    delete progBiblioInterventionHydrated.progUnite;
  }
  if (progInterventionFamilleEntities) {
    progBiblioInterventionHydrated.progInterventionFamille = progInterventionFamilleEntities[progBiblioIntervention.progInterventionFamille as number] as ProgInterventionFamille;
  } else {
    delete progBiblioInterventionHydrated.progInterventionFamille;
  }

  if (progInterventionEntities) {
    progBiblioInterventionHydrated.progInterventions = ((progBiblioInterventionHydrated.progInterventions as number[]) || []).map(
      id => progInterventionEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progBiblioInterventionHydrated.progInterventions;
  }

  if (progBiblioInterventionScenarioEntities) {
    progBiblioInterventionHydrated.progBiblioInterventionScenarios = ((progBiblioInterventionHydrated.progBiblioInterventionScenarios as number[]) || []).map(
      id => progBiblioInterventionScenarioEntities[id]
    ) as ProgBiblioInterventionScenario[];
  } else {
    delete progBiblioInterventionHydrated.progBiblioInterventionScenarios;
  }

  if (progScenarioEntities) {
    progBiblioInterventionHydrated.progScenarios = ((progBiblioInterventionHydrated.progScenarios as number[]) || []).map(
      id => progScenarioEntities[id]
    ) as ProgScenario[];
  } else {
    delete progBiblioInterventionHydrated.progScenarios;
  }

  return progBiblioInterventionHydrated as ProgBiblioIntervention;
}
