import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { PatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const PatrimoineGeneratedActions = createActionGroup({
  source: 'Patrimoine Generated',
  events: {
    'Get One Patrimoine': props<{ idPatrimoine: number; params?: any }>(),
    'Upsert One Patrimoine': props<{ patrimoine: Partial<Patrimoine>; ids?: PatrimoineRelationsIds; }>(),
    'Upsert Many Patrimoines': props<{ patrimoines: Partial<Patrimoine>[]; ids?: PatrimoineRelationsIds; }>(),
    'Upsert Many Patrimoines Success': props<{ patrimoines: PatrimoineEntityState[] }>(),
    'Delete One Patrimoine Success': props<{ idPatrimoine: number }>(),
    'Normalize Many Patrimoines After Upsert': props<{ patrimoines: PatrimoineEntityState[] }>(),
    'Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Patrimoines': emptyProps(),
    'Add Many User Patrimoine Success': props<{ idPatrimoine: number; idUserPatrimoines: number[] }>(),
    'Delete Many User Patrimoine Success': props<{ idUserPatrimoines: number[]; idPatrimoines: number[] }>(),
    'Add Many User Success': props<{ idPatrimoine: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idPatrimoines: number[] }>(),
    'Add Many Valeur Patrimoine Success': props<{ idPatrimoine: number; idValeurPatrimoines: number[] }>(),
    'Delete Many Valeur Patrimoine Success': props<{ idValeurPatrimoines: number[]; idPatrimoines: number[] }>(),
    'Add Many Valeur Success': props<{ idPatrimoine: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idPatrimoines: number[] }>(),
    'Add Many Ancetres Success': props<{ idPatrimoine: number; idAncetres: number[] }>(),
    'Delete Many Ancetres Success': props<{ idAncetres: number[]; idPatrimoines: number[] }>(),
    'Add Many Descendants Success': props<{ idPatrimoine: number; idDescendants: number[] }>(),
    'Delete Many Descendants Success': props<{ idDescendants: number[]; idPatrimoines: number[] }>(),
    'Add Many Espace Success': props<{ idPatrimoine: number; idEspaces: number[] }>(),
    'Delete Many Espace Success': props<{ idEspaces: number[]; idPatrimoines: number[] }>(),
    'Add Many Campagne Patrimoine Success': props<{ idPatrimoine: number; idCampagnePatrimoines: number[] }>(),
    'Delete Many Campagne Patrimoine Success': props<{ idCampagnePatrimoines: number[]; idPatrimoines: number[] }>(),
    'Add Many Campagne Success': props<{ idPatrimoine: number; idCampagnes: number[] }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idPatrimoines: number[] }>(),
    'Add Many Societe Territoire Patrimoine Success': props<{ idPatrimoine: number; idSocieteTerritoirePatrimoines: number[] }>(),
    'Delete Many Societe Territoire Patrimoine Success': props<{ idSocieteTerritoirePatrimoines: number[]; idPatrimoines: number[] }>(),
    'Add Many Societe Territoire Success': props<{ idPatrimoine: number; idSocieteTerritoires: number[] }>(),
    'Delete Many Societe Territoire Success': props<{ idSocieteTerritoires: number[]; idPatrimoines: number[] }>(),
    'Add Many Composant Attendu Success': props<{ idPatrimoine: number; idComposantAttendus: number[] }>(),
    'Delete Many Composant Attendu Success': props<{ idComposantAttendus: number[]; idPatrimoines: number[] }>(),
    'Add Many Prog Patrimoine Info Success': props<{ idPatrimoine: number; idProgPatrimoineInfos: number[] }>(),
    'Delete Many Prog Patrimoine Info Success': props<{ idProgPatrimoineInfos: number[]; idPatrimoines: number[] }>(),
    'Add Many Prog Composant Success': props<{ idPatrimoine: number; idProgComposants: number[] }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idPatrimoines: number[] }>(),
    'Add Many Prog Intervention Success': props<{ idPatrimoine: number; idProgInterventions: number[] }>(),
    'Delete Many Prog Intervention Success': props<{ idProgInterventions: number[]; idPatrimoines: number[] }>(),
    'Add Societe Patrimoine Hierarchie Success': props<{ idPatrimoine: number; idSocietePatrimoineHierarchie: number }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idPatrimoines: number[] }>(),
    'Add Societe Success': props<{ idPatrimoine: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idPatrimoines: number[] }>()
  }
});
