import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgEtatTechniqueApiService } from './prog-etat-technique-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgEtatTechniqueApiService extends GeneratedProgEtatTechniqueApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
