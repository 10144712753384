import { createEntityAdapter } from '@ngrx/entity';
import { UserPatrimoineEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<UserPatrimoineEntityState>;

export const adapter = createEntityAdapter<UserPatrimoineEntityState>({
  selectId: o => o.idUserPatrimoine
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const userPatrimoineFeatureKey = 'userPatrimoine';
