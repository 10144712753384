import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgBudgetApiService } from '@get/store/api-services';
import { GeneratedProgBudgetEffects } from './prog-budget-generated.effects';

@Injectable()
export class ProgBudgetEffects extends GeneratedProgBudgetEffects {
  constructor(actions$: Actions, progBudgetApiService: ProgBudgetApiService, store$: Store<AppState>) {
    super(actions$, progBudgetApiService, store$);
  }
}
