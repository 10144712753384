import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurProgInterventionGeneratedActions } from '@get/store/actions';
import { ValeurProgInterventionState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurProgInterventionEntityState } from '@get/api-interfaces';

export const valeurProgInterventionReducersGeneratedFunctions: ReducerTypes<ValeurProgInterventionState.IState, readonly ActionCreator[]>[] = [
  on(ValeurProgInterventionGeneratedActions.upsertOneValeurProgIntervention, (state: ValeurProgInterventionState.IState) => setLoadingsState(state, true)),

  on(ValeurProgInterventionGeneratedActions.upsertManyValeurProgInterventionsSuccess, (state: ValeurProgInterventionState.IState, { valeurProgInterventions }) =>
    ValeurProgInterventionState.adapter.upsertMany(valeurProgInterventions, setLoadingsState(state, false))
  ),
  on(ValeurProgInterventionGeneratedActions.deleteOneValeurProgInterventionSuccess, (state: ValeurProgInterventionState.IState, { idValeurProgIntervention }) =>
    ValeurProgInterventionState.adapter.removeOne(idValeurProgIntervention, setLoadingsState(state, false))
  ),

  on(ValeurProgInterventionGeneratedActions.clearValeurProgInterventions, () => ValeurProgInterventionState.initialState),
];

export function setLoadingsState(
  state: ValeurProgInterventionState.IState,
  isLoading: boolean,
  isLoaded = true
): ValeurProgInterventionState.IState {
  return { ...state, isLoaded, isLoading };
}
