import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgInterventionPatrimoine, ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { ProgInterventionPatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const ProgInterventionPatrimoineGeneratedActions = createActionGroup({
  source: 'Prog Intervention Patrimoine Generated',
  events: {
    'Upsert One Prog Intervention Patrimoine': props<{ progInterventionPatrimoine: Partial<ProgInterventionPatrimoine>; ids?: ProgInterventionPatrimoineRelationsIds; }>(),
    'Upsert Many Prog Intervention Patrimoines': props<{ progInterventionPatrimoines: Partial<ProgInterventionPatrimoine>[]; ids?: ProgInterventionPatrimoineRelationsIds; }>(),
    'Upsert Many Prog Intervention Patrimoines Success': props<{ progInterventionPatrimoines: ProgInterventionPatrimoineEntityState[] }>(),
    'Delete One Prog Intervention Patrimoine Success': props<{ idProgInterventionPatrimoine: number }>(),
    'Normalize Many Prog Intervention Patrimoines After Upsert': props<{ progInterventionPatrimoines: ProgInterventionPatrimoineEntityState[] }>(),
    'Prog Intervention Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Intervention Patrimoines': emptyProps()
  }
});
