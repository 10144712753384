import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgInterventionRelationsIds } from '@get/store/ids-interfaces';

export const ProgInterventionGeneratedActions = createActionGroup({
  source: 'Prog Intervention Generated',
  events: {
    'Upsert One Prog Intervention': props<{ progIntervention: Partial<ProgIntervention>; ids?: ProgInterventionRelationsIds; }>(),
    'Upsert Many Prog Interventions': props<{ progInterventions: Partial<ProgIntervention>[]; ids?: ProgInterventionRelationsIds; }>(),
    'Upsert Many Prog Interventions Success': props<{ progInterventions: ProgInterventionEntityState[] }>(),
    'Delete One Prog Intervention Success': props<{ idProgIntervention: number }>(),
    'Normalize Many Prog Interventions After Upsert': props<{ progInterventions: ProgInterventionEntityState[] }>(),
    'Prog Interventions Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Interventions': emptyProps(),
    'Add Many Patrimoine Success': props<{ idProgIntervention: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idProgInterventions: number[] }>()
  }
});
