import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { OrganisationNewsletterApiService } from '@get/store/api-services';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeOrganisationNewsletter } from '@get/store/configs/normalization';

export class GeneratedOrganisationNewsletterEffects {
  constructor(
    protected actions$: Actions,
    protected organisationNewsletterApiService: OrganisationNewsletterApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyOrganisationNewslettersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert),
      concatMap(({ organisationNewsletters }) => {
        const actions: Action[] = getActionsToNormalizeOrganisationNewsletter(organisationNewsletters, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganisationNewsletter] Normalization After Upsert Success')];
      })
    );
  });
}
