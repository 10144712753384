import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UserNewsletterState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const userNewsletterRelations: string[] = ['users','newsletters',];

export const { selectEntities, selectAll } = UserNewsletterState.adapter.getSelectors();

export const selectUserNewsletterState = createFeatureSelector<UserNewsletterState.IState>(UserNewsletterState.userNewsletterFeatureKey);

export const selectIsLoadedUserNewsletter = createSelector(
  selectUserNewsletterState,
  (state: UserNewsletterState.IState) => state.isLoaded
);

export const selectIsLoadingUserNewsletter = createSelector(
  selectUserNewsletterState,
  (state: UserNewsletterState.IState) => state.isLoading
);

export const selectIsReadyUserNewsletter = createSelector(
  selectUserNewsletterState,
  (state: UserNewsletterState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserNewsletter = createSelector(
  selectUserNewsletterState,
  (state: UserNewsletterState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserNewslettersEntities = createSelector(selectUserNewsletterState, selectEntities);

export const selectUserNewslettersArray = createSelector(selectUserNewsletterState, selectAll);

const userNewslettersInObject = (userNewsletters: Dictionary<UserNewsletterEntityState>) => ({ userNewsletters })

const selectUserNewslettersEntitiesDictionary = createSelector(selectUserNewslettersEntities, userNewslettersInObject);

const selectAllUserNewslettersObject = createSelector(selectUserNewslettersEntities, userNewsletters => {
  return hydrateAll({ userNewsletters });
});

const selectOneUserNewsletterDictionary = (idUserNewsletter : number) =>
  createSelector(selectUserNewslettersEntities, userNewsletters => ({
    userNewsletters: { [idUserNewsletter]: userNewsletters[idUserNewsletter] }
  }));

const selectOneUserNewsletterDictionaryWithoutChild = (idUserNewsletter : number) =>
  createSelector(selectUserNewslettersEntities, userNewsletters => ({
    userNewsletter: userNewsletters[idUserNewsletter]
  }));

const selectAllUserNewslettersSelectors: Dictionary<Selector> = {};
export function selectAllUserNewsletters(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserNewsletter>(
      schema,
      selectAllUserNewslettersSelectors,
      selectUserNewslettersEntitiesDictionary,
      getRelationSelectors,
      userNewsletterRelations,
      hydrateAll,
      'userNewsletter'
    );
  } else {
    return selectAllUserNewslettersObject;
  }
}

export function selectAllUserNewslettersDictionary(
  schema: SelectSchema = {},
  customKey = 'userNewsletters'
): Selector {
  return createSelector(selectAllUserNewsletters(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserNewsletterEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userNewsletters.length; i++) {
      res[customKey][result.userNewsletters[i].idUserNewsletter] = result.userNewsletters[i];
    }
    return res;
  });
}

export function selectOneUserNewsletter(
  schema: SelectSchema = {},
  idUserNewsletter: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUserNewsletterDictionary(idUserNewsletter)];
  selectors.push(...getRelationSelectors(schema, userNewsletterRelations, 'userNewsletter'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserNewsletterDictionaryWithoutChild(idUserNewsletter);
  }
}

interface hydrateArgs {
  userNewsletters: Dictionary<UserNewsletterEntityState>;
  users?: Dictionary<UserEntityState>;
  newsletters?: Dictionary<NewsletterEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userNewsletters: (UserNewsletter | null)[] } {
  const {
    userNewsletters,
    users,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userNewsletters: Object.keys(userNewsletters).map(idUserNewsletter =>
      hydrate(
        userNewsletters[idUserNewsletter] as UserNewsletterEntityState,
        users,
        newsletters
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userNewsletter: UserNewsletterEntityState | null } {
  const {
    userNewsletters,
    users,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userNewsletter = Object.values(userNewsletters)[0];
  return {
    userNewsletter: hydrate(
      userNewsletter as UserNewsletterEntityState,
      users,
      newsletters
    )
  };
}

function hydrate(
  userNewsletter: UserNewsletterEntityState,
  userEntities?: Dictionary<UserEntityState>,
  newsletterEntities?: Dictionary<NewsletterEntityState>,
): UserNewsletter | null {
  if (!userNewsletter) {
    return null;
  }

  const userNewsletterHydrated: UserNewsletterEntityState = { ...userNewsletter };
  if (userEntities) {
    userNewsletterHydrated.user = userEntities[userNewsletter.user as number] as User;
  } else {
    delete userNewsletterHydrated.user;
  }
  if (newsletterEntities) {
    userNewsletterHydrated.newsletter = newsletterEntities[userNewsletter.newsletter as number] as Newsletter;
  } else {
    delete userNewsletterHydrated.newsletter;
  }

  return userNewsletterHydrated as UserNewsletter;
}
