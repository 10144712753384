import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { OrganisationState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganisationEntityState } from '@get/api-interfaces';

export const organisationReducersGeneratedFunctions: ReducerTypes<OrganisationState.IState, readonly ActionCreator[]>[] = [
  on(OrganisationGeneratedActions.getManyOrganisations, (state: OrganisationState.IState) => setLoadingsState(state, true)),
  on(OrganisationGeneratedActions.upsertOneOrganisation, (state: OrganisationState.IState) => setLoadingsState(state, true)),

  on(OrganisationGeneratedActions.upsertManyOrganisationsSuccess, (state: OrganisationState.IState, { organisations }) =>
    OrganisationState.adapter.upsertMany(organisations, setLoadingsState(state, false))
  ),
  on(OrganisationGeneratedActions.deleteOneOrganisationSuccess, (state: OrganisationState.IState, { idOrganisation }) =>
    OrganisationState.adapter.removeOne(idOrganisation, setLoadingsState(state, false))
  ),

  on(OrganisationGeneratedActions.clearOrganisations, () => OrganisationState.initialState),

  on(
    OrganisationGeneratedActions.addManySocieteSuccess,
    (state: OrganisationState.IState, { idOrganisation, idSocietes }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const societes = (state.entities[idOrganisation]?.societes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            societes: societes.concat(
              idSocietes.filter(id => societes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManySocieteSuccess,
    (state: OrganisationState.IState, { idSocietes, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.societes) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              societes: (state.entities[idOrganisation]?.societes as number[])?.filter(
                (idSociete: number) => !idSocietes.some((id: number) => id === idSociete)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.addManyUserSuccess,
    (state: OrganisationState.IState, { idOrganisation, idUsers }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const users = (state.entities[idOrganisation]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            users: users.concat(
              idUsers.filter(id => users.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManyUserSuccess,
    (state: OrganisationState.IState, { idUsers, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.users) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              users: (state.entities[idOrganisation]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.addManyFichierSuccess,
    (state: OrganisationState.IState, { idOrganisation, idFichiers }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const fichiers = (state.entities[idOrganisation]?.fichiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            fichiers: fichiers.concat(
              idFichiers.filter(id => fichiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManyFichierSuccess,
    (state: OrganisationState.IState, { idFichiers, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.fichiers) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              fichiers: (state.entities[idOrganisation]?.fichiers as number[])?.filter(
                (idFichier: number) => !idFichiers.some((id: number) => id === idFichier)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.addManyOrganisationAzureLoginSuccess,
    (state: OrganisationState.IState, { idOrganisation, idOrganisationAzureLogins }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const organisationAzureLogins = (state.entities[idOrganisation]?.organisationAzureLogins as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            organisationAzureLogins: organisationAzureLogins.concat(
              idOrganisationAzureLogins.filter(id => organisationAzureLogins.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManyOrganisationAzureLoginSuccess,
    (state: OrganisationState.IState, { idOrganisationAzureLogins, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.organisationAzureLogins) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              organisationAzureLogins: (state.entities[idOrganisation]?.organisationAzureLogins as number[])?.filter(
                (idOrganisationAzureLogin: number) => !idOrganisationAzureLogins.some((id: number) => id === idOrganisationAzureLogin)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.addManyOrganisationNewsletterSuccess,
    (state: OrganisationState.IState, { idOrganisation, idOrganisationNewsletters }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const organisationNewsletters = (state.entities[idOrganisation]?.organisationNewsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            organisationNewsletters: organisationNewsletters.concat(
              idOrganisationNewsletters.filter(id => organisationNewsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManyOrganisationNewsletterSuccess,
    (state: OrganisationState.IState, { idOrganisationNewsletters, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.organisationNewsletters) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              organisationNewsletters: (state.entities[idOrganisation]?.organisationNewsletters as number[])?.filter(
                (idOrganisationNewsletter: number) => !idOrganisationNewsletters.some((id: number) => id === idOrganisationNewsletter)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.addManyNewsletterSuccess,
    (state: OrganisationState.IState, { idOrganisation, idNewsletters }) => {
      if (!state.entities[idOrganisation]) {
        return state;
      }
      const newsletters = (state.entities[idOrganisation]?.newsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisation]: {
            ...state.entities[idOrganisation],
            newsletters: newsletters.concat(
              idNewsletters.filter(id => newsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganisationGeneratedActions.deleteManyNewsletterSuccess,
    (state: OrganisationState.IState, { idNewsletters, idOrganisations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisations.reduce((entities, idOrganisation) => {
            if (!state.entities[idOrganisation]?.newsletters) {
              return entities;
            }
            entities[idOrganisation] = {
              ...state.entities[idOrganisation],
              newsletters: (state.entities[idOrganisation]?.newsletters as number[])?.filter(
                (idNewsletter: number) => !idNewsletters.some((id: number) => id === idNewsletter)
              )
            } as OrganisationEntityState;
            return entities;
          }, {} as Dictionary<OrganisationEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: OrganisationState.IState,
  isLoading: boolean,
  isLoaded = true
): OrganisationState.IState {
  return { ...state, isLoaded, isLoading };
}
