import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixRelationsIds } from '@get/store/ids-interfaces';

export const SocieteCaracteristiqueChoixGeneratedActions = createActionGroup({
  source: 'Societe Caracteristique Choix Generated',
  events: {
    'Upsert One Societe Caracteristique Choix': props<{ societeCaracteristiqueChoix: Partial<SocieteCaracteristiqueChoix>; ids?: SocieteCaracteristiqueChoixRelationsIds; }>(),
    'Upsert Many Societe Caracteristique Choices': props<{ societeCaracteristiqueChoices: Partial<SocieteCaracteristiqueChoix>[]; ids?: SocieteCaracteristiqueChoixRelationsIds; }>(),
    'Upsert Many Societe Caracteristique Choices Success': props<{ societeCaracteristiqueChoices: SocieteCaracteristiqueChoixEntityState[] }>(),
    'Delete One Societe Caracteristique Choix Success': props<{ idSocieteCaracteristiqueChoix: number }>(),
    'Normalize Many Societe Caracteristique Choices After Upsert': props<{ societeCaracteristiqueChoices: SocieteCaracteristiqueChoixEntityState[] }>(),
    'Societe Caracteristique Choices Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Caracteristique Choices': emptyProps(),
    'Add Many Valeur Success': props<{ idSocieteCaracteristiqueChoix: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idSocieteCaracteristiqueChoices: number[] }>(),
    'Add Societe Caracteristique Success': props<{ idSocieteCaracteristiqueChoix: number; idSocieteCaracteristique: number }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocieteCaracteristiqueChoices: number[] }>(),
    'Add Fichier Success': props<{ idSocieteCaracteristiqueChoix: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idSocieteCaracteristiqueChoices: number[] }>(),
    'Add Caracteristique Choix Template Success': props<{ idSocieteCaracteristiqueChoix: number; idCaracteristiqueChoixTemplate: number }>(),
    'Delete Many Caracteristique Choix Template Success': props<{ idCaracteristiqueChoixTemplates: number[]; idSocieteCaracteristiqueChoices: number[] }>()
  }
});
