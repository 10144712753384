import { Injectable } from '@angular/core';
import {
  AssertEmptyComposantAttenduInterface,
  ComposantAttendu,
  SynchronizeResultInterface
} from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedComposantAttenduApiService } from './composant-attendu-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ComposantAttenduApiService extends GeneratedComposantAttenduApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public upsertManyComposantAttendus(composantAttendus: Partial<ComposantAttendu>[]): Observable<ComposantAttendu[]> {
    return this.repo.update<ComposantAttendu[]>('composant-attendu/many', { composantAttendus });
  }

  public synchronizeComposantAttendus(idSociete?: number): Observable<SynchronizeResultInterface> {
    return this.repo.create<SynchronizeResultInterface>(
      `composant-attendu/synchronize${idSociete ? '/' + idSociete : ''}`,
      {}
    );
  }
}
