import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedProgBiblioInterventionApiService } from './prog-biblio-intervention-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgBiblioInterventionApiService extends GeneratedProgBiblioInterventionApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
