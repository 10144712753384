import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupeState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgComposantSousGroupeEntityState } from '@get/api-interfaces';

export const progComposantSousGroupeReducersGeneratedFunctions: ReducerTypes<ProgComposantSousGroupeState.IState, readonly ActionCreator[]>[] = [
  on(ProgComposantSousGroupeGeneratedActions.upsertOneProgComposantSousGroupe, (state: ProgComposantSousGroupeState.IState) => setLoadingsState(state, true)),

  on(ProgComposantSousGroupeGeneratedActions.upsertManyProgComposantSousGroupesSuccess, (state: ProgComposantSousGroupeState.IState, { progComposantSousGroupes }) =>
    ProgComposantSousGroupeState.adapter.upsertMany(progComposantSousGroupes, setLoadingsState(state, false))
  ),
  on(ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupe, (state: ProgComposantSousGroupeState.IState) => setLoadingsState(state, true)),
  on(ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupeSuccess, (state: ProgComposantSousGroupeState.IState, { idProgComposantSousGroupe }) =>
    ProgComposantSousGroupeState.adapter.removeOne(idProgComposantSousGroupe, setLoadingsState(state, false))
  ),

  on(ProgComposantSousGroupeGeneratedActions.clearProgComposantSousGroupes, () => ProgComposantSousGroupeState.initialState),

  on(
    ProgComposantSousGroupeGeneratedActions.addManyProgComposantSuccess,
    (state: ProgComposantSousGroupeState.IState, { idProgComposantSousGroupe, idProgComposants }) => {
      if (!state.entities[idProgComposantSousGroupe]) {
        return state;
      }
      const progComposants = (state.entities[idProgComposantSousGroupe]?.progComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposantSousGroupe]: {
            ...state.entities[idProgComposantSousGroupe],
            progComposants: progComposants.concat(
              idProgComposants.filter(id => progComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgComposantSousGroupeGeneratedActions.deleteManyProgComposantSuccess,
    (state: ProgComposantSousGroupeState.IState, { idProgComposants, idProgComposantSousGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposantSousGroupes.reduce((entities, idProgComposantSousGroupe) => {
            if (!state.entities[idProgComposantSousGroupe]?.progComposants) {
              return entities;
            }
            entities[idProgComposantSousGroupe] = {
              ...state.entities[idProgComposantSousGroupe],
              progComposants: (state.entities[idProgComposantSousGroupe]?.progComposants as number[])?.filter(
                (idProgComposant: number) => !idProgComposants.some((id: number) => id === idProgComposant)
              )
            } as ProgComposantSousGroupeEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantSousGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantSousGroupeGeneratedActions.addSocieteSuccess,
    (state: ProgComposantSousGroupeState.IState, { idProgComposantSousGroupe, idSociete }) => {
      if (!state.entities[idProgComposantSousGroupe]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposantSousGroupe]: {
            ...state.entities[idProgComposantSousGroupe],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgComposantSousGroupeGeneratedActions.deleteManySocieteSuccess,
    (state: ProgComposantSousGroupeState.IState, { idSocietes, idProgComposantSousGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposantSousGroupes.reduce((entities, idProgComposantSousGroupe) => {
            if (!state.entities[idProgComposantSousGroupe]?.societe) {
              return entities;
            }
            entities[idProgComposantSousGroupe] = {
              ...state.entities[idProgComposantSousGroupe],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgComposantSousGroupe]?.societe
              )
                ? undefined
                : state.entities[idProgComposantSousGroupe]?.societe
            } as ProgComposantSousGroupeEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantSousGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ProgComposantSousGroupeGeneratedActions.addProgComposantGroupeSuccess,
    (state: ProgComposantSousGroupeState.IState, { idProgComposantSousGroupe, idProgComposantGroupe }) => {
      if (!state.entities[idProgComposantSousGroupe]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgComposantSousGroupe]: {
            ...state.entities[idProgComposantSousGroupe],
            progComposantGroupe: idProgComposantGroupe
          }
        }
      };
    }
  ),

  on(
    ProgComposantSousGroupeGeneratedActions.deleteManyProgComposantGroupeSuccess,
    (state: ProgComposantSousGroupeState.IState, { idProgComposantGroupes, idProgComposantSousGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgComposantSousGroupes.reduce((entities, idProgComposantSousGroupe) => {
            if (!state.entities[idProgComposantSousGroupe]?.progComposantGroupe) {
              return entities;
            }
            entities[idProgComposantSousGroupe] = {
              ...state.entities[idProgComposantSousGroupe],
              progComposantGroupe: idProgComposantGroupes.some(
                (idProgComposantGroupe: number) => idProgComposantGroupe === state.entities[idProgComposantSousGroupe]?.progComposantGroupe
              )
                ? undefined
                : state.entities[idProgComposantSousGroupe]?.progComposantGroupe
            } as ProgComposantSousGroupeEntityState;
            return entities;
          }, {} as Dictionary<ProgComposantSousGroupeEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgComposantSousGroupeState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgComposantSousGroupeState.IState {
  return { ...state, isLoaded, isLoading };
}
