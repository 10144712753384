import { BoardStateReducer } from '@get/store/reducers';
import { BoardStateState } from '@get/store/states';
import { CampagneReducer } from '@get/store/reducers';
import { CampagneState } from '@get/store/states';
import { CampagnePatrimoineReducer } from '@get/store/reducers';
import { CampagnePatrimoineState } from '@get/store/states';
import { CampagneSocieteCaracteristiqueReducer } from '@get/store/reducers';
import { CampagneSocieteCaracteristiqueState } from '@get/store/states';
import { CampagneUserReducer } from '@get/store/reducers';
import { CampagneUserState } from '@get/store/states';
import { CaracteristiqueTemplateReducer } from '@get/store/reducers';
import { CaracteristiqueTemplateState } from '@get/store/states';
import { CaracteristiqueChoixTemplateReducer } from '@get/store/reducers';
import { CaracteristiqueChoixTemplateState } from '@get/store/states';
import { ComposantReducer } from '@get/store/reducers';
import { ComposantState } from '@get/store/states';
import { ComposantAttenduReducer } from '@get/store/reducers';
import { ComposantAttenduState } from '@get/store/states';
import { ComposantGroupeReducer } from '@get/store/reducers';
import { ComposantGroupeState } from '@get/store/states';
import { ComposantTemplateReducer } from '@get/store/reducers';
import { ComposantTemplateState } from '@get/store/states';
import { DroitReducer } from '@get/store/reducers';
import { DroitState } from '@get/store/states';
import { EspaceReducer } from '@get/store/reducers';
import { EspaceState } from '@get/store/states';
import { FichierReducer } from '@get/store/reducers';
import { FichierState } from '@get/store/states';
import { NewsletterReducer } from '@get/store/reducers';
import { NewsletterState } from '@get/store/states';
import { OrganisationAzureLoginReducer } from '@get/store/reducers';
import { OrganisationAzureLoginState } from '@get/store/states';
import { OrganisationReducer } from '@get/store/reducers';
import { OrganisationState } from '@get/store/states';
import { OrganisationNewsletterReducer } from '@get/store/reducers';
import { OrganisationNewsletterState } from '@get/store/states';
import { PatrimoineReducer } from '@get/store/reducers';
import { PatrimoineState } from '@get/store/states';
import { PatrimoineAncetreReducer } from '@get/store/reducers';
import { PatrimoineAncetreState } from '@get/store/states';
import { PatrimoineHierarchieTemplateReducer } from '@get/store/reducers';
import { PatrimoineHierarchieTemplateState } from '@get/store/states';
import { ProgBiblioInterventionReducer } from '@get/store/reducers';
import { ProgBiblioInterventionState } from '@get/store/states';
import { ProgBiblioInterventionScenarioReducer } from '@get/store/reducers';
import { ProgBiblioInterventionScenarioState } from '@get/store/states';
import { ProgBudgetReducer } from '@get/store/reducers';
import { ProgBudgetState } from '@get/store/states';
import { ProgComposantReducer } from '@get/store/reducers';
import { ProgComposantState } from '@get/store/states';
import { ProgComposantGroupeReducer } from '@get/store/reducers';
import { ProgComposantGroupeState } from '@get/store/states';
import { ProgComposantSousGroupeReducer } from '@get/store/reducers';
import { ProgComposantSousGroupeState } from '@get/store/states';
import { ProgEtatTechniqueReducer } from '@get/store/reducers';
import { ProgEtatTechniqueState } from '@get/store/states';
import { ProgInterventionReducer } from '@get/store/reducers';
import { ProgInterventionState } from '@get/store/states';
import { ProgInterventionFamilleReducer } from '@get/store/reducers';
import { ProgInterventionFamilleState } from '@get/store/states';
import { ProgInterventionPatrimoineReducer } from '@get/store/reducers';
import { ProgInterventionPatrimoineState } from '@get/store/states';
import { ProgInterventionScenarioReducer } from '@get/store/reducers';
import { ProgInterventionScenarioState } from '@get/store/states';
import { ProgPatrimoineInfoReducer } from '@get/store/reducers';
import { ProgPatrimoineInfoState } from '@get/store/states';
import { ProgScenarioReducer } from '@get/store/reducers';
import { ProgScenarioState } from '@get/store/states';
import { ProgUniteReducer } from '@get/store/reducers';
import { ProgUniteState } from '@get/store/states';
import { SocieteReducer } from '@get/store/reducers';
import { SocieteState } from '@get/store/states';
import { SocieteCaracteristiqueReducer } from '@get/store/reducers';
import { SocieteCaracteristiqueState } from '@get/store/states';
import { SocieteCaracteristiqueChoixReducer } from '@get/store/reducers';
import { SocieteCaracteristiqueChoixState } from '@get/store/states';
import { SocieteConfigAnalyseSyntheseReducer } from '@get/store/reducers';
import { SocieteConfigAnalyseSyntheseState } from '@get/store/states';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueReducer } from '@get/store/reducers';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState } from '@get/store/states';
import { SocieteComposantReducer } from '@get/store/reducers';
import { SocieteComposantState } from '@get/store/states';
import { SocieteComposantFamilleReducer } from '@get/store/reducers';
import { SocieteComposantFamilleState } from '@get/store/states';
import { SocieteComposantRattachementReducer } from '@get/store/reducers';
import { SocieteComposantRattachementState } from '@get/store/states';
import { SocieteEspaceReducer } from '@get/store/reducers';
import { SocieteEspaceState } from '@get/store/states';
import { SocieteEspaceFamilleReducer } from '@get/store/reducers';
import { SocieteEspaceFamilleState } from '@get/store/states';
import { SocietePatrimoineHierarchieReducer } from '@get/store/reducers';
import { SocietePatrimoineHierarchieState } from '@get/store/states';
import { SocietePatrimoineHierarchieAncetreReducer } from '@get/store/reducers';
import { SocietePatrimoineHierarchieAncetreState } from '@get/store/states';
import { SocieteProfilReducer } from '@get/store/reducers';
import { SocieteProfilState } from '@get/store/states';
import { SocieteProfilDroitReducer } from '@get/store/reducers';
import { SocieteProfilDroitState } from '@get/store/states';
import { SocieteTerritoireReducer } from '@get/store/reducers';
import { SocieteTerritoireState } from '@get/store/states';
import { SocieteTerritoirePatrimoineReducer } from '@get/store/reducers';
import { SocieteTerritoirePatrimoineState } from '@get/store/states';
import { SocieteTerritoireUserReducer } from '@get/store/reducers';
import { SocieteTerritoireUserState } from '@get/store/states';
import { UsageReducer } from '@get/store/reducers';
import { UsageState } from '@get/store/states';
import { UsageComposantTemplateReducer } from '@get/store/reducers';
import { UsageComposantTemplateState } from '@get/store/states';
import { UsageCaracteristiqueTemplateReducer } from '@get/store/reducers';
import { UsageCaracteristiqueTemplateState } from '@get/store/states';
import { UserReducer } from '@get/store/reducers';
import { UserState } from '@get/store/states';
import { UserNewsletterReducer } from '@get/store/reducers';
import { UserNewsletterState } from '@get/store/states';
import { UserPatrimoineReducer } from '@get/store/reducers';
import { UserPatrimoineState } from '@get/store/states';
import { UserSocieteProfilReducer } from '@get/store/reducers';
import { UserSocieteProfilState } from '@get/store/states';
import { ValeurReducer } from '@get/store/reducers';
import { ValeurState } from '@get/store/states';
import { ValeurFichierReducer } from '@get/store/reducers';
import { ValeurFichierState } from '@get/store/states';
import { ValeurComposantReducer } from '@get/store/reducers';
import { ValeurComposantState } from '@get/store/states';
import { ValeurPatrimoineReducer } from '@get/store/reducers';
import { ValeurPatrimoineState } from '@get/store/states';
import { ValeurProgInterventionReducer } from '@get/store/reducers';
import { ValeurProgInterventionState } from '@get/store/states';

export interface GeneratedAppState {
  boardState: BoardStateState.IState;
  campagne: CampagneState.IState;
  campagnePatrimoine: CampagnePatrimoineState.IState;
  campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueState.IState;
  campagneUser: CampagneUserState.IState;
  caracteristiqueTemplate: CaracteristiqueTemplateState.IState;
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateState.IState;
  composant: ComposantState.IState;
  composantAttendu: ComposantAttenduState.IState;
  composantGroupe: ComposantGroupeState.IState;
  composantTemplate: ComposantTemplateState.IState;
  droit: DroitState.IState;
  espace: EspaceState.IState;
  fichier: FichierState.IState;
  newsletter: NewsletterState.IState;
  organisationAzureLogin: OrganisationAzureLoginState.IState;
  organisation: OrganisationState.IState;
  organisationNewsletter: OrganisationNewsletterState.IState;
  patrimoine: PatrimoineState.IState;
  patrimoineAncetre: PatrimoineAncetreState.IState;
  patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateState.IState;
  progBiblioIntervention: ProgBiblioInterventionState.IState;
  progBiblioInterventionScenario: ProgBiblioInterventionScenarioState.IState;
  progBudget: ProgBudgetState.IState;
  progComposant: ProgComposantState.IState;
  progComposantGroupe: ProgComposantGroupeState.IState;
  progComposantSousGroupe: ProgComposantSousGroupeState.IState;
  progEtatTechnique: ProgEtatTechniqueState.IState;
  progIntervention: ProgInterventionState.IState;
  progInterventionFamille: ProgInterventionFamilleState.IState;
  progInterventionPatrimoine: ProgInterventionPatrimoineState.IState;
  progInterventionScenario: ProgInterventionScenarioState.IState;
  progPatrimoineInfo: ProgPatrimoineInfoState.IState;
  progScenario: ProgScenarioState.IState;
  progUnite: ProgUniteState.IState;
  societe: SocieteState.IState;
  societeCaracteristique: SocieteCaracteristiqueState.IState;
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixState.IState;
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseState.IState;
  societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState;
  societeComposant: SocieteComposantState.IState;
  societeComposantFamille: SocieteComposantFamilleState.IState;
  societeComposantRattachement: SocieteComposantRattachementState.IState;
  societeEspace: SocieteEspaceState.IState;
  societeEspaceFamille: SocieteEspaceFamilleState.IState;
  societePatrimoineHierarchie: SocietePatrimoineHierarchieState.IState;
  societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreState.IState;
  societeProfil: SocieteProfilState.IState;
  societeProfilDroit: SocieteProfilDroitState.IState;
  societeTerritoire: SocieteTerritoireState.IState;
  societeTerritoirePatrimoine: SocieteTerritoirePatrimoineState.IState;
  societeTerritoireUser: SocieteTerritoireUserState.IState;
  usage: UsageState.IState;
  usageComposantTemplate: UsageComposantTemplateState.IState;
  usageCaracteristiqueTemplate: UsageCaracteristiqueTemplateState.IState;
  user: UserState.IState;
  userNewsletter: UserNewsletterState.IState;
  userPatrimoine: UserPatrimoineState.IState;
  userSocieteProfil: UserSocieteProfilState.IState;
  valeur: ValeurState.IState;
  valeurFichier: ValeurFichierState.IState;
  valeurComposant: ValeurComposantState.IState;
  valeurPatrimoine: ValeurPatrimoineState.IState;
  valeurProgIntervention: ValeurProgInterventionState.IState;
}

export const generatedAppState = {
  boardState: BoardStateReducer.reducer,
  campagne: CampagneReducer.reducer,
  campagnePatrimoine: CampagnePatrimoineReducer.reducer,
  campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueReducer.reducer,
  campagneUser: CampagneUserReducer.reducer,
  caracteristiqueTemplate: CaracteristiqueTemplateReducer.reducer,
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateReducer.reducer,
  composant: ComposantReducer.reducer,
  composantAttendu: ComposantAttenduReducer.reducer,
  composantGroupe: ComposantGroupeReducer.reducer,
  composantTemplate: ComposantTemplateReducer.reducer,
  droit: DroitReducer.reducer,
  espace: EspaceReducer.reducer,
  fichier: FichierReducer.reducer,
  newsletter: NewsletterReducer.reducer,
  organisationAzureLogin: OrganisationAzureLoginReducer.reducer,
  organisation: OrganisationReducer.reducer,
  organisationNewsletter: OrganisationNewsletterReducer.reducer,
  patrimoine: PatrimoineReducer.reducer,
  patrimoineAncetre: PatrimoineAncetreReducer.reducer,
  patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateReducer.reducer,
  progBiblioIntervention: ProgBiblioInterventionReducer.reducer,
  progBiblioInterventionScenario: ProgBiblioInterventionScenarioReducer.reducer,
  progBudget: ProgBudgetReducer.reducer,
  progComposant: ProgComposantReducer.reducer,
  progComposantGroupe: ProgComposantGroupeReducer.reducer,
  progComposantSousGroupe: ProgComposantSousGroupeReducer.reducer,
  progEtatTechnique: ProgEtatTechniqueReducer.reducer,
  progIntervention: ProgInterventionReducer.reducer,
  progInterventionFamille: ProgInterventionFamilleReducer.reducer,
  progInterventionPatrimoine: ProgInterventionPatrimoineReducer.reducer,
  progInterventionScenario: ProgInterventionScenarioReducer.reducer,
  progPatrimoineInfo: ProgPatrimoineInfoReducer.reducer,
  progScenario: ProgScenarioReducer.reducer,
  progUnite: ProgUniteReducer.reducer,
  societe: SocieteReducer.reducer,
  societeCaracteristique: SocieteCaracteristiqueReducer.reducer,
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixReducer.reducer,
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseReducer.reducer,
  societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueReducer.reducer,
  societeComposant: SocieteComposantReducer.reducer,
  societeComposantFamille: SocieteComposantFamilleReducer.reducer,
  societeComposantRattachement: SocieteComposantRattachementReducer.reducer,
  societeEspace: SocieteEspaceReducer.reducer,
  societeEspaceFamille: SocieteEspaceFamilleReducer.reducer,
  societePatrimoineHierarchie: SocietePatrimoineHierarchieReducer.reducer,
  societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreReducer.reducer,
  societeProfil: SocieteProfilReducer.reducer,
  societeProfilDroit: SocieteProfilDroitReducer.reducer,
  societeTerritoire: SocieteTerritoireReducer.reducer,
  societeTerritoirePatrimoine: SocieteTerritoirePatrimoineReducer.reducer,
  societeTerritoireUser: SocieteTerritoireUserReducer.reducer,
  usage: UsageReducer.reducer,
  usageComposantTemplate: UsageComposantTemplateReducer.reducer,
  usageCaracteristiqueTemplate: UsageCaracteristiqueTemplateReducer.reducer,
  user: UserReducer.reducer,
  userNewsletter: UserNewsletterReducer.reducer,
  userPatrimoine: UserPatrimoineReducer.reducer,
  userSocieteProfil: UserSocieteProfilReducer.reducer,
  valeur: ValeurReducer.reducer,
  valeurFichier: ValeurFichierReducer.reducer,
  valeurComposant: ValeurComposantReducer.reducer,
  valeurPatrimoine: ValeurPatrimoineReducer.reducer,
  valeurProgIntervention: ValeurProgInterventionReducer.reducer,
};
