import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachementState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteComposantRattachementEntityState } from '@get/api-interfaces';

export const societeComposantRattachementReducersGeneratedFunctions: ReducerTypes<SocieteComposantRattachementState.IState, readonly ActionCreator[]>[] = [
  on(SocieteComposantRattachementGeneratedActions.upsertOneSocieteComposantRattachement, (state: SocieteComposantRattachementState.IState) => setLoadingsState(state, true)),

  on(SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachementsSuccess, (state: SocieteComposantRattachementState.IState, { societeComposantRattachements }) =>
    SocieteComposantRattachementState.adapter.upsertMany(societeComposantRattachements, setLoadingsState(state, false))
  ),
  on(SocieteComposantRattachementGeneratedActions.deleteOneSocieteComposantRattachementSuccess, (state: SocieteComposantRattachementState.IState, { idSocieteComposantRattachement }) =>
    SocieteComposantRattachementState.adapter.removeOne(idSocieteComposantRattachement, setLoadingsState(state, false))
  ),

  on(SocieteComposantRattachementGeneratedActions.clearSocieteComposantRattachements, () => SocieteComposantRattachementState.initialState),

  on(
    SocieteComposantRattachementGeneratedActions.addSocieteComposantSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocieteComposantRattachement, idSocieteComposant }) => {
      if (!state.entities[idSocieteComposantRattachement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantRattachement]: {
            ...state.entities[idSocieteComposantRattachement],
            societeComposant: idSocieteComposant
          }
        }
      };
    }
  ),

  on(
    SocieteComposantRattachementGeneratedActions.deleteManySocieteComposantSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocieteComposants, idSocieteComposantRattachements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantRattachements.reduce((entities, idSocieteComposantRattachement) => {
            if (!state.entities[idSocieteComposantRattachement]?.societeComposant) {
              return entities;
            }
            entities[idSocieteComposantRattachement] = {
              ...state.entities[idSocieteComposantRattachement],
              societeComposant: idSocieteComposants.some(
                (idSocieteComposant: number) => idSocieteComposant === state.entities[idSocieteComposantRattachement]?.societeComposant
              )
                ? undefined
                : state.entities[idSocieteComposantRattachement]?.societeComposant
            } as SocieteComposantRattachementEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantRattachementEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantRattachementGeneratedActions.addSocieteEspaceFamilleSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocieteComposantRattachement, idSocieteEspaceFamille }) => {
      if (!state.entities[idSocieteComposantRattachement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantRattachement]: {
            ...state.entities[idSocieteComposantRattachement],
            societeEspaceFamille: idSocieteEspaceFamille
          }
        }
      };
    }
  ),

  on(
    SocieteComposantRattachementGeneratedActions.deleteManySocieteEspaceFamilleSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocieteEspaceFamilles, idSocieteComposantRattachements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantRattachements.reduce((entities, idSocieteComposantRattachement) => {
            if (!state.entities[idSocieteComposantRattachement]?.societeEspaceFamille) {
              return entities;
            }
            entities[idSocieteComposantRattachement] = {
              ...state.entities[idSocieteComposantRattachement],
              societeEspaceFamille: idSocieteEspaceFamilles.some(
                (idSocieteEspaceFamille: number) => idSocieteEspaceFamille === state.entities[idSocieteComposantRattachement]?.societeEspaceFamille
              )
                ? undefined
                : state.entities[idSocieteComposantRattachement]?.societeEspaceFamille
            } as SocieteComposantRattachementEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantRattachementEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantRattachementGeneratedActions.addSocietePatrimoineHierarchieSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocieteComposantRattachement, idSocietePatrimoineHierarchie }) => {
      if (!state.entities[idSocieteComposantRattachement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposantRattachement]: {
            ...state.entities[idSocieteComposantRattachement],
            societePatrimoineHierarchie: idSocietePatrimoineHierarchie
          }
        }
      };
    }
  ),

  on(
    SocieteComposantRattachementGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: SocieteComposantRattachementState.IState, { idSocietePatrimoineHierarchies, idSocieteComposantRattachements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposantRattachements.reduce((entities, idSocieteComposantRattachement) => {
            if (!state.entities[idSocieteComposantRattachement]?.societePatrimoineHierarchie) {
              return entities;
            }
            entities[idSocieteComposantRattachement] = {
              ...state.entities[idSocieteComposantRattachement],
              societePatrimoineHierarchie: idSocietePatrimoineHierarchies.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idSocieteComposantRattachement]?.societePatrimoineHierarchie
              )
                ? undefined
                : state.entities[idSocieteComposantRattachement]?.societePatrimoineHierarchie
            } as SocieteComposantRattachementEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantRattachementEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteComposantRattachementState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteComposantRattachementState.IState {
  return { ...state, isLoaded, isLoading };
}
