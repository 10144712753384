import { RepositoryService } from '@get/services/repository';
import { Campagne } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCampagneApiService {
  constructor(protected repo: RepositoryService) {}

  public getCampagnes(params?: any): Observable<Campagne[]> {
    return this.repo.getData<Campagne[]>('campagne', params);
  }

  public getCampagne(params: { idCampagne: number; params?: any }): Observable<Campagne> {
    return this.repo.getData<Campagne>('campagne/' + params.idCampagne, params.params);
  }

  public addCampagne(campagne: Partial<Campagne>): Observable<Campagne> {
    return this.repo.create<Campagne>('campagne', campagne);
  }

  public updateCampagne(campagne: Partial<Campagne>): Observable<Campagne> {
    return this.repo.update('campagne', campagne);
  }

  public deleteCampagne(idCampagne: number): Observable<number> {
    return this.repo.delete('campagne/' + +idCampagne);
  }
}
