import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurPatrimoineEntityState } from '@get/api-interfaces';

export const valeurPatrimoineReducersGeneratedFunctions: ReducerTypes<ValeurPatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(ValeurPatrimoineGeneratedActions.upsertOneValeurPatrimoine, (state: ValeurPatrimoineState.IState) => setLoadingsState(state, true)),

  on(ValeurPatrimoineGeneratedActions.upsertManyValeurPatrimoinesSuccess, (state: ValeurPatrimoineState.IState, { valeurPatrimoines }) =>
    ValeurPatrimoineState.adapter.upsertMany(valeurPatrimoines, setLoadingsState(state, false))
  ),
  on(ValeurPatrimoineGeneratedActions.deleteOneValeurPatrimoineSuccess, (state: ValeurPatrimoineState.IState, { idValeurPatrimoine }) =>
    ValeurPatrimoineState.adapter.removeOne(idValeurPatrimoine, setLoadingsState(state, false))
  ),

  on(ValeurPatrimoineGeneratedActions.clearValeurPatrimoines, () => ValeurPatrimoineState.initialState),

  on(
    ValeurPatrimoineGeneratedActions.addValeurSuccess,
    (state: ValeurPatrimoineState.IState, { idValeurPatrimoine, idValeur }) => {
      if (!state.entities[idValeurPatrimoine]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurPatrimoine]: {
            ...state.entities[idValeurPatrimoine],
            valeur: idValeur
          }
        }
      };
    }
  ),

  on(
    ValeurPatrimoineGeneratedActions.deleteManyValeurSuccess,
    (state: ValeurPatrimoineState.IState, { idValeurs, idValeurPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurPatrimoines.reduce((entities, idValeurPatrimoine) => {
            if (!state.entities[idValeurPatrimoine]?.valeur) {
              return entities;
            }
            entities[idValeurPatrimoine] = {
              ...state.entities[idValeurPatrimoine],
              valeur: idValeurs.some(
                (idValeur: number) => idValeur === state.entities[idValeurPatrimoine]?.valeur
              )
                ? undefined
                : state.entities[idValeurPatrimoine]?.valeur
            } as ValeurPatrimoineEntityState;
            return entities;
          }, {} as Dictionary<ValeurPatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    ValeurPatrimoineGeneratedActions.addPatrimoineSuccess,
    (state: ValeurPatrimoineState.IState, { idValeurPatrimoine, idPatrimoine }) => {
      if (!state.entities[idValeurPatrimoine]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurPatrimoine]: {
            ...state.entities[idValeurPatrimoine],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    ValeurPatrimoineGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ValeurPatrimoineState.IState, { idPatrimoines, idValeurPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurPatrimoines.reduce((entities, idValeurPatrimoine) => {
            if (!state.entities[idValeurPatrimoine]?.patrimoine) {
              return entities;
            }
            entities[idValeurPatrimoine] = {
              ...state.entities[idValeurPatrimoine],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idValeurPatrimoine]?.patrimoine
              )
                ? undefined
                : state.entities[idValeurPatrimoine]?.patrimoine
            } as ValeurPatrimoineEntityState;
            return entities;
          }, {} as Dictionary<ValeurPatrimoineEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ValeurPatrimoineState.IState,
  isLoading: boolean,
  isLoaded = true
): ValeurPatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
