import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { SocieteRelationsIds } from '@get/store/ids-interfaces';

export const SocieteGeneratedActions = createActionGroup({
  source: 'Societe Generated',
  events: {
    'Get Many Societes': props<{ params: any }>(),
    'Add Many Actives Societes': props<{ idSocietes: number[] }>(),
    'Delete One Active Societe': props<{ idSociete: number }>(),
    'Clear Actives Societes': emptyProps(),
    'Upsert One Societe': props<{ societe: Partial<Societe>; ids?: SocieteRelationsIds; }>(),
    'Upsert Many Societes': props<{ societes: Partial<Societe>[]; ids?: SocieteRelationsIds; }>(),
    'Upsert Many Societes Success': props<{ societes: SocieteEntityState[] }>(),
    'Delete One Societe Success': props<{ idSociete: number }>(),
    'Normalize Many Societes After Upsert': props<{ societes: SocieteEntityState[] }>(),
    'Societes Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societes': emptyProps(),
    'Add Many Societe Composant Success': props<{ idSociete: number; idSocieteComposants: number[] }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idSocietes: number[] }>(),
    'Add Many Societe Patrimoine Hierarchie Success': props<{ idSociete: number; idSocietePatrimoineHierarchies: number[] }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idSocietes: number[] }>(),
    'Add Many Societe Caracteristique Success': props<{ idSociete: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocietes: number[] }>(),
    'Add Many Societe Composant Famille Success': props<{ idSociete: number; idSocieteComposantFamilles: number[] }>(),
    'Delete Many Societe Composant Famille Success': props<{ idSocieteComposantFamilles: number[]; idSocietes: number[] }>(),
    'Add Many Societe Espace Famille Success': props<{ idSociete: number; idSocieteEspaceFamilles: number[] }>(),
    'Delete Many Societe Espace Famille Success': props<{ idSocieteEspaceFamilles: number[]; idSocietes: number[] }>(),
    'Add Many Campagne Success': props<{ idSociete: number; idCampagnes: number[] }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idSocietes: number[] }>(),
    'Add Many Societe Territoire Success': props<{ idSociete: number; idSocieteTerritoires: number[] }>(),
    'Delete Many Societe Territoire Success': props<{ idSocieteTerritoires: number[]; idSocietes: number[] }>(),
    'Add Many Societe Profil Success': props<{ idSociete: number; idSocieteProfils: number[] }>(),
    'Delete Many Societe Profil Success': props<{ idSocieteProfils: number[]; idSocietes: number[] }>(),
    'Add Many Patrimoine Success': props<{ idSociete: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idSocietes: number[] }>(),
    'Add Many Societe Config Analyse Synthese Success': props<{ idSociete: number; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Delete Many Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSyntheses: number[]; idSocietes: number[] }>(),
    'Add Many Composant Groupe Success': props<{ idSociete: number; idComposantGroupes: number[] }>(),
    'Delete Many Composant Groupe Success': props<{ idComposantGroupes: number[]; idSocietes: number[] }>(),
    'Add Many Prog Composant Success': props<{ idSociete: number; idProgComposants: number[] }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idSocietes: number[] }>(),
    'Add Many Prog Composant Groupe Success': props<{ idSociete: number; idProgComposantGroupes: number[] }>(),
    'Delete Many Prog Composant Groupe Success': props<{ idProgComposantGroupes: number[]; idSocietes: number[] }>(),
    'Add Many Prog Composant Sous Groupe Success': props<{ idSociete: number; idProgComposantSousGroupes: number[] }>(),
    'Delete Many Prog Composant Sous Groupe Success': props<{ idProgComposantSousGroupes: number[]; idSocietes: number[] }>(),
    'Add Many Prog Biblio Intervention Success': props<{ idSociete: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idSocietes: number[] }>(),
    'Add Many Prog Budget Success': props<{ idSociete: number; idProgBudgets: number[] }>(),
    'Delete Many Prog Budget Success': props<{ idProgBudgets: number[]; idSocietes: number[] }>(),
    'Add Many Prog Unite Success': props<{ idSociete: number; idProgUnites: number[] }>(),
    'Delete Many Prog Unite Success': props<{ idProgUnites: number[]; idSocietes: number[] }>(),
    'Add Many Prog Intervention Famille Success': props<{ idSociete: number; idProgInterventionFamilles: number[] }>(),
    'Delete Many Prog Intervention Famille Success': props<{ idProgInterventionFamilles: number[]; idSocietes: number[] }>(),
    'Add Many Prog Scenario Success': props<{ idSociete: number; idProgScenarios: number[] }>(),
    'Delete Many Prog Scenario Success': props<{ idProgScenarios: number[]; idSocietes: number[] }>(),
    'Add Many Prog Etat Technique Success': props<{ idSociete: number; idProgEtatTechniques: number[] }>(),
    'Delete Many Prog Etat Technique Success': props<{ idProgEtatTechniques: number[]; idSocietes: number[] }>(),
    'Add Organisation Success': props<{ idSociete: number; idOrganisation: number }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idSocietes: number[] }>()
  }
});
