import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgScenarioApiService } from '@get/store/api-services';
import { GeneratedProgScenarioEffects } from './prog-scenario-generated.effects';

@Injectable()
export class ProgScenarioEffects extends GeneratedProgScenarioEffects {
  constructor(actions$: Actions, progScenarioApiService: ProgScenarioApiService, store$: Store<AppState>) {
    super(actions$, progScenarioApiService, store$);
  }
}
