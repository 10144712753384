import { createEntityAdapter } from '@ngrx/entity';
import { ComposantAttenduEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ComposantAttenduEntityState>;

export const adapter = createEntityAdapter<ComposantAttenduEntityState>({
  selectId: o => o.idComposantAttendu
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const composantAttenduFeatureKey = 'composantAttendu';
