import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { ProgComposantGroupe, ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgComposantSousGroupeState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progComposantSousGroupeRelations: string[] = ['progComposants','societes','progComposantGroupes',];

export const { selectEntities, selectAll } = ProgComposantSousGroupeState.adapter.getSelectors();

export const selectProgComposantSousGroupeState = createFeatureSelector<ProgComposantSousGroupeState.IState>(ProgComposantSousGroupeState.progComposantSousGroupeFeatureKey);

export const selectIsLoadedProgComposantSousGroupe = createSelector(
  selectProgComposantSousGroupeState,
  (state: ProgComposantSousGroupeState.IState) => state.isLoaded
);

export const selectIsLoadingProgComposantSousGroupe = createSelector(
  selectProgComposantSousGroupeState,
  (state: ProgComposantSousGroupeState.IState) => state.isLoading
);

export const selectIsReadyProgComposantSousGroupe = createSelector(
  selectProgComposantSousGroupeState,
  (state: ProgComposantSousGroupeState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgComposantSousGroupe = createSelector(
  selectProgComposantSousGroupeState,
  (state: ProgComposantSousGroupeState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgComposantSousGroupesEntities = createSelector(selectProgComposantSousGroupeState, selectEntities);

export const selectProgComposantSousGroupesArray = createSelector(selectProgComposantSousGroupeState, selectAll);

const progComposantSousGroupesInObject = (progComposantSousGroupes: Dictionary<ProgComposantSousGroupeEntityState>) => ({ progComposantSousGroupes })

const selectProgComposantSousGroupesEntitiesDictionary = createSelector(selectProgComposantSousGroupesEntities, progComposantSousGroupesInObject);

const selectAllProgComposantSousGroupesObject = createSelector(selectProgComposantSousGroupesEntities, progComposantSousGroupes => {
  return hydrateAll({ progComposantSousGroupes });
});

const selectOneProgComposantSousGroupeDictionary = (idProgComposantSousGroupe : number) =>
  createSelector(selectProgComposantSousGroupesEntities, progComposantSousGroupes => ({
    progComposantSousGroupes: { [idProgComposantSousGroupe]: progComposantSousGroupes[idProgComposantSousGroupe] }
  }));

const selectOneProgComposantSousGroupeDictionaryWithoutChild = (idProgComposantSousGroupe : number) =>
  createSelector(selectProgComposantSousGroupesEntities, progComposantSousGroupes => ({
    progComposantSousGroupe: progComposantSousGroupes[idProgComposantSousGroupe]
  }));

const selectAllProgComposantSousGroupesSelectors: Dictionary<Selector> = {};
export function selectAllProgComposantSousGroupes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgComposantSousGroupe>(
      schema,
      selectAllProgComposantSousGroupesSelectors,
      selectProgComposantSousGroupesEntitiesDictionary,
      getRelationSelectors,
      progComposantSousGroupeRelations,
      hydrateAll,
      'progComposantSousGroupe'
    );
  } else {
    return selectAllProgComposantSousGroupesObject;
  }
}

export function selectAllProgComposantSousGroupesDictionary(
  schema: SelectSchema = {},
  customKey = 'progComposantSousGroupes'
): Selector {
  return createSelector(selectAllProgComposantSousGroupes(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgComposantSousGroupeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progComposantSousGroupes.length; i++) {
      res[customKey][result.progComposantSousGroupes[i].idProgComposantSousGroupe] = result.progComposantSousGroupes[i];
    }
    return res;
  });
}

export function selectOneProgComposantSousGroupe(
  schema: SelectSchema = {},
  idProgComposantSousGroupe: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgComposantSousGroupeDictionary(idProgComposantSousGroupe)];
  selectors.push(...getRelationSelectors(schema, progComposantSousGroupeRelations, 'progComposantSousGroupe'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgComposantSousGroupeDictionaryWithoutChild(idProgComposantSousGroupe);
  }
}

interface hydrateArgs {
  progComposantSousGroupes: Dictionary<ProgComposantSousGroupeEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progComposantGroupes?: Dictionary<ProgComposantGroupeEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progComposantSousGroupes: (ProgComposantSousGroupe | null)[] } {
  const {
    progComposantSousGroupes,
    societes,
    progComposantGroupes,
    progComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progComposantSousGroupes: Object.keys(progComposantSousGroupes).map(idProgComposantSousGroupe =>
      hydrate(
        progComposantSousGroupes[idProgComposantSousGroupe] as ProgComposantSousGroupeEntityState,
        societes,
        progComposantGroupes,
        progComposants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progComposantSousGroupe: ProgComposantSousGroupeEntityState | null } {
  const {
    progComposantSousGroupes,
    societes,
    progComposantGroupes,
    progComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progComposantSousGroupe = Object.values(progComposantSousGroupes)[0];
  return {
    progComposantSousGroupe: hydrate(
      progComposantSousGroupe as ProgComposantSousGroupeEntityState,
      societes,
      progComposantGroupes,
      progComposants
    )
  };
}

function hydrate(
  progComposantSousGroupe: ProgComposantSousGroupeEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progComposantGroupeEntities?: Dictionary<ProgComposantGroupeEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
): ProgComposantSousGroupe | null {
  if (!progComposantSousGroupe) {
    return null;
  }

  const progComposantSousGroupeHydrated: ProgComposantSousGroupeEntityState = { ...progComposantSousGroupe };
  if (societeEntities) {
    progComposantSousGroupeHydrated.societe = societeEntities[progComposantSousGroupe.societe as number] as Societe;
  } else {
    delete progComposantSousGroupeHydrated.societe;
  }
  if (progComposantGroupeEntities) {
    progComposantSousGroupeHydrated.progComposantGroupe = progComposantGroupeEntities[progComposantSousGroupe.progComposantGroupe as number] as ProgComposantGroupe;
  } else {
    delete progComposantSousGroupeHydrated.progComposantGroupe;
  }

  if (progComposantEntities) {
    progComposantSousGroupeHydrated.progComposants = ((progComposantSousGroupeHydrated.progComposants as number[]) || []).map(
      id => progComposantEntities[id]
    ) as ProgComposant[];
  } else {
    delete progComposantSousGroupeHydrated.progComposants;
  }

  return progComposantSousGroupeHydrated as ProgComposantSousGroupe;
}
