import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurPatrimoineApiService } from '@get/store/api-services';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurPatrimoine } from '@get/store/configs/normalization';

export class GeneratedValeurPatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected valeurPatrimoineApiService: ValeurPatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeurPatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert),
      concatMap(({ valeurPatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeValeurPatrimoine(valeurPatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurPatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
