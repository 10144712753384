import { RepositoryService } from '@get/services/repository';
import { Patrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedPatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public getPatrimoine(params: { idPatrimoine: number; params?: any }): Observable<Patrimoine> {
    return this.repo.getData<Patrimoine>('patrimoine/' + params.idPatrimoine, params.params);
  }

  public addPatrimoine(patrimoine: Partial<Patrimoine>): Observable<Patrimoine> {
    return this.repo.create<Patrimoine>('patrimoine', patrimoine);
  }

  public updatePatrimoine(patrimoine: Partial<Patrimoine>): Observable<Patrimoine> {
    return this.repo.update('patrimoine', patrimoine);
  }
}
