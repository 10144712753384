import { ProgEtatTechniqueState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progEtatTechniqueReducersGeneratedFunctions } from './prog-etat-technique-generated.reducer';

const progEtatTechniqueReducersFunctions = [...progEtatTechniqueReducersGeneratedFunctions];

const progEtatTechniqueReducer = createReducer(
  ProgEtatTechniqueState.initialState,
  ...progEtatTechniqueReducersFunctions
);

export function reducer(state: ProgEtatTechniqueState.IState | undefined, action: Action) {
  return progEtatTechniqueReducer(state, action);
}
