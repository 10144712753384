import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgInterventionPatrimoineGeneratedActions } from '@get/store/actions';
import { ProgInterventionPatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';

export const progInterventionPatrimoineReducersGeneratedFunctions: ReducerTypes<ProgInterventionPatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(ProgInterventionPatrimoineGeneratedActions.upsertOneProgInterventionPatrimoine, (state: ProgInterventionPatrimoineState.IState) => setLoadingsState(state, true)),

  on(ProgInterventionPatrimoineGeneratedActions.upsertManyProgInterventionPatrimoinesSuccess, (state: ProgInterventionPatrimoineState.IState, { progInterventionPatrimoines }) =>
    ProgInterventionPatrimoineState.adapter.upsertMany(progInterventionPatrimoines, setLoadingsState(state, false))
  ),
  on(ProgInterventionPatrimoineGeneratedActions.deleteOneProgInterventionPatrimoineSuccess, (state: ProgInterventionPatrimoineState.IState, { idProgInterventionPatrimoine }) =>
    ProgInterventionPatrimoineState.adapter.removeOne(idProgInterventionPatrimoine, setLoadingsState(state, false))
  ),

  on(ProgInterventionPatrimoineGeneratedActions.clearProgInterventionPatrimoines, () => ProgInterventionPatrimoineState.initialState),
];

export function setLoadingsState(
  state: ProgInterventionPatrimoineState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgInterventionPatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
