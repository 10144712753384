import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgPatrimoineInfo, ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgEtatTechnique, ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgPatrimoineInfoState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progPatrimoineInfoRelations: string[] = ['patrimoines','progComposants','progEtatTechniques',];

export const { selectEntities, selectAll } = ProgPatrimoineInfoState.adapter.getSelectors();

export const selectProgPatrimoineInfoState = createFeatureSelector<ProgPatrimoineInfoState.IState>(ProgPatrimoineInfoState.progPatrimoineInfoFeatureKey);

export const selectIsLoadedProgPatrimoineInfo = createSelector(
  selectProgPatrimoineInfoState,
  (state: ProgPatrimoineInfoState.IState) => state.isLoaded
);

export const selectIsLoadingProgPatrimoineInfo = createSelector(
  selectProgPatrimoineInfoState,
  (state: ProgPatrimoineInfoState.IState) => state.isLoading
);

export const selectIsReadyProgPatrimoineInfo = createSelector(
  selectProgPatrimoineInfoState,
  (state: ProgPatrimoineInfoState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgPatrimoineInfo = createSelector(
  selectProgPatrimoineInfoState,
  (state: ProgPatrimoineInfoState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgPatrimoineInfosEntities = createSelector(selectProgPatrimoineInfoState, selectEntities);

export const selectProgPatrimoineInfosArray = createSelector(selectProgPatrimoineInfoState, selectAll);

const progPatrimoineInfosInObject = (progPatrimoineInfos: Dictionary<ProgPatrimoineInfoEntityState>) => ({ progPatrimoineInfos })

const selectProgPatrimoineInfosEntitiesDictionary = createSelector(selectProgPatrimoineInfosEntities, progPatrimoineInfosInObject);

const selectAllProgPatrimoineInfosObject = createSelector(selectProgPatrimoineInfosEntities, progPatrimoineInfos => {
  return hydrateAll({ progPatrimoineInfos });
});

const selectOneProgPatrimoineInfoDictionary = (idProgPatrimoineInfo : number) =>
  createSelector(selectProgPatrimoineInfosEntities, progPatrimoineInfos => ({
    progPatrimoineInfos: { [idProgPatrimoineInfo]: progPatrimoineInfos[idProgPatrimoineInfo] }
  }));

const selectOneProgPatrimoineInfoDictionaryWithoutChild = (idProgPatrimoineInfo : number) =>
  createSelector(selectProgPatrimoineInfosEntities, progPatrimoineInfos => ({
    progPatrimoineInfo: progPatrimoineInfos[idProgPatrimoineInfo]
  }));

const selectAllProgPatrimoineInfosSelectors: Dictionary<Selector> = {};
export function selectAllProgPatrimoineInfos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgPatrimoineInfo>(
      schema,
      selectAllProgPatrimoineInfosSelectors,
      selectProgPatrimoineInfosEntitiesDictionary,
      getRelationSelectors,
      progPatrimoineInfoRelations,
      hydrateAll,
      'progPatrimoineInfo'
    );
  } else {
    return selectAllProgPatrimoineInfosObject;
  }
}

export function selectAllProgPatrimoineInfosDictionary(
  schema: SelectSchema = {},
  customKey = 'progPatrimoineInfos'
): Selector {
  return createSelector(selectAllProgPatrimoineInfos(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgPatrimoineInfoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progPatrimoineInfos.length; i++) {
      res[customKey][result.progPatrimoineInfos[i].idProgPatrimoineInfo] = result.progPatrimoineInfos[i];
    }
    return res;
  });
}

export function selectOneProgPatrimoineInfo(
  schema: SelectSchema = {},
  idProgPatrimoineInfo: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgPatrimoineInfoDictionary(idProgPatrimoineInfo)];
  selectors.push(...getRelationSelectors(schema, progPatrimoineInfoRelations, 'progPatrimoineInfo'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgPatrimoineInfoDictionaryWithoutChild(idProgPatrimoineInfo);
  }
}

interface hydrateArgs {
  progPatrimoineInfos: Dictionary<ProgPatrimoineInfoEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
  progEtatTechniques?: Dictionary<ProgEtatTechniqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progPatrimoineInfos: (ProgPatrimoineInfo | null)[] } {
  const {
    progPatrimoineInfos,
    patrimoines,
    progComposants,
    progEtatTechniques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progPatrimoineInfos: Object.keys(progPatrimoineInfos).map(idProgPatrimoineInfo =>
      hydrate(
        progPatrimoineInfos[idProgPatrimoineInfo] as ProgPatrimoineInfoEntityState,
        patrimoines,
        progComposants,
        progEtatTechniques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progPatrimoineInfo: ProgPatrimoineInfoEntityState | null } {
  const {
    progPatrimoineInfos,
    patrimoines,
    progComposants,
    progEtatTechniques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progPatrimoineInfo = Object.values(progPatrimoineInfos)[0];
  return {
    progPatrimoineInfo: hydrate(
      progPatrimoineInfo as ProgPatrimoineInfoEntityState,
      patrimoines,
      progComposants,
      progEtatTechniques
    )
  };
}

function hydrate(
  progPatrimoineInfo: ProgPatrimoineInfoEntityState,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
  progEtatTechniqueEntities?: Dictionary<ProgEtatTechniqueEntityState>,
): ProgPatrimoineInfo | null {
  if (!progPatrimoineInfo) {
    return null;
  }

  const progPatrimoineInfoHydrated: ProgPatrimoineInfoEntityState = { ...progPatrimoineInfo };
  if (patrimoineEntities) {
    progPatrimoineInfoHydrated.patrimoine = patrimoineEntities[progPatrimoineInfo.patrimoine as number] as Patrimoine;
  } else {
    delete progPatrimoineInfoHydrated.patrimoine;
  }
  if (progComposantEntities) {
    progPatrimoineInfoHydrated.progComposant = progComposantEntities[progPatrimoineInfo.progComposant as number] as ProgComposant;
  } else {
    delete progPatrimoineInfoHydrated.progComposant;
  }
  if (progEtatTechniqueEntities) {
    progPatrimoineInfoHydrated.progEtatTechnique = progEtatTechniqueEntities[progPatrimoineInfo.progEtatTechnique as number] as ProgEtatTechnique;
  } else {
    delete progPatrimoineInfoHydrated.progEtatTechnique;
  }

  return progPatrimoineInfoHydrated as ProgPatrimoineInfo;
}
