import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteComposantFamilleState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeComposantFamilleRelations: string[] = ['societeComposants','societes','fichiers',];

export const { selectEntities, selectAll } = SocieteComposantFamilleState.adapter.getSelectors();

export const selectSocieteComposantFamilleState = createFeatureSelector<SocieteComposantFamilleState.IState>(SocieteComposantFamilleState.societeComposantFamilleFeatureKey);

export const selectIsLoadedSocieteComposantFamille = createSelector(
  selectSocieteComposantFamilleState,
  (state: SocieteComposantFamilleState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteComposantFamille = createSelector(
  selectSocieteComposantFamilleState,
  (state: SocieteComposantFamilleState.IState) => state.isLoading
);

export const selectIsReadySocieteComposantFamille = createSelector(
  selectSocieteComposantFamilleState,
  (state: SocieteComposantFamilleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteComposantFamille = createSelector(
  selectSocieteComposantFamilleState,
  (state: SocieteComposantFamilleState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteComposantFamillesEntities = createSelector(selectSocieteComposantFamilleState, selectEntities);

export const selectSocieteComposantFamillesArray = createSelector(selectSocieteComposantFamilleState, selectAll);

const societeComposantFamillesInObject = (societeComposantFamilles: Dictionary<SocieteComposantFamilleEntityState>) => ({ societeComposantFamilles })

const selectSocieteComposantFamillesEntitiesDictionary = createSelector(selectSocieteComposantFamillesEntities, societeComposantFamillesInObject);

const selectAllSocieteComposantFamillesObject = createSelector(selectSocieteComposantFamillesEntities, societeComposantFamilles => {
  return hydrateAll({ societeComposantFamilles });
});

const selectOneSocieteComposantFamilleDictionary = (idSocieteComposantFamille : number) =>
  createSelector(selectSocieteComposantFamillesEntities, societeComposantFamilles => ({
    societeComposantFamilles: { [idSocieteComposantFamille]: societeComposantFamilles[idSocieteComposantFamille] }
  }));

const selectOneSocieteComposantFamilleDictionaryWithoutChild = (idSocieteComposantFamille : number) =>
  createSelector(selectSocieteComposantFamillesEntities, societeComposantFamilles => ({
    societeComposantFamille: societeComposantFamilles[idSocieteComposantFamille]
  }));

const selectAllSocieteComposantFamillesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteComposantFamilles(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteComposantFamille>(
      schema,
      selectAllSocieteComposantFamillesSelectors,
      selectSocieteComposantFamillesEntitiesDictionary,
      getRelationSelectors,
      societeComposantFamilleRelations,
      hydrateAll,
      'societeComposantFamille'
    );
  } else {
    return selectAllSocieteComposantFamillesObject;
  }
}

export function selectAllSocieteComposantFamillesDictionary(
  schema: SelectSchema = {},
  customKey = 'societeComposantFamilles'
): Selector {
  return createSelector(selectAllSocieteComposantFamilles(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteComposantFamilleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeComposantFamilles.length; i++) {
      res[customKey][result.societeComposantFamilles[i].idSocieteComposantFamille] = result.societeComposantFamilles[i];
    }
    return res;
  });
}

export function selectOneSocieteComposantFamille(
  schema: SelectSchema = {},
  idSocieteComposantFamille: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteComposantFamilleDictionary(idSocieteComposantFamille)];
  selectors.push(...getRelationSelectors(schema, societeComposantFamilleRelations, 'societeComposantFamille'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteComposantFamilleDictionaryWithoutChild(idSocieteComposantFamille);
  }
}

interface hydrateArgs {
  societeComposantFamilles: Dictionary<SocieteComposantFamilleEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeComposantFamilles: (SocieteComposantFamille | null)[] } {
  const {
    societeComposantFamilles,
    societes,
    fichiers,
    societeComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeComposantFamilles: Object.keys(societeComposantFamilles).map(idSocieteComposantFamille =>
      hydrate(
        societeComposantFamilles[idSocieteComposantFamille] as SocieteComposantFamilleEntityState,
        societes,
        fichiers,
        societeComposants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeComposantFamille: SocieteComposantFamilleEntityState | null } {
  const {
    societeComposantFamilles,
    societes,
    fichiers,
    societeComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeComposantFamille = Object.values(societeComposantFamilles)[0];
  return {
    societeComposantFamille: hydrate(
      societeComposantFamille as SocieteComposantFamilleEntityState,
      societes,
      fichiers,
      societeComposants
    )
  };
}

function hydrate(
  societeComposantFamille: SocieteComposantFamilleEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
): SocieteComposantFamille | null {
  if (!societeComposantFamille) {
    return null;
  }

  const societeComposantFamilleHydrated: SocieteComposantFamilleEntityState = { ...societeComposantFamille };
  if (societeEntities) {
    societeComposantFamilleHydrated.societe = societeEntities[societeComposantFamille.societe as number] as Societe;
  } else {
    delete societeComposantFamilleHydrated.societe;
  }
  if (fichierEntities) {
    societeComposantFamilleHydrated.fichier = fichierEntities[societeComposantFamille.fichier as number] as Fichier;
  } else {
    delete societeComposantFamilleHydrated.fichier;
  }

  if (societeComposantEntities) {
    societeComposantFamilleHydrated.societeComposants = ((societeComposantFamilleHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete societeComposantFamilleHydrated.societeComposants;
  }

  return societeComposantFamilleHydrated as SocieteComposantFamille;
}
