import { ComponentTypeEnum } from '@enums';
import { Espace, Patrimoine } from '@get/api-interfaces';
import { composantRattachementForPatrimoine } from './composants.utils';

// TODO: Enlever l'include inutile des societeComposant dans les rattachements (juste récup l'attributes)
/**
 *
 * @param espaces attributes: ['idEspace', 'defaut'], include: [{ model: SocieteEspaceModel, attributes: ['idSocieteEspace', 'idSocieteEspaceFamille'] }]
 * @param patrimoine include: [{ model: SocietePatrimoineHierarchieModel, attributes: ['idSocietePatrimoineHierarchie'], include: [{ separate: true, model: SocieteComposantRattachementModel, attributes: ['idSocieteComposantRattachement', 'idSocieteEspaceFamille'], include: [{ model: SocieteComposantModel, attributes: ['idSocieteComposant'] }]}]}]
 * @param idSocieteComposant
 * @returns
 */
export function getDefaultEspaceForSocieteComposantOnPatrimoine(
  espaces: Espace[] | null,
  patrimoine: Patrimoine,
  idSocieteComposant?: number
): Espace | undefined {
  if (!idSocieteComposant) {
    return undefined;
  }
  const rattachements = composantRattachementForPatrimoine(
    patrimoine,
    ComponentTypeEnum.societeComposant,
    idSocieteComposant
  );
  const rattachement = rattachements[0];
  if (!rattachement) {
    return undefined;
  }
  const defaultEspaces = espaces?.filter(espace => espace.defaut);

  return (
    (!rattachement?.idSocieteEspaceFamille
      ? defaultEspaces?.find(espace => !espace.societeEspace?.idSocieteEspaceFamille)
      : defaultEspaces?.find(
          espace => espace.societeEspace?.idSocieteEspaceFamille === rattachement?.idSocieteEspaceFamille
        )) ?? defaultEspaces?.[0]
  );
}

export function filterEspacesForSocieteComposantOnPatrimoine(
  espaces: Espace[] | null,
  patrimoine: Patrimoine,
  idSocieteComposant?: number
): Espace[] {
  if (!idSocieteComposant || !espaces?.length) {
    return [];
  }
  const rattachements = composantRattachementForPatrimoine(
    patrimoine,
    ComponentTypeEnum.societeComposant,
    idSocieteComposant
  );
  const rattachement = rattachements[0];
  if (!rattachement) {
    return [];
  }

  return (
    (!rattachement?.idSocieteEspaceFamille
      ? espaces?.filter(espace => !espace.societeEspace?.idSocieteEspaceFamille)
      : espaces?.filter(
          espace => espace.societeEspace?.idSocieteEspaceFamille === rattachement?.idSocieteEspaceFamille
        )) ?? espaces
  );
}
