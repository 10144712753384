import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { OrganisationAzureLoginState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganisationAzureLoginEntityState } from '@get/api-interfaces';

export const organisationAzureLoginReducersGeneratedFunctions: ReducerTypes<OrganisationAzureLoginState.IState, readonly ActionCreator[]>[] = [
  on(OrganisationAzureLoginGeneratedActions.upsertOneOrganisationAzureLogin, (state: OrganisationAzureLoginState.IState) => setLoadingsState(state, true)),

  on(OrganisationAzureLoginGeneratedActions.upsertManyOrganisationAzureLoginsSuccess, (state: OrganisationAzureLoginState.IState, { organisationAzureLogins }) =>
    OrganisationAzureLoginState.adapter.upsertMany(organisationAzureLogins, setLoadingsState(state, false))
  ),
  on(OrganisationAzureLoginGeneratedActions.deleteOneOrganisationAzureLoginSuccess, (state: OrganisationAzureLoginState.IState, { idOrganisationAzureLogin }) =>
    OrganisationAzureLoginState.adapter.removeOne(idOrganisationAzureLogin, setLoadingsState(state, false))
  ),

  on(OrganisationAzureLoginGeneratedActions.clearOrganisationAzureLogins, () => OrganisationAzureLoginState.initialState),

  on(
    OrganisationAzureLoginGeneratedActions.addOrganisationSuccess,
    (state: OrganisationAzureLoginState.IState, { idOrganisationAzureLogin, idOrganisation }) => {
      if (!state.entities[idOrganisationAzureLogin]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganisationAzureLogin]: {
            ...state.entities[idOrganisationAzureLogin],
            organisation: idOrganisation
          }
        }
      };
    }
  ),

  on(
    OrganisationAzureLoginGeneratedActions.deleteManyOrganisationSuccess,
    (state: OrganisationAzureLoginState.IState, { idOrganisations, idOrganisationAzureLogins }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganisationAzureLogins.reduce((entities, idOrganisationAzureLogin) => {
            if (!state.entities[idOrganisationAzureLogin]?.organisation) {
              return entities;
            }
            entities[idOrganisationAzureLogin] = {
              ...state.entities[idOrganisationAzureLogin],
              organisation: idOrganisations.some(
                (idOrganisation: number) => idOrganisation === state.entities[idOrganisationAzureLogin]?.organisation
              )
                ? undefined
                : state.entities[idOrganisationAzureLogin]?.organisation
            } as OrganisationAzureLoginEntityState;
            return entities;
          }, {} as Dictionary<OrganisationAzureLoginEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: OrganisationAzureLoginState.IState,
  isLoading: boolean,
  isLoaded = true
): OrganisationAzureLoginState.IState {
  return { ...state, isLoaded, isLoading };
}
