import { RepositoryService } from '@get/services/repository';
import { Fichier } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedFichierApiService {
  constructor(protected repo: RepositoryService) {}

  public getFichiers(params?: any): Observable<Fichier[]> {
    return this.repo.getData<Fichier[]>('fichier', params);
  }
}
