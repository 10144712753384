import { PatrimoineActions } from '@get/store/actions';
import { PatrimoineState } from '@get/store/states';
import { Action, createReducer, on } from '@ngrx/store';
import { patrimoineReducersGeneratedFunctions, setLoadingsState } from './patrimoine-generated.reducer';

const patrimoineReducersFunctions = [
  ...patrimoineReducersGeneratedFunctions,
  on(PatrimoineActions.deleteManyPatrimoine, (state: PatrimoineState.IState, { idsPatrimoine }) =>
    PatrimoineState.adapter.removeMany(idsPatrimoine, setLoadingsState(state, false))
  )
];

const patrimoineReducer = createReducer(PatrimoineState.initialState, ...patrimoineReducersFunctions);

export function reducer(state: PatrimoineState.IState | undefined, action: Action) {
  return patrimoineReducer(state, action);
}
