import { GridApi } from '@ag-grid-community/core';

export function focusLowestIndex(gridApi: GridApi): void {
  // TODO: Check cette fonction, car si on prend 0 étant le plus petit index possible, le code n'a que peu d'intérêt (à check)
  let lowestIndex = 0;
  let atLeastOneElement = false;
  gridApi?.forEachNode(node => {
    atLeastOneElement = true;
    const index = node.rowIndex;
    if (index && index < lowestIndex) {
      lowestIndex = index;
    }
  });
  if (atLeastOneElement) {
    gridApi?.ensureIndexVisible(lowestIndex, 'middle');
  }
}
