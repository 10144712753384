import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgComposantSousGroupe } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgComposantSousGroupeRelationsIds } from '@get/store/ids-interfaces';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupeSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgComposantSousGroupeService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantSousGroupeSelectors.selectIsLoadedProgComposantSousGroupe));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgComposantSousGroupeSelectors.selectIsLoadingProgComposantSousGroupe));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgComposantSousGroupeSelectors.selectIsReadyAndLoadedProgComposantSousGroupe as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgComposantSousGroupes(schema: SelectSchema = {}): Observable<ProgComposantSousGroupe[]> {
    return this.store$.pipe(select(ProgComposantSousGroupeSelectors.selectAllProgComposantSousGroupes(schema))).pipe(
      switchMap(({ progComposantSousGroupes }: { progComposantSousGroupes: ProgComposantSousGroupe[] }) => {
        return this.getReady(schema).pipe(map(() => progComposantSousGroupes));
      })
    );
  }

  public selectOneProgComposantSousGroupe(
    idProgComposantSousGroupe: number,
    schema: SelectSchema = {}
  ): Observable<ProgComposantSousGroupe> {
    return this.store$
      .pipe(select(ProgComposantSousGroupeSelectors.selectOneProgComposantSousGroupe(schema, idProgComposantSousGroupe)))
      .pipe(
        switchMap(({ progComposantSousGroupe }: { progComposantSousGroupe: ProgComposantSousGroupe }) => {
          return this.getReady(schema).pipe(map(() => progComposantSousGroupe));
        })
      );
  }

  public upsertOneProgComposantSousGroupe(
    progComposantSousGroupe: Partial<ProgComposantSousGroupe>,
    ids: ProgComposantSousGroupeRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgComposantSousGroupe> {
    this.store$.dispatch(ProgComposantSousGroupeGeneratedActions.upsertOneProgComposantSousGroupe({ progComposantSousGroupe, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantSousGroupeGeneratedActions.normalizeManyProgComposantSousGroupesAfterUpsert,
        ProgComposantSousGroupeGeneratedActions.progComposantSousGroupesFailure,
        true
      );
    }
  }

  public deleteOneProgComposantSousGroupe(
    idProgComposantSousGroupe: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupe({ idProgComposantSousGroupe }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupeSuccess,
        ProgComposantSousGroupeGeneratedActions.progComposantSousGroupesFailure
      );
    }
  }
}
