import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { UsageComposantTemplate, UsageComposantTemplateEntityState } from '@get/api-interfaces';
import { Usage, UsageEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ComposantTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const composantTemplateRelations: string[] = ['caracteristiqueTemplates','societeComposants','usageComposantTemplates','usages','fichiers',];

export const { selectEntities, selectAll } = ComposantTemplateState.adapter.getSelectors();

export const selectComposantTemplateState = createFeatureSelector<ComposantTemplateState.IState>(ComposantTemplateState.composantTemplateFeatureKey);

export const selectIsLoadedComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoading
);

export const selectIsReadyComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectComposantTemplatesEntities = createSelector(selectComposantTemplateState, selectEntities);

export const selectComposantTemplatesArray = createSelector(selectComposantTemplateState, selectAll);

const composantTemplatesInObject = (composantTemplates: Dictionary<ComposantTemplateEntityState>) => ({ composantTemplates })

const selectComposantTemplatesEntitiesDictionary = createSelector(selectComposantTemplatesEntities, composantTemplatesInObject);

const selectAllComposantTemplatesObject = createSelector(selectComposantTemplatesEntities, composantTemplates => {
  return hydrateAll({ composantTemplates });
});

const selectOneComposantTemplateDictionary = (idComposantTemplate : number) =>
  createSelector(selectComposantTemplatesEntities, composantTemplates => ({
    composantTemplates: { [idComposantTemplate]: composantTemplates[idComposantTemplate] }
  }));

const selectOneComposantTemplateDictionaryWithoutChild = (idComposantTemplate : number) =>
  createSelector(selectComposantTemplatesEntities, composantTemplates => ({
    composantTemplate: composantTemplates[idComposantTemplate]
  }));

const selectAllComposantTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllComposantTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ComposantTemplate>(
      schema,
      selectAllComposantTemplatesSelectors,
      selectComposantTemplatesEntitiesDictionary,
      getRelationSelectors,
      composantTemplateRelations,
      hydrateAll,
      'composantTemplate'
    );
  } else {
    return selectAllComposantTemplatesObject;
  }
}

export function selectAllComposantTemplatesDictionary(
  schema: SelectSchema = {},
  customKey = 'composantTemplates'
): Selector {
  return createSelector(selectAllComposantTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<ComposantTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.composantTemplates.length; i++) {
      res[customKey][result.composantTemplates[i].idComposantTemplate] = result.composantTemplates[i];
    }
    return res;
  });
}

export function selectOneComposantTemplate(
  schema: SelectSchema = {},
  idComposantTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneComposantTemplateDictionary(idComposantTemplate)];
  selectors.push(...getRelationSelectors(schema, composantTemplateRelations, 'composantTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneComposantTemplateDictionaryWithoutChild(idComposantTemplate);
  }
}

interface hydrateArgs {
  composantTemplates: Dictionary<ComposantTemplateEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  usageComposantTemplates?: Dictionary<UsageComposantTemplateEntityState>;
  usages?: Dictionary<UsageEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { composantTemplates: (ComposantTemplate | null)[] } {
  const {
    composantTemplates,
    fichiers,
    caracteristiqueTemplates,
    societeComposants,
    usageComposantTemplates,
    usages
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    composantTemplates: Object.keys(composantTemplates).map(idComposantTemplate =>
      hydrate(
        composantTemplates[idComposantTemplate] as ComposantTemplateEntityState,
        fichiers,
        caracteristiqueTemplates,
        societeComposants,
        usageComposantTemplates,
        usages
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { composantTemplate: ComposantTemplateEntityState | null } {
  const {
    composantTemplates,
    fichiers,
    caracteristiqueTemplates,
    societeComposants,
    usageComposantTemplates,
    usages
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const composantTemplate = Object.values(composantTemplates)[0];
  return {
    composantTemplate: hydrate(
      composantTemplate as ComposantTemplateEntityState,
      fichiers,
      caracteristiqueTemplates,
      societeComposants,
      usageComposantTemplates,
      usages
    )
  };
}

function hydrate(
  composantTemplate: ComposantTemplateEntityState,
  fichierEntities?: Dictionary<FichierEntityState>,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  usageComposantTemplateEntities?: Dictionary<UsageComposantTemplateEntityState>,
  usageEntities?: Dictionary<UsageEntityState>,
): ComposantTemplate | null {
  if (!composantTemplate) {
    return null;
  }

  const composantTemplateHydrated: ComposantTemplateEntityState = { ...composantTemplate };
  if (fichierEntities) {
    composantTemplateHydrated.fichier = fichierEntities[composantTemplate.fichier as number] as Fichier;
  } else {
    delete composantTemplateHydrated.fichier;
  }

  if (caracteristiqueTemplateEntities) {
    composantTemplateHydrated.caracteristiqueTemplates = ((composantTemplateHydrated.caracteristiqueTemplates as number[]) || []).map(
      id => caracteristiqueTemplateEntities[id]
    ) as CaracteristiqueTemplate[];
  } else {
    delete composantTemplateHydrated.caracteristiqueTemplates;
  }

  if (societeComposantEntities) {
    composantTemplateHydrated.societeComposants = ((composantTemplateHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete composantTemplateHydrated.societeComposants;
  }

  if (usageComposantTemplateEntities) {
    composantTemplateHydrated.usageComposantTemplates = ((composantTemplateHydrated.usageComposantTemplates as number[]) || []).map(
      id => usageComposantTemplateEntities[id]
    ) as UsageComposantTemplate[];
  } else {
    delete composantTemplateHydrated.usageComposantTemplates;
  }

  if (usageEntities) {
    composantTemplateHydrated.usages = ((composantTemplateHydrated.usages as number[]) || []).map(
      id => usageEntities[id]
    ) as Usage[];
  } else {
    delete composantTemplateHydrated.usages;
  }

  return composantTemplateHydrated as ComposantTemplate;
}
