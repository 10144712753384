import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteComposantRattachementState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeComposantRattachementRelations: string[] = ['societeComposants','societeEspaceFamilles','societePatrimoineHierarchies',];

export const { selectEntities, selectAll } = SocieteComposantRattachementState.adapter.getSelectors();

export const selectSocieteComposantRattachementState = createFeatureSelector<SocieteComposantRattachementState.IState>(SocieteComposantRattachementState.societeComposantRattachementFeatureKey);

export const selectIsLoadedSocieteComposantRattachement = createSelector(
  selectSocieteComposantRattachementState,
  (state: SocieteComposantRattachementState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteComposantRattachement = createSelector(
  selectSocieteComposantRattachementState,
  (state: SocieteComposantRattachementState.IState) => state.isLoading
);

export const selectIsReadySocieteComposantRattachement = createSelector(
  selectSocieteComposantRattachementState,
  (state: SocieteComposantRattachementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteComposantRattachement = createSelector(
  selectSocieteComposantRattachementState,
  (state: SocieteComposantRattachementState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteComposantRattachementsEntities = createSelector(selectSocieteComposantRattachementState, selectEntities);

export const selectSocieteComposantRattachementsArray = createSelector(selectSocieteComposantRattachementState, selectAll);

const societeComposantRattachementsInObject = (societeComposantRattachements: Dictionary<SocieteComposantRattachementEntityState>) => ({ societeComposantRattachements })

const selectSocieteComposantRattachementsEntitiesDictionary = createSelector(selectSocieteComposantRattachementsEntities, societeComposantRattachementsInObject);

const selectAllSocieteComposantRattachementsObject = createSelector(selectSocieteComposantRattachementsEntities, societeComposantRattachements => {
  return hydrateAll({ societeComposantRattachements });
});

const selectOneSocieteComposantRattachementDictionary = (idSocieteComposantRattachement : number) =>
  createSelector(selectSocieteComposantRattachementsEntities, societeComposantRattachements => ({
    societeComposantRattachements: { [idSocieteComposantRattachement]: societeComposantRattachements[idSocieteComposantRattachement] }
  }));

const selectOneSocieteComposantRattachementDictionaryWithoutChild = (idSocieteComposantRattachement : number) =>
  createSelector(selectSocieteComposantRattachementsEntities, societeComposantRattachements => ({
    societeComposantRattachement: societeComposantRattachements[idSocieteComposantRattachement]
  }));

const selectAllSocieteComposantRattachementsSelectors: Dictionary<Selector> = {};
export function selectAllSocieteComposantRattachements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteComposantRattachement>(
      schema,
      selectAllSocieteComposantRattachementsSelectors,
      selectSocieteComposantRattachementsEntitiesDictionary,
      getRelationSelectors,
      societeComposantRattachementRelations,
      hydrateAll,
      'societeComposantRattachement'
    );
  } else {
    return selectAllSocieteComposantRattachementsObject;
  }
}

export function selectAllSocieteComposantRattachementsDictionary(
  schema: SelectSchema = {},
  customKey = 'societeComposantRattachements'
): Selector {
  return createSelector(selectAllSocieteComposantRattachements(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteComposantRattachementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeComposantRattachements.length; i++) {
      res[customKey][result.societeComposantRattachements[i].idSocieteComposantRattachement] = result.societeComposantRattachements[i];
    }
    return res;
  });
}

export function selectOneSocieteComposantRattachement(
  schema: SelectSchema = {},
  idSocieteComposantRattachement: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteComposantRattachementDictionary(idSocieteComposantRattachement)];
  selectors.push(...getRelationSelectors(schema, societeComposantRattachementRelations, 'societeComposantRattachement'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteComposantRattachementDictionaryWithoutChild(idSocieteComposantRattachement);
  }
}

interface hydrateArgs {
  societeComposantRattachements: Dictionary<SocieteComposantRattachementEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  societeEspaceFamilles?: Dictionary<SocieteEspaceFamilleEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeComposantRattachements: (SocieteComposantRattachement | null)[] } {
  const {
    societeComposantRattachements,
    societeComposants,
    societeEspaceFamilles,
    societePatrimoineHierarchies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeComposantRattachements: Object.keys(societeComposantRattachements).map(idSocieteComposantRattachement =>
      hydrate(
        societeComposantRattachements[idSocieteComposantRattachement] as SocieteComposantRattachementEntityState,
        societeComposants,
        societeEspaceFamilles,
        societePatrimoineHierarchies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeComposantRattachement: SocieteComposantRattachementEntityState | null } {
  const {
    societeComposantRattachements,
    societeComposants,
    societeEspaceFamilles,
    societePatrimoineHierarchies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeComposantRattachement = Object.values(societeComposantRattachements)[0];
  return {
    societeComposantRattachement: hydrate(
      societeComposantRattachement as SocieteComposantRattachementEntityState,
      societeComposants,
      societeEspaceFamilles,
      societePatrimoineHierarchies
    )
  };
}

function hydrate(
  societeComposantRattachement: SocieteComposantRattachementEntityState,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  societeEspaceFamilleEntities?: Dictionary<SocieteEspaceFamilleEntityState>,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
): SocieteComposantRattachement | null {
  if (!societeComposantRattachement) {
    return null;
  }

  const societeComposantRattachementHydrated: SocieteComposantRattachementEntityState = { ...societeComposantRattachement };
  if (societeComposantEntities) {
    societeComposantRattachementHydrated.societeComposant = societeComposantEntities[societeComposantRattachement.societeComposant as number] as SocieteComposant;
  } else {
    delete societeComposantRattachementHydrated.societeComposant;
  }
  if (societeEspaceFamilleEntities) {
    societeComposantRattachementHydrated.societeEspaceFamille = societeEspaceFamilleEntities[societeComposantRattachement.societeEspaceFamille as number] as SocieteEspaceFamille;
  } else {
    delete societeComposantRattachementHydrated.societeEspaceFamille;
  }
  if (societePatrimoineHierarchieEntities) {
    societeComposantRattachementHydrated.societePatrimoineHierarchie = societePatrimoineHierarchieEntities[societeComposantRattachement.societePatrimoineHierarchie as number] as SocietePatrimoineHierarchie;
  } else {
    delete societeComposantRattachementHydrated.societePatrimoineHierarchie;
  }

  return societeComposantRattachementHydrated as SocieteComposantRattachement;
}
