import { createEntityAdapter } from '@ngrx/entity';
import { ProgUniteEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgUniteEntityState>;

export const adapter = createEntityAdapter<ProgUniteEntityState>({
  selectId: o => o.idProgUnite
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progUniteFeatureKey = 'progUnite';
