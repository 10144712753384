import { GridError, SocieteEspace, SocieteEspaceDataRow, ValidationErrorInterface } from '@get/api-interfaces';

export function validateSocieteEspaceProperties(
  societeEspaceToCreate: Partial<SocieteEspace>
): ValidationErrorInterface<SocieteEspace>[] {
  const errors: ValidationErrorInterface<SocieteEspace>[] = [];
  if (!societeEspaceToCreate.libelleTechnique || !societeEspaceToCreate.idSocieteEspaceFamille) {
    errors.push({
      properties: ['libelleTechnique', 'idSocieteEspaceFamille'],
      message: 'You need a libelleTechnique and an idSocieteEspaceFamille to do this action'
    });
  }
  return errors;
}

export function mergeSocieteEspaceAndGridErrors(
  societeEspaces: SocieteEspace[],
  gridErrors: GridError[]
): SocieteEspaceDataRow[] {
  const societeEspaceDataRows: SocieteEspaceDataRow[] = [...(societeEspaces as SocieteEspaceDataRow[])];
  gridErrors?.forEach(gridErrorSocieteEspace => {
    if (gridErrorSocieteEspace.error.idSocieteEspace > 0) {
      const index = societeEspaceDataRows.findIndex(
        espace => espace.idSocieteEspace === gridErrorSocieteEspace.error.idSocieteEspace
      );

      societeEspaceDataRows[index] = { ...gridErrorSocieteEspace.error };
    } else {
      societeEspaceDataRows.push({ ...gridErrorSocieteEspace.error });
    }
  });
  return societeEspaceDataRows;
}

export function societeEspaceDefaultValue(idSocieteEspaceFamille: number): Partial<SocieteEspace> {
  return {
    idSocieteEspaceFamille,
    libelle: 'defaut',
    libelleTechnique: 'defaut',
    defaut: true
  };
}
