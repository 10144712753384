import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgInterventionScenario, ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionScenario, ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgScenarioState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progScenarioRelations: string[] = ['progInterventionScenarios','progInterventions','progBiblioInterventionScenarios','progBiblioInterventions','societes',];

export const { selectEntities, selectAll } = ProgScenarioState.adapter.getSelectors();

export const selectProgScenarioState = createFeatureSelector<ProgScenarioState.IState>(ProgScenarioState.progScenarioFeatureKey);

export const selectIsLoadedProgScenario = createSelector(
  selectProgScenarioState,
  (state: ProgScenarioState.IState) => state.isLoaded
);

export const selectIsLoadingProgScenario = createSelector(
  selectProgScenarioState,
  (state: ProgScenarioState.IState) => state.isLoading
);

export const selectIsReadyProgScenario = createSelector(
  selectProgScenarioState,
  (state: ProgScenarioState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgScenario = createSelector(
  selectProgScenarioState,
  (state: ProgScenarioState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgScenariosEntities = createSelector(selectProgScenarioState, selectEntities);

export const selectProgScenariosArray = createSelector(selectProgScenarioState, selectAll);

const progScenariosInObject = (progScenarios: Dictionary<ProgScenarioEntityState>) => ({ progScenarios })

const selectProgScenariosEntitiesDictionary = createSelector(selectProgScenariosEntities, progScenariosInObject);

const selectAllProgScenariosObject = createSelector(selectProgScenariosEntities, progScenarios => {
  return hydrateAll({ progScenarios });
});

const selectOneProgScenarioDictionary = (idProgScenario : number) =>
  createSelector(selectProgScenariosEntities, progScenarios => ({
    progScenarios: { [idProgScenario]: progScenarios[idProgScenario] }
  }));

const selectOneProgScenarioDictionaryWithoutChild = (idProgScenario : number) =>
  createSelector(selectProgScenariosEntities, progScenarios => ({
    progScenario: progScenarios[idProgScenario]
  }));

const selectAllProgScenariosSelectors: Dictionary<Selector> = {};
export function selectAllProgScenarios(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgScenario>(
      schema,
      selectAllProgScenariosSelectors,
      selectProgScenariosEntitiesDictionary,
      getRelationSelectors,
      progScenarioRelations,
      hydrateAll,
      'progScenario'
    );
  } else {
    return selectAllProgScenariosObject;
  }
}

export function selectAllProgScenariosDictionary(
  schema: SelectSchema = {},
  customKey = 'progScenarios'
): Selector {
  return createSelector(selectAllProgScenarios(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgScenarioEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progScenarios.length; i++) {
      res[customKey][result.progScenarios[i].idProgScenario] = result.progScenarios[i];
    }
    return res;
  });
}

export function selectOneProgScenario(
  schema: SelectSchema = {},
  idProgScenario: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgScenarioDictionary(idProgScenario)];
  selectors.push(...getRelationSelectors(schema, progScenarioRelations, 'progScenario'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgScenarioDictionaryWithoutChild(idProgScenario);
  }
}

interface hydrateArgs {
  progScenarios: Dictionary<ProgScenarioEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progInterventionScenarios?: Dictionary<ProgInterventionScenarioEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
  progBiblioInterventionScenarios?: Dictionary<ProgBiblioInterventionScenarioEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progScenarios: (ProgScenario | null)[] } {
  const {
    progScenarios,
    societes,
    progInterventionScenarios,
    progInterventions,
    progBiblioInterventionScenarios,
    progBiblioInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progScenarios: Object.keys(progScenarios).map(idProgScenario =>
      hydrate(
        progScenarios[idProgScenario] as ProgScenarioEntityState,
        societes,
        progInterventionScenarios,
        progInterventions,
        progBiblioInterventionScenarios,
        progBiblioInterventions
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progScenario: ProgScenarioEntityState | null } {
  const {
    progScenarios,
    societes,
    progInterventionScenarios,
    progInterventions,
    progBiblioInterventionScenarios,
    progBiblioInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progScenario = Object.values(progScenarios)[0];
  return {
    progScenario: hydrate(
      progScenario as ProgScenarioEntityState,
      societes,
      progInterventionScenarios,
      progInterventions,
      progBiblioInterventionScenarios,
      progBiblioInterventions
    )
  };
}

function hydrate(
  progScenario: ProgScenarioEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progInterventionScenarioEntities?: Dictionary<ProgInterventionScenarioEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
  progBiblioInterventionScenarioEntities?: Dictionary<ProgBiblioInterventionScenarioEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
): ProgScenario | null {
  if (!progScenario) {
    return null;
  }

  const progScenarioHydrated: ProgScenarioEntityState = { ...progScenario };
  if (societeEntities) {
    progScenarioHydrated.societe = societeEntities[progScenario.societe as number] as Societe;
  } else {
    delete progScenarioHydrated.societe;
  }

  if (progInterventionScenarioEntities) {
    progScenarioHydrated.progInterventionScenarios = ((progScenarioHydrated.progInterventionScenarios as number[]) || []).map(
      id => progInterventionScenarioEntities[id]
    ) as ProgInterventionScenario[];
  } else {
    delete progScenarioHydrated.progInterventionScenarios;
  }

  if (progInterventionEntities) {
    progScenarioHydrated.progInterventions = ((progScenarioHydrated.progInterventions as number[]) || []).map(
      id => progInterventionEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progScenarioHydrated.progInterventions;
  }

  if (progBiblioInterventionScenarioEntities) {
    progScenarioHydrated.progBiblioInterventionScenarios = ((progScenarioHydrated.progBiblioInterventionScenarios as number[]) || []).map(
      id => progBiblioInterventionScenarioEntities[id]
    ) as ProgBiblioInterventionScenario[];
  } else {
    delete progScenarioHydrated.progBiblioInterventionScenarios;
  }

  if (progBiblioInterventionEntities) {
    progScenarioHydrated.progBiblioInterventions = ((progScenarioHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete progScenarioHydrated.progBiblioInterventions;
  }

  return progScenarioHydrated as ProgScenario;
}
