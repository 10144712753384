import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PatrimoineAncetre, PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { PatrimoineAncetreState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const patrimoineAncetreRelations: string[] = ['ancetrePatrimoine','descendantPatrimoine',];

export const { selectEntities, selectAll } = PatrimoineAncetreState.adapter.getSelectors();

export const selectPatrimoineAncetreState = createFeatureSelector<PatrimoineAncetreState.IState>(PatrimoineAncetreState.patrimoineAncetreFeatureKey);

export const selectIsLoadedPatrimoineAncetre = createSelector(
  selectPatrimoineAncetreState,
  (state: PatrimoineAncetreState.IState) => state.isLoaded
);

export const selectIsLoadingPatrimoineAncetre = createSelector(
  selectPatrimoineAncetreState,
  (state: PatrimoineAncetreState.IState) => state.isLoading
);

export const selectIsReadyPatrimoineAncetre = createSelector(
  selectPatrimoineAncetreState,
  (state: PatrimoineAncetreState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedPatrimoineAncetre = createSelector(
  selectPatrimoineAncetreState,
  (state: PatrimoineAncetreState.IState) => state.isLoaded && !state.isLoading
);

export const selectPatrimoineAncetresEntities = createSelector(selectPatrimoineAncetreState, selectEntities);

export const selectPatrimoineAncetresArray = createSelector(selectPatrimoineAncetreState, selectAll);

const patrimoineAncetresInObject = (patrimoineAncetres: Dictionary<PatrimoineAncetreEntityState>) => ({ patrimoineAncetres })

const selectPatrimoineAncetresEntitiesDictionary = createSelector(selectPatrimoineAncetresEntities, patrimoineAncetresInObject);

const selectAllPatrimoineAncetresObject = createSelector(selectPatrimoineAncetresEntities, patrimoineAncetres => {
  return hydrateAll({ patrimoineAncetres });
});

const selectOnePatrimoineAncetreDictionary = (idPatrimoineAncetre : number) =>
  createSelector(selectPatrimoineAncetresEntities, patrimoineAncetres => ({
    patrimoineAncetres: { [idPatrimoineAncetre]: patrimoineAncetres[idPatrimoineAncetre] }
  }));

const selectOnePatrimoineAncetreDictionaryWithoutChild = (idPatrimoineAncetre : number) =>
  createSelector(selectPatrimoineAncetresEntities, patrimoineAncetres => ({
    patrimoineAncetre: patrimoineAncetres[idPatrimoineAncetre]
  }));

const selectAllPatrimoineAncetresSelectors: Dictionary<Selector> = {};
export function selectAllPatrimoineAncetres(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<PatrimoineAncetre>(
      schema,
      selectAllPatrimoineAncetresSelectors,
      selectPatrimoineAncetresEntitiesDictionary,
      getRelationSelectors,
      patrimoineAncetreRelations,
      hydrateAll,
      'patrimoineAncetre'
    );
  } else {
    return selectAllPatrimoineAncetresObject;
  }
}

export function selectAllPatrimoineAncetresDictionary(
  schema: SelectSchema = {},
  customKey = 'patrimoineAncetres'
): Selector {
  return createSelector(selectAllPatrimoineAncetres(schema), result => {
    const res = { [customKey]: {} as Dictionary<PatrimoineAncetreEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.patrimoineAncetres.length; i++) {
      res[customKey][result.patrimoineAncetres[i].idPatrimoineAncetre] = result.patrimoineAncetres[i];
    }
    return res;
  });
}

export function selectOnePatrimoineAncetre(
  schema: SelectSchema = {},
  idPatrimoineAncetre: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOnePatrimoineAncetreDictionary(idPatrimoineAncetre)];
  selectors.push(...getRelationSelectors(schema, patrimoineAncetreRelations, 'patrimoineAncetre'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOnePatrimoineAncetreDictionaryWithoutChild(idPatrimoineAncetre);
  }
}

interface hydrateArgs {
  patrimoineAncetres: Dictionary<PatrimoineAncetreEntityState>;
  ancetrePatrimoine?: Dictionary<PatrimoineEntityState>;
  descendantPatrimoine?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { patrimoineAncetres: (PatrimoineAncetre | null)[] } {
  const {
    patrimoineAncetres,
    ancetrePatrimoine,
    descendantPatrimoine
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    patrimoineAncetres: Object.keys(patrimoineAncetres).map(idPatrimoineAncetre =>
      hydrate(
        patrimoineAncetres[idPatrimoineAncetre] as PatrimoineAncetreEntityState,
        ancetrePatrimoine,
        descendantPatrimoine
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { patrimoineAncetre: PatrimoineAncetreEntityState | null } {
  const {
    patrimoineAncetres,
    ancetrePatrimoine,
    descendantPatrimoine
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const patrimoineAncetre = Object.values(patrimoineAncetres)[0];
  return {
    patrimoineAncetre: hydrate(
      patrimoineAncetre as PatrimoineAncetreEntityState,
      ancetrePatrimoine,
      descendantPatrimoine
    )
  };
}

function hydrate(
  patrimoineAncetre: PatrimoineAncetreEntityState,
  ancetrePatrimoineEntities?: Dictionary<PatrimoineEntityState>,
  descendantPatrimoineEntities?: Dictionary<PatrimoineEntityState>,
): PatrimoineAncetre | null {
  if (!patrimoineAncetre) {
    return null;
  }

  const patrimoineAncetreHydrated: PatrimoineAncetreEntityState = { ...patrimoineAncetre };
  if (ancetrePatrimoineEntities) {
    patrimoineAncetreHydrated.ancetrePatrimoine = ancetrePatrimoineEntities[patrimoineAncetre.ancetrePatrimoine as number] as Patrimoine;
  } else {
    delete patrimoineAncetreHydrated.ancetrePatrimoine;
  }
  if (descendantPatrimoineEntities) {
    patrimoineAncetreHydrated.descendantPatrimoine = descendantPatrimoineEntities[patrimoineAncetre.descendantPatrimoine as number] as Patrimoine;
  } else {
    delete patrimoineAncetreHydrated.descendantPatrimoine;
  }

  return patrimoineAncetreHydrated as PatrimoineAncetre;
}
