import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { OrganisationAzureLoginApiService } from '@get/store/api-services';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeOrganisationAzureLogin } from '@get/store/configs/normalization';

export class GeneratedOrganisationAzureLoginEffects {
  constructor(
    protected actions$: Actions,
    protected organisationAzureLoginApiService: OrganisationAzureLoginApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyOrganisationAzureLoginsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert),
      concatMap(({ organisationAzureLogins }) => {
        const actions: Action[] = getActionsToNormalizeOrganisationAzureLogin(organisationAzureLogins, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganisationAzureLogin] Normalization After Upsert Success')];
      })
    );
  });
}
