import { GridError, SocieteTerritoire, SocieteTerritoireDataRow, ValidationErrorInterface } from '@get/api-interfaces';

export function validateSocieteTerritoireProperties(
  societeTerritoireToCreate: Partial<SocieteTerritoire>
): ValidationErrorInterface<SocieteTerritoire>[] {
  const errors: ValidationErrorInterface<SocieteTerritoire>[] = [];
  if (!societeTerritoireToCreate.libelleTechnique || !societeTerritoireToCreate.libelle) {
    errors.push({
      properties: ['libelleTechnique', 'libelle'],
      message: 'You need a libelleTechnique and a libelle to do this action'
    });
  }
  return errors;
}

export function mergeSocieteTerritoireAndGridErrors(
  societeTerritoires: SocieteTerritoire[],
  gridErrors: GridError[]
): SocieteTerritoireDataRow[] {
  const societeTerritoireDataRows: SocieteTerritoireDataRow[] = [...(societeTerritoires as SocieteTerritoireDataRow[])];
  gridErrors?.forEach(gridErrorSocieteTerritoire => {
    if (gridErrorSocieteTerritoire.error.idSocieteTerritoire > 0) {
      const index = societeTerritoireDataRows.findIndex(
        espace => espace.idSocieteTerritoire === gridErrorSocieteTerritoire.error.idSocieteTerritoire
      );

      societeTerritoireDataRows[index] = { ...gridErrorSocieteTerritoire.error };
    } else {
      societeTerritoireDataRows.push({ ...gridErrorSocieteTerritoire.error });
    }
  });
  return societeTerritoireDataRows;
}
