import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { ProgInterventionFamilleState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgInterventionFamilleEntityState } from '@get/api-interfaces';

export const progInterventionFamilleReducersGeneratedFunctions: ReducerTypes<ProgInterventionFamilleState.IState, readonly ActionCreator[]>[] = [
  on(ProgInterventionFamilleGeneratedActions.upsertOneProgInterventionFamille, (state: ProgInterventionFamilleState.IState) => setLoadingsState(state, true)),

  on(ProgInterventionFamilleGeneratedActions.upsertManyProgInterventionFamillesSuccess, (state: ProgInterventionFamilleState.IState, { progInterventionFamilles }) =>
    ProgInterventionFamilleState.adapter.upsertMany(progInterventionFamilles, setLoadingsState(state, false))
  ),
  on(ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamille, (state: ProgInterventionFamilleState.IState) => setLoadingsState(state, true)),
  on(ProgInterventionFamilleGeneratedActions.deleteOneProgInterventionFamilleSuccess, (state: ProgInterventionFamilleState.IState, { idProgInterventionFamille }) =>
    ProgInterventionFamilleState.adapter.removeOne(idProgInterventionFamille, setLoadingsState(state, false))
  ),

  on(ProgInterventionFamilleGeneratedActions.clearProgInterventionFamilles, () => ProgInterventionFamilleState.initialState),

  on(
    ProgInterventionFamilleGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: ProgInterventionFamilleState.IState, { idProgInterventionFamille, idProgBiblioInterventions }) => {
      if (!state.entities[idProgInterventionFamille]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idProgInterventionFamille]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgInterventionFamille]: {
            ...state.entities[idProgInterventionFamille],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgInterventionFamilleGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgInterventionFamilleState.IState, { idProgBiblioInterventions, idProgInterventionFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgInterventionFamilles.reduce((entities, idProgInterventionFamille) => {
            if (!state.entities[idProgInterventionFamille]?.progBiblioInterventions) {
              return entities;
            }
            entities[idProgInterventionFamille] = {
              ...state.entities[idProgInterventionFamille],
              progBiblioInterventions: (state.entities[idProgInterventionFamille]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as ProgInterventionFamilleEntityState;
            return entities;
          }, {} as Dictionary<ProgInterventionFamilleEntityState>)
        }
      };
    }
  ),

  on(
    ProgInterventionFamilleGeneratedActions.addSocieteSuccess,
    (state: ProgInterventionFamilleState.IState, { idProgInterventionFamille, idSociete }) => {
      if (!state.entities[idProgInterventionFamille]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgInterventionFamille]: {
            ...state.entities[idProgInterventionFamille],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgInterventionFamilleGeneratedActions.deleteManySocieteSuccess,
    (state: ProgInterventionFamilleState.IState, { idSocietes, idProgInterventionFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgInterventionFamilles.reduce((entities, idProgInterventionFamille) => {
            if (!state.entities[idProgInterventionFamille]?.societe) {
              return entities;
            }
            entities[idProgInterventionFamille] = {
              ...state.entities[idProgInterventionFamille],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgInterventionFamille]?.societe
              )
                ? undefined
                : state.entities[idProgInterventionFamille]?.societe
            } as ProgInterventionFamilleEntityState;
            return entities;
          }, {} as Dictionary<ProgInterventionFamilleEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgInterventionFamilleState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgInterventionFamilleState.IState {
  return { ...state, isLoaded, isLoading };
}
