export enum ModulePathKeyEnum {
  admin = 'admin',
  analyze = 'analyze',
  campaign = 'campaign',
  consult = 'consult',
  societe = 'societe',
  user = 'user',
  notification = 'notification',
  support = 'support',
  programmation = 'programmation'
}
