import { ComposantAttenduReponseEnum } from '@enums';
import {
  Composant,
  ComposantAttendu,
  DynamicType,
  Patrimoine,
  PatrimoineDictWithChildrenCountInterface,
  PatrimoineWithCountInterface,
  SocieteCaracteristique,
  SocieteComposant
} from '@get/api-interfaces';
import { transformArrayToObject } from '../tree-handler.utils';
import { extractNumberFromComposant, getComposantsFromEspaces } from './composants.utils';

export function updatePatrimoineComposantAttendusValuesFromChild(
  patrimoine: PatrimoineWithCountInterface,
  child: PatrimoineWithCountInterface
): void {
  const idsSocieteComposant = Object.keys(child.societeComposants);
  for (let j = 0; j < idsSocieteComposant.length; j++) {
    const idSocieteComposant = idsSocieteComposant[j];
    patrimoine.societeComposants[idSocieteComposant] =
      (patrimoine.societeComposants[idSocieteComposant] ?? 0) + (child.societeComposants[idSocieteComposant] ?? 0);
    if (patrimoine.composantAttendus[idSocieteComposant]) {
      if (child.composantAttendus[idSocieteComposant]) {
        if (child.composantAttendus[idSocieteComposant]?.valeur) {
          patrimoine.composantAttendus[idSocieteComposant].valeur =
            (patrimoine.composantAttendus[idSocieteComposant].valeur ?? 0) +
            (child.composantAttendus[idSocieteComposant]?.valeur ?? 0);
        }
        if (child.composantAttendus[idSocieteComposant]?.nbReponsesRempli) {
          patrimoine.composantAttendus[idSocieteComposant].nbReponsesRempli =
            (patrimoine.composantAttendus[idSocieteComposant].nbReponsesRempli ?? 0) +
            (child.composantAttendus[idSocieteComposant]?.nbReponsesRempli ?? 0);
        }
        if (child.composantAttendus[idSocieteComposant]?.nbReponsesAttendu) {
          patrimoine.composantAttendus[idSocieteComposant].nbReponsesAttendu =
            (patrimoine.composantAttendus[idSocieteComposant].nbReponsesAttendu ?? 0) +
            (child.composantAttendus[idSocieteComposant]?.nbReponsesAttendu ?? 0);
        }
        if (child.composantAttendus[idSocieteComposant]?.nbValeursRempli) {
          patrimoine.composantAttendus[idSocieteComposant].nbValeursRempli =
            (patrimoine.composantAttendus[idSocieteComposant].nbValeursRempli ?? 0) +
            (child.composantAttendus[idSocieteComposant]?.nbValeursRempli ?? 0);
        }
        if (child.composantAttendus[idSocieteComposant]?.nbValeursAttendu) {
          patrimoine.composantAttendus[idSocieteComposant].nbValeursAttendu =
            (patrimoine.composantAttendus[idSocieteComposant].nbValeursAttendu ?? 0) +
            (child.composantAttendus[idSocieteComposant]?.nbValeursAttendu ?? 0);
        }
      }
    } else {
      patrimoine.composantAttendus[idSocieteComposant] = {
        valeur: child.composantAttendus[idSocieteComposant]?.valeur ?? 0,
        nbReponsesRempli: child.composantAttendus[idSocieteComposant]?.nbReponsesRempli ?? 0,
        nbReponsesAttendu: child.composantAttendus[idSocieteComposant]?.nbReponsesAttendu ?? 0,
        nbValeursRempli: child.composantAttendus[idSocieteComposant]?.nbValeursRempli ?? 0,
        nbValeursAttendu: child.composantAttendus[idSocieteComposant]?.nbValeursAttendu ?? 0
      };
    }
    if (patrimoine.composantAttendus[idSocieteComposant].nbReponsesRempli !== 0) {
      patrimoine.composantAttendus[idSocieteComposant].calcule = false;
    }
  }
}

export function updatePatrimoineComposantAttendusFromDescendants(
  idPatrimoine: number,
  patrimoinesObj: PatrimoineDictWithChildrenCountInterface
): void {
  const pat = patrimoinesObj[idPatrimoine];
  if (pat && !pat.updated) {
    for (let i = 0; i < (pat.descendants?.length ?? 0); i++) {
      const idDescendant = pat.descendants?.[i].idDescendantPatrimoine;
      updatePatrimoineComposantAttendusFromDescendants(idDescendant as number, patrimoinesObj);
      if (pat.descendants?.[i].niveau === 1) {
        const patrimoineDescendant = patrimoinesObj[idDescendant as number];
        if (patrimoineDescendant) {
          updatePatrimoineComposantAttendusValuesFromChild(pat, patrimoineDescendant);
        }
      }
    }
    pat.updated = true;
  }
}

export function updatePatrimoineComposantAttendusFromChildren(
  idPatrimoine: number,
  patrimoinesObj: PatrimoineDictWithChildrenCountInterface
): void {
  const pat = patrimoinesObj[idPatrimoine];
  if (pat && !pat.updated) {
    for (let i = 0; i < (pat.children?.length ?? 0); i++) {
      const idDescendant = pat.children?.[i].idPatrimoine;
      if (idDescendant) {
        updatePatrimoineComposantAttendusFromChildren(idDescendant as number, patrimoinesObj);
        const patrimoineDescendant = patrimoinesObj[idDescendant as number];
        if (patrimoineDescendant) {
          updatePatrimoineComposantAttendusValuesFromChild(pat, patrimoineDescendant);
        }
      }
    }
    pat.updated = true;
  }
}

export function turnPatrimoineToSocieteComposantsObj(patrimoine: Patrimoine): DynamicType<number> {
  // TODO: Check pour utiliser Object.assign au lieu de remplir les clés à la main sur les reduce de toute l'app
  const obj =
    patrimoine.societePatrimoineHierarchie?.societeComposantRattachements?.reduce(
      (acc, societeComposantRattachement) => {
        acc[societeComposantRattachement.idSocieteComposant] = 0;
        return acc;
      },
      {} as DynamicType<number>
    ) || {};

  for (let i = 0; i < patrimoine.espaces?.length; i++) {
    const espace = patrimoine.espaces[i];
    for (let j = 0; j < espace.composants?.length; j++) {
      const composant = espace.composants[j];
      obj[composant.idSocieteComposant] = (obj[composant.idSocieteComposant] ?? 0) + 1;
    }
  }
  return obj;
}

/**
 * @example
 * patrimoine
 * --> composantAttendu
 * --> societePatrimoineHierarchie
 * ----> societeComposantRattachements
 * --> espaces
 * ----> composants
 * ------> valeurs
 * --------> societeCaracteristique
 */
export function turnPatrimoineToComposantsAttendusObj(
  patrimoine: Patrimoine,
  societeComposantsDict: DynamicType<SocieteComposant>,
  idUser: number
): DynamicType<Partial<ComposantAttendu>> {
  // On recompte le nombre de réponses à chaque fois donc on repart de 0
  const composants = getComposantsFromEspaces(patrimoine.espaces);
  const composantsPerSocieteDict = composants.reduce<DynamicType<Composant[]>>((acc, curr) => {
    if (!acc[curr.idSocieteComposant]) {
      acc[curr.idSocieteComposant] = [];
    }
    acc[curr.idSocieteComposant].push(curr);
    return acc;
  }, {});
  const composantsAttendusDict =
    patrimoine.composantAttendus?.reduce((accComposantAttendus, composantAttendu) => {
      const composantBySocieteComposant = composantsPerSocieteDict[composantAttendu.idSocieteComposant] ?? [];
      const filteredSocieteCaracs = societeComposantsDict[
        composantAttendu?.idSocieteComposant
      ]?.societeCaracteristiques?.filter(el => el.completion);
      const societeCaracsCompletionDict: DynamicType<SocieteCaracteristique> = transformArrayToObject(
        filteredSocieteCaracs,
        { key: 'idSocieteCaracteristique' }
      );
      const valeurComposantBySocieteComposant = composantBySocieteComposant.map(el => el?.valeurs).flat();
      const valeurCompletion = valeurComposantBySocieteComposant.filter(
        el => societeCaracsCompletionDict[el?.idSocieteCaracteristique]?.completion
      );
      accComposantAttendus[composantAttendu.idSocieteComposant] = {
        ...composantAttendu,
        // On écrase les réponse à oui par null pour gérer le cas où on enlève le dernier composant d'un patrimoine, la réponse doit être vidée
        reponse:
          composantAttendu.reponse === ComposantAttenduReponseEnum.oui
            ? (null as unknown as ComposantAttenduReponseEnum)
            : composantAttendu.reponse,
        valeur: 0,
        nbReponsesRempli: 0,
        nbReponsesAttendu: 0,
        nbValeursRempli: valeurCompletion?.length ?? 0,
        nbValeursAttendu: (filteredSocieteCaracs?.length ?? 0) * (composantBySocieteComposant?.length ?? 0),
        idUser: idUser
      };
      return accComposantAttendus;
    }, {} as DynamicType<Partial<ComposantAttendu>>) || {};
  // Si jamais le patrimoine est lié au societe composant on met nbReponsesAttendu à 1
  // Si c'est un de ces enfants qui est lié au societe composant on laisse nbReponsesAttendu à 0
  for (let i = 0; i < patrimoine.societePatrimoineHierarchie?.societeComposantRattachements?.length; i++) {
    const societeComposantRattachement = patrimoine.societePatrimoineHierarchie?.societeComposantRattachements[i];
    if (!composantsAttendusDict[societeComposantRattachement.idSocieteComposant]) {
      composantsAttendusDict[societeComposantRattachement.idSocieteComposant] = {
        valeur: 0,
        calcule: true,
        nbReponsesRempli: 0,
        nbReponsesAttendu: 1
      };
    } else {
      const composantAttenduForSocieteComposant =
        composantsAttendusDict[societeComposantRattachement.idSocieteComposant];
      composantAttenduForSocieteComposant.nbReponsesAttendu = 1;
      // Si calcule est false ça veut dire que quelqu'un a répondu "non" ce qui compte comme une réponse
      if (!composantAttenduForSocieteComposant.calcule && composantAttenduForSocieteComposant.reponse !== null) {
        composantAttenduForSocieteComposant.nbReponsesRempli = 1;
      } else if (!composantAttenduForSocieteComposant.calcule && composantAttenduForSocieteComposant.reponse === null) {
        composantAttenduForSocieteComposant.calcule = true;
      }
    }
  }

  for (let i = 0; i < patrimoine.espaces?.length; i++) {
    const espace = patrimoine.espaces[i];
    for (let j = 0; j < espace.composants?.length; j++) {
      const composant = espace.composants[j];
      if (composantsAttendusDict[composant.idSocieteComposant]) {
        const nbToAdd = extractNumberFromComposant(composant);
        composantsAttendusDict[composant.idSocieteComposant].valeur =
          (composantsAttendusDict[composant.idSocieteComposant].valeur ?? 0) + nbToAdd;
        if (
          (composantsAttendusDict[composant.idSocieteComposant].valeur ?? 0) > 0 &&
          composantsAttendusDict[composant.idSocieteComposant].reponse !== ComposantAttenduReponseEnum.oui
        ) {
          composantsAttendusDict[composant.idSocieteComposant].reponse = ComposantAttenduReponseEnum.oui;
        }
        if (
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesRempli !== 1 &&
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesAttendu !== 0
        ) {
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesRempli = 1;
        }
        if (composantsAttendusDict[composant.idSocieteComposant].calcule !== false) {
          composantsAttendusDict[composant.idSocieteComposant].calcule = false;
        }
      }
    }
  }
  return composantsAttendusDict;
}

export function areComposantAttenduEqual(
  composantAttenduA: Partial<ComposantAttendu>,
  composantAttenduB: Partial<ComposantAttendu>
): boolean {
  return (
    composantAttenduA?.calcule === composantAttenduB?.calcule &&
    composantAttenduA?.reponse === composantAttenduB?.reponse &&
    composantAttenduA?.nbReponsesAttendu === composantAttenduB?.nbReponsesAttendu &&
    composantAttenduA?.nbReponsesRempli === composantAttenduB?.nbReponsesRempli &&
    composantAttenduA?.nbValeursAttendu === composantAttenduB?.nbValeursAttendu &&
    composantAttenduA?.nbValeursRempli === composantAttenduB?.nbValeursRempli &&
    composantAttenduA?.valeur === composantAttenduB?.valeur
  );
}
