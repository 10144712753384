import { RepositoryService } from '@get/services/repository';
import { Droit } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedDroitApiService {
  constructor(protected repo: RepositoryService) {}

  public getDroits(params?: any): Observable<Droit[]> {
    return this.repo.getData<Droit[]>('droit', params);
  }
}
