import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { NewsletterRelationsIds } from '@get/store/ids-interfaces';

export const NewsletterGeneratedActions = createActionGroup({
  source: 'Newsletter Generated',
  events: {
    'Get Many Newsletters': props<{ params: any }>(),
    'Upsert One Newsletter': props<{ newsletter: Partial<Newsletter>; ids?: NewsletterRelationsIds; }>(),
    'Upsert Many Newsletters': props<{ newsletters: Partial<Newsletter>[]; ids?: NewsletterRelationsIds; }>(),
    'Upsert Many Newsletters Success': props<{ newsletters: NewsletterEntityState[] }>(),
    'Delete One Newsletter': props<{ idNewsletter: number }>(),
    'Delete One Newsletter Success': props<{ idNewsletter: number }>(),
    'Normalize Many Newsletters After Upsert': props<{ newsletters: NewsletterEntityState[] }>(),
    'Newsletters Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Newsletters': emptyProps(),
    'Add Many User Newsletter Success': props<{ idNewsletter: number; idUserNewsletters: number[] }>(),
    'Delete Many User Newsletter Success': props<{ idUserNewsletters: number[]; idNewsletters: number[] }>(),
    'Add Many Organisation Newsletter Success': props<{ idNewsletter: number; idOrganisationNewsletters: number[] }>(),
    'Delete Many Organisation Newsletter Success': props<{ idOrganisationNewsletters: number[]; idNewsletters: number[] }>(),
    'Add Many User Success': props<{ idNewsletter: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idNewsletters: number[] }>(),
    'Add Many Organisation Success': props<{ idNewsletter: number; idOrganisations: number[] }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idNewsletters: number[] }>()
  }
});
