import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgComposantRelationsIds } from '@get/store/ids-interfaces';

export const ProgComposantGeneratedActions = createActionGroup({
  source: 'Prog Composant Generated',
  events: {
    'Upsert One Prog Composant': props<{ progComposant: Partial<ProgComposant>; ids?: ProgComposantRelationsIds; }>(),
    'Upsert Many Prog Composants': props<{ progComposants: Partial<ProgComposant>[]; ids?: ProgComposantRelationsIds; }>(),
    'Upsert Many Prog Composants Success': props<{ progComposants: ProgComposantEntityState[] }>(),
    'Delete One Prog Composant': props<{ idProgComposant: number }>(),
    'Delete One Prog Composant Success': props<{ idProgComposant: number }>(),
    'Normalize Many Prog Composants After Upsert': props<{ progComposants: ProgComposantEntityState[] }>(),
    'Prog Composants Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Composants': emptyProps(),
    'Add Many Prog Biblio Intervention Success': props<{ idProgComposant: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgComposants: number[] }>(),
    'Add Many Prog Patrimoine Info Success': props<{ idProgComposant: number; idProgPatrimoineInfos: number[] }>(),
    'Delete Many Prog Patrimoine Info Success': props<{ idProgPatrimoineInfos: number[]; idProgComposants: number[] }>(),
    'Add Many Patrimoine Success': props<{ idProgComposant: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idProgComposants: number[] }>(),
    'Add Societe Success': props<{ idProgComposant: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgComposants: number[] }>(),
    'Add Prog Composant Sous Groupe Success': props<{ idProgComposant: number; idProgComposantSousGroupe: number }>(),
    'Delete Many Prog Composant Sous Groupe Success': props<{ idProgComposantSousGroupes: number[]; idProgComposants: number[] }>(),
    'Add Societe Composant Success': props<{ idProgComposant: number; idSocieteComposant: number }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idProgComposants: number[] }>()
  }
});
