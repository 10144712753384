import { RepositoryService } from '@get/services/repository';
import { SocieteComposant } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteComposantApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteComposants(params?: any): Observable<SocieteComposant[]> {
    return this.repo.getData<SocieteComposant[]>('societe-composant', params);
  }

  public addSocieteComposant(societeComposant: Partial<SocieteComposant>): Observable<SocieteComposant> {
    return this.repo.create<SocieteComposant>('societe-composant', societeComposant);
  }

  public updateSocieteComposant(societeComposant: Partial<SocieteComposant>): Observable<SocieteComposant> {
    return this.repo.update('societe-composant', societeComposant);
  }

  public deleteSocieteComposant(idSocieteComposant: number): Observable<number> {
    return this.repo.delete('societe-composant/' + +idSocieteComposant);
  }
}
