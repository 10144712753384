import { DynamicType, SocieteTerritoire } from '@get/api-interfaces';

function isSocieteTerritoireChildOfId(
  elem: SocieteTerritoire,
  idSocieteTerritoire: number,
  territoiresObj: DynamicType<SocieteTerritoire>
): boolean {
  return (
    elem?.idTerritoireParent === idSocieteTerritoire ||
    (elem?.territoireParent &&
      isSocieteTerritoireChildOfId(
        territoiresObj?.[elem.territoireParent.idSocieteTerritoire],
        idSocieteTerritoire,
        territoiresObj
      ))
  );
}

export function filterSocieteTerritoiresWithoutItselfAndChilds(
  societeTerritoires: SocieteTerritoire[],
  idSocieteTerritoire: number,
  societeTerritoiresObj: DynamicType<SocieteTerritoire>,
  keepToutPatrimoine = false
): SocieteTerritoire[] {
  return (societeTerritoires || []).filter(
    el =>
      el?.libelle &&
      ((!keepToutPatrimoine && !el.toutPatrimoine) || keepToutPatrimoine) &&
      el.idSocieteTerritoire !== idSocieteTerritoire &&
      !isSocieteTerritoireChildOfId(el, idSocieteTerritoire, societeTerritoiresObj)
  );
}
