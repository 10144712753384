import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { ValeurPatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const ValeurPatrimoineGeneratedActions = createActionGroup({
  source: 'Valeur Patrimoine Generated',
  events: {
    'Upsert One Valeur Patrimoine': props<{ valeurPatrimoine: Partial<ValeurPatrimoine>; ids?: ValeurPatrimoineRelationsIds; }>(),
    'Upsert Many Valeur Patrimoines': props<{ valeurPatrimoines: Partial<ValeurPatrimoine>[]; ids?: ValeurPatrimoineRelationsIds; }>(),
    'Upsert Many Valeur Patrimoines Success': props<{ valeurPatrimoines: ValeurPatrimoineEntityState[] }>(),
    'Delete One Valeur Patrimoine Success': props<{ idValeurPatrimoine: number }>(),
    'Normalize Many Valeur Patrimoines After Upsert': props<{ valeurPatrimoines: ValeurPatrimoineEntityState[] }>(),
    'Valeur Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeur Patrimoines': emptyProps(),
    'Add Valeur Success': props<{ idValeurPatrimoine: number; idValeur: number }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idValeurPatrimoines: number[] }>(),
    'Add Patrimoine Success': props<{ idValeurPatrimoine: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idValeurPatrimoines: number[] }>()
  }
});
