import { Injectable } from '@angular/core';
import { GridError, ProgComposantSousGroupeDataRow } from '@get/api-interfaces';
import { GridErrorProgComposantSousGroupeActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorProgComposantSousGroupeSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorProgComposantSousGroupeService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorProgComposantSousGroupe: Partial<GridError>): number | undefined {
    if (!gridErrorProgComposantSousGroupe.error.idProgComposantSousGroupe) {
      gridErrorProgComposantSousGroupe.error.idProgComposantSousGroupe = --this.tempId;
    }
    gridErrorProgComposantSousGroupe.idGridError = gridErrorProgComposantSousGroupe.error.idProgComposantSousGroupe;
    this.store$.dispatch(
      GridErrorProgComposantSousGroupeActions.addOneGridErrorProgComposantSousGroupe({
        gridErrorProgComposantSousGroupe: gridErrorProgComposantSousGroupe as GridError
      })
    );
    return gridErrorProgComposantSousGroupe.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(
      GridErrorProgComposantSousGroupeActions.deleteOneGridErrorProgComposantSousGroupeSuccess({ idGridError })
    );
  }

  public selectProgComposantSousGroupesAndGridErrors(
    schema: SelectSchema = {}
  ): Observable<ProgComposantSousGroupeDataRow[]> {
    return this.store$
      .pipe(select(GridErrorProgComposantSousGroupeSelectors.selectProgComposantSousGroupeAndGridErrors(schema)))
      .pipe(map(progComposantSousGroupeDataRow => progComposantSousGroupeDataRow));
  }
}
