import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgBudgetApiService } from '@get/store/api-services';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgBudget } from '@get/store/configs/normalization';
import { ProgBudgetSelectors } from '@get/store/selectors';
import { ProgBudgetRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioIntervention } from '@get/api-interfaces';

export function getDefaultAddProgBudgetActions(progBudget: ProgBudgetEntityState, ids?: ProgBudgetRelationsIds): Action[] {
  const actions: Action[] = [ProgBudgetGeneratedActions.normalizeManyProgBudgetsAfterUpsert({ progBudgets: [progBudget] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgBudgetSuccess({
        idSociete: ids.societe,
        idProgBudgets: [progBudget.idProgBudget]
      })
    );
    actions.push(
      ProgBudgetGeneratedActions.addSocieteSuccess({
        idProgBudget: progBudget.idProgBudget,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progBiblioInterventions) {
    if (!Array.isArray(ids.progBiblioInterventions)) {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention({
          progBiblioIntervention: {
            idProgBudget: progBudget.idProgBudget,
            idProgBiblioIntervention: ids.progBiblioInterventions as number
          } as ProgBiblioIntervention
        })
      );
      actions.push(
        ProgBudgetGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgBudget: progBudget.idProgBudget,
          idProgBiblioInterventions: [ids.progBiblioInterventions as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventions({
          progBiblioInterventions: (ids.progBiblioInterventions as number[]).map(
            (idProgBiblioIntervention: number) => ({
              idProgBudget: progBudget.idProgBudget,
              idProgBiblioIntervention
            })
          ) as ProgBiblioIntervention[]
        })
      );
      actions.push(
        ProgBudgetGeneratedActions.addManyProgBiblioInterventionSuccess({
          idProgBudget: progBudget.idProgBudget,
          idProgBiblioInterventions: ids.progBiblioInterventions as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgBudgetActions(progBudget: ProgBudgetEntityState): Action[] {
  const actions: Action[] = [ProgBudgetGeneratedActions.deleteOneProgBudgetSuccess({ idProgBudget: progBudget.idProgBudget })];

  if (progBudget.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgBudgetSuccess({
        idProgBudgets: [progBudget.idProgBudget],
        idSocietes: [progBudget.societe as number]
      })
    );
  }

  if (progBudget.progBiblioInterventions) {
    actions.push(
      ProgBiblioInterventionGeneratedActions.deleteManyProgBudgetSuccess({
        idProgBudgets: [progBudget.idProgBudget],
        idProgBiblioInterventions: progBudget.progBiblioInterventions as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgBudgetEffects {
  constructor(
    protected actions$: Actions,
    protected progBudgetApiService: ProgBudgetApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgBudget$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgBudgetGeneratedActions.upsertOneProgBudget),
      concatMap(
        ({
          progBudget,
          ids
        }: {
          progBudget: Partial<ProgBudget>;
          ids?: ProgBudgetRelationsIds;
        }) => {
          if (progBudget.idProgBudget) {
            return this.progBudgetApiService.updateProgBudget(progBudget).pipe(
              map((progBudgetReturned: ProgBudget) => {
                return ProgBudgetGeneratedActions.normalizeManyProgBudgetsAfterUpsert({ progBudgets: [progBudgetReturned] });
              }),
              catchError(error => of(ProgBudgetGeneratedActions.progBudgetsFailure({ error })))
            );
          } else {
            return this.progBudgetApiService.addProgBudget(progBudget).pipe(
              mergeMap((progBudgetReturned: ProgBudget) => getDefaultAddProgBudgetActions(progBudgetReturned, ids)),
              catchError(error => of(ProgBudgetGeneratedActions.progBudgetsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgBudget$ = createEffect(() => {
    const selectProgBudgetState$ = this.store$.select(ProgBudgetSelectors.selectProgBudgetState);
    return this.actions$.pipe(
      ofType(ProgBudgetGeneratedActions.deleteOneProgBudget),
      withLatestFrom(selectProgBudgetState$),
      concatMap(([{ idProgBudget }, state]) =>
        this.progBudgetApiService.deleteProgBudget(idProgBudget).pipe(
          mergeMap(_success => getDefaultDeleteProgBudgetActions(state.entities[idProgBudget] as ProgBudgetEntityState)),
          catchError(error => of(ProgBudgetGeneratedActions.progBudgetsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgBudgetsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgBudgetGeneratedActions.normalizeManyProgBudgetsAfterUpsert),
      concatMap(({ progBudgets }) => {
        const actions: Action[] = getActionsToNormalizeProgBudget(progBudgets, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgBudget] Normalization After Upsert Success')];
      })
    );
  });
}
