import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupeRelationsIds } from '@get/store/ids-interfaces';

export const ProgComposantSousGroupeGeneratedActions = createActionGroup({
  source: 'Prog Composant Sous Groupe Generated',
  events: {
    'Upsert One Prog Composant Sous Groupe': props<{ progComposantSousGroupe: Partial<ProgComposantSousGroupe>; ids?: ProgComposantSousGroupeRelationsIds; }>(),
    'Upsert Many Prog Composant Sous Groupes': props<{ progComposantSousGroupes: Partial<ProgComposantSousGroupe>[]; ids?: ProgComposantSousGroupeRelationsIds; }>(),
    'Upsert Many Prog Composant Sous Groupes Success': props<{ progComposantSousGroupes: ProgComposantSousGroupeEntityState[] }>(),
    'Delete One Prog Composant Sous Groupe': props<{ idProgComposantSousGroupe: number }>(),
    'Delete One Prog Composant Sous Groupe Success': props<{ idProgComposantSousGroupe: number }>(),
    'Normalize Many Prog Composant Sous Groupes After Upsert': props<{ progComposantSousGroupes: ProgComposantSousGroupeEntityState[] }>(),
    'Prog Composant Sous Groupes Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Composant Sous Groupes': emptyProps(),
    'Add Many Prog Composant Success': props<{ idProgComposantSousGroupe: number; idProgComposants: number[] }>(),
    'Delete Many Prog Composant Success': props<{ idProgComposants: number[]; idProgComposantSousGroupes: number[] }>(),
    'Add Societe Success': props<{ idProgComposantSousGroupe: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgComposantSousGroupes: number[] }>(),
    'Add Prog Composant Groupe Success': props<{ idProgComposantSousGroupe: number; idProgComposantGroupe: number }>(),
    'Delete Many Prog Composant Groupe Success': props<{ idProgComposantGroupes: number[]; idProgComposantSousGroupes: number[] }>()
  }
});
