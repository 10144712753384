import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FichierApiService } from '@get/store/api-services';
import { GeneratedFichierEffects } from './fichier-generated.effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { FichierGeneratedActions } from '@get/store/actions';
import { Fichier } from '@get/api-interfaces';
import { FichierRelationsIds } from '@get/store/ids-interfaces';

@Injectable()
export class FichierEffects extends GeneratedFichierEffects {
  constructor(actions$: Actions, fichierApiService: FichierApiService, store$: Store<AppState>) {
    super(actions$, fichierApiService, store$);
  }

  upsertManyFichiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.upsertManyFichiers),
      concatMap(({ fichiers, ids }: { fichiers: Partial<Fichier>[]; ids?: FichierRelationsIds }) => {
        return this.fichierApiService.upsertManyFichiers(fichiers).pipe(
          map((fichiersReturned: Fichier[]) => {
            return FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers: fichiersReturned });
          }),
          catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
        );
      })
    );
  });
}
