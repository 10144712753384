import { createEntityAdapter } from '@ngrx/entity';
import { OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<OrganisationAzureLoginEntityState>;

export const adapter = createEntityAdapter<OrganisationAzureLoginEntityState>({
  selectId: o => o.idOrganisationAzureLogin
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const organisationAzureLoginFeatureKey = 'organisationAzureLogin';
