import { createEntityAdapter } from '@ngrx/entity';
import { ValeurEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ValeurEntityState>;

export const adapter = createEntityAdapter<ValeurEntityState>({
  selectId: o => o.idValeur
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const valeurFeatureKey = 'valeur';
