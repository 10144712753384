import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UsageComposantTemplate, UsageComposantTemplateEntityState } from '@get/api-interfaces';
import { UsageComposantTemplateRelationsIds } from '@get/store/ids-interfaces';

export const UsageComposantTemplateGeneratedActions = createActionGroup({
  source: 'Usage Composant Template Generated',
  events: {
    'Upsert One Usage Composant Template': props<{ usageComposantTemplate: Partial<UsageComposantTemplate>; ids?: UsageComposantTemplateRelationsIds; }>(),
    'Upsert Many Usage Composant Templates': props<{ usageComposantTemplates: Partial<UsageComposantTemplate>[]; ids?: UsageComposantTemplateRelationsIds; }>(),
    'Upsert Many Usage Composant Templates Success': props<{ usageComposantTemplates: UsageComposantTemplateEntityState[] }>(),
    'Delete One Usage Composant Template Success': props<{ idUsageComposantTemplate: number }>(),
    'Normalize Many Usage Composant Templates After Upsert': props<{ usageComposantTemplates: UsageComposantTemplateEntityState[] }>(),
    'Usage Composant Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Usage Composant Templates': emptyProps(),
    'Add Usage Success': props<{ idUsageComposantTemplate: number; idUsage: number }>(),
    'Delete Many Usage Success': props<{ idUsages: number[]; idUsageComposantTemplates: number[] }>(),
    'Add Composant Template Success': props<{ idUsageComposantTemplate: number; idComposantTemplate: number }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idUsageComposantTemplates: number[] }>()
  }
});
