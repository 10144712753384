import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttenduState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ComposantAttenduEntityState } from '@get/api-interfaces';

export const composantAttenduReducersGeneratedFunctions: ReducerTypes<ComposantAttenduState.IState, readonly ActionCreator[]>[] = [
  on(ComposantAttenduGeneratedActions.upsertOneComposantAttendu, (state: ComposantAttenduState.IState) => setLoadingsState(state, true)),

  on(ComposantAttenduGeneratedActions.upsertManyComposantAttendusSuccess, (state: ComposantAttenduState.IState, { composantAttendus }) =>
    ComposantAttenduState.adapter.upsertMany(composantAttendus, setLoadingsState(state, false))
  ),
  on(ComposantAttenduGeneratedActions.deleteOneComposantAttenduSuccess, (state: ComposantAttenduState.IState, { idComposantAttendu }) =>
    ComposantAttenduState.adapter.removeOne(idComposantAttendu, setLoadingsState(state, false))
  ),

  on(ComposantAttenduGeneratedActions.clearComposantAttendus, () => ComposantAttenduState.initialState),

  on(
    ComposantAttenduGeneratedActions.addSocieteComposantSuccess,
    (state: ComposantAttenduState.IState, { idComposantAttendu, idSocieteComposant }) => {
      if (!state.entities[idComposantAttendu]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantAttendu]: {
            ...state.entities[idComposantAttendu],
            societeComposant: idSocieteComposant
          }
        }
      };
    }
  ),

  on(
    ComposantAttenduGeneratedActions.deleteManySocieteComposantSuccess,
    (state: ComposantAttenduState.IState, { idSocieteComposants, idComposantAttendus }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantAttendus.reduce((entities, idComposantAttendu) => {
            if (!state.entities[idComposantAttendu]?.societeComposant) {
              return entities;
            }
            entities[idComposantAttendu] = {
              ...state.entities[idComposantAttendu],
              societeComposant: idSocieteComposants.some(
                (idSocieteComposant: number) => idSocieteComposant === state.entities[idComposantAttendu]?.societeComposant
              )
                ? undefined
                : state.entities[idComposantAttendu]?.societeComposant
            } as ComposantAttenduEntityState;
            return entities;
          }, {} as Dictionary<ComposantAttenduEntityState>)
        }
      };
    }
  ),

  on(
    ComposantAttenduGeneratedActions.addPatrimoineSuccess,
    (state: ComposantAttenduState.IState, { idComposantAttendu, idPatrimoine }) => {
      if (!state.entities[idComposantAttendu]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantAttendu]: {
            ...state.entities[idComposantAttendu],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    ComposantAttenduGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ComposantAttenduState.IState, { idPatrimoines, idComposantAttendus }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantAttendus.reduce((entities, idComposantAttendu) => {
            if (!state.entities[idComposantAttendu]?.patrimoine) {
              return entities;
            }
            entities[idComposantAttendu] = {
              ...state.entities[idComposantAttendu],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idComposantAttendu]?.patrimoine
              )
                ? undefined
                : state.entities[idComposantAttendu]?.patrimoine
            } as ComposantAttenduEntityState;
            return entities;
          }, {} as Dictionary<ComposantAttenduEntityState>)
        }
      };
    }
  ),

  on(
    ComposantAttenduGeneratedActions.addUserSuccess,
    (state: ComposantAttenduState.IState, { idComposantAttendu, idUser }) => {
      if (!state.entities[idComposantAttendu]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantAttendu]: {
            ...state.entities[idComposantAttendu],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    ComposantAttenduGeneratedActions.deleteManyUserSuccess,
    (state: ComposantAttenduState.IState, { idUsers, idComposantAttendus }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantAttendus.reduce((entities, idComposantAttendu) => {
            if (!state.entities[idComposantAttendu]?.user) {
              return entities;
            }
            entities[idComposantAttendu] = {
              ...state.entities[idComposantAttendu],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idComposantAttendu]?.user
              )
                ? undefined
                : state.entities[idComposantAttendu]?.user
            } as ComposantAttenduEntityState;
            return entities;
          }, {} as Dictionary<ComposantAttenduEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ComposantAttenduState.IState,
  isLoading: boolean,
  isLoaded = true
): ComposantAttenduState.IState {
  return { ...state, isLoaded, isLoading };
}
