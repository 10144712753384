import { createEntityAdapter } from '@ngrx/entity';
import { ComposantTemplateEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ComposantTemplateEntityState>;

export const adapter = createEntityAdapter<ComposantTemplateEntityState>({
  selectId: o => o.idComposantTemplate
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const composantTemplateFeatureKey = 'composantTemplate';
