import { Injectable } from '@angular/core';
import { GridError, ProgComposantDataRow } from '@get/api-interfaces';
import { GridErrorProgComposantActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorProgComposantSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorProgComposantService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorProgComposant: Partial<GridError>): number | undefined {
    if (!gridErrorProgComposant.error.idProgComposant) {
      gridErrorProgComposant.error.idProgComposant = --this.tempId;
    }
    gridErrorProgComposant.idGridError = gridErrorProgComposant.error.idProgComposant;
    this.store$.dispatch(
      GridErrorProgComposantActions.addOneGridErrorProgComposant({
        gridErrorProgComposant: gridErrorProgComposant as GridError
      })
    );
    return gridErrorProgComposant.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(GridErrorProgComposantActions.deleteOneGridErrorProgComposantSuccess({ idGridError }));
  }

  public selectProgComposantsAndGridErrors(schema: SelectSchema = {}): Observable<ProgComposantDataRow[]> {
    return this.store$
      .pipe(select(GridErrorProgComposantSelectors.selectProgComposantAndGridErrors(schema)))
      .pipe(map(progComposantDataRow => progComposantDataRow));
  }
}
