import { RepositoryService } from '@get/services/repository';
import { ComposantGroupe } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedComposantGroupeApiService {
  constructor(protected repo: RepositoryService) {}

  public getComposantGroupes(params?: any): Observable<ComposantGroupe[]> {
    return this.repo.getData<ComposantGroupe[]>('composant-groupe', params);
  }

  public addComposantGroupe(composantGroupe: Partial<ComposantGroupe>): Observable<ComposantGroupe> {
    return this.repo.create<ComposantGroupe>('composant-groupe', composantGroupe);
  }

  public updateComposantGroupe(composantGroupe: Partial<ComposantGroupe>): Observable<ComposantGroupe> {
    return this.repo.update('composant-groupe', composantGroupe);
  }

  public deleteComposantGroupe(idComposantGroupe: number): Observable<number> {
    return this.repo.delete('composant-groupe/' + +idComposantGroupe);
  }
}
