import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroitState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteProfilDroitEntityState } from '@get/api-interfaces';

export const societeProfilDroitReducersGeneratedFunctions: ReducerTypes<SocieteProfilDroitState.IState, readonly ActionCreator[]>[] = [
  on(SocieteProfilDroitGeneratedActions.upsertOneSocieteProfilDroit, (state: SocieteProfilDroitState.IState) => setLoadingsState(state, true)),

  on(SocieteProfilDroitGeneratedActions.upsertManySocieteProfilDroitsSuccess, (state: SocieteProfilDroitState.IState, { societeProfilDroits }) =>
    SocieteProfilDroitState.adapter.upsertMany(societeProfilDroits, setLoadingsState(state, false))
  ),
  on(SocieteProfilDroitGeneratedActions.deleteOneSocieteProfilDroitSuccess, (state: SocieteProfilDroitState.IState, { idSocieteProfilDroit }) =>
    SocieteProfilDroitState.adapter.removeOne(idSocieteProfilDroit, setLoadingsState(state, false))
  ),

  on(SocieteProfilDroitGeneratedActions.clearSocieteProfilDroits, () => SocieteProfilDroitState.initialState),

  on(
    SocieteProfilDroitGeneratedActions.addSocieteProfilSuccess,
    (state: SocieteProfilDroitState.IState, { idSocieteProfilDroit, idSocieteProfil }) => {
      if (!state.entities[idSocieteProfilDroit]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfilDroit]: {
            ...state.entities[idSocieteProfilDroit],
            societeProfil: idSocieteProfil
          }
        }
      };
    }
  ),

  on(
    SocieteProfilDroitGeneratedActions.deleteManySocieteProfilSuccess,
    (state: SocieteProfilDroitState.IState, { idSocieteProfils, idSocieteProfilDroits }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfilDroits.reduce((entities, idSocieteProfilDroit) => {
            if (!state.entities[idSocieteProfilDroit]?.societeProfil) {
              return entities;
            }
            entities[idSocieteProfilDroit] = {
              ...state.entities[idSocieteProfilDroit],
              societeProfil: idSocieteProfils.some(
                (idSocieteProfil: number) => idSocieteProfil === state.entities[idSocieteProfilDroit]?.societeProfil
              )
                ? undefined
                : state.entities[idSocieteProfilDroit]?.societeProfil
            } as SocieteProfilDroitEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilDroitEntityState>)
        }
      };
    }
  ),

  on(
    SocieteProfilDroitGeneratedActions.addDroitSuccess,
    (state: SocieteProfilDroitState.IState, { idSocieteProfilDroit, idDroit }) => {
      if (!state.entities[idSocieteProfilDroit]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfilDroit]: {
            ...state.entities[idSocieteProfilDroit],
            droit: idDroit
          }
        }
      };
    }
  ),

  on(
    SocieteProfilDroitGeneratedActions.deleteManyDroitSuccess,
    (state: SocieteProfilDroitState.IState, { idDroits, idSocieteProfilDroits }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfilDroits.reduce((entities, idSocieteProfilDroit) => {
            if (!state.entities[idSocieteProfilDroit]?.droit) {
              return entities;
            }
            entities[idSocieteProfilDroit] = {
              ...state.entities[idSocieteProfilDroit],
              droit: idDroits.some(
                (idDroit: number) => idDroit === state.entities[idSocieteProfilDroit]?.droit
              )
                ? undefined
                : state.entities[idSocieteProfilDroit]?.droit
            } as SocieteProfilDroitEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilDroitEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteProfilDroitState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteProfilDroitState.IState {
  return { ...state, isLoaded, isLoading };
}
