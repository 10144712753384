import { createEntityAdapter } from '@ngrx/entity';
import { SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteCaracteristiqueChoixEntityState>;

export const adapter = createEntityAdapter<SocieteCaracteristiqueChoixEntityState>({
  selectId: o => o.idSocieteCaracteristiqueChoix
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const societeCaracteristiqueChoixFeatureKey = 'societeCaracteristiqueChoix';
