import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedBoardStateApiService } from './board-state-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class BoardStateApiService extends GeneratedBoardStateApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
