import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GetCoreInterceptorsModule } from '@get/core/interceptors';
import { environment } from '@get/environment';
import { AuthService } from '@get/services/auth';
import { DbService } from '@get/services/storage';
import { GetStoreConfigsModuleModule } from '@get/store/configs/module';
import { EffectsModule } from '@ngrx/effects';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    GetCoreInterceptorsModule,
    BrowserModule,
    GetStoreConfigsModuleModule,
    BrowserAnimationsModule,
    MatDialogModule,
    EffectsModule,
    AppRoutingModule,
    // Pour les modals/bottomSheet
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        logErrors: true
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    DbService,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
})
export class AppModule {
  constructor(private authService: AuthService) {
    this.authService.getLoginStorage();
  }
}
