import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';

export const societeCaracteristiqueChoixReducersGeneratedFunctions: ReducerTypes<SocieteCaracteristiqueChoixState.IState, readonly ActionCreator[]>[] = [
  on(SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix, (state: SocieteCaracteristiqueChoixState.IState) => setLoadingsState(state, true)),

  on(SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoicesSuccess, (state: SocieteCaracteristiqueChoixState.IState, { societeCaracteristiqueChoices }) =>
    SocieteCaracteristiqueChoixState.adapter.upsertMany(societeCaracteristiqueChoices, setLoadingsState(state, false))
  ),
  on(SocieteCaracteristiqueChoixGeneratedActions.deleteOneSocieteCaracteristiqueChoixSuccess, (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiqueChoix }) =>
    SocieteCaracteristiqueChoixState.adapter.removeOne(idSocieteCaracteristiqueChoix, setLoadingsState(state, false))
  ),

  on(SocieteCaracteristiqueChoixGeneratedActions.clearSocieteCaracteristiqueChoices, () => SocieteCaracteristiqueChoixState.initialState),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.addManyValeurSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiqueChoix, idValeurs }) => {
      if (!state.entities[idSocieteCaracteristiqueChoix]) {
        return state;
      }
      const valeurs = (state.entities[idSocieteCaracteristiqueChoix]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristiqueChoix]: {
            ...state.entities[idSocieteCaracteristiqueChoix],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.deleteManyValeurSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idValeurs, idSocieteCaracteristiqueChoices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiqueChoices.reduce((entities, idSocieteCaracteristiqueChoix) => {
            if (!state.entities[idSocieteCaracteristiqueChoix]?.valeurs) {
              return entities;
            }
            entities[idSocieteCaracteristiqueChoix] = {
              ...state.entities[idSocieteCaracteristiqueChoix],
              valeurs: (state.entities[idSocieteCaracteristiqueChoix]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as SocieteCaracteristiqueChoixEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueChoixEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.addSocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiqueChoix, idSocieteCaracteristique }) => {
      if (!state.entities[idSocieteCaracteristiqueChoix]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristiqueChoix]: {
            ...state.entities[idSocieteCaracteristiqueChoix],
            societeCaracteristique: idSocieteCaracteristique
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiques, idSocieteCaracteristiqueChoices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiqueChoices.reduce((entities, idSocieteCaracteristiqueChoix) => {
            if (!state.entities[idSocieteCaracteristiqueChoix]?.societeCaracteristique) {
              return entities;
            }
            entities[idSocieteCaracteristiqueChoix] = {
              ...state.entities[idSocieteCaracteristiqueChoix],
              societeCaracteristique: idSocieteCaracteristiques.some(
                (idSocieteCaracteristique: number) => idSocieteCaracteristique === state.entities[idSocieteCaracteristiqueChoix]?.societeCaracteristique
              )
                ? undefined
                : state.entities[idSocieteCaracteristiqueChoix]?.societeCaracteristique
            } as SocieteCaracteristiqueChoixEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueChoixEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.addFichierSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiqueChoix, idFichier }) => {
      if (!state.entities[idSocieteCaracteristiqueChoix]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristiqueChoix]: {
            ...state.entities[idSocieteCaracteristiqueChoix],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.deleteManyFichierSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idFichiers, idSocieteCaracteristiqueChoices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiqueChoices.reduce((entities, idSocieteCaracteristiqueChoix) => {
            if (!state.entities[idSocieteCaracteristiqueChoix]?.fichier) {
              return entities;
            }
            entities[idSocieteCaracteristiqueChoix] = {
              ...state.entities[idSocieteCaracteristiqueChoix],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idSocieteCaracteristiqueChoix]?.fichier
              )
                ? undefined
                : state.entities[idSocieteCaracteristiqueChoix]?.fichier
            } as SocieteCaracteristiqueChoixEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueChoixEntityState>)
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.addCaracteristiqueChoixTemplateSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idSocieteCaracteristiqueChoix, idCaracteristiqueChoixTemplate }) => {
      if (!state.entities[idSocieteCaracteristiqueChoix]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteCaracteristiqueChoix]: {
            ...state.entities[idSocieteCaracteristiqueChoix],
            caracteristiqueChoixTemplate: idCaracteristiqueChoixTemplate
          }
        }
      };
    }
  ),

  on(
    SocieteCaracteristiqueChoixGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess,
    (state: SocieteCaracteristiqueChoixState.IState, { idCaracteristiqueChoixTemplates, idSocieteCaracteristiqueChoices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteCaracteristiqueChoices.reduce((entities, idSocieteCaracteristiqueChoix) => {
            if (!state.entities[idSocieteCaracteristiqueChoix]?.caracteristiqueChoixTemplate) {
              return entities;
            }
            entities[idSocieteCaracteristiqueChoix] = {
              ...state.entities[idSocieteCaracteristiqueChoix],
              caracteristiqueChoixTemplate: idCaracteristiqueChoixTemplates.some(
                (idCaracteristiqueChoixTemplate: number) => idCaracteristiqueChoixTemplate === state.entities[idSocieteCaracteristiqueChoix]?.caracteristiqueChoixTemplate
              )
                ? undefined
                : state.entities[idSocieteCaracteristiqueChoix]?.caracteristiqueChoixTemplate
            } as SocieteCaracteristiqueChoixEntityState;
            return entities;
          }, {} as Dictionary<SocieteCaracteristiqueChoixEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteCaracteristiqueChoixState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteCaracteristiqueChoixState.IState {
  return { ...state, isLoaded, isLoading };
}
