import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurComposantApiService } from '@get/store/api-services';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurComposant } from '@get/store/configs/normalization';

export class GeneratedValeurComposantEffects {
  constructor(
    protected actions$: Actions,
    protected valeurComposantApiService: ValeurComposantApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeurComposantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert),
      concatMap(({ valeurComposants }) => {
        const actions: Action[] = getActionsToNormalizeValeurComposant(valeurComposants, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurComposant] Normalization After Upsert Success')];
      })
    );
  });
}
