import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CaracteristiqueChoixTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const caracteristiqueChoixTemplateRelations: string[] = ['societeCaracteristiqueChoices','caracteristiqueTemplates','fichiers',];

export const { selectEntities, selectAll } = CaracteristiqueChoixTemplateState.adapter.getSelectors();

export const selectCaracteristiqueChoixTemplateState = createFeatureSelector<CaracteristiqueChoixTemplateState.IState>(CaracteristiqueChoixTemplateState.caracteristiqueChoixTemplateFeatureKey);

export const selectIsLoadedCaracteristiqueChoixTemplate = createSelector(
  selectCaracteristiqueChoixTemplateState,
  (state: CaracteristiqueChoixTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingCaracteristiqueChoixTemplate = createSelector(
  selectCaracteristiqueChoixTemplateState,
  (state: CaracteristiqueChoixTemplateState.IState) => state.isLoading
);

export const selectIsReadyCaracteristiqueChoixTemplate = createSelector(
  selectCaracteristiqueChoixTemplateState,
  (state: CaracteristiqueChoixTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCaracteristiqueChoixTemplate = createSelector(
  selectCaracteristiqueChoixTemplateState,
  (state: CaracteristiqueChoixTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectCaracteristiqueChoixTemplatesEntities = createSelector(selectCaracteristiqueChoixTemplateState, selectEntities);

export const selectCaracteristiqueChoixTemplatesArray = createSelector(selectCaracteristiqueChoixTemplateState, selectAll);

const caracteristiqueChoixTemplatesInObject = (caracteristiqueChoixTemplates: Dictionary<CaracteristiqueChoixTemplateEntityState>) => ({ caracteristiqueChoixTemplates })

const selectCaracteristiqueChoixTemplatesEntitiesDictionary = createSelector(selectCaracteristiqueChoixTemplatesEntities, caracteristiqueChoixTemplatesInObject);

const selectAllCaracteristiqueChoixTemplatesObject = createSelector(selectCaracteristiqueChoixTemplatesEntities, caracteristiqueChoixTemplates => {
  return hydrateAll({ caracteristiqueChoixTemplates });
});

const selectOneCaracteristiqueChoixTemplateDictionary = (idCaracteristiqueChoixTemplate : number) =>
  createSelector(selectCaracteristiqueChoixTemplatesEntities, caracteristiqueChoixTemplates => ({
    caracteristiqueChoixTemplates: { [idCaracteristiqueChoixTemplate]: caracteristiqueChoixTemplates[idCaracteristiqueChoixTemplate] }
  }));

const selectOneCaracteristiqueChoixTemplateDictionaryWithoutChild = (idCaracteristiqueChoixTemplate : number) =>
  createSelector(selectCaracteristiqueChoixTemplatesEntities, caracteristiqueChoixTemplates => ({
    caracteristiqueChoixTemplate: caracteristiqueChoixTemplates[idCaracteristiqueChoixTemplate]
  }));

const selectAllCaracteristiqueChoixTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllCaracteristiqueChoixTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CaracteristiqueChoixTemplate>(
      schema,
      selectAllCaracteristiqueChoixTemplatesSelectors,
      selectCaracteristiqueChoixTemplatesEntitiesDictionary,
      getRelationSelectors,
      caracteristiqueChoixTemplateRelations,
      hydrateAll,
      'caracteristiqueChoixTemplate'
    );
  } else {
    return selectAllCaracteristiqueChoixTemplatesObject;
  }
}

export function selectAllCaracteristiqueChoixTemplatesDictionary(
  schema: SelectSchema = {},
  customKey = 'caracteristiqueChoixTemplates'
): Selector {
  return createSelector(selectAllCaracteristiqueChoixTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<CaracteristiqueChoixTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.caracteristiqueChoixTemplates.length; i++) {
      res[customKey][result.caracteristiqueChoixTemplates[i].idCaracteristiqueChoixTemplate] = result.caracteristiqueChoixTemplates[i];
    }
    return res;
  });
}

export function selectOneCaracteristiqueChoixTemplate(
  schema: SelectSchema = {},
  idCaracteristiqueChoixTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCaracteristiqueChoixTemplateDictionary(idCaracteristiqueChoixTemplate)];
  selectors.push(...getRelationSelectors(schema, caracteristiqueChoixTemplateRelations, 'caracteristiqueChoixTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCaracteristiqueChoixTemplateDictionaryWithoutChild(idCaracteristiqueChoixTemplate);
  }
}

interface hydrateArgs {
  caracteristiqueChoixTemplates: Dictionary<CaracteristiqueChoixTemplateEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  societeCaracteristiqueChoices?: Dictionary<SocieteCaracteristiqueChoixEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { caracteristiqueChoixTemplates: (CaracteristiqueChoixTemplate | null)[] } {
  const {
    caracteristiqueChoixTemplates,
    caracteristiqueTemplates,
    fichiers,
    societeCaracteristiqueChoices
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    caracteristiqueChoixTemplates: Object.keys(caracteristiqueChoixTemplates).map(idCaracteristiqueChoixTemplate =>
      hydrate(
        caracteristiqueChoixTemplates[idCaracteristiqueChoixTemplate] as CaracteristiqueChoixTemplateEntityState,
        caracteristiqueTemplates,
        fichiers,
        societeCaracteristiqueChoices
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState | null } {
  const {
    caracteristiqueChoixTemplates,
    caracteristiqueTemplates,
    fichiers,
    societeCaracteristiqueChoices
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const caracteristiqueChoixTemplate = Object.values(caracteristiqueChoixTemplates)[0];
  return {
    caracteristiqueChoixTemplate: hydrate(
      caracteristiqueChoixTemplate as CaracteristiqueChoixTemplateEntityState,
      caracteristiqueTemplates,
      fichiers,
      societeCaracteristiqueChoices
    )
  };
}

function hydrate(
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  societeCaracteristiqueChoixEntities?: Dictionary<SocieteCaracteristiqueChoixEntityState>,
): CaracteristiqueChoixTemplate | null {
  if (!caracteristiqueChoixTemplate) {
    return null;
  }

  const caracteristiqueChoixTemplateHydrated: CaracteristiqueChoixTemplateEntityState = { ...caracteristiqueChoixTemplate };
  if (caracteristiqueTemplateEntities) {
    caracteristiqueChoixTemplateHydrated.caracteristiqueTemplate = caracteristiqueTemplateEntities[caracteristiqueChoixTemplate.caracteristiqueTemplate as number] as CaracteristiqueTemplate;
  } else {
    delete caracteristiqueChoixTemplateHydrated.caracteristiqueTemplate;
  }
  if (fichierEntities) {
    caracteristiqueChoixTemplateHydrated.fichier = fichierEntities[caracteristiqueChoixTemplate.fichier as number] as Fichier;
  } else {
    delete caracteristiqueChoixTemplateHydrated.fichier;
  }

  if (societeCaracteristiqueChoixEntities) {
    caracteristiqueChoixTemplateHydrated.societeCaracteristiqueChoices = ((caracteristiqueChoixTemplateHydrated.societeCaracteristiqueChoices as number[]) || []).map(
      id => societeCaracteristiqueChoixEntities[id]
    ) as SocieteCaracteristiqueChoix[];
  } else {
    delete caracteristiqueChoixTemplateHydrated.societeCaracteristiqueChoices;
  }

  return caracteristiqueChoixTemplateHydrated as CaracteristiqueChoixTemplate;
}
