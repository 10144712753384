import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { ProgInterventionPatrimoineApiService } from '@get/store/api-services';
import { ProgInterventionPatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgInterventionPatrimoine } from '@get/store/configs/normalization';

export function getDefaultDeleteProgInterventionPatrimoineActions(progInterventionPatrimoine: ProgInterventionPatrimoineEntityState): Action[] {
  const actions: Action[] = [ProgInterventionPatrimoineGeneratedActions.deleteOneProgInterventionPatrimoineSuccess({ idProgInterventionPatrimoine: progInterventionPatrimoine.idProgInterventionPatrimoine })];

  return actions;
}

export class GeneratedProgInterventionPatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected progInterventionPatrimoineApiService: ProgInterventionPatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyProgInterventionPatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgInterventionPatrimoineGeneratedActions.normalizeManyProgInterventionPatrimoinesAfterUpsert),
      concatMap(({ progInterventionPatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeProgInterventionPatrimoine(progInterventionPatrimoines);
        return [getMultiAction(actions, '[ProgInterventionPatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
