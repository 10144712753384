import { ProgPatrimoineInfoState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progPatrimoineInfoReducersGeneratedFunctions } from './prog-patrimoine-info-generated.reducer';

const progPatrimoineInfoReducersFunctions = [...progPatrimoineInfoReducersGeneratedFunctions];

const progPatrimoineInfoReducer = createReducer(
  ProgPatrimoineInfoState.initialState,
  ...progPatrimoineInfoReducersFunctions
);

export function reducer(state: ProgPatrimoineInfoState.IState | undefined, action: Action) {
  return progPatrimoineInfoReducer(state, action);
}
