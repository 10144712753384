import { Injectable } from '@angular/core';
import { ProgIntervention, ProgInterventionCreation, ProgPatrimoineInfo, Valeur } from '@get/api-interfaces';
import { ProgInterventionApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedProgInterventionService } from './prog-intervention-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgInterventionService extends GeneratedProgInterventionService {
  constructor(
    store$: Store<AppState>,
    actions$: Actions,
    private readonly progInterventionApiService: ProgInterventionApiService
  ) {
    super(store$, actions$);
  }

  public removeProgInterventionsBySociete(idSociete: number): Promise<number> {
    return this.progInterventionApiService.removeProgInterventionsBySociete(idSociete);
  }

  public removeProgInterventionsByProgComposant(idProgComposant: number): Promise<number> {
    return this.progInterventionApiService.removeProgInterventionsByProgComposant(idProgComposant);
  }

  public updateOneProgInterventionWithoutStore(
    progIntervention: Partial<ProgIntervention>
  ): Observable<ProgIntervention> {
    return this.progInterventionApiService.updateProgIntervention(progIntervention);
  }

  public removeProgInterventionWithoutStore(
    idProgIntervention: number
  ): Observable<{ idsProgInterventionPatrimoine: number[] }> {
    return this.progInterventionApiService.removeProgIntervention(idProgIntervention);
  }

  public duplicateProgInterventionWithoutStore(params: {
    idSociete: number;
    idProgIntervention: number;
  }): Observable<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }> {
    return this.progInterventionApiService.duplicateProgIntervention(params);
  }

  public handleProgInterventionCreationWithoutStore(
    params: ProgInterventionCreation
  ): Observable<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }> {
    return this.progInterventionApiService.handleProgInterventionCreation(params);
  }

  public upsertOneValeurWithoutStore(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.progInterventionApiService.updateValeur(valeur);
  }
}
