import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ValeurProgIntervention, ValeurProgInterventionEntityState } from '@get/api-interfaces';
import { ValeurProgInterventionRelationsIds } from '@get/store/ids-interfaces';

export const ValeurProgInterventionGeneratedActions = createActionGroup({
  source: 'Valeur Prog Intervention Generated',
  events: {
    'Upsert One Valeur Prog Intervention': props<{ valeurProgIntervention: Partial<ValeurProgIntervention>; ids?: ValeurProgInterventionRelationsIds; }>(),
    'Upsert Many Valeur Prog Interventions': props<{ valeurProgInterventions: Partial<ValeurProgIntervention>[]; ids?: ValeurProgInterventionRelationsIds; }>(),
    'Upsert Many Valeur Prog Interventions Success': props<{ valeurProgInterventions: ValeurProgInterventionEntityState[] }>(),
    'Delete One Valeur Prog Intervention Success': props<{ idValeurProgIntervention: number }>(),
    'Normalize Many Valeur Prog Interventions After Upsert': props<{ valeurProgInterventions: ValeurProgInterventionEntityState[] }>(),
    'Valeur Prog Interventions Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeur Prog Interventions': emptyProps()
  }
});
