import { ProgComposantSousGroupeState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progComposantSousGroupeReducersGeneratedFunctions } from './prog-composant-sous-groupe-generated.reducer';

const progComposantSousGroupeReducersFunctions = [...progComposantSousGroupeReducersGeneratedFunctions];

const progComposantSousGroupeReducer = createReducer(
  ProgComposantSousGroupeState.initialState,
  ...progComposantSousGroupeReducersFunctions
);

export function reducer(state: ProgComposantSousGroupeState.IState | undefined, action: Action) {
  return progComposantSousGroupeReducer(state, action);
}
