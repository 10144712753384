import { createEntityAdapter } from '@ngrx/entity';
import { ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ProgPatrimoineInfoEntityState>;

export const adapter = createEntityAdapter<ProgPatrimoineInfoEntityState>({
  selectId: o => o.idProgPatrimoineInfo
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const progPatrimoineInfoFeatureKey = 'progPatrimoineInfo';
