import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';

export const ComposantGeneratedActions = createActionGroup({
  source: 'Composant Generated',
  events: {
    'Upsert One Composant': props<{ composant: Partial<Composant>; ids?: ComposantRelationsIds; }>(),
    'Upsert Many Composants': props<{ composants: Partial<Composant>[]; ids?: ComposantRelationsIds; }>(),
    'Upsert Many Composants Success': props<{ composants: ComposantEntityState[] }>(),
    'Delete One Composant': props<{ idComposant: number }>(),
    'Delete One Composant Success': props<{ idComposant: number }>(),
    'Normalize Many Composants After Upsert': props<{ composants: ComposantEntityState[] }>(),
    'Composants Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Composants': emptyProps(),
    'Add Many Valeur Composant Success': props<{ idComposant: number; idValeurComposants: number[] }>(),
    'Delete Many Valeur Composant Success': props<{ idValeurComposants: number[]; idComposants: number[] }>(),
    'Add Many Valeur Success': props<{ idComposant: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idComposants: number[] }>(),
    'Add Espace Success': props<{ idComposant: number; idEspace: number }>(),
    'Delete Many Espace Success': props<{ idEspaces: number[]; idComposants: number[] }>(),
    'Add Societe Composant Success': props<{ idComposant: number; idSocieteComposant: number }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idComposants: number[] }>(),
    'Add User Success': props<{ idComposant: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idComposants: number[] }>()
  }
});
