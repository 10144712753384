import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { SocieteEspaceState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteEspaceEntityState } from '@get/api-interfaces';

export const societeEspaceReducersGeneratedFunctions: ReducerTypes<SocieteEspaceState.IState, readonly ActionCreator[]>[] = [
  on(SocieteEspaceGeneratedActions.getManySocieteEspaces, (state: SocieteEspaceState.IState) => setLoadingsState(state, true)),
  on(SocieteEspaceGeneratedActions.upsertOneSocieteEspace, (state: SocieteEspaceState.IState) => setLoadingsState(state, true)),

  on(SocieteEspaceGeneratedActions.upsertManySocieteEspacesSuccess, (state: SocieteEspaceState.IState, { societeEspaces }) =>
    SocieteEspaceState.adapter.upsertMany(societeEspaces, setLoadingsState(state, false))
  ),
  on(SocieteEspaceGeneratedActions.deleteOneSocieteEspace, (state: SocieteEspaceState.IState) => setLoadingsState(state, true)),
  on(SocieteEspaceGeneratedActions.deleteOneSocieteEspaceSuccess, (state: SocieteEspaceState.IState, { idSocieteEspace }) =>
    SocieteEspaceState.adapter.removeOne(idSocieteEspace, setLoadingsState(state, false))
  ),

  on(SocieteEspaceGeneratedActions.clearSocieteEspaces, () => SocieteEspaceState.initialState),

  on(
    SocieteEspaceGeneratedActions.addManyEspaceSuccess,
    (state: SocieteEspaceState.IState, { idSocieteEspace, idEspaces }) => {
      if (!state.entities[idSocieteEspace]) {
        return state;
      }
      const espaces = (state.entities[idSocieteEspace]?.espaces as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspace]: {
            ...state.entities[idSocieteEspace],
            espaces: espaces.concat(
              idEspaces.filter(id => espaces.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceGeneratedActions.deleteManyEspaceSuccess,
    (state: SocieteEspaceState.IState, { idEspaces, idSocieteEspaces }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaces.reduce((entities, idSocieteEspace) => {
            if (!state.entities[idSocieteEspace]?.espaces) {
              return entities;
            }
            entities[idSocieteEspace] = {
              ...state.entities[idSocieteEspace],
              espaces: (state.entities[idSocieteEspace]?.espaces as number[])?.filter(
                (idEspace: number) => !idEspaces.some((id: number) => id === idEspace)
              )
            } as SocieteEspaceEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceEntityState>)
        }
      };
    }
  ),

  on(
    SocieteEspaceGeneratedActions.addSocieteEspaceFamilleSuccess,
    (state: SocieteEspaceState.IState, { idSocieteEspace, idSocieteEspaceFamille }) => {
      if (!state.entities[idSocieteEspace]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspace]: {
            ...state.entities[idSocieteEspace],
            societeEspaceFamille: idSocieteEspaceFamille
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceGeneratedActions.deleteManySocieteEspaceFamilleSuccess,
    (state: SocieteEspaceState.IState, { idSocieteEspaceFamilles, idSocieteEspaces }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaces.reduce((entities, idSocieteEspace) => {
            if (!state.entities[idSocieteEspace]?.societeEspaceFamille) {
              return entities;
            }
            entities[idSocieteEspace] = {
              ...state.entities[idSocieteEspace],
              societeEspaceFamille: idSocieteEspaceFamilles.some(
                (idSocieteEspaceFamille: number) => idSocieteEspaceFamille === state.entities[idSocieteEspace]?.societeEspaceFamille
              )
                ? undefined
                : state.entities[idSocieteEspace]?.societeEspaceFamille
            } as SocieteEspaceEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteEspaceState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteEspaceState.IState {
  return { ...state, isLoaded, isLoading };
}
