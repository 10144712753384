import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProgComposantApiService } from '@get/store/api-services';
import { GeneratedProgComposantEffects } from './prog-composant-generated.effects';

@Injectable()
export class ProgComposantEffects extends GeneratedProgComposantEffects {
  constructor(actions$: Actions, progComposantApiService: ProgComposantApiService, store$: Store<AppState>) {
    super(actions$, progComposantApiService, store$);
  }
}
