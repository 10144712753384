import { ProgComposantState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progComposantReducersGeneratedFunctions } from './prog-composant-generated.reducer';

const progComposantReducersFunctions = [...progComposantReducersGeneratedFunctions];

const progComposantReducer = createReducer(ProgComposantState.initialState, ...progComposantReducersFunctions);

export function reducer(state: ProgComposantState.IState | undefined, action: Action) {
  return progComposantReducer(state, action);
}
