import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { UsageComposantTemplateApiService } from '@get/store/api-services';
import { UsageComposantTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUsageComposantTemplate } from '@get/store/configs/normalization';

export class GeneratedUsageComposantTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected usageComposantTemplateApiService: UsageComposantTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyUsageComposantTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageComposantTemplateGeneratedActions.normalizeManyUsageComposantTemplatesAfterUpsert),
      concatMap(({ usageComposantTemplates }) => {
        const actions: Action[] = getActionsToNormalizeUsageComposantTemplate(usageComposantTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[UsageComposantTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
