import { UsageComposantTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { usageComposantTemplateReducersGeneratedFunctions } from './usage-composant-template-generated.reducer';

const usageComposantTemplateReducersFunctions = [...usageComposantTemplateReducersGeneratedFunctions];

const usageComposantTemplateReducer = createReducer(UsageComposantTemplateState.initialState, ...usageComposantTemplateReducersFunctions);

export function reducer(state: UsageComposantTemplateState.IState | undefined, action: Action) {
  return usageComposantTemplateReducer(state, action);
}
