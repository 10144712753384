import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgBiblioInterventionScenario, ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionScenarioRelationsIds } from '@get/store/ids-interfaces';

export const ProgBiblioInterventionScenarioGeneratedActions = createActionGroup({
  source: 'Prog Biblio Intervention Scenario Generated',
  events: {
    'Upsert One Prog Biblio Intervention Scenario': props<{ progBiblioInterventionScenario: Partial<ProgBiblioInterventionScenario>; ids?: ProgBiblioInterventionScenarioRelationsIds; }>(),
    'Upsert Many Prog Biblio Intervention Scenarios': props<{ progBiblioInterventionScenarios: Partial<ProgBiblioInterventionScenario>[]; ids?: ProgBiblioInterventionScenarioRelationsIds; }>(),
    'Upsert Many Prog Biblio Intervention Scenarios Success': props<{ progBiblioInterventionScenarios: ProgBiblioInterventionScenarioEntityState[] }>(),
    'Delete One Prog Biblio Intervention Scenario Success': props<{ idProgBiblioInterventionScenario: number }>(),
    'Normalize Many Prog Biblio Intervention Scenarios After Upsert': props<{ progBiblioInterventionScenarios: ProgBiblioInterventionScenarioEntityState[] }>(),
    'Prog Biblio Intervention Scenarios Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Biblio Intervention Scenarios': emptyProps(),
    'Add Prog Scenario Success': props<{ idProgBiblioInterventionScenario: number; idProgScenario: number }>(),
    'Delete Many Prog Scenario Success': props<{ idProgScenarios: number[]; idProgBiblioInterventionScenarios: number[] }>(),
    'Add Prog Biblio Intervention Success': props<{ idProgBiblioInterventionScenario: number; idProgBiblioIntervention: number }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgBiblioInterventionScenarios: number[] }>()
  }
});
