import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteTerritoireApiService } from '@get/store/api-services';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteTerritoire } from '@get/store/configs/normalization';
import { SocieteTerritoireSelectors } from '@get/store/selectors';
import { SocieteTerritoireRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoine } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUser } from '@get/api-interfaces';

export function getDefaultAddSocieteTerritoireActions(societeTerritoire: SocieteTerritoireEntityState, ids?: SocieteTerritoireRelationsIds): Action[] {
  const actions: Action[] = [SocieteTerritoireGeneratedActions.normalizeManySocieteTerritoiresAfterUpsert({ societeTerritoires: [societeTerritoire] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteTerritoireSuccess({
        idSociete: ids.societe,
        idSocieteTerritoires: [societeTerritoire.idSocieteTerritoire]
      })
    );
    actions.push(
      SocieteTerritoireGeneratedActions.addSocieteSuccess({
        idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.societeTerritoirePatrimoines) {
    if (!Array.isArray(ids.societeTerritoirePatrimoines)) {
      actions.push(
        SocieteTerritoirePatrimoineGeneratedActions.upsertOneSocieteTerritoirePatrimoine({
          societeTerritoirePatrimoine: {
            idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
            idSocieteTerritoirePatrimoine: ids.societeTerritoirePatrimoines as number
          } as SocieteTerritoirePatrimoine
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idSocieteTerritoirePatrimoines: [ids.societeTerritoirePatrimoines as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoirePatrimoineGeneratedActions.upsertManySocieteTerritoirePatrimoines({
          societeTerritoirePatrimoines: (ids.societeTerritoirePatrimoines as number[]).map(
            (idSocieteTerritoirePatrimoine: number) => ({
              idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
              idSocieteTerritoirePatrimoine
            })
          ) as SocieteTerritoirePatrimoine[]
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idSocieteTerritoirePatrimoines: ids.societeTerritoirePatrimoines as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManyPatrimoineSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManyPatrimoineSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.societeTerritoireUsers) {
    if (!Array.isArray(ids.societeTerritoireUsers)) {
      actions.push(
        SocieteTerritoireUserGeneratedActions.upsertOneSocieteTerritoireUser({
          societeTerritoireUser: {
            idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
            idSocieteTerritoireUser: ids.societeTerritoireUsers as number
          } as SocieteTerritoireUser
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManySocieteTerritoireUserSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idSocieteTerritoireUsers: [ids.societeTerritoireUsers as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoireUserGeneratedActions.upsertManySocieteTerritoireUsers({
          societeTerritoireUsers: (ids.societeTerritoireUsers as number[]).map(
            (idSocieteTerritoireUser: number) => ({
              idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
              idSocieteTerritoireUser
            })
          ) as SocieteTerritoireUser[]
        })
      );
      actions.push(
        SocieteTerritoireGeneratedActions.addManySocieteTerritoireUserSuccess({
          idSocieteTerritoire: societeTerritoire.idSocieteTerritoire,
          idSocieteTerritoireUsers: ids.societeTerritoireUsers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteTerritoireActions(societeTerritoire: SocieteTerritoireEntityState): Action[] {
  const actions: Action[] = [SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoireSuccess({ idSocieteTerritoire: societeTerritoire.idSocieteTerritoire })];

  if (societeTerritoire.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteTerritoireSuccess({
        idSocieteTerritoires: [societeTerritoire.idSocieteTerritoire],
        idSocietes: [societeTerritoire.societe as number]
      })
    );
  }

  if (societeTerritoire.societeTerritoirePatrimoines) {
    actions.push(
      SocieteTerritoirePatrimoineGeneratedActions.deleteManySocieteTerritoireSuccess({
        idSocieteTerritoires: [societeTerritoire.idSocieteTerritoire],
        idSocieteTerritoirePatrimoines: societeTerritoire.societeTerritoirePatrimoines as number[]
      })
    );
  }

  if (societeTerritoire.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManySocieteTerritoireSuccess({
        idSocieteTerritoires: [societeTerritoire.idSocieteTerritoire],
        idPatrimoines: societeTerritoire.patrimoines as number[]
      })
    );
  }

  if (societeTerritoire.societeTerritoireUsers) {
    actions.push(
      SocieteTerritoireUserGeneratedActions.deleteManySocieteTerritoireSuccess({
        idSocieteTerritoires: [societeTerritoire.idSocieteTerritoire],
        idSocieteTerritoireUsers: societeTerritoire.societeTerritoireUsers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteTerritoireEffects {
  constructor(
    protected actions$: Actions,
    protected societeTerritoireApiService: SocieteTerritoireApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneSocieteTerritoire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireGeneratedActions.upsertOneSocieteTerritoire),
      concatMap(
        ({
          societeTerritoire,
          ids
        }: {
          societeTerritoire: Partial<SocieteTerritoire>;
          ids?: SocieteTerritoireRelationsIds;
        }) => {
          if (societeTerritoire.idSocieteTerritoire) {
            return this.societeTerritoireApiService.updateSocieteTerritoire(societeTerritoire).pipe(
              map((societeTerritoireReturned: SocieteTerritoire) => {
                return SocieteTerritoireGeneratedActions.normalizeManySocieteTerritoiresAfterUpsert({ societeTerritoires: [societeTerritoireReturned] });
              }),
              catchError(error => of(SocieteTerritoireGeneratedActions.societeTerritoiresFailure({ error })))
            );
          } else {
            return this.societeTerritoireApiService.addSocieteTerritoire(societeTerritoire).pipe(
              mergeMap((societeTerritoireReturned: SocieteTerritoire) => getDefaultAddSocieteTerritoireActions(societeTerritoireReturned, ids)),
              catchError(error => of(SocieteTerritoireGeneratedActions.societeTerritoiresFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteTerritoire$ = createEffect(() => {
    const selectSocieteTerritoireState$ = this.store$.select(SocieteTerritoireSelectors.selectSocieteTerritoireState);
    return this.actions$.pipe(
      ofType(SocieteTerritoireGeneratedActions.deleteOneSocieteTerritoire),
      withLatestFrom(selectSocieteTerritoireState$),
      concatMap(([{ idSocieteTerritoire }, state]) =>
        this.societeTerritoireApiService.deleteSocieteTerritoire(idSocieteTerritoire).pipe(
          mergeMap(_success => getDefaultDeleteSocieteTerritoireActions(state.entities[idSocieteTerritoire] as SocieteTerritoireEntityState)),
          catchError(error => of(SocieteTerritoireGeneratedActions.societeTerritoiresFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteTerritoiresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireGeneratedActions.normalizeManySocieteTerritoiresAfterUpsert),
      concatMap(({ societeTerritoires }) => {
        const actions: Action[] = getActionsToNormalizeSocieteTerritoire(societeTerritoires, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteTerritoire] Normalization After Upsert Success')];
      })
    );
  });
}
