import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgBudgetRelationsIds } from '@get/store/ids-interfaces';

export const ProgBudgetGeneratedActions = createActionGroup({
  source: 'Prog Budget Generated',
  events: {
    'Upsert One Prog Budget': props<{ progBudget: Partial<ProgBudget>; ids?: ProgBudgetRelationsIds; }>(),
    'Upsert Many Prog Budgets': props<{ progBudgets: Partial<ProgBudget>[]; ids?: ProgBudgetRelationsIds; }>(),
    'Upsert Many Prog Budgets Success': props<{ progBudgets: ProgBudgetEntityState[] }>(),
    'Delete One Prog Budget': props<{ idProgBudget: number }>(),
    'Delete One Prog Budget Success': props<{ idProgBudget: number }>(),
    'Normalize Many Prog Budgets After Upsert': props<{ progBudgets: ProgBudgetEntityState[] }>(),
    'Prog Budgets Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Budgets': emptyProps(),
    'Add Many Prog Biblio Intervention Success': props<{ idProgBudget: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgBudgets: number[] }>(),
    'Add Societe Success': props<{ idProgBudget: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgBudgets: number[] }>()
  }
});
