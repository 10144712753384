import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UsageCaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { UsageCaracteristiqueTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UsageCaracteristiqueTemplateEntityState } from '@get/api-interfaces';

export const usageCaracteristiqueTemplateReducersGeneratedFunctions: ReducerTypes<UsageCaracteristiqueTemplateState.IState, readonly ActionCreator[]>[] = [
  on(UsageCaracteristiqueTemplateGeneratedActions.upsertOneUsageCaracteristiqueTemplate, (state: UsageCaracteristiqueTemplateState.IState) => setLoadingsState(state, true)),

  on(UsageCaracteristiqueTemplateGeneratedActions.upsertManyUsageCaracteristiqueTemplatesSuccess, (state: UsageCaracteristiqueTemplateState.IState, { usageCaracteristiqueTemplates }) =>
    UsageCaracteristiqueTemplateState.adapter.upsertMany(usageCaracteristiqueTemplates, setLoadingsState(state, false))
  ),
  on(UsageCaracteristiqueTemplateGeneratedActions.deleteOneUsageCaracteristiqueTemplateSuccess, (state: UsageCaracteristiqueTemplateState.IState, { idUsageCaracteristiqueTemplate }) =>
    UsageCaracteristiqueTemplateState.adapter.removeOne(idUsageCaracteristiqueTemplate, setLoadingsState(state, false))
  ),

  on(UsageCaracteristiqueTemplateGeneratedActions.clearUsageCaracteristiqueTemplates, () => UsageCaracteristiqueTemplateState.initialState),

  on(
    UsageCaracteristiqueTemplateGeneratedActions.addUsageSuccess,
    (state: UsageCaracteristiqueTemplateState.IState, { idUsageCaracteristiqueTemplate, idUsage }) => {
      if (!state.entities[idUsageCaracteristiqueTemplate]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsageCaracteristiqueTemplate]: {
            ...state.entities[idUsageCaracteristiqueTemplate],
            usage: idUsage
          }
        }
      };
    }
  ),

  on(
    UsageCaracteristiqueTemplateGeneratedActions.deleteManyUsageSuccess,
    (state: UsageCaracteristiqueTemplateState.IState, { idUsages, idUsageCaracteristiqueTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsageCaracteristiqueTemplates.reduce((entities, idUsageCaracteristiqueTemplate) => {
            if (!state.entities[idUsageCaracteristiqueTemplate]?.usage) {
              return entities;
            }
            entities[idUsageCaracteristiqueTemplate] = {
              ...state.entities[idUsageCaracteristiqueTemplate],
              usage: idUsages.some(
                (idUsage: number) => idUsage === state.entities[idUsageCaracteristiqueTemplate]?.usage
              )
                ? undefined
                : state.entities[idUsageCaracteristiqueTemplate]?.usage
            } as UsageCaracteristiqueTemplateEntityState;
            return entities;
          }, {} as Dictionary<UsageCaracteristiqueTemplateEntityState>)
        }
      };
    }
  ),

  on(
    UsageCaracteristiqueTemplateGeneratedActions.addCaracteristiqueTemplateSuccess,
    (state: UsageCaracteristiqueTemplateState.IState, { idUsageCaracteristiqueTemplate, idCaracteristiqueTemplate }) => {
      if (!state.entities[idUsageCaracteristiqueTemplate]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUsageCaracteristiqueTemplate]: {
            ...state.entities[idUsageCaracteristiqueTemplate],
            caracteristiqueTemplate: idCaracteristiqueTemplate
          }
        }
      };
    }
  ),

  on(
    UsageCaracteristiqueTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: UsageCaracteristiqueTemplateState.IState, { idCaracteristiqueTemplates, idUsageCaracteristiqueTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsageCaracteristiqueTemplates.reduce((entities, idUsageCaracteristiqueTemplate) => {
            if (!state.entities[idUsageCaracteristiqueTemplate]?.caracteristiqueTemplate) {
              return entities;
            }
            entities[idUsageCaracteristiqueTemplate] = {
              ...state.entities[idUsageCaracteristiqueTemplate],
              caracteristiqueTemplate: idCaracteristiqueTemplates.some(
                (idCaracteristiqueTemplate: number) => idCaracteristiqueTemplate === state.entities[idUsageCaracteristiqueTemplate]?.caracteristiqueTemplate
              )
                ? undefined
                : state.entities[idUsageCaracteristiqueTemplate]?.caracteristiqueTemplate
            } as UsageCaracteristiqueTemplateEntityState;
            return entities;
          }, {} as Dictionary<UsageCaracteristiqueTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UsageCaracteristiqueTemplateState.IState,
  isLoading: boolean,
  isLoaded = true
): UsageCaracteristiqueTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
