import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UsageApiService } from '@get/store/api-services';
import { GeneratedUsageEffects } from './usage-generated.effects';

@Injectable()
export class UsageEffects extends GeneratedUsageEffects {
  constructor(actions$: Actions, usageApiService: UsageApiService, store$: Store<AppState>) {
    super(actions$, usageApiService, store$);
  }
}
