import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { CampagneRelationsIds } from '@get/store/ids-interfaces';

export const CampagneGeneratedActions = createActionGroup({
  source: 'Campagne Generated',
  events: {
    'Get One Campagne': props<{ idCampagne: number; params?: any }>(),
    'Get Many Campagnes': props<{ params: any }>(),
    'Upsert One Campagne': props<{ campagne: Partial<Campagne>; ids?: CampagneRelationsIds; }>(),
    'Upsert Many Campagnes': props<{ campagnes: Partial<Campagne>[]; ids?: CampagneRelationsIds; }>(),
    'Upsert Many Campagnes Success': props<{ campagnes: CampagneEntityState[] }>(),
    'Delete One Campagne': props<{ idCampagne: number }>(),
    'Delete One Campagne Success': props<{ idCampagne: number }>(),
    'Normalize Many Campagnes After Upsert': props<{ campagnes: CampagneEntityState[] }>(),
    'Campagnes Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Campagnes': emptyProps(),
    'Add Many Patrimoine Success': props<{ idCampagne: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idCampagnes: number[] }>(),
    'Add Many Campagne User Success': props<{ idCampagne: number; idCampagneUsers: number[] }>(),
    'Delete Many Campagne User Success': props<{ idCampagneUsers: number[]; idCampagnes: number[] }>(),
    'Add Many User Success': props<{ idCampagne: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idCampagnes: number[] }>(),
    'Add Many Societe Caracteristique Success': props<{ idCampagne: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idCampagnes: number[] }>(),
    'Add Many Campagne Patrimoine Success': props<{ idCampagne: number; idCampagnePatrimoines: number[] }>(),
    'Delete Many Campagne Patrimoine Success': props<{ idCampagnePatrimoines: number[]; idCampagnes: number[] }>(),
    'Add Many Campagne Societe Caracteristique Success': props<{ idCampagne: number; idCampagneSocieteCaracteristiques: number[] }>(),
    'Delete Many Campagne Societe Caracteristique Success': props<{ idCampagneSocieteCaracteristiques: number[]; idCampagnes: number[] }>(),
    'Add Societe Success': props<{ idCampagne: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idCampagnes: number[] }>()
  }
});
