import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupeApiService } from '@get/store/api-services';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgComposantSousGroupe } from '@get/store/configs/normalization';
import { ProgComposantSousGroupeSelectors } from '@get/store/selectors';
import { ProgComposantSousGroupeRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposant } from '@get/api-interfaces';

export function getDefaultAddProgComposantSousGroupeActions(progComposantSousGroupe: ProgComposantSousGroupeEntityState, ids?: ProgComposantSousGroupeRelationsIds): Action[] {
  const actions: Action[] = [ProgComposantSousGroupeGeneratedActions.normalizeManyProgComposantSousGroupesAfterUpsert({ progComposantSousGroupes: [progComposantSousGroupe] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgComposantSousGroupeSuccess({
        idSociete: ids.societe,
        idProgComposantSousGroupes: [progComposantSousGroupe.idProgComposantSousGroupe]
      })
    );
    actions.push(
      ProgComposantSousGroupeGeneratedActions.addSocieteSuccess({
        idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progComposantGroupe) {
    actions.push(
      ProgComposantGroupeGeneratedActions.addManyProgComposantSousGroupeSuccess({
        idProgComposantGroupe: ids.progComposantGroupe,
        idProgComposantSousGroupes: [progComposantSousGroupe.idProgComposantSousGroupe]
      })
    );
    actions.push(
      ProgComposantSousGroupeGeneratedActions.addProgComposantGroupeSuccess({
        idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
        idProgComposantGroupe: ids.progComposantGroupe
      })
    );
  }

  if (ids?.progComposants) {
    if (!Array.isArray(ids.progComposants)) {
      actions.push(
        ProgComposantGeneratedActions.upsertOneProgComposant({
          progComposant: {
            idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
            idProgComposant: ids.progComposants as number
          } as ProgComposant
        })
      );
      actions.push(
        ProgComposantSousGroupeGeneratedActions.addManyProgComposantSuccess({
          idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
          idProgComposants: [ids.progComposants as number]
        })
      );
    } else {
      actions.push(
        ProgComposantGeneratedActions.upsertManyProgComposants({
          progComposants: (ids.progComposants as number[]).map(
            (idProgComposant: number) => ({
              idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
              idProgComposant
            })
          ) as ProgComposant[]
        })
      );
      actions.push(
        ProgComposantSousGroupeGeneratedActions.addManyProgComposantSuccess({
          idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe,
          idProgComposants: ids.progComposants as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgComposantSousGroupeActions(progComposantSousGroupe: ProgComposantSousGroupeEntityState): Action[] {
  const actions: Action[] = [ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupeSuccess({ idProgComposantSousGroupe: progComposantSousGroupe.idProgComposantSousGroupe })];

  if (progComposantSousGroupe.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgComposantSousGroupeSuccess({
        idProgComposantSousGroupes: [progComposantSousGroupe.idProgComposantSousGroupe],
        idSocietes: [progComposantSousGroupe.societe as number]
      })
    );
  }

  if (progComposantSousGroupe.progComposantGroupe) {
    actions.push(
      ProgComposantGroupeGeneratedActions.deleteManyProgComposantSousGroupeSuccess({
        idProgComposantSousGroupes: [progComposantSousGroupe.idProgComposantSousGroupe],
        idProgComposantGroupes: [progComposantSousGroupe.progComposantGroupe as number]
      })
    );
  }

  if (progComposantSousGroupe.progComposants) {
    actions.push(
      ProgComposantGeneratedActions.deleteManyProgComposantSousGroupeSuccess({
        idProgComposantSousGroupes: [progComposantSousGroupe.idProgComposantSousGroupe],
        idProgComposants: progComposantSousGroupe.progComposants as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgComposantSousGroupeEffects {
  constructor(
    protected actions$: Actions,
    protected progComposantSousGroupeApiService: ProgComposantSousGroupeApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgComposantSousGroupe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantSousGroupeGeneratedActions.upsertOneProgComposantSousGroupe),
      concatMap(
        ({
          progComposantSousGroupe,
          ids
        }: {
          progComposantSousGroupe: Partial<ProgComposantSousGroupe>;
          ids?: ProgComposantSousGroupeRelationsIds;
        }) => {
          if (progComposantSousGroupe.idProgComposantSousGroupe) {
            return this.progComposantSousGroupeApiService.updateProgComposantSousGroupe(progComposantSousGroupe).pipe(
              map((progComposantSousGroupeReturned: ProgComposantSousGroupe) => {
                return ProgComposantSousGroupeGeneratedActions.normalizeManyProgComposantSousGroupesAfterUpsert({ progComposantSousGroupes: [progComposantSousGroupeReturned] });
              }),
              catchError(error => of(ProgComposantSousGroupeGeneratedActions.progComposantSousGroupesFailure({ error })))
            );
          } else {
            return this.progComposantSousGroupeApiService.addProgComposantSousGroupe(progComposantSousGroupe).pipe(
              mergeMap((progComposantSousGroupeReturned: ProgComposantSousGroupe) => getDefaultAddProgComposantSousGroupeActions(progComposantSousGroupeReturned, ids)),
              catchError(error => of(ProgComposantSousGroupeGeneratedActions.progComposantSousGroupesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgComposantSousGroupe$ = createEffect(() => {
    const selectProgComposantSousGroupeState$ = this.store$.select(ProgComposantSousGroupeSelectors.selectProgComposantSousGroupeState);
    return this.actions$.pipe(
      ofType(ProgComposantSousGroupeGeneratedActions.deleteOneProgComposantSousGroupe),
      withLatestFrom(selectProgComposantSousGroupeState$),
      concatMap(([{ idProgComposantSousGroupe }, state]) =>
        this.progComposantSousGroupeApiService.deleteProgComposantSousGroupe(idProgComposantSousGroupe).pipe(
          mergeMap(_success => getDefaultDeleteProgComposantSousGroupeActions(state.entities[idProgComposantSousGroupe] as ProgComposantSousGroupeEntityState)),
          catchError(error => of(ProgComposantSousGroupeGeneratedActions.progComposantSousGroupesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgComposantSousGroupesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgComposantSousGroupeGeneratedActions.normalizeManyProgComposantSousGroupesAfterUpsert),
      concatMap(({ progComposantSousGroupes }) => {
        const actions: Action[] = getActionsToNormalizeProgComposantSousGroupe(progComposantSousGroupes, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgComposantSousGroupe] Normalization After Upsert Success')];
      })
    );
  });
}
