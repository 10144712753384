import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgInterventionPatrimoine, ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgInterventionPatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progInterventionPatrimoineRelations: string[] = ['patrimoines','progInterventions',];

export const { selectEntities, selectAll } = ProgInterventionPatrimoineState.adapter.getSelectors();

export const selectProgInterventionPatrimoineState = createFeatureSelector<ProgInterventionPatrimoineState.IState>(ProgInterventionPatrimoineState.progInterventionPatrimoineFeatureKey);

export const selectIsLoadedProgInterventionPatrimoine = createSelector(
  selectProgInterventionPatrimoineState,
  (state: ProgInterventionPatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingProgInterventionPatrimoine = createSelector(
  selectProgInterventionPatrimoineState,
  (state: ProgInterventionPatrimoineState.IState) => state.isLoading
);

export const selectIsReadyProgInterventionPatrimoine = createSelector(
  selectProgInterventionPatrimoineState,
  (state: ProgInterventionPatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgInterventionPatrimoine = createSelector(
  selectProgInterventionPatrimoineState,
  (state: ProgInterventionPatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgInterventionPatrimoinesEntities = createSelector(selectProgInterventionPatrimoineState, selectEntities);

export const selectProgInterventionPatrimoinesArray = createSelector(selectProgInterventionPatrimoineState, selectAll);

const progInterventionPatrimoinesInObject = (progInterventionPatrimoines: Dictionary<ProgInterventionPatrimoineEntityState>) => ({ progInterventionPatrimoines })

const selectProgInterventionPatrimoinesEntitiesDictionary = createSelector(selectProgInterventionPatrimoinesEntities, progInterventionPatrimoinesInObject);

const selectAllProgInterventionPatrimoinesObject = createSelector(selectProgInterventionPatrimoinesEntities, progInterventionPatrimoines => {
  return hydrateAll({ progInterventionPatrimoines });
});

const selectOneProgInterventionPatrimoineDictionary = (idProgInterventionPatrimoine : number) =>
  createSelector(selectProgInterventionPatrimoinesEntities, progInterventionPatrimoines => ({
    progInterventionPatrimoines: { [idProgInterventionPatrimoine]: progInterventionPatrimoines[idProgInterventionPatrimoine] }
  }));

const selectOneProgInterventionPatrimoineDictionaryWithoutChild = (idProgInterventionPatrimoine : number) =>
  createSelector(selectProgInterventionPatrimoinesEntities, progInterventionPatrimoines => ({
    progInterventionPatrimoine: progInterventionPatrimoines[idProgInterventionPatrimoine]
  }));

const selectAllProgInterventionPatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllProgInterventionPatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgInterventionPatrimoine>(
      schema,
      selectAllProgInterventionPatrimoinesSelectors,
      selectProgInterventionPatrimoinesEntitiesDictionary,
      getRelationSelectors,
      progInterventionPatrimoineRelations,
      hydrateAll,
      'progInterventionPatrimoine'
    );
  } else {
    return selectAllProgInterventionPatrimoinesObject;
  }
}

export function selectAllProgInterventionPatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey = 'progInterventionPatrimoines'
): Selector {
  return createSelector(selectAllProgInterventionPatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgInterventionPatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progInterventionPatrimoines.length; i++) {
      res[customKey][result.progInterventionPatrimoines[i].idProgInterventionPatrimoine] = result.progInterventionPatrimoines[i];
    }
    return res;
  });
}

export function selectOneProgInterventionPatrimoine(
  schema: SelectSchema = {},
  idProgInterventionPatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgInterventionPatrimoineDictionary(idProgInterventionPatrimoine)];
  selectors.push(...getRelationSelectors(schema, progInterventionPatrimoineRelations, 'progInterventionPatrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgInterventionPatrimoineDictionaryWithoutChild(idProgInterventionPatrimoine);
  }
}

interface hydrateArgs {
  progInterventionPatrimoines: Dictionary<ProgInterventionPatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progInterventionPatrimoines: (ProgInterventionPatrimoine | null)[] } {
  const {
    progInterventionPatrimoines,
    patrimoines,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progInterventionPatrimoines: Object.keys(progInterventionPatrimoines).map(idProgInterventionPatrimoine =>
      hydrate(
        progInterventionPatrimoines[idProgInterventionPatrimoine] as ProgInterventionPatrimoineEntityState,
        patrimoines,
        progInterventions
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progInterventionPatrimoine: ProgInterventionPatrimoineEntityState | null } {
  const {
    progInterventionPatrimoines,
    patrimoines,
    progInterventions
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progInterventionPatrimoine = Object.values(progInterventionPatrimoines)[0];
  return {
    progInterventionPatrimoine: hydrate(
      progInterventionPatrimoine as ProgInterventionPatrimoineEntityState,
      patrimoines,
      progInterventions
    )
  };
}

function hydrate(
  progInterventionPatrimoine: ProgInterventionPatrimoineEntityState,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
): ProgInterventionPatrimoine | null {
  if (!progInterventionPatrimoine) {
    return null;
  }

  const progInterventionPatrimoineHydrated: ProgInterventionPatrimoineEntityState = { ...progInterventionPatrimoine };
  if (patrimoineEntities) {
    progInterventionPatrimoineHydrated.patrimoine = patrimoineEntities[progInterventionPatrimoine.patrimoine as number] as Patrimoine;
  } else {
    delete progInterventionPatrimoineHydrated.patrimoine;
  }
  if (progInterventionEntities) {
    progInterventionPatrimoineHydrated.progIntervention = progInterventionEntities[progInterventionPatrimoine.progIntervention as number] as ProgIntervention;
  } else {
    delete progInterventionPatrimoineHydrated.progIntervention;
  }

  return progInterventionPatrimoineHydrated as ProgInterventionPatrimoine;
}
