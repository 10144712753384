import { BackError, FileImportError } from '@get/api-interfaces';

function formatOneBackError(err: BackError): string {
  return `${err?.title ?? ''}<br /><br />${err?.messages?.join('<br />') ?? ''}`;
}

export function formatErrorsAsHtml(error: FileImportError): string {
  const totalNumberOfIncoherences =
    error?.errors?.map(el => el?.messages?.length)?.reduce((acc, curr) => acc + (curr ?? 0), 0) ?? 0;
  const title = `Fichier: ${error?.fileName}`;
  const incoherences = `${totalNumberOfIncoherences} ${
    totalNumberOfIncoherences > 1 ? 'incohérences ont été détectées' : 'incohérence a été détectée'
  }`;

  return `<b>${incoherences}.</b><br />${title}<br /><br />${
    error?.errors?.map(el => formatOneBackError(el))?.join('<br /><br />') ?? ''
  }`;
}
