import { ProgBiblioInterventionState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { progBiblioInterventionReducersGeneratedFunctions } from './prog-biblio-intervention-generated.reducer';

const progBiblioInterventionReducersFunctions = [...progBiblioInterventionReducersGeneratedFunctions];

const progBiblioInterventionReducer = createReducer(
  ProgBiblioInterventionState.initialState,
  ...progBiblioInterventionReducersFunctions
);

export function reducer(state: ProgBiblioInterventionState.IState | undefined, action: Action) {
  return progBiblioInterventionReducer(state, action);
}
