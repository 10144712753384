import { Injectable } from '@angular/core';
import {
  CopyInterventionCaracsParams,
  ProgIntervention,
  ProgInterventionCreation,
  ProgPatrimoineInfo,
  Valeur
} from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable, firstValueFrom } from 'rxjs';
import { GeneratedProgInterventionApiService } from './prog-intervention-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProgInterventionApiService extends GeneratedProgInterventionApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateProgIntervention(progIntervention: Partial<ProgIntervention>): Observable<ProgIntervention> {
    return this.repo.update('prog-intervention', progIntervention);
  }

  public handleProgInterventionCreation(
    params: ProgInterventionCreation
  ): Observable<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }> {
    return this.repo.create<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }>(
      'prog-intervention/handle-many',
      params
    );
  }

  public duplicateSocieteCaracAndList(params: CopyInterventionCaracsParams): Observable<string[]> {
    return this.repo.create('prog-intervention/duplicate-societe-carac-and-list', params);
  }

  public duplicateProgIntervention(params: {
    idSociete: number;
    idProgIntervention: number;
  }): Observable<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }> {
    return this.repo.create<{ progInterventions: ProgIntervention[]; progPatrimoineInfos: ProgPatrimoineInfo[] }>(
      'prog-intervention/duplicate',
      params
    );
  }

  public removeProgIntervention(idProgIntervention: number): Observable<{ idsProgInterventionPatrimoine: number[] }> {
    return this.repo.delete<{ idsProgInterventionPatrimoine: number[] }>(
      'prog-intervention/remove/' + +idProgIntervention
    );
  }

  public updateValeur(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.repo.update('prog-intervention/valeur', valeur);
  }

  public removeProgInterventionsBySociete(idSociete: number): Promise<number> {
    return firstValueFrom(this.repo.delete<number>('prog-intervention/remove-per-societe/' + +idSociete));
  }

  public removeProgInterventionsByProgComposant(idProgComposant: number): Promise<number> {
    return firstValueFrom(this.repo.delete<number>('prog-intervention/remove-per-prog-composant/' + +idProgComposant));
  }
}
