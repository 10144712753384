import { Injectable } from '@angular/core';
import { SocieteCaracteristique, SocieteCaracteristiqueToHandle } from '@get/api-interfaces';
import { SocieteCaracteristiqueActions, SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { GeneratedSocieteCaracteristiqueEffects } from './societe-caracteristique-generated.effects';

@Injectable()
export class SocieteCaracteristiqueEffects extends GeneratedSocieteCaracteristiqueEffects {
  constructor(
    actions$: Actions,
    societeCaracteristiqueApiService: SocieteCaracteristiqueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeCaracteristiqueApiService, store$);
  }

  handleManySocieteCaracteristiqueComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueActions.handleManySocieteCaracteristiqueComposants),
      concatMap((params: SocieteCaracteristiqueToHandle) => {
        return this.societeCaracteristiqueApiService.handleManySocieteCaracteristiqueComposants(params).pipe(
          map((societeCaracteristiqueReturned: SocieteCaracteristique[]) => {
            return SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({
              societeCaracteristiques: societeCaracteristiqueReturned
            });
          }),
          catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
        );
      })
    );
  });

  handleManySocieteCaracteristiquePatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueActions.handleManySocieteCaracteristiquePatrimoines),
      concatMap((params: SocieteCaracteristiqueToHandle) => {
        return this.societeCaracteristiqueApiService.handleManySocieteCaracteristiquePatrimoines(params).pipe(
          map((societeCaracteristiqueReturned: SocieteCaracteristique[]) => {
            return SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({
              societeCaracteristiques: societeCaracteristiqueReturned
            });
          }),
          catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
        );
      })
    );
  });
}
