import {
  ComposantAttendu,
  ComposantAttenduEntityState,
  DynamicType,
  Patrimoine,
  PatrimoineWithChildren,
  SocieteComposant,
  SocieteComposantFamille
} from '@get/api-interfaces';
import { ComposantAttenduDocument } from '@get/interfaces';
import { areComposantAttenduEqual, transformArrayToObject } from '@utils';

export function filterOnlyUsefulComposantAttendusForConsultDetail(
  composantAttendus: (ComposantAttenduEntityState | ComposantAttenduDocument)[],
  idPatrimoine: number
): ComposantAttenduEntityState[] {
  return (composantAttendus as ComposantAttenduEntityState[]).filter(
    composantAttendu => (composantAttendu.patrimoine as Patrimoine)?.idPatrimoine === idPatrimoine
  );
}

export function filterOnlyUsefulComposantAttendusForConsultComponents(
  composantAttendus: (ComposantAttenduEntityState | ComposantAttenduDocument)[],
  idPatrimoine: number,
  composantOrFamille?: SocieteComposant | SocieteComposantFamille
): ComposantAttenduEntityState[] {
  const composantOrFamilleObj =
    composantOrFamille && 'idSocieteComposant' in composantOrFamille
      ? {
          [composantOrFamille.idSocieteComposant]: true
        }
      : (composantOrFamille as SocieteComposantFamille)?.societeComposants?.reduce((acc, curr) => {
          acc[curr.idSocieteComposant] = true;
          return acc;
        }, {} as DynamicType<boolean>) || {};
  return (composantAttendus as ComposantAttenduEntityState[]).filter(
    composantAttendu =>
      (composantAttendu.patrimoine as Patrimoine)?.idPatrimoine === idPatrimoine ||
      composantOrFamilleObj[composantAttendu.societeComposant as number]
  );
}

export function gatherComposantAttendusFromPatrimoineWithChildren(
  patrimoine: PatrimoineWithChildren
): ComposantAttendu[] {
  return patrimoine.composantAttendus?.concat(
    patrimoine.children?.map(el => gatherComposantAttendusFromPatrimoineWithChildren(el)).flat() ?? []
  );
}

export function compareOfflineAndBackComposantAttendus(
  composantAttendus: ComposantAttenduDocument[],
  composantAttendusFromBackend: ComposantAttendu[]
): {
  mappedComposantAttendus: ComposantAttendu[];
  shouldResync: boolean;
} {
  const composantAttendusObj = transformArrayToObject(composantAttendusFromBackend, { key: 'idComposantAttendu' });
  return {
    mappedComposantAttendus: composantAttendus?.map(ca => ({
      ...ca,
      ...(composantAttendusObj[ca.idComposantAttendu] ?? {}),
      isSynced:
        areComposantAttenduEqual(ca as unknown as ComposantAttendu, composantAttendusObj[ca.idComposantAttendu]) ||
        ca.isSynced,
      idComposantAttendu: ca.idComposantAttendu
    })),
    shouldResync: !!composantAttendus?.filter(
      ca => !areComposantAttenduEqual(ca as unknown as ComposantAttendu, composantAttendusObj[ca.idComposantAttendu])
    )?.length
  };
}
