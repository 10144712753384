import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgBudget } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ProgBudgetRelationsIds } from '@get/store/ids-interfaces';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { ProgBudgetSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedProgBudgetService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProgBudgetSelectors.selectIsLoadedProgBudget));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProgBudgetSelectors.selectIsLoadingProgBudget));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProgBudgetSelectors.selectIsReadyAndLoadedProgBudget as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProgBudgets(schema: SelectSchema = {}): Observable<ProgBudget[]> {
    return this.store$.pipe(select(ProgBudgetSelectors.selectAllProgBudgets(schema))).pipe(
      switchMap(({ progBudgets }: { progBudgets: ProgBudget[] }) => {
        return this.getReady(schema).pipe(map(() => progBudgets));
      })
    );
  }

  public selectOneProgBudget(
    idProgBudget: number,
    schema: SelectSchema = {}
  ): Observable<ProgBudget> {
    return this.store$
      .pipe(select(ProgBudgetSelectors.selectOneProgBudget(schema, idProgBudget)))
      .pipe(
        switchMap(({ progBudget }: { progBudget: ProgBudget }) => {
          return this.getReady(schema).pipe(map(() => progBudget));
        })
      );
  }

  public upsertOneProgBudget(
    progBudget: Partial<ProgBudget>,
    ids: ProgBudgetRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProgBudget> {
    this.store$.dispatch(ProgBudgetGeneratedActions.upsertOneProgBudget({ progBudget, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgBudgetGeneratedActions.normalizeManyProgBudgetsAfterUpsert,
        ProgBudgetGeneratedActions.progBudgetsFailure,
        true
      );
    }
  }

  public deleteOneProgBudget(
    idProgBudget: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ProgBudgetGeneratedActions.deleteOneProgBudget({ idProgBudget }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProgBudgetGeneratedActions.deleteOneProgBudgetSuccess,
        ProgBudgetGeneratedActions.progBudgetsFailure
      );
    }
  }
}
