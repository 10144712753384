import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { DroitState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const droitRelations: string[] = ['societeProfilDroits','societeProfils',];

export const { selectEntities, selectAll } = DroitState.adapter.getSelectors();

export const selectDroitState = createFeatureSelector<DroitState.IState>(DroitState.droitFeatureKey);

export const selectIsLoadedDroit = createSelector(
  selectDroitState,
  (state: DroitState.IState) => state.isLoaded
);

export const selectIsLoadingDroit = createSelector(
  selectDroitState,
  (state: DroitState.IState) => state.isLoading
);

export const selectIsReadyDroit = createSelector(
  selectDroitState,
  (state: DroitState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedDroit = createSelector(
  selectDroitState,
  (state: DroitState.IState) => state.isLoaded && !state.isLoading
);

export const selectDroitsEntities = createSelector(selectDroitState, selectEntities);

export const selectDroitsArray = createSelector(selectDroitState, selectAll);

const droitsInObject = (droits: Dictionary<DroitEntityState>) => ({ droits })

const selectDroitsEntitiesDictionary = createSelector(selectDroitsEntities, droitsInObject);

const selectAllDroitsObject = createSelector(selectDroitsEntities, droits => {
  return hydrateAll({ droits });
});

const selectOneDroitDictionary = (idDroit : number) =>
  createSelector(selectDroitsEntities, droits => ({
    droits: { [idDroit]: droits[idDroit] }
  }));

const selectOneDroitDictionaryWithoutChild = (idDroit : number) =>
  createSelector(selectDroitsEntities, droits => ({
    droit: droits[idDroit]
  }));

const selectAllDroitsSelectors: Dictionary<Selector> = {};
export function selectAllDroits(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Droit>(
      schema,
      selectAllDroitsSelectors,
      selectDroitsEntitiesDictionary,
      getRelationSelectors,
      droitRelations,
      hydrateAll,
      'droit'
    );
  } else {
    return selectAllDroitsObject;
  }
}

export function selectAllDroitsDictionary(
  schema: SelectSchema = {},
  customKey = 'droits'
): Selector {
  return createSelector(selectAllDroits(schema), result => {
    const res = { [customKey]: {} as Dictionary<DroitEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.droits.length; i++) {
      res[customKey][result.droits[i].idDroit] = result.droits[i];
    }
    return res;
  });
}

export function selectOneDroit(
  schema: SelectSchema = {},
  idDroit: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneDroitDictionary(idDroit)];
  selectors.push(...getRelationSelectors(schema, droitRelations, 'droit'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneDroitDictionaryWithoutChild(idDroit);
  }
}

interface hydrateArgs {
  droits: Dictionary<DroitEntityState>;
  societeProfilDroits?: Dictionary<SocieteProfilDroitEntityState>;
  societeProfils?: Dictionary<SocieteProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { droits: (Droit | null)[] } {
  const {
    droits,
    societeProfilDroits,
    societeProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    droits: Object.keys(droits).map(idDroit =>
      hydrate(
        droits[idDroit] as DroitEntityState,
        societeProfilDroits,
        societeProfils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { droit: DroitEntityState | null } {
  const {
    droits,
    societeProfilDroits,
    societeProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const droit = Object.values(droits)[0];
  return {
    droit: hydrate(
      droit as DroitEntityState,
      societeProfilDroits,
      societeProfils
    )
  };
}

function hydrate(
  droit: DroitEntityState,
  societeProfilDroitEntities?: Dictionary<SocieteProfilDroitEntityState>,
  societeProfilEntities?: Dictionary<SocieteProfilEntityState>,
): Droit | null {
  if (!droit) {
    return null;
  }

  const droitHydrated: DroitEntityState = { ...droit };

  if (societeProfilDroitEntities) {
    droitHydrated.societeProfilDroits = ((droitHydrated.societeProfilDroits as number[]) || []).map(
      id => societeProfilDroitEntities[id]
    ) as SocieteProfilDroit[];
  } else {
    delete droitHydrated.societeProfilDroits;
  }

  if (societeProfilEntities) {
    droitHydrated.societeProfils = ((droitHydrated.societeProfils as number[]) || []).map(
      id => societeProfilEntities[id]
    ) as SocieteProfil[];
  } else {
    delete droitHydrated.societeProfils;
  }

  return droitHydrated as Droit;
}
