import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ComposantAttenduState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const composantAttenduRelations: string[] = ['societeComposants','patrimoines','users',];

export const { selectEntities, selectAll } = ComposantAttenduState.adapter.getSelectors();

export const selectComposantAttenduState = createFeatureSelector<ComposantAttenduState.IState>(ComposantAttenduState.composantAttenduFeatureKey);

export const selectIsLoadedComposantAttendu = createSelector(
  selectComposantAttenduState,
  (state: ComposantAttenduState.IState) => state.isLoaded
);

export const selectIsLoadingComposantAttendu = createSelector(
  selectComposantAttenduState,
  (state: ComposantAttenduState.IState) => state.isLoading
);

export const selectIsReadyComposantAttendu = createSelector(
  selectComposantAttenduState,
  (state: ComposantAttenduState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedComposantAttendu = createSelector(
  selectComposantAttenduState,
  (state: ComposantAttenduState.IState) => state.isLoaded && !state.isLoading
);

export const selectComposantAttendusEntities = createSelector(selectComposantAttenduState, selectEntities);

export const selectComposantAttendusArray = createSelector(selectComposantAttenduState, selectAll);

const composantAttendusInObject = (composantAttendus: Dictionary<ComposantAttenduEntityState>) => ({ composantAttendus })

const selectComposantAttendusEntitiesDictionary = createSelector(selectComposantAttendusEntities, composantAttendusInObject);

const selectAllComposantAttendusObject = createSelector(selectComposantAttendusEntities, composantAttendus => {
  return hydrateAll({ composantAttendus });
});

const selectOneComposantAttenduDictionary = (idComposantAttendu : number) =>
  createSelector(selectComposantAttendusEntities, composantAttendus => ({
    composantAttendus: { [idComposantAttendu]: composantAttendus[idComposantAttendu] }
  }));

const selectOneComposantAttenduDictionaryWithoutChild = (idComposantAttendu : number) =>
  createSelector(selectComposantAttendusEntities, composantAttendus => ({
    composantAttendu: composantAttendus[idComposantAttendu]
  }));

const selectAllComposantAttendusSelectors: Dictionary<Selector> = {};
export function selectAllComposantAttendus(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ComposantAttendu>(
      schema,
      selectAllComposantAttendusSelectors,
      selectComposantAttendusEntitiesDictionary,
      getRelationSelectors,
      composantAttenduRelations,
      hydrateAll,
      'composantAttendu'
    );
  } else {
    return selectAllComposantAttendusObject;
  }
}

export function selectAllComposantAttendusDictionary(
  schema: SelectSchema = {},
  customKey = 'composantAttendus'
): Selector {
  return createSelector(selectAllComposantAttendus(schema), result => {
    const res = { [customKey]: {} as Dictionary<ComposantAttenduEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.composantAttendus.length; i++) {
      res[customKey][result.composantAttendus[i].idComposantAttendu] = result.composantAttendus[i];
    }
    return res;
  });
}

export function selectOneComposantAttendu(
  schema: SelectSchema = {},
  idComposantAttendu: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneComposantAttenduDictionary(idComposantAttendu)];
  selectors.push(...getRelationSelectors(schema, composantAttenduRelations, 'composantAttendu'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneComposantAttenduDictionaryWithoutChild(idComposantAttendu);
  }
}

interface hydrateArgs {
  composantAttendus: Dictionary<ComposantAttenduEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { composantAttendus: (ComposantAttendu | null)[] } {
  const {
    composantAttendus,
    societeComposants,
    patrimoines,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    composantAttendus: Object.keys(composantAttendus).map(idComposantAttendu =>
      hydrate(
        composantAttendus[idComposantAttendu] as ComposantAttenduEntityState,
        societeComposants,
        patrimoines,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { composantAttendu: ComposantAttenduEntityState | null } {
  const {
    composantAttendus,
    societeComposants,
    patrimoines,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const composantAttendu = Object.values(composantAttendus)[0];
  return {
    composantAttendu: hydrate(
      composantAttendu as ComposantAttenduEntityState,
      societeComposants,
      patrimoines,
      users
    )
  };
}

function hydrate(
  composantAttendu: ComposantAttenduEntityState,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  userEntities?: Dictionary<UserEntityState>,
): ComposantAttendu | null {
  if (!composantAttendu) {
    return null;
  }

  const composantAttenduHydrated: ComposantAttenduEntityState = { ...composantAttendu };
  if (societeComposantEntities) {
    composantAttenduHydrated.societeComposant = societeComposantEntities[composantAttendu.societeComposant as number] as SocieteComposant;
  } else {
    delete composantAttenduHydrated.societeComposant;
  }
  if (patrimoineEntities) {
    composantAttenduHydrated.patrimoine = patrimoineEntities[composantAttendu.patrimoine as number] as Patrimoine;
  } else {
    delete composantAttenduHydrated.patrimoine;
  }
  if (userEntities) {
    composantAttenduHydrated.user = userEntities[composantAttendu.user as number] as User;
  } else {
    delete composantAttenduHydrated.user;
  }

  return composantAttenduHydrated as ComposantAttendu;
}
