import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { ProgInterventionFamilleRelationsIds } from '@get/store/ids-interfaces';

export const ProgInterventionFamilleGeneratedActions = createActionGroup({
  source: 'Prog Intervention Famille Generated',
  events: {
    'Upsert One Prog Intervention Famille': props<{ progInterventionFamille: Partial<ProgInterventionFamille>; ids?: ProgInterventionFamilleRelationsIds; }>(),
    'Upsert Many Prog Intervention Familles': props<{ progInterventionFamilles: Partial<ProgInterventionFamille>[]; ids?: ProgInterventionFamilleRelationsIds; }>(),
    'Upsert Many Prog Intervention Familles Success': props<{ progInterventionFamilles: ProgInterventionFamilleEntityState[] }>(),
    'Delete One Prog Intervention Famille': props<{ idProgInterventionFamille: number }>(),
    'Delete One Prog Intervention Famille Success': props<{ idProgInterventionFamille: number }>(),
    'Normalize Many Prog Intervention Familles After Upsert': props<{ progInterventionFamilles: ProgInterventionFamilleEntityState[] }>(),
    'Prog Intervention Familles Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Prog Intervention Familles': emptyProps(),
    'Add Many Prog Biblio Intervention Success': props<{ idProgInterventionFamille: number; idProgBiblioInterventions: number[] }>(),
    'Delete Many Prog Biblio Intervention Success': props<{ idProgBiblioInterventions: number[]; idProgInterventionFamilles: number[] }>(),
    'Add Societe Success': props<{ idProgInterventionFamille: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idProgInterventionFamilles: number[] }>()
  }
});
