import dayjs from 'dayjs';

export function formatDate(date: Date, format = 'DD/MM/YY'): string {
  const el = dayjs(date);
  return el.isValid() ? el.format(format) : '';
}

export function formatDateString(date: string, format = 'DD/MM/YY'): string {
  const el = dayjs(date);
  return el.isValid() ? el.format(format) : '';
}

export function formatDateFullYear(date: Date): string {
  return formatDate(date, 'DD/MM/YYYY');
}

export function formatDateTime(date: Date): string {
  return formatDate(date, 'DD/MM/YY HH:mm');
}

export function formatTime(date: Date): string {
  return formatDate(date, 'HH:mm');
}

export function dayjsDate(date: Date): dayjs.Dayjs | null {
  const el = dayjs(date);
  return date && el.isValid() ? el : null;
}

export function getDateFromYear(year: number): Date | null {
  return year && dayjs(year).isValid() ? new Date(Date.UTC(year, 0, 1, 0, 0, 0)) : null;
}

export function isDateBeforeOrEqual(dateAComparer: Date, currentDate: dayjs.Dayjs): boolean {
  if (dateAComparer) {
    const dateToCompare = dayjsDate(dateAComparer);
    return (
      !!dateToCompare &&
      currentDate?.isValid() &&
      (dateToCompare.isBefore(currentDate, 'day') || dateToCompare.isSame(currentDate, 'day'))
    );
  }
  return false;
}

export function isDateAfterOrEqual(dateAComparer: Date, currentDate: dayjs.Dayjs): boolean {
  if (dateAComparer) {
    const dateToCompare = dayjsDate(dateAComparer);
    return !!dateToCompare && currentDate?.isValid() && !dateToCompare.isBefore(currentDate, 'day');
  }
  return false;
}
