import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteProfilDroitApiService } from '@get/store/api-services';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteProfilDroit } from '@get/store/configs/normalization';

export class GeneratedSocieteProfilDroitEffects {
  constructor(
    protected actions$: Actions,
    protected societeProfilDroitApiService: SocieteProfilDroitApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteProfilDroitsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert),
      concatMap(({ societeProfilDroits }) => {
        const actions: Action[] = getActionsToNormalizeSocieteProfilDroit(societeProfilDroits, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteProfilDroit] Normalization After Upsert Success')];
      })
    );
  });
}
