import { RepositoryService } from '@get/services/repository';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteCaracteristiqueApiService {
  constructor(protected repo: RepositoryService) {}

  public addSocieteCaracteristique(societeCaracteristique: Partial<SocieteCaracteristique>): Observable<SocieteCaracteristique> {
    return this.repo.create<SocieteCaracteristique>('societe-caracteristique', societeCaracteristique);
  }

  public updateSocieteCaracteristique(societeCaracteristique: Partial<SocieteCaracteristique>): Observable<SocieteCaracteristique> {
    return this.repo.update('societe-caracteristique', societeCaracteristique);
  }

  public deleteSocieteCaracteristique(idSocieteCaracteristique: number): Observable<number> {
    return this.repo.delete('societe-caracteristique/' + +idSocieteCaracteristique);
  }
}
