import { RepositoryService } from '@get/services/repository';
import { User } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getUsers(params?: any): Observable<User[]> {
    return this.repo.getData<User[]>('user', params);
  }

  public getUser(params: { idUser: number; params?: any }): Observable<User> {
    return this.repo.getData<User>('user/' + params.idUser, params.params);
  }

  public addUser(user: Partial<User>): Observable<User> {
    return this.repo.create<User>('user', user);
  }

  public updateUser(user: Partial<User>): Observable<User> {
    return this.repo.update('user', user);
  }

  public deleteUser(idUser: number): Observable<number> {
    return this.repo.delete('user/' + +idUser);
  }
}
