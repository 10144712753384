import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurFichierEntityState } from '@get/api-interfaces';
import { ValeurFichierApiService } from '@get/store/api-services';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurFichier } from '@get/store/configs/normalization';
import { ValeurGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';

export function getDefaultDeleteValeurFichierActions(valeurFichier: ValeurFichierEntityState): Action[] {
  const actions: Action[] = [ValeurFichierGeneratedActions.deleteOneValeurFichierSuccess({ idValeurFichier: valeurFichier.idValeurFichier })];

  if (valeurFichier.valeur) {
    actions.push(
      ValeurGeneratedActions.deleteManyValeurFichierSuccess({
        idValeurFichiers: [valeurFichier.idValeurFichier],
        idValeurs: [valeurFichier.valeur as number]
      })
    );
  }

  if (valeurFichier.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyValeurFichierSuccess({
        idValeurFichiers: [valeurFichier.idValeurFichier],
        idFichiers: [valeurFichier.fichier as number]
      })
    );
  }

  return actions;
}

export class GeneratedValeurFichierEffects {
  constructor(
    protected actions$: Actions,
    protected valeurFichierApiService: ValeurFichierApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeurFichiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert),
      concatMap(({ valeurFichiers }) => {
        const actions: Action[] = getActionsToNormalizeValeurFichier(valeurFichiers, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurFichier] Normalization After Upsert Success')];
      })
    );
  });
}
