import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ProgBudgetState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const progBudgetRelations: string[] = ['progBiblioInterventions','progInterventionSaisies','progInterventionCalculs','societes',];

export const { selectEntities, selectAll } = ProgBudgetState.adapter.getSelectors();

export const selectProgBudgetState = createFeatureSelector<ProgBudgetState.IState>(ProgBudgetState.progBudgetFeatureKey);

export const selectIsLoadedProgBudget = createSelector(
  selectProgBudgetState,
  (state: ProgBudgetState.IState) => state.isLoaded
);

export const selectIsLoadingProgBudget = createSelector(
  selectProgBudgetState,
  (state: ProgBudgetState.IState) => state.isLoading
);

export const selectIsReadyProgBudget = createSelector(
  selectProgBudgetState,
  (state: ProgBudgetState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProgBudget = createSelector(
  selectProgBudgetState,
  (state: ProgBudgetState.IState) => state.isLoaded && !state.isLoading
);

export const selectProgBudgetsEntities = createSelector(selectProgBudgetState, selectEntities);

export const selectProgBudgetsArray = createSelector(selectProgBudgetState, selectAll);

const progBudgetsInObject = (progBudgets: Dictionary<ProgBudgetEntityState>) => ({ progBudgets })

const selectProgBudgetsEntitiesDictionary = createSelector(selectProgBudgetsEntities, progBudgetsInObject);

const selectAllProgBudgetsObject = createSelector(selectProgBudgetsEntities, progBudgets => {
  return hydrateAll({ progBudgets });
});

const selectOneProgBudgetDictionary = (idProgBudget : number) =>
  createSelector(selectProgBudgetsEntities, progBudgets => ({
    progBudgets: { [idProgBudget]: progBudgets[idProgBudget] }
  }));

const selectOneProgBudgetDictionaryWithoutChild = (idProgBudget : number) =>
  createSelector(selectProgBudgetsEntities, progBudgets => ({
    progBudget: progBudgets[idProgBudget]
  }));

const selectAllProgBudgetsSelectors: Dictionary<Selector> = {};
export function selectAllProgBudgets(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProgBudget>(
      schema,
      selectAllProgBudgetsSelectors,
      selectProgBudgetsEntitiesDictionary,
      getRelationSelectors,
      progBudgetRelations,
      hydrateAll,
      'progBudget'
    );
  } else {
    return selectAllProgBudgetsObject;
  }
}

export function selectAllProgBudgetsDictionary(
  schema: SelectSchema = {},
  customKey = 'progBudgets'
): Selector {
  return createSelector(selectAllProgBudgets(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProgBudgetEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.progBudgets.length; i++) {
      res[customKey][result.progBudgets[i].idProgBudget] = result.progBudgets[i];
    }
    return res;
  });
}

export function selectOneProgBudget(
  schema: SelectSchema = {},
  idProgBudget: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneProgBudgetDictionary(idProgBudget)];
  selectors.push(...getRelationSelectors(schema, progBudgetRelations, 'progBudget'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProgBudgetDictionaryWithoutChild(idProgBudget);
  }
}

interface hydrateArgs {
  progBudgets: Dictionary<ProgBudgetEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progInterventionSaisies?: Dictionary<ProgInterventionEntityState>;
  progInterventionCalculs?: Dictionary<ProgInterventionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { progBudgets: (ProgBudget | null)[] } {
  const {
    progBudgets,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    progBudgets: Object.keys(progBudgets).map(idProgBudget =>
      hydrate(
        progBudgets[idProgBudget] as ProgBudgetEntityState,
        societes,
        progBiblioInterventions,
        progInterventionSaisies,
        progInterventionCalculs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { progBudget: ProgBudgetEntityState | null } {
  const {
    progBudgets,
    societes,
    progBiblioInterventions,
    progInterventionSaisies,
    progInterventionCalculs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const progBudget = Object.values(progBudgets)[0];
  return {
    progBudget: hydrate(
      progBudget as ProgBudgetEntityState,
      societes,
      progBiblioInterventions,
      progInterventionSaisies,
      progInterventionCalculs
    )
  };
}

function hydrate(
  progBudget: ProgBudgetEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progInterventionSaisiesEntities?: Dictionary<ProgInterventionEntityState>,
  progInterventionCalculsEntities?: Dictionary<ProgInterventionEntityState>,
): ProgBudget | null {
  if (!progBudget) {
    return null;
  }

  const progBudgetHydrated: ProgBudgetEntityState = { ...progBudget };
  if (societeEntities) {
    progBudgetHydrated.societe = societeEntities[progBudget.societe as number] as Societe;
  } else {
    delete progBudgetHydrated.societe;
  }

  if (progBiblioInterventionEntities) {
    progBudgetHydrated.progBiblioInterventions = ((progBudgetHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete progBudgetHydrated.progBiblioInterventions;
  }

  if (progInterventionSaisiesEntities) {
    progBudgetHydrated.progInterventionSaisies = ((progBudgetHydrated.progInterventionSaisies as number[]) || []).map(
      id => progInterventionSaisiesEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progBudgetHydrated.progInterventionSaisies;
  }

  if (progInterventionCalculsEntities) {
    progBudgetHydrated.progInterventionCalculs = ((progBudgetHydrated.progInterventionCalculs as number[]) || []).map(
      id => progInterventionCalculsEntities[id]
    ) as ProgIntervention[];
  } else {
    delete progBudgetHydrated.progInterventionCalculs;
  }

  return progBudgetHydrated as ProgBudget;
}
