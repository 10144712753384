import { GridErrorProgComposantSousGroupeActions } from '@get/store/actions';
import { GridErrorProgComposantSousGroupeState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<
  GridErrorProgComposantSousGroupeState.IState,
  readonly ActionCreator[]
>[] = [];

export function setLoadingsState(
  state: GridErrorProgComposantSousGroupeState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorProgComposantSousGroupeState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorProgComposantSousGroupeActions.addOneGridErrorProgComposantSousGroupe,
    (state: GridErrorProgComposantSousGroupeState.IState, { gridErrorProgComposantSousGroupe }) =>
      GridErrorProgComposantSousGroupeState.adapter.upsertMany(
        [gridErrorProgComposantSousGroupe],
        setLoadingsState(state, false)
      )
  ),
  on(
    GridErrorProgComposantSousGroupeActions.deleteOneGridErrorProgComposantSousGroupeSuccess,
    (state: GridErrorProgComposantSousGroupeState.IState, { idGridError }) =>
      GridErrorProgComposantSousGroupeState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(
  GridErrorProgComposantSousGroupeState.initialState,
  ...gridErrorReducersFunctions
);

export function reducer(state: GridErrorProgComposantSousGroupeState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
