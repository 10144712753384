import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgInterventionGeneratedActions } from '@get/store/actions';
import { ProgInterventionState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgInterventionEntityState } from '@get/api-interfaces';

export const progInterventionReducersGeneratedFunctions: ReducerTypes<ProgInterventionState.IState, readonly ActionCreator[]>[] = [
  on(ProgInterventionGeneratedActions.upsertOneProgIntervention, (state: ProgInterventionState.IState) => setLoadingsState(state, true)),

  on(ProgInterventionGeneratedActions.upsertManyProgInterventionsSuccess, (state: ProgInterventionState.IState, { progInterventions }) =>
    ProgInterventionState.adapter.upsertMany(progInterventions, setLoadingsState(state, false))
  ),
  on(ProgInterventionGeneratedActions.deleteOneProgInterventionSuccess, (state: ProgInterventionState.IState, { idProgIntervention }) =>
    ProgInterventionState.adapter.removeOne(idProgIntervention, setLoadingsState(state, false))
  ),

  on(ProgInterventionGeneratedActions.clearProgInterventions, () => ProgInterventionState.initialState),

  on(
    ProgInterventionGeneratedActions.addManyPatrimoineSuccess,
    (state: ProgInterventionState.IState, { idProgIntervention, idPatrimoines }) => {
      if (!state.entities[idProgIntervention]) {
        return state;
      }
      const patrimoines = (state.entities[idProgIntervention]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgIntervention]: {
            ...state.entities[idProgIntervention],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgInterventionGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ProgInterventionState.IState, { idPatrimoines, idProgInterventions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgInterventions.reduce((entities, idProgIntervention) => {
            if (!state.entities[idProgIntervention]?.patrimoines) {
              return entities;
            }
            entities[idProgIntervention] = {
              ...state.entities[idProgIntervention],
              patrimoines: (state.entities[idProgIntervention]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as ProgInterventionEntityState;
            return entities;
          }, {} as Dictionary<ProgInterventionEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgInterventionState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgInterventionState.IState {
  return { ...state, isLoaded, isLoading };
}
