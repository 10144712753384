import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurComposantState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurComposantRelations: string[] = ['valeurs','composants',];

export const { selectEntities, selectAll } = ValeurComposantState.adapter.getSelectors();

export const selectValeurComposantState = createFeatureSelector<ValeurComposantState.IState>(ValeurComposantState.valeurComposantFeatureKey);

export const selectIsLoadedValeurComposant = createSelector(
  selectValeurComposantState,
  (state: ValeurComposantState.IState) => state.isLoaded
);

export const selectIsLoadingValeurComposant = createSelector(
  selectValeurComposantState,
  (state: ValeurComposantState.IState) => state.isLoading
);

export const selectIsReadyValeurComposant = createSelector(
  selectValeurComposantState,
  (state: ValeurComposantState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeurComposant = createSelector(
  selectValeurComposantState,
  (state: ValeurComposantState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeurComposantsEntities = createSelector(selectValeurComposantState, selectEntities);

export const selectValeurComposantsArray = createSelector(selectValeurComposantState, selectAll);

const valeurComposantsInObject = (valeurComposants: Dictionary<ValeurComposantEntityState>) => ({ valeurComposants })

const selectValeurComposantsEntitiesDictionary = createSelector(selectValeurComposantsEntities, valeurComposantsInObject);

const selectAllValeurComposantsObject = createSelector(selectValeurComposantsEntities, valeurComposants => {
  return hydrateAll({ valeurComposants });
});

const selectOneValeurComposantDictionary = (idValeurComposant : number) =>
  createSelector(selectValeurComposantsEntities, valeurComposants => ({
    valeurComposants: { [idValeurComposant]: valeurComposants[idValeurComposant] }
  }));

const selectOneValeurComposantDictionaryWithoutChild = (idValeurComposant : number) =>
  createSelector(selectValeurComposantsEntities, valeurComposants => ({
    valeurComposant: valeurComposants[idValeurComposant]
  }));

const selectAllValeurComposantsSelectors: Dictionary<Selector> = {};
export function selectAllValeurComposants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ValeurComposant>(
      schema,
      selectAllValeurComposantsSelectors,
      selectValeurComposantsEntitiesDictionary,
      getRelationSelectors,
      valeurComposantRelations,
      hydrateAll,
      'valeurComposant'
    );
  } else {
    return selectAllValeurComposantsObject;
  }
}

export function selectAllValeurComposantsDictionary(
  schema: SelectSchema = {},
  customKey = 'valeurComposants'
): Selector {
  return createSelector(selectAllValeurComposants(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurComposantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurComposants.length; i++) {
      res[customKey][result.valeurComposants[i].idValeurComposant] = result.valeurComposants[i];
    }
    return res;
  });
}

export function selectOneValeurComposant(
  schema: SelectSchema = {},
  idValeurComposant: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurComposantDictionary(idValeurComposant)];
  selectors.push(...getRelationSelectors(schema, valeurComposantRelations, 'valeurComposant'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurComposantDictionaryWithoutChild(idValeurComposant);
  }
}

interface hydrateArgs {
  valeurComposants: Dictionary<ValeurComposantEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  composants?: Dictionary<ComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurComposants: (ValeurComposant | null)[] } {
  const {
    valeurComposants,
    valeurs,
    composants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurComposants: Object.keys(valeurComposants).map(idValeurComposant =>
      hydrate(
        valeurComposants[idValeurComposant] as ValeurComposantEntityState,
        valeurs,
        composants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeurComposant: ValeurComposantEntityState | null } {
  const {
    valeurComposants,
    valeurs,
    composants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeurComposant = Object.values(valeurComposants)[0];
  return {
    valeurComposant: hydrate(
      valeurComposant as ValeurComposantEntityState,
      valeurs,
      composants
    )
  };
}

function hydrate(
  valeurComposant: ValeurComposantEntityState,
  valeurEntities?: Dictionary<ValeurEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
): ValeurComposant | null {
  if (!valeurComposant) {
    return null;
  }

  const valeurComposantHydrated: ValeurComposantEntityState = { ...valeurComposant };
  if (valeurEntities) {
    valeurComposantHydrated.valeur = valeurEntities[valeurComposant.valeur as number] as Valeur;
  } else {
    delete valeurComposantHydrated.valeur;
  }
  if (composantEntities) {
    valeurComposantHydrated.composant = composantEntities[valeurComposant.composant as number] as Composant;
  } else {
    delete valeurComposantHydrated.composant;
  }

  return valeurComposantHydrated as ValeurComposant;
}
