import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfilState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteProfilEntityState } from '@get/api-interfaces';

export const societeProfilReducersGeneratedFunctions: ReducerTypes<SocieteProfilState.IState, readonly ActionCreator[]>[] = [
  on(SocieteProfilGeneratedActions.upsertOneSocieteProfil, (state: SocieteProfilState.IState) => setLoadingsState(state, true)),

  on(SocieteProfilGeneratedActions.upsertManySocieteProfilsSuccess, (state: SocieteProfilState.IState, { societeProfils }) =>
    SocieteProfilState.adapter.upsertMany(societeProfils, setLoadingsState(state, false))
  ),
  on(SocieteProfilGeneratedActions.deleteOneSocieteProfil, (state: SocieteProfilState.IState) => setLoadingsState(state, true)),
  on(SocieteProfilGeneratedActions.deleteOneSocieteProfilSuccess, (state: SocieteProfilState.IState, { idSocieteProfil }) =>
    SocieteProfilState.adapter.removeOne(idSocieteProfil, setLoadingsState(state, false))
  ),

  on(SocieteProfilGeneratedActions.clearSocieteProfils, () => SocieteProfilState.initialState),

  on(
    SocieteProfilGeneratedActions.addManyUserSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfil, idUsers }) => {
      if (!state.entities[idSocieteProfil]) {
        return state;
      }
      const users = (state.entities[idSocieteProfil]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfil]: {
            ...state.entities[idSocieteProfil],
            users: users.concat(
              idUsers.filter(id => users.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.deleteManyUserSuccess,
    (state: SocieteProfilState.IState, { idUsers, idSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfils.reduce((entities, idSocieteProfil) => {
            if (!state.entities[idSocieteProfil]?.users) {
              return entities;
            }
            entities[idSocieteProfil] = {
              ...state.entities[idSocieteProfil],
              users: (state.entities[idSocieteProfil]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as SocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilEntityState>)
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.addManyUserSocieteProfilSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfil, idUserSocieteProfils }) => {
      if (!state.entities[idSocieteProfil]) {
        return state;
      }
      const userSocieteProfils = (state.entities[idSocieteProfil]?.userSocieteProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfil]: {
            ...state.entities[idSocieteProfil],
            userSocieteProfils: userSocieteProfils.concat(
              idUserSocieteProfils.filter(id => userSocieteProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.deleteManyUserSocieteProfilSuccess,
    (state: SocieteProfilState.IState, { idUserSocieteProfils, idSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfils.reduce((entities, idSocieteProfil) => {
            if (!state.entities[idSocieteProfil]?.userSocieteProfils) {
              return entities;
            }
            entities[idSocieteProfil] = {
              ...state.entities[idSocieteProfil],
              userSocieteProfils: (state.entities[idSocieteProfil]?.userSocieteProfils as number[])?.filter(
                (idUserSocieteProfil: number) => !idUserSocieteProfils.some((id: number) => id === idUserSocieteProfil)
              )
            } as SocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilEntityState>)
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.addManySocieteProfilDroitSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfil, idSocieteProfilDroits }) => {
      if (!state.entities[idSocieteProfil]) {
        return state;
      }
      const societeProfilDroits = (state.entities[idSocieteProfil]?.societeProfilDroits as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfil]: {
            ...state.entities[idSocieteProfil],
            societeProfilDroits: societeProfilDroits.concat(
              idSocieteProfilDroits.filter(id => societeProfilDroits.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.deleteManySocieteProfilDroitSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfilDroits, idSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfils.reduce((entities, idSocieteProfil) => {
            if (!state.entities[idSocieteProfil]?.societeProfilDroits) {
              return entities;
            }
            entities[idSocieteProfil] = {
              ...state.entities[idSocieteProfil],
              societeProfilDroits: (state.entities[idSocieteProfil]?.societeProfilDroits as number[])?.filter(
                (idSocieteProfilDroit: number) => !idSocieteProfilDroits.some((id: number) => id === idSocieteProfilDroit)
              )
            } as SocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilEntityState>)
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.addManyDroitSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfil, idDroits }) => {
      if (!state.entities[idSocieteProfil]) {
        return state;
      }
      const droits = (state.entities[idSocieteProfil]?.droits as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfil]: {
            ...state.entities[idSocieteProfil],
            droits: droits.concat(
              idDroits.filter(id => droits.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.deleteManyDroitSuccess,
    (state: SocieteProfilState.IState, { idDroits, idSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfils.reduce((entities, idSocieteProfil) => {
            if (!state.entities[idSocieteProfil]?.droits) {
              return entities;
            }
            entities[idSocieteProfil] = {
              ...state.entities[idSocieteProfil],
              droits: (state.entities[idSocieteProfil]?.droits as number[])?.filter(
                (idDroit: number) => !idDroits.some((id: number) => id === idDroit)
              )
            } as SocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilEntityState>)
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.addSocieteSuccess,
    (state: SocieteProfilState.IState, { idSocieteProfil, idSociete }) => {
      if (!state.entities[idSocieteProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteProfil]: {
            ...state.entities[idSocieteProfil],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteProfilGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteProfilState.IState, { idSocietes, idSocieteProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteProfils.reduce((entities, idSocieteProfil) => {
            if (!state.entities[idSocieteProfil]?.societe) {
              return entities;
            }
            entities[idSocieteProfil] = {
              ...state.entities[idSocieteProfil],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteProfil]?.societe
              )
                ? undefined
                : state.entities[idSocieteProfil]?.societe
            } as SocieteProfilEntityState;
            return entities;
          }, {} as Dictionary<SocieteProfilEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteProfilState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
