import { createEntityAdapter } from '@ngrx/entity';
import { UserSocieteProfilEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<UserSocieteProfilEntityState>;

export const adapter = createEntityAdapter<UserSocieteProfilEntityState>({
  selectId: o => o.idUserSocieteProfil
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const userSocieteProfilFeatureKey = 'userSocieteProfil';
