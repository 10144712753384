import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { ProgPatrimoineInfoApiService } from '@get/store/api-services';
import { ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgPatrimoineInfo } from '@get/store/configs/normalization';
import { ProgPatrimoineInfoRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgEtatTechniqueGeneratedActions } from '@get/store/actions';

export function getDefaultAddProgPatrimoineInfoActions(progPatrimoineInfo: ProgPatrimoineInfoEntityState, ids?: ProgPatrimoineInfoRelationsIds): Action[] {
  const actions: Action[] = [ProgPatrimoineInfoGeneratedActions.normalizeManyProgPatrimoineInfosAfterUpsert({ progPatrimoineInfos: [progPatrimoineInfo] })];

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyProgPatrimoineInfoSuccess({
        idPatrimoine: ids.patrimoine,
        idProgPatrimoineInfos: [progPatrimoineInfo.idProgPatrimoineInfo]
      })
    );
    actions.push(
      ProgPatrimoineInfoGeneratedActions.addPatrimoineSuccess({
        idProgPatrimoineInfo: progPatrimoineInfo.idProgPatrimoineInfo,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  if (ids?.progComposant) {
    actions.push(
      ProgComposantGeneratedActions.addManyProgPatrimoineInfoSuccess({
        idProgComposant: ids.progComposant,
        idProgPatrimoineInfos: [progPatrimoineInfo.idProgPatrimoineInfo]
      })
    );
    actions.push(
      ProgPatrimoineInfoGeneratedActions.addProgComposantSuccess({
        idProgPatrimoineInfo: progPatrimoineInfo.idProgPatrimoineInfo,
        idProgComposant: ids.progComposant
      })
    );
  }

  if (ids?.progEtatTechnique) {
    actions.push(
      ProgEtatTechniqueGeneratedActions.addManyProgPatrimoineInfoSuccess({
        idProgEtatTechnique: ids.progEtatTechnique,
        idProgPatrimoineInfos: [progPatrimoineInfo.idProgPatrimoineInfo]
      })
    );
    actions.push(
      ProgPatrimoineInfoGeneratedActions.addProgEtatTechniqueSuccess({
        idProgPatrimoineInfo: progPatrimoineInfo.idProgPatrimoineInfo,
        idProgEtatTechnique: ids.progEtatTechnique
      })
    );
  }

  return actions;
}

export class GeneratedProgPatrimoineInfoEffects {
  constructor(
    protected actions$: Actions,
    protected progPatrimoineInfoApiService: ProgPatrimoineInfoApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyProgPatrimoineInfosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgPatrimoineInfoGeneratedActions.normalizeManyProgPatrimoineInfosAfterUpsert),
      concatMap(({ progPatrimoineInfos }) => {
        const actions: Action[] = getActionsToNormalizeProgPatrimoineInfo(progPatrimoineInfos, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgPatrimoineInfo] Normalization After Upsert Success')];
      })
    );
  });
}
