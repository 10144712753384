import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { ProgUniteState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProgUniteEntityState } from '@get/api-interfaces';

export const progUniteReducersGeneratedFunctions: ReducerTypes<ProgUniteState.IState, readonly ActionCreator[]>[] = [
  on(ProgUniteGeneratedActions.upsertOneProgUnite, (state: ProgUniteState.IState) => setLoadingsState(state, true)),

  on(ProgUniteGeneratedActions.upsertManyProgUnitesSuccess, (state: ProgUniteState.IState, { progUnites }) =>
    ProgUniteState.adapter.upsertMany(progUnites, setLoadingsState(state, false))
  ),
  on(ProgUniteGeneratedActions.deleteOneProgUnite, (state: ProgUniteState.IState) => setLoadingsState(state, true)),
  on(ProgUniteGeneratedActions.deleteOneProgUniteSuccess, (state: ProgUniteState.IState, { idProgUnite }) =>
    ProgUniteState.adapter.removeOne(idProgUnite, setLoadingsState(state, false))
  ),

  on(ProgUniteGeneratedActions.clearProgUnites, () => ProgUniteState.initialState),

  on(
    ProgUniteGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: ProgUniteState.IState, { idProgUnite, idProgBiblioInterventions }) => {
      if (!state.entities[idProgUnite]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idProgUnite]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgUnite]: {
            ...state.entities[idProgUnite],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProgUniteGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: ProgUniteState.IState, { idProgBiblioInterventions, idProgUnites }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgUnites.reduce((entities, idProgUnite) => {
            if (!state.entities[idProgUnite]?.progBiblioInterventions) {
              return entities;
            }
            entities[idProgUnite] = {
              ...state.entities[idProgUnite],
              progBiblioInterventions: (state.entities[idProgUnite]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as ProgUniteEntityState;
            return entities;
          }, {} as Dictionary<ProgUniteEntityState>)
        }
      };
    }
  ),

  on(
    ProgUniteGeneratedActions.addSocieteSuccess,
    (state: ProgUniteState.IState, { idProgUnite, idSociete }) => {
      if (!state.entities[idProgUnite]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProgUnite]: {
            ...state.entities[idProgUnite],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ProgUniteGeneratedActions.deleteManySocieteSuccess,
    (state: ProgUniteState.IState, { idSocietes, idProgUnites }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProgUnites.reduce((entities, idProgUnite) => {
            if (!state.entities[idProgUnite]?.societe) {
              return entities;
            }
            entities[idProgUnite] = {
              ...state.entities[idProgUnite],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idProgUnite]?.societe
              )
                ? undefined
                : state.entities[idProgUnite]?.societe
            } as ProgUniteEntityState;
            return entities;
          }, {} as Dictionary<ProgUniteEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ProgUniteState.IState,
  isLoading: boolean,
  isLoaded = true
): ProgUniteState.IState {
  return { ...state, isLoaded, isLoading };
}
