import { Injectable } from '@angular/core';
import { ProgPatrimoineInfo } from '@get/api-interfaces';
import {
  ProgInterventionGeneratedActions,
  ProgPatrimoineInfoActions,
  ProgPatrimoineInfoGeneratedActions
} from '@get/store/actions';
import { ProgPatrimoineInfoApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, mergeMap, of } from 'rxjs';
import { GeneratedProgPatrimoineInfoEffects } from './prog-patrimoine-info-generated.effects';

@Injectable()
export class ProgPatrimoineInfoEffects extends GeneratedProgPatrimoineInfoEffects {
  constructor(actions$: Actions, progPatrimoineInfoApiService: ProgPatrimoineInfoApiService, store$: Store<AppState>) {
    super(actions$, progPatrimoineInfoApiService, store$);
  }

  updateOneProgPatrimoineInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgPatrimoineInfoActions.updateOneProgPatrimoineInfo),
      concatMap(({ progPatrimoineInfo }: { progPatrimoineInfo: Partial<ProgPatrimoineInfo> }) =>
        this.progPatrimoineInfoApiService.updateOneProgPatrimoineInfo(progPatrimoineInfo).pipe(
          mergeMap(({ progInterventions, progPatrimoineInfoResponse }) => {
            const actions: Action[] = [
              ProgPatrimoineInfoGeneratedActions.normalizeManyProgPatrimoineInfosAfterUpsert({
                progPatrimoineInfos: [progPatrimoineInfoResponse]
              })
            ];
            if (progInterventions?.length) {
              actions.push(
                ProgInterventionGeneratedActions.normalizeManyProgInterventionsAfterUpsert({ progInterventions })
              );
            }
            return actions;
          }),
          catchError(error => of(ProgPatrimoineInfoGeneratedActions.progPatrimoineInfosFailure({ error })))
        )
      )
    );
  });
}
