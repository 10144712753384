import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Droit } from '@get/api-interfaces';
import { DroitApiService } from '@get/store/api-services';
import { DroitGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeDroit } from '@get/store/configs/normalization';

export class GeneratedDroitEffects {
  constructor(
    protected actions$: Actions,
    protected droitApiService: DroitApiService,
    protected store$: Store<AppState>
  ) {}

  getManyDroits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.getManyDroits),
      switchMap(({ params }) =>
        this.droitApiService.getDroits(params).pipe(
          map((droits: Droit[]) => {
            return DroitGeneratedActions.normalizeManyDroitsAfterUpsert({ droits });
          }),
          catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
        )
      )
    );
  });

  normalizeManyDroitsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.normalizeManyDroitsAfterUpsert),
      concatMap(({ droits }) => {
        const actions: Action[] = getActionsToNormalizeDroit(droits, StoreActionType.upsert);
        return [getMultiAction(actions, '[Droit] Normalization After Upsert Success')];
      })
    );
  });
}
