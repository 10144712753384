import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { IsUserLogged, IsUserNotLogged } from '@get/core/guards';

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [
  {
    path: 'app',
    canActivate: [IsUserLogged],
    resolve: { AppResolver },
    loadChildren: () => import('@get/layout').then(m => m.LayoutRoutingModule),
    data: {
      breadcrumb: 'Accueil'
    }
  },
  {
    path: 'auth',
    canActivate: [IsUserNotLogged],
    loadChildren: () => import('@get/auth/shell').then(m => m.GetAuthShellModule)
  },
  { path: '**', redirectTo: 'app' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
