import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedSocietePatrimoineHierarchieService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocietePatrimoineHierarchieSelectors.selectIsLoadedSocietePatrimoineHierarchie));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocietePatrimoineHierarchieSelectors.selectIsLoadingSocietePatrimoineHierarchie));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocietePatrimoineHierarchieSelectors.selectIsReadyAndLoadedSocietePatrimoineHierarchie as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocietePatrimoineHierarchies(schema: SelectSchema = {}): Observable<SocietePatrimoineHierarchie[]> {
    return this.store$.pipe(select(SocietePatrimoineHierarchieSelectors.selectAllSocietePatrimoineHierarchies(schema))).pipe(
      switchMap(({ societePatrimoineHierarchies }: { societePatrimoineHierarchies: SocietePatrimoineHierarchie[] }) => {
        return this.getReady(schema).pipe(map(() => societePatrimoineHierarchies));
      })
    );
  }

  public selectOneSocietePatrimoineHierarchie(
    idSocietePatrimoineHierarchie: number,
    schema: SelectSchema = {}
  ): Observable<SocietePatrimoineHierarchie> {
    return this.store$
      .pipe(select(SocietePatrimoineHierarchieSelectors.selectOneSocietePatrimoineHierarchie(schema, idSocietePatrimoineHierarchie)))
      .pipe(
        switchMap(({ societePatrimoineHierarchie }: { societePatrimoineHierarchie: SocietePatrimoineHierarchie }) => {
          return this.getReady(schema).pipe(map(() => societePatrimoineHierarchie));
        })
      );
  }

  public upsertOneSocietePatrimoineHierarchie(
    societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>,
    ids: SocietePatrimoineHierarchieRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocietePatrimoineHierarchie> {
    this.store$.dispatch(SocietePatrimoineHierarchieGeneratedActions.upsertOneSocietePatrimoineHierarchie({ societePatrimoineHierarchie, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert,
        SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure,
        true
      );
    }
  }

  public deleteOneSocietePatrimoineHierarchie(
    idSocietePatrimoineHierarchie: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchie({ idSocietePatrimoineHierarchie }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchieSuccess,
        SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure
      );
    }
  }
}
