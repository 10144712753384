import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteTerritoireState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeTerritoireRelations: string[] = ['societeTerritoirePatrimoines','patrimoines','societeTerritoireUsers','societes',];

export const { selectEntities, selectAll } = SocieteTerritoireState.adapter.getSelectors();

export const selectSocieteTerritoireState = createFeatureSelector<SocieteTerritoireState.IState>(SocieteTerritoireState.societeTerritoireFeatureKey);

export const selectIsLoadedSocieteTerritoire = createSelector(
  selectSocieteTerritoireState,
  (state: SocieteTerritoireState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteTerritoire = createSelector(
  selectSocieteTerritoireState,
  (state: SocieteTerritoireState.IState) => state.isLoading
);

export const selectIsReadySocieteTerritoire = createSelector(
  selectSocieteTerritoireState,
  (state: SocieteTerritoireState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteTerritoire = createSelector(
  selectSocieteTerritoireState,
  (state: SocieteTerritoireState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteTerritoiresEntities = createSelector(selectSocieteTerritoireState, selectEntities);

export const selectSocieteTerritoiresArray = createSelector(selectSocieteTerritoireState, selectAll);

const societeTerritoiresInObject = (societeTerritoires: Dictionary<SocieteTerritoireEntityState>) => ({ societeTerritoires })

const selectSocieteTerritoiresEntitiesDictionary = createSelector(selectSocieteTerritoiresEntities, societeTerritoiresInObject);

const selectAllSocieteTerritoiresObject = createSelector(selectSocieteTerritoiresEntities, societeTerritoires => {
  return hydrateAll({ societeTerritoires });
});

const selectOneSocieteTerritoireDictionary = (idSocieteTerritoire : number) =>
  createSelector(selectSocieteTerritoiresEntities, societeTerritoires => ({
    societeTerritoires: { [idSocieteTerritoire]: societeTerritoires[idSocieteTerritoire] }
  }));

const selectOneSocieteTerritoireDictionaryWithoutChild = (idSocieteTerritoire : number) =>
  createSelector(selectSocieteTerritoiresEntities, societeTerritoires => ({
    societeTerritoire: societeTerritoires[idSocieteTerritoire]
  }));

const selectAllSocieteTerritoiresSelectors: Dictionary<Selector> = {};
export function selectAllSocieteTerritoires(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteTerritoire>(
      schema,
      selectAllSocieteTerritoiresSelectors,
      selectSocieteTerritoiresEntitiesDictionary,
      getRelationSelectors,
      societeTerritoireRelations,
      hydrateAll,
      'societeTerritoire'
    );
  } else {
    return selectAllSocieteTerritoiresObject;
  }
}

export function selectAllSocieteTerritoiresDictionary(
  schema: SelectSchema = {},
  customKey = 'societeTerritoires'
): Selector {
  return createSelector(selectAllSocieteTerritoires(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteTerritoireEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeTerritoires.length; i++) {
      res[customKey][result.societeTerritoires[i].idSocieteTerritoire] = result.societeTerritoires[i];
    }
    return res;
  });
}

export function selectOneSocieteTerritoire(
  schema: SelectSchema = {},
  idSocieteTerritoire: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteTerritoireDictionary(idSocieteTerritoire)];
  selectors.push(...getRelationSelectors(schema, societeTerritoireRelations, 'societeTerritoire'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteTerritoireDictionaryWithoutChild(idSocieteTerritoire);
  }
}

interface hydrateArgs {
  societeTerritoires: Dictionary<SocieteTerritoireEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  societeTerritoirePatrimoines?: Dictionary<SocieteTerritoirePatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  societeTerritoireUsers?: Dictionary<SocieteTerritoireUserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeTerritoires: (SocieteTerritoire | null)[] } {
  const {
    societeTerritoires,
    societes,
    societeTerritoirePatrimoines,
    patrimoines,
    societeTerritoireUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeTerritoires: Object.keys(societeTerritoires).map(idSocieteTerritoire =>
      hydrate(
        societeTerritoires[idSocieteTerritoire] as SocieteTerritoireEntityState,
        societes,
        societeTerritoirePatrimoines,
        patrimoines,
        societeTerritoireUsers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeTerritoire: SocieteTerritoireEntityState | null } {
  const {
    societeTerritoires,
    societes,
    societeTerritoirePatrimoines,
    patrimoines,
    societeTerritoireUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeTerritoire = Object.values(societeTerritoires)[0];
  return {
    societeTerritoire: hydrate(
      societeTerritoire as SocieteTerritoireEntityState,
      societes,
      societeTerritoirePatrimoines,
      patrimoines,
      societeTerritoireUsers
    )
  };
}

function hydrate(
  societeTerritoire: SocieteTerritoireEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  societeTerritoirePatrimoineEntities?: Dictionary<SocieteTerritoirePatrimoineEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  societeTerritoireUserEntities?: Dictionary<SocieteTerritoireUserEntityState>,
): SocieteTerritoire | null {
  if (!societeTerritoire) {
    return null;
  }

  const societeTerritoireHydrated: SocieteTerritoireEntityState = { ...societeTerritoire };
  if (societeEntities) {
    societeTerritoireHydrated.societe = societeEntities[societeTerritoire.societe as number] as Societe;
  } else {
    delete societeTerritoireHydrated.societe;
  }

  if (societeTerritoirePatrimoineEntities) {
    societeTerritoireHydrated.societeTerritoirePatrimoines = ((societeTerritoireHydrated.societeTerritoirePatrimoines as number[]) || []).map(
      id => societeTerritoirePatrimoineEntities[id]
    ) as SocieteTerritoirePatrimoine[];
  } else {
    delete societeTerritoireHydrated.societeTerritoirePatrimoines;
  }

  if (patrimoineEntities) {
    societeTerritoireHydrated.patrimoines = ((societeTerritoireHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete societeTerritoireHydrated.patrimoines;
  }

  if (societeTerritoireUserEntities) {
    societeTerritoireHydrated.societeTerritoireUsers = ((societeTerritoireHydrated.societeTerritoireUsers as number[]) || []).map(
      id => societeTerritoireUserEntities[id]
    ) as SocieteTerritoireUser[];
  } else {
    delete societeTerritoireHydrated.societeTerritoireUsers;
  }

  return societeTerritoireHydrated as SocieteTerritoire;
}
