import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposantFamilleApiService } from '@get/store/api-services';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteComposantFamille } from '@get/store/configs/normalization';
import { SocieteComposantFamilleSelectors } from '@get/store/selectors';
import { SocieteComposantFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant } from '@get/api-interfaces';

export function getDefaultAddSocieteComposantFamilleActions(societeComposantFamille: SocieteComposantFamilleEntityState, ids?: SocieteComposantFamilleRelationsIds): Action[] {
  const actions: Action[] = [SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert({ societeComposantFamilles: [societeComposantFamille] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteComposantFamilleSuccess({
        idSociete: ids.societe,
        idSocieteComposantFamilles: [societeComposantFamille.idSocieteComposantFamille]
      })
    );
    actions.push(
      SocieteComposantFamilleGeneratedActions.addSocieteSuccess({
        idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManySocieteComposantFamilleSuccess({
        idFichier: ids.fichier,
        idSocieteComposantFamilles: [societeComposantFamille.idSocieteComposantFamille]
      })
    );
    actions.push(
      SocieteComposantFamilleGeneratedActions.addFichierSuccess({
        idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.societeComposants) {
    if (!Array.isArray(ids.societeComposants)) {
      actions.push(
        SocieteComposantGeneratedActions.upsertOneSocieteComposant({
          societeComposant: {
            idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
            idSocieteComposant: ids.societeComposants as number
          } as SocieteComposant
        })
      );
      actions.push(
        SocieteComposantFamilleGeneratedActions.addManySocieteComposantSuccess({
          idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
          idSocieteComposants: [ids.societeComposants as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantGeneratedActions.upsertManySocieteComposants({
          societeComposants: (ids.societeComposants as number[]).map(
            (idSocieteComposant: number) => ({
              idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
              idSocieteComposant
            })
          ) as SocieteComposant[]
        })
      );
      actions.push(
        SocieteComposantFamilleGeneratedActions.addManySocieteComposantSuccess({
          idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille,
          idSocieteComposants: ids.societeComposants as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteComposantFamilleActions(societeComposantFamille: SocieteComposantFamilleEntityState): Action[] {
  const actions: Action[] = [SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamilleSuccess({ idSocieteComposantFamille: societeComposantFamille.idSocieteComposantFamille })];

  if (societeComposantFamille.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteComposantFamilleSuccess({
        idSocieteComposantFamilles: [societeComposantFamille.idSocieteComposantFamille],
        idSocietes: [societeComposantFamille.societe as number]
      })
    );
  }

  if (societeComposantFamille.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManySocieteComposantFamilleSuccess({
        idSocieteComposantFamilles: [societeComposantFamille.idSocieteComposantFamille],
        idFichiers: [societeComposantFamille.fichier as number]
      })
    );
  }

  if (societeComposantFamille.societeComposants) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManySocieteComposantFamilleSuccess({
        idSocieteComposantFamilles: [societeComposantFamille.idSocieteComposantFamille],
        idSocieteComposants: societeComposantFamille.societeComposants as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteComposantFamilleEffects {
  constructor(
    protected actions$: Actions,
    protected societeComposantFamilleApiService: SocieteComposantFamilleApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteComposantFamilles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantFamilleGeneratedActions.getManySocieteComposantFamilles),
      switchMap(({ params }) =>
        this.societeComposantFamilleApiService.getSocieteComposantFamilles(params).pipe(
          map((societeComposantFamilles: SocieteComposantFamille[]) => {
            return SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert({ societeComposantFamilles });
          }),
          catchError(error => of(SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteComposantFamille$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantFamilleGeneratedActions.upsertOneSocieteComposantFamille),
      concatMap(
        ({
          societeComposantFamille,
          ids
        }: {
          societeComposantFamille: Partial<SocieteComposantFamille>;
          ids?: SocieteComposantFamilleRelationsIds;
        }) => {
          if (societeComposantFamille.idSocieteComposantFamille) {
            return this.societeComposantFamilleApiService.updateSocieteComposantFamille(societeComposantFamille).pipe(
              map((societeComposantFamilleReturned: SocieteComposantFamille) => {
                return SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert({ societeComposantFamilles: [societeComposantFamilleReturned] });
              }),
              catchError(error => of(SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure({ error })))
            );
          } else {
            return this.societeComposantFamilleApiService.addSocieteComposantFamille(societeComposantFamille).pipe(
              mergeMap((societeComposantFamilleReturned: SocieteComposantFamille) => getDefaultAddSocieteComposantFamilleActions(societeComposantFamilleReturned, ids)),
              catchError(error => of(SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteComposantFamille$ = createEffect(() => {
    const selectSocieteComposantFamilleState$ = this.store$.select(SocieteComposantFamilleSelectors.selectSocieteComposantFamilleState);
    return this.actions$.pipe(
      ofType(SocieteComposantFamilleGeneratedActions.deleteOneSocieteComposantFamille),
      withLatestFrom(selectSocieteComposantFamilleState$),
      concatMap(([{ idSocieteComposantFamille }, state]) =>
        this.societeComposantFamilleApiService.deleteSocieteComposantFamille(idSocieteComposantFamille).pipe(
          mergeMap(_success => getDefaultDeleteSocieteComposantFamilleActions(state.entities[idSocieteComposantFamille] as SocieteComposantFamilleEntityState)),
          catchError(error => of(SocieteComposantFamilleGeneratedActions.societeComposantFamillesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteComposantFamillesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantFamilleGeneratedActions.normalizeManySocieteComposantFamillesAfterUpsert),
      concatMap(({ societeComposantFamilles }) => {
        const actions: Action[] = getActionsToNormalizeSocieteComposantFamille(societeComposantFamilles, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteComposantFamille] Normalization After Upsert Success')];
      })
    );
  });
}
