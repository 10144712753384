import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionApiService } from '@get/store/api-services';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeProgBiblioIntervention } from '@get/store/configs/normalization';
import { ProgBiblioInterventionSelectors } from '@get/store/selectors';
import { ProgBiblioInterventionRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionScenario } from '@get/api-interfaces';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { ProgScenario } from '@get/api-interfaces';

export function getDefaultAddProgBiblioInterventionActions(progBiblioIntervention: ProgBiblioInterventionEntityState, ids?: ProgBiblioInterventionRelationsIds): Action[] {
  const actions: Action[] = [ProgBiblioInterventionGeneratedActions.normalizeManyProgBiblioInterventionsAfterUpsert({ progBiblioInterventions: [progBiblioIntervention] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyProgBiblioInterventionSuccess({
        idSociete: ids.societe,
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention]
      })
    );
    actions.push(
      ProgBiblioInterventionGeneratedActions.addSocieteSuccess({
        idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.progComposant) {
    actions.push(
      ProgComposantGeneratedActions.addManyProgBiblioInterventionSuccess({
        idProgComposant: ids.progComposant,
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention]
      })
    );
    actions.push(
      ProgBiblioInterventionGeneratedActions.addProgComposantSuccess({
        idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
        idProgComposant: ids.progComposant
      })
    );
  }

  if (ids?.progBudget) {
    actions.push(
      ProgBudgetGeneratedActions.addManyProgBiblioInterventionSuccess({
        idProgBudget: ids.progBudget,
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention]
      })
    );
    actions.push(
      ProgBiblioInterventionGeneratedActions.addProgBudgetSuccess({
        idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
        idProgBudget: ids.progBudget
      })
    );
  }

  if (ids?.progUnite) {
    actions.push(
      ProgUniteGeneratedActions.addManyProgBiblioInterventionSuccess({
        idProgUnite: ids.progUnite,
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention]
      })
    );
    actions.push(
      ProgBiblioInterventionGeneratedActions.addProgUniteSuccess({
        idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
        idProgUnite: ids.progUnite
      })
    );
  }

  if (ids?.progInterventionFamille) {
    actions.push(
      ProgInterventionFamilleGeneratedActions.addManyProgBiblioInterventionSuccess({
        idProgInterventionFamille: ids.progInterventionFamille,
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention]
      })
    );
    actions.push(
      ProgBiblioInterventionGeneratedActions.addProgInterventionFamilleSuccess({
        idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
        idProgInterventionFamille: ids.progInterventionFamille
      })
    );
  }

  if (ids?.progBiblioInterventionScenarios) {
    if (!Array.isArray(ids.progBiblioInterventionScenarios)) {
      actions.push(
        ProgBiblioInterventionScenarioGeneratedActions.upsertOneProgBiblioInterventionScenario({
          progBiblioInterventionScenario: {
            idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
            idProgBiblioInterventionScenario: ids.progBiblioInterventionScenarios as number
          } as ProgBiblioInterventionScenario
        })
      );
      actions.push(
        ProgBiblioInterventionGeneratedActions.addManyProgBiblioInterventionScenarioSuccess({
          idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
          idProgBiblioInterventionScenarios: [ids.progBiblioInterventionScenarios as number]
        })
      );
    } else {
      actions.push(
        ProgBiblioInterventionScenarioGeneratedActions.upsertManyProgBiblioInterventionScenarios({
          progBiblioInterventionScenarios: (ids.progBiblioInterventionScenarios as number[]).map(
            (idProgBiblioInterventionScenario: number) => ({
              idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
              idProgBiblioInterventionScenario
            })
          ) as ProgBiblioInterventionScenario[]
        })
      );
      actions.push(
        ProgBiblioInterventionGeneratedActions.addManyProgBiblioInterventionScenarioSuccess({
          idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
          idProgBiblioInterventionScenarios: ids.progBiblioInterventionScenarios as number[]
        })
      );
    }
  }

  if (ids?.progScenarios) {
    if (!Array.isArray(ids.progScenarios)) {
      actions.push(
        ProgScenarioGeneratedActions.upsertOneProgScenario({
          progScenario: {
            idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
            idProgScenario: ids.progScenarios as number
          } as ProgScenario & any
        })
      );
      actions.push(
        ProgBiblioInterventionGeneratedActions.addManyProgScenarioSuccess({
          idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
          idProgScenarios: [ids.progScenarios as number]
        })
      );
    } else {
      actions.push(
        ProgScenarioGeneratedActions.upsertManyProgScenarios({
          progScenarios: (ids.progScenarios as number[]).map(
            (idProgScenario: number) => ({
              idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
              idProgScenario
            })
          ) as ProgScenario[] & any[]
        })
      );
      actions.push(
        ProgBiblioInterventionGeneratedActions.addManyProgScenarioSuccess({
          idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention,
          idProgScenarios: ids.progScenarios as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProgBiblioInterventionActions(progBiblioIntervention: ProgBiblioInterventionEntityState): Action[] {
  const actions: Action[] = [ProgBiblioInterventionGeneratedActions.deleteOneProgBiblioInterventionSuccess({ idProgBiblioIntervention: progBiblioIntervention.idProgBiblioIntervention })];

  if (progBiblioIntervention.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idSocietes: [progBiblioIntervention.societe as number]
      })
    );
  }

  if (progBiblioIntervention.progComposant) {
    actions.push(
      ProgComposantGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgComposants: [progBiblioIntervention.progComposant as number]
      })
    );
  }

  if (progBiblioIntervention.progBudget) {
    actions.push(
      ProgBudgetGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgBudgets: [progBiblioIntervention.progBudget as number]
      })
    );
  }

  if (progBiblioIntervention.progUnite) {
    actions.push(
      ProgUniteGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgUnites: [progBiblioIntervention.progUnite as number]
      })
    );
  }

  if (progBiblioIntervention.progInterventionFamille) {
    actions.push(
      ProgInterventionFamilleGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgInterventionFamilles: [progBiblioIntervention.progInterventionFamille as number]
      })
    );
  }

  if (progBiblioIntervention.progBiblioInterventionScenarios) {
    actions.push(
      ProgBiblioInterventionScenarioGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgBiblioInterventionScenarios: progBiblioIntervention.progBiblioInterventionScenarios as number[]
      })
    );
  }

  if (progBiblioIntervention.progScenarios) {
    actions.push(
      ProgScenarioGeneratedActions.deleteManyProgBiblioInterventionSuccess({
        idProgBiblioInterventions: [progBiblioIntervention.idProgBiblioIntervention],
        idProgScenarios: progBiblioIntervention.progScenarios as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProgBiblioInterventionEffects {
  constructor(
    protected actions$: Actions,
    protected progBiblioInterventionApiService: ProgBiblioInterventionApiService,
    protected store$: Store<AppState>
  ) {}

  upsertOneProgBiblioIntervention$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgBiblioInterventionGeneratedActions.upsertOneProgBiblioIntervention),
      concatMap(
        ({
          progBiblioIntervention,
          ids
        }: {
          progBiblioIntervention: Partial<ProgBiblioIntervention>;
          ids?: ProgBiblioInterventionRelationsIds;
        }) => {
          if (progBiblioIntervention.idProgBiblioIntervention) {
            return this.progBiblioInterventionApiService.updateProgBiblioIntervention(progBiblioIntervention).pipe(
              map((progBiblioInterventionReturned: ProgBiblioIntervention) => {
                return ProgBiblioInterventionGeneratedActions.normalizeManyProgBiblioInterventionsAfterUpsert({ progBiblioInterventions: [progBiblioInterventionReturned] });
              }),
              catchError(error => of(ProgBiblioInterventionGeneratedActions.progBiblioInterventionsFailure({ error })))
            );
          } else {
            return this.progBiblioInterventionApiService.addProgBiblioIntervention(progBiblioIntervention).pipe(
              mergeMap((progBiblioInterventionReturned: ProgBiblioIntervention) => getDefaultAddProgBiblioInterventionActions(progBiblioInterventionReturned, ids)),
              catchError(error => of(ProgBiblioInterventionGeneratedActions.progBiblioInterventionsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProgBiblioIntervention$ = createEffect(() => {
    const selectProgBiblioInterventionState$ = this.store$.select(ProgBiblioInterventionSelectors.selectProgBiblioInterventionState);
    return this.actions$.pipe(
      ofType(ProgBiblioInterventionGeneratedActions.deleteOneProgBiblioIntervention),
      withLatestFrom(selectProgBiblioInterventionState$),
      concatMap(([{ idProgBiblioIntervention }, state]) =>
        this.progBiblioInterventionApiService.deleteProgBiblioIntervention(idProgBiblioIntervention).pipe(
          mergeMap(_success => getDefaultDeleteProgBiblioInterventionActions(state.entities[idProgBiblioIntervention] as ProgBiblioInterventionEntityState)),
          catchError(error => of(ProgBiblioInterventionGeneratedActions.progBiblioInterventionsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProgBiblioInterventionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgBiblioInterventionGeneratedActions.normalizeManyProgBiblioInterventionsAfterUpsert),
      concatMap(({ progBiblioInterventions }) => {
        const actions: Action[] = getActionsToNormalizeProgBiblioIntervention(progBiblioInterventions, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProgBiblioIntervention] Normalization After Upsert Success')];
      })
    );
  });
}
