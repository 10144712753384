import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CaracteristiqueTemplate } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { CaracteristiqueTemplateRelationsIds } from '@get/store/ids-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplateSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export class GeneratedCaracteristiqueTemplateService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CaracteristiqueTemplateSelectors.selectIsLoadedCaracteristiqueTemplate));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CaracteristiqueTemplateSelectors.selectIsLoadingCaracteristiqueTemplate));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CaracteristiqueTemplateSelectors.selectIsReadyAndLoadedCaracteristiqueTemplate as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCaracteristiqueTemplates(schema: SelectSchema = {}): Observable<CaracteristiqueTemplate[]> {
    return this.store$.pipe(select(CaracteristiqueTemplateSelectors.selectAllCaracteristiqueTemplates(schema))).pipe(
      switchMap(({ caracteristiqueTemplates }: { caracteristiqueTemplates: CaracteristiqueTemplate[] }) => {
        return this.getReady(schema).pipe(map(() => caracteristiqueTemplates));
      })
    );
  }

  public selectOneCaracteristiqueTemplate(
    idCaracteristiqueTemplate: number,
    schema: SelectSchema = {}
  ): Observable<CaracteristiqueTemplate> {
    return this.store$
      .pipe(select(CaracteristiqueTemplateSelectors.selectOneCaracteristiqueTemplate(schema, idCaracteristiqueTemplate)))
      .pipe(
        switchMap(({ caracteristiqueTemplate }: { caracteristiqueTemplate: CaracteristiqueTemplate }) => {
          return this.getReady(schema).pipe(map(() => caracteristiqueTemplate));
        })
      );
  }

  public getManyCaracteristiqueTemplates(
    params: any = {},
    getResult?: boolean
  ): void | Observable<CaracteristiqueTemplate[]> {
    this.store$.dispatch(CaracteristiqueTemplateGeneratedActions.getManyCaracteristiqueTemplates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert,
        CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure
      );
    }
  }

  public upsertOneCaracteristiqueTemplate(
    caracteristiqueTemplate: Partial<CaracteristiqueTemplate>,
    ids: CaracteristiqueTemplateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CaracteristiqueTemplate> {
    this.store$.dispatch(CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate({ caracteristiqueTemplate, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert,
        CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure,
        true
      );
    }
  }

  public deleteOneCaracteristiqueTemplate(
    idCaracteristiqueTemplate: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplate({ idCaracteristiqueTemplate }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplateSuccess,
        CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure
      );
    }
  }
}
