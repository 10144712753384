import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixApiService } from '@get/store/api-services';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteCaracteristiqueChoix } from '@get/store/configs/normalization';
import { SocieteCaracteristiqueChoixRelationsIds } from '@get/store/ids-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';

export function getDefaultAddSocieteCaracteristiqueChoixActions(societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState, ids?: SocieteCaracteristiqueChoixRelationsIds): Action[] {
  const actions: Action[] = [SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert({ societeCaracteristiqueChoices: [societeCaracteristiqueChoix] })];

  if (ids?.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
        idSocieteCaracteristique: ids.societeCaracteristique,
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix]
      })
    );
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.addSocieteCaracteristiqueSuccess({
        idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
        idSocieteCaracteristique: ids.societeCaracteristique
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
        idFichier: ids.fichier,
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix]
      })
    );
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.addFichierSuccess({
        idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.caracteristiqueChoixTemplate) {
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
        idCaracteristiqueChoixTemplate: ids.caracteristiqueChoixTemplate,
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix]
      })
    );
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.addCaracteristiqueChoixTemplateSuccess({
        idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
        idCaracteristiqueChoixTemplate: ids.caracteristiqueChoixTemplate
      })
    );
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.addManyValeurSuccess({
          idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
              idValeur
            })
          ) as Valeur[]
        })
      );
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.addManyValeurSuccess({
          idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteCaracteristiqueChoixActions(societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState): Action[] {
  const actions: Action[] = [SocieteCaracteristiqueChoixGeneratedActions.deleteOneSocieteCaracteristiqueChoixSuccess({ idSocieteCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix })];

  if (societeCaracteristiqueChoix.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess({
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix],
        idSocieteCaracteristiques: [societeCaracteristiqueChoix.societeCaracteristique as number]
      })
    );
  }

  if (societeCaracteristiqueChoix.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess({
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix],
        idFichiers: [societeCaracteristiqueChoix.fichier as number]
      })
    );
  }

  if (societeCaracteristiqueChoix.caracteristiqueChoixTemplate) {
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess({
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix],
        idCaracteristiqueChoixTemplates: [societeCaracteristiqueChoix.caracteristiqueChoixTemplate as number]
      })
    );
  }

  if (societeCaracteristiqueChoix.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess({
        idSocieteCaracteristiqueChoices: [societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix],
        idValeurs: societeCaracteristiqueChoix.valeurs as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteCaracteristiqueChoixEffects {
  constructor(
    protected actions$: Actions,
    protected societeCaracteristiqueChoixApiService: SocieteCaracteristiqueChoixApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManySocieteCaracteristiqueChoicesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert),
      concatMap(({ societeCaracteristiqueChoices }) => {
        const actions: Action[] = getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoices, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteCaracteristiqueChoix] Normalization After Upsert Success')];
      })
    );
  });
}
